import React from 'react';
import FieldErrorValidation from "../../FieldErrorValidation";
import PlacesAutocomplete from "react-google-autocomplete";
import { NOTIFICATION_TYPE, openNotification } from "../../../utils/notification";
import { PlaceInput } from './styles';

export const validation = ({ address = '', addressNumber = '' }) => {

  if (!address) {
    openNotification("Endereço inválido!", NOTIFICATION_TYPE.warning);
    return false;
  }

  if (!addressNumber) {
    openNotification("Número não informado!", NOTIFICATION_TYPE.warning);
    return false;
  }

  return true;

};

const defaultPlace = () => ({
  addressNumber: '',
  address: '',
  neighborhoodName: '',
  cityName: '',
  stateName: '',
  countryName: '',
  postalCode: '',
  formatted_address: '',
  complement: '',
  location: {
    latitude: 0,
    longitude: 0,
  },
});

const empty = (value = '') => { //console.warn(value) 
};

const props = {
  stateValue: '',
  number: '',
  setValue: empty,
  setNumber: empty,
  setComplement: empty,
  setPlace: (value = defaultPlace()) => { },
  onChangeVerification: empty,
  locationValidation: validation,
  isSeparatedFields: false,
  placeData: {},
}

const GoogleApiKey = "AIzaSyCbyjDUXM7xpSvvwybHURKFrOh65yPo2Gg";

const PlaceField = ({
  stateValue,
  number,
  setValue = empty,
  setNumber = empty,
  setComplement = empty,
  setPlace = empty,
  onChangeVerification = empty,
  locationValidation = validation,
  isSeparatedFields = true,
  placeData = {},
  label = 'Endereço (ex: Praça Pio X, 12 - ....)',
  ...rest } = props) => {

  return (<FieldErrorValidation {...rest}>
    <small>{label}</small>
    <PlaceInput {...rest}
      value={stateValue}
      onChange={(text) => {

        setValue(text.target.value)
        onChangeVerification(text.target.value);
        setNumber('');
        setPlace(defaultPlace());
      }}
      onBlur={(e) => {
        if (!stateValue || !number || e?.target?.value !== stateValue) {
          setNumber('');
          setPlace(defaultPlace());
        }
      }
      }
      apiKey={GoogleApiKey}
      options={{
        types: ["address"],
        componentRestrictions: { country: "br" },
        fields: ["address_components", "geometry.location", "formatted_address"],
      }}
      onPlaceSelected={(placeResult) => {
        if (!placeResult?.address_components.length) return;

        const address_components = {
          location: {
            latitude: placeResult.geometry.location.lat(),
            longitude: placeResult.geometry.location.lng(),
          },
        };
        placeResult.address_components.map(
          (c) => address_components[c.types[0]] = c.long_name);
        const place = {
          addressNumber: address_components.street_number,
          address: address_components.route,
          neighborhoodName: address_components.sublocality_level_1,
          cityName: address_components.administrative_area_level_2,
          stateName: address_components.administrative_area_level_1,
          countryName: address_components.country,
          postalCode: address_components.postal_code,
          location: address_components.location,
          formatted_address: placeResult.formatted_address,
        };

        if (isSeparatedFields)
          setNumber(place.addressNumber);
        if (!locationValidation(place)) {
          setValue(place.address);
          return;
        }
        setValue(place.formatted_address);
        const complement = address_components.premise
          ? `${address_components.premise} - ${address_components.subpremise}`
          : address_components.subpremise || '';
        if (complement) {
          if (isSeparatedFields)
            setComplement(complement);
          place.complement = complement;
        }

        setPlace(place);
      }}
    />
  </FieldErrorValidation>);
};

export default PlaceField;