import React from 'react';
import { Container } from "./styles";

export default function () {
  return (
    <Container>
      <div className="title">
        Atenção,
      </div>
      <div className="subtitle">
        aumentamos o repasse do correspondente!<br />
        Nosso valor agora pode chegar até 0,4% fixo em cima do valor
        financiado
      </div>
      <div className="content">
        Exemplo: um imóvel sendo negociado por
        R$ 700.000,00 e o comprador vai financiar
        R$ 500.000,00 desse valor, o repasse seria de
        500.000 x 0,4% = R$2.000,00
      </div>
    </Container>
  );
}
