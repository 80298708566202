import React, { useEffect, useState } from "react";
import HeaderLP from "../../components/LP_MG/headerLP";
import FooterLP from "../../components/LP_MG/FooterLP";
import { Link } from "react-router-dom";
import { PlayCircleOutlined, ArrowRightOutlined, MenuOutlined } from '@ant-design/icons';
import { Main, BgImg, ImgBuildings, InitialLP, IntialTextLP, 
  InitalTitle, ContainerParagraph, PartnerBroker, BtnPartnerBroker, BtnSeeProperties,
  InitialVideo, ContainerVideo } from "./style";
import Buildings from '../../assets/buildings.svg'
import ServicesLP from "../../components/LP_MG/ServicesLP";
import StoryLP from "../../components/LP_MG/StoryLP";
import Platform from "../../components/LP_MG/Platform_LP"
import GoTogether from '../../components/LP_MG/GoTogether_LP'
import Thumby_Video from '../../assets/LP_MG/Header_LP/Thumby_Video.svg'
import ModalVideo from  '../../components/LP_MG/Modal'
import { getUserSession, getCityOfUserActing } from '../../utils/sessionHelper'
import { getStates, getCities } from '../../services/getLocation'
import "../../global.css";

export default function LP_MG() {

  const [showModal, setShowModal] = useState(false);
  const [visible, setVisible] = useState(false);

  const user = getUserSession();
  const city = getCityOfUserActing();
 
  useEffect(() => {
    window.scrollTo({
      top: 0
    })
  }, [])

  return (
    <>
      <Main>
        <HeaderLP 
        setShowModal={setShowModal} 
        visible={visible} 
        user={user} 
        city={city} 
        setVisible={setVisible} />
          <BgImg>
            <InitialLP>
            <IntialTextLP>
              <InitalTitle>
                Somos seu <span className="titleStrong">investidor 
                {window.innerWidth <= 600 ? "" : <br />} imobiliário número 1</span>
              </InitalTitle>
              <ContainerParagraph>
              <p>
              Você, profissional do mercado imobiliário, agora tem para quem oferecer sua carteira 
              de imóveis que são ótimos investimentos, mas precisam de reforma ou solução de pendências documentais.
              </p>
              </ContainerParagraph>
              {user !== undefined ?
              <BtnSeeProperties>
              <Link to={`/imoveis?city_id=${city}`}>
                <span className="title-button">
                  Conferir Imóveis
                </span>
                <ArrowRightOutlined className="icon-arrow" />
              </Link>
              </BtnSeeProperties> 
               :
              <BtnPartnerBroker>
                  <Link to="/register?origin=chegamos_em_bh">
                    <span className="title-button">
                      Quero Ser Corretor Parceiro
                    </span>
                    <ArrowRightOutlined className="icon-arrow" />
                  </Link>
                </BtnPartnerBroker> 
               }
            </IntialTextLP>
          <ContainerVideo>
            <InitialVideo src={Thumby_Video} onClick={() => setShowModal(true)} />
          </ContainerVideo>
            </InitialLP>
            <div>
            <ImgBuildings src={Buildings} />
            </div>
          </BgImg>
          <section id="services">
          <ServicesLP user={user} city={city} />
          </section>
          <section id="story">
          <StoryLP />
          </section>
          <section id="platform">
          <Platform user={user} city={city} />
          </section>
          <section id="go_together">
            <GoTogether user={user} city={city}/>
          </section>
          <FooterLP className='footer' />
          {showModal === true &&
          <ModalVideo setShowModal={setShowModal}/>
           }
        </Main>
    </>
  );
}
