import styled from "styled-components";

export const Container = styled.div`
  height: 479px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  form {
    padding-right: 30px;
  }
`

export const Title = styled.span`
  color: #3B414A;
  font-family: 'Roboto';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 30px;
`

export const SubTitle = styled.span`
  color: #3B414A;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 2rem;
  padding-bottom: 15px;
`

export const ContentField = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;

  .content-select {
    border: 1px solid transparent;
    border-radius: 6px;
    transition: border-color 0.3s ease-in-out;
  }

  .content-select.error {
    border-color: red;
  }

  .content-error-and-count {
    display: flex;
    align-items: center;
  }

  .txt-error {
    color: red;
    width: 100%;
  }

  .count-caractere {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
  }

  .content-cep {
    width: 50%;
  }

  .content-large {
    width: 100%;
  }
`

export const LabelField = styled.label`
  color: #474C57;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 0 !important;
`

export const LabelFieldDisabled = styled.span`
  color: #B3B4BA;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 0 !important;
`

export const FieldDisabled = styled.input`
  display: flex;
  padding: 10px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  width: 100%;

  color: #8C8C8C;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const Field = styled.input`
  display: flex;
  padding: 10px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  width: 100%;

  color: #3B414A;
  /* Body/Medium/ts.body-large-regular */
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`