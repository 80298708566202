import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`

export const Title = styled.span`
  color: #3B414A;
  text-align: center;
  font-family: 'Roboto';
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const SubTitle = styled.span`
  color: #3B414A;
  text-align: center;
  font-family: 'Roboto'; 
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 500px;
  margin-top: 28px;
`