import React, { useState, useEffect } from 'react';
import { PlayStore } from "@styled-icons/boxicons-logos/PlayStore";
import { Apple } from "@styled-icons/boxicons-logos/Apple";
import { INSTALL_APP, getMobileOperatingSystem, getUserAgentTypes } from '../../helpers/devices';
import styled from 'styled-components';

const InstallApp = ({ }) => {
  const [currentUserAgent, setCurrentUserAgent] = useState(undefined);
  useEffect(() => {
    const current = getMobileOperatingSystem();
    setCurrentUserAgent(current);
  }, []);

  const FECHADURA_DIGITAL_APP_URL = "https://fechaduradigitalapp.page.link/naxz";
  return (<div
    style={{
      marginTop: "2rem"
    }}>
    <h2>Instalar app {getUserAgentTypes().unknown === currentUserAgent ? "Imóvel Vazio" : "Fechadura Digital"}</h2>
    <div
      className="downloads"
      style={{
        marginTop: "20px",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "row",
      }}
    >

      {(getUserAgentTypes().android === currentUserAgent) &&
        <a
          className="advertise"
          target="_blank"
          href={FECHADURA_DIGITAL_APP_URL}
        >
          <img
            style={{
              width:
                window.innerWidth <= 600
                  ? window.innerWidth / 2 - 22
                  : "",
              cursor: "pointer",
            }}
            src={require("../../../../assets/home/playstore.svg")}
            alt=""
          />
        </a>
      }
      {(getUserAgentTypes().ios === currentUserAgent) &&
        <a
          style={{ marginRight: window.innerWidth <= 600 ? 4 : 20 }}
          className="advertise"
          href={FECHADURA_DIGITAL_APP_URL}
          target="_blank"
        >
          <img
            style={{
              width:
                window.innerWidth <= 600
                  ? window.innerWidth / 2 - 22
                  : "",
              cursor: "pointer",
            }}
            src={require("../../../../assets/home/appstore.svg")}
            alt=""
          />
        </a>
      }
      {(getUserAgentTypes().unknown === currentUserAgent) &&
        <DownloadIV>
          <div>

            <h3><PlayStore size={20} className="social-icons" />Google Play</h3>
            <img id='barcode'
              src={INSTALL_APP.android}
              alt="https://play.google.com/store/apps/details?id=br.com.iv.imovelvazio&hl=pt_BR&gl=US"
              style={{
                margin: "0 10px 10px",
              }}
              title="Instalar app da Fechadura Digital"
              width="150"
              height="150" />
          </div>
          <div>

            <h3><Apple size={20} className="social-icons" />App Store</h3>

            <img id='barcode'
              src={INSTALL_APP.apple}
              alt="https://apps.apple.com/br/app/im%C3%B3vel-vazio/id1573642473?l=en"
              style={{
                margin: "0 10px 10px",
              }}
              title="Instalar app da Fechadura Digital"
              width="150"
              height="150" />
          </div>
        </DownloadIV>
      }
    </div>
  </div>);
}

export default InstallApp;

const DownloadIV = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    h3 {
      margin: 0.8rem;
    }
`;