import React, { useEffect, useState } from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import api from '../../api'
import { getUserProfile, getUserSession } from '../../utils/sessionHelper'
import { Link, useLocation } from 'react-router-dom'
import InputMask from "react-input-mask";
import { Popconfirm } from "antd";
import Loading from '../../components/Loading'
import { getStates, getCities } from '../../services/getLocation'

export default function AdminMarketingList() {
    const userProfile = getUserProfile();
    const token = getUserSession();
    const location = useLocation();

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [dataId, setDataId] = useState(0);
    const [algo, setAlgo] = useState('')
    const [nome, setNome] = useState('')
    const [cpf, setCPF] = useState('')
    const [creci, setCreci] = useState('')
    const [email, setEmail] = useState('')
    const [celular, setCelular] = useState('')
    const [celular2, setCelular2] = useState('')
    const [checked, setChecked] = useState('')
    const [correspondentes, setCorrespondentes] = useState(0)
    const [interesses, setInteressados] = useState(0)
    const [ofertas, setOfertas] = useState(0)
    const [totalTelephone, setTotalTelephone] = useState([])
    const [defaultStateSelected, setDefaultStateSelected] = useState(0)
    const [stateSelected, setStateSelected] = useState('')
    const [citySelected, setCitySelected] = useState(0)
    const [stateData, setStateData] = useState([])
    const [cityData, setCityData] = useState([])

    const CONFIRM_CONTEXT = {
        save: 'Salvar'
    };

    const [confirmLoading, setConfirmLoading] = useState(false);
    const defaultOpenConfirm = { state: false, id: 0, context: CONFIRM_CONTEXT.default };
    const [openConfirm, setOpenConfirm] = useState(defaultOpenConfirm);
    const isOpen = (id, context) => {
        return openConfirm.state && openConfirm.context === context && openConfirm.id === id;
    }
    const handlerOpenConfirm = (state = defaultOpenConfirm.state, id = defaultOpenConfirm.id, context = defaultOpenConfirm.context) => {
        setOpenConfirm({
            state,
            id,
            context,
        })
    }

    const onConfirm = async (callback) => {
        try {
            setConfirmLoading(true);
            await callback();
        } finally {
            setConfirmLoading(false);
            handlerOpenConfirm(false);
        }
    };

    useEffect(() => {
        getStates()
            .then(({ data }) => {
                setStateData(data);
            })

        if (localStorage.getItem('type_user') != 'admin') {
            window.location.href = '/login'
        }
        getLoadId();
        showIsProfile();
    }, [])

    useEffect(() => {
        if (defaultStateSelected !== stateSelected) {
            setCitySelected(0)
        }
        if (stateSelected !== '') {
            getCities({ stateSelected })
                .then(({ data }) => {
                    setCityData(data.citys);
                });
        }
    }, [stateSelected])

    function cleanStates() {
        setAlgo('')
    }

    const getLoadId = async () => {
        await handleLoad()
    }

    function showIsProfile() {
        const id = location.search.split("=");
        setIsUser(token.sub === id[1]);
    }

    async function handleLoad() {
        setLoading(true)
        cleanStates()
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('id');
        if (myParam) {
            const response = await api.get(`/user/${myParam}?limit=20`)

            setDataId(response.data[0]._id)
            setData(response.data)
            setInteressados(response.data[0].totalInteresses)
            setCorrespondentes(response.data[0].totalCorrespondente)
            setOfertas(response.data[0].totalOfertas)
            setNome(response.data[0].name)
            setCPF(response.data[0].cpf)
            setCreci(response.data[0].creci)
            setEmail(response.data[0].email)
            setStateSelected(response.data[0].stateName === undefined ? '' : response.data[0].stateName)
            setDefaultStateSelected(response.data[0].stateName === undefined ? '' : response.data[0].stateName)
            setCitySelected(response.data[0].city === undefined ? '' : response.data[0].city)
            setCelular(response.data[0].telephone[0])
            setCelular2(response.data[0].telephone[1])
            setChecked(response.data[0].receiveEmail != undefined ? response.data[0].receiveEmail : true)
            setTotalTelephone(response.data[0].telephone)
        } else {
            window.location.href = '/admin/marketing/listar'
        }

        setLoading(false)
    }

    async function handleSave() {
        setLoading(true)

        var object = {
            receiveEmail: checked
        }

        if (nome) {
            object = {
                ...object,
                name: nome
            }
        }

        if (validaCpf(cpf)) {
            object = {
                ...object,
                cpf: cpf.replaceAll('.', '').replaceAll('-', '')
            }
        }

        if (creci) {
            if (creci.length >= 3) {
                object = {
                    ...object,
                    creci
                }
            }
        }

        if (email) {
            object = {
                ...object,
                email
            }
        }

        if (stateSelected.length !== 0 && citySelected != 0) {
            object = {
                ...object,
                city: citySelected,
                stateName: stateSelected,
                isRestricted: true,
            }
        } else {
            object = {
                ...object,
                isRestricted: false,
            }
        }

        if (celular && !celular.includes('_')) {
            let telephones = totalTelephone
            telephones[0] = celular
            object = {
                ...object,
                telephone: telephones
            }
        }

        if (celular2 && !celular2.includes('_')) {
            let telephones = totalTelephone
            telephones[1] = celular2
            object = {
                ...object,
                telephone: telephones
            }
        }

        let res = await api.put(`/user/${data[0]._id}`, object)
        if (res.data._id) {
            window.location.href = '/admin/marketing/listar'
        }

        setLoading(false)
    }

    function validaCpf(val) {
        if (!val) {
            return false
        }
        var cpf = val.trim();

        cpf = cpf.replace(/\./g, '');
        cpf = cpf.replace('-', '');
        cpf = cpf.split('');

        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (var i = 1; cpf.length > i; i++) {
            if (cpf[i - 1] != cpf[i]) {
                aux = true;
            }
        }

        if (aux == false) {
            return false;
        }

        for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
            v1 += cpf[i] * p;
        }

        v1 = ((v1 * 10) % 11);

        if (v1 == 10) {
            v1 = 0;
        }

        if (v1 != cpf[9]) {
            return false;
        }

        for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
            v2 += cpf[i] * p;
        }

        v2 = ((v2 * 10) % 11);

        if (v2 == 10) {
            v2 = 0;
        }

        if (v2 != cpf[10]) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <>
            {loading && <Loading />}
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        MARKETING >
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Lista de Corretores > Editar
                    </Link>
                </div>

                <div className="header" style={{ marginTop: 30 }}>
                    <div className="cod" style={{ width: window.innerWidth <= 600 ? '100%' : '55%' }}>
                        <h2>Editar</h2>
                    </div>
                    {
                        window.innerWidth <= 600 ? '' : (
                            <div className="active" style={{ width: '45%' }}>
                                <h2>ATIVIDADES</h2>
                            </div>
                        )
                    }
                </div>

                {/* {
                    data.map(row => ( */}
                <div className="body" style={{ height: 'auto', border: 'none', flexDirection: window.innerWidth <= 600 ? 'column' : 'row' }}>
                    <div className="body-cod" style={{ width: window.innerWidth <= 600 ? '100%' : '55%', flexDirection: 'column', border: 'none', paddingRight: window.innerWidth <= 600 ? 0 : '4%' }}>

                        <div className="input-group">
                            <label htmlFor="">Nome</label>
                            <input placeholder='Adhemar Figueira Filho' disabled={!isUser} value={nome} onChange={e => setNome(e.target.value)} />
                        </div>

                        <div style={{ display: 'flex', flexDirection: window.innerWidth <= 600 ? 'column' : 'row', justifyContent: 'space-between', width: '100%' }}>
                            <div className="input-group" style={{ width: window.innerWidth <= 600 ? '100%' : '48%' }}>
                                <label htmlFor="">CPF</label>
                                {/* <input placeholder='879.843.937-53' value={cpf} onChange={e => setCPF(e.target.value)}/> */}
                                <InputMask mask="999.999.999-99" disabled={!isUser} onChange={text => setCPF(text.target.value)} value={cpf} placeholder="Ex.: 000.000.000-00" />
                            </div>
                            <div className="input-group" style={{ width: window.innerWidth <= 600 ? '100%' : '48%' }}>
                                <label htmlFor="">CRECI</label>
                                <input placeholder='39751' disabled={!isUser} value={creci} onChange={e => setCreci(e.target.value)} />
                            </div>
                        </div>

                        <div className="input-group">
                            <label htmlFor="">Email</label>
                            <input placeholder='adhemarfigueirafilho@hotmail.com' disabled={!isUser} value={email} onChange={e => setEmail(e.target.value)} />
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div className="input-group" style={{ width: '48%' }}>
                                <label htmlFor="">Estado de Atuação</label>
                                <select value={stateSelected} disabled={!isUser} onChange={e => setStateSelected(e.target.value)}>
                                    <option value={0}>
                                        Selecione uma opção:
                                    </option>
                                    {
                                        stateData.map((item) => {
                                            return <option key={item.name} value={item.name}>{item.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="input-group" style={{ width: '48%' }}>
                                <label htmlFor="">Cidade de Atuação</label>
                                <select
                                    disabled={stateSelected.length == 0}
                                    value={citySelected}
                                    disabled={!isUser}
                                    defaultValue={0}
                                    onChange={e => setCitySelected(e.target.value)}
                                >
                                    <option value={0}>
                                        Selecione uma opção:
                                    </option>
                                    {
                                        cityData.map((item) => {
                                            return <option key={item._id} value={item._id}>{item.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="input-group">
                            <label htmlFor="">Celular</label>
                            {/* <input placeholder='(21) 9866-85230' value={celular} onChange={e => setCelular(e.target.value)}/> */}
                            <InputMask mask="(99) 99999-9999" disabled={!isUser} value={celular} onChange={text => setCelular(text.target.value)} placeholder="Ex.: (21) 9866-85230" pattern="\(\d{2}\)\d{4}-\d{4}" />
                        </div>

                        <div className="input-group">
                            <label htmlFor="">Outro Telefone</label>
                            {/* <input placeholder='(21) 3315-6500' value={celular2} onChange={e => setCelular2(e.target.value)}/> */}
                            <InputMask mask="(99) 99999-9999" disabled={!isUser} value={celular2} onChange={text => setCelular2(text.target.value)} placeholder="Ex.: (21) 3315-6500" pattern="\(\d{2}\)\d{4}-\d{4}" />
                        </div>
                    </div>

                    {window.innerWidth <= 600 ? (
                        <div className="header" style={{ marginBottom: 30 }}>
                            <div className="active" style={{ width: '100%' }}>
                                <h2>ATIVIDADES</h2>
                            </div>
                        </div>
                    ) : ''}

                    <div className="body-active" style={{ width: window.innerWidth <= 600 ? '100%' : '45%', flexFlow: 'wrap', border: 'none', height: 'inherit' }}>
                        <div className="square">
                            <strong>Anúncios</strong>

                            <h3>0</h3>
                        </div>
                        <div className="square">
                            <strong>Imóveis Interessados</strong>

                            <h3>{interesses}</h3>
                        </div>
                        <div className="square" style={{ marginTop: window.innerWidth <= 600 ? 15 : 0 }}>
                            <strong>Ofertas para Imóvel Vazio</strong>

                            <h3>{ofertas}</h3>
                        </div>
                        <div className="square" style={{ marginTop: window.innerWidth <= 600 ? 15 : 0 }}>
                            <strong>Correspondente Bancário</strong>

                            <h3>{correspondentes}</h3>
                        </div>

                        <div className="select-group" style={{ marginTop: window.innerWidth <= 600 ? 15 : 0 }}>
                            <input type="checkbox" name="" id="" disabled={!isUser} checked={checked} onChange={e => setChecked(!checked)} />
                            <span>Quer receber informações por e-mail sobre os imóveis à venda.</span>
                        </div>

                        {/* <button style={{marginTop: window.innerWidth <= 600?15:0}}> */}
                        {isUser &&
                            <Popconfirm
                                placement="top"
                                className="size-modal"
                                style={{ marginTop: window.innerWidth <= 600 ? 15 : 0 }}
                                title='Editar'
                                description='Tem certeza que deseja alterar as informações?'
                                onConfirm={() => onConfirm(async () => await handleSave())}
                                open={isOpen(1, CONFIRM_CONTEXT.save)}
                                okButtonProps={{
                                    loading: confirmLoading,
                                }}
                                onCancel={() => handlerOpenConfirm()}
                                okText="Sim"
                                cancelText="Não"
                            >
                                <a onClick={() => handlerOpenConfirm(true, 1, CONFIRM_CONTEXT.save)}>Salvar</a>
                            </Popconfirm>
                        }
                        {/* </button> */}
                    </div>
                </div>
                {/* ))
                } */}

            </Container>
        </>
    )
}