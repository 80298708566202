import styled from 'styled-components'

export const BgImg = styled.div`
    width:100vw;
    min-height:150vh;
    background:url(${require('../../assets/Cbanking/bg.svg')});
    background-size:cover;
    background-position:center; 
    padding-top:200px;

    @media(max-width:600px){
        padding-top:0px;

        .header{
            margin-bottom: 70px;
        }
    }
`

export const Container = styled.main`
    width:100%;
    height:100%;
    display:flex;
    flex-wrap:wrap;
    padding-bottom:500px;   
    justify-content:center;

    @media(max-width:600px){
        padding-bottom:0px;   
    }
`
export const TableContainer = styled.div`   
    width:100%;
    max-width:650px;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(30px);
    border-radius: 8px 0px 0px 8px;
    padding:40px;
    margin-top:10px;
    margin-bottom: 10px;

    p{
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.05em;
        margin-top:10px;    
        color: #1E1D1D;
    }

    table{
        border-collapse:collapse;
        width:100%;

        .initial-th{
            border-top:1px solid #858585;
            border-right:1px solid #858585;
            border-bottom:1px solid #858585;
        }

        .final-th{
            border:1px solid #858585;
            width:30%;
            text-align:left;
        }

        td:last-child{
            border:none;
        }

        th{
            padding:5px;
            text-align:left;
            padding-left:20px;
        }     

        th:last-child{
            border-bottom:1px solid #858585;
            width:40%;
        }       

        td{
            padding-left:20px;
            border-right:1px solid #858585;
            text-align:left;
            font-weight: 500;
            font-size: 14px;
            line-height: 33px;
            width:30%;
            font-family: Ubuntu;
            font-style: normal;
            letter-spacing: 0.04em;

            color: #1E1D1D;
        }
    }
`
export const FormContainer = styled.div`
    width:100%;
    max-width:644px;
    background-color:#FFFFFF;   
    padding:30px;
    border-radius: 7px;
    .caption{
        font-size:0.8rem;
        line-height:25px;

        h1{
            font-size: 35px
        }

        .subTitle{
            margin-top: 15px;
            font-size: 15px;
            padding: 5px;
        }
        
        .input-box{
            background-color: #eee;
            border-radius: 5px;
            height: 60px;
            width: 100%;
            margin-top: 20px;
            padding-left: 10px;
            padding-right: 15px;
            padding-top: 3px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-evenly;

            small{
                font-weight: 500;
                line-height: normal;  
            }

            input{
                background-color: transparent;
                border: transparent;
                border-bottom: 2px solid #ccc;
                width: 100%;
            }
        }

        .input-center{
            width: 60%;
            margin-left: 20%;
        }

        .container-next{
            height: 50px;
            width: 100%;
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            align-items: space-evenly;
            justify-content: center;

            
            button{
                border: transparent;
                background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
                width: 60%;
                color: white;
                height: 100%;
                border-radius: 4px;
            }

            button:hover{
                color: #EA5C1E;
                background: white;
                text-decoration: none;
                border: 2px solid #EA5C1E;
                filter: none;
                background-color: white;
                background-image: none;
            }

            .btBack{
                margin-right: 4%;
                border: 2px solid #EA5C1E;
                background-color: white;
                background: white;
                color: #EA5C1E;
            }

            .btBack:hover{
                background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
                color: white;
                border: none;
            }
        }

        .remember{
            margin-left: 40%;
            margin-top: 10px;
            cursor: pointer;
        }
        

        .container-next-reverse{
            height: 50px;
            width: 100%;
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            align-items: space-evenly;
            justify-content: center;

            button{
                color: #EA5C1E;
                background: white;
                text-decoration: none;
                border: 2px solid #EA5C1E;
                filter: none;
                width: 60%;
                background-color: white;
                background-image: none;
                height: 100%;
                border-radius: 4px;
            }
            
            button:hover{
                border: transparent;
                background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
                color: white;
            }


            .btBack{
                margin-right: 4%;
                border: 2px solid #EA5C1E;
                background-color: white;
                background: white;
                color: #EA5C1E;
            }

            .btBack:hover{
                background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
                color: white;
                border: none;
            }
        }

        img{
            margin-left: 40%;
            margin-top: 80px;
        }

        .textSend{
            text-align: center;
            font-size: 17px;
            margin-top: 15px;
        }
        
        .container-input-box{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .input-box{
                width: 48%;
                margin-left: 0;
            }
        }
    }
`