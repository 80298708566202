import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;

  .alert-icon {
    width: 150px;
    padding-bottom: 12px;
  }

  .title {
    color: #3B414A;
    text-align: center;
    font-family: 'Roboto';
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;
  }

  .subtitle {
    color: #3B414A;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 616px;
    text-align: center;
  }
`

export const ContentButtonBack = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 70px;
  padding-bottom: 50px;

   .content-back {
        display: flex;
        gap: 6px;
        align-items: center;
        justify-content: flex-start !important;    
        width: 100%;
        cursor: pointer;

        .icon-left {
            width: 30px;
            height: 30px;
        }
    }
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

// export const Content = styled.div`
//   display: flex;
//   justify-content: start;
//   align-items: center;
//   flex-direction: column;
//   width: 65%;
//   padding-left: 70px;
//   padding-right: 70px;
//   height: 100%;
// `

// export const ContentTitle = styled.div`
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   width: 100%;
//   height: 90%;

//   .title {
//     color: #3B414A;
//     font-family: 'Roboto';
//     font-size: 32px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: normal;
//     margin-bottom: 16px;
//   }

//   .subTitle {
//     color: #3B414A;
//     font-family: 'Roboto';
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//     margin-bottom: 32px;
//   }
// `

// export const ContentOptionFirst = styled.div`
//    display: flex;
//    gap: 20px;
//    margin-bottom: 30px;

//    .titleOption {
//     color: #3B414A;
//     font-family: 'Roboto';
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//     padding-top: 3px;
//    }
// `

// export const ContentOptionLast = styled.div`
//    display: flex;
//    gap: 20px;

//    .titleOption {
//     color: #3B414A;
//     font-family: 'Roboto';
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//     padding-top: 3px;
//    }
// `

// export const Position = styled.span`
//   display: flex;
//   width: 24px;
//   height: 24px;
//   padding: 2.4px;
//   justify-content: center;
//   align-items: center;
//   gap: 12px;
//   border-radius: 240px;
//   border: 1.2px solid #EA5C1D;
//   background: #EA5C1D;

//   color: #FFF;
//   text-align: center;
//   font-family: 'Roboto';
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: normal;
// `

// export const ContentImgIllustrated = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   width: 35%;
//   background-color: #FFFAF3;
//   height: 100%;

//   .content-back {
//     display: flex;
//     gap: 6px;
//     align-items: center;
//     justify-content: flex-start !important;    
//     width: 70%;
//     margin-bottom: 15px;
//     cursor: pointer;
//   }

//   .title-back {
//     color: #474C57;
//     text-align: center;
//     font-family: 'Roboto';
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 500;
//     line-height: normal;
//   }
// `

// export const ContentBtns = styled.div`
//  display: flex;
//  align-items: center;
//  gap: 20px;
// `

// export const NextButton = styled.button`
//   border-radius: 6px;
//   background: #EA5C1D;
//   padding: 15px 16px;
//   border: none;

//   color: #FFF;
//   text-align: center;
//   font-family: 'Roboto';
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: normal;
// `

// export const BtnOutlined = styled.button`
//   border-radius: 6px;
//   background: #FFF;
//   padding: 15px 16px;
//   border: none;

//   color: #EA5C1D;
//   text-align: center;
//   font-family: 'Roboto';
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: normal;
// `

// export const ContentInfo = styled.div`
//   border-radius: 6px;
//   background: #FFECCC;
//   width: 100%;
//   padding: 10px;
//   display: flex;
//   align-items: center;
//   gap: 10px;

//   .info-circle {
//     width: 20px;
//     height: 20px;
//     background-color: #F0A000;
//     display: flex;
//     border-radius: 200px;
//     justify-content: center;
//     align-items: center;
//   }

//   .info {
//     color: #fff;
//   }

//   .title-info {
//     color: #3B414A;
//     /* Body/Medium/ts.body-large-regular */
//     font-family: 'Roboto';
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//   }
// `