const { default: styled } = require("styled-components");

const background = require('./background-home.png')

export const Container = styled.div`
    margin-left:20vw;
    width:80vw;
    height:100%;
    padding:30px;
    padding-top:12vh;

    @media only screen and (max-width: 600px) {
        margin-left: 0;
        // border: 1px solid black;
        width: 100%;
        padding-top: 30px;
    }
    
  .bread-crumb{
    display:flex;
    align-items:center; 
    margin-bottom:40px;
    
    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
    .category{
        color: #0A677C;
        margin-right:3px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;

        @media only screen and (max-width: 600px) {
            inline-size: max-content;
        }
    }

    .where{
        color: #EB5C1C;
        text-decoration:none;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;

        @media only screen and (max-width: 600px) { 
            inline-size: max-content;
            margin-top: 10px;
        }
    }
  }

    
    .filter{
        border: 1px solid #C4C4C4;
        border-radius: 4px;
        padding:20px;
        display:flex;
        flex-wrap:wrap;
        
        .input-group{
            width: 230px;
            height: 59px;
            background: #DADADA;
            border-radius: 4px;
            padding:5px;
            margin-right:10px;
            margin-bottom:10px;

            @media only screen and (max-width: 600px) {
                width: 100%;
                margin-right: 0px;
            }
            
            label{
                width:100%;
            }
            input,select{
                width:100%;
                height:60%;
                background:transparent;
                border:none;
                outline:none;
                margin-top: 3px;
                padding-left: 10px;
            }
        
        }
        .button-group{
            margin-left:auto;
            //margin-right:85px;
            margin-top: 10px;

            @media only screen and (max-width: 600px) {
                margin-right: 0px;

                button {
                    width: 100%;
                }
            }

            .outline{
                background:transparent;
                outline:none;
                border: 1px solid #EA5C1E;
                padding:10px;
                border-radius: 4px;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                /* identical to box height */
                text-align: center;
                letter-spacing: 0.05em;
                /* #EA5C1E */
                color: #EA5C1E;
            }

            .full{
                outline:none;
                background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
                border-radius: 4px;
                border:none;
                padding:10px;
                color:#ffffff;
                margin-left:10px;

                @media only screen and (max-width: 600px) {
                    margin-left: 0px;
                    margin-top: 15px;
                }
            }
        }
    }
    .row-ordenate{
        display:flex;
        width:100%;
        justify-content:space-between;
        align-items:center;
        margin-top:20px;
        margin-bottom:20px;

        
        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }

        .input-group{
            width: 230px;
            height: 59px;
            background: #DADADA;
            border-radius: 4px;
            padding:5px;
            margin-right:10px;
            margin-bottom:10px;
            
            label{
                width:100%;
            }
            input,select{
                width:100%;
                height:100%;
                background:transparent;
                border:none;
                outline:none;

            }
           
            @media only screen and (max-width: 600px) {
                margin-top: 15px;
                margin-right:0px;
                margin-bottom:0px;
            }
        }
    }
    .header{
        display:flex;
        background: #0A677C;
        border: 1px solid #0A677C;
        border-radius: 4px;
        color:#ffffff;
        height: 50px;
        margin-bottom: 10px;
        margin-top: 20px;

        
        // @media only screen and (max-width: 600px) {
        //     width: 120%;
        //     margin-left: -10%;
        // }

        div{
            height: 100%;
            h2{
                padding-left: 12px;
                display: flex;
                align-items: center;
                height: 100%
            }
        }

        div + div{
            border-left:1px solid #ffffff;
        }

        .cod{
            width: 7%;

            @media only screen and (max-width: 600px) {
                width: 18%;
                font-size: 10px;
            }
        }

        .active{
            width: 10%;

            @media only screen and (max-width: 600px) {
                font-size: 10px;
            }
        }

        .imovel{
            width: 45%;
            @media only screen and (max-width: 600px) {
                font-size: 10px;
            }
        }
        
        .engajamento{
            width: 27%;
            @media only screen and (max-width: 600px) {
                font-size: 10px;
            }
        }

        .actions{
            width: 18%;
            @media only screen and (max-width: 600px) {
                font-size: 10px;
            }
        }

    }

    .body{
        height: 150px;
        width: 100%;
        margin-top: 20px;
        border-radius: 5px;
        border: 1px solid #aaa;
        display: flex;
        flex-direction: row;

        div{
            display: flex;
        }
        
        .body-cod{
            border-right: 1px solid #aaa;
            width: 7%;
            height: 100%;
            color: #0A677C;
            text-align: center;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 17px;

            
            @media only screen and (max-width: 600px) {
                width: 18%;
            }
        }

        .body-active{
            border-right: 1px solid #aaa;
            width: 10%;
            height: 100%;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 600px) {
                width: 78px;
            }

            // .home{
            //     cursor: pointer;
            // }

            div{
                display: flex;
                margin-bottom: 20px;
                flex-direction: column;
                
                
                img {
                    margin-bottom: 15px;
                    margin-top:15px;
                    height: 40px
                }
                font-size: 18px;
            }
        }
        .body-imovel{
            border-right: 1px solid #aaa;
            width: 45%;
            height: 100%;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            .container-divide{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-right: 0px solid red; 

                div{
                    width: 100%;
                    height: 50%;
                    border: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    white-space: nowrap;
                    overflow-x: auto;
                }

                .border-top{
                    border-top: 2px solid #ddd;
                }

                .btOptions{
                    cursor: pointer;
                    color: #00AEC8;
                    font-weight:500;
                }

                .divNome{
                    align-items: flex-start;
                    padding-left: 10px;
                }
                
                .divEmail{
                    align-items: flex-start;
                    padding-left: 10px;
                }

                .divTelephone{
                    align-items: flex-start;
                    padding-left: 10px;
                }

                // .btOptions:hover{
                //     background: #00AEC8;
                //     color:white;
                // }

                .divData{
                    font-size: 13px;
                    height: 25%;
                    align-items: flex-start;
                    padding-left: 20px;
                    padding-top: 4px;
                    overflow: hidden;
                    border-top: none;
                    white-space: break-spaces;
                    overflow-y: auto;
                }
                .divDescription{
                    font-size: 15px;
                    height: 50%;
                    align-items: flex-start;
                    padding-left: 20px;
                    overflow: hidden;
                    border-top: none;
                    white-space: break-spaces;
                    color: #0A677C;
                    font-weight: 700;
                    overflow-y: auto;
                    font-size: 20px;
                    font-weight: 500;
                }

                .divState{
                    font-size: 14px;
                    height: 25%;
                    align-items: flex-start;
                    padding-left: 20px;
                    overflow: hidden;
                    color: #0A677C;
                    font-weight: 400;
                    border-top: none;
                    white-space: break-spaces;
                    overflow-y: auto;
                }
            }

            @media only screen and (max-width: 600px) {
                width: 200px;
                overflow: auto;
                //padding-top: 35px;
            }

            small {
                width: 100%;
                font-size: 16px;
                padding-left: 20px;

                @media only screen and (max-width: 600px) {
                    font-size: 14px;   
                    padding-left: 15px; 
                }
            }

            h2, h3{
                width: 100%;
                color: #0A677C;
                padding-left: 20px;

                @media only screen and (max-width: 600px) {
                    font-size: 14px;   
                    padding-left: 15px; 
                }
            }
            h2{
                font-size: 20px;
                @media only screen and (max-width: 600px) {
                    font-size: 17px;   
                    padding-left: 15px; 
                }
            }

            h3{
                font-weight: 400;
                font-size: 18px;
                @media only screen and (max-width: 600px) {
                    font-size: 16px;   
                    padding-left: 15px; 
                }
            }

            h5{
                width: 100%;
                font-size: 16px;
                padding-left: 20px;
                font-weight: 400;
                margin-top: 10px;
            }
            h6{
                width: 100%;
                font-size: 16px;
                padding-left: 20px;
                font-weight: 400;
            }
        }
        .body-engajamento{
            border-right: 1px solid #aaa;
            width: 27%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 600px) {
                width: 140px;

                p {
                    font-size: 18px;    
                }
            }


            p{
                height: 100%;
                width: 100%;
                color: #000000;
                font-size: 18px;  
                text-align: center;
                align-items: center;
                display: flex;  
                padding-left: 15px;

                a{
                    cursor: pointer;
                }
                
                @media only screen and (max-width: 600px) {   
                    font-size: 18px;    
                    padding-top: 0px;
                    padding-left: 0px;
                }
            }

            div + div {
                border-top: 1px solid #aaa;
            }
        }
        .body-actions{
            width: 21%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            @media only screen and (max-width: 600px) {
                width: 150px;

                div {
                    a {
                        font-size: 17px;
                    }
                }
            }

            
            div{
                height: 100%;
                display: flex;
                justify-content: flex-start;
                padding-left: 18px;
                align-items: center;
                width: 100%;
                color: #00AEC8;
                font-size: 20px;

                a{
                    cursor: pointer;
                }
            }

            div + div {
                border-top: 1px solid #aaa;
            }
        }
    }

    .paginate{
        width: 100%;
        height: 30px;
        margin-top: 22px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .pagination{
            display: flex;
            height: 100%;
            list-style-type: none;
            
            li{
                list-style-type: none;

                a{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            
        }

        div, li{
            height: 100%;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #aaa;
            border-top: 1px solid #aaa;
            border-left: 1px solid #aaa;
        }

        .previous {
            width: 70px;
            font-size: 16px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        .next{
            width: 70px;
            border-right: 1px solid #aaa;
            border-top-right-radius: 5px;
            font-size: 16px;
            border-bottom-right-radius: 5px;
        }

        li:hover {
            color: white;
            background: #0A677C;
            cursor: pointer;
        }

        .active {
            background: #0A677C;
            color: white;
        }
    }
`