import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function TextEditor({ isEmpty = false, text = '', handlerText = null, height = '50vh', padding = '0.25rem 0 2rem', margin = '0.25rem 0 2rem', readOnly = false }) {
  let value = text;
  if (value) {
    value = text?.startsWith('<') ? text : `<p>${text}</p>`;
  }
  const modules = isEmpty ? { toolbar: null } : {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike',],
    ],
  };

  return (<ReactQuill
    style={{ height, padding, margin }}
    theme="snow"
    value={value}
    onChange={handlerText}
    modules={modules}
    readOnly={readOnly}
  />);
}

export default TextEditor;