import React, { useState, useContext, useEffect } from 'react';
import FormContext from './_context';
import FormField from '../../../components/FormField';
import { ACTION_STATE, NOTIFICATION_TYPE } from './helper';
import { validateCode } from '../../../services/auth.service';

export default function VerificationStep() {
  const { errors, register, setData, userData, setStatus, currentStatus, openNotification } = useContext(FormContext);

  const [isValidCode, setIsValidCode] = useState(true);

  const processCurrentStep = () => {
    validateCode(userData)
      .then(() => setStatus(ACTION_STATE.finish))
      .catch(({response}) => {
        const { message } = response?.data.message;
        openNotification(message || 'Código inválido!', NOTIFICATION_TYPE.warning);
        setIsValidCode(false)
        setStatus(ACTION_STATE.await);
      });
  }

  useEffect(() => {
    if (currentStatus === ACTION_STATE.process)
      processCurrentStep();
  }, [currentStatus]);

  useEffect(() => setIsValidCode(true), [userData.verificationCode]);


  useEffect(() =>
    setData({
      VerificationStep: new Date().getTime(),
      verificationCode: ''
    })
    , []);

  const validate = () => (isValidCode || 'Código inválido!');

  return <>
    <FormField label='Código de verificação' error={errors.verificationCode} >
      <input {...register('verificationCode', { required: 'Informe o código', validate })}
        value={userData.verificationCode}
        onChange={(e) => setData({ verificationCode: e.target.value })}
      />
    </FormField>
  </>
}