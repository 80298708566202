import React, { useEffect, useState } from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import Autocomplete from '../../components/autosuggest'
import api from '../../api'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate';

export default function HistoricPage() {
    const [data, setData] = useState([])


    useEffect(() => {
        if (localStorage.getItem('type_user') != 'admin') {
            window.location.href = '/login'
        }

        handleLoad()
    }, [])

    const handleLoad = async () => {

        let pathname = window.location.pathname.split("/")
        const response = await api.get(`/historic/${pathname[pathname.length - 1]}`)
        setData(response.data)
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        {'IMÓVEIS À VENDA >'}
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        {'Histórico >'}
                    </Link>
                    <h1 className="category">
                        {'Imóvel Nº'} {String(window.location.pathname.split("/")[window.location.pathname.split("/").length - 1])}
                    </h1>
                </div>

                {/* <div className="header">
                    <div className="cod" style={{ width: "5%" }}>
                        <h2>SEQ.</h2>
                    </div>
                    <div className="actions" style={{ width: "90%" }}>
                        <h2>CORRETORES QUE ABRIRAM O IMÓVEL PELA FECHADURA</h2>
                    </div>
                </div> */}
                {
                    data.map((row, index) => (
                        <RowData userId={row.userId} row={row} key={"Row-" + index} index={index} />
                    ))
                }
                <div className="body" style={{ height: 60 }}>
                    <div className="body-cod" style={{ width: '100%' }}>
                    </div>
                </div>
            </Container >
        </>
    )
}

const RowData = ({ userId, index, row }) => {
    const [user, setUser] = useState(undefined)
    const date = new Date(parseInt(row?.createdAt)).toLocaleTimeString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })

    useEffect(async () => {
        const response = await api.get(`/user/${userId}`)

        if (response.data?.length) {
            setUser(response.data[0])
        }
    }, []);

    return (
        <>
            <div className="body" style={{ height: 60 }}>
                <div className="body-cod" style={{ width: '5%' }}>{index + 1}</div>
                <div className="body-active" style={{ width: '30%', alignItems: 'flex-start', paddingLeft: 20, fontSize: 17, fontWeight: 600 }}>
                    <span>{user?.name}</span>
                </div>
                <div className="body-imovel" style={{ width: '50%', alignItems: 'flex-start', paddingLeft: 20, fontSize: 17 }}>
                    <span>Email: {user?.email}</span>
                    <span>Telefone: {user?.telephone[0]} </span>
                </div>
                <div className="body-imovel" style={{ width: '15%', alignItems: 'flex-start', paddingLeft: 20, fontSize: 17 }}>
                    <span>{date}</span>
                </div>
            </div>
        </>
    )
}
