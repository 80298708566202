import React, { useContext, useEffect } from 'react';
import FormContext from './_context';
import { Controller } from 'react-hook-form';
import FormField from '../../../components/FormField';
import ReactInputMask from 'react-input-mask';
import { sendCodeVerification } from '../../../services/auth.service';
import { ACTION_STATE, NOTIFICATION_TYPE } from './helper';

export default function IdentityStep() {
  const { errors, register, control, setData, userData, setStatus, currentStatus, openNotification } = useContext(FormContext);

  const isValidationByPhone = () => userData.validationType === 'phone';
  const isValidPhoneNumber = (val) => {
    const value = (val || userData.phone || '')?.replace(/\D/g, '');
    return !isValidationByPhone() || value.length === 11;
  }

  useEffect(() => {
    setData({ IdentityStep: new Date().getTime() });
  }, []);

  const processCurrentStep = () => {
    sendCodeVerification(userData)
      .then(() => {
        const deviceType = userData.validationType === 'phone'
          ? 'celular' : 'e-mail';
        const deviceId = userData.validationType === 'phone'
          ? userData.phone : userData.email;
        openNotification(`Código enviado para o ${deviceType} ${deviceId}`, NOTIFICATION_TYPE.success);
        setStatus(ACTION_STATE.finish)
      })
      .catch(({ response }) => {
        openNotification(response.data?.message || 'Não foi possível identificar o telefone ou email informado!', NOTIFICATION_TYPE.warning);
        setStatus(ACTION_STATE.await);
      });
  };

  useEffect(() => {
    if (currentStatus === ACTION_STATE.process)
      processCurrentStep();
  }, [currentStatus]);

  return <>

    <FormField label='E-mail ou usuário' error={errors.email} >
      <input {...register('email', { required: 'Informe o usuário', minLength: { value: 2, message: 'Informe um usuário válido' } })}
        value={userData.email}
        onChange={(e) => setData({ email: e.target.value })}
      />
    </FormField>
    <FormField label='Tipo de verificação' error={errors.validationType} >
      <select {...register("validationType", { required: 'Selecione o tipo de verificação' })}
        onChange={(e) => setData({ validationType: e.target.value })}
      >
        <option value="">Selecione</option>
        <option value="email">Por e-mail</option>
        <option value="phone">Por SMS (celular)</option>
      </select>
    </FormField>
    <Controller
      name="phone"
      control={control}
      rules={{
        required: isValidationByPhone() && !userData.phone ? 'Informe o celular cadastrado' : false,
        validate: (val) => isValidPhoneNumber(val) || 'Informe um número válido!'
      }}
      render={({ field }) =>
        isValidationByPhone() && <FormField label='telefone' error={errors.phone} >
          <ReactInputMask
            {...{ field }}
            mask="(99) 99999-9999"
            value={userData.phone}
            onChange={(e) => setData({ phone: e.target.value })}
            placeholder="Ex.: (00) 00000-00000"
          />

        </FormField>
      }
    />
  </>
}

