import React from "react";
import { BarStory, MainStory, StoryTextLP, StoryTextTitle, 
       StoryFirstParagraph, StoryLastParagraph, ImageBanner, ImageBuildings  } from "./style";
import StoryImage from '../../../assets/LP_MG/StoryLP/StoryImage.svg'
import BuildingsStory from '../../../assets/LP_MG/StoryLP/BuildingsStory.svg'
import "../../../global.css";

export default function StoryLP() {

  return (
    <>
        <BarStory>
         <MainStory>
            <StoryTextLP>
             <StoryTextTitle>
                <span>E a <span className="title-color">nossa história 
                {window.innerWidth <= 600 ? "" : <br />}</span> só se expande</span>
             </StoryTextTitle>
             <StoryFirstParagraph>
                <p>
                Temos mais de 10 anos de atuação no mercado do Rio de Janeiro. Compramos e vendemos centenas 
                de imóveis durante período. Aprendemos que para receber as melhores oportunidades e vender 
                nossos imóveis de forma mais rápida, precisamos de você ao nosso lado.
                </p>
            </StoryFirstParagraph>
            <StoryLastParagraph>
                <p>
                Após nosso sucesso em revitalizar os bairros nobres do Rio de Janeiro, resolvemos explorar novos 
                Horizontes. Nossa missão, é reincorporar ao mercado imóveis <strong>degradados e com dificuldade de liquidez 
                por problemas documentais.</strong>
                </p>
            </StoryLastParagraph>
            </StoryTextLP>
            <ImageBanner>
            <img src={StoryImage}></img>
           </ImageBanner>
       </MainStory>
       <div>
       <ImageBuildings src={BuildingsStory}></ImageBuildings>
       </div>
         </BarStory>
    </>
  );
}
