import React, { useState, useEffect } from "react";
import * as S from "./styles";
import "../../global.css";
import { Modal } from 'antd';
import CloseCircle from '../../assets/close-circle.svg'


export default function ModalZapBinding({ open, showFooter, showButton, showCheckbox, closeModal, children, nextModal, advertisementTutorial, viewModalPrepare, titleCheckbox }) {
  
  const [checkboxMarked, setCheckboxMarked] = useState(showCheckbox);

  function validateClickNextModal() {
    if(showCheckbox) {
      if(checkboxMarked) {
        nextModal();
        setCheckboxMarked(false);
      }
    } else {
        nextModal();
    }
  }

  return (
    <>
      <Modal
        title={
            <S.ContainerTitle>
                <S.Title>Como vincular</S.Title>
                <img src={CloseCircle} alt="Close Circle" onClick={() => closeModal()}/>
            </S.ContainerTitle>
        }
        centered
        closeIcon={false}
        open={open}
        width={1200}
        footer={[
          <>
            {showFooter && 
                <S.ContainerFooter>
                  {showButton && 
                    <>
                     {showCheckbox && 
                        <S.DontRememberAgain>
                          <input 
                          type="checkbox" 
                          className="checkboxRemember" 
                          id="checkboxRemember" 
                          name="checkboxRemember" 
                          checked={checkboxMarked}
                          onClick={() => setCheckboxMarked(!checkboxMarked)} />
                          <label className="titleRemember" htmlFor="checkboxRemember">{titleCheckbox}</label>
                        </S.DontRememberAgain>
                      }
                      <S.NextButton onClick={() => validateClickNextModal()} disabled={showCheckbox ? !checkboxMarked : false}>Próximo Passo</S.NextButton>
                    </>
                  }
                </S.ContainerFooter>
            }
          </>
        ]}
      >
        <S.Background>
            {children}
        </S.Background>
      </Modal>

    </>
  );
}
