import React from 'react';
import NavBar from './NavBar'
export default function HeaderLP({setShowModal, visible, setVisible, user, city}) {
  
    return (
    <>
     <NavBar 
     setShowModal={setShowModal} 
     visible={visible}
     setVisible={setVisible}
     user={user}
     city={city} />
    </>
  )}