import React, { useEffect, useState } from 'react';
import Header from '../../components/adminHeader';
import Aside from '../../components/adminSideBar';
import { Container } from './styles';
import api from '../../api';
import { Link } from 'react-router-dom';
import { getCitiesAsync } from '../../services/city.service';
import { getPropertiesAsync } from '../../services/property.service';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { notification } from "antd";
import { getUserSession } from '../../utils/sessionHelper';

export default function AdminOrdenation() {
	const user = getUserSession();
	const [data, setData] = useState([])
	const [status, setStatus] = useState('Home 1')
	const [cities, setCities] = useState([]);

	const getCities = async () => {
		try {
			const response = await getCitiesAsync({
				orderBy: 'total',
				direction: 'DESC',
				showCatalog: true,
				arquive: false,
				sold: false
			});

			if (Array.isArray(response.data))
				setCities(response.data);

		} catch (e) {

		}
	}

	useEffect(() => {
		if (localStorage.getItem('type_user') != 'admin') {
			window.location.href = '/login'
		}
		getCities();
	}, []);

	async function handleLoad() {
		const limit = 10000
		if (status === 'Home 1') {
			const { data: result } = await getPropertiesAsync({ limit, showHome: true, orderby: null, }, !!user);
			setData(result.propertys)
		} else if (status === 'Pagina de Imoveis') {
			const { data: result } = await getPropertiesAsync({ limit, showCatalog: true, sold: false, orderby: null }, !!user);
			setData(result.propertys)
		}
		else if (cities.some(x => status == x.value)) {
			const { data: result } = await getPropertiesAsync({ limit, orderby: null, showCatalog: true, city_id: status }, !!user);
			setData(result.propertys);
		}
	}

	useEffect(() => {
		handleLoad()
	}, [status])

	async function handleApply() {
		let array = []
		data.map(e => {
			array.push(e._id)
		})

		const response = await api.post(`/property/order`, {
			array,
			status,
			isByCity: cities.some(x => status == x.value)
		})

		if (response.data.status == 'success') {
			notification.success({
				message: 'Ordem Aplicada'
			});
		}
	}

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		let newItems = Array.from(data);
		const [reorderedItem] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, reorderedItem);
		newItems = newItems.sort((a, b) => a.sold ? 1 : b.sold ? -1 : 0);
		setData(newItems);
	};

	return (
		<>
			<Header />
			<Aside />
			<Container>
				<div className="bread-crumb">
					<h1 className="category">
                        ANÚNCIOS >
					</h1>
					<Link to={window.location.pathname} className="where">
						Ordenação de Imóveis
					</Link>
				</div>
				<div className="filter">
					<div className="input-group">
						<label htmlFor="">Selecione a posição de destaque no site</label>
						<select value={status} onChange={(e) => setStatus(e.target.value)}>
							<option selected>Home 1</option>
							<option>Pagina de Imoveis</option>
							{
								cities.map(city =>
									<option value={city.value}>{city.city}, {city.uf}</option>
								)
							}
						</select>
					</div>
					<div className="button-group">
						<button onClick={handleApply} className="full">Aplicar Ordem</button>
					</div>
				</div>

				<div className="header" style={{ marginTop: 25 }}>
					<div className="cod">
						<h2>CÓD.</h2>
					</div>
					<div className="active">
						<h2>IMÓVEL</h2>
					</div>
				</div>
				<div className='container-table'>
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId="itemList">
							{(provided) => (
								<div  {...provided.droppableProps} ref={provided.innerRef}>
									{data.map((item, index) => (
										<Draggable key={item._id} draggableId={item._id} index={index}>
											{(provided) => (
												<div className='body'
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													ref={provided.innerRef}
												>
													<div className="body-cod">{item._id}</div>
													<div className="body-actions" style={{ textDecoration: item.sold ? 'line-through' : 'none' }}>
														<p>{item.address}{item.addressNumber ? `, ${item.addressNumber}` : ''}{item.addressComplement ? ' - ' + item.addressComplement : ' '} - {item?.neighborhood?.name}</p>
													</div>
													{item.sold && <b className='sold'>(Vendido)</b>}
													<img style={{ margin: '0.5rem' }} src={require('../../assets/icons/drag-indicator.svg')} />
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</Container>
		</>
	)
}