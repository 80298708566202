import React, { useEffect, useState } from "react";
import Header from "../../components/adminHeader";
import { useHistory } from "react-router-dom";
import {  notification } from "antd";

import { Container } from "./styles.js";
import api from "../../api";
import Loading from "../../components/Loading";

export default function AdminLogin() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  useEffect(() => {
    window.location.href = "/login";

    document
      .querySelector("form")
      .addEventListener("submit", (e) => e.preventDefault());
  }, []);

  async function handleLogin() {
    setLoading(true)
    const { data } = await api.post("/user/login", {
      email,
      password,
    });

    if (data.token) {
      localStorage.setItem("tkn", data.token);
    }
    if (data.location) {
      localStorage.setItem("location", JSON.stringify(data.location));
    }
    if (localStorage.getItem("chatLink") && data.type === "admin") {
      localStorage.setItem("id_painel", data._id);
      localStorage.setItem("type_user", "admin");
      window.location.href = localStorage.getItem("chatLink");
      localStorage.removeItem("chatLink");
    } else if (data.type == "admin") {
      localStorage.setItem("id_painel", data._id);
      localStorage.setItem("type_user", "admin");
      history.push("/admin/property/list");
    } else if (data.type == "agent") {
      localStorage.setItem("id_painel", data._id);
      localStorage.setItem("type_user", "agent");
      history.push("/admin/home-corretor");
    } else {
      notification.error({
        message: 'Falha ao inserir documentos,tente novamente mais tarde'
      });
    }

    // localStorage.setItem('id_painel', data._id)
    // localStorage.setItem('type_user', data.type)
    // if(data.type == "admin"){
    //     history.push('/admin/property/new')
    // }

    setLoading(false)
  }

  return (
    <>
      {loading && <Loading />}
      <Header show={false} />
      <Container>
        <form>
          <h1>Login</h1>
          <div className="inputs">
            <div className="input-group">
              <label htmlFor="">Email</label>
              <input
                type="text"
                value={email}
                onChange={(input) => setEmail(input.target.value)}
              />
            </div>
            <div className="input-group">
              <label htmlFor="">Senha</label>
              <input
                type="password"
                value={password}
                onChange={(input) => setPassword(input.target.value)}
              />
            </div>
          </div>
          <button onClick={handleLogin}>Entrar</button>
        </form>
      </Container>
    </>
  );
}
