import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    width: 50%;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
`

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;

  .title {
    color: #3B414A;
    font-family: 'Roboto';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 16px;
  }

  .subTitle {
    color: #3B414A;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 75px;
  }
`

export const ContentOptionFirst = styled.div`
   display: flex;
   gap: 20px;
   margin-bottom: 30px;

   .titleOption {
    color: #3B414A;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 3px;
   }
`

export const ContentOptionLast = styled.div`
   display: flex;
   gap: 20px;

   .titleOption {
    color: #3B414A;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 3px;
   }
`

export const Position = styled.span`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2.4px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 240px;
  border: 1.2px solid #EA5C1D;
  background: #EA5C1D;

  color: #FFF;
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`