import React, {useState} from 'react'
import '../../global.css';
import { Link } from 'react-router-dom'
import api from '../../api'

export default function Finish({setStep}) {

    return (
        <>
            <div className="stepi">  
                <br/><br/><br/>
                <p style={{fontSize: 24}}>Cadastro realizado com sucesso!</p>
                <div className="container-next container-center">
                    <Link to="/imoveis"><button>Ver Imóveis</button></Link>
                </div>
            </div>
        </>
    )
}
