import React from "react";

import { Drawer, Descriptions } from "antd";
import moment from "moment";


// import "antd/lib/descriptions/style/index.css";
// import "antd/lib/drawer/style/index.css";
const Single = React.memo(function Single({ selected, setIsOpen, isOpen }) {
  return (
    <Drawer
      title={`Log #${selected?._id}`}
      placement="right"
      visible={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Descriptions
        bordered
        layout="vertical"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="ID">{selected?._id}</Descriptions.Item>
        <Descriptions.Item label="Data">
          {moment(selected?.date, "YYYY-MM-DD[T]HH:mm:ss")
            .subtract(3, "hours")
            .format("DD/MM/YYYY HH:mm:ss")}
        </Descriptions.Item>
        <Descriptions.Item label="Metódo">
          {selected?.requestMethod}
        </Descriptions.Item>
        <Descriptions.Item label="Rota">{selected?.route}</Descriptions.Item>
        <Descriptions.Item label="Código resposta">
          {selected?.responseCode}
        </Descriptions.Item>
        <Descriptions.Item label="Retorno">
          {JSON.stringify(selected?.responseMessage)}
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  );
});

export default Single;
