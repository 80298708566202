import React from 'react'
import '../../global.css';
import InputMask from "react-input-mask";
import { getAlreadyRegisteredAsync } from '../../services/user.service';
import { NOTIFICATION_TYPE, openNotification } from '../../utils/notification';

export default function StepTwo({ setStep, setEmail, email, setEmailConfirm, emailConfirm, setCelular, celular, setOtherCelular, otherCelular }) {

    function handleNext() {
        if (email.length >= 5 && emailConfirm.length >= 5 && emailConfirm == email && celular.length == 15 && !celular.includes('_')) {

            getAlreadyRegisteredAsync({ email })
                .then(() => {
                    setStep(3)
                })
                .catch(err => {
                    console.error(err);
                    const content = err?.response?.data?.userMessage || 'Falha na validação!';
                    openNotification(content, NOTIFICATION_TYPE.error);
                });
        } else {
            let errors = document.querySelectorAll('.input-box')

            if (email.length == 0) {
                errors[0].style.border = "2px solid red"
                styleBorderError(errors[0])
            }

            if (emailConfirm.length == 0 || emailConfirm != email) {
                errors[1].style.border = "2px solid red"
                styleBorderError(errors[1])
            }


            if (celular.includes('_') || celular == '') {
                errors[2].style.border = "2px solid red"
                styleBorderError(errors[2])
            }

            if (otherCelular.includes('_')) {
                errors[3].style.border = "2px solid red"
                styleBorderError(errors[3])
            }

        }
    }

    function styleBorderError(object) {
        setTimeout(() => {
            object.style.border = "none"
        }, 3000);
    }

    return (
        <>
            <div className="step">
                <div className="input-box">
                    <small>E-mail</small>
                    <input value={email} onChange={text => setEmail(text.target.value)} placeholder="Ex.: joao.silva@email.com" type="email" />
                </div>
                <div className="input-box">
                    <small>Confirmação de E-mail</small>
                    <input value={emailConfirm} onChange={text => setEmailConfirm(text.target.value)} placeholder="Ex.: joao.silva@email.com" />
                </div>
                <div className="input-box">
                    <small>Celular</small>
                    <InputMask mask="(99) 99999-9999" value={celular} onChange={text => setCelular(text.target.value)} placeholder="Ex.: (00) 00000-0000" pattern="\(\d{2}\)\d{4}-\d{4}" />
                </div>
                <div className="input-box">
                    <small>Outro telefone</small>
                    <InputMask mask="(99) 99999-9999" value={otherCelular} onChange={text => setOtherCelular(text.target.value)} placeholder="Ex.: (00) 00000-0000" />
                </div>
                <div className="container-next">
                    <button className="btBack" onClick={click => setStep(1)}>Voltar</button>
                    <button onClick={handleNext}>Avançar</button>
                </div>
            </div>
        </>
    )
}
