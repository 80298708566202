import React from "react";

import { BgImg, Container, FormContainer, TableContainer } from "./style";
import Header from "../../components/header";
import Footer from "../../components/Footer";
import FinancialTransferInfo from "../../components/FinancialTransferInfo";

function CBanking() {
  return (
    <>
      <BgImg>
        <Header />
        <Container>
          <TableContainer>
            <FinancialTransferInfo />
          </TableContainer>
          <FormContainer>
            <div class="caption">
              <p>Prezado(a),</p>
              <p>
                Você precisa de aprovação na Caixa para o financiamento do seu
                cliente? Oferecemos esse serviço e ainda fazemos um repasse para
                você, vide tabela ao lado.
              </p>
              <p>
                Entre em contato pelo telefone <strong>(21) 99997-1300</strong>{" "}
                ou e-mail <strong>correspondente@imovelvazio.com.br</strong>.
              </p>
              <p>Segue relação de documentos necessários:</p>
              <p>
                - <strong>Identidade</strong>: CNH na validade, RG, ID militar,
                carteira de trabalho ou passaporte
                <br />- <strong>CPF</strong>: Somente o número, se constar na
                indentidade não precisa
                <br />- <strong>Comprovante de residência</strong>: Em nome do
                cliente/participante - Pode ser Conta de Consumo (Água, Luz,
                Telefone e Gás) com a parte de dentro da conta ou Cartão de
                Crédito, fatura aberta. Validade 30 dias
                <br />- <strong>Comprovante de Estado Civil</strong>:
                <br />
                <span style={{ marginLeft: "3em" }}>Solteiro:</span> Certidão de
                Nascimento
                <br />
                <span style={{ marginLeft: "3em" }}>Casado:</span> Certidão de
                Casamento
                <br />
                <span style={{ marginLeft: "3em" }}>Divorciado:</span> Certidão
                de Casamento com Averbação do divórcio
                <br />
                <span style={{ marginLeft: "3em" }}>Viúvo:</span> Certidão de
                Casamento e Atestado de Óbito
                <br />- <strong>Comprovante de Renda</strong>:
                <br />
                <span style={{ marginLeft: "3em" }}>
                  Assalariado, funcionário privado ou público:
                </span>{" "}
                Contra-Cheque
                <br />
                <span style={{ marginLeft: "3em" }}>
                  Empresários, comerciantes e donos de empresas:
                </span>{" "}
                Pró-Labore (último mês + comprovante de pagamento de imposto do
                pró-labore DARF) ou IR completo + recibo de entrega + contrato
                empresa
                <br />
                <span style={{ marginLeft: "3em" }}>
                  Aposentados/Pensionistas:
                </span>{" "}
                Último extrato do benefício ou comprovante de pagamento
              </p>
            </div>
          </FormContainer>
        </Container>
      </BgImg>
      <Footer />
    </>
  );
}

export default CBanking;
