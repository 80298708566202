import React from "react";
import { Badge, Tooltip } from "antd";
import { useState } from "react";
import api from "../../../../api";

import DoorOpen from "../../../../assets/image-icons/door-open.png";
import DoorClose from "../../../../assets/image-icons/door-close.png";

export default ({ useApp, setModal, userId, propertyId }) => {
  const [hover, setHover] = useState(false);
  const handleClick = async () => {
    if (useApp) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(`imovel:${propertyId}`);
        window.location.href = `lockiv://app/lock/${propertyId}`;
      }
    }
    if (userId) {
      setModal(true);
      await api.post(`/interested/${propertyId}/${userId}`);
    } else {
      window.location.href = `/login?goBack=/imoveis/${propertyId}`;
    }
  };

  return (<Tooltip title={useApp ? "Abrir Porta" : "Esquema de chaves"} >
    <Badge count="Novo" color="green">
      <div style={{
        // width: '100px !important',
        // display: 'flex !important',
        marginLeft: '1rem', cursor: "pointer",
      }}>
        {
          hover ?
            <img
              src={DoorOpen}
              width={36}
              height={36}
              alt="Abrir Porta"
              onClick={handleClick}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            />
            : <img

              src={DoorClose}
              width={36}
              height={36}
              alt="Abrir Porta"
              onClick={handleClick}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            />
        }
      </div>
    </Badge>
  </Tooltip>);
};
