import styled from 'styled-components'

export const Background = styled.div`
  height: 569px;
  background-color: #fff;
  width: 100%;
`

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:  20px;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.03);
  width: 100%;

  img {
    cursor: pointer;
  }
`

export const Title = styled.span`
  color: #313F4C;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const ContainerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 40px;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);
  width: 100%;
  display: flex;
  justify-content: end;
`

export const NextButton = styled.button`
  border-radius: 6px;
  background: #EA5C1D;
  padding: 15px 16px;
  border: none;

  color: #FFF;
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

  &:disabled {
    background: #B3B4BA;
    cursor: none;
  }
`

export const DontRememberAgain = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .checkboxRemember {
    width: 20px;
    height: 20px;
    color: #B3B4BA;
    border-radius: 6px;
    cursor: pointer;
  }

  .titleRemember {
    color: #3B414A;
    text-align: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-right: 15px;
  }
`

export const BtnOutlined = styled.button`
  border-radius: 6px;
  border: 1px solid #EA5C1D;
  background: #FFF; 
  padding: 15px 16px;

  color: #EA5C1D;
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`