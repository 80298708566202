import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import * as S from "./styles";
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import TableWithSearch from "../../components/TableWithSearch";
import api, { refreshTokenCallback } from "../../api";
import IconSetting from './icons/setting.svg'
import lampOn from './icons/lamp-on.svg'
import ArrowDown from './icons/arrow-down.svg'

import { getUserSession } from "../../utils/sessionHelper";
import { InfoCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import ArrowLeft from './icons/ArrowLeft.svg'

import PrepareAds from "../../components/ViewsModal/AddAdsList/PrepareAd";
import SuccessPropertyPrepared from '../../components/ViewsModal/AddAdsList/SuccessPropertyPrepared'

import ImageVerifyPrepared from '../../assets/ImgVerifyPrepared.svg'
import DownloadIcon from './icons/download.svg';
import CopyIcon from './icons/copy.svg';

import ModalZap from "../../components/ModalZap";
import ModalZapBinding from "../../components/ModalZapBinding";

import InformationTabAds from "../../components/ViewsModal/TabsViewsVerify/Information";
import AdAboutTab from "../../components/ViewsModal/TabsViewsVerify/AdAbout";
import AdCharacteristicsTab from "../../components/ViewsModal/TabsViewsVerify/AdCharacteristics";
// import AdDescription from "../../components/ViewsModal/TabsViewsVerify/AdDescription";
import AdDescriptionEdit from "../../components/ViewsModal/TabsViewsVerify/AdDescriptionEdit";
import AdValuesTab from "../../components/ViewsModal/TabsViewsVerify/AdValues";

import InformationView from "../../components/ViewsModal/TabsViewsVerify/InformationView";
import AdAboutTabView from "../../components/ViewsModal/TabsViewsVerify/AdAboutView";
import AdValuesView from "../../components/ViewsModal/TabsViewsVerify/AdValuesView";
import AdDescriptionFull from "../../components/ViewsModal/TabsViewsVerify/AdDescriptionFull";

import StartBindingZap from "../../components/TabsModalBindingZap/StartBinding";
import CreateZapAccount from "../../components/TabsModalBindingZap/CreateZapAccount";
import InformationAlert from "../../components/TabsModalBindingZap/InformationAlert";
import LoginZap from "../../components/TabsModalBindingZap/LoginZap";
import ConfigZap from "../../components/TabsModalBindingZap/ConfigZap";
import IntegratorNameZap from "../../components/TabsModalBindingZap/IntegratorNameZap";
import LinkZap from "../../components/TabsModalBindingZap/LinkZap";
import SuccessZap from "../../components/TabsModalBindingZap/SuccessZap";

import { notification, Tabs, Popover } from 'antd';

import Loading from "../../components/Loading";

import { getPropertiesAdvertisement } from "../../services/AdminAdManagement/getPropertyAdvertisement";

import { validateContent } from "../../utils/validateNoContent";
import { getFeatures } from "../../services/getFeatures";

export default function AdminAdManagement() {

  const [dataPropertyZap, setPropertyZap] = useState([]);
  const [loading, setLoading] = useState(false);
  const [features, setFeatures] = useState([])
  const [modalZap, setModalZap] = useState(false);
  const [modalBindingZap, setModalBindingZap] = useState(false);


  const [commercialDistrict, setCommercialDistrict] = useState('');
  const [constructionInProgress, setConstructionInProgress] = useState(false);
  const [propertyStatusInProgress, setPropertyStatusInProgress] = useState('');
  const [propertyRenovated, setPropertyRenovated] = useState(false);
  const [propertyFurnished, setPropertyFurnished] = useState(false);
  const [propertyFurnishedStatus, setPropertyFurnishedStatus] = useState('');
  const [propertyFeatures, setPropertyFeatures] = useState([]);
  const [propertyDescription, setPropertyDescription] = useState('');
  const [propertyDescriptionSuggest, setPropertyDescriptionSuggest] = useState('');
  const [checkboxMarkedModal, setCheckboxMarkedModal] = useState(false);

  const [nextViewModalPrepare, setNextViewModalPrepare] = useState("verify");
  const [titleCheckbox, setTitleCheckbox] = useState('');
  const [titleButtonModal, setTitleButtonModal] = useState('Salvar');
  const [valueTitleAds, setValueTitleAds] = useState('');

  const [nextViewModalBinding, setNextViewModalBinding] = useState("start");
  const [titleButtonModalBinding, setTitleButtonModalBinding] = useState('Salvar');

  const [adData, setAdData] = useState();
  const [propertyData, setPropertyData] = useState();
  const [dataPropertyFeatures, setDataPropertyFeature] = useState();

  const [dataSelected, setDataSelected] = useState();

  const [showLoading, setShowLoading] = useState(false);

  const [apiNotification, contextHolder] = notification.useNotification();

  const [openPopupCopy, setOpenPopupCopy] = useState(false);

  const [openPopoverDownloadList, setOpenPopoverDownloadList] = useState(false);
  const [openPopoverZapBinding, setOpenPopoverZapBinding] = useState(false);

  const [dataZapFileAccessLog, setDataZapFileAccessLog] = useState();

  const idFurnished = 8147151116452981;
  const idUnderConstruction = 1930369337545538;
  const idRenovated = 7102595495777344;

  const history = useHistory();
  let userSession = getUserSession();

  const getZapIntegrationLink = () => {
    const zapIntegrationUrl = process.env.REACT_APP_ZAP_INTEGRATION_URL;
    return `${zapIntegrationUrl}/${+userSession.sub}.xml`;
  }

  const zapAdsTabs = [
    {
      key: 'InformationView',
      label: 'Informações',
      children: <InformationView data={propertyData} />,
    },
    {
      key: 'AdAboutTabView',
      label: 'Sobre',
      children: <AdAboutTabView data={propertyData} />,
    },
    {
      key: 'AdValuesTab',
      label: 'Valores',
      children: <AdValuesView data={propertyData} />,
    },
    {
      key: 'AdDescriptionFull',
      label: 'Descrição do anúncio',
      // icon: <Badge count={countZapAdsErrors} />,
      children: <AdDescriptionFull
        data={propertyData}
        isAdEdit={false}
        features={features}
      />,
    },
  ];

  const itemsVerifyAds = [
    {
      key: '1',
      label: 'Informações',
      children: <InformationTabAds
        propertyData={propertyData}
        commercialDistrict={commercialDistrict}
        setCommercialDistrict={setCommercialDistrict}
        valueTitleAds={valueTitleAds}
        setValueTitleAds={setValueTitleAds}
      />,
    },
    {
      key: '2',
      label: 'Sobre',
      children: <AdAboutTab
        data={propertyData}
        constructionInProgress={constructionInProgress}
        setConstructionInProgress={setConstructionInProgress}
        propertyStatusInProgress={propertyStatusInProgress}
        setPropertyStatusInProgress={setPropertyStatusInProgress}
        propertyRenovated={propertyRenovated}
        setPropertyRenovated={setPropertyRenovated}
        propertyFurnished={propertyFurnished}
        setPropertyFurnished={setPropertyFurnished}
        propertyFurnishedStatus={propertyFurnishedStatus}
        setPropertyFurnishedStatus={setPropertyFurnishedStatus} />,
    },
    {
      key: '3',
      label: 'Características',
      children: <AdCharacteristicsTab
        features={features}
        data={propertyData}
        dataPropertyFeatures={dataPropertyFeatures}
        propertyEdit={true}
        setPropertyFeatures={setPropertyFeatures} />,
    },
    {
      key: '4',
      label: 'Descrição',
      children: <AdDescriptionEdit data={adData} setPropertyDescription={setPropertyDescription} />,
    },
    {
      key: '5',
      label: 'Valores',
      children: <AdValuesTab data={propertyData} />,
    },
  ]

  const handleCopyClick = () => {
    const textToCopy = getZapIntegrationLink();
    // Cria um elemento de input dinamicamente
    const inputElement = document.createElement('input');
    // Atribui o texto que será copiado ao valor do input
    inputElement.value = textToCopy;
    // Adiciona o input ao DOM (é necessário estar no DOM para copiar)
    document.body.appendChild(inputElement);
    // Seleciona todo o texto dentro do input
    inputElement.select();
    // Executa o comando de cópia
    document.execCommand('copy');
    // Remove o input do DOM
    document.body.removeChild(inputElement);

    // Atualiza o estado para indicar que o texto foi copiado
    setOpenPopupCopy(true);
    // Define um tempo limite para resetar o estado após um curto período de tempo
    setTimeout(() => {
      setOpenPopupCopy(false)
    }, 1200);
  };


  function closeModalZap(refresh = false) {
    cleanStatesModalZap();
    setModalZap(false);
    if (refresh === true) {
      getPropertiesAdvertisement()
        .then(({ data }) => {
          setPropertyZap(data);
        });
    }
  }

  function closeModalBindingZap() {
    setNextViewModalBinding('start');
    setModalBindingZap(false);
  }

  function cleanStatesModalZap() {
    setCheckboxMarkedModal(false);
    setConstructionInProgress(false);
    setPropertyStatusInProgress('');
    setPropertyRenovated(false);
    setPropertyFurnished(false);
    setPropertyFurnishedStatus('');
    setCommercialDistrict('');
    setPropertyFeatures([]);
    setPropertyDescription('');
    setPropertyDescriptionSuggest('');
  }

  function closePopoverZapBinding() {
    setOpenPopoverZapBinding(null);
  }

  useEffect(() => {
    getZapFileAccessLog();
    getFeatures()
      .then(({ data }) => {
        setFeatures(data);
      })
    getPropertiesAdvertisement()
      .then(({ data }) => {
        setPropertyZap(data);
      });
  }, [])

  useEffect(() => {
    if (nextViewModalBinding === 'alertZap') {
      setTitleCheckbox('Estou ciente');
    }

    if (nextViewModalBinding === 'linkZap') {
      setTitleCheckbox('Já inseri minha URL de integração no Canal PRO');
    }
  }, [nextViewModalBinding])

  //  async function getFeatures() {
  //     const response = await api
  //       .get(`/feature`)
  //       .catch((err) => console.error(err));

  // }

  function resultFieldText(arrayFieldError) {
    let resultError = '';

    if (arrayFieldError.length > 2) {
      resultError += `${arrayFieldError[0]}, ${arrayFieldError[1]} e ${arrayFieldError[2]}`
    }

    if (arrayFieldError.length === 2) {
      resultError += `${arrayFieldError[0]} e ${arrayFieldError[1]}`
    }

    if (arrayFieldError.length === 1) {
      resultError += arrayFieldError[0];
    }

    return resultError;
  }

  // function validateModalEdit() {
  //   let arrayFieldError = [];

  //   if (valueTitleAds === '') {
  //     arrayFieldError.push('titulo do anúncio');
  //   }

  //   if (!propertyFeatures) {
  //     arrayFieldError.push('característica do imóvel');
  //   }

  //   if (validateContent(propertyDescription)) {
  //     arrayFieldError.push('descrição');
  //   }

  //   if (arrayFieldError.length !== 0) {
  //     const messageError = `Erro: Os seguintes campos são obrigatórios - ${resultFieldText(arrayFieldError)}. Por favor, forneça as informações necessárias.`

  //     notification.error({ message: messageError });
  //   } else {
  //     editProperty();
  //   }

  // }

  async function editProperty(val) {
    const request = {
      idProperty: +dataSelected.property?._id,
      ...val,
    };

    setLoading(true);

    try {
      await api.put(`/property-zap-advertisement/${dataSelected._id}`, request);

      advertisementTutorial();
      setLoading(false);
      setTitleButtonModal('Voltar para a listagem')
      setNextViewModalPrepare('success');
    } catch (e) {
      setLoading(false);
      openNotificationWithIcon();
    }
  }

  const openNotificationWithIcon = () => {
    notification.error({ message: `Erro: Imóvel já foi inserido a Zap Móveis. Por favor, insira outro imóvel!` });
  };

  useEffect(() => {
    setTitleButtonModal('Salvar')
    setNextViewModalPrepare('verify');

    //  if(modalZap) {
    // const featuresSelect = dataPropertyZap.property?.features.map((item) => {
    //   if (features?.some(feature => feature.displayName.trim() === item.name.trim())) {
    //     return { value: item.name };
    //   } else {
    //     return null
    //   }
    // }).filter(Boolean);

    // setPropertyFeatures(featuresSelect);
    // }
  }, [modalZap])

  const changeViewModalPrepare = () => {
    if (nextViewModalPrepare === 'initial') {
      setTitleButtonModal('Salvar');
      setNextViewModalPrepare('verify');
    }


    if (nextViewModalPrepare === 'verify') {
      setTitleButtonModal('Ir para o Gerenciamento');
      setNextViewModalPrepare('success');
    }
  }

  // const validationNextModal = () => {
  //   if (nextViewModalPrepare === 'verify') {
  //     return validateModalEdit();
  //   }

  //   if (nextViewModalPrepare === 'success') {
  //     closeModalZap();
  //   }

  //   return changeViewModalPrepare();
  // }

  const validationNextModalBinding = () => {
    switch (nextViewModalBinding) {
      case 'alertZap':
        setNextViewModalBinding('loginZap')
        break;
      case 'loginZap':
        setNextViewModalBinding('configZap')
        break;
      case 'configZap':
        setNextViewModalBinding('configZapIntegrate')
        break;
      case 'configZapIntegrate':
        setNextViewModalBinding('integrateNameZap')
        break;
      case 'integrateNameZap':
        setNextViewModalBinding('linkZap')
        break;
      case 'linkZap':
        setNextViewModalBinding('successZap')
        break;
      case 'successZap':
        closeModalBindingZap();
        break;
    }
  }

  async function advertisementTutorial() {
    await api
      .patch(`/user/advertisement-tutorial`, { checkboxMarkedModal })
      .catch((err) => console.error(err));

    await refreshTokenCallback(true);
    const dados = getUserSession();
  }

  async function actionProperty(id, status) {
    setLoading(true);
    const res = await api
      .patch(`/property-zap-advertisement/${id}/status`, { status })
      .then(() => {
        const activeStatus = status.trim() === 'Ativo' ? 'inactive' : 'active';

        setLoading(false);
        notification.success({
          message: `Anúncio alterado com sucesso!`,
        });

        getPropertiesAdvertisement()
          .then(({ data }) => {
            setPropertyZap(data);
          });
      })
      .catch(({ response }) => {

        setLoading(false);
        notification.error({
          message: response.data.error,
        });
      });
  }

  async function deleteProperty(id) {
    setLoading(true);
    await api
      .delete(`/property-zap-advertisement/${id}`)
      .catch(({ response }) => {

        setLoading(false);
        notification.error({
          message: response.data.error,
        });
      });
    setLoading(false);

    notification.success({
      message: 'Anúncio deletado com sucesso!',
    });

    getPropertiesAdvertisement()
      .then(({ data }) => {
        setPropertyZap(data);
        setLoading(false);
      });
  }

  async function getAdById(id) {
    setLoading(true);
    const { data } = await api
      .get(`/property-zap-advertisement/${id}`)
      .catch((err) => {
        setLoading(false);
      });

    setAdData(data);

    setDataPropertyFeature(data?.propertyFeatures);
    setPropertyData(data.property);

    setValueTitleAds(data.title);

    setCommercialDistrict(data?.commercialDistrict);
    setPropertyDescription(data?.description);
    setPropertyDescriptionSuggest(data?.descriptionSuggestion);

    let validateIsConstructionInProgress = data?.constructionInProgress;
    let validateIsPropertyFurnished = data?.furnished;
    let validateIsPropertyRenovated = data?.renovated;

    setConstructionInProgress(validateIsConstructionInProgress);
    setPropertyFurnished(validateIsPropertyFurnished);
    setPropertyRenovated(validateIsPropertyRenovated);
    setPropertyFurnishedStatus(data?.propertyFurnishedStatus);
    setPropertyStatusInProgress(data?.propertyStatusInProgress);
    setLoading(false);
    setModalZap(true);

  }
  async function getZapFileAccessLog() {
    const { data } = await api
      .get(`/property-zap-advertisement/zap-file-access-log`)
      .catch((err) => {
        setLoading(false);
      });

    setDataZapFileAccessLog(data);
  }

  async function openModalZapEdit(dataZap) {
    setDataSelected(dataZap);
    await getAdById(dataZap._id);
  }

  const hide = () => {
    setOpenPopoverDownloadList(null);
  };

  const integratorLinkDisclaimer = 'Link integrador é atualizado a cada hora. Desta forma, se seu anúncio foi preparado dentro deste intervalo, somente será incorporado ao link no próximo horário.';

  const [currentTabModalZap, SetCurrentTabModalZap] = useState(zapAdsTabs[0].key);

  const nextModal = (val) => {
    if (nextViewModalPrepare === 'initial') {
      changeViewModalPrepare();
    }
    else if (currentTabModalZap === zapAdsTabs[zapAdsTabs.length - 1].key) {
      editProperty(val);
    }
    else {
      const index = zapAdsTabs.findIndex(item => item.key === currentTabModalZap);
      SetCurrentTabModalZap(zapAdsTabs[index + 1]?.key)
    }


  };

  return (
    <>
      {loading && <Loading />}
      {modalZap &&
        <>
          <ModalZap
            open={modalZap}
            redirectProperty={closeModalZap}
            showButtonOtherProperties={false}
            closeModal={closeModalZap}
            nextModal={nextModal}
            activeKey={currentTabModalZap}
            adData={adData}
            propertyData={propertyData}
            advertisementTutorial={setCheckboxMarkedModal}
            viewModalPrepare={nextViewModalPrepare}
            titleButtonPrimary={titleButtonModal}
            features={features}
            advertisementRemember={userSession.advertisementInformationSeen}
          >
            {nextViewModalPrepare == 'initial' &&
              <PrepareAds />
            }
            {nextViewModalPrepare == 'verify' &&
              <>
                <S.ContainerVerifyPrepareAds>
                  <S.ContentImgVerifyPrepareAds>
                    {/* <div className="content-back" onClick={() => setNextViewModalPrepare('initial')}>
                            <img src={ArrowLeft} alt="Arrow Left" />
                            <span className="title-back">Voltar</span>
                          </div> */}
                    <img src={ImageVerifyPrepared} alt="Img Success" />
                  </S.ContentImgVerifyPrepareAds>
                  <S.ContentInformationVerifyPrepareAds>
                    <Tabs defaultActiveKey={zapAdsTabs[0].key} activeKey={currentTabModalZap} className="custom-tab" onChange={(activeKey) => SetCurrentTabModalZap(activeKey)} items={zapAdsTabs} />
                  </S.ContentInformationVerifyPrepareAds>
                </S.ContainerVerifyPrepareAds>
              </>
            }
            {nextViewModalPrepare == 'success' &&
              <SuccessPropertyPrepared />
            }
          </ModalZap>
        </>
      }
      {modalBindingZap &&
        <>
          <ModalZapBinding
            open={modalBindingZap}
            showFooter={nextViewModalBinding !== 'createZap'}
            showButton={nextViewModalBinding !== 'start'}
            showCheckbox={nextViewModalBinding === 'alertZap' || nextViewModalBinding === 'linkZap'}
            closeModal={closeModalBindingZap}
            nextModal={validationNextModalBinding}
            advertisementTutorial={setCheckboxMarkedModal}
            viewModalPrepare={nextViewModalPrepare}
            titleCheckbox={titleCheckbox}
          >
            {nextViewModalBinding === 'start' &&
              <StartBindingZap setNextViewModalBinding={setNextViewModalBinding} />
            }

            {nextViewModalBinding === 'createZap' &&
              <CreateZapAccount setNextViewModalBinding={setNextViewModalBinding} />
            }

            {nextViewModalBinding === 'alertZap' &&
              <InformationAlert setNextViewModalBinding={setNextViewModalBinding} />
            }

            {nextViewModalBinding === 'loginZap' &&
              <LoginZap setNextViewModalBinding={setNextViewModalBinding} />
            }

            {nextViewModalBinding === 'configZap' &&
              <ConfigZap titleNameConfig="Configurações de conta" showImageConfigZapAccount={true} setNextViewModalBinding={setNextViewModalBinding} />
            }

            {nextViewModalBinding === 'configZapIntegrate' &&
              <ConfigZap titleNameConfig="Integração de Anúncios" showImageConfigZapAccount={false} setNextViewModalBinding={setNextViewModalBinding} />
            }

            {nextViewModalBinding === 'integrateNameZap' &&
              <IntegratorNameZap setNextViewModalBinding={setNextViewModalBinding} />
            }

            {nextViewModalBinding === 'linkZap' &&
              <LinkZap setNextViewModalBinding={setNextViewModalBinding} />
            }

            {nextViewModalBinding === 'successZap' &&
              <SuccessZap />
            }
          </ModalZapBinding>
        </>
      }
      <Header />
      <Aside />
      <S.Container>
        <S.Content>
          <S.ContentTitle>
            <div className="bread-crumb">
              <h1 className="title-list">ZAP Imóveis (Canal PRO)</h1>
              <S.TagStatusList isAccessLogZap={dataZapFileAccessLog?._id}>
                <div className="indicate"></div>
                {dataZapFileAccessLog?._id ? 'Vinculado' : 'Não Vinculado'}
              </S.TagStatusList>
            </div>
            <div className="content-button-zap">
              {/* <Popover
                trigger="click"
                content={
                  <S.ContainerPopoverBindingZap>
                    <span className="popover-text-binding">
                      O serviço de vinculação é uma nova funcionalidade. 
                      No momento ainda não está disponível para uso.
                    </span>
                  </S.ContainerPopoverBindingZap>
                }
                placement="bottom"
                open={openPopoverZapBinding}
                onOpenChange={closePopoverZapBinding}
              >
                <InfoCircleOutlined onClick={() => setOpenPopoverZapBinding(true)} />
              </Popover> */}
              <button className="zap-linking" onClick={() => setModalBindingZap(true)}>
                Vinculação ZAP
                <img src={IconSetting} alt="Icon Setting" />
              </button>
            </div>
          </S.ContentTitle>
          <S.ContentInformation>
            <img src={lampOn} alt="lamp On" />
            <span className="title-information">Seus anúncios ainda não estão sendo divulgados na ZAP Imóveis. Vá em <strong>vinculação zap</strong> e entenda como.</span>
          </S.ContentInformation>
          <S.ContentList>
            <TableWithSearch
              dataPropertyZap={dataPropertyZap}
              actionProperty={actionProperty}
              deleteProperty={deleteProperty}
              openModalZapEdit={openModalZapEdit}
            >
              <S.ContentTitleTable>
                <Popover
                  content={
                    <S.ContainerPopover>
                      <S.ContentField>
                        <S.LabelField>Link Integrador
                        </S.LabelField>

                        <S.ContentFieldLink>
                          <S.Field
                            type='text'
                            readOnly
                            value={getZapIntegrationLink()}
                          />
                          <Popover
                            content={
                              <S.ContentTitlePopupCopy>
                                <S.TitlePopupCopy>Copiado com Sucesso!</S.TitlePopupCopy>
                              </S.ContentTitlePopupCopy>
                            }
                            trigger="click"
                            open={openPopupCopy}
                          >
                            <img src={CopyIcon} alt="Copy Icon" onClick={handleCopyClick} />
                          </Popover>
                        </S.ContentFieldLink>
                      </S.ContentField>
                      <S.ContentHR>
                        <hr />
                        <span>ou</span>
                        <hr />
                      </S.ContentHR>
                      <S.ContentTitleDownloadList>
                        <span className="title-download" onClick={() => window.open(getZapIntegrationLink(), '_blank')}>
                          Baixar lista (xml)
                          <img src={DownloadIcon} alt="Download Icon" />
                        </span>
                      </S.ContentTitleDownloadList>
                    </S.ContainerPopover>
                  }
                  trigger="click"
                  placement="bottomRight"
                  open={openPopoverDownloadList}
                  onOpenChange={hide}
                >
                  <button onClick={() => setOpenPopoverDownloadList(true)}>
                    Baixar ou integrar lista
                    <img src={ArrowDown} alt="Arrow Down" />
                  </button>
                </Popover>
                <Popover
                  placement="bottomRight"
                  title={<div style={{ width: '250px', padding: '10px 10px 0' }}>Atenção!</div>}
                  content={<div style={{ width: '250px', padding: '0 10px 10px' }}>{integratorLinkDisclaimer}</div>}
                ><ExclamationCircleOutlined style={{ marginLeft: 5, color: '#0088AC', fontSize: '1.25rem' }} />
                </Popover>
              </S.ContentTitleTable>
            </TableWithSearch>
          </S.ContentList>
        </S.Content>
      </S.Container>
    </>
  );
}
