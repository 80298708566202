import styled from "styled-components";
import { Table, Popconfirm } from 'antd';

export const TableZap = styled(Table)`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #F1F3F9;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

  .property-column {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .title-property {
    color: #3B414A;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .title-state {
    color: #8C8C8C;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .icon-more {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`

export const TagStatusList = styled.li`
    border-radius: 16px;
    border: 1px solid ${props => props.isActive ? '#0074E8' : '#6E6893'};
    background: ${props => props.isActive ? '#F7FBFF' : '#F2F0F9'};;
    display: flex;
    padding: 6px 10px;
    align-items: center;
    gap: 6px;
    width: fit-content;

    color: ${props => props.isActive ? '#0074E8' : '#6E6893'};;
    text-align: right;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .indicate {
     width: 6px;
     height: 6px;
     border-radius: 50%;
     background-color: ${props => props.isActive ? '#0074E8' : '#6E6893'};;
    }
`

export const ContainerPopover = styled.div`
  display: flex;
  width: 281px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  background: #FFF;
`

export const ContentPopover = styled.div`
  display: flex;
  padding: 15px 13px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;

  &:hover{
    border: 1px solid #0088AC;
    border-radius: 6px;
  }

  &:hover > span,
  &:hover > svg {
    color: #0088AC !important;
    stroke: #0088AC !important;
  }

`

export const ContainerPopconfirm = styled(Popconfirm)`
  padding: 20px !important;
`