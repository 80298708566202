import styled from 'styled-components'
import BgImage from './background-card.svg'
import BgCard from './background-card-footer.svg'

export const BgImg = styled.div`
    width:100vw;
    background: white;
`

export const CardBottom = styled.div`
        height: 276px;
        width: 90%;
        margin-top: 140px;
        background : url(${BgCard});
        background-size:cover;
        background-position:left;
        background-repeat:no-repeat;
        margin-left: 5%;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div{
            height: 60%;
            width: 60%;
            background: rgba(255, 255, 255, 0.6);
            backdrop-filter: blur(30px);
            border-radius: 15px;
            display: flex;  
            flex-direction: column;
            justify-content: center;
            align-items: center;

            padding-left: 20px;
            padding-right: 20px;
        }

        h1{
            font-size: 40px;
            margin-bottom: 10px;
        }

        h3{
            font-size: 20px;
            font-weight: 400;
            text-align: center;
        }

    @media(max-width:600px){
        div{
            width: 80%;
            height: 80%;
        }

        h1{
            text-align: center;
            font-size: 28px;
        }

        h3{
            font-size: 15px;
        }
    }
`

export const ContainerQuestion = styled.div`
    margin-top: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 50px;

    .context-questions{
        width: 65%;
        min-height: 700px;

        h1{
            font-size: 33px;
            margin-top: 30px;
        }

        .container-questions{
            margin-top: 20px;
            padding-bottom: 20px;
            font-size: 29px;
            min-height: 60px;
            cursor: pointer;
            width: 98%;
            border-bottom: 2px solid #ddd;

            .pergunta{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items:center;
                width: 97%;

                h4{
                    width: 97%;
                    font-size: 23px;
                    font-weight: 300;
                }

                img{
                    height: 8px;
                    width: 31px;
                }
            }

            .resposta{
                display: none;
                margin-top: 15px;
                font-size: 18px;
                font-weight: 300;
                width: 97%;
            }

        }



















    }

    .context-contact{
        border: 1px solid #eee;
        width: 25%;
        height: 737px;
        border-radius: 15px;
        box-shadow: 0px 2px 3px #ddd, 0px 4px 19px #ddd;
        display: flex;
        flex-direction: column;
        
        h1{
            font-size: 33px;
            margin-left: 5%;
            margin-top: 30px;
        }

        .phone{
            display: flex;
            margin-top: 15px;
            padding-left:5%;
            font-size: 18px;
            
            img{
                padding-right: 20px;
            }
        }
        .email{
            display: flex;
            margin-top: 15px;
            padding-left:5%;
            font-size: 18px;
            img{
                padding-right: 20px;
            }
        }

        .input-box{
            background-color: #eee;
            border-radius: 5px;
            height: 60px;
            width: 90%;
            margin-left:5%;
            margin-top: 20px;
            padding-left: 10px;
            padding-right: 15px;
            padding-top: 3px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-evenly;

            small{
                font-weight: 500;
                                        
            }

            input, select, textarea{
                background-color: transparent;
                border: transparent;
                border-bottom: 2px solid #ccc;
                width: 100%;
            }
            
        }

        .max{
            height: 150px;
            textarea{
                height: 80%;
                resize: none;
            }
        }
        .btnSend{
            background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
            border-radius: 4px;
            color: white;
            font-size: 22px;
            width: 90%;
            font-weight: 400;
            cursor: pointer;
            margin-left: 5%;
            margin-top: 30px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .btnSend:hover{
            color: #EA5C1E;
            background: white;
            border: 2px solid #EA5C1E;
        }
    }
    
    @media(max-width:600px){
        flex-direction: column-reverse;
        
        .context-questions{
            margin-left: 5%;
            width: 90%;
        }

        .context-contact{
            width: 100%;
        }
    }
`