import Axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function Download({ id, images}) {

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('url');
        const image_name = urlParams.get('image_name');
        if(myParam && !image_name){
            handleDownloadOld(myParam)

            setTimeout(() => {
                window.close()
            }, 8000);
        }else if(myParam && image_name){
            handleDownloadNew(myParam, image_name)

            setTimeout(() => {
                window.close()
            }, 8000);
        }else{
            window.location.href='/'
        }
        
    }, [])

    async function handleDownloadNew(url, name) {
            Axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
             }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
             });
    }

    async function handleDownloadOld(url){
        var item = {
            url: `${url}`
        }
        
        fetch(item.url).then(async (result) =>{
            var blob = await result.blob();// recuperandoo um blob para baixar
            var anchor = window.document.createElement('a');
            anchor.href = window.URL.createObjectURL(blob);
            anchor.download = item.url.split('/').pop();
            anchor.click();
            window.URL.revokeObjectURL(anchor.href);
        });
    }

    return <></>
}
