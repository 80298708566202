import React, { useEffect, useState } from 'react';
import { Button, Modal, Steps, notification, Spin } from 'antd';
import IdentityStep from './identityStep';
import VerificationStep from './verificationStep';
import ChangePasswordStep from './changePasswordStep';
import { Content, Footer, Header } from './styles';
import FormContext from './_context';
import { useForm } from 'react-hook-form';
import { ACTION_STATE } from './helper';

export default function PasswordRecovery({ HandlerModal, showModal }) {

  const [currentStatus, setCurrentStatus] = useState(ACTION_STATE.await);
  const [userData, setUserData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    { title: 'Identificação', header: 'Dados para recuperação', component: <IdentityStep /> },
    { title: 'Verificação', header: 'Verifique o código enviado', component: <VerificationStep />, },
    { title: 'Alteração', header: 'Cadastre a nova senha', component: <ChangePasswordStep /> },
  ];

  const { control, register, formState, handleSubmit, reset } = useForm({});
  const { errors } = formState;

  const isFinished = () => currentStep === steps.length - 1;
  const previousStep = () => setCurrentStep(currentStep - 1);
  const nextStep = () => {
    if (isFinished()) {
      reset();
      resetData();
      HandlerModal(false);
    }
    else {
      setCurrentStep(currentStep + 1);
    }
  }

  const canGoToThePreviousStep = () => ![steps.length - 1, 0].includes(currentStep);
  const isProcessing = () => formState.isSubmitting || formState.isValidating || currentStatus !== ACTION_STATE.await;

  const resetData = () => {
    setUserData({});
    setCurrentStep(0);
  };

  useEffect(() => resetData(), []);

  const onSubmit = (data) => {
    setCurrentStatus(ACTION_STATE.process);
  };

  useEffect(() => {
    if (ACTION_STATE.finish === currentStatus) {
      nextStep();
      setCurrentStatus(ACTION_STATE.await);
      reset(userData);
    }

  }, [currentStatus]);

  const openNotification = (content, notificationType) => {
    notification[notificationType.type]({
      message: notificationType.message,
      description: content,
    });
  };

  return showModal &&
    <FormContext.Provider value={{
      control, register, errors, formState, userData, currentStep, currentStatus,
      setData: (data) => setUserData({ ...userData, ...data }),
      setStatus: (value = ACTION_STATE.await) => setCurrentStatus(value),
      openNotification
    }}>
      <Modal
        footer={null}
        maskClosable={false}
        onCancel={() => HandlerModal(false)}
        open={showModal}
        title="Recuperar senha"
      >
        <Steps items={steps} size='small' current={currentStep} />
        <Header>{steps[currentStep].header}</Header>
        <form onSubmit={handleSubmit(onSubmit)} >
          <Content>{steps[currentStep].component}</Content>
          <Footer>
            {canGoToThePreviousStep() && <Button className='default' onClick={previousStep}>Voltar</Button>}
            {
              isProcessing()
                ? <Button className='default' disabled={true}>Enviando... &nbsp; <Spin size="small" /></Button>
                : <input type="submit" className='primary' />
            }
          </Footer>
        </form>
      </Modal>
    </FormContext.Provider>
}