// Core
import React from "react";

// Components
import InputMask from "react-input-mask";

// Utils
import { Andar, Posicao } from "../../../offerSolicitation/utils";

const DefaultForm = React.memo(function DefaultForm({
  posicao,
  metragem,
  setMetragem,
  money,
  condominio,
  setCondominio,
  setPosicao,
  andar,
  setAndar,
  setQuartos,
  quartos,
  banheiros,
  setBanheiros,
  suites,
  setSuites,
  dependencias,
  setDependencias,
  vagas,
  setVagas,
  moneyToNumber,
  escritura,
  setEscritura,
}) {
  return (
    <div className="step">
      <div className="container-input-box">
        <div className="input-box" required id="metragem">
          <small>Metragem no IPTU (m2) *</small>
          <InputMask
            name="metragem"
            min={0}
            type="number"
            onKeyDown={(e) => (e.key == "-" ? e.preventDefault() : "")}
            onChange={(text) => setMetragem(text.target.value)}
            value={metragem}
          />
        </div>
        <div className="input-box" required id="condominio">
          <small>Valor do Condomínio *</small>
          <InputMask
            name="condominio"
            type="text"
            value={money(condominio)}
            onChange={(text) => setCondominio(moneyToNumber(text.target.value))}
          />
        </div>
      </div>
      <div className="container-input-box">
        <div className="input-box autoselect" id="posicao">
          <small>Posição</small>
          <select
            className={posicao === "" ? "default-option" : null}
            onChange={(text) => setPosicao(text.target.value)}
            value={posicao}
          >
            <option className="option" value="">
              Selecione
            </option>
            {Posicao.list.map((val) => {
              return (
                <option className="option" value={val.value}>
                  {val.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="input-box autoselect" id="andar">
          <small>Andar</small>
          <select
            className={andar === "" ? "default-option" : null}
            onChange={(text) => setAndar(text.target.value)}
            value={andar}
          >
            <option className="option" value=''>
              Selecione
            </option>
            {Andar.list.map((val) => {
              return (
                <option className="option" value={val.value}>
                  {val.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="container-input-box">
        <div className="input-box" required id="quartos">
          <small>Número de quartos (inclui suítes) *</small>
          <InputMask
            name="quartos"
            min={0}
            type="number"
            onKeyDown={(e) => (e.key == "-" ? e.preventDefault() : "")}
            value={quartos}
            onChange={(text) => setQuartos(text.target.value)}
          />
        </div>
        <div className="input-box">
          <small>Número de suites</small>
          <InputMask
            min={0}
            type="number"
            onChange={(text) => setSuites(text.target.value)}
            onKeyDown={(e) => (e.key == "-" ? e.preventDefault() : "")}
            value={suites}
          />
        </div>
      </div>
      <div className="container-input-box">
        <div className="input-box" required id="banheiros">
          <small>Número de banheiros (exceto de serviço) *</small>
          <InputMask
            name="banheiros"
            min={0}
            type="number"
            onKeyDown={(e) => (e.key == "-" ? e.preventDefault() : "")}
            value={banheiros}
            onChange={(text) => setBanheiros(text.target.value)}
          />
        </div>
        <div className="input-box" id="dependencias">
          <small>Número de dependências de serviço </small>
          <input
            min={0}
            type="number"
            value={dependencias}
            onKeyDown={(e) => (e.key == "-" ? e.preventDefault() : "")}
            onChange={(text) => setDependencias(text.target.value)}
          />
        </div>
      </div>
      <div className="container-input-box">
        <div className="input-box" id="vagas">
          <small>Número de Vagas?</small>
          <InputMask
            min={0}
            type="number"
            value={vagas}
            onKeyDown={(e) => (e.key == "-" ? e.preventDefault() : "")}
            onChange={(text) => setVagas(text.target.value)}
          />
        </div>
        <div
          className="input-box"
          style={{ backgroundColor: "transparent", marginLeft: 0 }}
        >
          <label class="control control-checkbox" style={{ marginLeft: -10 }}>
            <small style={{ fontSize: 16, fontWeight: 400 }}>
              Vaga na escritura
            </small>
            <input
              checked={escritura}
              type="checkbox"
              onChange={(evt) => setEscritura(evt.target.checked)}
            />
            <div className="control_indicator"></div>
          </label>
          <br />
          <small style={{ fontSize: 16, fontWeight: 400, marginLeft: -10 }}>
            * Campos obrigatórios
          </small>
        </div>
      </div>
    </div>
  );
});

export default DefaultForm;
