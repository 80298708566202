import React, { useEffect, } from 'react';
import PlaceField from '../../components/forms/PlaceField';
import { Field, UploadButtonContainer } from './styles';
import UploadButton from '../../components/UploadButton';
import { Col, Input, Row } from 'antd';
import { useFormContext, Controller } from 'react-hook-form';
import { checkErrorStyle } from './constants';
import * as yup from 'yup';

const downloadUrl = process.env.REACT_APP_FILES_URL;
const gridProps = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
  xxl: 24,
};

const gridParentProps = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 12,
};

const props = {
  addField: (val) => console.warn('addField not implemented', val),
};

const Documentation = ({ addField } = props) => {

  const {
    control,
    getValues,
    setValue,
    formState: { isSubmitted, },
  } = useFormContext();

  const id = getValues('_id');

  useEffect(() => {
    return addField(['name', 'fullAddress', 'video']);
  }, []);

  const validateAddress = () => {
    const { place, fullAddress } = getValues();

    return !fullAddress ? 'Informe o endereço'
      : !place?.addressNumber ? 'Número não informado'
        : place?.formatted_address ? undefined : 'Endereço inválido'
  };

  return <>
    <Row {...{ justify: 'space-between', gutter: 16, }}>
      <Col {...gridParentProps}>
        <Row {...{ justify: 'space-between', gutter: 16, }}>
          <Col {...gridProps}>
            <Controller
              control={control}
              name={`name`}
              rules={{
                required: 'Informe o nome do empreendimento',
                maxLength: {
                  value: 50,
                  message: 'Máximo de 50 caracteres',
                },
                minLength: {
                  value: 3,
                  message: 'Mínimo de 3 caracteres',
                },
              }}
              render={({ field, fieldState: {
                invalid, isDirty, isTouched, error,
              }, }) => (
                <Field {...checkErrorStyle({
                  invalid, isDirty, isTouched, isSubmitted, error,
                })} className="input-box">
                  <small>Empreendimento</small>
                  <Input {...field} />
                </Field>
              )}
            />
          </Col>

          <Col {...gridProps}>
            <Controller
              control={control}
              name={`fullAddress`}
              rules={{
                validate: validateAddress,
              }}
              render={({ field, fieldState: {
                invalid, isDirty, isTouched, error,
              }, }) => (
                <Field {...checkErrorStyle({
                  invalid, isDirty, isTouched, isSubmitted, error,
                })} className="input-box">
                  <PlaceField style={{ width: '100%', }}
                    isSeparatedFields={false}
                    className="input-compose"
                    stateValue={field.value}
                    setValue={(value) => field.onChange(value)}
                    placeData={getValues('place')}
                    setPlace={(value) => {
                      if (value.formatted_address) {
                        field.onChange(value.formatted_address);
                        setValue('place', value);
                      }
                    }}
                  />
                </Field>
              )}
            />
          </Col>

          <Col {...gridProps}>
            <Controller
              control={control}
              name={`video`}
              rules={{
                validate: (val) => yup.string().url().required().isValidSync(val) || 'Insira um link válido!',
              }}
              render={({ field, fieldState: {
                invalid, isDirty, isTouched, error,
              }, }) => (
                <Field {...checkErrorStyle({
                  invalid, isDirty, isTouched, isSubmitted, error,
                })} className="input-box">
                  <small>Link do Vídeo</small>
                  <Input {...field} />
                </Field>
              )}
            />
          </Col>
        </Row>

      </Col>
      {id && <Col {...gridParentProps}>
        <Row {...{ justify: 'space-between', gutter: 16, }}>
          <Col {...gridProps}>
            <UploadButtonContainer>
              <UploadButton title='Book' method='patch' downloadUrl={downloadUrl} uploadUrl={`real-estate-development/${id}/upload/book`} files={[{ link: getValues('book') }]} handler={(val) => setValue('book', val?.key)} multiple={false} />
            </UploadButtonContainer>
          </Col>

          <Col {...gridProps}>
            <UploadButtonContainer>
              <UploadButton title='Memorial' method='patch' downloadUrl={downloadUrl} uploadUrl={`real-estate-development/${id}/upload/memorial`} files={[{ link: getValues('memorial') }]} handler={(val) => setValue('memorial', val?.key)} multiple={false} />
            </UploadButtonContainer>
          </Col>

          <Col {...gridProps}>
            <UploadButtonContainer>
              <UploadButton title='Quadro de disponibilidade' method='patch' downloadUrl={downloadUrl} uploadUrl={`real-estate-development/${id}/upload/availabilityTable`} files={[{ link: getValues('availabilityTable') }]} handler={(val) => setValue('availabilityTable', val?.key)} multiple={false} />
            </UploadButtonContainer>
          </Col>

        </Row >

      </Col>}
    </Row>
  </>;
};


export default Documentation;
