import React, { useEffect, useState } from "react";

import { BgImg, Container, FormContainer, TableContainer } from "./style";
import Header from "../../components/header";
import Footer from "../../components/Footer";
import api from "../../api";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FinancialTransferInfo from "../../components/FinancialTransferInfo";
import { notification } from "antd";
import { getUserId } from "../../utils/sessionHelper";

function CBanking() {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [step, setStep] = useState(1);

  const [regime, setRegime] = useState("CLT");
  const [FGTS, setFGTS] = useState(true);
  const [imovelNovo, setImovelNovo] = useState(false);
  const [imovelProprio, setImovelProprio] = useState(true);

  const [dataN, setDataN] = useState("0000-00-00");
  const [compra, setCompra] = useState("");
  const [renda, setRenda] = useState("");
  const [financiamento, setFinanciamento] = useState("");

  useEffect(() => {
    if (getUserId()) setStep(2);
  }, []);

  async function handleLogin() {
    if (email.length > 0 && senha.length > 0) {
      const { data } = await api.post("/user/login", {
        email: email,
        password: senha,
      });
      if (data._id) {
        localStorage.setItem("id", data._id);
        setStep(2);
      } else {
        notification.error({
          message: 'Usuario ou senha incorreto'
          });
      }
    }
  }
  async function handleSend() {
    let campos = document.querySelectorAll(".input-box");
    if (dataN.length == 10) {
      campos[0].style.border = "0px solid red";

      if (
        renda &&
        renda != "." &&
        renda != ".00" &&
        compra &&
        compra != "." &&
        compra != ".00" &&
        financiamento &&
        financiamento != "." &&
        financiamento != ".00"
      ) {
        campos[1].style.border = "0px solid red";
        campos[2].style.border = "0px solid red";
        campos[3].style.border = "0px solid red";

        let dateFormated =
          dataN.split("-")[2] +
          "/" +
          dataN.split("-")[1] +
          "/" +
          dataN.split("-")[0];
        const { data } = await api.post("/banking", {
          id: getUserId(),
          dateBorn: dateFormated,
          rendaBruta: renda,
          valorCompra: compra,
          contratacao: regime,
          financiamento,
          FGTS,
          createdAt:
            new Date().toLocaleDateString("pt-BR", { day: "2-digit" }) +
            "/" +
            new Date().toLocaleDateString("pt-BR", { month: "2-digit" }) +
            "/" +
            new Date().toLocaleDateString("pt-BR", { year: "numeric" }),
          imovelProprio,
          imovelNovo,
        });

        if (data._id) {
          setStep(3);
        }
      } else {
        !renda || renda == "." || renda == ".00"
          ? (campos[1].style.border = "2px solid red")
          : (campos[1].style.border = "0px solid red");
        !compra || compra == "." || compra == ".00"
          ? (campos[2].style.border = "2px solid red")
          : (campos[2].style.border = "0px solid red");
        !financiamento || financiamento == "." || financiamento == ".00"
          ? (campos[3].style.border = "2px solid red")
          : (campos[3].style.border = "0px solid red");
      }
    } else {
      campos[0].style.border = "2px solid red";
    }
  }

  function moneyToNumber(value) {
    value = value
      .replaceAll("R", "")
      .replaceAll("$", "")
      .replaceAll(" ", "")
      .replaceAll(".", "")
      .replaceAll(",", "");

    return (
      value.substr(1, value.length - 3) +
      "." +
      value.substr(value.length - 2, 2)
    );
  }

  function money(value) {
    value = String(value);
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(+value.replace(/\D+/g, "") / 100);
  }

  return (
    <>
      <BgImg>
        <Header />
        <Container>
          <TableContainer>
            <FinancialTransferInfo />
          </TableContainer>
          <FormContainer>
            <div class="caption">
              <h1>Correspondente Bancário</h1>

              {step == 1 ? (
                <>
                  <div className="subTitle">
                    Você precisa de aprovação bancária para o financiamento do
                    seu cliente? Conte com os nossos especialistas e simplifique
                    as suas vendas! Faça o login ou cadastre-se como corretor:
                  </div>

                  <div className="input-box input-center">
                    <small>Email</small>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder=""
                      autoCapitalize
                    />
                  </div>
                  <div className="input-box input-center">
                    <small>Senha</small>
                    <input
                      value={senha}
                      onChange={(e) => setSenha(e.target.value)}
                      placeholder=""
                      type="password"
                    />
                  </div>

                  <div className="container-next">
                    <button onClick={handleLogin}>Entrar</button>
                  </div>

                  <p
                    className="remember"
                    onClick={(e) => {
                      window.location.href = "/login?modal=true";
                    }}
                  >
                    Esqueci minha senha
                  </p>

                  <div className="container-next-reverse">
                    <button
                      onClick={(e) =>
                        (window.location.href = "/cadastro-corretor")
                      }
                    >
                      Cadastrar-se
                    </button>
                  </div>
                </>
              ) : (
                ""
              )}

              {step == 2 ? (
                <>
                  <div className="subTitle">
                    Você precisa de aprovação bancária para o financiamento do
                    seu cliente? Conte com os nossos especialistas e simplifique
                    as suas vendas!{" "}
                  </div>

                  <div className="container-input-box">
                    <div className="input-box input-center">
                      <small>Data de Nascimento</small>
                      <input
                        style={{ height: "inherit" }}
                        value={dataN}
                        onChange={(e) => setDataN(e.target.value)}
                        type="date"
                        placeholder="Participante mais velho"
                      />
                    </div>

                    <div className="input-box input-center">
                      <small>Renda Bruta</small>
                      <input
                        value={money(renda)}
                        onChange={(e) =>
                          setRenda(moneyToNumber(e.target.value))
                        }
                        placeholder="Total da renda familiar"
                      />
                    </div>
                  </div>

                  <div className="container-input-box">
                    <div className="input-box input-center">
                      <small>Valor de Compra/Venda</small>
                      <input
                        value={money(compra)}
                        onChange={(e) =>
                          setCompra(moneyToNumber(e.target.value))
                        }
                        placeholder=""
                      />
                    </div>

                    <div className="input-box input-center">
                      <small>Financiamento no banco</small>
                      <input
                        value={money(financiamento)}
                        onChange={(e) =>
                          setFinanciamento(moneyToNumber(e.target.value))
                        }
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div
                    className="container-input-box"
                    style={{ marginTop: 15 }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={FGTS}
                          onChange={(change) => setFGTS(!FGTS)}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Sim, pretende usar o FGTS"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={imovelProprio}
                          onChange={(change) =>
                            setImovelProprio(!imovelProprio)
                          }
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Sim, possui imóvel próprio"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={imovelNovo}
                          onChange={(change) => setImovelNovo(!imovelNovo)}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Imóvel Novo"
                    />
                  </div>

                  <h2 style={{ marginTop: 15 }}>
                    Qual o regime de contratação do comprador?{" "}
                  </h2>

                  <div
                    className="container-input-box"
                    style={{ marginTop: 15 }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={regime == "CLT" ? true : false}
                          onChange={(change) => setRegime("CLT")}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="CLT"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={regime == "Autônomo" ? true : false}
                          onChange={(change) => setRegime("Autônomo")}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Autônomo"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={regime == "Servidor Público" ? true : false}
                          onChange={(change) => setRegime("Servidor Público")}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Servidor Público"
                    />
                  </div>

                  <div
                    className="container-next"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <button onClick={handleSend} style={{ width: "40%" }}>
                      Confirmar
                    </button>
                  </div>
                </>
              ) : (
                ""
              )}

              {step == 3 ? (
                <>
                  <div className="subTitle">
                    Você precisa de aprovação bancária para o financiamento do
                    seu cliente? Conte com os nossos especialistas e simplifique
                    as suas vendas!{" "}
                  </div>

                  <img src={require("./icons/success.svg")} />

                  <div className="textSend">
                    A equipe Imóvel Vazio agradece o seu interesse pelo serviço
                    de Correspondente Bancário. Entraremos em contato o mais
                    breve possível.
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </FormContainer>
        </Container>
      </BgImg>
      <Footer />
    </>
  );
}

export default CBanking;
