import React, { useEffect, useState } from "react";
import Header from "../../components/adminHeader";
import Aside from "../../components/adminSideBar";
import { Container } from "./styles";
import api from "../../api";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import exportFromJSON from "export-from-json";
import parseHTML from "../../utils/parserHtml";
import { getPropertiesAsync } from "../../services/property.service";
import Loading from "../../components/Loading";
import { getCitiesAsync } from "../../services/city.service";

import { Popconfirm, notification } from "antd";
import { getUserSession } from "../../utils/sessionHelper";
import { PERMISSION } from "../../utils/userProfileHelper";

export default function AdminPropertyList() { 
  const user = getUserSession();

  const baseUrl = document.location.origin;
  const [data, setData] = useState([]);
  const [dataOrder, setDataOrder] = useState([]);

  const [verification, setVerification] = useState(true);

  const [city, setCity] = useState([]);
  const [neighSelect, setNeighSelect] = useState([]);
  const [types, setTypes] = useState([]);
  const [total, setTotal] = useState([]);

  const [valorMaximo, setValorMaximo] = useState("R$ 0,00");
  const [valorMinimo, setValorMinimo] = useState("R$ 0,00");
  const [type, setType] = useState("");
  const [generalType, setGeneralType] = useState("");
  const [quartos, setQuartos] = useState("");
  const [Banheiros, setBanheiros] = useState("");
  const [vagas, setVagas] = useState("");
  const [areaMinima, setAreaMinima] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [status, setStatus] = useState("false");
  const [algo, setAlgo] = useState("");

  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const permission = localStorage.getItem("permission");


  useEffect(() => {
    if (localStorage.getItem("type_user") != "admin") {
      window.location.href = "/login";
    }

    setLoading(true);
    setTimeout(() => handleLoad(), 200);
  }, []);

  const getNeighborhood = () => {
    if (cidade)
      api.get(`/neighborhood?limit=10000&city=${cidade}`)
        .then(({ data }) => {
          setNeighSelect(data.neighborhoods);
        });

  };

  useEffect(() => {
    getNeighborhood();
  }, [cidade]);

  function cleanStates() {
    setValorMaximo("R$ 0,00");
    setValorMinimo("R$ 0,00");
    setType("");
    setGeneralType("");
    setQuartos("");
    setBanheiros("");
    setVagas("");
    setAreaMinima("");
    setBairro("");
    setCidade("");
    setStatus("false");
    setAlgo("");
  }
  async function handleLoadReset() {
    cleanStates();
    const response = await getPropertiesAsync({ sold: status }, !!user);
    setLastPage(response.data.pages);
    setData(response.data.propertys);
    setTotal(response.data.total);
  }

  async function handleLoad() {
    cleanStates();
    setLoading(true);
    const response = await getPropertiesAsync({ sold: status }, !!user);
    setLastPage(response.data.pages);
    setData(response.data.propertys);
    setTotal(response.data.total);
    var { data: citiesData } = await getCitiesAsync({ limit: 9999, orderBy: 'city', direction: 'ASC' });


    let cities = [];
    let location = { isRestricted: false };
    if (permission === PERMISSION.regional) {
      const user = getUserSession();
      location = user.location;
      if (location.isRestricted)
        cities = citiesData.filter(x => location.cities.includes(x.value));
    }
    if (!location?.isRestricted)
      cities = citiesData;
    setCity(cities.map(x => ({ _id: x.value, name: `${x.city}, ${x.uf}` })));

    var { data } = await api.get("/propertyType");

    setTypes(data.propertyTypes);
    setLoading(false);
  }

  async function handleDelete(id) {
    const { data } = await api.delete(`/property/${id}?arquive=true`, {
      headers: {
        data: JSON.stringify({
          arquive: true,
          showHome: false,
          updateAt: Date.now(),
        }),
      },
    });

    handleLoad();
  }

  async function handleUpdate(id, sold, arquive, index, object, setObject) {
    if (sold && !arquive) {
      return notification.error({
        message: 'Este imovel ja foi vendido.'
      });
    }

    const { data } = await api.put(`/property/${id}`, {
      sold: true,
      soldDate:
        new Date().toLocaleDateString("pt-BR", { day: "2-digit" }) +
        "/" +
        new Date().toLocaleDateString("pt-BR", { month: "2-digit" }) +
        "/" +
        new Date().toLocaleDateString("pt-BR", { year: "numeric" }),
    });
    if (data.sold == true) {
      object = object.filter((r) => r._id != "null");
      object[index].sold = true;
      setObject(object);
    }
    // handleLoad();

  }

  async function handleUpdateRevert(
    id,
    sold,
    arquive,
    index,
    object,
    setObject
  ) {
    // if (window.confirm("Tem certeza que quer atualizar este imovel?")) {
    const response = await api.put(`/property/${id}`, { sold: false });
    if (response.data.sold === false) {
      object = object.filter((r) => r._id != "null");
      object[index].sold = false;
      setObject(object);
    }
  }
  // }

  async function handlePage(pages) {
    const response = await getPropertiesAsync({ sold: status, page: (pages.selected + 1) }, true);
    setTotal(response.data.total);

    if (response.data.propertys) {
      setPage(pages.selected);
      setData(response.data.propertys);
    }
  }

  async function handleApply() {
    let formData = {};

    if (cidade) {
      formData.city_id = cidade;
    }

    if (bairro) {
      formData.neigh_id = bairro;
    }

    if (generalType) {
      formData.generalType = generalType;
    }

    if (type) {
      formData.generalTypes = type;
    }

    if (quartos) {
      formData.bedrooms = quartos;
    }

    if (vagas) {
      formData.parkingSpots = vagas;
    }

    if (Banheiros) {
      formData.bathrooms = Banheiros;
    }
    //6989
    if (areaMinima) {
      formData.fullLandSize = areaMinima;
    }

    if (valorMaximo != "R$ 0,00") {
      formData.sellingPrice = removeMoney(valorMaximo);
    }

    if (valorMinimo != "R$ 0,00") {
      formData.sellingPriceMin = removeMoney(valorMinimo);
    }

    if (algo) {
      formData.endereco = algo;
    }

    if (status) {
      if (status == "arquivado") {
        formData.arquive = true;
      } else {
        formData.sold = status;
      }
    }

    const { data } = await getPropertiesAsync({ limit: 10000, ...formData }, true);

    setTotal(data.total ? data.total : 0);
    setLastPage(data.pages);
    setData(data.propertys);

  }

  function removeMoney(value) {
    value = value
      .replaceAll("R", "")
      .replaceAll("$", "")
      .replaceAll(" ", "")
      .replaceAll(".", "")
      .replaceAll(",", "");

    return (
      value.substr(1, value.length - 3) +
      "." +
      value.substr(value.length - 2, 2)
    );
  }

  async function handleOrder(order) {
    setDataOrder([]);
    switch (order) {
      case "recent":
        var response = await getPropertiesAsync({ sold: status }, true);

        setVerification(true);
        setDataOrder([]);
        setData(response.data.propertys);
        setTotal(response.data.total);
        setLastPage(response.data.pages);
        break;

      case "antigo":
        var response = await getPropertiesAsync({ sold: status }, true);
        setVerification(true);
        setDataOrder([]);
        setData(response.data.propertys);
        setTotal(response.data.total);
        setLastPage(response.data.pages);
        break;
      case "maior-menor":
        var response = await getPropertiesAsync({ sold: status, orderby: { sellingPrice: -1 } }, true);
        setVerification(true);
        setDataOrder([]);
        setData(response.data.propertys);
        setTotal(response.data.total);
        setLastPage(response.data.pages);
        break;

      case "menor-maior":
        var response = await getPropertiesAsync({ sold: status, orderby: { sellingPrice: -1 } }, true);
        setVerification(true);
        setDataOrder([]);
        setData(response.data.propertys);
        setTotal(response.data.total);
        setLastPage(response.data.pages);
        break;

      case "me-maior-menor":
        var response = await getPropertiesAsync({ sold: status, orderby: { fullLandSize: -1 } }, true);
        setVerification(true);
        setDataOrder([]);
        setData(response.data.propertys);
        setTotal(response.data.total);
        setLastPage(response.data.pages);
        break;

      case "me-menor-maior":
        var response = await getPropertiesAsync({ sold: status, orderby: { fullLandSize: -1 } }, true);
        setVerification(true);
        setDataOrder([]);
        setData(response.data.propertys);
        setTotal(response.data.total);
        setLastPage(response.data.pages);
        break;

      case "a-z":
        var sortDatas = data.sort(function (a, b) {
          var textA = "";
          var textB = "";
          if (a.neighborhood && b.neighborhood) {
            textA = a.neighborhood?.name?.toUpperCase();
            textB = b.neighborhood?.name?.toUpperCase();
          }

          return textA.localeCompare(textB);
        });

        if (!verification) {
          setData(sortDatas);
          setVerification(true);
        } else {
          setDataOrder(sortDatas);
          setVerification(false);
        }
        break;

      case "z-a":
        var sortDatas = data
          .sort(function (a, b) {
            var textA = "";
            var textB = "";
            if (a.neighborhood && b.neighborhood) {
              textA = a.neighborhood?.name?.toUpperCase();
              textB = b.neighborhood?.name?.toUpperCase();
            }

            return textA.localeCompare(textB);
          })
          .reverse();

        if (!verification) {
          setData(sortDatas);
          setVerification(true);
        } else {
          setDataOrder(sortDatas);
          setVerification(false);
        }
        break;
    }
  }

  function getConfirmContext(sold, arquive) {
    if (sold && !arquive) {
      return CONFIRM_CONTEXT.active;
    }

    return CONFIRM_CONTEXT.sold;
  }

  function handleChangeCity(value) {
    // setNeighSelect(
    //   neighborhoods.filter((target) => {
    //     return target.city == value;
    //   })
    // );

    setCidade(value);
  }

  function money(value) {
    value = String(value);
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(+value.replace(/\D+/g, "") / 100);
  }

  async function handlerSetCatalogV2(id, state, canTry = true) {

    if (!canTry) return;
    try {
      const response = await api.put(`/property/${id}`, {
        showCatalog: state,
      });

      let ar = [];
      data.map((e) => {
        if (parseInt(e._id) === parseInt(id)) {
          e.showCatalog = response.data.showCatalog;
        }
        ar.push(e);
      });
      setData(ar);
    } catch (err) {
      console.error(err);
    }
  }

  async function handlerSetHomeV2(id, state, canTry = true) {

    if (!canTry) return;
    try {
      const response = await api.put(`/property/${id}`, {
        showHome: state,
        teste: true,
      });

      let ar = [];
      data.map((e) => {
        if (parseInt(e._id) === parseInt(id)) {
          e.showHome = response.data.showHome;
        }
        ar.push(e);
      });
      setData(ar);
    } catch (err) {
      console.error(err);
    }

  }

  const CONFIRM_CONTEXT = {
    default: '',
    home: 'Home',
    catalog: 'Catalog',
    delete: 'Excluir',
    sold: 'Vendido',
    active: 'Ativo'
  };

  const [confirmLoading, setConfirmLoading] = useState(false);
  const defaultOpenConfirm = { state: false, id: 0, context: CONFIRM_CONTEXT.default };
  const [openConfirm, setOpenConfirm] = useState(defaultOpenConfirm);
  const isOpen = (id, context) => {
    return openConfirm.state && openConfirm.context === context && openConfirm.id === id;
  }
  const handlerOpenConfirm = (state = defaultOpenConfirm.state, id = defaultOpenConfirm.id, context = defaultOpenConfirm.context) => {
    setOpenConfirm({
      state,
      id,
      context,
    })
  }

  const onConfirm = async (callback) => {
    try {
      setConfirmLoading(true);
      await callback();
    } finally {
      setConfirmLoading(false);
      handlerOpenConfirm(false);
    }
  };

  const getCatalogToggleMessage = (isShow) => isShow
    ? 'Deseja remover esse imóvel do catálogo?'
    : 'Deseja adicionar este imóvel no catálogo?';

  const getHomeToggleMessage = (isShow) => isShow
    ? 'Deseja remover este imóvel da HOME?'
    : 'Deseja adicionar este imóvel na HOME?';

  function dateBr(value) {
    return (
      value.substr(8, 2) + "/" + value.substr(5, 2) + "/" + value.substr(0, 4)
    );
  }

  const downloadPropertyList = async () => {
    setLoading(true);
    const newData = [];
    const response = await getPropertiesAsync({ limit: 9999, sold: status }, true);

    response.data.propertys.forEach((item) => {
      newData.push({
        ID: item?._id,
        Endereco: item?.address,
        Numero: item?.addressNumber,
        Complemento: item?.addressComplement,
        Cidade: item?.city?.name,
        Estado: item?.city?.stateName,
        Bairro: item?.neighborhood?.name,
        Tipo: item?.type?.name,
        ["Comercial/Residencial"]: item?.generalType?.name,
        Banheiros: item?.bathrooms,
        Quartos: item?.bedrooms,
        Suites: item?.suites,
        Vagas: item?.parkingSpots,
        Metros: item?.fullLandSize,
        Vendido: item?.sold ? "vendido" : "nao vendido",
        IPTU: money(String(item?.IPTU)),
        Comissao: `${item?.comission} % `,
        Condominio: money(parseFloat(item?.condominiumPrice).toFixed(2)),
        Valor: money(parseFloat(item?.sellingPrice).toFixed(2)),
        Valor_com_desconto: money(parseFloat(item?.descountPrice).toFixed(2)),
        Descrição: !!item?.description
          ? parseHTML(item?.description.toString())
          : "",
        Data_cadastro: item?.createdAt,
      });
    });

    const fileName = "Propriedades";
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data: newData, fileName, exportType });
    return setLoading(false);
  };

  function innerRow(row, index) {
    return (
      <>
        <div className="body-active">
        {PERMISSION.marketing === permission ?
           (<div>
              HOME 1
              {row.showHome
                ? <img src={require("./icons/success.svg")} />
                : <img src={require("./icons/error.svg")} />
              }
            </div>)
          :
          (<Popconfirm
            placement="top"
            title={`Imóvel ${row._id}`}
            description={getHomeToggleMessage(row.showHome)}
            onConfirm={() => onConfirm(async () => await handlerSetHomeV2(row._id, !row.showHome, !row.arquive))}
            open={isOpen(row._id, CONFIRM_CONTEXT.home)}
            okButtonProps={{
              loading: confirmLoading,
            }}
            onCancel={() => handlerOpenConfirm()}
            okText="Sim"
            cancelText="Não"
          >
            <div
              className="catalog-icon"
              onClick={() => handlerOpenConfirm(true, row._id, CONFIRM_CONTEXT.home)}
              style={{ cursor: row.arquive ? "default" : "pointer" }}
            >
              HOME 1
              {row.showHome
                ? <img src={require("./icons/success.svg")} />
                : <img src={require("./icons/error.svg")} />
              }
            </div>
          </Popconfirm>)
         }
        
        {PERMISSION.marketing === permission ?
          (<div>
            CATÁLOGO
            {row.showCatalog
              ? <img src={require("./icons/success.svg")} />
              : <img src={require("./icons/error.svg")} />
            }
           </div>)
          :
          (<Popconfirm
            placement="top"
            title={`Imóvel ${row._id}`}
            description={getCatalogToggleMessage(row.showCatalog)}
            onConfirm={() => onConfirm(async () => await handlerSetCatalogV2(row._id, !row.showCatalog, !row.arquive))}
            open={isOpen(row._id, CONFIRM_CONTEXT.catalog)}
            disabled={PERMISSION.marketing === permission}
            okButtonProps={{
              loading: confirmLoading,
            }}
            onCancel={() => handlerOpenConfirm()}
            okText="Sim"
            cancelText="Não"
          >
            <div
              className="catalog-icon"
              onClick={() => handlerOpenConfirm(true, row._id, CONFIRM_CONTEXT.catalog)}
              style={{ cursor: row.arquive ? "default" : "pointer" }}
            >
              CATÁLOGO
              {row.showCatalog
                ? <img src={require("./icons/success.svg")} />
                : <img src={require("./icons/error.svg")} />
              }
            </div>
          </Popconfirm>)
        }

        </div>
        <div className="body-imovel">
          <small>
            Cadastrado em:{" "}
            {String(row.createdAt).split(" ")[0].includes("/")
              ? String(row.createdAt).split(" ")[0]
              : dateBr(String(row.createdAt).split(" ")[0])}
          </small>

          <h2 style={{ fontSize: 18 }}>
            <a
              style={{ color: "inherit" }}
              href={`${baseUrl}/imoveis/${row._id}`}
              target="_blank"
            >
              {row.address ? row.address : ""}
              {row.addressNumber ? ", " + row.addressNumber : ""}{" "}
              {row.addressComplement ? ` / ${row.addressComplement}` : ""}
            </a>
          </h2>
          <h3>
            {row.neighborhood ? row.neighborhood.name : ""}
            {row.city ? " - " + row.city.name : ""}
            {row.neighborhood ? ", " + row.neighborhood.state : ""}
          </h3>

          <h5>
            {row.type ? row.type.name : ""}{" "}
            {row.generalType
              ? row.generalType.name
              : row.type
                ? row.type.generalType == "2372820374682508"
                  ? "Residencial"
                  : "Comercial"
                : ""}
          </h5>
          <h6>
            Vendido?{" "}
            {row.sold && !row.arquive
              ? "Sim"
              : !row.arquive
                ? "Não"
                : "Arquivado"}
          </h6>
        </div>
        <div className="body-engajamento">
          <div>
            <a
              onClick={(e) =>
                (window.location.href = `/admin/property/visualization?id=${row._id}`)
              }
            >
              Visualizações
            </a>
          </div>

          <div>
            <a
              onClick={(e) =>
                (window.location.href = `/admin/historico/${row._id}`)
              }
            >
              Abriram a Fechadura
            </a>
          </div>
          <div>
            <a
              onClick={(e) =>
                (window.location.href = `/admin/property/interested?id=${row._id}`)
              }
            >
              Corretores interessados
            </a>
          </div>
        </div>
        <div className="body-actions">
          <div>
            <a
              onClick={(i) =>
                (window.location.href = `/admin/property/edit?id=${row._id}`)
              }
            >
              Editar
            </a>
          </div>
          {PERMISSION.marketing !== permission &&
          <Popconfirm
            placement="top"
            title={`Imóvel ${row._id}`}
            description='Deseja excluir essa propriedade?'
            onConfirm={() => onConfirm(async () => await handleDelete(row._id))}
            open={isOpen(row._id, CONFIRM_CONTEXT.delete)}
            okButtonProps={{
              loading: confirmLoading,
            }}
            onCancel={() => handlerOpenConfirm()}
            okText="Sim"
            cancelText="Não"
          >
             <div>
              <a className="catalog-icon"
                onClick={() => handlerOpenConfirm(true, row._id, CONFIRM_CONTEXT.delete)}>Excluir</a>
            </div>
          </Popconfirm>
            }
          <Popconfirm
            placement="top"
            title={`Imóvel ${row._id}`}
            description='Tem certeza que quer atualizar este imovel?'
            onConfirm={() => onConfirm(async () => row.sold && !row.arquive ? await handleUpdateRevert(
              row._id,
              row.sold,
              row.arquive,
              index,
              data,
              setData
            ) :
              handleUpdate(
                row._id,
                row.sold,
                row.arquive,
                index,
                data,
                setData
              ))}
            open={isOpen(row._id, getConfirmContext(row.sold && !row.arquive))}
            okButtonProps={{
              loading: confirmLoading,
            }}
            onCancel={() => handlerOpenConfirm()}
            okText="Sim"
            cancelText="Não"
          >
            <div>
              {row.sold && !row.arquive ? (
                <a
                  onClick={(i) => handlerOpenConfirm(true, row._id, CONFIRM_CONTEXT.active)}
                >
                  Reverter para ativo
                </a>
              ) : (
                <a
                  onClick={(i) => handlerOpenConfirm(true, row._id, CONFIRM_CONTEXT.sold)}
                >
                  Colocar como vendido
                </a>
              )}
            </div>
          </Popconfirm>
        </div>
      </>
    );
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <Header />
      <Aside />
      <Container>
        <div className="bread-crumb">
          <h1 className="category">ANÚNCIOS</h1>
          <Link to={window.location.pathname} className="where">
            Anúncios Imóvel Vazio
          </Link>
        </div>
        <div className="filter">

          <div className="input-group">
            <label htmlFor="">Cidade</label>
            <select
              onChange={(e) =>
                e.target.value != "Selecione:"
                  ? handleChangeCity(e.target.value)
                  : handleChangeCity("")
              }
            >
              {/* <option defaultChecked="true">Selecione: </option> */}
              <option defaultChecked="true">Selecione: </option>
              {city.map((city) => (
                <option key={city._id.toString()} value={city._id}>{city.name}</option>
              ))}
            </select>
            {/* {
                            city.length != 0 ? 
                                <Autocomplete placeholder="Selecione" onChange={(value) => handleChangeCity(value._id)} inputName="Cidade" objs={city} />
                                :
                                ''
                        } */}
          </div>
          <div className="input-group">
            <label htmlFor="">Bairro</label>
            <select onChange={(select) => setBairro(select.target.value)}>
              <option defaultChecked="true">Selecione: </option>
              {neighSelect.map((neigh) => (
                <option key={neigh._id.toString()} value={neigh._id}>{neigh.name}</option>
              ))}
            </select>
            {/* {
                            neighSelect.length != 0 ?
                                <Autocomplete placeholder="Selecione" onChange={(value) => setBairro(value._id)} inputName="Bairro" objs={neighSelect} />
                                :
                                ''
                        } */}
          </div>
          <div className="input-group">
            <label htmlFor="">Status</label>
            <select
              value={status}
              onChange={(select) => setStatus(select.target.value)}
            >
              <option defaultValue value="false">
                À venda
              </option>
              <option value="true">Vendido</option>
              <option value="arquivado">Arquivado</option>
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="">Residencial ou comercial</label>
            <select onChange={(select) => setGeneralType(select.target.value)}>
              <option>Selecione</option>
              <option value="2372820374682508">Residencial</option>
              <option value="1644602035863502">Comercial</option>
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="">Tipo de imovel</label>
            <select onChange={(select) => setType(select.target.value)}>
              <option defaultChecked="true">Selecione: </option>
              {types.map((neigh) => (
                <option key={neigh._id.toString()} value={neigh._id}>{neigh.name}</option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="">Valor minimo</label>
            <input
              value={valorMinimo}
              onChange={(input) => setValorMinimo(money(input.target.value))}
            />
          </div>
          <div className="input-group">
            <label htmlFor="">Valor maximo</label>
            <input
              value={valorMaximo}
              onChange={(input) => setValorMaximo(money(input.target.value))}
            />
          </div>
          <div className="input-group">
            <label htmlFor="">Quartos</label>
            <input
              type="number"
              value={quartos}
              onChange={(input) => setQuartos(input.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="">Banheiros</label>
            <input
              type="number"
              value={Banheiros}
              onChange={(input) => setBanheiros(input.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="">Vagas</label>
            <input
              type="number"
              value={vagas}
              onChange={(input) => setVagas(input.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="">Área minima</label>
            <input
              type="number"
              value={areaMinima}
              onChange={(input) => setAreaMinima(input.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="">Digite algo</label>
            <input
              type="text"
              value={algo}
              onChange={(input) => setAlgo(input.target.value)}
            />
          </div>
          <div className="button-group">
            {PERMISSION.marketing !== permission && <button
              onClick={() => (window.location.href = "/admin/property/new")}
              style={{ marginRight: 10 }}
              className="outline"
            >
              Adicionar Imóvel
            </button>}
            <button onClick={handleLoadReset} className="outline">
              Remover filtros
            </button>
            <button onClick={handleApply} className="full">
              Aplicar filtros
            </button>
          </div>
        </div>
        <div className="row-ordenate">
          <p>Foram encontrados {total} imóveis</p>
          <div className="input-group">
            <select onChange={(option) => handleOrder(option.target.value)}>
              <option value="">Ordenar por</option>
              <option value="recent">Mais Recentes</option>
              <option value="antigo">Mais Antigos</option>
              <option value="maior-menor">Valor (Maior-Menor)</option>
              <option value="menor-maior">Valor (Menor-Maior)</option>
              <option value="me-maior-menor">Metragem (Maior-Menor)</option>
              <option value="me-menor-maior">Metragem (Menor-Maior)</option>
              <option value="a-z">Bairro (A-Z)</option>
              <option value="z-a">Bairro (Z-A)</option>
            </select>
          </div>
        </div>
        <div className="download">
          <a
            onClick={downloadPropertyList}
            style={{
              color: "#00AEC8",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            {loading ? "Carregando..." : "Fazer download da lista de anúncios"}
          </a>
        </div>
        <div className="header">
          <div className="cod">
            <h2>CÓD.</h2>
          </div>
          {window.innerWidth <= 600 ? (
            <div
              style={{
                overflow: "auto",
                display: "-webkit-inline-box",
                width: "82%",
              }}
            >
              <div style={{ width: 90 }} className="active">
                <h2>ATIVO</h2>
              </div>
              <div style={{ width: 210 }} className="imovel">
                <h2>IMÓVEL</h2>
              </div>
              <div style={{ width: 210 }} className="engajamento">
                <h2>ENGAJAMENTO</h2>
              </div>
              <div style={{ width: 150 }} className="actions">
                <h2>AÇÕES</h2>
              </div>
            </div>
          ) : (
            <>
              <div className="active">
                <h2>ATIVO</h2>
              </div>
              <div className="imovel">
                <h2>IMÓVEL</h2>
              </div>
              <div className="engajamento">
                <h2>ENGAJAMENTO</h2>
              </div>
              <div className="actions">
                <h2>AÇÕES</h2>
              </div>
            </>
          )}
        </div>

        {verification
          ? data.map((row, index) => (
            <div key={row._id} className="body">
              <div className="body-cod">{row._id}</div>

              {window.innerWidth <= 600 ? (
                <div
                  style={{
                    overflow: "auto",
                    display: "-webkit-inline-box",
                    width: "82%",
                  }}
                >
                  {innerRow(row, index)}
                </div>
              ) : (
                <>{innerRow(row, index)}</>
              )}
            </div>
          ))
          : ""}

        {!verification
          ? dataOrder.map((row, index) => (
            <div className="body">
              <div className="body-cod">{row._id}</div>
              {window.innerWidth <= 600 ? (
                <div
                  style={{
                    overflow: "auto",
                    display: "-webkit-inline-box",
                    width: "82%",
                  }}
                >
                  {innerRow(row, index)}
                </div>
              ) : (
                <>{innerRow(row, index)}</>
              )}
            </div>
          ))
          : ""}

        <div className="paginate">
          {/* <div onClick={r => handlePage(1)} className="firstDiv">primeiro</div>
                    <div onClick={r => handlePage(lastPage == page ? page-3 : page+1)}>{lastPage == page ? page-3 : page+1}</div>
                    <div onClick={r => handlePage(lastPage == page ? page-2 : page+2)}>{lastPage == page ? page-2 : page+2}</div>
                    <div onClick={r => handlePage(lastPage == page ? page-1 : page+3)}>{lastPage == page ? page-1 : page+3}</div>
                    <div onClick={r => handlePage(lastPage)} className="lastDiv">última</div> */}
          <ReactPaginate
            pageCount={Math.ceil(total / 20)}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={(e) => handlePage(e)}
            forcePage={page}
            previousLabel={"Anterior"}
            nextLabel={"Proximo"}
            breakLabel={<a href="">...</a>}
            breakClassName={"break-me"}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </Container>
    </>
  );
}
