import React from "react";
import * as S from "./style";
import "../../global.css";
import Success from '../../assets/success.svg'

export default function ModalApp({ closeModalProposal }) {

  function redirectScreenEdit() {
    const id = localStorage.getItem('idProposal');
    window.location.href = `/admin/propostas-corretor/editar?id=${id}`
  }

  return (
    <>
      <S.BannerContainer>
        <div className="container">
          <nav className="image-success-title">
            <img src={Success}></img>
            <h3>Está tudo certo!</h3>
          </nav>

          <section className="subtitle">
            <span className="first-subtitle">A equipe Imóvel Vazio agradece a preferência.</span>
            <span>
              Iremos avaliar e entrar em contato o mais breve possível.
            </span>
          </section>

          <section className="btns">
            <button className="btn-ok" onClick={() => closeModalProposal()}>Ok, Entendi</button>
            <button className="btn-follow" onClick={() => redirectScreenEdit()}>Verificar minha proposta</button>
          </section>
        </div>
      </S.BannerContainer>
    </>
  );
}
