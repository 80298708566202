/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, } from "react";
import { Link, useHistory } from "react-router-dom";
import crypto from "crypto";

import {
  Container,
  ImageItem,
  PlusBtn,
  BtnWrapper,
  Carousel,
  BookItem,
  DocItem,
  Item,
} from "./styles";
import DownloadLink from "../../components/DownloadLink";
import Header from "../../components/adminHeader";
import Aside from "../../components/adminSideBar";
import Picker from "../../components/picker";

import api from "../../api";

import Loading from "../../components/Loading";
import { Button, Tabs, notification } from "antd";
import PlaceField from "../../components/forms/PlaceField";
import PhotoModal from '../../components/PhotosModal'
import SwitchPartnerProperties from "../../components/forms/SwitchPartnerProperties";
import { NOTIFICATION_TYPE, openNotification } from "../../utils/notification";
import { getNeighborhoodAndCityAsync, getNeighborhoodsOfCityAsync } from "../../services/city.service";
import TextEditor from "../../components/TextEditor";
import { CodeOutlined, VideoCameraOutlined, DesktopOutlined } from "@ant-design/icons";
import UploadGallery from '../../components/UploadGallery';

const filesUrl = process.env.REACT_APP_FILES_URL;

export default function AdminOfferPropertyEdit() {
  const [showVideoCode, setShowVideoCode] = useState(true);
  const [showDescriptionCode, setShowDescriptionCode] = useState(true);
  const [showSuggestionCode, setShowSuggestionCode] = useState(true);

  const [itemStart, setItemStart] = useState("");

  const [loading, setLoading] = useState(false);

  const [block, setBlock] = useState("Apenas um bloqueio, null");
  const [bairro, setBairro] = useState("");
  const [imageAlter, setImageAlter] = useState(false);
  const [docAlter, setDocAlter] = useState(false);
  const [pdfAlter, setPdfAlter] = useState(false);

  const [iptu, setIptu] = useState("");
  const [condominio, setCondominio] = useState("");
  const [vendaImovel, setVendaImovel] = useState("");
  const [descontoImovel, setDescontoImovel] = useState("");

  const [photoModal, setPhotoModal] = useState(false);

  const history = useHistory();

  const [fullAddress, setFullAddress] = useState('');
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [generalPropertyType, setGeneralPropertyType] = useState([]);
  const [types, setTypes] = useState([]);
  const [tags, setTags] = useState([]);
  const [teste, setteste] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [pdfMem, setPdfMem] = useState([
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
  ]);

  const [lockList, setLockList] = useState([]);

  const [ID, setID] = useState(1);
  const [property, setProperty] = useState({
    address: '',
    addressNumber: '',
    addressComplement: '',
    neighborhood: null,
    neighborhoodName: '',
    showNumber: true,
    showComplement: true,
    city: undefined,
    state: '',
    IPTU: null,
    IPTUNumber: '',
    sellingPrice: null,
    descountPrice: null,
    condominiumPrice: null,
    sharedSale: false,
    sellingNotes: '',

    // Novos
    useApp: false,
    lockId: 0,
    sellingNotesApp: '',
    comission: '',
    type: '',
    generalType: '',
    fullLandSize: '',
    bathrooms: 0,
    suites: 0,
    bedrooms: 0,
    parkingSpots: 0,
    features: [],
    tag: '',
    description: '',
    descriptionSuggestion: '',
    videoLink: '',
    driveLink: '',
    dependencies: 0,
    showTag: false,
    vendedor: undefined,
  });
  const [propertyPlace, setPropertyPlace] = useState(property);
  const [checkedPartnerProperty, setCheckedPartnerProperty] = useState(false)

  const [data, setData] = useState();

  const onChangePartnerProperty = (checked) => {
    setCheckedPartnerProperty(checked)
  };

  const getNeighborhoods = (stateName, cityName) => {
    if (stateName && cityName) {
      getNeighborhoodsOfCityAsync({
        cityName,
        stateName,
      })
        .then(({ data: dataArray }) => {
          setNeighborhoods(dataArray);
        })
        .catch(err => {
          console.error(err);
          openNotification('Falha ao obter os bairros', NOTIFICATION_TYPE.error);
        });
    }
  };

  useEffect(() => {
    setProperty({ ...property, ...propertyPlace });
    getNeighborhoods(propertyPlace?.stateName, propertyPlace?.cityName);
  }, [propertyPlace]);

  useEffect(() => {
    if (localStorage.getItem("type_user") != "admin") {
      window.location.href = "/login";
    }
    handleLoad();
    handleLoadList();
  }, []);

  const handleLoadList = async () => {
    var url = window.location.search.replace("?", "");
    var items = url.split("&");
    setID(items[0].split("=")[1]);
    const { data } = await api.get(`/lock`);
    setLockList(data);
  };

  async function handleLoad() {
    var url = window.location.search.replace("?", "");
    var items = url.split("&");
    setID(items[0].split("=")[1]);
    const { data } = await api.get(`/property/${items[0].split("=")[1]}`);
    setData(data)
    setBairro(data?.neighborhood?.name || data?.neighborhoodName);
    getNeighborhoods(data.city?.stateName, data.city?.name);


    data.images.sort((a, b) => {
      if (a["order"] > b["order"]) {
        return 1;
      } else {
        return -1;
      }
    });

    let imagens = [];
    let docs = [];
    data.images.map((image) => {
      imagens.push({
        id: crypto
          .createHash("sha256")
          .update(Math.random().toString())
          .digest("hex"),
        url: `${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${image.image}`,
        image: "teste",
        name: image.image,
        show: image.show ? image.show : "true",
        order: image.order,
      });
    });

    data.documents.map((doc) => {
      docs.push({
        id: crypto
          .createHash("sha256")
          .update(Math.random().toString())
          .digest("hex"),
        url: `${process.env.REACT_APP_FILES_URL}/${data._id}/documentos/${doc.name}`,
        image: "teste",
        name: doc.name,
      });
    });
    if (data.pdf) {
      setPdf([
        {
          id: crypto
            .createHash("sha256")
            .update(Math.random().toString())
            .digest("hex"),
          url: `${process.env.REACT_APP_FILES_URL}/${data._id}/${data.pdf}`,
          image: "teste",
          name: data.pdf,
        },
      ]);
    }
    if (data.memorial) {
      setPdfMem([
        {
          id: crypto
            .createHash("sha256")
            .update(Math.random().toString())
            .digest("hex"),
          url: `${process.env.REACT_APP_FILES_URL}/${data._id}/${data.memorial}`,
          image: "teste",
          name: data.memorial,
        },
      ]);
    }

    setImageCounter(imagens);
    setDocCounter(docs);
    setBlock(data.address);
    setBlock(data.address);
    setFullAddress(`${data.address}, ${data.addressNumber}`);

    setCheckedPartnerProperty(data.isPartnerProperty)
    setProperty({
      ...property,
      id: data._id,
      address: data.address,
      addressNumber: data.addressNumber,
      addressComplement: data.addressComplement,
      IPTU: data.IPTU,
      IPTUNumber: data.IPTUNumber,
      bathrooms: data.bathrooms,
      bedrooms: data.bedrooms,
      suites: data.suites,
      dependencies: data.dependencies ? data.dependencies : 0,
      description: data.description,
      descriptionSuggestion: data.descriptionSuggestion,
      videoLink: data.videoLink,
      driveLink: data.driveLink,
      condominiumPrice: String(data.condominiumPrice).includes(".")
        ? data.condominiumPrice
        : data.condominiumPrice + "00",
      features: data.features,
      sellingPrice: String(data.sellingPrice).includes(".")
        ? data.sellingPrice
        : data.sellingPrice + "00",
      sellingNotes: data.sellingNotes,

      // Novo
      useApp: data.useApp,
      lockId: data.lockId,
      sellingNotesApp: data.sellingNotesApp,

      fullLandSize: data.fullLandSize,
      generalType: data.generalType
        ? data.generalType._id
        : data.type.generalType,
      type: data.type,
      parkingSpots: data.parkingSpots,
      sharedSale: data.sharedSale,
      showComplement: data.showComplement != null ? data.showComplement : true,
      showNumber: data.showNumber != null ? data.showNumber : true,
      state: data.city.stateName,
      tag: data.tag ? (data.tag._id ? data.tag._id : data.tag) : data.tag || '',
      neighborhood: data?.neighborhood?._id,
      neighborhoodName: data?.neighborhood?.name || data?.neighborhoodName,
      postalCode: data.postalCode,
      city: data.city,
      comission: data.comission,
      type: data.type._id,
      showTag: data.showTag,
      vendedor: data.vendedor,
      memorial: data.memorial,
    });

    setCondominio(
      String(data.condominiumPrice).includes(".")
        ? data.condominiumPrice
        : data.condominiumPrice + "00"
    );
    setVendaImovel(
      String(data.sellingPrice).includes(".")
        ? data.sellingPrice
        : data.sellingPrice + "00"
    );
    setDescontoImovel(
      String(data.descountPrice).includes(".")
        ? data.descountPrice
        : data.descountPrice + "00"
    );
    setIptu(data.IPTU);
  }

  useEffect(() => {
    api.get("/propertyType").then((res) => {
      setTypes(res.data.propertyTypes);
    });

    api.get("/generalPropertyType").then((res) => {
      setGeneralPropertyType(res.data.generalPropertyTypes);
    });

    api.get("/tag").then((res) => setTags(res.data.tags));
  }, [teste]);

  useEffect(() => {
    if (types.length > 1) {
      setProperty({
        ...property,
        type: property.type,
      });
    }

    if (generalPropertyType.length > 1) {
      setProperty({
        ...property,
        generalType: property.generalType,
      });
    }
  }, [property.type, types, generalPropertyType]);

  useEffect(() => {
    setTimeout(() => setShowDescriptionCode(false), 1000);
    setTimeout(() => setShowSuggestionCode(false), 1800);
    setTimeout(() => setShowVideoCode(false), 2500);
  }, [property.id]);

  function handleOrdenation(event) {
    var itemEnd = event.target.closest("div div").id;
    if (itemEnd == itemStart) return;

    let array = [];

    imageCounter.map((f, index) => {
      if (itemEnd < itemStart) {
        if (index == itemEnd) {
          array.push(imageCounter[itemStart]);
          array.push(f);
        } else if (index == itemStart) {
        } else {
          array.push(f);
        }
      } else {
        if (index == itemStart) {
        } else if (index == itemEnd) {
          array.push(f);
          array.push(imageCounter[itemStart]);
        } else {
          array.push(f);
        }
      }
    });

    setImageCounter(array);
  }

  async function sendDocs() {
    const filtered = docCounter.filter((value) => {
      if (value.image && value.image != "teste") {
        return value;
      }
    });

    let formData;

    filtered.map(async (value) => {
      formData = new FormData();
      formData.append("property", property.id);
      formData.append("file", value.file);

      setLoading(true);
      await api
        .post("/file/doc", formData)
        .then((res) => {
          setLoading(false);
          let docs = [];
          res.data.documents.map((doc) => {
            docs.push({
              id: crypto
                .createHash("sha256")
                .update(Math.random().toString())
                .digest("hex"),
              url: `${process.env.REACT_APP_FILES_URL}/${res.data._id}/documentos/${encodeURIComponent(doc.name)}`,
              image: "teste",
              name: doc.name,
            });
          });

          setDocCounter(docs);
        })
        .catch((err) => {
          setLoading(false);
          notification.error({
            message: 'Ops, algo deu errado',
            description: "Falha ao inserir documentos,tente novamente mais tarde",
          });
        });
    });

    docCounter.map((e) => {
      e.image = "teste";
    });
  }
  async function sendPdf() {
    const filtered = pdf.filter(async (value) => {
      if (value.image && value.image != "teste") {
        return value;
      }
    });

    let formData;

    if (pdfMem[0].file) {
      const formData = new FormData();
      formData.append("property", property.id);
      formData.append("file", pdfMem[0].file);

      setLoading(true);

      await api
        .post("/file/memorial", formData)
        .then((res) => {
          setPdfMem([
            {
              id: crypto
                .createHash("sha256")
                .update(Math.random().toString())
                .digest("hex"),
              url: `${process.env.REACT_APP_FILES_URL}/${res.data._id}/${res.data.memorial}`,
              image: "teste",
              name: res.data.memorial,
            },
          ]);

          setProperty({
            ...property,
            memorial: res.data.memorial,
          })
        })
        .catch((_err) => {

          setLoading(false);
          openNotification("Houve um erro ao cadastrar o memorial...", NOTIFICATION_TYPE.error)
        }
        );

      setLoading(false);
    }

    if (pdf[0].file) {
      filtered.map(async (value) => {
        formData = new FormData();
        formData.append("property", ID);
        formData.append("file", value.file);

        setLoading(true);
        await api
          .post("/file/pdf", formData)
          .then((res) => {
            setPdf([
              {
                id: crypto
                  .createHash("sha256")
                  .update(Math.random().toString())
                  .digest("hex"),
                url: `${process.env.REACT_APP_FILES_URL}/${res.data._id}/${encodeURIComponent(res.data.pdf)}`,
                image: "teste",
                name: res.data.pdf,
              },
            ]);


            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: 'Ops, algo deu errado',
              description: "Falha ao inserir pdf,tente novamente mais tarde",
            });
          });
      });

    }
    if (filtered.length != []) {
      pdf.map((e) => {
        e.image = "teste";
      });
    }
  }

  async function saveSortHandler(imageSortList) {
    return await api.post(`/property/ordenation/${ID}`, {
      images: imageSortList?.map(({ link }) => ({ name: link })),
    });
  }

  const isValid = (data) => !!(
    data["bathrooms"] != null &&
    data["bedrooms"] != null &&
    data["suites"] != null &&
    data["fullLandSize"] != null &&
    data["parkingSpots"] != null &&
    data["dependencies"] != null &&
    //data["city"] &&
    data["addressNumber"] &&
    data["condominiumPrice"] &&
    data["IPTU"]
  );

  const showValidation = (data) => {
    if (!String(data["sellingPrice"]).includes(".")) {
      data["sellingPrice"] = data["sellingPrice"] + "00";
    }
    if (!String(data["condominiumPrice"]).includes(".")) {
      data["condominiumPrice"] = data["condominiumPrice"] + "00";
    }
    if (!String(data["IPTU"]).includes(".")) {
      data["IPTU"] = data["IPTU"] + "00";
    }
    let inputs = document.querySelectorAll(".input-compose");

    !data["condominiumPrice"]
      ? (inputs[6].style.border = "2px solid red")
      : (inputs[6].style.border = "0px solid red");
    !data["IPTU"]
      ? (inputs[7].style.border = "2px solid red")
      : (inputs[7].style.border = "0px solid red");
    !data["IPTUNumber"]
      ? (inputs[8].style.border = "2px solid red")
      : (inputs[8].style.border = "0px solid red");
    !data["sellingPrice"]
      ? (inputs[9].style.border = "2px solid red")
      : (inputs[9].style.border = "0px solid red");
    !data["generalType"]
      ? (inputs[13].style.border = "2px solid red")
      : (inputs[13].style.border = "0px solid red");
    !data["type"]
      ? (inputs[14].style.border = "2px solid red")
      : (inputs[14].style.border = "0px solid red");
    !data["fullLandSize"]
      ? (inputs[15].style.border = "2px solid red")
      : (inputs[15].style.border = "0px solid red");
    !data["bedrooms"]
      ? (inputs[16].style.border = "2px solid red")
      : (inputs[16].style.border = "0px solid red");
    !data["suites"]
      ? (inputs[17].style.border = "2px solid red")
      : (inputs[17].style.border = "0px solid red");
    !data["bathrooms"]
      ? (inputs[18].style.border = "2px solid red")
      : (inputs[18].style.border = "0px solid red");
    !data["dependencies"]
      ? (inputs[19].style.border = "2px solid red")
      : (inputs[19].style.border = "0px solid red");
    !data["parkingSpots"]
      ? (inputs[20].style.border = "2px solid red")
      : (inputs[20].style.border = "0px solid red");
    !data["address"]
      ? (inputs[0].style.border = "2px solid red")
      : (inputs[0].style.border = "0px solid red");

    openNotification('Preencha os campos em vermelho.', NOTIFICATION_TYPE.error);
  };

  async function create() {
    setLoading(true);
    //handleSaveOrder();

    const emptyToUndefined = (value = '') => !String(value).includes('undefined') ? value : '';
    const emptyToZero = (value = '') => (value == '.' || value == '.00' || value == '00') ? '' : value;
    const centsHandler = (value = '') => !String(value).includes('.') ? value.substring(0, value.length - 2) : value;

    var dto = { ...property };
    delete dto["_id"];
    dto.sellingPrice = centsHandler(emptyToZero(emptyToUndefined(vendaImovel)));
    dto.descountPrice = centsHandler(emptyToZero(emptyToUndefined(descontoImovel)));
    dto.IPTU = centsHandler(emptyToZero(emptyToUndefined(iptu)));
    dto.condominiumPrice = centsHandler(emptyToZero(emptyToUndefined(condominio)));
    dto.isPartnerProperty = checkedPartnerProperty
    Object.keys(dto).forEach((key) => dto[key] == null && delete dto[key]);

    if (!!dto.memorial && !pdfMem.some((e) => !!e.image)) {
      dto.memorial = null;
    }

    if (!dto.neighborhood && !dto.neighborhoodName) {
      openNotification('Verifique o bairro informado', NOTIFICATION_TYPE.error);
      return;
    }

    if (!dto.useApp) {
      dto.useApp = 'false';
    }

    if (!dto['lockId']) {
      dto['lockId'] = '0';
    }
    if (confirm || !dto.neighborhood) {
      dto.neighborhood = null;
      try {
        const { data: NeighborhoodAndCity } = await getNeighborhoodAndCityAsync({
          stateName: dto.stateName || dto.state,
          cityName: dto.cityName || dto.city?.name,
          neighborhoodName: dto.neighborhoodName,
        });
        const { neighborhoodId, cityId } = NeighborhoodAndCity;
        dto.neighborhood = neighborhoodId;
        dto.city = cityId;
      } catch (error) {
        console.error(error);
        openNotification('Não foi possível validar a localização, corrija o endereço!', NOTIFICATION_TYPE.error);
        setLoading(false);

        return;
      }
    }

    if (!dto.neighborhood) {
      setLoading(true);
      return;
    }

    if (!isValid(dto)) {
      showValidation(dto);
    } else {

      await api
        .put(`/property/${property.id}`, dto)
        .then(async (_) => {
          openNotification('Imóvel atualizado com sucesso', NOTIFICATION_TYPE.success);
          setTimeout(() => {
            history.goBack();
          }, 1500);
          setLoading(true);
        })
        .catch((_) => {
          openNotification('Falha ao atualizar imóvel, tente novamente mais tarde', NOTIFICATION_TYPE.error);
        });
    }
    setLoading(false);
  }

  const [imageCounter, setImageCounter] = useState([
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
      order: null,
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
      order: null,
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
      order: null,
    },
  ]);

  const [docCounter, setDocCounter] = useState([
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
  ]);

  const [pdf, setPdf] = useState([
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
  ]);

  function addImage(files, id) {
    if (!files.length) {
      return null;
    }
    if (files.length == 1) {
      const checkbox = document.querySelector(`input[name=checkbox-${id}]`);

      const url = URL.createObjectURL(files[0]);

      const value = imageCounter.find((value) => value.id === id);
      const clear = imageCounter.filter((value) => value.id !== id);

      value.image = files[0];
      value.url = url;
      value.show = checkbox.checked;
      value.name = null;

      setImageCounter([...clear, value]);
    } else {
      let arrayImage = [];
      imageCounter.map((row) => {
        if (row.image != null) {
          arrayImage.push(row);
        }
      });

      for (let i = 0; i < files.length; i++) {
        const url = URL.createObjectURL(files[i]);
        arrayImage.push({
          id: crypto
            .createHash("sha256")
            .update(Math.random().toString())
            .digest("hex"),
          image: files[i],
          url: url,
          show: true,
          name: null,
        });
      }

      setImageCounter(arrayImage);
    }

    setImageAlter(!imageAlter);
  }

  function removeImage(id) {
    const remove = imageCounter.filter((value) => value.id === id);
    if (remove[0].name) {
      api.post("/file/remove", { data: remove[0], id: ID });
    }
    const removed = imageCounter.filter((value) => value.id !== id);
    setImageCounter(removed);
  }


  function removeImageV2({ link: name }) {
    return api.post("/file/remove", { data: { name }, id: ID });
  }

  function editImageStatus(id, status) {
    const edited = imageCounter.map((value) => {
      if (value.id === id) {
        api.post("/file/changeShow", {
          status: `${status}`,
          name: value.name,
          id: ID,
        });
        value.show = `${status}`;
      }
      return value;
    });

    setImageCounter(edited);
    openNotification("Espere alguns segundos para atualizar outra imagem.", NOTIFICATION_TYPE.warning);
  }

  function addDoc(files, id) {
    if (!files.length) {
      return null;
    }

    if (files.length == 1) {
      const url = URL.createObjectURL(files[0]);

      const value = docCounter.find((value) => value.id === id);
      const clear = docCounter.filter((value) => value.id !== id);

      value.file = files[0];
      value.url = url;
      value.image = files[0];

      setDocCounter([...clear, value]);
    } else {
      let arrayDocs = [];
      docCounter.map((row) => {
        if (row.image != null) {
          arrayDocs.push(row);
        }
      });

      for (let i = 0; i < files.length; i++) {
        const url = URL.createObjectURL(files[i]);
        arrayDocs.push({
          id: crypto
            .createHash("sha256")
            .update(Math.random().toString())
            .digest("hex"),
          image: files[i],
          url: url,
          file: files[i],
        });
      }

      setDocCounter(arrayDocs);
    }

    setDocAlter(!docAlter);
  }

  function addPdf(files, id) {
    if (!files.length) {
      return null;
    }
    const url = URL.createObjectURL(files[0]);

    const value = pdf.find((value) => value.id === id);
    const clear = pdf.filter((value) => value.id !== id);

    value.file = files[0];
    value.url = url;
    value.image = files[0];

    setPdf([...clear, value]);


    sendPdf();
    // setPdfAlter(!pdfAlter);
  }
  function removePdf(id) {
    const remove = pdf.filter((value) => value.id === id);
    if (remove[0].name) {
      api.post("/file/pdfRemove", { pdf: remove[0].name, id: ID });
    }
    const removed = pdf.filter((value) => value.id !== id);
    if (removed.length == 0) {
      setPdf([
        {
          id: crypto
            .createHash("sha256")
            .update(Math.random().toString())
            .digest("hex"),
        },
      ]);


      document.getElementById(`doc-${id}`).value = "";
      return;
    }

    document.getElementById(`doc-${id}`).value = "";
    setPdf(removed);
  }

  function removeMem(id) {
    const remove = pdfMem.filter((value) => value.id === id);
    if (remove[0].name) {
      api.post("/file/memRemove", { memorial: remove[0].name, id: ID });
    }
    const removed = pdf.filter((value) => value.id !== id);
    if (removed.length == 0) {
      setPdfMem([
        {
          id: crypto
            .createHash("sha256")
            .update(Math.random().toString())
            .digest("hex"),
        },
      ]);


      document.getElementById(`doc-${id}`).value = "";
      return;
    }
    setPdfMem([
      {
        id: crypto
          .createHash("sha256")
          .update(Math.random().toString())
          .digest("hex"),
      },
    ]);


    document.getElementById(`doc-${id}`).value = "";
  }

  function addPdfMem(files, id) {
    if (!files.length) {
      return null;
    }

    const url = URL.createObjectURL(files[0]);

    const value = pdfMem.find((value) => value.id === id);
    const clear = pdfMem.filter((value) => value.id !== id);

    value.file = files[0];
    value.url = url;
    value.image = files[0];

    setPdfMem([...clear, value]);


    sendPdf();
    // setPdfAlter(!pdfAlter);
  }

  function removeDoc(id) {
    const remove = docCounter.filter((value) => value.id === id);
    if (remove[0].name) {
      api.post("/file/docRemove", { data: remove[0], id: ID });
    }
    const removed = docCounter.filter((value) => value.id !== id);
    setDocCounter(removed);
  }

  useEffect(() => {
    async function sendImages() {
      const filtered = imageCounter.filter((value) => {
        if (value.image && value.image != "teste") {
          return value;
        }
      });

      let formData;

      for (var i = 0; i < filtered.length;) {
        formData = new FormData();
        formData.append("show", filtered[i].show);
        formData.append("property", ID);
        formData.append("isUpdate", true);
        formData.append("file", filtered[i].image);
        setLoading(true);

        const res = await api.post("/file/image", formData);
        setLoading(false);
        let imagens = [];
        res.data.images.map((image) => {
          imagens.push({
            id: crypto
              .createHash("sha256")
              .update(Math.random().toString())
              .digest("hex"),
            url: `${process.env.REACT_APP_FILES_URL}/${res.data._id}/fotos/${encodeURIComponent(image.image)}`,
            image: "teste",
            name: image.image,
            show: image.show ? image.show : "true",
            order: image.order,
          });
        });

        setImageCounter(imagens);
        i++;
      }

      imageCounter.map((e) => {
        e.image = "teste";
      });
    }

    sendImages();
  }, [imageAlter]);

  useEffect(() => {
    // sendPdf();
  }, [pdfAlter]);

  useEffect(() => {
    sendDocs();
  }, [docAlter]);

  function moneyToNumber(value) {
    value = value
      .replaceAll("R", "")
      .replaceAll("$", "")
      .replaceAll(" ", "")
      .replaceAll(".", "")
      .replaceAll(",", "");

    return (
      value.substr(1, value.length - 3) +
      "." +
      value.substr(value.length - 2, 2)
    );
  }

  function maskIptu(value) {
    if (value.length == 8) {
      return (
        value.substr(0, 1) +
        "." +
        value.substr(1, 3) +
        "." +
        value.substr(4, 3) +
        "-" +
        value.substr(7, 1)
      );
    }
    return value;
  }

  function money(value) {
    value = String(value);
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(+value.replace(/\D+/g, "") / 100);
  }

  const getNameImageCounter = () => {
    let imagesName = []
    imageCounter.map((value) => {
      imagesName.push({
        image: value.name
      })
    })

    return imagesName
  }



  const Main = ({ children }) => {
    return (<>
      {loading && <Loading />}
      {photoModal &&
        <PhotoModal
          images={getNameImageCounter()}
          id={data._id}
          setModal={setPhotoModal}
        />}
      <Header />
      <Aside />
      <Container>
        <div className="bread-crumb">
          <h1 className="category">ANÚNCIOS&nbsp;{">"}&nbsp;</h1>
          <Link to={window.location.pathname} className="where">
            Anúncios Corretores&nbsp;{">"}&nbsp;
          </Link>
          <Link to={window.location.pathname} className="where">
            &nbsp;Editar
          </Link>
        </div>
        {children}
      </Container>
    </>);
  }

  const GeneralTab = () => {
    return (
      <>
        <div className="input-group">
          <PlaceField
            isSeparatedFields={false}
            className="input-compose"
            stateValue={fullAddress}
            setValue={(value) => {
              setFullAddress(value);
            }}
            number={propertyPlace.addressNumber}
            setNumber={(value) => {
              setPropertyPlace({
                addressNumber: value
              })
            }}
            setComplement={(value) => {
              if (value)
                setPropertyPlace({
                  addressComplement: value,
                })
            }}

            onChangeVerification={() => setConfirm(true)}
            placeData={propertyPlace}
            setPlace={(value) => {
              setConfirm(true);
              setPropertyPlace(value);
            }}
          />
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>CEP</label>
            <input
              type="text"
              disabled={true}
              value={property.postalCode || ''}
            />
          </div>
          <div className="input-compose">
            <label>Complemento</label>
            <input
              type="text"
              value={property.addressComplement}
              onChange={(e) => {
                setProperty({
                  ...property,
                  addressComplement: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className="input-group">
          <div className="select-group">
            <input
              type="checkbox"
              name=""
              id=""
              checked={!property.showNumber}
              onChange={(e) => {
                setProperty({
                  ...property,
                  showNumber: !e.target.checked,
                });
              }}
            />
            <span>Não desejo exibir o número no anúncio</span>
          </div>
          <div className="select-group">
            <input
              type="checkbox"
              name=""
              id=""
              checked={!property.showComplement}
              onChange={(e) => {
                setProperty({
                  ...property,
                  showComplement: !e.target.checked,
                });
              }}
            />
            <span>Não desejo exibir o complemento no anúncio</span>
          </div>
        </div>
        <div className="input-group" style={{ marginTop: "30px" }}>
          <div className="input-compose">
            <label>Estado</label>
            <input
              autoComplete="off"
              type="text"
              disabled={true}
              value={property?.state || property?.stateName || ''}
            />
          </div>
          <div className="input-compose">
            <label>Cidade</label>
            <input
              autoComplete="off"
              type="text"
              disabled={true}
              value={property?.city?.name || property?.cityName || ''}
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>Bairro</label>
            <select
              style={{ 'fontSize': '14px' }}
              value={property?.neighborhood?.name || property?.neighborhoodName}
              onChange={e => {
                setConfirm(true);
                setProperty({ ...property, neighborhoodName: e.target.value, neighborhood: undefined });

              }}>
              <option></option>
              {
                neighborhoods.map(({ name, _id }) => (
                  <option key={_id.toString()} value={name}>{name}</option>
                ))
              }
            </select>
          </div>
          <div className="input-compose">
            <label>Valor do condomínio</label>
            <input
              type="text"
              value={money(condominio)}
              onChange={(e) => {
                setCondominio(moneyToNumber(e.target.value));
              }}
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>IPTU Valor</label>
            <input
              type="text"
              value={money(iptu)}
              onChange={(e) => {
                setIptu(moneyToNumber(e.target.value));
              }}
            />
          </div>
          <div className="input-compose">
            <label>IPTU Numero</label>
            <input
              type="text"
              maxLength={11}
              value={property.IPTUNumber}
              onChange={(e) => {
                setProperty({
                  ...property,
                  IPTUNumber: maskIptu(e.target.value),
                });
              }}
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>Valor de venda do imóvel</label>
            <input
              type="text"
              value={money(String(vendaImovel))}
              onChange={(e) => {
                setVendaImovel(moneyToNumber(e.target.value));
              }}
            />
          </div>
          <div className="input-compose">
            <label>Valor de venda com desconto</label>
            <input
              type="text"
              value={money(String(descontoImovel))}
              onChange={(e) => {
                setDescontoImovel(moneyToNumber(e.target.value));

                if (
                  Number(moneyToNumber(e.target.value)) >
                  Number(moneyToNumber(money(vendaImovel)))
                ) {
                  setDescontoImovel(vendaImovel);
                  openNotification(
                    "O desconto não pode ser maior que o valor original.",
                    NOTIFICATION_TYPE.warning
                  );
                  return;
                }
              }}
            />
          </div>
        </div>

        <h1>Configurações de Venda</h1>
        <div className="input-group" style={{ flexFlow: "column" }}>
          <div className="input-compose">
            <p>Qual a comissão compartilhada em %?</p>
            <input
              type="text"
              //disabled={!property.sharedSale}
              value={property.comission}
              onChange={(e) => {
                setProperty({
                  ...property,
                  comission: e.target.value,
                });
              }}
            />
          </div>
          <div
            className="input-compose"
            style={{ width: "100%" }}
            hidden={!property.useApp}
          >
            <p>Fechadura</p>
            <select
              value={property.lockId}
              onChange={(e) => {
                setProperty({
                  ...property,
                  lockId: Number(e.target.value), //marcação
                });
              }}
            >
              <option value={0}>Não definido</option>
              {lockList?.length > 0 &&
                lockList.map((row) => {
                  return <option key={row._id} value={row._id}>{row.name}</option>;
                })}
            </select>
          </div>
          <div className=""></div>
        </div>
        <div
          className="share"
          style={{
            borderTop: 0,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div className="column">
            <div className="radio-group">
              <input
                checked={!property.useApp}
                type="radio"
                onChange={() => {
                  setProperty({
                    ...property,
                    useApp: false,
                    lockId: "0",
                  });
                }}
              />
              <h2>&nbsp;Método Esquema de Chave</h2>
              <br />
            </div>
            <textarea
              rows={10}
              placeholder="Escreva as instruções de venda para o método de esquema de chave"
              value={property.sellingNotes}
              onChange={(e) => {
                setProperty({
                  ...property,
                  sellingNotes: e.target.value,
                });
              }}
            ></textarea>
          </div>
          <div className="column">
            <div className="radio-group">
              <input
                checked={property.useApp}
                type="radio"
                onChange={() => {
                  setProperty({
                    ...property,
                    useApp: true,
                  });
                }}
              />
              <h2>&nbsp;Método Abrir Porta pelo App</h2>
              <br />
            </div>
            <textarea
              rows={10}
              placeholder="Escreva as instruções de venda para o método de abertura pelo APP"
              value={property.sellingNotesApp}
              onChange={(e) => {
                setProperty({
                  ...property,
                  sellingNotesApp: e.target.value,
                });
              }}
            ></textarea>
          </div>
        </div>

        <div className="input-group">
          <div className="input-compose">
            <label>Residencial ou comercial</label>
            <select
              value={property.generalType}
              onChange={(e) => {
                setProperty({
                  ...property,
                  generalType:
                    e.target.value != "Selecione" ? e.target.value : null,
                });
              }}
            >
              <option value="Selecione">Selecione</option>
              {generalPropertyType.map((value) => (
                <option key={value._id} value={value._id}>{value.name}</option>
              ))}
            </select>
          </div>
          <div className="input-compose">
            <label>Tipo</label>
            <select
              value={property.type}
              onChange={(e) => {
                setProperty({
                  ...property,
                  type: e.target.value,
                });
              }}
            >
              {types.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>Metragem (m²)</label>
            <input
              type="number"
              value={property.fullLandSize}
              onChange={(e) => {
                setProperty({
                  ...property,
                  fullLandSize: e.target.value,
                });
              }}
            />
          </div>
          <div className="input-compose">
            <label>Total quartos (Exceto dependências)</label>
            <input
              type="number"
              value={property.bedrooms}
              onChange={(e) =>
                setProperty({
                  ...property,
                  bedrooms: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>Sendo quantas Suites?</label>
            <input
              type="number"
              value={property.suites}
              onChange={(e) =>
                setProperty({
                  ...property,
                  suites: e.target.value,
                })
              }
            />
          </div>
          <div className="input-compose">
            <label>Total de banheiros (Exceto dependências)</label>
            <input
              type="number"
              value={property.bathrooms}
              onChange={(e) =>
                setProperty({
                  ...property,
                  bathrooms: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>Total de dependências de serviço</label>
            <input
              type="number"
              value={property.dependencies}
              onChange={(e) =>
                setProperty({
                  ...property,
                  dependencies: e.target.value,
                })
              }
            />
          </div>
          <div className="input-compose">
            <label>Número de vagas</label>
            <input
              type="number"
              value={property.parkingSpots}
              onChange={(e) =>
                setProperty({
                  ...property,
                  parkingSpots: e.target.value,
                })
              }
            />
          </div>
        </div>
        <h1>Selecione todas as características deste imóvel</h1>
        <Picker
          value={property.features}
          setValue={(value) => setProperty({ ...property, features: value })}
        />
        <div className="tag" style={{ marginTop: "30px" }}>
          <h2>Escolha uma TAG que melhor represente esta oportunidade</h2>
          <p>
            Esta TAG será aplicada eventualmente no seu anúncio para destacá-lo.
          </p>
          <SwitchPartnerProperties
            checkedPartnerProperty={checkedPartnerProperty}
            onChangePartnerProperty={onChangePartnerProperty} />
          <div className="input-group">
            <div className="input-compose">
              <label>TAG</label>
              <select
                value={property.tag}
                onChange={(e) =>
                  setProperty({
                    ...property,
                    tag:
                      e.target.value != "Selecione" ? e.target.value : "nulle",
                  })
                }
              >
                <option>Selecione</option>
                {tags.map((value) => (
                  <option value={value._id} key={value._id}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            className="select-group"
            style={{ marginBottom: 20, marginTop: -5 }}
          >
            <input
              type="checkbox"
              name=""
              id=""
              checked={property.showTag}
              onChange={(e) => {
                setProperty({
                  ...property,
                  showTag: e.target.checked,
                });
              }}
            />
            <span style={{ marginLeft: 10 }}>
              Marque para forçar a aplicação desta tag no imóvel
            </span>
          </div>

          <h2>Descreva o seu imóvel (opcional){showDescriptionCode ? <Button type="text" onClick={() => setShowDescriptionCode(false)}><DesktopOutlined />Exibir Pré-visualização</Button> : <Button type="text" onClick={() => setShowDescriptionCode(true)}><CodeOutlined />Exibir Código Fonte</Button>}</h2>
          {showDescriptionCode ?
            <div className="input-group">
              <div className="input-compose">
                <textarea
                  rows={5}
                  placeholder="Insira o código fonte aqui"
                  value={property.description}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      description: e.target.value,
                    });
                  }}
                ></textarea>

              </div>
            </div>
            : <TextEditor text={property.description} handlerText={(val) => {
              setProperty({
                ...property,
                description: val,
              });
            }} />}

          <h2>Sugestão de descrição para anúncios (opcional){showSuggestionCode ? <Button type="text" onClick={() => setShowSuggestionCode(false)}><DesktopOutlined />Exibir Pré-visualização</Button> : <Button type="text" onClick={() => setShowSuggestionCode(true)}><CodeOutlined />Exibir Código Fonte</Button>}</h2>
          {showSuggestionCode ?
            <div className="input-group">
              <div className="input-compose">
                <textarea
                  rows={5}
                  placeholder="Insira o código fonte aqui"
                  value={property.descriptionSuggestion}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      descriptionSuggestion: e.target.value,
                    });
                  }}
                ></textarea>

              </div>
            </div>
            : <TextEditor text={property.descriptionSuggestion} handlerText={(val) => {
              setProperty({
                ...property,
                descriptionSuggestion: val,
              });
            }} />}
        </div>

        <h2>Incorporar vídeo (opcional){showVideoCode ? <Button type="text" onClick={() => setShowVideoCode(false)}><VideoCameraOutlined />Exibir Pré-visualização</Button> : <Button type="text" onClick={() => setShowVideoCode(true)}><CodeOutlined />Exibir Código Fonte</Button>}</h2>
        {showVideoCode ?
          <div className="input-group">
            <div className="input-compose">
              <textarea
                rows={5}
                placeholder="Insira o código fonte aqui"
                value={property.videoLink}
                onChange={(e) => {
                  setProperty({
                    ...property,
                    videoLink: e.target.value,
                  });
                }}
              ></textarea>

            </div>
          </div>
          : <TextEditor
            isEmpty={true}
            text={property.videoLink}
            handlerText={(val) => {
              setProperty({
                ...property,
                videoLink: val,
              });
            }}
          />}

        <div className="input-group">
          <div className="input-compose">
            <label>Link de fotos para download (opcional)</label>
            <input
              type="text"
              placeholder="Insira o link do drive ex: https://drive.google.com/file/d/iv01234"
              value={property?.driveLink}
              onChange={(e) =>
                setProperty({
                  ...property,
                  driveLink: e.target.value,
                })
              }
            />
          </div>
        </div>

        <h2>
          Adicione documentos. Se preferir, pode adicionar
          posteriormente.
        </h2>

        {/* <Carousel style={{ marginTop: "30px" }}>
          {imageCounter.map((value, index) => (
            <Item
              key={index}
              draggable={true}
              id={index}
              onDragOver={(e) => e.preventDefault()}
              onDragStart={(f) => setItemStart(f.target.id)}
              onDropCapture={handleOrdenation}
            >
              <ImageItem
                htmlFor={`image-${value.id}`}
                image={value.url ? `'${value.url}'` : ""}
                className="image-container"
                onClick={(e) => {
                  if (value.url !== undefined) {
                    setPhotoModal(true)
                  }
                }
                }
              >
                {value.url === undefined && <input
                  multiple="multiple"
                  disabled={value.image == "teste" ? true : false}
                  type="file"
                  name="image"
                  id={`image-${value.id}`}
                  onInput={(e) => addImage(e.target.files, value.id)}
                />}
                <button
                  className="close"
                  show={value.image ? "true" : undefined}
                  onClick={() => {
                    if (value.image == "teste") {
                      if (
                        window.confirm(
                          "A imagem será deletada, deseja continuar?"
                        )
                      ) {
                        removeImage(value.id);
                      }
                    } else {
                      removeImage(value.id);
                    }
                  }}
                >
                  x
                </button>
              </ImageItem>
              <DownloadLink url={value.url} name={value?.name}>
                [Baixar foto]
              </DownloadLink>
              <div className="row" style={{ marginTop: "20px" }}>
                <input
                  type="checkbox"
                  name={`checkbox-${value.id}`}
                  id=""
                  checked={value.show == "false" ? false : true}
                  onChange={(e) => {
                    editImageStatus(value.id, e.target.checked);
                  }}
                />
                <label style={{ fontWeight: "normal" }}>
                  Exibir no anúncio
                </label>
              </div>
            </Item>
          ))}
          <BtnWrapper>
            <PlusBtn
              onClick={() =>
                setImageCounter([
                  ...imageCounter,
                  {
                    id: crypto
                      .createHash("sha256")
                      .update(Math.random().toString())
                      .digest("hex"),
                  },
                ])
              }
            >
              <img
                src={require("../../assets/adminOfferProperty/plus.svg")}
                alt=""
              />
            </PlusBtn>
          </BtnWrapper>
        </Carousel> */}

        <Carousel style={{ marginTop: "30px" }}>
          {docCounter.map((value, index) => (
            <Item key={index}>
              <DocItem
                htmlFor={`doc-${value.id}`}
                image={
                  value.file || value.image == "teste"
                    ? require("../../assets/adminOfferProperty/docok.svg")
                    : ""
                }
                className="doc-container"
              >
                <input
                  multiple="multiple"
                  type="file"
                  disabled={value.image == "teste" ? true : false}
                  name="doc"
                  id={`doc-${value.id}`}
                  onInput={(e) => addDoc(e.target.files, value.id)}
                />
                <button
                  className="close"
                  show={value.file ? "true" : "false"}
                  onClick={() => {
                    if (value.image == "teste") {
                      if (
                        window.confirm(
                          "O documento será deletado, deseja continuar?"
                        )
                      ) {
                        removeDoc(value.id);
                      }
                    } else {
                      removeDoc(value.id);
                    }
                  }}
                >
                  x
                </button>
              </DocItem>
              <div className="row" style={{ marginTop: "20px" }}>
                <DownloadLink url={value.url} name={value?.name}></DownloadLink>
              </div>
            </Item>
          ))}
          <BtnWrapper>
            <PlusBtn
              onClick={() =>
                setDocCounter([
                  ...docCounter,
                  {
                    id: crypto
                      .createHash("sha256")
                      .update(Math.random().toString())
                      .digest("hex"),
                  },
                ])
              }
            >
              <img
                src={require("../../assets/adminOfferProperty/plus.svg")}
                alt=""
              />
            </PlusBtn>
          </BtnWrapper>
        </Carousel>
        <Carousel style={{ marginTop: "30px" }}>
          {pdf.map((value, index) => (
            <Item key={index}>
              <BookItem
                htmlFor={`doc-${value.id}`}
                image={
                  value.file || value.image == "teste"
                    ? require("../../assets/adminOfferProperty/docok.svg")
                    : ""
                }
                className="doc-container"
              >
                <input
                  type="file"
                  disabled={value.image == "teste" ? true : false}
                  name="doc"
                  id={`doc-${value.id}`}
                  onInput={(e) => addPdf(e.target.files, value.id)}
                />
                <button
                  className="close"
                  show={value.file ? "true" : "false"}
                  onClick={() => {
                    if (value.image == "teste") {
                      if (
                        window.confirm(
                          "O documento será deletado, deseja continuar?"
                        )
                      ) {
                        removePdf(value.id);
                      }
                    } else {
                      removePdf(value.id);
                    }
                  }}
                >
                  x
                </button>
                <div>
                  Adicione Book
                  <br />
                  (opcional)
                </div>
              </BookItem>
              <div className="row" style={{ marginTop: "20px" }}>
                {pdf[0].url ? (
                  <DownloadLink url={pdf[0].url} name={value?.name}>
                    [Visualizar Book]
                  </DownloadLink>
                ) : (
                  <label style={{ fontWeight: "normal" }}>
                    {value.file && value.image != "teste"
                      ? value.file.name
                      : ""}
                    {!value.file && value.image == "teste" ? value.name : ""}
                  </label>
                )}
              </div>
            </Item>
          ))}
          {pdfMem.map((value, index) => (
            <Item key={index}>
              <BookItem
                htmlFor={`doc-${value.id}`}
                image={
                  value.file || value.image == "teste"
                    ? require("../../assets/adminOfferProperty/docok.svg")
                    : ""
                }
                className="doc-container"
              >
                <input
                  type="file"
                  disabled={value.image == "teste" ? true : false}
                  name="doc"
                  id={`doc-${value.id}`}
                  onInput={(e) => addPdfMem(e.target.files, value.id)}
                />
                <button
                  className="close"
                  show={value.file ? "true" : "false"}
                  onClick={() => {
                    if (value.image == "teste") {
                      if (
                        window.confirm(
                          "O documento será deletado, deseja continuar?"
                        )
                      ) {
                        removeMem(value.id);
                      }
                    } else {
                      removeMem(value.id);
                    }
                  }}
                >
                  x
                </button>
                <div>
                  Adicione Memorial
                  <br />
                  (opcional)
                </div>
              </BookItem>
              <div className="row" style={{ marginTop: "20px" }}>
                {pdfMem[0].url ? (
                  <DownloadLink url={pdfMem[0].url} name={value?.name}>
                    [Visualizar Memorial]
                  </DownloadLink>
                ) : (
                  <label style={{ fontWeight: "normal" }}>
                    {value.file && value.image != "teste"
                      ? value.file.name
                      : ""}
                    {!value.file && value.image == "teste" ? value.name : ""}
                  </label>
                )}
              </div>
            </Item>
          ))}
        </Carousel>

        <h2>Vendedor</h2>
        <h4 style={{ marginBottom: 20 }}>
          Preencha para exibir na lista de imóveis vendidos
        </h4>

        <div className="input-group">
          <div
            className="input-compose"
            style={{ width: window.innerWidth <= 600 ? "100%" : "50%" }}
          >
            <label>Nome do vendedor</label>
            <input
              value={property.vendedor}
              onChange={(e) =>
                setProperty({
                  ...property,
                  vendedor: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="save">
          <button className="save-button" onClick={create} disabled={loading}>
            {loading ? "Salvando ..." : "Salvar"}
          </button>
        </div>
      </>
    );
  };

  /*
        formData.append("show", filtered[i].show);
        formData.append("property", ID);
        formData.append("isUpdate", true);
        formData.append("file", filtered[i].image);
   */
  const menuTabs = [
    {
      key: 'general',
      label: 'Geral',
      children: <>
        <div className="input-group">
          <PlaceField
            isSeparatedFields={false}
            className="input-compose"
            stateValue={fullAddress}
            setValue={(value) => {
              setFullAddress(value);
            }}
            number={propertyPlace.addressNumber}
            setNumber={(value) => {
              setPropertyPlace({
                addressNumber: value
              })
            }}
            setComplement={(value) => {
              if (value)
                setPropertyPlace({
                  addressComplement: value,
                })
            }}

            onChangeVerification={() => setConfirm(true)}
            placeData={propertyPlace}
            setPlace={(value) => {
              setConfirm(true);
              setPropertyPlace(value);
            }}
          />
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>CEP</label>
            <input
              type="text"
              disabled={true}
              value={property.postalCode || ''}
            />
          </div>
          <div className="input-compose">
            <label>Complemento</label>
            <input
              type="text"
              value={property.addressComplement}
              onChange={(e) => {
                setProperty({
                  ...property,
                  addressComplement: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className="input-group">
          <div className="select-group">
            <input
              type="checkbox"
              name=""
              id=""
              checked={!property.showNumber}
              onChange={(e) => {
                setProperty({
                  ...property,
                  showNumber: !e.target.checked,
                });
              }}
            />
            <span>Não desejo exibir o número no anúncio</span>
          </div>
          <div className="select-group">
            <input
              type="checkbox"
              name=""
              id=""
              checked={!property.showComplement}
              onChange={(e) => {
                setProperty({
                  ...property,
                  showComplement: !e.target.checked,
                });
              }}
            />
            <span>Não desejo exibir o complemento no anúncio</span>
          </div>
        </div>
        <div className="input-group" style={{ marginTop: "30px" }}>
          <div className="input-compose">
            <label>Estado</label>
            <input
              autoComplete="off"
              type="text"
              disabled={true}
              value={property?.state || property?.stateName || ''}
            />
          </div>
          <div className="input-compose">
            <label>Cidade</label>
            <input
              autoComplete="off"
              type="text"
              disabled={true}
              value={property?.city?.name || property?.cityName || ''}
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>Bairro</label>
            <select
              style={{ 'fontSize': '14px' }}
              value={property?.neighborhood?.name || property?.neighborhoodName}
              onChange={e => {
                setConfirm(true);
                setProperty({ ...property, neighborhoodName: e.target.value, neighborhood: undefined });

              }}>
              <option></option>
              {
                neighborhoods.map(({ name, _id }) => (
                  <option key={_id.toString()} value={name}>{name}</option>
                ))
              }
            </select>
          </div>
          <div className="input-compose">
            <label>Valor do condomínio</label>
            <input
              type="text"
              value={money(condominio)}
              onChange={(e) => {
                setCondominio(moneyToNumber(e.target.value));
              }}
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>IPTU Valor</label>
            <input
              type="text"
              value={money(iptu)}
              onChange={(e) => {
                setIptu(moneyToNumber(e.target.value));
              }}
            />
          </div>
          <div className="input-compose">
            <label>IPTU Numero</label>
            <input
              type="text"
              maxLength={11}
              value={property.IPTUNumber}
              onChange={(e) => {
                setProperty({
                  ...property,
                  IPTUNumber: maskIptu(e.target.value),
                });
              }}
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>Valor de venda do imóvel</label>
            <input
              type="text"
              value={money(String(vendaImovel))}
              onChange={(e) => {
                setVendaImovel(moneyToNumber(e.target.value));
              }}
            />
          </div>
          <div className="input-compose">
            <label>Valor de venda com desconto</label>
            <input
              type="text"
              value={money(String(descontoImovel))}
              onChange={(e) => {
                setDescontoImovel(moneyToNumber(e.target.value));

                if (
                  Number(moneyToNumber(e.target.value)) >
                  Number(moneyToNumber(money(vendaImovel)))
                ) {
                  setDescontoImovel(vendaImovel);
                  openNotification(
                    "O desconto não pode ser maior que o valor original.",
                    NOTIFICATION_TYPE.warning
                  );
                  return;
                }
              }}
            />
          </div>
        </div>

        <h1>Configurações de Venda</h1>
        <div className="input-group" style={{ flexFlow: "column" }}>
          <div className="input-compose">
            <p>Qual a comissão compartilhada em %?</p>
            <input
              type="text"
              //disabled={!property.sharedSale}
              value={property.comission}
              onChange={(e) => {
                setProperty({
                  ...property,
                  comission: e.target.value,
                });
              }}
            />
          </div>
          <div
            className="input-compose"
            style={{ width: "100%" }}
            hidden={!property.useApp}
          >
            <p>Fechadura</p>
            <select
              value={property.lockId}
              onChange={(e) => {
                setProperty({
                  ...property,
                  lockId: Number(e.target.value), //marcação
                });
              }}
            >
              <option value={0}>Não definido</option>
              {lockList?.length > 0 &&
                lockList.map((row) => {
                  return <option key={row._id} value={row._id}>{row.name}</option>;
                })}
            </select>
          </div>
          <div className=""></div>
        </div>
        <div
          className="share"
          style={{
            borderTop: 0,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div className="column">
            <div className="radio-group">
              <input
                checked={!property.useApp}
                type="radio"
                onChange={() => {
                  setProperty({
                    ...property,
                    useApp: false,
                    lockId: "0",
                  });
                }}
              />
              <h2>&nbsp;Método Esquema de Chave</h2>
              <br />
            </div>
            <textarea
              rows={10}
              placeholder="Escreva as instruções de venda para o método de esquema de chave"
              value={property.sellingNotes}
              onChange={(e) => {
                setProperty({
                  ...property,
                  sellingNotes: e.target.value,
                });
              }}
            ></textarea>
          </div>
          <div className="column">
            <div className="radio-group">
              <input
                checked={property.useApp}
                type="radio"
                onChange={() => {
                  setProperty({
                    ...property,
                    useApp: true,
                  });
                }}
              />
              <h2>&nbsp;Método Abrir Porta pelo App</h2>
              <br />
            </div>
            <textarea
              rows={10}
              placeholder="Escreva as instruções de venda para o método de abertura pelo APP"
              value={property.sellingNotesApp}
              onChange={(e) => {
                setProperty({
                  ...property,
                  sellingNotesApp: e.target.value,
                });
              }}
            ></textarea>
          </div>
        </div>

        <div className="input-group">
          <div className="input-compose">
            <label>Residencial ou comercial</label>
            <select
              value={property.generalType}
              onChange={(e) => {
                setProperty({
                  ...property,
                  generalType:
                    e.target.value != "Selecione" ? e.target.value : null,
                });
              }}
            >
              <option value="Selecione">Selecione</option>
              {generalPropertyType.map((value) => (
                <option key={value._id} value={value._id}>{value.name}</option>
              ))}
            </select>
          </div>
          <div className="input-compose">
            <label>Tipo</label>
            <select
              value={property.type}
              onChange={(e) => {
                setProperty({
                  ...property,
                  type: e.target.value,
                });
              }}
            >
              {types.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>Metragem (m²)</label>
            <input
              type="number"
              value={property.fullLandSize}
              onChange={(e) => {
                setProperty({
                  ...property,
                  fullLandSize: e.target.value,
                });
              }}
            />
          </div>
          <div className="input-compose">
            <label>Total quartos (Exceto dependências)</label>
            <input
              type="number"
              value={property.bedrooms}
              onChange={(e) =>
                setProperty({
                  ...property,
                  bedrooms: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>Sendo quantas Suites?</label>
            <input
              type="number"
              value={property.suites}
              onChange={(e) =>
                setProperty({
                  ...property,
                  suites: e.target.value,
                })
              }
            />
          </div>
          <div className="input-compose">
            <label>Total de banheiros (Exceto dependências)</label>
            <input
              type="number"
              value={property.bathrooms}
              onChange={(e) =>
                setProperty({
                  ...property,
                  bathrooms: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>Total de dependências de serviço</label>
            <input
              type="number"
              value={property.dependencies}
              onChange={(e) =>
                setProperty({
                  ...property,
                  dependencies: e.target.value,
                })
              }
            />
          </div>
          <div className="input-compose">
            <label>Número de vagas</label>
            <input
              type="number"
              value={property.parkingSpots}
              onChange={(e) =>
                setProperty({
                  ...property,
                  parkingSpots: e.target.value,
                })
              }
            />
          </div>
        </div>
        <h1>Selecione todas as características deste imóvel</h1>
        <Picker
          value={property.features}
          setValue={(value) => setProperty({ ...property, features: value })}
        />
        <div className="tag" style={{ marginTop: "30px" }}>
          <h2>Escolha uma TAG que melhor represente esta oportunidade</h2>
          <p>
            Esta TAG será aplicada eventualmente no seu anúncio para destacá-lo.
          </p>
          <SwitchPartnerProperties
            checkedPartnerProperty={checkedPartnerProperty}
            onChangePartnerProperty={onChangePartnerProperty} />
          <div className="input-group">
            <div className="input-compose">
              <label>TAG</label>
              <select
                value={property.tag}
                onChange={(e) =>
                  setProperty({
                    ...property,
                    tag:
                      e.target.value != "Selecione" ? e.target.value : "nulle",
                  })
                }
              >
                <option>Selecione</option>
                {tags.map((value) => (
                  <option value={value._id} key={value._id}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            className="select-group"
            style={{ marginBottom: 20, marginTop: -5 }}
          >
            <input
              type="checkbox"
              name=""
              id=""
              checked={property.showTag}
              onChange={(e) => {
                setProperty({
                  ...property,
                  showTag: e.target.checked,
                });
              }}
            />
            <span style={{ marginLeft: 10 }}>
              Marque para forçar a aplicação desta tag no imóvel
            </span>
          </div>

          <h2>Descreva o seu imóvel (opcional){showDescriptionCode ? <Button type="text" onClick={() => setShowDescriptionCode(false)}><DesktopOutlined />Exibir Pré-visualização</Button> : <Button type="text" onClick={() => setShowDescriptionCode(true)}><CodeOutlined />Exibir Código Fonte</Button>}</h2>
          {showDescriptionCode ?
            <div className="input-group">
              <div className="input-compose">
                <textarea
                  rows={5}
                  placeholder="Insira o código fonte aqui"
                  value={property.description}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      description: e.target.value,
                    });
                  }}
                ></textarea>

              </div>
            </div>
            : <TextEditor text={property.description} handlerText={(val) => {
              setProperty({
                ...property,
                description: val,
              });
            }} />}

          <h2>Sugestão de descrição para anúncios (opcional){showSuggestionCode ? <Button type="text" onClick={() => setShowSuggestionCode(false)}><DesktopOutlined />Exibir Pré-visualização</Button> : <Button type="text" onClick={() => setShowSuggestionCode(true)}><CodeOutlined />Exibir Código Fonte</Button>}</h2>
          {showSuggestionCode ?
            <div className="input-group">
              <div className="input-compose">
                <textarea
                  rows={5}
                  placeholder="Insira o código fonte aqui"
                  value={property.descriptionSuggestion}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      descriptionSuggestion: e.target.value,
                    });
                  }}
                ></textarea>

              </div>
            </div>
            : <TextEditor text={property.descriptionSuggestion} handlerText={(val) => {
              setProperty({
                ...property,
                descriptionSuggestion: val,
              });
            }} />}
        </div>

        <h2>Incorporar vídeo (opcional){showVideoCode ? <Button type="text" onClick={() => setShowVideoCode(false)}><VideoCameraOutlined />Exibir Pré-visualização</Button> : <Button type="text" onClick={() => setShowVideoCode(true)}><CodeOutlined />Exibir Código Fonte</Button>}</h2>
        {showVideoCode ?
          <div className="input-group">
            <div className="input-compose">
              <textarea
                rows={5}
                placeholder="Insira o código fonte aqui"
                value={property.videoLink}
                onChange={(e) => {
                  setProperty({
                    ...property,
                    videoLink: e.target.value,
                  });
                }}
              ></textarea>

            </div>
          </div>
          : <TextEditor
            isEmpty={true}
            text={property.videoLink}
            handlerText={(val) => {
              setProperty({
                ...property,
                videoLink: val,
              });
            }}
          />}

        <div className="input-group">
          <div className="input-compose">
            <label>Link de fotos para download (opcional)</label>
            <input
              type="text"
              placeholder="Insira o link do drive ex: https://drive.google.com/file/d/iv01234"
              value={property?.driveLink}
              onChange={(e) =>
                setProperty({
                  ...property,
                  driveLink: e.target.value,
                })
              }
            />
          </div>
        </div>

        <h2>
          Adicione documentos. Se preferir, pode adicionar
          posteriormente.
        </h2>

        <Carousel style={{ marginTop: "30px" }}>
          {docCounter.map((value, index) => (
            <Item key={index}>
              <DocItem
                htmlFor={`doc-${value.id}`}
                image={
                  value.file || value.image == "teste"
                    ? require("../../assets/adminOfferProperty/docok.svg")
                    : ""
                }
                className="doc-container"
              >
                <input
                  multiple="multiple"
                  type="file"
                  disabled={value.image == "teste" ? true : false}
                  name="doc"
                  id={`doc-${value.id}`}
                  onInput={(e) => addDoc(e.target.files, value.id)}
                />
                <button
                  className="close"
                  show={value.file ? "true" : "false"}
                  onClick={() => {
                    if (value.image == "teste") {
                      if (
                        window.confirm(
                          "O documento será deletado, deseja continuar?"
                        )
                      ) {
                        removeDoc(value.id);
                      }
                    } else {
                      removeDoc(value.id);
                    }
                  }}
                >
                  x
                </button>
              </DocItem>
              <div className="row" style={{ marginTop: "20px" }}>
                <DownloadLink url={value.url} name={value?.name}></DownloadLink>
              </div>
            </Item>
          ))}
          <BtnWrapper>
            <PlusBtn
              onClick={() =>
                setDocCounter([
                  ...docCounter,
                  {
                    id: crypto
                      .createHash("sha256")
                      .update(Math.random().toString())
                      .digest("hex"),
                  },
                ])
              }
            >
              <img
                src={require("../../assets/adminOfferProperty/plus.svg")}
                alt=""
              />
            </PlusBtn>
          </BtnWrapper>
        </Carousel>
        <Carousel style={{ marginTop: "30px" }}>
          {pdf.map((value, index) => (
            <Item key={index}>
              <BookItem
                htmlFor={`doc-${value.id}`}
                image={
                  value.file || value.image == "teste"
                    ? require("../../assets/adminOfferProperty/docok.svg")
                    : ""
                }
                className="doc-container"
              >
                <input
                  type="file"
                  disabled={value.image == "teste" ? true : false}
                  name="doc"
                  id={`doc-${value.id}`}
                  onInput={(e) => addPdf(e.target.files, value.id)}
                />
                <button
                  className="close"
                  show={value.file ? "true" : "false"}
                  onClick={() => {
                    if (value.image == "teste") {
                      if (
                        window.confirm(
                          "O documento será deletado, deseja continuar?"
                        )
                      ) {
                        removePdf(value.id);
                      }
                    } else {
                      removePdf(value.id);
                    }
                  }}
                >
                  x
                </button>
                <div>
                  Adicione Book
                  <br />
                  (opcional)
                </div>
              </BookItem>
              <div className="row" style={{ marginTop: "20px" }}>
                {pdf[0].url ? (
                  <DownloadLink url={pdf[0].url} name={value?.name}>
                    [Visualizar Book]
                  </DownloadLink>
                ) : (
                  <label style={{ fontWeight: "normal" }}>
                    {value.file && value.image != "teste"
                      ? value.file.name
                      : ""}
                    {!value.file && value.image == "teste" ? value.name : ""}
                  </label>
                )}
              </div>
            </Item>
          ))}
          {pdfMem.map((value, index) => (
            <Item key={index}>
              <BookItem
                htmlFor={`doc-${value.id}`}
                image={
                  value.file || value.image == "teste"
                    ? require("../../assets/adminOfferProperty/docok.svg")
                    : ""
                }
                className="doc-container"
              >
                <input
                  type="file"
                  disabled={value.image == "teste" ? true : false}
                  name="doc"
                  id={`doc-${value.id}`}
                  onInput={(e) => addPdfMem(e.target.files, value.id)}
                />
                <button
                  className="close"
                  show={value.file ? "true" : "false"}
                  onClick={() => {
                    if (value.image == "teste") {
                      if (
                        window.confirm(
                          "O documento será deletado, deseja continuar?"
                        )
                      ) {
                        removeMem(value.id);
                      }
                    } else {
                      removeMem(value.id);
                    }
                  }}
                >
                  x
                </button>
                <div>
                  Adicione Memorial
                  <br />
                  (opcional)
                </div>
              </BookItem>
              <div className="row" style={{ marginTop: "20px" }}>
                {pdfMem[0].url ? (
                  <DownloadLink url={pdfMem[0].url} name={value?.name}>
                    [Visualizar Memorial]
                  </DownloadLink>
                ) : (
                  <label style={{ fontWeight: "normal" }}>
                    {value.file && value.image != "teste"
                      ? value.file.name
                      : ""}
                    {!value.file && value.image == "teste" ? value.name : ""}
                  </label>
                )}
              </div>
            </Item>
          ))}
        </Carousel>

        <h2>Vendedor</h2>
        <h4 style={{ marginBottom: 20 }}>
          Preencha para exibir na lista de imóveis vendidos
        </h4>

        <div className="input-group">
          <div
            className="input-compose"
            style={{ width: window.innerWidth <= 600 ? "100%" : "50%" }}
          >
            <label>Nome do vendedor</label>
            <input
              value={property.vendedor}
              onChange={(e) =>
                setProperty({
                  ...property,
                  vendedor: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="save">
          <button className="save-button" onClick={create} disabled={loading}>
            {loading ? "Salvando ..." : "Salvar"}
          </button>
        </div>
      </>,
    },
    {
      key: 'gallery',
      label: 'Galeria',
      children: <UploadGallery
        method='post'
        downloadUrl={`${filesUrl}/${ID}/fotos`}
        uploadUrl={`file/image`}
        formDataArray={[
          { key: 'show', value: true },
          { key: 'property', value: ID },
          { key: 'isUpdate', value: true },
        ]}
        // files={data?.images?.map(({ image, ...rest }) => ({
        //   ...rest,
        //   link: image,
        //   url: `${filesUrl}/${ID}/fotos/${image}`,
        // }))}
        files={data?.images}
        transformHandler={
          ({ image, ...rest }) => ({
            ...rest,
            link: image || rest.link,
            image: image || rest.link,
            url: `${filesUrl}/${ID}/fotos/${image}`,
          })
        }
        handler={(val = []) => {
          setData({ ...data, images: val });
        }}
        externalDeleteHandler={removeImageV2}
        externalSortHandler={saveSortHandler}
        parentId={ID}
        multiple />,
    }
  ];

  const [activeTab, setActiveTab] = useState('general');

  return (<>
    {loading && <Loading />}
    {photoModal &&
      <PhotoModal
        images={getNameImageCounter()}
        id={data._id}
        setModal={setPhotoModal}
      />}
    <Header />
    <Aside />
    <Container>
      <div className="bread-crumb">
        <h1 className="category">ANÚNCIOS&nbsp;{">"}&nbsp;</h1>
        <Link to={window.location.pathname} className="where">
          Anúncios Corretores&nbsp;{">"}&nbsp;
        </Link>
        <Link to={window.location.pathname} className="where">
          &nbsp;Editar
        </Link>
      </div>
      <Tabs animated
        defaultActiveKey={activeTab}
        onChange={(val) => setActiveTab(val)}
        items={menuTabs}
      />
    </Container>
  </>)
}
