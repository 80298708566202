export const getUserAgentTypes = () => ({
  windowsPhone: "Windows Phone",
  android: "Android",
  ios: "iOS",
  unknown: "UNKNOWN",
});

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return getUserAgentTypes().windowsPhone;
  }

  if (/android/i.test(userAgent)) {
    return getUserAgentTypes().android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return getUserAgentTypes().ios;
  }

  return getUserAgentTypes().unknown;
};

export const INSTALL_APP = {
  apple: 'https://api.qrserver.com/v1/create-qr-code/?data=https://apps.apple.com/br/app/im%C3%B3vel-vazio/id1573642473?l=en&amp;size=100x100',
  android: 'https://api.qrserver.com/v1/create-qr-code/?data=https://play.google.com/store/apps/details?id=br.com.iv.imovelvazio&hl=pt_BR&gl=US&amp;size=100x100',
}