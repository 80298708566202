import React from "react";
import { DownloadLinkStyled } from "./styles";

const TEXT_LENGTH = 10;

export default function DownloadLink({ url = "", name = "", children }) {
  const encodedURL = !url ? '' : url.split('/').map(x => x.includes('http') ? x : encodeURIComponent(x)).join('/');
  const getFilename = (value = "") => {
    const splitFilename = value.split(".");
    const ext = splitFilename.reverse()[0];
    let filename = value.substring(0, value.length - ext.length - 1);
    if (filename.length < TEXT_LENGTH) return value;
    const splitName = filename.split("-");
    if (splitName.length > 1) filename = splitName.slice(1).join("-");
    return filename.length > TEXT_LENGTH
      ? `${filename.substring(0, TEXT_LENGTH)}...${ext}`
      : `${filename}.${ext}`;
  };

  return (
    url ? <DownloadLinkStyled href={encodedURL} target="_blank" title={name}>
      {children || `[Visualizar ${name && getFilename(name)}]`}
    </DownloadLinkStyled> : <br />
  );
}
