import styled from 'styled-components';
import FieldErrorValidation from '../../components/FieldErrorValidation';

export const Container = styled.div`
  width:100%;
  height:100%;
  padding:30px;
  /* padding-right:20vw; */
  padding-left: calc(20vw + 30px);

  @media only screen and (max-width: 600px) {
      padding-top: 4vh;
      margin-left: 0;
      width: 100%;
      padding-right: 0px;
      padding: 30px;
  }

  .bread-crumb{
    display:flex;
    align-items:center; 
    margin-bottom:20px;
    margin-top:10vh;
    
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        margin-top: 3vh;
        width: 100%;
    }

    .category{
        color: #0A677C;
        margin-right:3px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }

    .where{
        color: #EB5C1C;
        text-decoration:none;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }
  }

`;

export const Field = styled(FieldErrorValidation)`

    .ant-input:hover, 
    .ant-input:focus, 
    .ant-input:focus-within {
        border-color: transparent;
        box-shadow: none;
        outline: 0;
        border-bottom: 2px solid #ccc;
    }
    background-color: #eee;
    border-radius: 5px;
    min-height: 60px;
    width: 100%;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 15px;
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

    small {
        font-weight: 500;
    }

    input,
    select {
        background-color: transparent;
        border: transparent;
        border-bottom: 2px solid #ccc;
        width: 100%;
        border-radius: 0;
    }
    textarea {
        background-color: transparent;
        border: transparent;
        border-bottom: 2px solid #ccc;
        width: 100%;
    }

`;

export const SectionContainer = styled.div`
    padding: 0 2rem 0 0;
`;

export const SectionsMenu = styled.div`
    padding: 0.5rem ;
`;

export const SaveContainer = styled.div`
    padding-top: 2rem;
`;

export const UploadButtonContainer = styled.div`
    height: 60px;
    margin-top: 20px;
    display: inline-flex;
`;
