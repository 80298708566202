import styled from 'styled-components'
import cardbg from '../../assets/carousel/cardbg.svg'
import cardtext from '../../assets/carousel/cardText.svg'

export const Tag = styled.div`

    position:absolute;
    top:20px;
    left:20px;
    color: #FFFFFF;
    height:22px;
    display:flex;
    padding:5px;
    justify-content:center;
    align-items:center;
    border-radius: 2px;
    background-color:${props => props.background ? props.background : '#1E1D1D  '};
    opacity:0.8;

    h6{
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }
        
`
export const Item = styled.div`
    width:100%;
    max-width:450px;
    height:450px;
    background: #FFFFFF;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    border:none;
    text-align:left;
    margin-bottom: 58px;
    margin-top: 38px;

    .img{
        background : url('${props => props.children[0].props.image ?props.children[0].props.image : 'https://www.ufukkimya.com/media/resim-yok.png' }');
        width: 100%;
        height: 256px;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center;
    }

    .description{
        width:100%;
        padding:20px;

        .address{
            width:100%;
            display:flex;
            justify-content:space-between;
            align-items:center;

            .neighborhood{
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                /* identical to box height */

                letter-spacing: 0.05em;

                color: #1E1D1D;
            }

            .price{
                font-weight: bold;
                font-size: 18px;
                line-height: 21px;
                /* identical to box height */

                text-align: right;

                /* #00AEC8 */

                color: #00AEC8;
            }
        }

        .street{
            p{
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;
                /* identical to box height */

                letter-spacing: 0.05em;
                text-align:left;
                color: #1E1D1D;
            }
        }

        .items{
            display:flex;
            flex-wrap:wrap;
            align-items:center;

            .item-1{
                margin-left:4px;
            }

            .item{
                margin-top:10px;
                width:50%;
                display:flex;
                align-items:center;

                p{
                    margin-left:5px;
                }
                
                @media only screen and (max-width: 600px) {
                    flex-direction: column;
                }
            }
        }
    }

`

export const Card = styled.div`
    width:100%;
    max-width:450px;
    height:430px;
    background: #FFFFFF;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    border:none;    
    text-align:left;
    background-image : url(${require('../../assets/carousel/cardbg.svg')});
    background-size:cover;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:center;
    padding:40px;
    background-color:transparent;

    img{
        margin-top:30px;
    }

    p{
        padding:30px;
        text-align:left;
        color:#FFFFFF;
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;
    }

    a{
        text-decoration:none;
        color:#FFFFFF;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        font-weight: bold;
        letter-spacing: 0.05em;
        border:1px solid #FFFFFF;
        padding:10px;
        border-radius:4px;
    }
`