import React from "react";
import * as S from "./style";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from '@ant-design/icons';
import ImageSocialMedia from '../../../assets/LP_MG/Platform_LP/ImageSocialMedia.svg'
import ImageVideoProperties from '../../../assets/LP_MG/Platform_LP/ImageVideoProperties.svg'
import ImageBook from '../../../assets/LP_MG/Platform_LP/ImageBook.svg'
import ImageSuggestion from '../../../assets/LP_MG/Platform_LP/ImageSuggestion.svg'
import ImagePage from '../../../assets/LP_MG/Platform_LP/ImagePage.svg'
import ImageOther from '../../../assets/LP_MG/Platform_LP/ImageOthers.svg'
import "../../../global.css";

export default function Platform_LP({ user, city }) {

  return (
    <>
     <S.Bar>
        <S.TitlePlatform>
         Por entender que você precisa focar em vender, construímos uma
         <span> plataforma completa</span> para agilizar seu trabalho.
        </S.TitlePlatform>
        <S.MainCards>
           <S.CardsPlatform1>
            <S.CardPlatform>
                <img src={ImageSocialMedia}></img>
                <div>
                <span>Cards para compartilhamento em redes sociais</span>
                </div>
            </S.CardPlatform>
            <S.CardPlatform>
                <img src={ImageVideoProperties}></img>
                <div>
                <span>Vídeos dos imóveis</span>
                </div>
            </S.CardPlatform>
            <S.CardPlatform>
                <img src={ImageBook}></img>
                <div>
                <span>Books de vendas</span>
                </div>
            </S.CardPlatform>
           </S.CardsPlatform1>
           <S.CardsPlatform2>
            <S.CardPlatform>
                <img src={ImageSuggestion}></img>
                <div>
                <span>Sugestão do texto de venda</span>
                </div>
            </S.CardPlatform>
            <S.CardPlatform>
                <img src={ImagePage}></img>
                <div>
                <span>Páginas para cada imóvel</span>
                </div>
            </S.CardPlatform>
            <S.CardPlatform>
                <img src={ImageOther}></img>
                <div>
                <span>E muito mais possibilidades</span>
                </div>
            </S.CardPlatform>
           </S.CardsPlatform2>  
        </S.MainCards>
        <S.Btn>
        {user !== undefined ?
            <S.BtnSeeProperties>
              <Link to={`/imoveis?city_id=${city}`}>
                <span className="title-button">
                  Conferir Imóveis
                </span>
                <ArrowRightOutlined className="icon-arrow" />
              </Link>
            </S.BtnSeeProperties> 
               :
            <S.BtnPartnerBroker>
              <Link to="/register?origin=chegamos_em_bh" className="linkButton">
                <span className="title-button">Quero Ser Corretor Parceiro</span>
                <ArrowRightOutlined className="icon-arrow" />
              </Link>
            </S.BtnPartnerBroker>
        } 
        </S.Btn>   
     </S.Bar>
    </>
  );
}
