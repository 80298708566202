const { default: styled } = require("styled-components");

export const ImagesContainer = styled.div`
  padding: 30px;
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  grid-template-rows: 30vh 30vh;
  row-gap: 5px;
  column-gap: 5px;
  /* margin-top: 100px; */
`;
export const Tag = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 5px;
  height: 22px;
  background: #ea5c1e;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  border-radius: 2px;

  @media (max-width: 600px) {
    height: 30px;
  }
`;

export const ImageFull = styled.div`
  height: 99%;
  background: url(${(props) =>
    props.image
      ? props.image
      : "https://www.ufukkimya.com/media/resim-yok.png"});
  background-size: cover;
  background-position: center;
  grid-column: 1/2;
  grid-row: 1/4;
  border-radius: 8px 0px 0px 8px;
  position: relative;
`;

export const ImagesContainerMobile = styled.div`
  display: grid;
  position: relative;
  margin-top: 80px;
`;

export const ImageFullMobile = styled.img`
  object-fit: cover;
  width: 100%;
`;
export const CountImage = styled.div`
  position: absolute;
  bottom: 20px;
  right: 18px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70px;
`;

export const DownloadImage = styled.img`
  position: absolute;
  top: 10px;
  right: 18px;
  height: 25px;
  width: 25px;
`;

export const ArrowSlide = styled.div`          
    position: absolute;
    ${(props) => props.direction}: 0;
    color: white;
    font-weight: 1000;
    font-size: 50px;
    height: 100%;
    justify-content: center;
    display: flex;
    margin-${(props) => props.direction}: -1px;
    align-items: center;
    cursor: pointer;
    width: 50px;
`;

export const SpanArrow = styled.img`
  -webkit-text-stroke: 2px black;
`;

export const Image1 = styled.div`
  height: 100%;
  background: url(${(props) =>
    props.image
      ? props.image
      : "https://www.ufukkimya.com/media/resim-yok.png"});
  background-size: cover;
  background-position: center;
  grid-row: 1/2;
  grid-column: 2/3;
`;

export const Image2 = styled.div`
  height: 100%;
  background: url(${(props) =>
    props.image
      ? props.image
      : "https://www.ufukkimya.com/media/resim-yok.png"});
  background-size: cover;
  background-position: center;
  grid-row: 2/3;
  grid-column: 2/3;
`;

export const Image3 = styled.div`
  height: 100%;
  background: url(${(props) =>
    props.image
      ? props.image
      : "https://www.ufukkimya.com/media/resim-yok.png"});
  background-size: cover;
  background-position: center;
  grid-row: 1/2;
  grid-column: 3/4;
  border-radius: 0px 8px 0px 0px;
`;
export const Image4 = styled.div`
  height: 100%;
  background-color: brown;
  grid-row: 2/3;
  grid-column: 3/4;
  border-radius: 0px 0px 8px 0px;
  position: relative;
  background: url(${(props) =>
    props.image
      ? props.image
      : "https://www.ufukkimya.com/media/resim-yok.png"});
  background-size: cover;
  background-position: center;

  button {
    position: absolute;
    width: 115px;
    height: 28px;
    right: 20px;
    bottom: 20px;
    background: #ffffff;
    border-radius: 4px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #00aec8;
    border-radius: 4px;
    border: none;
    outline: none;
  }
`;

export const Container = styled.div`
  display: flex;
  padding: 30px;
  flex-wrap: wrap;
  width: 80vw;

  .actions {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 8px;
      margin-bottom: 20px;


      > div {
        margin-left: 10px;
      }

    .btn-zap {
      display: flex;
      align-items: center;
      border-radius: 6px;
      background: #EA5C1D;
      margin-top: 5px;

      font-family: 'Roboto';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      color: #ffff;
      line-height: normal;
    }
  }

  .street {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 800px) {
      h1 {
        margin-bottom: 10px !important;
      }
      flex-flow: column !important;
      align-items: baseline !important;
      margin-bottom: 25px !important;
    }

    h1 {
      font-size: 36px;
      line-height: 41px;
      color: #1e1d1d;
    }

    button,
    .btBook {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      width: 154px;
      height: 28px;
      background: #f2f2f2;
      border-radius: 4px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      margin-top: -5px;
      color: #00aec8;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
      min-width: 100px;

      img {
        margin-right: 5px;
      }
    }
  }

  .street-column {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;

    @media (max-width: 800px) {
      h1 {
        margin-bottom: 10px !important;
      }
      flex-flow: column !important;
      align-items: baseline !important;
      margin-bottom: 25px !important;
    }

    h1 {
      font-size: 36px;
      line-height: 41px;
      color: #1e1d1d;
    }

    button,
    .btBook {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      width: 154px;
      height: 28px;
      background: #f2f2f2;
      border-radius: 4px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: #00aec8;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
      margin-top: -5px;
      min-width: 100px;
      gap: 8px;

      img {
        margin-right: 5px;
      }
    }
  }

  .city {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
  }

  .btCompartilhe {
    margin-top: 0;
    display: flex;
    border: none;
    outline: none;
    width: 154px;
    height: 28px;
    background: #f2f2f2;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #00aec8;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 5px;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 30px;
    align-items: center;

    .icon-group {
      display: flex;
      margin-right: 20px;
      align-items: center;

      img {
        margin-right: 5px;
      }

      p {
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: #1e1d1d;
      }
    }
  }

  a {
    margin-top: 6px !important;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.05em;
    text-decoration-line: underline;
    width: 100%;
    /* #00AEC8 */

    color: #00aec8;
  }

  h1 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 30px;
    color: #1e1d1d;
    width: 100%;
  }

  p {
    font-size: 18px;
    line-height: 26px;
    text-align: start;
    width: 100%;
    letter-spacing: 0.05em;

    color: #1e1d1d;
  }

  iframe {
    width: 100%;
    height: 500px;
  }

  @media (max-width: 600px) {
    width: 100%;
    border-top: 2px solid #eee;
    margin-top: 15px;
  }
`;
export const Body = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;

export const modalCharacter = styled.div`
  display: flex;
  height: 25%;
  flex-direction: row;
  align-items: center;
  brackground: #fff;
  z-index: 99999;

  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const Aside = styled.aside`
  width: 40vw;
  display: flex;
  align-items: center;
  flex-direction: column;

  .card {
    height: 454px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 35px;

    @media (min-width: 600px) {
      width: 421px;
    }
    @media (max-width: 600px) {
      height: auto;
      width: 116%;
      padding: 15px;
    }
    .price {
      margin-left: auto;
      align-items: center;
      margin-bottom: 15px;

      p {
        margin-top: auto;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
      }

      h1 {
        margin-left: 10px;
        font-size: 36px;
        line-height: 41px;
        color: #00aec8;
      }
    }

    .condo {
      margin-bottom: 10px;
      margin-right: 15px;

      @media (max-width: 600px) {
        width: auto;
      }
    }

    .margin-left {
      margin-left: auto;
    }

    .price {
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.05em;
      color: #1e1d1d;
      @media (max-width: 600px) {
        width: auto;
      }
    }

    .descount {
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      text-align: right;
      letter-spacing: 0.05em;
      color: #4f4f4f;
    }

    .row {
      display: flex;
      align-items: center;
    }

    a {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #ffffff;
      background: linear-gradient(90deg, #ea5c1e 0%, #c13818 100%);
      border-radius: 4px;
      text-decoration: none;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text-center {
      text-align: center;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .exclusive {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #1e1d1d;
    }
  }

  .contactUs {
    margin-top: 20px;
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-decoration-line: underline;
    color: #00aec8;
  }
  .download {
    border: 2px solid #ea5c1e;
    height: 50px;
    width: 100%; //350px;
    color: #ea5c1e;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    text-align: center;
    text-decoration: initial;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ModalLayout = styled.div`
  @media (min-width: 768px) {
    height: auto;
    width: 60%;
    top: 22%;
    left: 20%;
    background-color: #fff;
    position: absolute;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.3);
    z-index: 5;
    border-radius: 10px;
    overflow-y: hidden;
  }

  @media (max-width: 767px) {
    height: 80%;
    width: 90%;
    top: 10%;
    left: 5%;
    background-color: #fff;
    position: absolute;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.3);
    z-index: 5;
    border-radius: 5px;
    overflow-y: auto;
  }
`;

export const ModalContainer = styled.div`
  height: 92%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    margin: 10px;
  }
`;

export const ModalTitle = styled.h2`
  text-align: center;
  margin-top: 0.5em;
  @media (min-width: 768px) {
    font-size: 2.5em;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;

  @media (max-width: 767px) {
    padding: 1em;
    gap: 1em;
    flex-flow: rows;
  }

  @media (min-width: 768px) {
    padding: 3em;
    gap: 2em;
    grid-template-columns: 33% 33% 33%;
  }
`;

export const FormItem = styled.div`
  background-color: #f2f2f2;
  border-radius: 5%;
  padding: ${(props) => props.padding || "10px"};
  width: 100%;

  @media (max-width: 767px) {
  }

  @media (min-width: 768px) {
    &.send-item {
      grid-column-start: 3;
    }
  }

  p {
    font-size: large;
    margin-top: 10px;
  }

  label {
    font-weight: bold;
    font-size: 120%;
    margin-left: 0%;
    color: rgb(51, 51, 51);
  }

  input {
    border: none;
    border-bottom: 2px solid rgb(204, 204, 204);
    margin-top: 10px;
    background-color: rgb(242, 242, 242);
    width: 100%;
  }

  button {
    width: 100%;
    border-radius: 5px;
    border: none;
    color: white;
    font-weight: bold;
    background: rgb(235, 92, 28);
    padding: 10px 40px;
  }
`;

export const DownloadIV = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    h3 {
      margin: 0.8rem;
    }
`;

export const ContainerVerifyPrepareAds = styled.div`
  display: flex;
  height: 100%;
`

export const ContentImgVerifyPrepareAds = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 35%;
  background-color: #FFFAF3;
  height: 100%;

  .content-back {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: flex-start !important;    
    width: 70%;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .title-back {
    color: #474C57;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`

export const ContentInformationVerifyPrepareAds = styled.div`
  width: 65%;
  padding-top: 30px;
  padding-left: 30px;
`


export const TitleVerifyPrepareAds = styled.span`
  color: #3B414A;
  text-align: center;
  font-family: 'Roboto';
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const SubTitleVerifyPrepareAds = styled.span`
  color: #3B414A;
  text-align: center;
  font-family: 'Roboto'; 
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 500px;
  margin-top: 28px;
`

export const PrimaryButton = styled.button`
    width: 100%;
    border-radius: 5px;
    border: none;
    color: white;
    font-weight: bold;
    background: rgb(235, 92, 28);
    padding: 10px 40px;
`;
