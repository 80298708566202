import React, { useContext, useEffect } from 'react';
import FormContext from './_context';
import { Controller } from 'react-hook-form';
import FormField from '../../../components/FormField';
import { ACTION_STATE, NOTIFICATION_TYPE } from './helper';
import { Input } from 'antd';
import { changePassword } from '../../../services/auth.service';

export default function ChangePasswordStep() {
  const { errors, control, setData, userData, setStatus, currentStatus, openNotification } = useContext(FormContext);

  const validate = () => {

    const password = userData?.newPassword || '';
    const regexValidation = /^(?=.{6})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])/;

    if (password.length < 6)
      return 'A senha deve possuir no mínimo 6 caracteres.';
    if (!regexValidation.test(password))
      return 'Utilize maiúsculas, minúsculas, número e caracteres especiais.';

  };

  const isEqual = () => userData.newPassword === userData.confirmPassword;

  const processCurrentStep = () => {
    changePassword(userData)
      .then(() => {
        openNotification('Senha alterada com sucesso.', NOTIFICATION_TYPE.success);
        setStatus(ACTION_STATE.finish)
      })
      .catch(({ response }) => {
        console.error(response?.data);
        const { message } = response?.data.message;
        openNotification(message || 'Algo deu errado, tente novamente!', NOTIFICATION_TYPE.warning);
        setStatus(ACTION_STATE.await);
      });
  }

  useEffect(() => {
    if (currentStatus === ACTION_STATE.process)
      processCurrentStep();
  }, [currentStatus]);

  return <>
    <Controller
      name="newPassword"
      control={control}
      rules={{
        required: userData.newPassword ? false : 'Informe a nova senha',
        validate
      }}
      render={({ field }) =>
        <FormField label='Nova senha' error={errors.newPassword} >
          <Input.Password
            {...{ field }}
            value={userData.newPassword}
            onChange={(e) => setData({ newPassword: e.target.value })}
            autoComplete="off"

          />
        </FormField>
      }
    />

    <Controller
      name="confirmPassword"
      control={control}
      rules={{
        required: userData.confirmPassword ? false : 'Repita a nova senha',
        validate: (val) => isEqual(val) || 'As senhas devem ser iguais!'
      }}
      render={({ field }) =>
        <FormField label='Confirmar senha' error={errors.confirmPassword} >
          <Input.Password
            {...{ field }}
            value={userData.confirmPassword}
            onChange={(e) => setData({ confirmPassword: e.target.value })}
            autoComplete="off"
          />
        </FormField>
      }
    />
  </>
}