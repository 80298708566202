import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Container,
  ImageItem,
  PlusBtn,
  BtnWrapper,
  Carousel,
  DocItem,
  Item,
} from "./styles";
import Header from "../../components/adminHeader";
import Aside from "../../components/adminSideBar";
import Autosuggest from "../../components/autosuggest";
import Loading from "../../components/Loading";

import { getStatics, getStaticsOffer, downloadStaticsOffer } from "../../services/requestsStatics";

import api from "../../api";
import { defaultDate, formatDate } from "../../utils/formatDate";

export default function () {
  const [corretores, setCorretores] = useState(0);
  const [correspondente, setCorrespondente] = useState(0);
  const [ofertas, setOfertas] = useState(0);

  const [dataInicio, setDataInicio] = useState(""); //2021-01-01
  const [dataFinal, setDataFinal] = useState("");

  const [anunciosImovel, setAnunciosImovel] = useState(0);
  const [anunciosCorretores, setAnunciosCorretores] = useState(0);

  const [corretoresAtivos, setCorretoresAtivos] = useState([]);
  const [corretoresAtivosOffer, setCorretoresAtivosOffer] = useState([]);
  const [locaisOffer, setLocaisOffer] = useState([]);
  const [locaisAnuncios, setLocaisAnuncios] = useState([]);
  const [loading, setLoading] = useState(false);

  const [ofertasCategorias, setOfertasCategorias] = useState({
    novo: 0,
    analisando: 0,
    negociando: 0,
    naoComprado: 0,
    comprado: 0,
  });

  const categories = {
    analyzing: 0,
    dealing: 0,
    new: 0,
    notPurchased: 0,
    purchased: 0,
    total: 0,
  }

  const metrics = {
    brokers: 0,
    financingRequest: 0,
    properties: 0
  }
 
  const [optionsMetrics, setOptionsMetrics] = useState(metrics)
  const [optionsOffer, setOptionsOffer] = useState(categories);
  const [locationOffer, setLocationsOffer] = useState([]);
  const [brokerOffer, setBrokerOffer] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("type_user") != "admin") {
      window.location.href = "/login";
    }
    // handleLoad();
    requestStatics();
  }, []);

  const requestStatics = async() => {
    await getStatics(dataInicio, dataFinal).then(({data}) => {
      setOptionsMetrics(data.metrics)
      setCorretoresAtivos(data.brokers)
      setLocaisAnuncios(data.places)
    });
    await getStaticsOffer(dataInicio, dataFinal).then(({data}) => {
      setOptionsOffer(data.metrics);
      setLocationsOffer(data.places);
      setBrokerOffer(data.brokers);
    });
  }

  async function downloadStaticsOffer() {
    setLoading(true)
    const { data } = await api.get(`/statistics/offers/download?startDate=${defaultDate(dataInicio)}&endDate=${defaultDate(dataFinal)}`);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Métricas de ofertas - ${formatDate(new Date())}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
    setLoading(false);
  }

  window.onload = () => {
    let a = document.querySelectorAll(".content-box-2 div")[1];
  };

  function handlePrint() {
    var style = "<style>";
    style =
      style +
      `body{
                    height: 1000px;
                    width: 90%;
                    display: flex;
                    margin-left: 5%;
                    flex-direction: column;
                    border-radius: 5px;
                    border: 2px solid #ddd;
                }
        
                .box-container-1{
                    height: 220px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
                .content-box-1{
                    margin-left: 30px;
                    height: 100%;
                    width: 230px;
                    padding-top: 35px;
                }
                .content-box-1 h2{
                    margin-left: 10px;
                }
        
                .content-box-1 div{
                    border-radius: 6px;
                    width: 100%;
                    height: 103px;
                    margin-top: 25px;
                    background: #eee;
                    border: 1px solid #ccc;
                    padding-top: 10px;
                }
        
                .content-box-1:nth-child(1) div{
                    margin-left: -30px;
                }

                .content-box-1:nth-child(2){
                    margin-left: -28px;
                }
                
                .content-box-1 div span{
                    margin-left: 10px;
                }
                                    
                .content-box-1 div h3{
                    color: #8B9495;
                    width: 100%;
                    font-size: 35px;
                    margin-top: 10px;
                    text-align: center;
                }
                .span-share{
                    font-size: 10px;
                }
        
                .content-box-2{
                    height: 103px;
                    width: 100%;
                    display: flex;
                    margin-top: 15px;
                    flex-direction: row;
                    align-items: space-around;
                    justify-content: space-between;
                }
                .div-offer{
                    width: 95px;
                }   
                .content-box-2 div{
                    border-radius: 6px;
                    width: 230px;
                    height: 103px;
                    background: #eee;
                    border: 1px solid #ccc;
                    padding-top: 10px;
                }
                .content-box-2 div span{
                    margin-left: 10px;
                }
                                    
                .content-box-2 div h3{
                    color: #8B9495;
                    width: 100%;
                    font-size: 35px;
                    margin-top: 10px;
                    text-align: center;
                }
                
                .link-h3{
                    text-align: start;
                    padding-left: 15px;
                    font-size: 15px;
                    margin-top: 0px;
                    font-weight: 400;
                }
                .u{
                    text-decoration: underline;
                    cursor: pointer;
                }
    
                .span-min{
                    display: inline-block;
                    text-align: center;
                    margin-left: 0;
                    width: 100%;
                    font-size: 13px;
                }
                `;
    style = style + "</style>";

    let win = window.open("", "", "height=700, width=1000");
    win.document.write("<html><head>");
    win.document.write("<title>Metricas Gerais</title>");
    win.document.write(style);
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write(document.querySelector(".box-header").innerHTML);
    win.document.write(`</div></body></html>`);
    win.print();
    win.close();
  }

  return (
    <>
      {loading && <Loading />}
      <Header />
      <Aside />
      <Container>
        <div className="bread-crumb">
          <h1 className="category">{'MARKETING >'}</h1>
          <Link to={window.location.pathname} className="where">
            Métricas Gerais de Desempenho
          </Link>
        </div>
        <div className="box-control">
          <div className="container-control">
            <div className="input-tolerant">
              <span>Data de Início</span>
              {/* 451, 481, 501, 511 */}
              <input
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
                type="date"
              />
            </div>
            <div className="input-tolerant">
              <span>Data de Fim</span>
              <input
                value={dataFinal}
                onChange={(e) =>setDataFinal(e.target.value)}
                type="date"
                min={dataInicio}
              />
            </div>
          </div>

          <div className="container-control flex-end">
            <button
              onClick={() => {
                setDataInicio("");
              }}
              className="buttonSave bt-inverse"
            >
              Remover filtros
            </button>
            <button onClick={() => requestStatics()} className="buttonSave">
              Aplicar Filtros
            </button>
          </div>
          <input hidden id="ArrayValues" />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: window.innerWidth <= 600 ? "column" : "row",
            justifyContent: "space-between",
            marginTop: 20,
            width: window.innerWidth <= 600 ? "100%" : "123%",
          }}
        >
          <p></p>
          {/* <a
            onClick={handlePrint}
            style={{
              color: "#00AEC8",
              textAlign: window.innerWidth <= 600 ? "center" : "flex-end",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            Fazer download das métricas
          </a> */}
        </div>

        <div className="box-control box-table">
          <div className="box-header">
            <div className="box-container-1">
              <div className="content-box-1">
                <h2>Cadastros</h2>
                <div>
                  <span>Corretores</span>
                  <h3>{optionsMetrics.brokers}</h3>
                </div>
              </div>
              <div className="content-box-1">
                <h2>Correspondente</h2>
                <div>
                  <span>Solicitações</span>
                  <h3>{optionsMetrics.financingRequest}</h3>
                </div>
              </div>
            </div>

            <div
              className="box-container-1"
              style={{
                height: window.innerWidth <= 600 ? 780 : 350,
                flexDirection: "column",
                marginTop: 20,
              }}
            >
              <h2 style={{ marginLeft: window.innerWidth <= 600 ? 0 : 25 }}>
                Anúncios
              </h2>

              <div
                className="content-box-2"
                style={{
                  justifyContent: "start",
                  alignItems: window.innerWidth <= 600 ? "center" : "start",
                  flexDirection: window.innerWidth <= 600 ? "column" : "row",
                  height: window.innerWidth <= 600 ? 350 : "unset",
                }}
              >
                {/* <div>
                  <span>Total de Anúncios</span>
                  <h3>{anunciosImovel + anunciosCorretores}</h3>
                </div> */}
                <div
                  style={{
                    marginTop: window.innerWidth <= 600 ? 10 : 0,
                  }}
                >
                  <span>Anúncios Imóvel Vazio</span>
                  <h3>{optionsMetrics.properties}</h3>
                </div>
                {/* <div
                  style={{
                    marginLeft: window.innerWidth <= 600 ? 0 : 30,
                    marginTop: window.innerWidth <= 600 ? 10 : 0,
                  }}
                >
                  <span>Anúncios Corretores</span>
                  <h3>{anunciosCorretores}</h3>
                  <span style={{fontSize: 11}}>46% com comissão compartilhada</span>
                </div> */}
              </div>

              <div
                className="content-box-2"
                style={{
                  marginTop: 25,
                  alignItems: window.innerWidth <= 600 ? "center" : "start",
                  height: window.innerWidth <= 400 ? 500 : "auto",
                  flexDirection: window.innerWidth <= 600 ? "column" : "row",
                }}
              >
                <div
                  style={{
                    width: window.innerWidth <= 600 ? "95%" : "48%",
                    height: "165px",
                  }}
                >
                  <span>Locais Mais Anunciados</span>

                  {locaisAnuncios.map((e, i) => (
                    <h3
                      className="link-h3"
                      style={{
                        fontSize: 15,
                        textAlign: "start",
                        marginBottom: 0,
                        marginTop: i == 0 ? 10 : 0,
                      }}
                    >
                      {e.name} ({e.total})
                    </h3>
                  ))}
                </div>
                <div
                  style={{
                    width: window.innerWidth <= 600 ? "95%" : "48%",
                    height: "165px",
                    marginTop: window.innerWidth <= 600 ? 10 : 0,
                  }}
                >
                  <span>Corretores Mais Ativos (imóveis)</span>
                  {corretoresAtivos.map((e, i) => (
                    <h3
                      style={{
                        fontSize: 15,
                        textAlign: "start",
                        marginTop: i == 0 ? 10 : 0,
                        marginBottom: 0,
                      }}
                      onClick={() =>
                        window.open(
                          `/admin/marketing/editar?id=${
                            e.id
                          }`
                        )
                      }
                      className="link-h3 u"
                    >
                      {e.name} ({e.total} {e.total > 1 ? 'imóveis' : 'imóvel'})
                    </h3>
                  ))}
                </div>
              </div>
            </div>

            <div
              className="box-container-1"
              style={{
                height: window.innerWidth <= 600 ? 500 : 300,
                flexDirection: "column",
                marginTop: 25,
              }}
            >
              <div className="container-text-imovel">
              <h2 style={{ marginLeft: 40 }}>COMPRAMOS SEU IMÓVEL </h2>

              <a
                onClick={downloadStaticsOffer}
                style={{
                  color: "#00AEC8",
                  textAlign: window.innerWidth <= 600 ? "center" : "flex-end",
                  textDecoration: "none",
                  cursor: "pointer",
                  marginRight: 40
                }}
              >
                
                Download das métricas das ofertas
              </a>
              </div>

              <div className="content-box-2">
                <div>
                  <span>Total de Ofertas Recebidas</span>
                  <h3>{optionsOffer.total}</h3>
                </div>
                <div className="div-offer">
                  <span className="span-min">Novo</span>
                  <h3>{optionsOffer.new}</h3>
                </div>
                <div className="div-offer">
                  <span className="span-min">Analisando</span>
                  <h3>{optionsOffer.analyzing}</h3>
                </div>
                <div className="div-offer">
                  <span className="span-min">Negociando</span>
                  <h3>{optionsOffer.analyzing}</h3>
                </div>
                <div className="div-offer">
                  <span className="span-min">Não Comprado</span>
                  <h3>{optionsOffer.analyzing}</h3>
                </div>
                <div className="div-offer" >
                  <span className="span-min">Comprado</span>
                  <h3>{optionsOffer.analyzing}</h3>
                </div>
              </div>

              <div className="content-box-2" style={{ marginTop: 25 }}>
                <div
                  style={{
                    width: window.innerWidth <= 600 ? "90%" : "48%",
                    height: window.innerWidth <= 600 ? "160px" : "135px",
                  }}
                >
                  <span>Locais Mais Ofertados para Imóvel Vazio</span>
                  {locationOffer.map((e, i) => (
                    <h3
                      className="link-h3"
                      style={{
                        fontSize: 15,
                        textAlign: "start",
                        marginBottom: 0,
                        marginTop: i == 0 ? 10 : 0,
                      }}
                    >
                      {e.name} ({e.total})
                    </h3>
                  ))}
                </div>
                <div
                  style={{
                    width: window.innerWidth <= 600 ? "90%" : "48%",
                    height: window.innerWidth <= 600 ? "160px" : "135px",
                  }}
                >
                  <span>Corretores Mais Ativos (imóveis ofertados)</span>
                  {brokerOffer.map((e, i) => (
                    <h3
                      onClick={() =>
                        window.open(
                          `/admin/marketing/editar?id=${
                            e.id
                          }`
                        )
                      }
                      className="link-h3 u"
                      style={{
                        fontSize: 14,
                        textAlign: "start",
                        marginBottom: 0,
                        marginTop: i == 0 ? 10 : 0,
                      }}
                    >
                      {e.name} ({e.total} {e.total > 1 ? 'imóveis' : 'imóvel'})
                    </h3>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
