import { createContext } from "react";

const FormContext = createContext({
  form: {
    formState: {
      errors: {},
    },
  },
});

export default FormContext;