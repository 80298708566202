import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  .text {
    margin-bottom: 3%;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 1.5em;
  }
  .spin-icon {
    color: red;
  }
  .ant-spin-dot-item {
    background-color: #FFFFFF;
  }
  img {
    width: 40%;
    margin-top: 7%;
  }
`