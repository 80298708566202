import React from 'react'
import '../../global.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { notification } from "antd";

export default function StepThree({setStep, setSenha, senha, setSenhaConfirm, senhaConfirm, termos, setTermos, email, setReceiveEmail}) {
    
    function handleNext(){
        
        if(senha == senhaConfirm && senha.length >= 6 && termos.termo == true){
            setReceiveEmail(termos.email)
            setStep(4)
        }else{
            let errors = document.querySelectorAll('.input-box')
            
            if(senha.length < 6){
                errors[0].style.border = "2px solid red"
                styleBorderError(errors[0])
            }
            if(senhaConfirm != senha){
                errors[1].style.border = "2px solid red"
                styleBorderError(errors[1])
            }
        }
    }
    
    function styleBorderError(object){
        setTimeout(() => {
            object.style.border = "none"
        }, 3000);
    }

    return (
        <>
            <div className="step">
                <div className="container-input-box">
                    <div className="input-box">
                        <small>Senha</small>
                        <input value={senha}  onChange={text => setSenha(text.target.value)} type="password" placeholder="No mínimo 6 digitos" />
                    </div>
                    <div className="input-box">
                        <small>Confirmação de senha</small>
                        <input value={senhaConfirm}  onChange={text => setSenhaConfirm(text.target.value)} type="password" placeholder="" />
                    </div>
                </div>

                <div className="body-terms">
                    <h2>Politica de Uso</h2>
                    <p>Nós, da Imóvel Vazio, estamos comprometidos em resguardar sua privacidade. O intuito deste documento é esclarecer quais informações são coletadas dos usuários de nosso site http://imovelvazio.com.br – e de que forma esses dados são manipulados e utilizados.
                    </p><br/>
                    <p>Alertamos que se você não concorda com o conteúdo desta política, não é recomendável baixar nossos materiais nem utilizar quaisquer de nossos serviços nós, da Imóvel Vazio, estamos comprometidos em resguardar sua privacidade. O intuito deste documento é esclarecer quais informações são coletadas dos usuários de nosso site http://imovelvazio.com.br – e de que forma esses dados são manipulados e utilizados.
                    </p><br/>
                    <p>Alertamos que se você não concorda com o conteúdo desta política, não é recomendável baixar nossos materiais nem utilizar quaisquer de nossos serviços</p>
                </div>

                <FormControlLabel        
                    control={
                    <Checkbox
                        checked={termos.termo}
                        onChange={change=>setTermos({termo:!termos.termo, email: termos.email})}
                        name="checkedB"
                        color="primary"
                        
                    />
                    }
                    label="Confirmo e aprovo a leitura da Política de Uso."
                />
                                        
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={termos.email}
                        onChange={change=>setTermos({termo:termos.termo, email: !termos.email})}
                        name="checkedB"
                        color="primary"
                        
                    />
                    }
                    label="Quero receber informações por e-mail sobre os imóveis à venda."
                />
                <div className="container-next">
                    <button className="btBack" onClick={click => setStep(2)}>Voltar</button>
                    <button onClick={handleNext}>Avançar</button>
                </div>
            </div>
        </>
    )
}
