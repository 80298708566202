import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import crypto from "crypto";

// Components
import {
  Container,
  ImageItem,
  PlusBtn,
  BtnWrapper,
  Carousel,
  DocItem,
  Item,
} from "./style2";
import DownloadLink from "../../components/DownloadLink";
import Header from "../../components/adminHeader";
import Aside from "../../components/adminSideBar";
import Autocomplete from "../../components/autosuggest";
import InputMask from "react-input-mask";
import { Andar, Posicao } from "./utils";
import RoofForm from "./Properties/Roof";
import HomeForm from "./Properties/Home";
import DefaultForm from "./Properties/Default";

import PhotoModal from '../../components/PhotosModal'

import api from "../../api";

import socketClient from "socket.io-client";

import Loading from "../../components/Loading";
import { notification } from "antd";
import { getOffersAsync } from "../../services/offer.service";

import RestrictedView from "../../components/RestrictedView";
import RealEstateAppraisals from "../../components/RealEstateAppraisals";

export default function () {
  const [socket, setSocket] = useState(null);

  const [loading, setLoading] = useState(false);
  const [comentario, setComentario] = useState("");
  const [obs, setObs] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [tipo, setTipo] = useState("");
  const [tipo2, setTipo2] = useState("");
  const [numberIPTU, setNumberIPTU] = useState("");

  const [posicao, setPosicao] = useState("");
  const [andar, setAndar] = useState("");
  const [banheiros, setBanheiros] = useState();
  const [escritura, setEscritura] = useState(false);

  const [metragem, setMetragem] = useState("");
  const [quartos, setQuartos] = useState("0");
  const [bairro, setBairro] = useState("  ");
  const [analise, setAnalise] = useState("");
  const [suites, setSuites] = useState("0");
  const [dependencias, setDependencias] = useState("0");
  const [vagas, setVagas] = useState("0");
  const [condominio, setCondominio] = useState("R$ 0,00");
  const [valorDesejado, setValorDesejado] = useState("R$ 0,00");
  const [valorFinalProposta, setValorFinalProposta] = useState("R$ 0,00");
  const [valorMercado, setValorMercado] = useState("R$ 0,00");
  const [valorAnalise, setValorAnalise] = useState("R$ 0,00");
  const [status, setStatus] = useState("");
  const [tipoArray, setTipoArray] = useState([]);

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState([]);

  const [photoModal, setPhotoModal] = useState(false);

  const [controlUser, setControlUser] = useState("");
  const [ID, setID] = useState(0);
  const [offerId, setOfferId] = useState(0);
  const [message, setMessage] = useState("");
  const [docsPermission, setDocsPermission] = useState(false);
  const [photosPermission, setPhotosPermission] = useState(false);
  const [imageAlter, setImageAlter] = useState(false);
  const [docAlter, setDocAlter] = useState(false);

  const [areaConstruida, setAreaConstruida] = useState("");
  const [areaTotal, setAreaTotal] = useState("");
  const [areaIptu, setAreaIptu] = useState("");
  const [constaIptu, setConstaIptu] = useState(false);
  const [propertyInf, setPropertyInf] = useState("");
  const [reload, setReload] = useState(false);

  const [itbiAvgValueCurrentYear, setItbiAvgValueCurrentYear] =
    useState(0);
  const [itbiAvgValueLastYear, setItbiAvgValueLastYear] = useState(0);
  const [itbiPriceCurrentYear, setItbiPriceCurrentYear] = useState(0);
  const [itbiPriceLastYear, setItbiPriceLastYear] = useState(0);
  const [iVAvgValueCurrentYear, setIVAvgValueCurrentYear] = useState(0);
  const [iVAvgValueLastYear, setIVAvgValueLastYear] = useState(0);
  const [iVPriceCurrentYear, setIVPriceCurrentYear] = useState(0);
  const [iVPriceLastYear, setIVPriceLastYear] = useState(0);
  const [landSizeUseful, setLandSizeUseful] = useState(0);
  const [createdAt, setCreatedAt] = useState('');

  const [data, setData] = useState();

  const userType = localStorage.getItem("type_user");

  const [messages, setMessages] = useState([
    /*
        {_id: 1, user_message: 5302, type: 'admin', message: 'Bom dia', offer: 1660},
        {_id: 2, user_message: 5302, type: 'admin', message: 'Como voce esta?', offer: 1660},
        {_id: 3, user_message: 1090, type: 'agent', message: 'Bom dia, eu estou bem', offer: 1660},
        {_id: 4, user_message: 5302, type: 'admin', message: 'Estou fazendo um teste', offer: 1660},
        {_id: 5, user_message: 1090, type: 'agent', message: 'Que legal, esta indo bem?', offer: 1660},*/
  ]);

  useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    if (id !== offerId) {
      setOfferId(id);
      setID(Number(id));
    }
  }, [ID]);

  function handleCompact(id) {
    if (id > 0) api.post("/download", { id: id });
  }

  const [imageCounter, setImageCounter] = useState([
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
      order: null,
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
      order: null,
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
      order: null,
    },
  ]);

  const [docCounter, setDocCounter] = useState([
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
  ]);

  const verifyPropertyTip = useCallback(() => {
    if (
      propertyInf == 25 ||
      propertyInf == 29 ||
      propertyInf == 30 ||
      propertyInf == 39
    ) {
      return "house";
    } else if (propertyInf == 26) {
      return "roof";
    } else {
      return false;
    }
  }, [propertyInf]);

  useEffect(() => {
    if (
      !localStorage.getItem("type_user") &&
      !localStorage.getItem("id_painel")
    ) {
      localStorage.setItem("chatLink", window.location.href);
      window.location.href = "/login";
    } else {
      setControlUser(localStorage.getItem("type_user"));
    }
    let wrapper = document.querySelector(".wrapper");
    wrapper.style.width = "100%";
    document.querySelector(".wrapper label").style.display = "none";
    document.querySelector(".wrapper div").style.height = "auto";

    setSocket(
      socketClient(process.env.REACT_APP_API_URL, {
        query: {
          user: localStorage.getItem("id_painel"),
          type: localStorage.getItem("type_user"),
          Offer: offerId,
        },
      })
    );

    handleLoadTypes();
    handleLoadOffer();
    verifyPropertyTip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socket != null) {
      socket.on("message", (data) => {
        if (data[0].Offer == offerId) {
          setMessages(data);
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    if (document.getElementById("chat")) {
      document.getElementById("chat").scrollTop =
        document.getElementById("chat").scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    sendImages();
    sendDocs();
    handleCompact(ID);
  }, [imageAlter, docAlter]);

  async function handleLoadOffer() {
    if (offerId) {
      var { data } = await getOffersAsync({ id: offerId }, true);
      if (data.nothing) {
        window.history.back();
      }
      if (localStorage.getItem("type_user") == "agent") {
        if (data.idUser) {
          if (data.idUser._id != localStorage.getItem("id_painel")) {
            window.history.back();
          }
        }
      }
      setID(offerId);
      setData(data)
      setNome(data.idUser ? data.idUser.name : data.name);
      setEmail(data.idUser ? data.idUser.email : data.email);
      setTelephone(data.idUser ? data.idUser.telephone : data.telephone);
      setBairro(data.bairro);
      setEndereco(data.address);
      setComplemento(data.addressComplement);
      setNumero(data.addressNumber);
      setStatus(data.status);
      setComentario(data.coment);
      setQuartos(data.bedrooms);
      setDependencias(data.dependencies);
      setSuites(data.suites);
      setObs(data.observation);
      setVagas(data.parkingSpots);
      setMetragem(String(data.landSizeUseful));
      setNumberIPTU(data.numberIPTU);
      setTipo2(data.type ? data.type.name : "");
      setTipo(data.type ? data.type._id : "");
      setAnalise(data.analise);
      setCondominio(money(String(data.condominiumPrice)));
      setValorAnalise(money(String(data.valorFinal)));
      setValorDesejado(money(String(data.valueDesejado)));
      setValorMercado(money(String(data.valueMercado)));
      setValorFinalProposta(money(String(data.valorFinalProposta)));
      //novos
      setPosicao(data.position);
      setAndar(data.level);
      setBanheiros(data.bathrooms);
      setEscritura(data.scripture);
      setPhotosPermission(data?.photosSolicitation);
      setDocsPermission(data?.docsSolicitation);
      setAreaConstruida(String(data?.constructArea));
      setAreaTotal(String(data?.totalArea));
      setAreaIptu(String(data?.iptuArea));
      setConstaIptu(data?.constructOnIptu);

      // fix: showing unused values
      setItbiAvgValueCurrentYear(Number(data.ItbiAvgValueCurrentYear));
      setItbiAvgValueLastYear(Number(data.ItbiAvgValueLastYear));
      setItbiPriceCurrentYear(Number(data.ItbiPriceCurrentYear));
      setItbiPriceLastYear(Number(data.ItbiPriceLastYear));
      setIVAvgValueCurrentYear(Number(data.IVAvgValueCurrentYear));
      setIVAvgValueLastYear(Number(data.IVAvgValueLastYear));
      setIVPriceCurrentYear(Number(data.IVPriceCurrentYear));
      setIVPriceLastYear(Number(data.IVPriceLastYear));
      setLandSizeUseful(Number(data.landSizeUseful));
      setCreatedAt(data.createdAt);

      data?.type && setPropertyInf(data?.type["_id"]);

      if (data?.images.length > 0) {
        let images = [];
        for (let image of data.images) {
          images.push({
            id: crypto
              .createHash("sha256")
              .update(Math.random().toString())
              .digest("hex"),
            url: `${process.env.REACT_APP_FILES_URL}/${offerId}/fotos/${(image.image)}`,
            show: true,
            name: image.image,
          });
        }
        setImageCounter(images);
      }

      if (data?.documents.length > 0) {
        let docs = [];
        for (let doc of data.documents) {
          docs.push({
            id: crypto
              .createHash("sha256")
              .update(Math.random().toString())
              .digest("hex"),
            url: `${process.env.REACT_APP_FILES_URL}/${offerId}/documentos/${doc.name}`,
            name: doc.name,
            file: {
              name: doc.name,
            },
          });
        }
        setDocCounter(docs);
      }

      if (!String(data.valueDesejado).includes(".")) {
        setValorDesejado(money(String(data.valueDesejado) + "00"));
      }
      if (!String(data.condominiumPrice).includes(".")) {
        setCondominio(money(String(data.condominiumPrice) + "00"));
      }
      if (!String(data.valorFinal).includes(".")) {
        setValorAnalise(money(String(data.valorFinal) + "00"));
      }
      if (!String(data.valueMercado).includes(".")) {
        setValorMercado(money(String(data.valueMercado) + "00"));
      }
      if (!String(data.valorFinalProposta).includes(".")) {
        setValorFinalProposta(money(String(data.valorFinalProposta) + "00"));
      }

      var response = await api.get(`/chat/${data._id}`);

      setMessages(response.data);

      response = await api.put(
        `/chat/${data._id}?type=${localStorage.getItem("type_user") == "agent" ? "admin" : "agent"
        }`
      );
    } else {
      window.location.href = "/admin/solicitacao-ofertas";
    }
  }

  window.onbeforeunload = () => localStorage.setItem("fill", "filter");

  function money(value) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(+value.replace(/\D+/g, "") / 100);
  }

  async function handleLoadTypes() {
    const { data } = await api.get("/propertyType/");

    let tips = new Array();
    data.propertyTypes.map((item) => {
      tips.push({ value: item._id, name: item.name });
    });

    setTipoArray(tips);
  }

  function styleBorderError(object) {
    object.style.border = "2px solid red";
    setTimeout(() => {
      object.style.border = "none";
    }, 10000);
  }

  const verifyFields = () => {
    let success = true;
    let inputs = document.querySelectorAll("[required]");
    Array.from(inputs).map((input) => {
      if (input.querySelector("input")) {
        if (
          !input.querySelector("input").value ||
          input.querySelector("input").value === "R$ 0,00"
        ) {
          styleBorderError(input);
          success = false;
        }
      }
      if (input.querySelector("select")) {
        if (input.querySelector("select").value === "") {
          styleBorderError(input);
          success = false;
        }
      }
    });

    return success;
  };

  async function handleSubmit() {
    if (!verifyFields()) {
      return notification.info({
        message: 'Verifique os campos informados!',
      });
    }

    const { data } = await api.put(`/offer/${offerId}`, {
      address: endereco,
      bathrooms: banheiros,
      position: posicao,
      level: andar,
      scripture: escritura,
      addressNumber: numero,
      addressComplement: complemento,
      type: tipo,
      landSizeUseful: metragem && parseFloat(metragem.replace(",", ".")),
      constructArea:
        areaConstruida && parseFloat(areaConstruida.replace(",", ".")),
      totalArea: areaTotal && parseFloat(areaTotal.replace(",", ".")),
      constructOnIptu: constaIptu,
      numberIPTU,
      bedrooms: quartos,
      suites: suites === "" ? 0 : suites,
      dependencies: dependencias,
      parkingSpots: vagas,
      condominiumPrice: moneyToNumber(condominio),
      coment: comentario,
      status,
      observation: obs,
      valorFinal: moneyToNumber(valorAnalise),
      valorFinalProposta: moneyToNumber(valorFinalProposta),
      valueDesejado: moneyToNumber(valorDesejado),
      valueMercado: moneyToNumber(valorMercado),
      bairro,
      location: window.location.href,
      email,
    });

    if (data.analise) {
      window.location.href = "/admin/solicitacao-ofertas#filter";
    }
  }

  function moneyToNumber(value) {
    return value
      .replace("R", "")
      .replace("$", "")
      .replaceAll(" ", "")
      .replaceAll(".", "*")
      .replace(",", ".")
      .replaceAll("*", "")
      .substr(1, value.length - 1);
  }

  async function handleMessage(text) {
    var msg = text ? text : message;

    let mess = messages.filter((e) => e);
    mess.push({
      message: msg,
      user_message: localStorage.getItem("id_painel"),
      type: localStorage.getItem("type_user"),
      Offer: ID,
    });
    setMessage("");
    setMessages(mess);

    const endArr = endereco.split(",");

    if (message.length > 0 || text) {
      var { data } = await api.post(`/chat/`, {
        message: msg,
        user_message: localStorage.getItem("id_painel"),
        type: localStorage.getItem("type_user"),
        Offer: ID,
        email,
        address:
          endArr[0] +
          (numero ? ", " + numero : "") +
          (!complemento ? "" : " / " + complemento) +
          ", " +
          endArr[1] +
          endArr[2],
        telephone: telephone[0],
        location: window.location.href,
        createdAt:
          new Date().toLocaleDateString("pt-BR", { day: "2-digit" }) +
          "/" +
          new Date().toLocaleDateString("pt-BR", { month: "2-digit" }) +
          "/" +
          new Date().toLocaleDateString("pt-BR", { year: "numeric" }),
      });

      if (data) {
        setMessages(data);
      }
    }
  }

  function addImage(files, id) {
    if (!files.length) {
      return null;
    }
    if (files.length == 1) {
      const url = URL.createObjectURL(files[0]);

      const value = imageCounter.find((value) => value.id === id);
      const clear = imageCounter.filter((value) => value.id !== id);

      value.image = files[0];
      value.url = url;
      value.name = files[0]?.name;
      value.file = files[0];

      setImageCounter([...clear, value]);
    } else {
      let arrayImage = [];
      imageCounter.map((row) => {
        if (row.image != null) {
          arrayImage.push(row);
        }
      });

      for (let i = 0; i < files.length; i++) {
        const url = URL.createObjectURL(files[i]);
        arrayImage.push({
          id: crypto
            .createHash("sha256")
            .update(Math.random().toString())
            .digest("hex"),
          image: files[i],
          url: url,
          show: true,
          name: null,
        });
      }

      setImageCounter(arrayImage);
    }

    setImageAlter(!imageAlter);
  }

  function removeImage(id) {
    const remove = imageCounter.filter((value) => value.id === id);
    if (remove[0].name) {
      api
        .post("/file/remove", { data: remove[0], offer: ID })
        .then((_res) => handleCompact(ID));
    }
    const removed = imageCounter.filter((value) => value.id !== id);
    setImageCounter(removed);
  }

  function editImageStatus(id, status) {
    const edited = imageCounter.map((value) => {
      if (value.id === id) {
        api.post("/file/changeShow", {
          status: `${status}`,
          name: value.name,
          id: ID,
        });
      }
      return value;
    });

    setImageCounter(edited);
    notification.info({
      message: 'Espere 1-2 segundos para atualizar outra imagem.',
    });
  }

  function addDoc(files, id) {
    if (!files.length) {
      return null;
    }

    if (files.length == 1) {
      const url = URL.createObjectURL(files[0]);

      const value = docCounter.find((value) => value.id === id);
      const clear = docCounter.filter((value) => value.id !== id);

      value.file = files[0];
      value.url = url;
      value.image = files[0];

      setDocCounter([...clear, value]);
    } else {
      let arrayDocs = [];
      docCounter.map((row) => {
        if (row.image != null) {
          arrayDocs.push(row);
        }
      });

      for (let i = 0; i < files.length; i++) {
        const url = URL.createObjectURL(files[i]);
        arrayDocs.push({
          id: crypto
            .createHash("sha256")
            .update(Math.random().toString())
            .digest("hex"),
          image: files[i],
          url: url,
          file: files[i],
        });
      }

      setDocCounter(arrayDocs);
    }

    setDocAlter(!docAlter);
  }

  function removeDoc(id) {
    const remove = docCounter.filter((value) => value.id === id);
    if (remove[0].name) {
      api
        .post("/file/docRemove", { data: remove[0], offer: ID })
        .then((_res) => handleCompact(ID));
    }
    const removed = docCounter.filter((value) => value.id !== id);
    setDocCounter(removed);
  }

  async function sendDocs() {
    const filtered = docCounter.filter((value) => {
      if (value.image && value.image != "teste") {
        return value;
      }
    });

    let formData;

    const images = filtered.map(async (value) => {
      formData = new FormData();
      formData.append("offer", ID);
      formData.append("file", value.file);
      setLoading(true);
      await api
        .post("/file/doc", formData)
        .then((res) => {
          let docs = [];
          res.data.documents &&
            res.data.documents.map((doc) => {
              docs.push({
                id: crypto
                  .createHash("sha256")
                  .update(Math.random().toString())
                  .digest("hex"),
                url: `${process.env.REACT_APP_FILES_URL}/${res.data._id}/documentos/${doc.name}`,
                image: "teste",
                name: doc.name,
              });
            });

          setDocCounter(docs);
        })
        .catch((err) => {
          notification.error({
            message: 'Falha ao inserir documentos,tente novamente mais tarde',
          });
        })
        .finally(() => {
          setLoading(false);
          handleCompact(ID);
        });
    });

    docCounter.map((e) => {
      e.image = "teste";
    });
  }

  async function sendImages() {
    try {
      setLoading(true);
      const filtered = imageCounter.filter((value) => {
        if (value.image && value.image != "teste") {
          return value;
        }
      });

      let formData;

      for (var i = 0; i < filtered.length;) {
        formData = new FormData();
        formData.append("offer", ID);
        formData.append("file", filtered[i].image);

        const res = await api.post("/file/image", formData);


        let imagens = [];
        res.data.images.map((value) => {
          imagens.push({
            id: crypto
              .createHash("sha256")
              .update(Math.random().toString())
              .digest("hex"),
            url: `${process.env.REACT_APP_FILES_URL}/${res.data._id}/fotos/${(value.image)}`,
            image: "teste",
            name: value.image,
            show: value.show ? value.show : "true",
            order: value.order,
            file: value.file,
          });
        });

        setImageCounter(imagens);
        i++;
      }

      imageCounter.map((e) => {
        e.image = "teste";
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      handleCompact(ID);
    }
  }

  const getFilename = (filename = "") => {
    const splitFilename = filename.split(".");
    const ext = splitFilename.reverse()[0];
    let name = filename.slice(splitFilename.length * -1 + 1);
    if (name.length < 10) return filename; //`${name.substring(0,10)}.${ext}`;

    const splitName = name.split("-");
    return name.length > 10
      ? `${splitName
        .slice(splitName.length * -1 + 1)
        .join("-")
        .substring(0, 20)}...${ext}`
      : `${name}.${ext}`;
  };

  const getNameImageCounter = () => {
    let imagesName = []
    imageCounter.map((value) => {
      imagesName.push({
        image: value.name
      })
    })

    return imagesName
  }

  return (
    <>
      {loading && <Loading />}
      {photoModal &&
        <PhotoModal
          images={getNameImageCounter()}
          id={data._id}
          setModal={setPhotoModal}
        />}
      <Header />
      <Aside />
      <Container>
        <div className="bread-crumb">
          <h1 className="category">COMPRAMOS SEU IMÓVEL</h1>
          <Link to={window.location.pathname} className="where">
            Ofertas de Venda para Imóvel Vazio{" "}
            {window.innerWidth <= 600 ? "" : "Editar"}
          </Link>
          {window.innerWidth <= 600 ? (
            <Link to={window.location.pathname} className="where">
              Editar
            </Link>
          ) : (
            ""
          )}
        </div>

        <div className="box-control">
          <div className="container-input-box flex-column">
            <div style={{ flexDirection: "column", width: "100%" }}>
              {nome}
              <br></br>
              {email}
              <br></br>
              {/* target='_blank' href={`https://api.whatsapp.com/send?1=pt_BR&phone=55${telephone[0]?telephone[0]:telephone[1]?telephone[1]:''}`} style={{textDecoration: 'underline', cursor: 'pointer', color:'black'}} */}
              <a style={{ color: "black" }}>
                {telephone[0] ? telephone[0] : telephone[1] ? telephone[1] : ""}
              </a>
            </div>

            <div className="roll-page" style={{}}>
              <button
                onClick={(to) =>
                  window.scrollTo(0, window.innerWidth <= 600 ? 1270 : 600)
                }
                style={{}}
              >
                Ler/Escrever Mensagens
              </button>
            </div>
          </div>

          <div className="box-divide">
            <small>Endereço (sem número)</small>
            <input
              disabled={controlUser == "agent" ? true : false}
              value={endereco}
              onChange={(comp) => setEndereco(comp.target.value)}
            />
          </div>

          <div className="container-input-box">
            <div className="box-divide">
              <small>Número</small>
              <InputMask
                disabled={controlUser == "agent" ? true : false}
                type="number"
                value={numero}
                onChange={(comp) => setNumero(comp.target.value)}
              />
            </div>
            <div className="box-divide">
              <small>Complemento</small>
              <InputMask
                disabled={controlUser == "agent" ? true : false}
                value={complemento}
                onChange={(comp) => setComplemento(comp.target.value)}
              />
            </div>
          </div>
          <div className="box-divide autoselect">
            <small>Tipo de imóvel</small>
            <Autocomplete
              inputName="Tipo"
              placeholder={tipo2}
              objs={tipoArray}
              onChange={(comp) => setTipo(comp.value)}
            />
          </div>
          {verifyPropertyTip() == "house" && (
            <HomeForm
              areaIptu={metragem == 0 ? areaIptu : metragem}
              setAreaIptu={metragem == 0 ? setAreaIptu : setMetragem}
              areaTerreno={areaTotal}
              setAreaTerreno={setAreaTotal}
              areaConstruida={areaConstruida}
              setAreaConstruida={setAreaConstruida}
              vagas={vagas}
              setVagas={setVagas}
              quartos={quartos}
              setQuartos={setQuartos}
              suites={suites}
              setSuites={setSuites}
              banheiros={banheiros}
              setBanheiros={setBanheiros}
              dependencias={dependencias}
              setDependencias={setDependencias}
              escritura={escritura}
              setEscritura={setEscritura}
            />
          )}

          {verifyPropertyTip() == "roof" && (
            <RoofForm
              money={money}
              moneyToNumber={moneyToNumber}
              condominio={condominio}
              setCondominio={setCondominio}
              areaIptu={metragem == 0 ? areaIptu : metragem}
              setAreaIptu={metragem == 0 ? setAreaIptu : setMetragem}
              areaConstruida={areaConstruida}
              setAreaConstruida={setAreaConstruida}
              setConstaIptu={setConstaIptu}
              constaIptu={constaIptu}
              vagas={vagas}
              setVagas={setVagas}
              quartos={quartos}
              setQuartos={setQuartos}
              suites={suites}
              setSuites={setSuites}
              banheiros={banheiros}
              setBanheiros={setBanheiros}
              setEscritura={setEscritura}
              escritura={escritura}
              dependencias={dependencias}
              setDependencias={setDependencias}
            />
          )}

          {!verifyPropertyTip() && (
            <DefaultForm
              posicao={posicao}
              metragem={metragem}
              setMetragem={setMetragem}
              money={money}
              condominio={condominio}
              setCondominio={setCondominio}
              setPosicao={setPosicao}
              andar={andar}
              setAndar={setAndar}
              setQuartos={setQuartos}
              quartos={quartos}
              banheiros={banheiros}
              setBanheiros={setBanheiros}
              suites={suites}
              setSuites={setSuites}
              dependencias={dependencias}
              setDependencias={setDependencias}
              vagas={vagas}
              setVagas={setVagas}
              moneyToNumber={moneyToNumber}
              escritura={escritura}
              setEscritura={setEscritura}
            />
          )}

          <div className="container-input-box">
            <div className="box-divide">
              <small>Valor desejado pelo imóvel</small>
              <InputMask
                disabled={controlUser == "agent" ? true : false}
                type="text"
                placeholder="É o valor que pagaremos pelo imóvel"
                value={valorDesejado}
                onChange={(comp) => setValorDesejado(money(comp.target.value))}
              />
            </div>
            <div className="box-divide">
              <small>Valor de mercado avaliado</small>
              <InputMask
                disabled={controlUser == "agent" ? true : false}
                type="text"
                placeholder="É o valor que podemos vender"
                value={valorMercado}
                onChange={(comp) => setValorMercado(money(comp.target.value))}
              />
            </div>
          </div>

          <div className="container-input-box flex-column">
            <div className="box-divide max max-camp" style={{ height: 400 }}>
              <small>Observação do corretor</small>
              <textarea
                style={{ height: "93%" }}
                disabled={controlUser == "agent" ? true : false}
                placeholder="Digite aqui..."
                value={comentario}
                onChange={(comp) => setComentario(comp.target.value)}
              />
            </div>

            <div
              className="container-input-box max-camp"
              style={{ flexDirection: "column" }}
            >
              <div
                className="box-divide max"
                style={{
                  height: 320,
                  paddingLeft: 0,
                  paddingRight: 0,
                  width: "100%",
                }}
              >
                <small style={{ marginLeft: 10, paddingRight: 15 }}>
                  Converse sobre este imóvel:
                </small>
                <div
                  id="chat"
                  style={{
                    height: "90%",
                    width: "100%",
                    overflowY: "auto",
                    padding: 15,
                  }}
                >
                  {messages.map((message, i) => (
                    <>
                      {(message.type == "admin" &&
                        (messages[i > 0 ? i - 1 : i].type == "agent" ||
                          i == 0)) ||
                        (messages[i > 0 ? i - 1 : i].createdAt !=
                          message.createdAt &&
                          message.type == "admin") ? (
                        <div
                          style={{
                            color:
                              "admin" === message.type ? "#C13818" : "#05677C",
                            width: "100%",
                            marginTop: 7,
                            fontWeight: 500,
                            textAlign:
                              "admin" == message.type ? "left" : "right",
                          }}
                        >
                          {message.createdAt} - Imóvel Vazio:
                        </div>
                      ) : (
                        ""
                      )}
                      {message.type == "agent" &&
                        messages[i > 0 ? i - 1 : i].createdAt !=
                        message.createdAt ? (
                        <div
                          style={{
                            color:
                              "admin" === message.type ? "#C13818" : "#05677C",
                            width: "100%",
                            marginTop: 7,
                            fontWeight: 500,
                            textAlign:
                              "admin" == message.type ? "left" : "right",
                          }}
                        >
                          {message.createdAt}:
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        style={{
                          color:
                            "admin" === message.type ? "#C13818" : "#05677C",
                          width: "100%",
                          marginTop: 7,
                          fontWeight: 500,
                          textAlign: "admin" == message.type ? "left" : "right",
                        }}
                      >
                        {message.message}
                      </div>
                    </>
                  ))}
                </div>
              </div>

              <div
                className="box-divide max max-camp"
                style={{
                  height: 70,
                  paddingLeft: 0,
                  paddingRight: 0,
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <small style={{ marginLeft: 10, paddingRight: 15 }}>
                  Escreva uma mensagem
                </small>
                <div className="divSend">
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{
                      height: "100%",
                      width: "100%",
                      overflowY: "auto",
                      color: "#aaa",
                      border: "none",
                    }}
                  />
                  <div
                    onClick={async (e) => {
                      handleMessage();
                    }}
                    className="btSend"
                  >
                    {">"}
                  </div>
                </div>
              </div>
              {localStorage.getItem("type_user") === "admin" ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 17,
                        marginTop: 10,
                        cursor: "pointer",
                        color: "#EB5C1C",
                      }}
                      onClick={async (e) => {
                        await handleMessage(`Olá, ${nome.split(" ")[0]}`);
                        await handleMessage(
                          `Agradecemos por ter nos oferecido esse imóvel, mas não temos interesse na compra. Quando nos oferecer outra oportunidade, teremos o prazer em avaliar. Boas vendas!`
                        );
                      }}
                    >
                      [sem interesse]
                    </p>
                    <p
                      style={{
                        fontSize: 17,
                        marginTop: 10,
                        cursor: "pointer",
                        color: "#EB5C1C",
                      }}
                      onClick={async (e) => {
                        await handleMessage(`Olá, ${nome.split(" ")[0]}`);
                        await handleMessage(
                          `Infelizmente não conseguimos elaborar uma proposta de compra para o seu imóvel, pois o valor da nossa avaliação está muito diferente do informado. Caso concorde podemos fazer uma proposta baseada em nossa avaliação. Obrigado e Boas vendas!!`
                        );
                      }}
                    >
                      [sem proposta]
                    </p>
                    <p
                      style={{
                        fontSize: 17,
                        marginTop: 10,
                        cursor: "pointer",
                        color: "#EB5C1C",
                      }}
                      onClick={async (e) => {
                        await handleMessage(`Olá, ${nome.split(" ")[0]}`);
                        await handleMessage(
                          `Agradecemos por ter nos oferecido esse imóvel. `
                        );
                        await handleMessage(
                          `Nossa proposta para a compra é de R$ ${valorFinalProposta}`
                        );
                        await handleMessage(`Boas vendas!`);
                      }}
                    >
                      [proposta de R$]
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 17,
                        marginTop: 10,
                        cursor: "pointer",
                        color: "#EB5C1C",
                      }}
                      onClick={async (e) => {
                        await handleMessage(`Olá, ${nome.split(" ")[0]}`);
                        await handleMessage(
                          `Por favor, envie as FOTOS do imóvel fazendo upload logo abaixo. Boas vendas!`
                        );
                      }}
                    >
                      [Enviar fotos]
                    </p>
                    <p
                      style={{
                        fontSize: 17,
                        marginTop: 10,
                        cursor: "pointer",
                        color: "#EB5C1C",
                      }}
                      onClick={async (e) => {
                        await handleMessage(`Olá, ${nome.split(" ")[0]}`);
                        await handleMessage(
                          `Por favor, envie os DOCUMENTOS do imóvel fazendo upload logo abaixo. Boas vendas!`
                        );
                      }}
                    >
                      [Enviar Docs]
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <>
            {photosPermission && (
              <div style={{ marginTop: "30px" }}>
                <label>
                  <strong>
                    Arquivos de imagem e documentos pdf permitidos, com até
                    10mb*
                  </strong>
                </label>
                <Carousel style={{ marginTop: "30px" }}>
                  {imageCounter.map((value, index) => (
                    <Item
                      key={index}
                      draggable={true}
                      id={index}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <ImageItem
                        htmlFor={`image-${value.id}`}
                        image={value.url ? `'${value.url}'` : ""}
                        className="image-container"
                        onClick={(e) => {
                          if (value.url !== undefined) {
                            setPhotoModal(true)
                          }
                        }
                        }
                      >
                        {value.url === undefined && <input
                          multiple="multiple"
                          type="file"
                          name="image"
                          id={`image-${value.id}`}
                          onInput={(e) => {
                            if (e.target?.files[0]?.size > 2 * 5120000) {
                              return notification.info({
                                message: 'Tamanho máximo: 10Mb',
                              });
                            }
                            addImage(e.target.files, value.id);
                          }}
                        />}
                        <button
                          className="close"
                          show={value.show ? "true" : undefined}
                          onClick={(e) => {
                            e.stopPropagation();

                            if (
                              window.confirm(
                                "A imagem será deletada, deseja continuar?"
                              )
                            ) {
                              removeImage(value.id);
                            }
                          }}
                        >
                          x
                        </button>
                      </ImageItem>
                      <DownloadLink url={value.url} name={value?.name}>
                        [Baixar foto]
                      </DownloadLink>
                    </Item>
                  ))}
                  <BtnWrapper>
                    <PlusBtn
                      onClick={() =>
                        setImageCounter([
                          ...imageCounter,
                          {
                            id: crypto
                              .createHash("sha256")
                              .update(Math.random().toString())
                              .digest("hex"),
                          },
                        ])
                      }
                    >
                      <img
                        src={require("../../assets/adminOfferProperty/plus.svg")}
                        alt=""
                      />
                    </PlusBtn>
                  </BtnWrapper>
                </Carousel>
                {imageCounter &&
                  imageCounter.length > 0 &&
                  imageCounter.filter((obj) => !!obj.url).length > 0 && (
                    <div style={{ marginTop: "15px" }}>
                      <a
                        style={{ color: "#00AEC8", cursor: "pointer" }}
                        href={`${process.env.REACT_APP_FILES_URL}/${ID}/fotos.zip`}
                        target="_blank"
                      >
                        Fazer download das fotos
                      </a>
                    </div>
                  )}
              </div>
            )}
            {docsPermission && (
              <div>
                <Carousel style={{ marginTop: "30px" }}>
                  {docCounter.map((value, index) => (
                    <Item key={index}>
                      <DocItem
                        htmlFor={`doc-${value.id}`}
                        image={
                          value.name || value.name == "teste"
                            ? require("../../assets/adminOfferProperty/docok.svg")
                            : ""
                        }
                        className="doc-container"
                      >
                        <input
                          multiple="multiple"
                          type="file"
                          name="doc"
                          id={`doc-${value.id}`}
                          onInput={(e) => {
                            if (e.target.files[0].size > 2 * 5120000) {
                              return notification.info({
                                message: 'Tamanho máximo: 10Mb',
                              });
                            }
                            addDoc(e.target.files, value.id);
                          }}
                        />
                        <button
                          className="close"
                          show={value.file ? "true" : "false"}
                          onClick={() => {
                            if (value.image == "teste") {
                              if (
                                window.confirm(
                                  "O documento será deletado, deseja continuar?"
                                )
                              ) {
                                removeDoc(value.id);
                              }
                            } else {
                              removeDoc(value.id);
                            }
                          }}
                        >
                          x
                        </button>
                      </DocItem>
                      <DownloadLink
                        url={value.url}
                        name={value?.name}
                      ></DownloadLink>
                    </Item>
                  ))}
                  <BtnWrapper>
                    <PlusBtn
                      onClick={() =>
                        setDocCounter([
                          ...docCounter,
                          {
                            id: crypto
                              .createHash("sha256")
                              .update(Math.random().toString())
                              .digest("hex"),
                          },
                        ])
                      }
                    >
                      <img
                        src={require("../../assets/adminOfferProperty/plus.svg")}
                        alt=""
                      />
                    </PlusBtn>
                  </BtnWrapper>
                </Carousel>
                {docCounter &&
                  docCounter.length > 0 &&
                  docCounter.filter((obj) => !!obj.url).length > 0 && (
                    <a
                      style={{
                        color: "#00AEC8",
                        cursor: "pointer",
                        marginTop: 20,
                        display: "block",
                      }}
                      href={`${process.env.REACT_APP_FILES_URL}/${ID}/docs.zip`}
                      target="_blank"
                    >
                      Fazer download dos documentos
                    </a>
                  )}
              </div>
            )}
          </>
          <div className="analise">Pré-análise: {analise}</div>

          <div className="container-input-box">
            {userType === "admin" && (
              <div className="box-divide">
                <small>Status</small>
                <select
                  disabled={controlUser == "agent" ? true : false}
                  id="input-tolerancia"
                  type="number"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Novo">Novo</option>
                  <option value="Analisando">Analisando</option>
                  <option value="Sem Interesse">Sem Interesse</option>
                  <option value="Negociando">Negociando</option>
                  <option value="Não Comprado">Não Comprado</option>
                  <option value="Comprado">Comprado</option>
                  <option value="Acima do Preço">Acima do Preço</option>
                  <option value="Avaliado">Avaliado</option>
                  <option value="Vendido">Vendido</option>
                  <option value="Aguardando infos">Aguardando infos</option>
                </select>
              </div>
            )}

            {controlUser == "agent" ? (
              ""
            ) : (
              <div className="box-divide">
                <small>Valor final avaliado</small>
                <InputMask
                  disabled={controlUser == "agent" ? true : false}
                  value={valorAnalise}
                  onChange={(comp) => setValorAnalise(money(comp.target.value))}
                />
              </div>
            )}
          </div>
          <div className="container-input-box">
            <div
              className="box-divide max"
              style={{ display: controlUser == "agent" ? "none" : "flex" }}
            >
              <small>Observação do admin</small>
              <textarea
                placeholder="Digite aqui..."
                value={obs}
                onChange={(comp) => setObs(comp.target.value)}
              />
            </div>
            <div className="box-divide">
              <small>Valor final da proposta</small>
              <InputMask
                disabled={controlUser == "agent" ? true : false}
                value={valorFinalProposta}
                onChange={(comp) =>
                  setValorFinalProposta(money(comp.target.value))
                }
              />
            </div>
          </div>



          <RestrictedView userType={["admin"]} permission={null} >
            <RealEstateAppraisals offerId={offerId}
              {...{
                itbiAvgValueCurrentYear,
                itbiPriceCurrentYear,
                itbiAvgValueLastYear,
                itbiPriceLastYear,
                iVAvgValueLastYear,
                iVPriceLastYear,
                iVAvgValueCurrentYear,
                iVPriceCurrentYear,
                landSizeUseful,
                createdAt
              }} ></RealEstateAppraisals>
          </RestrictedView>

          <div className="container-next">
            <button
              onClick={(e) => {
                if (controlUser == "agent") {
                  window.location.href = "/admin/venda-corretor";
                } else {
                  handleSubmit();
                }
              }}
            >
              {controlUser == "agent" ? "Voltar" : "Salvar"}
            </button>
          </div>
        </div>
      </Container>
    </>
  );
}
