import React from "react";
import * as S from "./styles";
import "../../../../global.css";
import SuccessImg from '../../../../assets/success.svg'

export default function PrepareAds() {
  
  return (
    <>
      <S.Container>
          <img src={SuccessImg} alt="Img Success" />
          <S.Title>Imóvel preparado para anúncio</S.Title>
          <S.SubTitle>Confira este anúncio em sua área de <strong>Gerenciamento de Anúncios</strong> ou continue buscando outros imóveis.</S.SubTitle>
      </S.Container>
    </>
  );
}
