import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import { BgImg, FilterContainer } from "./styles";
import Footer from "../../components/Footer";
import "../../global.css";
import { Link } from "react-router-dom";
import api from "../../api";

import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import StepFour from "./stepFour";
import StepEight from "./stepEight";
import { encryptSerialKey, generateKey } from "../../utils/cryptography-helper";

import { NOTIFICATION_TYPE, openNotification } from "../../utils/notification";
import { getUserId } from "../../utils/sessionHelper";

export default function Offer() {

  const [ticket, setTicket] = useState('');
  const [attemps, setAttemps] = useState(0)
  const [step, setStep] = useState(1);
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [tipo, setTipo] = useState("");
  const [bairro, setBairro] = useState("");
  const [currentPlace, setCurrentPlace] = useState({});

  const [metragem, setMetragem] = useState();
  const [areaConstruida, setAreaConstruida] = useState();
  const [areaTerreno, setAreaTerreno] = useState();
  const [areaIptu, setAreaIptu] = useState();
  const [condominio, setCondominio] = useState();
  const [nIPTU, setNIPTU] = useState(""); // Desabilitado
  const [quartos, setQuartos] = useState();
  const [suites, setSuites] = useState('');
  const [dependencias, setDependencias] = useState('');
  const [vagas, setVagas] = useState('');
  const [constaIptu, setConstaIptu] = useState("");
  // novos campos
  const [posicao, setPosicao] = useState("");
  const [andar, setAndar] = useState("");
  const [banheiros, setBanheiros] = useState('');
  const [escritura, setEscritura] = useState(false);

  const [valorImovel, setValorImovel] = useState("0");
  const [valorMercado, setValorMercado] = useState("0");
  const [comentario, setComentario] = useState("");

  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [block, setBlock] = useState(true);

  useEffect(() => {
    setTicket(`${generateKey()}${generateKey()}${generateKey()}`.toUpperCase());
  }, []);

  const handleStep = (value) => {
    if (value == 6) {
      setTimeout(() => {
        window.location.href = "/ofertar-imovel/sucesso";
      }, 400);
    }
    setStep(value);
  };

  async function handleRegister(valorDesejado, analise) {

    let val1 = valorImovel + "";
    let val2 = valorMercado + "";
    let valueDesejado = val1
      .replace("R", "")
      .replace("$", "")
      .replace(" ", "")
      .replaceAll(".", "")
      .replace(",", "");
    valueDesejado =
      valueDesejado.substr(0, valueDesejado.length - 2) +
      "." +
      valueDesejado.substr(valueDesejado.length - 2, 2);
    let valueMercado = val2
      .replace("R", "")
      .replace("$", "")
      .replace(" ", "")
      .replaceAll(".", "")
      .replace(",", "");
    valueMercado =
      valueMercado.substr(0, valueMercado.length - 2) +
      "." +
      valueMercado.substr(valueMercado.length - 2, 2);
    let id_user = getUserId();

    const serializeContent = {
      place: currentPlace,
      address: endereco,
      bathrooms: banheiros === "" ? 0 : banheiros,
      position: posicao,
      level: andar,
      scripture: escritura, // o novo aqui
      addressNumber: numero,
      addressComplement: complemento,
      type: tipo,
      landSizeUseful: metragem && parseFloat(metragem.replace(",", ".")),
      constructArea:
        areaConstruida && parseFloat(areaConstruida.replace(",", ".")),
      totalArea: areaTerreno && parseFloat(areaTerreno.replace(",", ".")),
      iptuArea: areaIptu && parseFloat(areaIptu.replace(",", ".")),
      constructOnIptu: constaIptu ? constaIptu : false,
      numberIPTU: nIPTU,
      bedrooms: quartos,
      suites: suites === "" ? 0 : suites,
      bairro,
      dependencies: dependencias === "" ? 0 : dependencias,
      parkingSpots: vagas === "" ? 0 : vagas,
      condominiumPrice: condominio,
      valueDesejado: valorDesejado ? valorDesejado : valueDesejado,
      valueMercado,
      analise: analise ? analise : "Aceita",
      coment: comentario,
      id_user,
      email,
      nome,
      telefone,
      location: window?.location?.origin,
    };

    const serializedRequest = encryptSerialKey(JSON.stringify(serializeContent));

    try {
      const { data } = await api.post("/offer", { ticket, ...serializedRequest });
      openNotification(data.message, NOTIFICATION_TYPE.success);
    } catch (e) {
      const { response: { data, status } } = e;
      openNotification(data.message ?? 'Verifique as informações e tente novamente!', NOTIFICATION_TYPE.error)
      if (analise == "Negada") {
        handleStep(7);
      }
      handleStep(1);
      throw e;
    }

  }

  return (
    <>
      <BgImg>
        <Header />
        <FilterContainer>
          <div className="login-wrapper">
            <div className="info-box">
              {step == 1 ? (
                <div className="info-box-2">
                  <h1>Prezado(a)</h1>
                  <p>
                    {/* Obrigado pela oferta, iremos entrar em contato por e-mail e WhatsApp em até 48h */}

                    Sabe aquele imóvel que ninguém quer comprar, ou porque está
                    com problema de documentação, ou porque está precisando de
                    muita reforma, ou qualquer outro motivo? Preencha o
                    formulário ao lado somente se o preço do seu imóvel estiver
                    abaixo do valor de mercado.
                  </p>
                </div>
              ) : (
                ""
              )}

              {step == 2 ? (
                <div className="info-box-2">
                  <h1>Você está a poucos passos de ofertar o seu imóvel!</h1>
                  <p>Complete com os detalhes do imóvel.</p>
                </div>
              ) : (
                ""
              )}

              {step == 3 ? (
                <div className="info-box-2">
                  <h1>Quanto custa o seu imóvel?</h1>
                  <p>
                    Preencha os campos de valor desejado e valor de mercado.
                  </p>
                </div>
              ) : (
                ""
              )}

              {step == 4 ? (
                <div className="info-box-2">
                  <h1>Estamos analisando sua oferta</h1>
                  <p>Aguarde a resposta em instantes.</p>
                </div>
              ) : (
                ""
              )}

              {step == 5 ? (
                <div className="info-box-2">
                  <h1>Já temos uma resposta para sua oferta!</h1>
                </div>
              ) : (
                ""
              )}

              {step == 6 ? (
                <div className="info-box-2">
                  <h1>Talvez possamos fechar negócio!</h1>
                </div>
              ) : (
                ""
              )}

              {step == 7 ? (
                <div className="info-box-2">
                  <h1>Ainda não foi dessa vez!</h1>
                </div>
              ) : (
                ""
              )}

              {step == 8 ? (
                <div className="info-box-2">
                  <h1>Quanto custa o seu imóvel?</h1>
                  <p>
                    Preencha os campos de valor desejado e valor de mercado.
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="register-box">
              <div className="body-box">
                <div className="steps-box">
                  <div className="step-horizontal">
                    <div
                      className={step == 1 ? "step-circle" : "step-complete"}
                    >
                      1
                    </div>

                    {window.innerWidth <= 600
                      ? step == 1
                        ? "Endereço"
                        : ""
                      : "Endereço"}
                  </div>
                  <div className="step-horizontal">
                    <div
                      className={
                        step == 2
                          ? "step-circle"
                          : step < 2
                            ? "step-disable"
                            : "step-complete"
                      }
                    >
                      2
                    </div>

                    {window.innerWidth <= 600
                      ? step == 2
                        ? "Informação"
                        : ""
                      : "Informação"}
                  </div>
                  <div className="step-horizontal">
                    <div
                      className={
                        step == 3
                          ? "step-circle"
                          : step < 3
                            ? "step-disable"
                            : "step-complete"
                      }
                    >
                      3
                    </div>

                    {window.innerWidth <= 600
                      ? step == 3
                        ? "Valor"
                        : ""
                      : "Valor"}
                  </div>
                  <div className="step-horizontal">
                    <div
                      className={
                        step == 4 ||
                          step == 5 ||
                          step == 6 ||
                          step == 7 ||
                          step == 8
                          ? "step-circle"
                          : step < 4
                            ? "step-disable"
                            : "step-complete"
                      }
                    >
                      4
                    </div>

                    {window.innerWidth <= 600
                      ? step == 4
                        ? "Confirmação"
                        : ""
                      : "Confirmação"}
                  </div>
                </div>

                {step == 1 ? (
                  <StepOne
                    step={step}
                    block={block}
                    setBlock={setBlock}
                    setBairro={setBairro}
                    setTipo={setTipo}
                    tipoSelected={tipo}
                    setStep={handleStep}
                    complemento={complemento}
                    setComplemento={setComplemento}
                    endereco={endereco}
                    setEndereco={setEndereco}
                    numero={numero}
                    setNumero={setNumero}
                    setCurrentPlace={setCurrentPlace}
                    currentPlace={currentPlace}
                  />
                ) : step == 2 ? ( //novos campos
                  <StepTwo
                    setNIPTU={setNIPTU}
                    nIPTU={nIPTU}
                    constaIptu={constaIptu}
                    setConstaIptu={setConstaIptu}
                    setStep={handleStep}
                    condominio={condominio}
                    setCondominio={setCondominio}
                    metragem={metragem}
                    setMetragem={setMetragem}
                    dependencias={dependencias}
                    setDependencias={setDependencias}
                    quartos={quartos}
                    setQuartos={setQuartos}
                    suites={suites}
                    setSuites={setSuites}
                    vagas={vagas}
                    setVagas={setVagas}
                    posicao={posicao}
                    setPosicao={setPosicao}
                    andar={andar}
                    setAndar={setAndar}
                    banheiros={banheiros}
                    setBanheiros={setBanheiros}
                    escritura={escritura}
                    setEscritura={setEscritura}
                    tipo={tipo}
                    setAreaConstruida={setAreaConstruida}
                    areaConstruida={areaConstruida}
                    setAreaIptu={setAreaIptu}
                    areaIptu={areaIptu}
                    setAreaTerreno={setAreaTerreno}
                    areaTerreno={areaTerreno}
                  />
                ) : step == 3 ? (
                  <StepThree
                    setStep={handleStep}
                    setComentario={setComentario}
                    comentario={comentario}
                    setValorImovel={setValorImovel}
                    valorImovel={valorImovel}
                    setValorMercado={setValorMercado}
                    valorMercado={valorMercado}
                  />
                ) : step >= 4 && step !== 8 ? (
                  <StepFour
                    setValorMercado={setValorMercado}
                    setValorProposta={setValorImovel}
                    setStep={handleStep}
                    step={step}
                    valorMercado={valorMercado}
                    valorProposta={valorImovel}
                    handleRegister={handleRegister}
                  />
                ) : step >= 8 ? (
                  <StepEight
                    handleRegister={handleRegister}
                    setTelefone={setTelefone}
                    telefone={telefone}
                    setStep={handleStep}
                    setEmail={setEmail}
                    email={email}
                    setNome={setNome}
                    nome={nome}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </FilterContainer>
      </BgImg>

      <Footer />
    </>
  );
}
