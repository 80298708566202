import api from "../api";
import queryString from "../utils/queryString";

export const getPropertiesProposalsAsync = ({limit = 9999, ...rest}, isAuth = false, cancelToken = undefined) => {
  const request = {limit, ...rest };
  const url = 'proposalImovel';
  return api.get(
    `/${url}${queryString(request)}`,
  ).catch(({ response: error }) => {
    const { status, data } = error;
    localStorage.clear();
    console.error(data);
    if (status === 401) window.location.href = "/login";
  });

};
