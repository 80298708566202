import crypto from "crypto";

export const generateKey = () => (Math.random() + 1).toString(36).substring(2);

export const encryptSerialKey = (content = '') => {
  if (!content) return content;
  const keys = [];
  for (let i = 0; i < 6; i++)
    keys.push(generateKey());

  const cipher = crypto.createCipher('aes-128-cbc', keys[3]);
  const hash = cipher.update(content, 'utf8', 'hex')
  return {
    hash: `${hash}${cipher.final('hex')}`,
    serialKey: keys.join('-')
  };

};