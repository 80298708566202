import styled from 'styled-components'
import Banner from '../../assets/banner.png'

export const BannerContainer = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 99999;
   background-color: rgba(0, 0, 0, 0.4);
   backdrop-filter: blur(15px);
   display: flex;
   justify-content: center;
   align-items: center;
   opacity: 1;
   pointer-events: all;
   transition: all 0.3s;

   .container {
    width: 80%;
    background-color: #fff;
    max-width: 650px;
    border-radius: 10px;
    height: 50%;
    transform: scale(1);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
    outline: none;
    transition: all 0.3s;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    justify-content: space-around;
    flex-direction: column;
    display: flex;

    @media (max-width: 700px) {
      justify-content: space-between;
   }

      @media (max-width: 350px) {
         justify-content: space-between;
         height: 500px;
      }
   }

   .container-close {
    display: flex;
    justify-content: end;
    padding-right: 10px;
   }

   .close {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    z-index: 999;
   }

   nav {
    text-align: center;
    padding-top: 20px;
   }

   h3 {
   @media (max-width: 700px) {
     font-size: 20px;
   }
   }

   .subtitle {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 14px;
   }

   .subtitle, .first-subtitle {
    display: block;
    margin-bottom: 8px;
   }

   .link-text {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
   }

   .btns {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media (max-width: 700px) {
      flex-direction: column;
      align-items: center;
    }
   }

   button {
      width: 250px;

      @media (max-width: 700px) {
        width: 95%;
      }
   }

   .btn-ok {
    background-color: #0088AC;
    color: #fff;
    border-radius: 3px;
    padding: 7px;
    font-size: 14px;
    margin-right: 12px;

      @media (max-width: 700px) {
         margin-right: 0;
         margin-bottom: 20px;
         
      }
   }

   .btn-follow {
    color: #0088AC;
    background-color: #fff;
    border: 1px solid #0088AC;
    border-radius: 3px;
    border: none;
    padding: 7px;
    font-size: 14px;
   }
`