import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100% - 20vw);
  position: absolute;
  right: 0;

  @media only screen and (max-width: 600px) {
      padding-top: 4vh;
      margin-left: 0;
      width: 100%;
      padding-right: 0px;
      padding: 30px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 80vh;
`

export const ContentTitle = styled.div`
    display: flex;
    padding: 16px 33px;
    margin-top: 10vh;
    justify-content: space-between;
    align-items: center;
    background: #F1F3F9;

     @media only screen and (max-width: 600px) {
        flex-direction: column;
        margin-top: 3vh;
    }
    

  .content-button-zap {
    display: flex;
    align-items: center;
    gap: 15px;
  } 

  .bread-crumb{
    display:flex;
    align-items:center; 
    gap: 8px;

    .title-list {
        color: #3B414A;
        text-align: right;
        font-family: 'Roboto';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
  }

  .zap-linking {
    background-color: #EA5C1D;
    border: none;
    border-radius: 6px;
    display: flex;
    padding: 15px 16px;
    align-items: center;
    gap: 8px;

    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .zap-linking-disabled {
    background-color: #B3B7BF;
    border: none;
    border-radius: 6px;
    display: flex;
    padding: 15px 16px;
    align-items: center;
    gap: 8px;
    cursor: none;

    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`

export const ContainerPopoverBindingZap = styled.div`
    max-width: 350px;
    padding: 15px;

     .popover-text-binding {
      font-weight: 400;
      font-size: 16px;
      color: #3B414A;
    }
`

export const TagStatusList = styled.li`
    border-radius: 16px;
    border: 1px solid ${props => props?.isAccessLogZap ? '#147F25' : '#E60023' };
    background: ${props => props?.isAccessLogZap ? '#E8FDD9' : '#FEE7D8' };
    display: flex;
    padding: 6px 10px;
    align-items: center;
    gap: 6px;

    color:  ${props => props?.isAccessLogZap ? '#147F25' : '#E60023' };
    text-align: right;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .indicate {
     width: 6px;
     height: 6px;
     border-radius: 50%;
     background-color: ${props => props.isAccessLogZap ? '#147F25' : '#E60023' };
    }
`


export const ContentInformation = styled.div`
  background: #FFECCC;
  width: 100%;
  display: flex;
  padding: 10px 33px;
  align-items: center;
  gap: 10px;

  img {
    border-radius: 200px;
    background: #F0A000;   
    padding: 2px;
  }

  .title-information {
    color: #3B414A;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

export const ContentList = styled.div`
  background-color: #fff;
  display: flex;
  padding: 43px 33px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  height: 100%;
`

export const ContentTitleTable = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 11px 16px;

  input {
    display: flex;
    width: 262px;
    height: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #B3B4BA;
    background: #FFF;
    padding: 15px 13px;

    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  input::placeholder {
    color: #B3B4BA;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  button {
    display: flex;
    padding: 8px 10px;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #0088AC;
    background: #FFF;

    color: #0088AC;
    text-align: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`

export const ContainerVerifyPrepareAds = styled.div`
  display: flex;
  height: 100%;
`

export const ContentImgVerifyPrepareAds = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 35%;
  background-color: #FFFAF3;
  height: 100%;

  .content-back {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: flex-start !important;    
    width: 70%;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .title-back {
    color: #474C57;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`

export const ContentInformationVerifyPrepareAds = styled.div`
  width: 65%;
  padding-top: 30px;
  padding-left: 30px;
`

export const ContainerPopover = styled.div`
  display: flex;
  width: 281px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  background: #FFF;
`

export const ContentField = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
`

export const LabelField = styled.label`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 0 !important;
  width: 100%;
`

export const ContentFieldLink = styled.div`
  width: 100%;
  position: relative;

  img {
    position: absolute;
    background-color: #fff;
    right: 8px;
    top: 2px;
    padding: 10px;
    cursor: pointer;
  }
`

export const ContentTitlePopupCopy = styled.div`
  padding: 30px;
`

export const TitlePopupCopy = styled.span`
   padding: 20px;
   font-size: 16px;
   margin-bottom: 30px;
   margin-top: 30px;
   font-weight: 500;
`

export const Field = styled.input`
  display: flex;
  padding: 10px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  width: 100%;

  color: #3B414A;
  /* Body/Medium/ts.body-large-regular */
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  `;

export const ContentTitleDownloadList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;

  .title-download {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    padding-right: 8px;
    cursor: pointer;
  }

  img {
    padding-left: 6px;
  }
`

export const ContentHR = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 15px 15px 15px;

  hr {
    color: #B3B4BA;
    background-color: #B3B4BA;
    width: 50%;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    color: #B3B4BA;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
`