// Core
import React from "react";

// Components
import InputMask from "react-input-mask";

const RoofForm = React.memo(function RoofForm({
  money,
  moneyToNumber,
  areaIptu,
  setAreaIptu,
  areaConstruida,
  setAreaConstruida,
  condominio,
  setCondominio,
  vagas,
  setVagas,
  quartos,
  setQuartos,
  suites,
  setSuites,
  banheiros,
  setBanheiros,
  setEscritura,
  escritura,
  dependencias,
  setDependencias,
}) {
  return (
    <>
      <div className="container-input-box">
        <div className="box-divide" required id="metragem">
          <small>Area no IPTU (m2) *</small>
          <InputMask
            type="number"
            onKeyDown={(e) => (e.key == "-" ? e.preventDefault() : "")}
            onChange={(text) => setAreaIptu(text.target.value)}
            value={areaIptu}
          />
        </div>
        <div className="box-divide" required id="metragem">
          <small>Area construída (m2) *</small>
          <InputMask
            type="number"
            onKeyDown={(e) => (e.key == "-" ? e.preventDefault() : "")}
            onChange={(text) => setAreaConstruida(text.target.value)}
            value={areaConstruida}
          />
        </div>
      </div>
      <div className="container-input-box">
        <div className="box-divide" required id="quartos">
          <small>Número de quartos (inclui suítes) *</small>
          <InputMask
            min={0}
            type="number"
            onKeyDown={(e) => (e.key == "-" ? e.preventDefault() : "")}
            value={quartos}
            onChange={(text) => setQuartos(text.target.value)}
          />
        </div>
        <div className="box-divide">
          <small>Número de suites</small>
          <InputMask
            min={0}
            type="number"
            onChange={(text) => setSuites(text.target.value)}
            onKeyDown={(e) => (e.key == "-" ? e.preventDefault() : "")}
            value={suites}
          />
        </div>
      </div>
      <div className="container-input-box">
        <div className="box-divide" required id="banheiros">
          <small>Número de banheiros (exceto de serviço) *</small>
          <InputMask
            min={0}
            type="number"
            onKeyDown={(e) => (e.key == "-" ? e.preventDefault() : "")}
            value={banheiros}
            onChange={(text) => setBanheiros(text.target.value)}
          />
        </div>
        <div className="box-divide" id="dependencias">
          <small>Número de dependências de serviço</small>
          <input
            min={0}
            type="number"
            value={dependencias}
            onKeyDown={(e) => (e.key == "-" ? e.preventDefault() : "")}
            onChange={(text) => setDependencias(text.target.value)}
          />
        </div>
      </div>
      <div className="container-input-box">
        <div className="box-divide" id="vagas">
          <small>Número de Vagas?</small>
          <InputMask
            min={0}
            type="number"
            value={vagas}
            onKeyDown={(e) => (e.key == "-" ? e.preventDefault() : "")}
            onChange={(text) => setVagas(text.target.value)}
          />
        </div>
        <div className="box-divide" required id="condominio">
          <small>Valor do Condomínio *</small>
          <InputMask
            type="text"
            value={money(condominio)}
            onChange={(text) => setCondominio(moneyToNumber(text.target.value))}
          />
        </div>
      </div>
      <div
        className="box-divide"
        style={{ backgroundColor: "transparent", marginLeft: 0 }}
      >
        <label class="control control-checkbox" style={{ marginLeft: -10 }}>
          <small style={{ fontSize: 16, fontWeight: 400 }}>
            Vaga na escritura
          </small>
          <input
            checked={escritura}
            type="checkbox"
            onChange={(evt) => setEscritura(evt.target.checked)}
          />
          <div class="control_indicator"></div>
        </label>
        <br />
      </div>
      <div className="infos">
        <strong>Confirme: </strong>
        {quartos} quarto(s), sendo {suites} suíte(s) e {dependencias}{" "}
        dependência(s).
      </div>
    </>
  );
});

export default RoofForm;
