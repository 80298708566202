export const PERMISSION = {
  all: '*',
  regional: 'only-location',
  marketing: 'only-marketing',
  banking: 'only-banking',
}

export const USER_TYPE = {
  agent: 'agent',
  admin: 'admin',
};