import React from 'react';

const TickCircleIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/tick-circle">
        <g id="tick-circle">
        <path id="Vector" d="M5.16738 7.99992L7.05405 9.88659L10.8341 6.11326M8.00065 14.6667C11.6673 14.6667 14.6673 11.6667 14.6673 8.00004C14.6673 4.33337 11.6673 1.33337 8.00065 1.33337C4.33398 1.33337 1.33398 4.33337 1.33398 8.00004C1.33398 11.6667 4.33398 14.6667 8.00065 14.6667Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        </g>
    </svg>

  );
};

export default TickCircleIcon;
