export const ACTION_STATE = {
  await: 'Aguardar',
  process: 'Processar',
  finish: 'Finalizar',
}

export const NOTIFICATION_TYPE = {
  warning: {
    type: "warning",
    message: "Atenção!",
  },
  success: {
    type: "success",
    message: "Sucesso!",
  },
};
