import styled from 'styled-components';

export const Button = styled.button`
    width: 150px;
    height: 40px;
    margin-top: 20px;
    color: white;
    background: linear-gradient(
    90deg, #EA5C1E 0%, #C13818 100%);
    border: none;
    border-radius: 3px;
`;

export const Container = styled.div`
  height:90vh;
  width:20vw;
  position:fixed;
  top:10vh;
  background: #0A677C;    
  color: #FFFFFF;
  display:flex;
  flex-direction:column;
  text-align:left;
  padding:30px 0px 0px 20px;
  position: absolute;
  //height: 1500px;
  
  @media only screen and (max-width: 600px) {
    width: 75%;
    height: 100%;
    top: 0px;
    z-index: 55555;
    display: none;
    position: fixed;
    // top: 120px;
    // text-align: center;
    //position: unset;
  }

  h1{
    font-weight: bold;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.05em;
    margin-bottom:5px;
    color: #E5983D
  }

  .link-group{
    margin-bottom:15px;
    display:flex;
    flex-direction:column;
    text-align:left;
    
  }

  a{
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    text-decoration:none;
    margin-top:5px;
    
    @media only screen and (max-width: 600px) {
      text-align: start;//center
    }
  }

`;
