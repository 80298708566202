import React from 'react';

const EditIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/edit-2">
        <g id="edit-2">
        <path id="Vector" d="M7.92667 3.36661C8.21333 5.20661 9.70667 6.61327 11.56 6.79994M2 14.6666H14M8.84 2.39994L3.36667 8.19327C3.16 8.41327 2.96 8.84661 2.92 9.14661L2.67333 11.3066C2.58667 12.0866 3.14667 12.6199 3.92 12.4866L6.06667 12.1199C6.36667 12.0666 6.78667 11.8466 6.99333 11.6199L12.4667 5.82661C13.4133 4.82661 13.84 3.68661 12.3667 2.29327C10.9 0.913274 9.78667 1.39994 8.84 2.39994Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        </g>
    </svg>
  );
};

export default EditIcon;
