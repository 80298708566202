import React, { useEffect, useState } from "react";
import Header from "../../components/adminHeader";
import Aside from "../../components/adminSideBar";
import { Container } from "./styles";
import api from "../../api";
import { Link } from "react-router-dom";
import { getPropertiesProposalsAsync } from "../../services/proposalImovel.service";
import { notification } from "antd";

export default function PropostaCorretorEditar() {
  const [data, setData] = useState([]);
  const [dataHistorico, setDataHistorico] = useState([]);
  const [status, setStatus] = useState("false");
  const [valorVista, setValorVista] = useState([]);
  const [valorComissao, setValorComissao] = useState([]);
  const [valorTotalMenosComissao, setValorTotalMenosComissao] = useState([]);
  const [valorFinanciado, setValorFinanciado] = useState([]);
  const [valorProposta, setValorProposta] = useState([]);
  const [ID, setID] = useState(0);
  const [indexValores, setIndex] = useState(0);

  useEffect(() => {
    if (!localStorage.getItem("type_user")) {
      window.location.href = "/login";
    }

    handleLoad();
  }, []);

  function cleanStates() {
    setValorFinanciado([]);
    setValorProposta([]);
    setValorVista([]);
    setStatus([]);
    setValorComissao([]);
    setValorTotalMenosComissao([]);
  }
  async function handleLoad() {
    cleanStates();
    let id = new URL(window.location).searchParams.get("id");
    setID(id);
    const response = await getPropertiesProposalsAsync({
      Property: id,
      User: localStorage.getItem('id_painel')
    });
    let array = [];
    if (response.data.length === 0) return window.history.go(-1);

    const valorVistaArray = [];
    const valorFinanciadoArray = [];
    const valorPropostaArray = [];
    const valorComissaoArray = [];
    const valorTotalMenosComissaoArray = [];

    response.data.ProposalImovel.map((e, i) => {
      if (!String(e.valorVista).includes(".")) {
        valorVistaArray.push(money(String(e.valorVista) + "00"));
      } else {
        valorVistaArray.push(money(String(e.valorVista)));
      }

      if (!String(e.valorProposta).includes(".")) {
        valorPropostaArray.push(money(String(e.valorProposta) + "00"));
      } else {
        valorPropostaArray.push(money(String(e.valorProposta)));
      }

      if (!String(e.valorComissao).includes(".")) {
        valorComissaoArray.push(money(String(e.valorComissao) + "00"));
      } else {
        valorComissaoArray.push(money(String(e.valorComissao)));
      }

      if (!String(e.valorTotalMenosComissao).includes(".")) {
        valorTotalMenosComissaoArray.push(
          money(String(e.valorTotalMenosComissao) + "00")
        );
      } else {
        valorTotalMenosComissaoArray.push(
          money(String(e.valorTotalMenosComissao))
        );
      }

      if (!String(e.valorFinanciado).includes(".")) {
        valorFinanciadoArray.push(money(String(e.valorFinanciado) + "00"));
      } else {
        valorFinanciadoArray.push(money(String(e.valorFinanciado)));
      }

      array.push(e);
    });

    setValorVista(valorVistaArray);
    setValorProposta(valorPropostaArray);
    setValorFinanciado(valorFinanciadoArray);
    setValorComissao(valorComissaoArray);
    setValorTotalMenosComissao(valorTotalMenosComissaoArray);

    if (array.length === 0)
      return (window.location.href = "/admin/propostas-corretor");

    array.sort((a, b) => {
      if (a.status == "Novo") {
        return -1;
      }
    });

    array.sort((a, b) =>
      a.status > b.status ? -1 : a.status < b.status ? 1 : 0
    );

    setData(array);
  }

  function moneyToNumber(value) {
    value = String(value);
    return value
      .replace("R", "")
      .replace("$", "")
      .replaceAll(" ", "")
      .replaceAll(".", "*")
      .replace(",", ".")
      .replaceAll("*", "")
      .substr(1, value.length - 1);
  }

  function money(value) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(+value.replace(/\D+/g, "") / 100);
  }

  const handleEditar = async (id, index, historic) => {
    // if (!window.confirm("Deseja salvar as alterações?")) throw ''

    const response = await api.put(`/proposalImovel/${id}`, {
      valorFinanciado: moneyToNumber(valorFinanciado[index]),
      valorVista: moneyToNumber(valorVista[index]),
      valorProposta: moneyToNumber(valorProposta[index]),
      valorComissao: moneyToNumber(valorComissao[index]),
      valorTotalMenosComissao: moneyToNumber(valorTotalMenosComissao[index]),
      createdAt:
        new Date().toLocaleDateString("pt-BR", { day: "2-digit" }) +
        "/" +
        new Date().toLocaleDateString("pt-BR", { month: "2-digit" }) +
        "/" +
        new Date().toLocaleDateString("pt-BR", { year: "numeric" }),
    });

    if (historic.length > 0) {
      await api.put(`/proposalImovel/Historic/${id}`, {
        isCorretor: true,
        valorFinanciado: moneyToNumber(valorFinanciado[index]),
        valorVista: moneyToNumber(valorVista[index]),
        valorProposta: moneyToNumber(valorProposta[index]),
        valorComissao: moneyToNumber(valorComissao[index]),
        valorTotalMenosComissao: moneyToNumber(valorTotalMenosComissao[index]),
        createdAt:
          new Date().toLocaleDateString("pt-BR", { day: "2-digit" }) +
          "/" +
          new Date().toLocaleDateString("pt-BR", { month: "2-digit" }) +
          "/" +
          new Date().toLocaleDateString("pt-BR", { year: "numeric" }),
      });
    }

    handleLoad();

    if (response.data.success)
      notification.success({
        message: 'Proposta Enviada!',
        description: 'Proposta enviada com sucesso. Vamos entrar em contato!'
      });
  };

  useEffect(() => {
    if (valorProposta && valorProposta.length > 0) {
      let arrayValores = [];
      let arrayTotalMenosComissao = [];

      valorProposta.map((valor, index) => {
        if (index == indexValores) {
          const vista = Number(moneyToNumber(valorVista[indexValores]));
          const financiado = Number(
            moneyToNumber(valorFinanciado[indexValores])
          );
          const comissao = Number(moneyToNumber(valorComissao[indexValores]));

          const soma = vista + financiado;
          const newValorTotal = soma - comissao;

          arrayValores.push(money(String(Math.floor(soma * 100))));
          arrayTotalMenosComissao.push(
            money(String(Math.floor(newValorTotal * 100)))
          );
        } else {
          arrayValores.push(valor);
          arrayTotalMenosComissao.push(valor);
        }
      });
      setValorProposta(arrayValores);
      setValorTotalMenosComissao(arrayTotalMenosComissao);
    }
  }, [valorFinanciado, valorVista]);

  function innerRow(row, i) {
    return (
      <>
        <div
          className="body-imovel"
          style={{
            width: window.innerWidth <= 600 ? "93%" : "83%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              height: window.innerWidth <= 600 ? "30%" : "50%",
              paddingLeft: 20,
              flexDirection: "column",
            }}
          >
            <p>Data da Proposta: {row.createdAt}</p>
            <br></br>
            <p>
              Status: <b style={{ color: "#EA5C1E" }}>{row.status}</b>
            </p>
          </div>
          <div
            style={{
              width: "100%",
              height: window.innerWidth <= 600 ? "70%" : "50%",
              paddingLeft: 20,
              paddingRight: 20,
              display: "flex",
              flexDirection: window.innerWidth <= 600 ? "column" : "row",
            }}
          >
            <div className="input-group-teste">
              <label htmlFor="">Valor à Vista</label>
              <input
                placeholder="Ex: R$ 1.200.000,00"
                disabled={String(row.status).includes("Cancelad")}
                value={valorVista[i]}
                onChange={(element) => {
                  const arrayVista = [];

                  valorVista.map((valor, index) => {
                    if (index == i) {
                      arrayVista.push(money(element.target.value));
                    } else {
                      arrayVista.push(valor);
                    }
                  });

                  setIndex(i);
                  setValorVista(arrayVista);
                }}
              />
            </div>
            <div className="input-group-teste">
              <label htmlFor="">Valor Financiado</label>
              <input
                placeholder="Ex: R$ 500.000,00"
                disabled={String(row.status).includes("Cancelad")}
                value={valorFinanciado[i]}
                onChange={(element) => {
                  const arrayFinanciado = [];

                  valorFinanciado.map((valor, index) => {
                    if (index == i) {
                      arrayFinanciado.push(money(element.target.value));
                    } else {
                      arrayFinanciado.push(valor);
                    }
                  });

                  setIndex(i);
                  setValorFinanciado(arrayFinanciado);
                }}
              />
            </div>
            <div className="input-group-teste">
              <label htmlFor="">Total da Proposta</label>
              <input
                disabled={true}
                placeholder="Ex: R$ 1.700.000,00"
                value={valorProposta[i]}
              />
            </div>
          </div>
          <div className="sub-box">
            <div className="input-group-teste">
              <label htmlFor="">Valor comissão</label>
              <input
                placeholder="Ex: R$ 1.200.000,00"
                disabled={String(row.status).includes("Cancelad")}
                value={valorComissao[i]}
                onChange={(e) => {
                  const arrayComissao = [];
                  const arrMenosComissao = [];
                  const comissao = money(e.target.value);

                  valorComissao.map((valor, index) => {
                    if (index == i) {
                      const parsedComissao = parseInt(
                        moneyToNumber(comissao)
                      ).toFixed(2);

                      const parsedValorProposta = parseInt(
                        moneyToNumber(valorProposta[index])
                      ).toFixed(2);

                      const newTotal = (
                        parsedValorProposta - parsedComissao
                      ).toFixed(2);

                      arrMenosComissao.push(
                        newTotal < 0 ? money("0") : money(newTotal)
                      );
                      arrayComissao.push(money(e.target.value));
                    } else {
                      arrayComissao.push(valor);
                      arrMenosComissao.push(valorTotalMenosComissao[index]);
                    }
                  });

                  setIndex(i);
                  setValorComissao(arrayComissao);
                  setValorTotalMenosComissao(arrMenosComissao);
                }}
              />
            </div>
            <div className="input-group-teste">
              <label htmlFor="">Valor total menos comissao</label>
              <input
                placeholder="Ex: R$ 1.200.000,00"
                disabled={true}
                value={valorTotalMenosComissao[i]}
                onChange={(element) => {
                  const arrayValorTotalMenosComissao = [];

                  valorTotalMenosComissao.map((valor, index) => {
                    if (index == i) {
                      arrayValorTotalMenosComissao.push(
                        money(element.target.value)
                      );
                    } else {
                      arrayValorTotalMenosComissao.push(valor);
                    }
                  });

                  setIndex(i);
                  setValorTotalMenosComissao(arrayValorTotalMenosComissao);
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="body-stats"
          style={{
            flexDirection: "column",
            justifyContent: "space-around",
            width: window.innerWidth <= 600 ? "50%" : "10%",
          }}
        >
          {/* {!String(row.status).includes("Cancelad") && (
            <div
              style={{
                height: "100%",
                color: "#00AEC8",
                fontSize: window.innerWidth <= 600 ? "2vw" : "1.3vw",
                borderTop: "1px solid #aaa",
              }}
            >
              <a
                style={{
                  cursor: "pointer",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={(e) => handleEditar(row._id, i, row.historic)}
              >
                Enviar
              </a>
            </div>
          )} */}
          <div
            style={{
              height: "100%",
              color: "#00AEC8",
              fontSize: window.innerWidth <= 600 ? "2vw" : "1.3vw",
              borderTop: "1px solid #aaa",
            }}
          >
            <a
              style={{
                cursor: "pointer",
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={async (e) => {
                try {
                  // if(row.status === 'Cancelado' || row.status == 'Cancelada (Corretor)') return alert('Proposta já cancelada!')
                  if (String(row.status).includes("Cancelad")) {
                    if (!window.confirm("Deseja ativar a proposta?")) throw "";

                    await api.put(`/proposalImovel/${row._id}`, {
                      status: "Proposta Ativa",
                    });
                  } else {
                    if (!window.confirm("Deseja cancelar a proposta?"))
                      throw "";

                    await api.put(`/proposalImovel/${row._id}`, {
                      status: "Cancelada (Corretor)",
                    });
                  }

                  handleLoad();
                } catch (err) { }
              }}
            >
              {String(row.status).includes("Cancelad") ? "Ativar" : "Cancelar"}
            </a>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <Aside />
      <Container>
        <div className="bread-crumb">
          <h1 className="category">IMÓVEIS À VENDA ></h1>
          <Link to={window.location.pathname} className="where">
            Propostas de Compra Enviadas
          </Link>
        </div>

        <div className="header" style={{ marginTop: 30 }}>
          <div className="cod">
            <h2>CÓD.</h2>
          </div>

          <div className="active">
            <h2>IMÓVEL</h2>
          </div>
        </div>

        {data[0] && data[0].Property && (
          <div
            className="body"
            style={{ height: window.innerWidth <= 600 ? 200 : 150 }}
          >
            <div className="body-cod">{data[0].Property._id}</div>
            <div
              className="body-imovel"
              style={{
                width: "93%",
                alignItems: "start",
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <small>
                Cadastrado em:{" "}
                {String(data[0].Property.createdAt.split(" ")[0])}
              </small>
              <h2 style={{ fontSize: 18, marginTop: 20 }}>
                <a style={{ color: "inherit" }}>
                  {data[0].Property.address ? data[0].Property.address : ""}
                  {data[0].Property.addressNumber
                    ? ", " + data[0].Property.addressNumber
                    : ""}{" "}
                  {data[0].Property.addressComplement
                    ? ` / ${data[0].Property.addressComplement}`
                    : ""}
                </a>
              </h2>
              <h3>
                {data[0].Property.neighborhood
                  ? data[0].Property.neighborhood.name
                  : ""}
                {data[0].Property.city
                  ? " - " + data[0].Property.city.name
                  : ""}
                {data[0].Property.neighborhood
                  ? ", " + data[0].Property.neighborhood.state
                  : ""}
              </h3>

              <h5>
                {data[0].Property.type ? data[0].Property.type.name : ""}{" "}
                {data[0].Property.generalType
                  ? data[0].Property.generalType.name
                  : data[0].Property.type
                    ? data[0].Property.type.generalType == "2372820374682508"
                      ? "Residencial"
                      : "Comercial"
                    : ""}
              </h5>
            </div>
          </div>
        )}

        {data.map(
          (row, index) =>
            row.Property &&
            row.User && (
              <div
                className="body"
                style={{ height: window.innerWidth <= 600 ? 350 : 200 }}
              >
                <div className="body-cod">{row._id}</div>
                {window.innerWidth <= 600 ? (
                  <div
                    style={{
                      overflow: "auto",
                      display: "-webkit-inline-box",
                      width: "82%",
                    }}
                  >
                    {innerRow(row, index)}
                  </div>
                ) : (
                  innerRow(row, index)
                )}
              </div>
            )
        )}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: 40,
          }}
        >
          <button
            onClick={() => (window.location.href = "/admin/propostas-corretor")}
            style={{
              color: "#C13818",
              background: "white",
              border: "2px solid #EA5C1E",
              borderRadius: 5,
              width: window.innerWidth <= 600 ? "100%" : 250,
              height: 45,
            }}
          >
            Voltar
          </button>
        </div>
      </Container>
    </>
  );
}
