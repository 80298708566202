import React from "react";
import * as S from "./styles";
import "../../../../global.css";
import ListZap from '../../../../assets/ListZap.svg'

export default function PrepareAds() {
  
  return (
    <>
      <S.Container>
        <S.Content>
          <S.ContentTitle>
           <span className="title">Preparar anúncio</span>
           <span className="subTitle">Você irá preparar este imóvel para anúncios dentro da nossa plataforma. 
            Acesse pela sua área administrativa em <strong>Gerenciamento de Anúncio.</strong></span>
            <S.ContentOptionFirst>
              <S.Position>1</S.Position>
              <span className="titleOption"><strong>Gerencie</strong> todos os anúncios em um único lugar</span>
            </S.ContentOptionFirst>
            <S.ContentOptionLast>
              <S.Position>2</S.Position>
              <span className="titleOption"><strong>Anuncie</strong> diretamente na plataforma Zap imóveis</span>
            </S.ContentOptionLast>
          </S.ContentTitle>
        </S.Content>
        <img src={ListZap} alt="List Zap" />
      </S.Container>
    </>
  );
}
