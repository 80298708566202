import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, notification, Upload } from 'antd';
import { Container, ErrorMessage, Filename } from './styles';
import api from '../../api';
import path from 'path';
const emptyHandler = (value) => console.warn('UploadButton', value);

const props = {
  handler: emptyHandler,
  files: [],
  multiple: false,
  required: false,
  title: '',
  uploadUrl: '',
  downloadUrl: '',
  method: 'post',
};

const UploadButton = ({
  handler,
  files,
  multiple,
  required,
  title,
  uploadUrl,
  downloadUrl,
  method = 'post',
} = props) => {
  const [fileList, setFileList] = useState(files.filter(({ link }) => link).map(linkMap));
  const [uploading, setUploading] = useState(false);
  function linkMap({ link, ...rest }) {
    const limit = 50;
    let name = path.basename(link);
    if (name.indexOf('___-___') >= 0) {
      name = name.split('___-___')[1];
    }
    if (name.length > limit) {
      name = `...${name.substring(name.length - limit)}`;

    }

    const url = encodeURI(`${downloadUrl}/${link}`);
    return { ...rest, name, status: 'done', url };
  }

  const handleUpload = (file) => {

    const formData = new FormData();
    formData.append('file', file);
    setUploading(true);
    api[method](uploadUrl, formData)
      .then(({ data }) => {
        handler(data);
        notification.success({ message: `${title} enviado com sucesso!` });
        if (!multiple) {
          setFileList([linkMap({ link: data.key })]);
        }
      })
      .catch(() => {
        setFileList(fileList.filter(({ uid }) => uid !== file.uid));
        notification.error({ message: `Falha ao tentar enviar ${title}!` });
      })
      .finally(() => {
        setUploading(false);
      });

  };

  const handleRemove = (file) => {

    setUploading(true);
    api.delete(uploadUrl)
      .then(({ data }) => {
        handler(data);
        notification.success({ message: `${title} removido com sucesso!` });

        const index = fileList.indexOf(file);
        if (index >= 0) {
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        }

      })
      .catch(() => {
        notification.error({ message: `Falha ao tentar remover ${title}!` });
      })
      .finally(() => {
        setUploading(false);
      });

  };

  const uploadProps = {
    onRemove: (file) => {
      handleRemove(file);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      handleUpload(file);
      return false;
    },
    fileList,
  };
  return (
    <Container>
      <Upload listType='text' {...uploadProps} multiple={multiple} >
        <Button loading={uploading} icon={<UploadOutlined />}>{title}</Button>
        {required && !files.length && <ErrorMessage>Upload obrigatório!</ErrorMessage>}
      </Upload>
    </Container>
  );
};
export default UploadButton;