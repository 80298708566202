import React from "react";
import * as S from "./styles";
import "../../../global.css";
import FormImageZap from '../../../assets/formImageZap.svg'
import ArrowLeft from '../../../assets/ArrowLeft.svg';
import { InfoOutlined } from '@ant-design/icons';

export default function CreateZapAccount({ setNextViewModalBinding }) {

  return (
    <>
      <S.Container>
        <S.Content>
          <S.ContentButtonBack>
            <div className="content-back" onClick={() => setNextViewModalBinding('start')}>
              <img src={ArrowLeft} alt="Arrow Left" />
              <span className="title-back">Voltar</span>
            </div>
          </S.ContentButtonBack>
          <S.ContentTitle>
            <span className="title">Crie sua conta</span>
            <span className="subTitle">
              Entre no site: <br />
              <span className="link" onClick={() => window.open('https://anuncie.zapimoveis.com.br/')}>https://anuncie.zapimoveis.com.br/</span><br />
              siga os passos para criar uma conta. <br />
              Certifique-se de ter a assinatura do plano clássico ativada
            </span>
          </S.ContentTitle>
          <S.ContentInfo>
            <div className="info-circle">
              <InfoOutlined className="info" />
            </div>
            <span className="title-info">Certifique-se de possuir uma assinatura e uma conta ativa.</span>
          </S.ContentInfo>
        </S.Content>
        <S.ImageCreateAccountZap src={FormImageZap} alt="Image Create Account Zap" />
      </S.Container>
    </>
  );
}
