import React from "react";
import { Bar, TitleServices, CardServices, CardGroupService, Topic, TopicLine, Btn, BtnPartnerBroker, BtnSeeProperties } from "./style";
import { Link } from "react-router-dom";
import { PlayCircleOutlined, ArrowRightOutlined, MenuOutlined } from '@ant-design/icons';
import Group from '../../../assets/LP_MG/ServicesLP/Group.svg'
import Group_2 from '../../../assets/LP_MG/ServicesLP/Group_2.svg'
import Group_3 from '../../../assets/LP_MG/ServicesLP/Group_3.svg'
import Line from '../../../assets/LP_MG/ServicesLP/Line.svg'
import "../../../global.css";

export default function ServicesLP({ user, city }) {

  return (
    <>
     <Bar>
        <TitleServices>
            E por que <span>somos seu investidor</span> número 1?
        </TitleServices>
        <CardServices>
          <CardGroupService>
            <img src={Group}></img>
            <div className="first-service-text">
            <span>Porque se comprarmos seu imóvel <strong>degradado</strong> e com 
            <strong> dificuldade de liquidez</strong> ou por <strong>problemas documentais</strong></span>
            </div>
          </CardGroupService>
          <TopicLine>
          <Topic src={Line}></Topic>
          </TopicLine>
          <CardGroupService>
            <img className="last-service-image" src={Group_2}></img>
            <div className="last-service-text">
            <span> Você receberá um <strong>bônus de R$ 6.000 </strong>
            pela indicação</span>
            </div>
          </CardGroupService>
          <TopicLine>
          <Topic src={Line}></Topic>
          </TopicLine>
          <CardGroupService>
            <img className="last-service-image" src={Group_3}></img>
            <div className="last-service-text">
            <span>Além do <strong>comissionamento padrão de 6%</strong>
            , já incluída nos valores listados</span>
            </div>
          </CardGroupService>
        </CardServices>
        <Btn>
        {user !== undefined ?
            <BtnSeeProperties>
              <Link to={`/imoveis?city_id=${city}`}>
                <span className="title-button">
                  Conferir Imóveis
                </span>
                <ArrowRightOutlined className="icon-arrow" />
              </Link>
            </BtnSeeProperties> 
               :
            <BtnPartnerBroker>
              <Link to="/register?origin=chegamos_em_bh" className="linkButton">
                <span className="title-button">Quero Ser Corretor Parceiro</span>
                <ArrowRightOutlined className="icon-arrow" />
              </Link>
            </BtnPartnerBroker>
        }
        </Btn>   
     </Bar>
    </>
  );
}
