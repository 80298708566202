import React, { useEffect, useState, useCallback } from "react";
import "../../global.css";
import api from "../../api";
import { CheckBox } from "./styles";
import { ActionsBox } from "./styles";
import "./styles.css";
import Autocomplete from "../../components/autosuggest";
import { NOTIFICATION_TYPE, openNotification } from "../../utils/notification";

// Components
import DefaultForm from "./stepTwo/Default";
import HomeForm from "./stepTwo/Home";
import RoofForm from "./stepTwo/Roof";

import { Andar, Posicao } from "../offerSolicitation/utils";

export default function StepOne(props) {
  const [form, setForm] = useState(false);
  const {
    setStep,
    setNIPTU,
    nIPTU, // Desativado
    metragem,
    setMetragem,
    quartos,
    setQuartos,
    suites,
    setSuites,
    dependencias,
    setDependencias,
    vagas,
    setVagas,
    condominio,
    setCondominio,
    // novos campos
    posicao,
    setPosicao,
    andar,
    setAndar,
    banheiros,
    setBanheiros,
    escritura,
    setEscritura,
    tipo,
    areaTerreno,
    setAreaTerreno,
    areaConstruida,
    setAreaConstruida,
    areaIptu,
    setAreaIptu,
    constaIptu,
    setConstaIptu,
  } = props;

  console.log({ tipo });
  const optionalRequired = async () => {
    const optionalTypeNames = ['Terreno Padrão', 'Prédio', 'Prédio Inteiro'];
    const { data: x } = await api.get(`/propertyType/${tipo}`);
    return optionalTypeNames.includes(x.name);
  };

  const handleNext = async () => {
    var isOptional = await optionalRequired();
    let inputs = document.querySelectorAll("[required]");
    let success = true;

    const optionalInputNames = ['quartos', 'suites', 'banheiros', 'dependencias', 'vagas',];
    Array.from(inputs).map((input) => {
      const isRequired = !(isOptional && optionalInputNames.includes(input.id));
      if (input.querySelector("input") && isRequired) {
        if (!input.querySelector("input").value || input.querySelector("input").value === "R$ 0,00") {
          styleBorderError(input);
          success = false;
        }
      }

      if (document.getElementById("metragem")) {
        if (input.querySelector("input").value === '0') {
          styleBorderError(input);
          success = false;
        }
      }
      if (input.querySelector("select")) {
        if (input.querySelector("select").value === "") {
          styleBorderError(input);
          success = false;
        }
      }
    });

    success ? setStep(3) :
      openNotification("Verifique os campos informados!", NOTIFICATION_TYPE.warning);
  };

  function styleBorderError(object) {
    object.style.border = "2px solid red";
    setTimeout(() => {
      object.style.border = "none";
    }, 7000);
  }

  function money(value) {
    value = String(value);
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(+value.replace(/\D+/g, "") / 100);
  }

  // function moneyToNumber(value) {
  //     value = value.replaceAll('R', '').replaceAll('$', '').replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '')
  //     value = value.substr(1, value.length - 3) + '.' + value.substr(value.length - 2, 2)
  //     return value
  // }

  function moneyToNumber(value) {
    let val = value + "";
    val = val
      .replaceAll("R", "")
      .replaceAll("$", "")
      .replaceAll(" ", "")
      .replaceAll(".", "")
      .replaceAll(",", "");
    val = val.substr(1, val.length - 3) + "." + val.substr(val.length - 2, 2);
    return val;
  }

  const verifyPropertyTip = useCallback(() => {
    if (tipo == 25 || tipo == 29 || tipo == 30 || tipo == 39) {
      return "house";
    } else if (tipo == 26) {
      return "roof";
    } else {
      return false;
    }
  }, [tipo]);

  useEffect(() => {
    setForm(verifyPropertyTip());
  }, [verifyPropertyTip]);

  return (
    <>
      {form === "roof" && (
        <RoofForm
          money={money}
          moneyToNumber={moneyToNumber}
          areaIptu={areaIptu}
          setAreaIptu={setAreaIptu}
          areaConstruida={areaConstruida}
          setAreaConstruida={setAreaConstruida}
          constaIptu={constaIptu}
          setConstaIptu={setConstaIptu}
          vagas={vagas}
          setVagas={setVagas}
          quartos={quartos}
          setQuartos={setQuartos}
          condominio={condominio}
          setCondominio={setCondominio}
          suites={suites}
          setSuites={setSuites}
          banheiros={banheiros}
          setBanheiros={setBanheiros}
          dependencias={dependencias}
          setDependencias={setDependencias}
          setEscritura={setEscritura}
          escritura={escritura}
        />
      )}

      {form === "house" && (
        <HomeForm
          areaIptu={areaIptu}
          setAreaIptu={setAreaIptu}
          areaTerreno={areaTerreno}
          setAreaTerreno={setAreaTerreno}
          areaConstruida={areaConstruida}
          setAreaConstruida={setAreaConstruida}
          vagas={vagas}
          setVagas={setVagas}
          quartos={quartos}
          setQuartos={setQuartos}
          suites={suites}
          setSuites={setSuites}
          banheiros={banheiros}
          setBanheiros={setBanheiros}
          dependencias={dependencias}
          setDependencias={setDependencias}
          setEscritura={setEscritura}
          escritura={escritura}
        />
      )}

      {!form && (
        <DefaultForm
          money={money}
          setMetragem={setMetragem}
          metragem={metragem}
          condominio={condominio}
          setCondominio={setCondominio}
          setPosicao={setPosicao}
          posicao={posicao}
          andar={andar}
          setAndar={setAndar}
          setQuartos={setQuartos}
          quartos={quartos}
          setSuites={setSuites}
          suites={suites}
          setBanheiros={setBanheiros}
          banheiros={banheiros}
          dependencias={dependencias}
          setDependencias={setDependencias}
          vagas={vagas}
          setVagas={setVagas}
          setEscritura={setEscritura}
          escritura={escritura}
          moneyToNumber={moneyToNumber}
        />
      )}

      {/* <div className="input-box">
                        <small>Número do IPTU</small>
                        <InputMask onKeyDown={e => e.key == '-' ? e.preventDefault() : ''} onChange={text => setNIPTU(text.target.value)} value={nIPTU} />
                    </div> */}

      <ActionsBox
        className="actions-box"
      >
        <button
          className="btBack"
          onClick={(click) => setStep(1)}
        >
          Voltar
        </button>
        <button
          onClick={async () => await handleNext()}
        >
          Avançar
        </button>
      </ActionsBox>
    </>
  );
}
