export const SECTION_TYPE = {
  title: 'title',
  image: 'image',
  topicTitle: 'topicInline',
  topicDescription: 'topicDescriptive',
};

const props = {
  invalid: false, isDirty: false, isTouched: false, isSubmitted: false,
  error: { message: undefined, type: undefined, ref: null, types: undefined, },
}

export const checkErrorStyle = ({ invalid, isDirty, isTouched, isSubmitted, error } = props) => {
  const getErrorByType = (type = '') => {

    switch (type) {
      case 'required':
        return 'Obrigatório';
      case 'maxLength':
        return 'Máximo de caracteres excedido';
      case 'minLength':
        return 'Mínimo de caracteres não atingido';
      case 'max':
        return 'Valor excedido';
      case 'min':
        return 'Valor insuficiente';
      default:
        return '*Campo inválido';
    };
  };

  const
    pristine = !isDirty && !isTouched && !isSubmitted,
    isValid = !invalid,
    errorMessage = error?.message || getErrorByType(error?.type || error?.types[0]);

  return {
    pristine, isValid, errorMessage
  };
};