import styled from 'styled-components'
import BgImage from '../../assets/home-background.png'


export const Main = styled.div`
    position: relative;
    font-family: 'Roboto';
`

export const BgImg = styled.div`
    width:100%;
    background : #0098AE;
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
    position: relative;
`

export const InitalTitle = styled.div`
    font-family: 'Roboto';
    font-size: 62px;
    font-weight: 300;
    line-height: 74px;
    text-align: left;
    color: #FFFFFF;

    @media (max-width: 768px) { 
     text-align: center;
     font-size: 52px;
  }
`

export const ContainerParagraph = styled.div`
    @media (max-width: 768px) { 
     display: flex;
     justify-content: center;

     p {
        text-align: center;
     }
  }
`

export const InitialLP = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 110px;
  padding-bottom: 250px;

  @media (max-width: 768px) { 
       display: flex;
       flex-direction: column;
    }
`

export const IntialTextLP = styled.div`
    width: 50%;

  .title {
    font-family: 'Roboto';
    font-size: 62px;
    font-weight: 400;
    line-height: 74px;
    text-align: left;
    color: #FFFFFF;
  }

  .titleStrong {
    font-family: 'Roboto';
    font-size: 62px;
    font-weight: 700;
    line-height: 74px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF
  }

  p{
    width: 80%;
    font-family: 'Roboto';
    padding-top: 15px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
  }

  @media (max-width: 768px) { 
    width: 90%;

    .title {
        font-size: 52px;
    }

    .titleStrong {
        font-size: 52px;
    }

    p {
        font-size: 16px;
        text-align: center;
    }
  }
`

export const ContainerVideo = styled.div`
   @media (max-width: 768px) {
    position: absolute;
    top: 85%;
    z-index: 999;
    display: flex;
    justify-content: center;
   }
`

export const InitialVideo = styled.img`
   border-radius: 8px 8px;
   border: none;
   width: 90%;
   z-index: 999;
   cursor: pointer;
   `

export const PartnerBroker = styled.div`
   padding-top: 10px;
`

export const BtnPartnerBroker = styled.div`
    display: inline-block;
    padding-top: 40px;
    a{
        text-decoration:none;
        color: #0088AC;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 15px;

        span {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        }

        .icon-arrow {
            position: relative;
            top: 2px;
            left: 8px;
        }

        &:hover, .linkButton {
            background-color: #006690;
            color: #fff;
        }

    }

    @media (max-width: 768px) {
       display: flex;
       justify-content: center;

       a {
        padding-left: 60px;
        padding-right: 60px
       }
    }
`

export const BtnSeeProperties = styled.div`
    display: inline-block;
    padding-top: 40px;

    a{
        text-decoration:none;
        color: #0088AC;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 15px;
        padding-left: 25px;
        padding-right: 25px;

        span {
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        }

        .icon-arrow {
            position: relative;
            top: 2px;
            left: 8px;
        }

        &:hover, .linkButton {
            background-color: #006690;
            color: #fff;
        }

    }

    @media (max-width: 768px) {
       display: flex;
       justify-content: center;

       a {
        padding-left: 60px;
        padding-right: 60px
       }
    }
`

export const ImgBuildings = styled.img`
  width: 100%;
  opacity: 0.2;
  position: absolute;
  bottom: 0;

  @media (max-width: 768px) {
    bottom: 110px;
  }
`;

export const BtnContainer = styled.div`
    width:100vw;
    height:10vh;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top: -50px;

    a{
        text-decoration:none;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #EA5C1E;
        border: 1px solid #EA5C1E;
        box-sizing: border-box;
        border-radius: 4px;
        padding:14px;
        padding-left:40px;
        padding-right:40px;
    }
`

