import React from "react";
import * as S from "./styles";
import "../../../../global.css";
// import * as Yup from 'yup';

export default function InformationView({ data }) {
  // const [newValueTitleAds, setNewValueTitleAds] = useState('');
  // const [messageError, setMessageError] = useState('Campo obrigatório!');
  // const [errorTitleAds, setErrorTitleAds] = useState(false);

  // const validationSchema = Yup.object({
  //   selecionarOpcao: Yup.string().min(10, 'Mínimo de 10 caracteres').max(100, 'Máximo de 100 caracteres').required('Campo obrigatório!'),
  // });

  return (
    <>
      <S.Container>
        <S.Title>Informações</S.Title>
        <S.SubTitle>Endereço</S.SubTitle>
        <form>
          <S.ContentField>
            <div className="content-cep">
              <S.LabelFieldDisabled>CEP</S.LabelFieldDisabled>
              <S.FieldDisabled type='text' value={data?.postalCode || ""} disabled />
            </div>
            <div className="content-cep">
              <S.LabelFieldDisabled>Endereço</S.LabelFieldDisabled>
              <S.FieldDisabled type='text' value={data?.address || ""} disabled />
            </div>
          </S.ContentField>
          <S.ContentField>
            <div className="content-cep">
              <S.LabelFieldDisabled>Bairro</S.LabelFieldDisabled>
              <S.FieldDisabled type='text' value={data?.neighborhoodRealName || ""} disabled />
            </div>
            <div className="content-cep">
              <S.LabelFieldDisabled>Estado</S.LabelFieldDisabled>
              <S.FieldDisabled type='text' value={data?.city.stateName || ""} disabled />
            </div>
          </S.ContentField>
          <S.ContentField>
            <div className="content-cep">
              <S.LabelFieldDisabled>Cidade</S.LabelFieldDisabled>
              <S.FieldDisabled type='text' value={data?.city.name || ""} disabled />
            </div>
            <div className="content-cep">
              <S.LabelFieldDisabled>Número</S.LabelFieldDisabled>
              <S.FieldDisabled type='text' value={data?.addressNumber || ""} disabled />
            </div>
          </S.ContentField>
          <S.ContentField>
            <div className="content-large">
              <S.LabelFieldDisabled>Tipo de imóvel</S.LabelFieldDisabled>
              <S.FieldDisabled type='text' value={data?.type.name || ""} disabled />
            </div>
            <div className="content-large">
              <S.LabelFieldDisabled>Complemento</S.LabelFieldDisabled>
              <S.FieldDisabled type='text' value={data?.addressComplement || ""} disabled />
            </div>
          </S.ContentField>
        </form>
      </S.Container>
    </>
  );
}
