import React from 'react';

const EditIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/trash">
        <g id="trash">
        <path id="Vector" d="M14 3.98663C11.78 3.76663 9.54667 3.6533 7.32 3.6533C6 3.6533 4.68 3.71996 3.36 3.8533L2 3.98663M5.6667 3.31337L5.81337 2.44004C5.92003 1.80671 6.00003 1.33337 7.1267 1.33337H8.87337C10 1.33337 10.0867 1.83337 10.1867 2.44671L10.3334 3.31337M12.5666 6.09334L12.1333 12.8067C12.06 13.8533 12 14.6667 10.14 14.6667H5.85997C3.99997 14.6667 3.93997 13.8533 3.86663 12.8067L3.4333 6.09334M6.88667 11H9.10667M6.33333 8.33331H9.66667" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        </g>
    </svg>
  );
};

export default EditIcon;
