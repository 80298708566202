import styled from "styled-components";

export const Container = styled.div`
  padding-top: 65px;
  height: 479px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  form {
    padding-right: 30px;
  }
`

export const Title = styled.span`
  color: #3B414A;
  font-family: 'Roboto';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 50px;
`

export const ContentCharacteristics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 45px;
`

export const TitleCharacteristics = styled.span`
  color: #1B1F27;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const ContentCharacteristicsValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
`

export const ContentCharacteristicsSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
`
export const Question = styled.span`
  max-width: 220px;
  color: #000;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const ValueQuestion = styled.span`
  color: #8C8C8C;
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const SubTitle = styled.span`
  color: #3B414A;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 30px;
`

export const ContentField = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
  padding-right: 30px;
  gap: 10px;
  margin-top: 40px;

  .content-middle {
    width: 50%;
  }

  .content-larger {
    width: 100%;
  }
`

export const ContentQuestion = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 40px;
  padding-bottom: 30px;
`

export const TitleQuestion = styled.span`
  color: #3B414A;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const TagValue = styled.span`
  border-radius: 100px;
  border: 1px solid #EA5C1D;
  background: #FEEDDB;
  padding: 10px;
  width: fit-content;

  color: #EA5C1D;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const ContentTextEditor = styled.div`
  padding-right: 30px;
  margin-bottom: 45px;

  .quill-editor {
    border: 1px solid transparent;
    transition: border-color 0.3s ease-in-out;
  }

  .quill-editor.erro {
    border-color: red;
  }

  .txt-error {
    color: red;
  }
`

export const LabelEditor = styled.span`
  color: #474C57;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const Label = styled.label`
  color: #B3B4BA;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 10px;
  margin-left: 0 !important;
`

export const Field = styled.input`
  display: flex;
  padding: 10px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  width: 100%;
`

export const ContainerTitleImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TitlePhoto = styled.span`
  color: #000;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 8px;
`

export const SubTitlePhoto = styled.span`
  color: #8C8C8C;
  font-family: 'Roboto';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const ContainerImage = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
  padding-right: 30px;
  margin-bottom: 45px; 
  padding-top: 25px;

  .content-image {
    position: relative;
  }

  .image-count {
    background: rgba(0, 0, 0, 0.65);
    filter: blur(6px);
    z-index: 1000;
  }

  .image-count-hidden {
    display: none;
  }
`

export const ContentCountImage = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1000;

    
    .title {
      color: #FFF;
      text-align: center;
      font-family: 'Roboto';
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      z-index: 1000;
    }

    .subtitle {
      color: #F0F1F4;
      text-align: center;
      /* Label/Label small */
      font-family: 'Roboto';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      z-index: 1000;
      line-height: normal;
    }
`