import React, {useState, useEffect} from 'react'
import Header from '../../components/header'
import { BgImg, FilterContainer} from './styles'
import Footer from '../../components/Footer'
import '../../global.css';
import { Link } from 'react-router-dom'
import api from '../../api'
import { notification } from "antd";

import StepOne from './stepOne'
import StepTwo from './stepTwo'
import Finish from './finish'
import Loading from '../../components/Loading';

export default function Register() {
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)
    const [nome, setNome] = useState('')
    const [cpf, setCpf] = useState('')
    const [email, setEmail] = useState('')
    const [celular, setCelular] = useState('')
    const [senha, setSenha] = useState('')
    const [senhaConfirm, setSenhaConfirm] = useState('')
    const [codigo, setCodigo] = useState('')

    async function handleRegister(){
        setLoading(true)
        const {data} = await api.post('/user/', {
            password: senha,
            email,
            telephone: [celular],
            name: nome,
            cpf
        })
        if(data.error) {
            notification.error({
                message: `${data.error}`,
              });
            
            setStep(1)
            return false
        }
        
        setStep(5)
        localStorage.setItem('id', data._id)
        setLoading(false)
        return true
    }

    return (
        <>
            <BgImg>
                {loading && <Loading />}
                <Header />
                <FilterContainer>
                    <div className="login-wrapper">
                        <div className="info-box">
                            <h1>Cadastre-se para ofertar o seu imóvel!</h1>
                            <h1></h1>

                            <div className="card-retangle">
                                <img src={require('./icons/dima.svg')}/>
                                Compramos imóveis que sejam oportunidades reais 
                            </div>
                            <div className="card-retangle">
                                <img src={require('./icons/porcent.svg')}/>
                                Faça a sua oferta se o preço do seu imóvel estiver abaixo do valor de mercado
                            </div>
                            <div className="card-retangle">
                                <img src={require('./icons/money.svg')}/>
                                Aceitamos imóveis com valor mínimo de R$250.000
                            </div>
                            <div className="card-retangle">
                                <img src={require('./icons/work.svg')}/>
                                Avaliamos imóveis com documentação irregular
                            </div>
                            <div className="card-retangle">
                                <img src={require('./icons/perfil.svg')}/>
                                O imóvel precisa de obra? Não tem problema
                            </div>
                            <div className="card-retangle">
                                <img src={require('./icons/hammer.svg')}/>
                                Vamos analisar a sua oferta!
                            </div>
                        </div>

                        <div className="register-box">
                            <h1>Cadastro de Proprietário</h1>
                            <h4>Em poucos passos você já poderá ofertar o imóvel</h4>

                            <div className="body-box">

                                <div className="steps-box">
                                    <div className="step-horizontal">
                                        <div className={step == 1 ? "step-circle" : "step-complete"}>1</div>
                                        Identificação
                                    </div>
                                    <div className="step-horizontal">
                                        <div className={step == 2 ? "step-circle" : step < 2 ? "step-disable" : "step-complete"}>2</div>
                                        Confirmação
                                    </div>
                                </div>

                                {step == 1 ? <StepOne nome={nome} setSenhaConfirm={setSenhaConfirm} senhaConfirm={senhaConfirm} setNome={setNome} cpf={cpf} setCpf={setCpf} senha={senha} setSenha={setSenha} celular={celular} setCelular={setCelular} email={email} setEmail={setEmail} setStep={setStep}/> : step == 2 ? <StepTwo codigo={codigo} handleRegister={handleRegister} setCodigo={setCodigo} setStep={setStep} celular={celular}/> : <Finish setStep={setStep}/>}
                                
                            </div>
                        </div>
                    </div>
                </FilterContainer>
            </BgImg>

            <Footer />

        </>
    )
}
