import React from "react";
import * as S from "./styles";
import "../../../global.css";
import SuccessImg from '../../../assets/success.svg'

export default function SuccessZap() {
  
  return (
    <>
      <S.Container>
          <img src={SuccessImg} alt="Img Success" />
          <S.Title>Tudo certo!</S.Title>
          <S.SubTitle>
            Sua vinculação está em análise e teremos confirmação em até 72 horas.
            Caso não tenha sucesso com a vinculação, <span className="support-link" onClick={() => window.open('http://wa.me/5521993643054')}>entre em contato nosso suporte.</span>        
          </S.SubTitle>
      </S.Container>
    </>
  );
}
