import jwtDecode from 'jwt-decode';
import { SESSION_NAMES } from '../../api';

export const setUserSession = (data) => {
  localStorage.setItem(SESSION_NAMES.userId, data._id);
  localStorage.setItem(SESSION_NAMES.idPainel, data._id);
  localStorage.setItem(SESSION_NAMES.userProfile, data.type);
  localStorage.setItem(SESSION_NAMES.userType, data.type);
  localStorage.setItem(SESSION_NAMES.userPermission, data.permission);
  localStorage.setItem(SESSION_NAMES.accessToken, data[':accessToken']);
  localStorage.setItem(SESSION_NAMES.refreshToken, data[':refresh-token']);
};

const USER_LOCATION_REL = 'user location';
export const setLocationSession = (data) => {
  const { token, clientId, _links } = data;
  localStorage.setItem(SESSION_NAMES.clientId, clientId);
  localStorage.setItem(SESSION_NAMES.userLocation, JSON.stringify(_links.find(link => link.rel === USER_LOCATION_REL)));
  return { token, clientId };
};

export const getRefreshToken = () => localStorage.getItem(SESSION_NAMES.refreshToken);

export const getUserSession = () => {
  const accessToken = localStorage.getItem(SESSION_NAMES.accessToken);
  return (accessToken) ? jwtDecode(accessToken) : undefined;
}

export const getCityOfUserActing = () => {
  const user = getUserSession();
  return user?.location?.cities[0];
}

export const getUserProfile = () => {
  const user = getUserSession();
  if (user) {
    const { type, permission } = user;
    return { type, permission };
  }
  return undefined;
}

export const getUserId = () => {
  const user = getUserSession();
  if (user) {
    const { sub } = user;
    return sub ? Number(sub) : undefined;
  }
  return undefined;
}