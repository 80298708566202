import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import * as S from "./styles";
import "../../global.css";
import CloseCircle from '../../assets/close-circle.svg'
import { getCityOfUserActing, } from "../../utils/sessionHelper";
import { getCitiesAsync } from "../../services/city.service";
import { useForm } from "react-hook-form";
import FormContext from './_context';
import { NOTIFICATION_TYPE, openNotification } from "../../utils/notification";

export default function ModalZap({ open, closeModal, adData = undefined, propertyData, children, nextModal, advertisementTutorial, viewModalPrepare, advertisementRemember, activeKey, features = [], }) {

  const [checkboxMarked, setCheckboxMarked] = useState(advertisementRemember);
  const history = useHistory();

  const goToOtherProperties = () => {
    //window.location.href = '/imoveis';
    const cityId = getCityOfUserActing();

    getCitiesAsync({ orderBy: 'city', direction: 'ASC' })
      .then(({ data: cities }) => {
        if (cities?.some(c => c.value === cityId)) {
          window.location.href = `/imoveis?city_id=${cityId}`;
        }
        else {
          window.location.href = '/imoveis';
        }
      })
      .catch(() => window.location.href = '/imoveis');

  };

  function selectRemindAgain() {
    setCheckboxMarked(!checkboxMarked);
    advertisementTutorial(!checkboxMarked);
  }

  const defaultValues = {
    commercialDistrict: propertyData.neighborhoodName,
  };

  if (propertyData.features.length > 0) {
    const propertyFeatures = [];
    propertyData.features.forEach((x) => {
      const feature = features.find(f => f.displayName === x.name);
      if (feature)
        propertyFeatures.push(feature.displayName);
    });

    if (propertyFeatures.length > 0)
      defaultValues.propertyFeatures = propertyFeatures;
  }

  const { handleSubmit, ...form } = useForm({
    defaultValues,
    values: adData
  });

  const onSubmitError = (errors) => {
    openNotification(Object.keys(errors).map(key => errors[key].message).join('\r\n'), NOTIFICATION_TYPE.warning);
  }

  return (
    <FormContext.Provider value={{ form }}>
      <S.ModalContainer
        title={
          <S.ContainerTitle>
            <S.Title>Preparar</S.Title>
            <img src={CloseCircle} onClick={() => closeModal()} alt="Close Circle" />
          </S.ContainerTitle>
        }
        centered
        closeIcon={false}
        open={open}
        width={1200}
        footer={[
          <S.ContainerFooter key="ContainerFooter">
            {viewModalPrepare === 'initial' &&
              <S.DontRememberAgain>
                <input
                  type="checkbox"
                  className="checkboxRemember"
                  id="checkboxRemember"
                  name="checkboxRemember"
                  checked={checkboxMarked}
                  onChange={selectRemindAgain} />
                <label className="titleRemember" htmlFor="checkboxRemember">Não me lembrar novamente</label>
              </S.DontRememberAgain>
            }
            {(viewModalPrepare === 'success') ?
              <>
                <S.BtnOutlined onClick={() => goToOtherProperties()}>Buscar outros imóveis</S.BtnOutlined>
                <S.NextButton onClick={() => adData ? closeModal(true) : history.push('/admin/anuncios-zap/list')}>{adData ? 'Voltar para a listagem' : 'Ir para o Gerenciamento'}</S.NextButton>
              </>
              : (activeKey === 'AdDescriptionFull'
                ? <S.NextButton onClick={handleSubmit((val) => nextModal(val), (erros) => onSubmitError(erros))}>Salvar</S.NextButton>
                : <S.NextButton onClick={nextModal}>Próximo</S.NextButton>
              )
            }
          </S.ContainerFooter>
        ]
        }
      >
        <S.Background>
          {children}
        </S.Background>
      </S.ModalContainer >
    </FormContext.Provider >
  );
}
