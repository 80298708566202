import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Container } from "./styles";
import Header from "../../adminHeader";
import Aside from "../../adminSideBar";
import { Table, notification } from "antd";
import api from "../../../api";
import Single from "../Single";


// import "antd/lib/table/style/index.css";
// import "antd/lib/notification/style/index.css";
// import "antd/lib/pagination/style/index.css";
// import "antd/lib/spin/style/index.css";

export default function () {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [singleIsOpen, setSingleIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const fetchData = useCallback(() => {
    setLoading(true);
    api
      .get(`/logs?page=${page}&limit=100`)
      .then((res) => {
        setData(res.data.logs);
        setTotal(res.data.total);
      })
      .catch((err) => {
        notification.error({
          message: "Alguma coisa deu errada ao recuperar os logs",
        });
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => fetchData(), [fetchData, page]);

  return (
    <>
      <Header />
      <Aside />
      <Single
        selected={selected}
        isOpen={singleIsOpen}
        setIsOpen={setSingleIsOpen}
      />
      <Container>
        <div className="bread-crumb">
          <h1 className="category">Monitoramento ></h1>
          <Link to={window.location.pathname} className="where">
            Logs
          </Link>
        </div>
        <Table
          loading={loading}
          size="small"
          columns={[
            {
              key: "_id",
              title: "ID",
              dataIndex: "_id",
            },
            {
              key: "createdAt",
              title: "Data",
              dataIndex: "createdAt",
              render: (text) =>
                moment(text, "YYYY-MM-DD[T]HH:mm:ss")
                  .subtract(3, "hours")
                  .format("DD/MM/YYYY HH:mm:ss"),
            },
            {
              key: "requestMethod",
              title: "Método",
              dataIndex: "requestMethod",
            },
            {
              key: "route",
              title: "Rotas",
              dataIndex: "route",
            },
            {
              key: "responseCode",
              title: "Resposta",
              dataIndex: "responseCode",
            },
          ]}
          onRow={(record) => ({
            onClick: () => {
              setSelected(record);
              setSingleIsOpen(true);
            },
          })}
          dataSource={data}
          rowKey="_id"
          pagination={{
            current: page,
            hideOnSinglePage: true,
            showSizeChanger: false,
            showQuickJumper: false,            
            total,
            pageSize: 100,
            onChange: (page) => setPage(page),
          }}
        />
      </Container>
    </>
  );
}
