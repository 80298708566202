import styled from "styled-components";

export const ContainerFilter = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
  pointer-events: all;
  overflow: hidden !important;
  transition: all 0.3s;
`;

export const ContentFilter = styled.div`
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 425px;
  height: 100%;
  transition: all 0.5s ease;
  overflow-y: hidden;
`;

export const ContentFilterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  height: 8%;
  border-bottom: 1px solid #ddd;
  flex-direction: row;
  align-items: center;

  .title-filter {
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #3b414a;
  }

  .icon-close {
    width: 28px;
    height: 28px;
    cursor: pointer;
    color: #3b414a;
    margin-top: 5px;
  }
`;

export const ContentFilterBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 84%;
  border-bottom: 1px solid #ddd;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  height: 8%;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

export const CleanFiltersButton = styled.button`
  display: flex;
  height: 60%;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none !important;
  padding: 16px 20px;
  margin-right: 5px;
  border-radius: 6px;

  .clean-filters-button--title {
    color: #0088ac;
    text-align: center;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const FilterButton = styled.button`
  display: flex;
  height: 60%;
  justify-content: center;
  align-items: center;
  background-color: #0088ac !important;
  border-radius: 6px;
  border: none;
  padding: 16px 20px;
  margin-left: 5px;

  .filters-button--title {
    color: #ffffff;
    text-align: center;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
