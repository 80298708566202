
import React from 'react';
import { ValidationMessage, ValidationStyle, } from './styles';
const FieldErrorValidation = ({ pristine = true, isValid = true, errorMessage = 'Obrigatório', className, children, ...rest }) => {

  return <ValidationStyle {...{ ...rest, pristine, isValid, className }}>
    {children}
    {(!isValid && !pristine) && <ValidationMessage>
      {errorMessage}
    </ValidationMessage>}
  </ValidationStyle>
}

export default FieldErrorValidation;