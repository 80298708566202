import React, {useEffect, useState} from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import api from '../../api'
import { Link } from 'react-router-dom'
import { Popconfirm } from "antd";
import Loading from '../../components/Loading'


export default function CorrespondenteCorretor() {
    const [data, setData] = useState([])
    const [status, setStatus] = useState('false')
    const [loading, setLoading] = useState(false)
    const [FGTS, setFGTS] = useState('')
    const [regime, setRegime] = useState('')
    const [imovelProprio, setImovelProprio] = useState('')

    const CONFIRM_CONTEXT = {
        default: '',
        home: 'Home',
        catalog: 'Catalog',
        delete: 'Excluir'
      };

    const [confirmLoading, setConfirmLoading] = useState(false);
    const defaultOpenConfirm = {state: false, id: 0, context: CONFIRM_CONTEXT.default};
    const [openConfirm, setOpenConfirm] = useState(defaultOpenConfirm);
    const isOpen = (id, context) => {
    return openConfirm.state && openConfirm.context === context && openConfirm.id === id;
    }
    const handlerOpenConfirm = (state= defaultOpenConfirm.state, id=defaultOpenConfirm.id, context =defaultOpenConfirm.context) => {
    setOpenConfirm({
      state,
      id,
      context,
    })
    }

   

    const onConfirm = async (callback)=> {
        try {
          setConfirmLoading(true);
          await callback();
        } finally{
          setConfirmLoading(false);
          handlerOpenConfirm(false);
        }
      };


    useEffect(() => {
        if(!localStorage.getItem('type_user')){
            window.location.href='/login'
        }

        handleLoad()
    }, [])
    

    function cleanStates(){
        setImovelProprio('')
        setFGTS('')
        setRegime('')
        setStatus('')
    }

    async function handleLoad(){
        setLoading(true);
        cleanStates()
        const response = await api.get(`/banking?limit=999&user=${localStorage.getItem('id_painel')}`)
        let array = []

        response.data.Banking.map(e=>{
            e.financiamento=String(e.financiamento).includes('.')? e.financiamento:e.financiamento+'00'
            e.valorCompra=String(e.valorCompra).includes('.')? e.valorCompra:e.valorCompra+'00'
            e.rendaBruta=String(e.rendaBruta).includes('.')? e.rendaBruta:e.rendaBruta+'00'
            array.push(e)
        })
        
        array.sort((a, b) => {
            if(a.status == 'Novo'){
                return -1
            }
        })

        setData(array)
        
        setLoading(false);
    }

    async function handleApply(){
        setLoading(true);
        let querys = `?`

        if(FGTS){
            querys += `FGTS=${FGTS}&`
        }

        if(imovelProprio){
            querys += `imovelProprio=${imovelProprio}&`
        }

        if(regime){
            querys += `contratacao=${regime}&`
        }

        if(status){
            querys += `status=${status}&`
        }

        if(querys == '?'){
            return
        }
        let {data} = await api.get(`/banking${querys}&limit=10000&user=${localStorage.getItem('id_painel')}`)
        
        setData(data)

        setLoading(false);
    }

    function removeMoney(value) {
        value = value.replaceAll('R', '').replaceAll('$', '').replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '')
        
        return value.substr(1, value.length-3)+'.'+value.substr(value.length-2, 2)
    }

    function money(value) {
        value = String(value)
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value.replace(/\D+/g, '') / 100)
    }
    
    async function handleDelete(id){
        setLoading(true);
            let {data} = await api.delete('/banking/'+id)
            if(data.deletedId){
                window.location.reload()
            }
        setLoading(false);   
    }

    function innerRow(row, i){
        return <>

            <div className="body-imovel">
            <div className="opt-left">
                <a>
                    <img src={require(`./icons/${row.FGTS?'success.svg':'error.svg'}`)} />
                    Uso do FGTS
                </a>
                <a>
                    <img src={require(`./icons/${row.imovelProprio?'success.svg':'error.svg'}`)} />
                    Imóvel próprio
                </a>
                <a>Nasc.: {row.dateBorn}</a>
            </div>
            <div className="opt-right">
                <a>{row.contratacao}</a>
                <a>Renda:  {money(row.rendaBruta)}</a>
            </div>
        </div>
        <div className="body-engajamento">
            <div>
                <a>Compra/Venda: {money(row.valorCompra)}</a>
            </div>
            <div>
                <a>Financiamento : {money(row.financiamento)}</a>
            </div>
        </div>
        <div className="body-stats">
            <div style={{flexDirection:'column'}}>
                <a style={{textAlign: 'center', cursor: 'default', fontSize: '12px'}}>{`Cadastro:`}</a>
                <a style={{textAlign: 'center', marginTop: 8, cursor: 'default', fontSize: '12px'}}>{`${row.createdAt}`}</a>
            </div>
            <div style={{borderTop: '1px solid #aaa'}}>
                <a style={{cursor: 'default', color: '#EB5C1C'}}>{row.status? row.status:'Novo'}</a>
            </div>
        </div>
        <div className="body-actions">
            <div>
                <a onClick={e => window.location.href=`/admin/correspondente/negotiate?id=${row._id}`}>Negociar</a>
            </div>
            <Popconfirm
            placement="top"
            title={`Imóvel ${row._id}`}
            description='Deseja excluir essa propriedade?'
            onConfirm={()=> onConfirm(async() => await handleDelete(row._id))}
            open={isOpen(row._id, CONFIRM_CONTEXT.delete)}
            okButtonProps={{
              loading: confirmLoading,
            }}
            onCancel={()=>handlerOpenConfirm()}
            okText="Sim"
            cancelText="Não"
          >
            <div>
                <a onClick={e=>handleDelete(row._id)}>Excluir</a>
            </div>
            </Popconfirm>
        </div>
        </>
    }

    return (
        <>
        {loading && <Loading />}
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        CORRESPONDENTE BANCÁRIO > 
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Minhas Solicitações
                    </Link>
                </div>
                <div className="filter">
                    <div className="input-group">
                        <label htmlFor="">Uso do FGTS</label>
                        <select value={FGTS} onChange={e => setFGTS(e.target.value)}>
                            <option></option>
                            <option value={true}>Sim</option>
                            <option value={false}>Não</option>
                        </select>
                    </div>
                    <div className="input-group">
                        
                        <label htmlFor="">Imóvel Próprio</label>
                         <select value={imovelProprio} onChange={e => setImovelProprio(e.target.value)}>
                            <option></option>
                            <option value={true}>Sim</option>
                            <option value={false}>Não</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Regime de Contratação</label>
                         <select value={regime} onChange={select => setRegime(select.target.value)}>
                            <option></option>
                            <option value={'CLT'}>CLT</option>
                            <option value={'Autônomo'}>Autônomo</option>
                            <option value={'Servidor Público'}>Servidor Público</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Status</label>
                        <select value={status} onChange={select => setStatus(select.target.value)}>
                            <option></option>
                            <option>Novo</option>
                            <option>Analisando</option>
                            <option>Concluído</option>
                            <option>Não concluído</option>
                            <option>Reprovado</option>
                            <option>Aprovado</option>
                        </select>
                    </div>
                    <div className="button-group">
                        <button onClick={handleLoad} className="outline">Remover filtros</button>
                        <button onClick={handleApply} className="full">Aplicar filtros</button>
                    </div>
                </div>

                <div className="header" style={{marginTop: 30}}>
                    <div className="cod">
                        <h2>SEQ.</h2>
                    </div>
                    {
                        window.innerWidth <= 600 ? 
                        <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                            <div style={{width: 250}} className="active">
                                <h2>COMPRADOR DO IMÓVEL</h2>
                            </div>
                            <div style={{width: 150}} className="imovel">
                                <h2>VALORES</h2>
                            </div>
                            <div style={{width: 140}} className="engajamento">
                                <h2>STATUS</h2>
                            </div>
                            <div style={{width: 150}} className="actions">
                                <h2>AÇÕES</h2>
                            </div>
                            
                        </div>
                    :
                    <>
                        <div className="active">
                            <h2>COMPRADOR DO IMÓVEL</h2>
                        </div>
                        <div className="imovel">
                            <h2>VALORES</h2>
                        </div>
                        <div className="stats">
                            <h2>STATUS</h2>
                        </div>
                        <div className="actions">
                            <h2>AÇÕES</h2>
                        </div>
                    </>
                    }
                </div>

                {
                    data.map(row => (
                        <div className="body">
                            <div className="body-cod">{row._id}</div>
                            {
                                window.innerWidth <= 600 ? 
                                    <div style={{overflow:'auto', display: '-webkit-inline-box', width: '82%'}}>
                                        {innerRow(row)}
                                    </div>    
                                :
                                innerRow(row)
                            }    
                        </div>
                    ))
                }

            </Container>
        </>
    )
}

