import { NonceProvider } from "react-select/dist/react-select.cjs.dev";
import styled from "styled-components";

export const Bar = styled.div`
   background-color: #fff;
`

export const TitlePlatform = styled.div`
    font-family: 'Roboto';
    font-size: 36px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 100px;
    width: 50%;
    margin: 0 auto;

      span {
        color: #0088AC;
      }

    @media (max-width: 1024px) and (min-width: 769px) {
        font-size: 30px;
        width: 70%;
    }  

    @media (max-width: 768px) {
        font-size: 26px;
        width: 90%;
    }
`

export const MainCards = styled.div`
    margin-top: 90px;
    margin: 0 auto;
`

export const CardsPlatform1 = styled.div`
    margin-top: 90px;
    margin-left: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        margin-left: 0;
    }
`

export const CardsPlatform2 = styled.div`
    margin-top: 70px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        margin-top: 0;
    }
`

export const CardPlatform = styled.div`
    text-align: center;

    div {
        margin-top: 10px;
    }

    span {
        font-family: 'Roboto';
        font-size: 20px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
    }

    @media (max-width: 1024px) and (min-width: 769px) {
        span {
            font-size: 18px
        }
    }  

    @media (max-width: 768px) {
        padding-top: 15px;
        padding-bottom: 25px;

        span {
            font-size: 16px
        }
    }
`
export const Btn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
    margin-bottom: 130px;
`


export const BtnPartnerBroker = styled.div`
    display: inline-block;
    padding-top: 40px;
    a{
        text-decoration:none;
        color: #fff;
        background-color: #0088AC;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 15px;

        span {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        }

        .icon-arrow {
            position: relative;
            top: 2px;
            left: 8px;
        }

        &:hover, .linkButton {
            background-color: #006690;
            color: #fff;
        }

    }

    @media (max-width: 1024px) { 
        a {
        padding-left: 60px;
        padding-right: 60px
        }
     }
`

export const BtnSeeProperties = styled.div`
    display: inline-block;
    padding-top: 40px;

    a{
        text-decoration:none;
        color: #fff;
        background-color: #0088AC;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 15px;
        padding-left: 35px;
        padding-right: 35px;

        span {
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        }

        .icon-arrow {
            position: relative;
            top: 2px;
            left: 8px;
        }

        &:hover, .linkButton {
            background-color: #006690;
            color: #fff;
        }

    }

    @media (max-width: 768px) {
       display: flex;
       justify-content: center;

       a {
        padding-left: 60px;
        padding-right: 60px
       }
    }
`