import React, { useEffect, useState } from 'react';
import Header from '../../components/adminHeader';
import Aside from '../../components/adminSideBar';
import { Container } from './styles';
import api from '../../api';
import { Link } from 'react-router-dom';
import { getPropertiesProposalsAsync } from "../../services/proposalImovel.service";

export default function PropostaAdmin() {
    const [data, setData] = useState([]);
    const [statusImovel, setStatus] = useState('false');
    const [digiteAlgo, setDigiteAlvo] = useState('');

    useEffect(() => {
        if (!localStorage.getItem('type_user')) {
            return window.location.href = '/login';
        }

        if (localStorage.getItem('type_user') !== 'admin') {
            return window.location.href = '/admin/propostas-corretor';
        }

        handleLoad();
    }, []);


    function cleanStates() {
        setDigiteAlvo('');
        setStatus('');
    }

    async function handleLoad() {
        cleanStates();
        const response = await getPropertiesProposalsAsync({});
        let array = [];
        let arrayDuplicate = [];
        response.data.ProposalImovel.map(e => {
            if (e.Property) {

                let isDistinct = true

                array.map(arr => {
                    if (arr.Property._id == e.Property._id && arr._id != e._id) {
                        isDistinct = false
                    }
                })

                if (isDistinct)
                    array.push(e)

                arrayDuplicate.push(e)
            }
        });

        array.sort((a, b) => {
            if (a.statusImovel == 'Não Vendido') {
                return -1
            }
        });

        array.map(proposal => {
            const proposalDuplicateAndSold = arrayDuplicate.filter(e => e.Property._id == proposal.Property._id && e.statusImovel == 'Vendido')
            if (proposalDuplicateAndSold.length == 1) {
                proposal.statusImovel = 'Vendido'
            }
            return proposal
        });

        setData(array);
    }

    async function handleApply() {
        let query = { distinct: true };

        if (statusImovel) {
            query.statusImovel = statusImovel;
        }

        if (digiteAlgo) {
            query.parametros = digiteAlgo;
        }

        if (!statusImovel && !digiteAlgo) {
            return
        }

        let { data } = await getPropertiesProposalsAsync(query);

        let array = []

        data.ProposalImovel.map(async e => {
            if (e.Property) {

                let isDistinct = true

                array.map(arr => {
                    if (arr.Property._id == e.Property._id && arr._id != e._id) {
                        isDistinct = false
                    }
                })

                if (isDistinct)
                    array.push(e)
            }
        });

        setData(array)
    }

    function innerRow(data, i) {
        let row = data.Property

        return <>

            <div className="body-imovel">
                <small>Cadastrado em: {String(data.createdAt).split(' ')[0]}</small>
                {/* {row.sellingPrice} */}
                <h2 style={{ fontSize: 18, marginTop: 15 }}><a style={{ color: 'inherit' }}>{row.address ? row.address : ''}{row.addressNumber ? ', ' + row.addressNumber : ''} {row.addressComplement ? ` / ${row.addressComplement}` : ''}</a></h2>
                <h3>{row.neighborhood ? row.neighborhood?.name : ''}{row.city ? " - " + row.city.name : ''}{row.neighborhood ? ', ' + row.neighborhood?.state : ''}</h3>

                <h5>{row.type ? row.type.name : ''} {row.generalType ? row.generalType.name : (row.type ? row.type.generalType == '2372820374682508' ? 'Residencial' : 'Comercial' : '')}</h5>

            </div>
            <div className="body-engajamento" style={{ color: data.statusImovel === 'Vendido' ? '#FF0000' : '#000' }}>
                {data.statusImovel}
            </div>
            <div className="body-stats">
                <button style={{ width: '80%' }} className="outline" onClick={() => window.location.href = `/admin/propostas-admin/editar?id=${data.Property._id}`}>
                    Ver Propostas
                </button>
            </div>
        </>
    }

    const handleSort = (value) => {
        switch (value) {
            case 'recente':
                const recente = data.filter(e => e._id)
                recente.sort((data1, data2) =>
                    (data1._id > data2._id) ? -1 : 1);

                setData(recente)
                break
            case 'antigo':
                const antigo = data.filter(e => e._id)

                antigo.sort((data1, data2) =>
                    (data1._id <= data2._id) ? -1 : 1)

                setData(antigo)
                break
            case 'a-z':
                const bairroAZ = data.filter(e => e._id)

                bairroAZ.sort(function (a, b) {
                    var textA = ''
                    var textB = ''
                    if (a.Property) {
                        if (a.Property.neighborhood && b.Property.neighborhood) {
                            textA = a.Property.neighborhood?.name?.toUpperCase();
                            textB = b.Property.neighborhood?.name?.toUpperCase();
                        }
                    }

                    return textA.localeCompare(textB);
                })

                setData(bairroAZ)
                break
            case 'z-a':
                const bairroZA = data.filter(e => e._id)

                bairroZA.sort(function (a, b) {
                    var textA = ''
                    var textB = ''
                    if (a.Property) {
                        if (a.Property.neighborhood && b.Property.neighborhood) {
                            textA = a.Property.neighborhood?.name?.toUpperCase();
                            textB = b.Property.neighborhood?.name?.toUpperCase();
                        }
                    }

                    return textA.localeCompare(textB);
                }).reverse()

                setData(bairroZA)
                break
        }
    }

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        IMÓVEIS À VENDA >
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Propostas Recebidas
                    </Link>
                </div>
                <div className="filter">
                    <div className="input-group">
                        <label htmlFor="">Digite Algo</label>
                        <input value={digiteAlgo} onChange={e => setDigiteAlvo(e.target.value)} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Status</label>
                        <select value={statusImovel} onChange={select => setStatus(select.target.value)}>
                            <option></option>
                            <option>Não Vendido</option>
                            <option>Vendido</option>
                        </select>
                    </div>
                    <div className="button-group">
                        <button onClick={handleLoad} className="outline">Remover filtros</button>
                        <button onClick={handleApply} className="full">Aplicar filtros</button>
                    </div>
                </div>

                <div className="row-ordenate">
                    <p></p>
                    <div className="input-group">
                        <select onChange={e => handleSort(e.target.value)}>
                            <option value="">Ordenar por</option>
                            <option value="recente">Mais Recentes</option>
                            <option value="antigo">Mais Antigos</option>
                            <option value="a-z">Bairro (A-Z)</option>
                            <option value="z-a">Bairro (Z-A)</option>
                        </select>
                    </div>

                </div>

                <div className="header" style={{ marginTop: 30 }}>
                    <div className="cod">
                        <h2>CÓD.</h2>
                    </div>
                    {
                        window.innerWidth <= 600 ?
                            <div style={{ overflow: 'auto', display: '-webkit-inline-box', width: '82%' }}>
                                <div style={{ width: 250 }} className="active">
                                    <h2>IMÓVEL</h2>
                                </div>
                                <div style={{ width: 150 }} className="imovel">
                                    <h2>STATUS</h2>
                                </div>
                                <div style={{ width: 140 }} className="engajamento">
                                    <h2>PROPOSTAS</h2>
                                </div>
                            </div>
                            :
                            <>
                                <div className="active">
                                    <h2>IMÓVEL</h2>
                                </div>
                                <div className="imovel">
                                    <h2>STATUS</h2>
                                </div>
                                <div className="engajamento">
                                    <h2>PROPOSTAS</h2>
                                </div>
                            </>
                    }
                </div>

                {
                    data.map(row => row.Property && (
                        <div key={row.Property._id} className="body">
                            <div className="body-cod">{row.Property._id}</div>
                            {
                                window.innerWidth <= 600 ?
                                    <div style={{ overflow: 'auto', display: '-webkit-inline-box', width: '82%' }}>
                                        {innerRow(row)}
                                    </div>
                                    :
                                    innerRow(row)
                            }
                        </div>
                    ))

                }

            </Container>
        </>
    )
}

