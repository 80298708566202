import api from "../api";

export const sendCodeVerification = async ({ email, validationType, phone }) => {
  const { data } = await api.patch(
    '/user/auth/v2/send-code-verification',
    { email, validationType, phone }
  );
  return data;
};

export const validateCode = async ({ email, verificationCode }) => {
  const { data } = await api.patch(
    '/user/auth/v2/validate-code',
    { email, verificationCode }
  );
  return data;
};

export const changePassword = async ({ newPassword, verificationCode }) => {
  const { data } = await api.patch(
    '/user/auth/v2/change-password-by-code',
    { newPassword, verificationCode }
  );
  return data;
};