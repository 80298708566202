/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Button, } from "antd";

import { Link, useHistory } from "react-router-dom";
import crypto from "crypto";

import {
  Container,
  ImageItem,
  PlusBtn,
  BtnWrapper,
  Carousel,
  DocItem,
  BookItem,
  Item,
} from "./styles";
import Header from "../../components/adminHeader";
import Aside from "../../components/adminSideBar";
import Picker from "../../components/picker";
import PlacesAutocomplete from "react-google-autocomplete";
import SwitchPartnerProperties from "../../components/forms/SwitchPartnerProperties";

import Loading from "../../components/Loading";

import api from "../../api";
import { NOTIFICATION_TYPE, openNotification } from "../../utils/notification";
import { getNeighborhoodAndCityAsync } from "../../services/city.service";
import TextEditor from "../../components/TextEditor";
import { CodeOutlined, VideoCameraOutlined, DesktopOutlined } from "@ant-design/icons";

export default function AdminOfferProperty() {
  const [showVideoCode, setShowVideoCode] = useState(false);
  const [showDescriptionCode, setShowDescriptionCode] = useState(false);
  const [showSuggestionCode, setShowSuggestionCode] = useState(false);

  const [pdfAlter, setPdfAlter] = useState(false);
  const [pdfMem, setPdfMem] = useState([
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
  ]);
  const [pdf, setPdf] = useState([
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
  ]);

  const history = useHistory();
  const [completePlace, setCompletePlace] = useState("");
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [generalPropertyType, setGeneralPropertyType] = useState([]);
  const [types, setTypes] = useState([]);
  const [tags, setTags] = useState([]);

  const [loading, setLoading] = useState(false);

  const [lockList, setLockList] = useState([]);
  const [lock, setLock] = useState("");
  const [useApp, setUseApp] = useState(false); //campos antigos já vão começar com valor falso, sendo a opção da chave normal, SE verdadeiro alterna para a opção APP e

  const [ID, setID] = useState(1);


  const [checkedPartnerProperty, setCheckedPartnerProperty] = useState(false)

  const [property, setProperty] = useState({
    _id: null,
    postalCode: undefined,
    cityName: undefined,
    neighborhoodName: undefined,
    address: undefined,
    addressNumber: undefined,
    addressComplement: undefined,
    neighborhood: undefined,
    showNumber: true,
    showComplement: true,
    city: undefined,
    state: undefined,
    IPTU: undefined,
    IPTUNumber: undefined,
    sellingPrice: undefined,
    descountPrice: undefined,
    condominiumPrice: undefined,
    sharedSale: false,
    sellingNotes: undefined,

    // Novos
    useApp: false,
    lockId: 0,
    sellingNotesApp: undefined,

    comission: undefined,
    type: undefined,
    generalType: undefined,
    fullLandSize: undefined,
    bathrooms: undefined,
    suites: undefined,
    showHome: false,
    bedrooms: undefined,
    parkingSpots: undefined,
    features: [],
    tag: undefined,
    isPartnerProperty: false,
    description: undefined,
    descriptionSuggestion: undefined,
    videoLink: undefined,
    driveLink: undefined,
    dependencies: undefined,
    sold: false,
    showTag: false,
    vendedor: undefined,
  });

  const onChangePartnerProperty = (checked) => {
    setCheckedPartnerProperty(checked)
  };

  const getCity = async () => {

    let collection = cities;
    if (property.state && !collection.some(x => x.stateName === property.state))
      await api.get(`/city?stateName=${property.state}`).then((res) => {
        const { citys } = res.data;
        collection = citys;
        setCities(citys);
      });
    const city = collection.find(x => x.name === property?.place?.cityName)
    setProperty({
      ...property,
      city: city._id,
    });
  };

  useEffect(() => {
    if (property.state)
      getCity();
  }, [property.state]);

  const handleLoadList = async () => {
    const { data } = await api.get(`/lock`);
    setLockList(data.locks);
  };

  function addPdf(files, id) {
    if (!files.length) {
      return null;
    }
    const url = URL.createObjectURL(files[0]);

    const value = pdf.find((value) => value.id === id);
    const clear = pdf.filter((value) => value.id !== id);

    value.file = files[0];
    value.url = url;
    value.image = files[0];

    setPdf([...clear, value]);

    setPdfAlter(!pdfAlter);
  }

  function addPdfMem(files, id) {
    if (!files.length) {
      return null;
    }

    const url = URL.createObjectURL(files[0]);

    const value = pdfMem.find((value) => value.id === id);
    const clear = pdfMem.filter((value) => value.id !== id);

    value.file = files[0];
    value.url = url;
    value.image = files[0];

    setPdfMem([...clear, value]);

    setPdfAlter(!pdfAlter);
  }

  function removePdf(id) {
    const remove = pdf.filter((value) => value.id === id);
    if (remove[0].name) {
      api.post("/file/pdfRemove", { pdf: remove[0].name, id: ID });
    }
    const removed = pdf.filter((value) => value.id !== id);
    if (removed.length == 0) {
      setPdf([
        {
          id: crypto
            .createHash("sha256")
            .update(Math.random().toString())
            .digest("hex"),
        },
      ]);
      return;
    }
    setPdf(removed);
  }

  useEffect(() => {
    setLoading(true)
    handleLoadList();
    api
      .get("/propertyType")
      .then((res) => {
        setTypes(res.data.propertyTypes);
      })
      .catch((err) => console.error(err));

    api.get("/generalPropertyType").then((res) => {
      setGeneralPropertyType(res.data.generalPropertyTypes);
    });

    api
      .get("/tag")
      .then((res) => setTags(res.data.tags))
      .catch((err) => console.error(err));

    setLoading(false)
  }, []);

  useEffect(() => {
    if (types.length > 1) {
      setProperty({
        ...property,
        type: property.type,
      });
    }

    if (generalPropertyType.length > 1) {
      setProperty({
        ...property,
        generalType: property.generalType,
      });
    }
  }, [property.type, types, generalPropertyType]);

  const getNeighborhood = () => {
    let collection = neighborhoods;
    if (property.city && !neighborhoods?.some(x => x.city === property.city))
      api.get(`/neighborhood?city=${property.city}`).then((res) => {
        setNeighborhoods(res.data.neighborhoods);
        collection = res.data.neighborhoods;
      });
    const item = collection.find((x) => x.name === property.place.neighborhoodName);
    setProperty({
      ...property,
      neighborhood: item?._id,
    });
  };

  async function sendImages(id) {
    const filtered = imageCounter.filter((value) => {
      if (value.image) {
        return value;
      }
    });

    let formData;

    for (var i = 0; i < filtered.length;) {
      formData = new FormData();
      formData.append("show", filtered[i].show);
      formData.append("property", id);
      formData.append("file", filtered[i].image);

      await api.post("/file/image", formData);
      i++;
    }
  }

  async function sendDocs(id) {
    const filtered = docCounter.filter((value) => {
      if (value.file) {
        return value;
      }
    });

    let formData;

    filtered.map((value) => {
      formData = new FormData();
      formData.append("property", id);
      formData.append("file", value.file);

      api
        .post("/file/doc", formData)
        .then((res) => { })
        .catch((err) => {
          openNotification("Falha ao inserir documentos,tente novamente mais tarde", NOTIFICATION_TYPE.error);
        });
    });

    if (pdfMem[0].file) {
      const formData = new FormData();

      formData.append("property", id);
      formData.append("file", pdfMem[0].file);

      await api
        .post("/file/memorial", formData)
        .then((_res) => { })
        .catch((_err) =>
          openNotification("Houve um erro ao cadastrar o memorial...", NOTIFICATION_TYPE.error)
        );
    }

    if (pdf[0].file) {
      const formData = new FormData();

      formData.append("property", id);
      formData.append("file", pdf[0].file);

      await api
        .post("/file/pdf", formData)
        .then((_res) => { })
        .catch((_err) => openNotification("Houve um erro ao cadastrar o book...", NOTIFICATION_TYPE.error));
    }
  }

  async function create() {

    var obj = property;
    delete obj["_id"];
    Object.keys(obj).forEach((key) => (obj[key] == null || obj[key] == undefined) && delete obj[key]);
    // obj["address"] = end;

    if (!obj["neighborhood"]) {
      obj["neighborhood"] = 0;
    }

    if (obj["useApp"]) {
      if (!obj["lockId"]) {
        let inputs = document.querySelectorAll(".input-compose");
        inputs[12].style.border = "2px solid red";
        openNotification('Selecione uma Fechadura ou altera o método de abertura da Porta!');
        return;
      }
    } else {
      obj["useApp"] = "false";
    }

    if (!obj["lockId"]) {
      obj["lockId"] = "0";
    }

    obj["createdAt"] =
      new Date().toLocaleDateString("pt-BR", { day: "2-digit" }) +
      "/" +
      new Date().toLocaleDateString("pt-BR", { month: "2-digit" }) +
      "/" +
      new Date().toLocaleDateString("pt-BR", { year: "numeric" });

    try {
      const { data: NeighborhoodAndCity } = await getNeighborhoodAndCityAsync({
        ...obj.place,
        neighborhoodName: obj.neighborhoodName || obj.place.neighborhoodName
      });
      const { neighborhoodId, cityId } = NeighborhoodAndCity;
      obj.neighborhood = neighborhoodId;
      obj.city = cityId;
    } catch (error) {
      console.error(error);
      openNotification('Não foi possível validar a localização, corrija o endereço!', NOTIFICATION_TYPE.error);
      return;
    }

    obj["isPartnerProperty"] = checkedPartnerProperty

    const handleOptionalValidation = (value) => {
      const optionalTypeNames = ['Terreno Padrão', 'Prédio', 'Prédio Inteiro'];
      const isOptional = types.some((x) =>
        Number(obj["type"]) === x._id && optionalTypeNames.includes(x.name));
      return isOptional || !!value;
    }

    if (
      handleOptionalValidation(obj["bathrooms"]) &&
      handleOptionalValidation(obj["bedrooms"]) &&
      obj["city"] &&
      obj["condominiumPrice"] &&
      obj["fullLandSize"] &&
      handleOptionalValidation(obj["parkingSpots"]) &&
      handleOptionalValidation(obj["suites"]) &&
      obj["IPTU"] &&
      obj["IPTUNumber"] &&
      obj["addressNumber"] &&
      handleOptionalValidation(obj["dependencies"]) &&
      obj["type"] &&
      obj["generalType"]
    ) {
      //&& obj['tag'] && obj['features']){
      api
        .post("/property", obj)
        .then(async (res) => {
          // setProperty({
          //   ...property,
          //   _id: res.data._id,
          // });
          await sendImages(res.data._id);
          await sendDocs(res.data._id);

          openNotification('Imóvel adicionado com sucesso', NOTIFICATION_TYPE.success);
          setTimeout(() => {
            history.goBack();
          }, 1500);
        })
        .catch((err) => {
          openNotification('Falha ao criar imóvel,tente novamente mais tarde', NOTIFICATION_TYPE.error);
          return;
        });
    } else {
      let inputs = document.querySelectorAll(".input-compose");

      !obj["addressNumber"]
        ? (inputs[0].style.border = "2px solid red")
        : (inputs[0].style.border = "0px solid red");
      !obj["condominiumPrice"]
        ? (inputs[6].style.border = "2px solid red")
        : (inputs[6].style.border = "0px solid red");
      !obj["IPTU"]
        ? (inputs[7].style.border = "2px solid red")
        : (inputs[7].style.border = "0px solid red");
      !obj["IPTUNumber"]
        ? (inputs[8].style.border = "2px solid red")
        : (inputs[8].style.border = "0px solid red");
      !obj["sellingPrice"]
        ? (inputs[9].style.border = "2px solid red")
        : (inputs[9].style.border = "0px solid red");
      !obj["generalType"]
        ? (inputs[13].style.border = "2px solid red")
        : (inputs[13].style.border = "0px solid red");
      !obj["type"]
        ? (inputs[14].style.border = "2px solid red")
        : (inputs[14].style.border = "0px solid red");
      !obj["fullLandSize"]
        ? (inputs[15].style.border = "2px solid red")
        : (inputs[15].style.border = "0px solid red");
      !handleOptionalValidation(obj["bedrooms"])
        ? (inputs[16].style.border = "2px solid red")
        : (inputs[16].style.border = "0px solid red");
      !handleOptionalValidation(obj["suites"])
        ? (inputs[17].style.border = "2px solid red")
        : (inputs[17].style.border = "0px solid red");
      !handleOptionalValidation(obj["bathrooms"])
        ? (inputs[18].style.border = "2px solid red")
        : (inputs[18].style.border = "0px solid red");
      !handleOptionalValidation(obj["dependencies"])
        ? (inputs[19].style.border = "2px solid red")
        : (inputs[19].style.border = "0px solid red");
      !handleOptionalValidation(obj["parkingSpots"])
        ? (inputs[20].style.border = "2px solid red")
        : (inputs[20].style.border = "0px solid red");
      openNotification("Preencha os campos em vermelho.", NOTIFICATION_TYPE.warning);
    }
  }

  useEffect(() => {
    if (!isNaN(property.city)) {
      getNeighborhood();
    }
  }, [property.city]);

  useEffect(() => {
    const setImages = async () => {
      if (property._id) {
        await sendImages();
        await sendDocs();
        history.push(`/imoveis/${property._id}`);
      }
    };
    setImages();
  }, [property._id]);

  useEffect(() => {
    if (property.place) {
      setProperty({
        ...property,
        postalCode: property.place.postalCode,
        address: property.place.address,
        addressNumber: property.place.addressNumber,
        cityName: property.place.cityName,
        neighborhoodName: property.place.neighborhoodName,
        state: property.place.stateName,
      });
    }
  }, [property?.place]);

  useEffect(() => {
    if (localStorage.getItem("type_user") != "admin") {
      window.location.href = "/login";
      return;
    }
  })

  const [imageCounter, setImageCounter] = useState([
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
  ]);

  const [docCounter, setDocCounter] = useState([
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
    {
      id: crypto
        .createHash("sha256")
        .update(Math.random().toString())
        .digest("hex"),
    },
  ]);

  function addImage(files, id) {
    if (!files.length) {
      return null;
    }

    if (files.length == 1) {
      const checkbox = document.querySelector(`input[name=checkbox-${id}]`);

      const url = URL.createObjectURL(files[0]);

      const value = imageCounter.find((value) => value.id === id);
      const clear = imageCounter.filter((value) => value.id !== id);

      value.image = files[0];
      value.url = url;
      value.show = checkbox.checked;

      setImageCounter([...clear, value]);
    } else {
      let arrayImage = [];
      imageCounter.map((row) => {
        if (row.image != null) {
          arrayImage.push(row);
        }
      });

      for (let i = 0; i < files.length; i++) {
        const url = URL.createObjectURL(files[i]);
        arrayImage.push({
          id: crypto
            .createHash("sha256")
            .update(Math.random().toString())
            .digest("hex"),
          image: files[i],
          url: url,
          show: true,
          name: null,
        });
      }

      setImageCounter(arrayImage);
    }
  }

  function removeImage(id) {
    const removed = imageCounter.filter((value) => value.id !== id);
    setImageCounter(removed);
  }

  function editImageStatus(id, status) {
    const edited = imageCounter.map((value) => {
      if (value.id === id) {
        value.show = `${status}`;
      }
      return value;
    });

    setImageCounter(edited);
  }

  function addDoc(files, id) {
    if (!files.length) {
      return null;
    }

    if (files.length == 1) {
      const url = URL.createObjectURL(files[0]);

      const value = docCounter.find((value) => value.id === id);
      const clear = docCounter.filter((value) => value.id !== id);

      value.file = files[0];
      value.url = url;

      setDocCounter([...clear, value]);
    } else {
      let arrayDocs = [];
      docCounter.map((row) => {
        if (row.image != null) {
          arrayDocs.push(row);
        }
      });

      for (let i = 0; i < files.length; i++) {
        const url = URL.createObjectURL(files[i]);
        arrayDocs.push({
          id: crypto
            .createHash("sha256")
            .update(Math.random().toString())
            .digest("hex"),
          image: files[i],
          url: url,
          file: files[i],
        });
      }

      setDocCounter(arrayDocs);
    }
  }

  function removeDoc(id) {
    const removed = docCounter.filter((value) => value.id !== id);
    setDocCounter(removed);
  }

  function moneyToNumber(value) {
    value = value
      .replaceAll("R", "")
      .replaceAll("$", "")
      .replaceAll(" ", "")
      .replaceAll(".", "")
      .replaceAll(",", "");

    return (
      value.substr(1, value.length - 3) +
      "." +
      value.substr(value.length - 2, 2)
    );
  }

  function money(value) {
    value = String(value);
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(+value.replace(/\D+/g, "") / 100);
  }

  return (
    <>
      {loading && <Loading />}
      <Header />
      <Aside />
      <Container>
        <div className="bread-crumb">
          <h1 className="category">ANÚNCIOS&nbsp;{">"}&nbsp;</h1>
          <Link to={window.location.pathname} className="where">
            Anúncios Corretores&nbsp;{">"}&nbsp;
          </Link>
          <Link to={window.location.pathname} className="where">
            &nbsp;Novo
          </Link>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label>Endereço</label>
            <PlacesAutocomplete
              value={completePlace}
              // value={property.address}
              onChange={(place) => {
                setCompletePlace(place?.target?.value);
              }}
              placeholder=""
              apiKey="AIzaSyCbyjDUXM7xpSvvwybHURKFrOh65yPo2Gg"
              options={{
                types: ["address"],
                componentRestrictions: { country: "br" },
                fields: ["address_components", "geometry.location", "formatted_address"],
              }}
              onPlaceSelected={(placeResult) => {
                if (!placeResult?.address_components.length) return;

                const address_components = {
                  location: {
                    latitude: placeResult.geometry.location.lat(),
                    longitude: placeResult.geometry.location.lng(),
                  },
                };
                placeResult.address_components.map(
                  (c) => address_components[c.types[0]] = c.long_name);

                const place = {
                  addressNumber: address_components.street_number,
                  address: address_components.route,
                  neighborhoodName: address_components.sublocality_level_1,
                  cityName: address_components.administrative_area_level_2,
                  stateName: address_components.administrative_area_level_1,
                  countryName: address_components.country,
                  postalCode: address_components.postal_code,
                  location: address_components.location,
                };

                if (!place.address) {
                  openNotification("Endereço inválido!", NOTIFICATION_TYPE.warning);
                  return;
                }


                if (place.addressNumber) {
                  place.formatted_address = `${place.address}, ${place.addressNumber}`;
                  place.addressNumber = parseInt(place.addressNumber);
                } else
                  place.formatted_address = place.address;

                if (isNaN(place.addressNumber)) {
                  openNotification("Número não informado!", NOTIFICATION_TYPE.warning);
                }

                setCompletePlace(place.formatted_address);
                setProperty({
                  ...property,
                  place,
                });
              }
              }
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label htmlFor="">CEP</label>
            <input
              type="text"
              disabled={true}
              value={property?.postalCode}
            />
          </div>
          <div className="input-compose">
            <label htmlFor="">Complemento</label>
            <input
              type="text"
              value={property?.addressComplement}
              onChange={(e) =>
                setProperty({
                  ...property,
                  addressComplement: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="input-group">
          <div className="select-group">
            <input
              type="checkbox"
              name=""
              id=""
              checked={!property.showNumber}
              value={!property.showNumber}
              onChange={(e) =>
                setProperty({
                  ...property,
                  showNumber: !e.target.checked,
                })
              }
            />
            <span>Não desejo exibir o número no anúncio</span>
          </div>
          <div className="select-group">
            <input
              type="checkbox"
              name=""
              id=""
              checked={!property.showComplement}
              value={property.showComplement}
              onChange={(e) =>
                setProperty({
                  ...property,
                  showComplement: !e.target.checked,
                })
              }
            />
            <span>Não desejo exibir o complemento no anúncio</span>
          </div>
        </div>
        <div className="input-group" style={{ marginTop: "30px" }}>
          <div className="input-compose">
            <label htmlFor="">Estado</label>
            <input
              type="text"
              disabled={true}
              value={property.state}
            />
          </div>
          <div className="input-compose">
            <label htmlFor="">Cidade</label>
            <input
              type="text"
              disabled={true}
              value={property.cityName}
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label htmlFor="">Bairro</label>
            <select
              style={{ 'fontSize': '14px' }}
              value={property.neighborhoodName}
              onChange={e => setProperty({ ...property, neighborhoodName: e.target.value })}>
              <option></option>
              {
                neighborhoods.map(({ name, _id }) => (
                  <option key={_id.toString()} value={name}>{name}</option>
                ))
              }
            </select>
          </div>
          <div className="input-compose">
            <label htmlFor="">Valor do condomínio</label>
            <input
              type="text"
              value={money(property.condominiumPrice)}
              onChange={(e) =>
                setProperty({
                  ...property,
                  condominiumPrice: moneyToNumber(e.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label htmlFor="">IPTU Valor</label>
            <input
              type="text"
              value={money(property.IPTU)}
              onChange={(e) =>
                setProperty({
                  ...property,
                  IPTU: moneyToNumber(e.target.value),
                })
              }
            />
          </div>
          <div className="input-compose">
            <label htmlFor="">IPTU Numero</label>
            <input
              type="text"
              value={property?.IPTUNumber || undefined}
              onChange={(e) =>
                setProperty({
                  ...property,
                  IPTUNumber: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label htmlFor="">Valor de venda do imóvel</label>
            <input
              type="text"
              value={money(property.sellingPrice)}
              onChange={(e) => {
                setProperty({
                  ...property,
                  sellingPrice: moneyToNumber(e.target.value),
                });
              }}
            />
          </div>
          <div className="input-compose">
            <label htmlFor="">Valor de venda com desconto</label>
            <input
              type="text"
              value={money(String(property.descountPrice))}
              onChange={(e) => {
                setProperty({
                  ...property,
                  descountPrice: moneyToNumber(e.target.value),
                });

                if (
                  Number(moneyToNumber(e.target.value)) >
                  Number(moneyToNumber(money(property.sellingPrice)))
                ) {
                  setProperty({
                    ...property,
                    descountPrice: property.sellingPrice,
                  });
                  openNotification(
                    "O desconto não pode ser maior que o valor original.",
                    NOTIFICATION_TYPE.warning
                  );
                  return;
                }
              }}
            />
          </div>
        </div>

        <h1>Configurações de Venda</h1>
        <div className="input-group" style={{ flexFlow: "column" }}>
          <div className="input-compose">
            <p>Qual a comissão compartilhada em %?</p>
            <input
              type="text"
              value={property?.comission || undefined}
              onChange={(e) =>
                setProperty({
                  ...property,
                  comission: e.target.value,
                })
              }
            />
          </div>
          <div className=""></div>
          <div
            className="input-compose"
            style={{ width: "100%" }}
            hidden={!property.useApp}
          >
            <p>Fechadura</p>
            <select
              value={property.lockId}
              onChange={(e) =>
                setProperty({
                  ...property,
                  lockId: Number(e.target.value), //marcação
                })
              }
            >
              <option value={0}>Não definido</option>
              {lockList?.length > 0 &&
                lockList.map((row) => {
                  return (
                    <>
                      <option value={row._id}>{row.name}</option>
                    </>
                  );
                })}
            </select>
          </div>
        </div>
        <div
          className="share"
          style={{
            borderTop: 0,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div className="column">
            <div className="radio-group">
              <input
                checked={!property.useApp}
                type="radio"
                onChange={() =>
                  setProperty({
                    ...property,
                    useApp: false,
                    lockId: "0",
                  })
                }
              />
              <h3>&nbsp;Método Esquema de Chave</h3>
              <br />
            </div>
            <textarea
              rows={10}
              placeholder="Escreva as instruções de venda para o método de esquema de chave"
              value={property.sellingNotes || undefined}
              onChange={(e) =>
                setProperty({
                  ...property,
                  sellingNotes: e.target.value,
                })
              }
            ></textarea>
          </div>

          <div className="column">
            <div className="radio-group">
              <input
                checked={property.useApp}
                type="radio"
                onChange={() =>
                  setProperty({
                    ...property,
                    useApp: true,
                  })
                }
              />
              <h3>&nbsp;Método Abrir Porta pelo App</h3>
              <br />
            </div>
            <textarea
              rows={10}
              placeholder="Escreva as instruções de venda para o método de abertura pelo APP"
              value={property.sellingNotesApp || undefined}
              onChange={(e) =>
                setProperty({
                  ...property,
                  sellingNotesApp: e.target.value,
                })
              }
            ></textarea>
          </div>
        </div>

        <div className="input-group">
          <div className="input-compose">
            <label htmlFor="">Residencial ou comercial</label>
            <select
              name=""
              id=""
              onChange={(e) =>
                setProperty({
                  ...property,
                  generalType:
                    e.target.value != "Selecione" ? e.target.value : null,
                })
              }
            >
              <option defaultValue>Selecione</option>
              {generalPropertyType.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-compose">
            <label htmlFor="">Tipo</label>
            <select
              onChange={(e) => {
                setProperty({
                  ...property,
                  type: e.target.value == "Selecione" ? null : e.target.value,
                });
              }}
            >
              <option defaultValue>Selecione</option>

              {types.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label htmlFor="">Metragem (m²)</label>
            <input
              type="text"
              value={property.fullLandSize || undefined}
              onChange={(e) =>
                setProperty({
                  ...property,
                  fullLandSize: e.target.value,
                })
              }
            />
          </div>
          <div className="input-compose">
            <label htmlFor="">Total quartos (Exceto dependências)</label>
            <input
              type="text"
              value={property.bedrooms || undefined}
              onChange={(e) =>
                setProperty({
                  ...property,
                  bedrooms: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label htmlFor="">Sendo quantas Suites?</label>
            <input
              type="text"
              value={property.suites || undefined}
              onChange={(e) =>
                setProperty({
                  ...property,
                  suites: e.target.value,
                })
              }
            />
          </div>
          <div className="input-compose">
            <label htmlFor="">Total de banheiros (Exceto dependências)</label>
            <input
              type="text"
              value={property.bathrooms || undefined}
              onChange={(e) =>
                setProperty({
                  ...property,
                  bathrooms: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="input-group">
          <div className="input-compose">
            <label htmlFor="">Total de dependências de serviço</label>
            <input
              type="text"
              value={property.dependencies || undefined}
              onChange={(e) =>
                setProperty({
                  ...property,
                  dependencies: e.target.value,
                })
              }
            />
          </div>
          <div className="input-compose">
            <label htmlFor="">Número de vagas</label>
            <input
              type="text"
              value={property.parkingSpots || undefined}
              onChange={(e) =>
                setProperty({
                  ...property,
                  parkingSpots: e.target.value,
                })
              }
            />
          </div>
        </div>
        <h1>Selecione todas as características deste imóvel</h1>
        <Picker
          setValue={(value) => setProperty({ ...property, features: value })}
        />
        <div className="tag" style={{ marginTop: "30px" }}>
          <h2>Escolha uma TAG que melhor represente esta oportunidade</h2>
          <p>
            Esta TAG será aplicada eventualmente no seu anúncio para destacá-lo.
          </p>
          <SwitchPartnerProperties
            checkedPartnerProperty={checkedPartnerProperty}
            onChangePartnerProperty={onChangePartnerProperty} />
          <div className="input-group">
            <div className="input-compose">
              <label htmlFor="">TAG</label>
              <select
                onChange={(e) =>
                  setProperty({
                    ...property,
                    tag: e.target.value != "Selecione" ? e.target.value : null,
                  })
                }
              >
                <option>Selecione</option>
                {tags.map((value) => (
                  <option value={value._id} key={value._id}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            className="select-group"
            style={{ marginBottom: 20, marginTop: -5 }}
          >
            <input
              type="checkbox"
              name=""
              id=""
              value={property.showTag || undefined}
              onChange={(e) =>
                setProperty({
                  ...property,
                  showTag: e.target.checked,
                })
              }
            />
            <span style={{ marginLeft: 10 }}>
              Marque para forçar a aplicação desta tag no imóvel
            </span>
          </div>

          <h2>Descreva o seu imóvel (opcional){showDescriptionCode ? <Button type="text" onClick={() => setShowDescriptionCode(false)}><DesktopOutlined />Exibir Pré-visualização</Button> : <Button type="text" onClick={() => setShowDescriptionCode(true)}><CodeOutlined />Exibir Código Fonte</Button>}</h2>
          {showDescriptionCode ?
            <div className="input-group">
              <div className="input-compose">
                <textarea
                  rows={5}
                  placeholder="Insira o código fonte aqui"
                  value={property.description}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      description: e.target.value,
                    });
                  }}
                ></textarea>

              </div>
            </div>
            : <TextEditor text={property.description} handlerText={(val) => {
              setProperty({
                ...property,
                description: val,
              });
            }} />}

          <h2>Sugestão de descrição para anúncios (opcional){showSuggestionCode ? <Button type="text" onClick={() => setShowSuggestionCode(false)}><DesktopOutlined />Exibir Pré-visualização</Button> : <Button type="text" onClick={() => setShowSuggestionCode(true)}><CodeOutlined />Exibir Código Fonte</Button>}</h2>
          {showSuggestionCode ?
            <div className="input-group">
              <div className="input-compose">
                <textarea
                  rows={5}
                  placeholder="Insira o código fonte aqui"
                  value={property.descriptionSuggestion}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      descriptionSuggestion: e.target.value,
                    });
                  }}
                ></textarea>

              </div>
            </div>
            : <TextEditor text={property.descriptionSuggestion} handlerText={(val) => {
              setProperty({
                ...property,
                descriptionSuggestion: val,
              });
            }} />}
        </div>


        <h2>Incorporar vídeo (opcional){showVideoCode ? <Button type="text" onClick={() => setShowVideoCode(false)}><VideoCameraOutlined />Exibir Pré-visualização</Button> : <Button type="text" onClick={() => setShowVideoCode(true)}><CodeOutlined />Exibir Código Fonte</Button>}</h2>
        {showVideoCode ?
          <div className="input-group">
            <div className="input-compose">
              <textarea
                rows={5}
                placeholder="Insira o código fonte aqui"
                value={property.videoLink}
                onChange={(e) => {
                  setProperty({
                    ...property,
                    videoLink: e.target.value,
                  });
                }}
              ></textarea>

            </div>
          </div>
          : <TextEditor
            isEmpty={true}
            text={property.videoLink || ''}
            handlerText={(val) => {
              setProperty({
                ...property,
                videoLink: val,
              });
            }}
          />}

        <div className="input-group">
          <div className="input-compose">
            <label htmlFor="">Link do drive das fotos (opcional)</label>
            <input
              type="text"
              placeholder="Insira o link do drive ex: https://drive.google.com/file/d/iv01234"
              value={property?.driveLink}
              onChange={(e) =>
                setProperty({
                  ...property,
                  driveLink: e.target.value,
                })
              }
            />
          </div>
        </div>

        <h2>
          Adicione fotos e documentos. Se preferir, pode adicionar
          posteriormente.
        </h2>
        <Carousel style={{ marginTop: "30px" }}>
          {imageCounter.map((value, index) => (
            <Item key={index}>
              <ImageItem
                htmlFor={`image-${value.id}`}
                image={value.url ? value.url : ""}
                className="image-container"
              >
                <input
                  multiple="multiple"
                  type="file"
                  name="image"
                  id={`image-${value.id}`}
                  onInput={(e) => addImage(e.target.files, value.id)}
                />
                <button
                  className="close"
                  show={value.image ? "true" : undefined}
                  onClick={() => removeImage(value.id)}
                >
                  x
                </button>
              </ImageItem>
              <div className="row" style={{ marginTop: "20px" }}>
                <input
                  type="checkbox"
                  name={`checkbox-${value.id}`}
                  id=""
                  defaultChecked
                  onChange={(e) => {
                    editImageStatus(value.id, e.target.checked);
                  }}
                />
                <label htmlFor="" style={{ fontWeight: "normal" }}>
                  Exibir no anúncio{" "}
                </label>
              </div>
            </Item>
          ))}
          <BtnWrapper>
            <PlusBtn
              onClick={() =>
                setImageCounter([
                  ...imageCounter,
                  {
                    id: crypto
                      .createHash("sha256")
                      .update(Math.random().toString())
                      .digest("hex"),
                  },
                ])
              }
            >
              <img
                src={require("../../assets/adminOfferProperty/plus.svg")}
                alt=""
              />
            </PlusBtn>
          </BtnWrapper>
        </Carousel>
        <Carousel style={{ marginTop: "30px" }}>
          {docCounter.map((value, index) => (
            <Item key={index}>
              <DocItem
                htmlFor={`doc-${value.id}`}
                image={
                  value.file
                    ? require("../../assets/adminOfferProperty/docok.svg")
                    : ""
                }
                className="doc-container"
              >
                <input
                  multiple="multiple"
                  type="file"
                  name="doc"
                  id={`doc-${value.id}`}
                  onInput={(e) => addDoc(e.target.files, value.id)}
                />
                <button
                  className="close"
                  show={value.file ? "true" : "false"}
                  onClick={() => removeDoc(value.id)}
                >
                  x
                </button>
              </DocItem>
              <div className="row" style={{ marginTop: "20px" }}>
                <label htmlFor="" style={{ fontWeight: "normal" }}>
                  {value.file ? value.file.name : ""}
                </label>
              </div>
            </Item>
          ))}
          <BtnWrapper>
            <PlusBtn
              onClick={() =>
                setDocCounter([
                  ...docCounter,
                  {
                    id: crypto
                      .createHash("sha256")
                      .update(Math.random().toString())
                      .digest("hex"),
                  },
                ])
              }
            >
              <img
                src={require("../../assets/adminOfferProperty/plus.svg")}
                alt=""
              />
            </PlusBtn>
          </BtnWrapper>
        </Carousel>
        <Carousel style={{ marginTop: "30px" }}>
          {pdf.map((value, index) => (
            <Item key={index}>
              <BookItem
                htmlFor={`doc-${value.id}`}
                image={
                  value.file || value.image == "teste"
                    ? require("../../assets/adminOfferProperty/docok.svg")
                    : ""
                }
                className="doc-container"
              >
                <input
                  type="file"
                  disabled={value.image == "teste" ? true : false}
                  name="doc"
                  id={`doc-${value.id}`}
                  onInput={(e) => addPdf(e.target.files, value.id)}
                />
                <button
                  className="close"
                  show={value.file ? "true" : "false"}
                  onClick={() => {
                    if (value.image == "teste") {
                      if (
                        window.confirm(
                          "O documento será deletado, deseja continuar?"
                        )
                      ) {
                        removePdf(value.id);
                      }
                    } else {
                      removePdf(value.id);
                    }
                  }}
                >
                  x
                </button>
                <div>
                  Adicione Book
                  <br />
                  (opcional)
                </div>
              </BookItem>
              <div className="row" style={{ marginTop: "20px" }}>
                <label htmlFor="" style={{ fontWeight: "normal" }}>
                  {value.file && value.image != "teste" ? value.file.name : ""}
                  {!value.file && value.image == "teste" ? value.name : ""}
                </label>
              </div>
            </Item>
          ))}
          {pdfMem.map((value, index) => (
            <Item key={index}>
              <BookItem
                htmlFor={`doc-${value.id}`}
                image={
                  value.file || value.image == "teste"
                    ? require("../../assets/adminOfferProperty/docok.svg")
                    : ""
                }
                className="doc-container"
              >
                <input
                  type="file"
                  disabled={value.image == "teste" ? true : false}
                  name="doc"
                  id={`doc-${value.id}`}
                  onInput={(e) => addPdfMem(e.target.files, value.id)}
                />
                <button
                  className="close"
                  show={value.file ? "true" : "false"}
                  onClick={() => {
                    setPdfMem([
                      {
                        id: crypto
                          .createHash("sha256")
                          .update(Math.random().toString())
                          .digest("hex"),
                      },
                    ]);
                  }}
                >
                  x
                </button>
                <div>
                  Adicione Memorial
                  <br />
                  (opcional)
                </div>
              </BookItem>
              <div className="row" style={{ marginTop: "20px" }}>
                <label htmlFor="" style={{ fontWeight: "normal" }}>
                  {value.file && value.image != "teste" ? value.file.name : ""}
                  {!value.file && value.image == "teste" ? value.name : ""}
                </label>
              </div>
            </Item>
          ))}
        </Carousel>
        <div className="save">
          <button className="save-button" onClick={create}>
            Salvar
          </button>
        </div>
      </Container>
    </>

  )
}
