import React from "react";
import { Collapse } from "antd";
import DigitalLockLinkButton from "../DigitalLockLinkButton";
import InstallApp from "../InstallApp";

const InstructionDetails = ({ data, sellingNotes, isMobile, lockId, useApp, }) => {
  return (
    <div
      style={{
        width: "87%",
        marginLeft: "5%",
        height: isMobile ? "90%" : "auto",
      }}
    >
      <h1
        style={{
          fontSize: isMobile ? 20 : 35,
          marginTop: "2%",
          height: "13%",
        }}
      >
        Ofereça este exclusivo imóvel aos seus clientes!
      </h1>
      <h4
        style={{
          height: "4%",
          width: "100%",
          fontSize: isMobile ? 18 : 23,
          marginTop: isMobile ? "10px" : "25px",
        }}
      >
        Comissão{" "}
        <span style={{ color: "#EA5C1E" }}>
          {data.comission}%
        </span>
      </h4>

      {useApp ? (
        <>
          <h4
            style={{
              height: "4%",
              width: "100%",
              fontSize: isMobile ? 18 : 23,
              marginTop: isMobile ? "15px" : "30px",
              marginBottom: isMobile ? "15px" : "25px",
            }}
          >
            Instruções
          </h4>

          <ol
            style={{
              marginLeft: '2rem',
              marginTop: "10px",
            }}
          >
            <li style={{ marginBottom: "10px" }}>
              Para abrir a fechadura é preciso usar o app do
              Imóvel Vazio. <strong>Instale no seu celular!</strong>.
            </li>
            <li style={{ marginBottom: "10px" }}>
              Instale também o app Fechadura Digital
            </li>
            <li style={{ marginBottom: "10px" }}>
              Esteja próximo da fechadura do Imóvel.
            </li>
            <li style={{ marginBottom: "10px" }}>
              {isMobile ?
                <span>Clique no botão abaixo e aguarde novas instruções.</span>
                : <span>Acesse o app Imóvel Vazio</span>}
            </li>
          </ol>

          {data?.sellingNotesApp && <Collapse size='small' ghost defaultActiveKey={['more']}>
            <Collapse.Panel header={<b style={{
              fontWeight: 'bolder',
              padding: '0px',
            }}>Mais Instruções</b>} key="more"
              style={{
                height: "20%",
                fontSize: isMobile ? 13 : 16,
                overflowY: "auto",
                color: "#333",
                marginTop: "10px",
              }}
            >
              {data?.sellingNotesApp}
            </Collapse.Panel>
          </Collapse>}

          <DigitalLockLinkButton lockId={lockId} />
          <InstallApp />

        </>
      ) : (
        <div
          style={{
            marginTop: isMobile ? "20px" : "30px",
            marginBottom: "30px",
          }}
        >
          <h4
            style={{
              width: "100%",
              fontSize: isMobile ? 18 : 23,
            }}
          >
            Instruções
          </h4>

          <div
            style={{
              height: "20%",
              fontSize: isMobile ? 13 : 16,
              overflowY: "auto",
              color: "#333",
              marginTop: "10px",
            }}
            ref={sellingNotes}
          >
            {data && data.sellingNotes
              ? data?.sellingNotes
              : "Sem instruções no momento."}
          </div>
        </div>
      )}

    </div>
  )
};

export default InstructionDetails;