import React from "react";
import * as S from "./style";
import "../../../global.css";

export default function ModalVideo({setShowModal}) {

  return (
    <>
    <S.VideoContainer >
       <span className="close" onClick={() => setShowModal(false)}>&#10006;</span>
       <video src="https://files.visualizarimovel.com.br/media/WelcomeLP.mp4" 
       autoPlay controls></video>
    </S.VideoContainer>
    </>
  );
}
