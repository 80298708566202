import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { revokeToken } from "../../api.js";
import { Container } from "./styles.js";
import * as S from '../header/styles.js'
import { Popconfirm } from "antd";
import api from "../../api";
import { Dropdown, Avatar, Space } from "antd";
import { DownOutlined, DownCircleTwoTone } from '@ant-design/icons';
import { getInitialsName } from "../../utils/getInitialsName.js";
import { getUserId, getUserSession } from "../../utils/sessionHelper/index.js";
import { getUserName } from "../../utils/getUserName.js";


export default function Header({ show = true }) {
  const [mobile, setMobile] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const userSessionProfile = getUserSession();
  const userId = getUserId();

  const CONFIRM_CONTEXT = {
    default: '',
    exit: 'Sair'
  };

  const [confirmLoading, setConfirmLoading] = useState(false);
  const defaultOpenConfirm = { state: false, id: 0, context: CONFIRM_CONTEXT.default };
  const [openConfirm, setOpenConfirm] = useState(defaultOpenConfirm);
  const isOpen = (id, context) => {
    return openConfirm.state && openConfirm.context === context && openConfirm.id === id;
  }
  const handlerOpenConfirm = (state = defaultOpenConfirm.state, id = defaultOpenConfirm.id, context = defaultOpenConfirm.context) => {
    setOpenConfirm({
      state,
      id,
      context,
    })
  }

  const onConfirm = async (callback) => {
    try {
      setConfirmLoading(true);
      await callback();
    } finally {
      setConfirmLoading(false);
      handlerOpenConfirm(false);
    }
  };

  async function handleDropdownItemClick(e) {
    switch (e) {
      case 0:
        window.location.href = "/";
        break
      case 1:
        window.location.href = "/admin/meu-perfil";
        break
      case 2:
        revokeToken();
        break
      default:
        console.error(`Error não tem opção`);
    }
  };

  async function openMyProfile() {
    // setLoading(true)
    if (userId) {
      localStorage.setItem("id_painel", userId);
      let { data } = await api.get(
        "/user/" + userId
      );
      localStorage.setItem("type_user", data[0].type);
      localStorage.setItem("permission", data[0].permission);
      window.location.href = "/admin/home-corretor";
    } else {
      window.location.href = "/login";
    }

    // setLoading(false);
  }

  useEffect(() => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      setMobile(true);
    }

  }, []);

  return (
    <Container>
      <header style={{ position: mobile ? "unset" : "fixed" }}>

        <Link
          to={window.location.pathname}
          className="logo"
          style={{ cursor: "default" }}
        >
          {/*to="/"*/}
          <img src={require("../../assets/logo.svg")} alt="" />
        </Link>
        {show ? (
          <div className="links">
            {window.innerWidth < 600 &&
              <img
                onClick={() => {
                  let e = document.getElementById("sidebar-4");
                  e.style.display = "flex";
                }}
                src={require("./menu.svg")}
                alt=""
                style={{
                  cursor: "pointer",
                  paddingLeft: 10,
                  paddingRight: 2
                }}
              />
            }
            {/* <Link to="/contato" className="link" target="_blank">
              <img src={require("../../assets/adminHeader/help.svg")} alt="" />
              <p>Ajuda</p>
            </Link> */}
            {/* <Popconfirm
               placement="top"
               className="size-modal"
               style={{marginTop: window.innerWidth <= 600?15:0}}
               title='Sair'
               description='Tem certeza que deseja sair?'
               onConfirm={()=> onConfirm(async() => await revokeToken())}
               open={isOpen(1, CONFIRM_CONTEXT.exit)}
               okButtonProps={{
                   loading: confirmLoading,
               }}
               onCancel={()=>handlerOpenConfirm()}
               okText="Sim"
               cancelText="Não"
               >                            
                <button
                  onClick={()=>handlerOpenConfirm(true, 1, CONFIRM_CONTEXT.exit)}
                  className="link"
                >
                  <img
                    src={require("../../assets/adminHeader/logout.svg")}
                    alt=""
                  />
                  <p>Sair</p>
                </button>
            </Popconfirm>     */}
            <div className="container">
              <div className="user-hamburger">
                <S.UserLogin admin={true} style={{ paddingRight: 20 }}>
                  {window.innerWidth >= 800 ? (
                    <S.UserAvatar>
                      <Avatar size="large" className="user-avatar">
                        {getInitialsName(getUserName(userSessionProfile.name))}
                      </Avatar>
                    </S.UserAvatar>
                  ) : (
                    <div>
                      <S.UserAvatar>
                        <Avatar size="large" className="user-avatar">
                          {getInitialsName(getUserName(userSessionProfile.name))}
                        </Avatar>
                        <DownCircleTwoTone
                          className="icon-outlined-down"
                          onClick={() => setShowOptions((showModal) => !showModal)}
                        />
                      </S.UserAvatar>
                    </div>)
                  }
                  {window.innerWidth >= 800 &&
                    <S.UserLabel>
                      <a onClick={(e) => handleDropdownItemClick(e)}>
                        <span className="user-name"> {getUserName(userSessionProfile.name)} </span>
                      </a>
                    </S.UserLabel>
                  }
                  <div
                    className="dropdown"
                    onClick={() => setShowOptions((showModal) => !showModal)}
                  >
                    {window.innerWidth >= 800 &&
                      <DownOutlined className="icon-down dropbtn" />
                    }
                    {showOptions &&
                      <div className="dropdown-content">
                        {window.innerWidth <= 800 &&
                          <>
                            <div className="container-user-name">
                              <span className="user-name"> {getUserName(userSessionProfile.name)} </span>
                            </div>
                            <S.Divider />
                          </>
                        }
                        <a className="dropdown-item"
                          onClick={() => handleDropdownItemClick(0)}>Visitar Site</a>
                        {userSessionProfile.type === "agent" &&
                          <a className="dropdown-item"
                            onClick={() => handleDropdownItemClick(1)}>Editar Perfil</a>
                        }
                        <a className="dropdown-item"
                          onClick={() => handleDropdownItemClick(2)}>Sair</a>
                      </div>
                    }
                  </div>
                </S.UserLogin>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </header>
    </Container>
  );
}
