import React from "react";
import * as S from "./style";
import logoUsabit from '../../../assets/LP_MG/FooterLP/logoUsabit.svg'
import "../../../global.css";

export default function FooterLP() {

  return (
    <>
    <S.Footer>
      <a href="https://linktr.ee/usabitdigital" target="_blank">
        <img src={logoUsabit} />
      </a>
    </S.Footer>
    </>
  );
}
