import styled from "styled-components";

export const Container = styled.div`
  padding-top: 65px;
  height: 479px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  form {
    padding-right: 30px;
  }
`

export const Title = styled.span`
  color: #3B414A;
  font-family: 'Roboto';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 30px;
`

export const SubTitle = styled.span`
  color: #3B414A;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 30px;
  padding-bottom: 15px;
`

export const ContentField = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;

  .content-middle {
    width: 50%;
  }

  .content-larger {
    width: 100%;
  }
`

export const Label = styled.label`
  color: #B3B4BA;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 0 !important;
`

export const Field = styled.input`
  display: flex;
  padding: 10px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  width: 100%;

  color: #8C8C8C;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`