import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/Footer'
import { Body, ImageWrapper, TextWrapper } from './styles'

export default function Site() {
    return (
        <div>
            <Header />
            <Body>
                <ImageWrapper>
                    <TextWrapper>
                        <h1>Termos e Condições de Uso Imóvel Vazio</h1>
                    </TextWrapper>
                </ImageWrapper>
                <div className="text">
                    <p style={{textAlign: 'start'}}>
                    A Arg Investimentos Imobiliários, sociedade limitada, inscrita no CNPJ sob no 08.254.011/0001-00, com sede na Avenida Nossa Senhora de Copacabana, nº 861, complemento 408, em Copacabana, na cidade do Rio de Janeiro - Rio de Janeiro, CEP: 22.060-900 (“Imóvel Vazio”), apresenta os Termos de Uso do seu site imovelvazio.com.br.

                    A Imóvel Vazio é uma plataforma digital que usa a tecnologia para simplificar a compra e venda de imóveis residenciais, sempre com o auxílio de Corretor, de acordo com as seguintes modalidades:
                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        Venda de Imóveis:
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        O Usuário, proprietário de imóvel residencial, realiza o cadastro de imóvel de sua propriedade no Site. Após a avaliação do consultor, e caso seja de interesse da Imóvel Vazio, o Usuário receberá uma proposta de aquisição, que será por escrito, detalhada e justificada. Caso a proposta da Imóvel Vazio seja aprovada pelo Usuário, este fornecerá todos os documentos necessários e/ou os demais documentos solicitados pela Imóvel Vazio e, caso esta documentação seja aprovada, o Usuário e a Imóvel Vazio assinarão a escritura de compra e venda, com pagamento integral do preço do imóvel;
                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        Intermediação de Imóveis - Venda:
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        O Corretor indica um imóvel para a Imóvel Vazio, com a autorização prévia do seu cliente. Com base na avaliação do consultor da Imóvel Vazio, o Corretor receberá uma proposta da Imóvel Vazio, que deverá ser repassada ao seu cliente. Caso a proposta da Imóvel Vazio seja aprovada pelo cliente do Corretor, o cliente deverá fornecer todos os documentos necessários e/ou os demais documentos solicitados pela Imóvel Vazio e, caso esta documentação seja aprovada, o cliente do Corretor e a Imóvel Vazio assinarão a escritura de compra e venda, com pagamento integral do preço do imóvel e, consequentemente, pagamento da comissão ao Corretor. Os demais termos e condições da intermediação de imóveis estão detalhadamente descritos na Política do Corretor.
                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        Intermediação de Imóveis - Compra:
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        O Corretor indica um imóvel cadastrado no Site para aquisição de seu cliente. Caso o cliente do Corretor tenha interesse na aquisição do imóvel, o cliente deverá fornecer todos os documentos necessários e/ou os demais documentos solicitados pela Imóvel Vazio e, caso esta documentação seja aprovada, o cliente do Corretor e a Imóvel Vazio assinarão a escritura de compra e venda, com pagamento integral do preço do imóvel e, consequentemente, pagamento da comissão ao Corretor. Os demais termos e condições da intermediação de imóveis estão detalhadamente descritos na Política do Corretor.
                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        Financiamento de Imóveis:
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        O Usuário, interessado em financiar a compra de um imóvel cadastrado no Site, utiliza as funcionalidades do Site para buscar instituições financeiras interessadas em financiar a compra do imóvel. Caso o crédito do Usuário seja aprovado pelas instituições financeiras, a instituição financeira escolhida pelo Usuário efetuará o pagamento do preço, total ou parcial, do imóvel cadastrado no Site, adquirido pelo Usuário. As condições de crédito e os documentos necessários para aprovação do financiamento do imóvel serão definidas pela instituição financeira e informados por esta ao usuário na ocasião.
                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        Compra de Imóveis por um Usuário do Site:
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        O Usuário, que deseja adquirir um imóvel residencial cadastrado no Site, realiza o seu cadastro no Site. Em seguida, um dos  corretores cadastrados no site acompanha o Usuário em uma visita ao imóvel e havendo o interesse de compra, é dado início ao processo de compra cuja  intermediação é feita através deste Corretor, incluindo a verificação de documentos necessários e orientação quanto ao financiamento do imóvel.

                        Estes Termos de Uso estabelece as condições de utilização do Site e dos serviços nele oferecidos ao Usuário. Para poder usar o Site, você deverá ler, compreender e aceitar estes Termos de Uso. A forma como coletamos, armazenamos e tratamos os dados dos Usuários, bem como para quais finalidades eles poderão ser utilizados, estão descritas na Política de Privacidade da Imóvel Vazio.

                        Para facilitar a leitura, usamos certas palavras/termos com a primeira letra em maiúsculo. Sempre que isso ocorrer nos Termos de Uso e na Política de Privacidade, você deve entender que essa palavra/termo tem o significado indicado abaixo:

                        “Conteúdo” significa toda e qualquer informação disponibilizada pelo ou por meio do Site, tais como textos, dados, software, imagens, vídeos, áudios, recursos interativos, incluindo os códigos fonte empregados para exibição desses conteúdos, como aqueles em linguagem HTML, CSS, PHP, entre outros.

                        “Dados Pessoais” significa quaisquer dados do Usuário que, de alguma forma, permitam sua identificação, tais como, mas não se limitando a, nome completo,CPF, RG endereço, número de telefone e endereço de email.

                        “Política de Privacidade” significa a política de privacidade que rege as disposições sobre a utilização dos dados do Usuário pela Imóvel Vazio.

                        “Site” significa o endereço eletrônico imovelvazio.com.br  ou qualquer outro que venha a substituí-lo.

                        “Software” significa o sistema de propriedade exclusiva da Imóvel Vazio por meio do qual serão copiados os Dados do Usuário, bem como geridos e manejados todos os dados coletados de forma automatizada.

                        “Tratamento” significa toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.

                        “Usuário” significa uma pessoa física, com plena capacidade de contratar, que acessa o Site e realiza o seu cadastro pessoal para utilizar os serviços oferecidos pela Imóvel Vazio, aceitando expressamente os Termos de Uso e a Política de Privacidade.
                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        Aceitação aos Termos de Uso
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        Ao acessar e/ou usar o Site da Imóvel Vazio ou o seu Conteúdo, bem como os Serviços nele oferecidos, o Usuário concorda, de forma livre, expressa, informada e sem quaisquer reservas, com todas as cláusulas e condições dos Termos de Uso.
                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        Condições gerais de uso
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        O Usuário será responsável pela veracidade, validade e precisão dos dados por ele fornecidos à Imóvel Vazio, inclusive no seu cadastro, devendo mantê-los sempre atualizados. Ao aceitar estes Termos de Uso, o Usuário tem o direito não exclusivo, intransferível, não sub-licenciável e limitado de entrar, acessar e usar os Serviços, unicamente para uso como usuário final, sendo expressamente vedado o uso para fins comerciais.

                        Todos os direitos não previstos expressamente nestes Termos de Uso estão reservados à Imóvel Vazio e/ou às suas controladoras.

                        Além da licença limitada de uso estabelecida nestes Termos de Uso, o Usuário não tem qualquer outro direito, título ou propriedade sobre o Site e/ou os Serviços, inclusive quaisquer direitos de cópia ou reprodução. O Usuário entende e reconhece que, em quaisquer circunstâncias, os seus direitos com relação ao Site e/ou aos Serviços serão limitados pelos direitos autorais e/ou de propriedade intelectual aplicáveis, bem como por estes Termos de Uso.

                        O Usuário está ciente que a Imóvel Vazio não aprova conteúdo do Usuário e/ou de terceiros, que são inseridos pelo Usuário no Site e, portanto, a Imóvel Vazio poderá, a seu exclusivo critério, apagar ou modificar o conteúdo inserido pelo Usuário no Site.

                        A Imóvel Vazio poderá modificar os Serviços e/ou o site, inclusive o seu Conteúdo, ou descontinuar a sua disponibilidade a qualquer momento, sem a necessidade de qualquer aviso prévio.

                        O Usuário é o único responsável pela obtenção, pagamento e manutenção de todos os serviços de telefonia, acesso à internet, plano de dados, tarifas e/ou outras taxas, mensalidades e custos associados ao seu acesso para a rede mundial de computadores, para o uso do site e/ou dos Serviços, bem como pelo software, hardware de computador e outros equipamentos necessários para o uso e acesso ao Site e/ou aos Serviços.
                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        O Usuário não poderá:
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        I. Utilizar o Site para divulgar informações de qualquer forma possa implicar em violação de:
                        a) normas aplicáveis a si, à Imóvel Vazio ou a terceiros;
                        b) direitos de propriedade da Imóvel Vazio e/ou de terceiros;
                        c) quaisquer direitos de terceiros, como confidencialidade, honra, privacidade, imagem, ou outros direitos pessoais;
                        d) da moral e dos bons costumes, incluindo, sem limitação, a violação de direitos intelectuais, autorais e de privacidade, ou a produção e divulgação de conteúdo ilegal, imoral, inapropriado ou ofensivo, à exclusivo critério da Imóvel Vazio;
                        II. Reproduzir, copiar, ceder, modificar, realizar engenharia reversa, compilar, descompilar, transmitir, publicar, sublicenciar, alugar, vender, comercializar, distribuir, ou, de qualquer outra forma, utilizar qualquer funcionalidade, Conteúdo, Software ou material disponibilizado por meio do Site de forma não permitida nestes Termos de Uso ou na Política de Privacidade;
                        III. Empregar softwares, técnicas e/ou artifícios com o intuito de utilizar indevidamente o
                        Site e/ou o Software para práticas nocivas à Imóvel Vazio ou a terceiros, inclusive qualquer
                        mecanismo para impedir ou tentar impedir o funcionamento adequado do Site ou qualquer atividade que nele esteja sendo realizada;
                        IV. Publicar ou transmitir qualquer arquivo que contenha malwares, tais como vírus, cavalos de tróia, spyware, adware, phishing, worms, rootkits, ransomware, botnets, sequestradores de navegador ou qualquer outro programa contaminante ou destrutivo, ou que de outra forma possa interferir no bom funcionamento do Site;
                        V. Utilizar o Site para finalidade diversa daquela para a qual foi disponibilizado pela Imóvel Vazio;
                        VI. Efetuar o cadastro no Site, utilizando-se de informações falsas ou de propriedade de
                        terceiros;
                        VII. Divulgar, utilizar ou modificar indevidamente os dados de outros Usuários.

                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        Propriedade intelectual
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        O Usuário não adquire, por meio destes Termos de Uso ou da Política de Privacidade, qualquer direito de propriedade intelectual, ou outros direitos exclusivos, incluindo patentes, desenhos, bases de dados, marcas, direitos autorais, direitos sobre informações confidenciais ou segredos de negócio, sobre ou relacionados ao Site, os quais são de propriedade exclusiva da Imóvel Vazio.


                        O Usuário poderá manter uma cópia das informações, planilhas e gráficos disponibilizados por meio do Site, desde que para uso pessoal e não comercializável. Qualquer utilização deste material e/ou do Conteúdo do Site, no todo ou em parte, que não para uso pessoal do Usuário, conforme estes Termos de Uso, sem que haja prévio consentimento por escrito da Imóvel Vazio, é expressamente proibida.


                        Todo e qualquer Conteúdo disponibilizado no Site, tais como, mas não se limitando a, textos, gráficos, imagens, logos, ícones, conteúdo editorial, notificações, softwares e qualquer outro material, pertencem exclusivamente a Imóvel Vazio e são protegidos pela legislação brasileira, especialmente no que se refere à propriedade intelectual e aos direitos autorais.


                        A Imóvel Vazio reserva-se o direito de tomar as medidas que julgar apropriadas, a seu exclusivo critério, a fim de proteger os direitos autorais do Site, seu Conteúdo e dos Serviços, além das condições previstas nestes Termos de Uso.

                        O Usuário não está autorizado a utilizar nenhuma marca e/ou sinais distintivos encontrados no Site, no Conteúdo e/ou nos Serviços da Imóvel Vazio, não podendo copiar, exibir ou usar nenhuma das marcas comerciais da Imóvel Vazio ou de suas controladoras sem consentimento prévio por escrito do respectivo proprietário. Qualquer uso não autorizado será considerado uma violação às leis de propriedade industrial, leis de privacidade, de propriedade intelectual e quaisquer outras legislações vigentes, conforme o caso.


                        Todos os direitos e licenças não concedidos expressamente nestes Termos de Uso são reservados aos proprietários dos Conteúdos e/ou Serviços. Estes Termos de Uso não concedem quaisquer licenças implícitas ou autorizam, de qualquer forma, a cessão, pelo Usuário, dos direitos ou obrigações neles previstos.

                    </p>

                    <h3 style={{textAlign: 'start'}}>
                        Responsabilidade
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        A Imóvel Vazio se responsabiliza e garante que realiza o Tratamento dos dados pessoais do Usuário de acordo com o previsto na Política de Privacidade, que é parte e indissociável destes Termos de Uso.
                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        A Imóvel Vazio não se responsabiliza:
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        I. Pelos serviços ou produtos oferecidos por parceiros ou quaisquer terceiros, inclusive no que diz respeito a sua disponibilidade, qualidade, quantidade, características essenciais, ofertas, preços, validade da oferta, formas de pagamento ou quaisquer outros elementos a eles referentes;
                        II. Por eventuais prejuízos sofridos pelos Usuários em razão da tomada de decisões com base no Conteúdo ou nas informações disponibilizadas no Site;
                        III. Por falhas no sistema de informática, nos servidores ou na conectividade com a internet de modo geral, não garantindo a disponibilidade, a continuidade e infalibilidade do funcionamento do Site e/ou dos Serviços disponibilizados; e
                        IV. Por danos causados por hackers e/ou programas/códigos nocivos ao Software, tais como, mas sem se limitar a vírus, worms, ataques de negação, cavalos de tróia, spyware, adware, phishing, rootkits, ransomware, botnets, sequestradores de navegador ou qualquer outro programa contaminante ou destrutivo.


                        Em nenhum caso, a Imóvel Vazio será responsável por danos diretos ou indiretos sofridos pelo Usuário, incluindo, sem limitação, prejuízos por perda de lucro, falha de transmissão ou recepção de dados, lucros cessantes, não continuidade do negócio ou qualquer outra perda comercial, decorrentes ou relacionados ao uso do Site ou aos Serviços, tampouco com relação à incapacidade e/ou impossibilidade de usá-lo.

                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        A Imóvel Vazio não garante que:
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        (i) as funções contidas no Site atendem às necessidades do Usuário;
                        (ii) a operação do Site será ininterrupta ou livre de erros;
                        (iii) qualquer funcionalidade continuará disponível;
                        (iv) os defeitos no Site serão corrigidos;
                        (v) o Site será compatível ou funcionará com qualquer software, aplicações ou serviços de terceiros.

                        A Imóvel Vazio fará todo o possível para manter os dados coletados sempre seguros, inclusive adotando medidas de segurança e de proteção compatíveis com a natureza dos dados coletados, usados e armazenados, conforme previsto na Política de Privacidade.


                        No entanto, a Imóvel Vazio não pode garantir que tais medidas de segurança sejam isentas de erros ou que não estejam sujeitas a interferência de terceiros, como hackers, entre outros.

                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        Indenização
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        O Usuário concorda em defender, indenizar e manter indene a Imóvel Vazio, seus diretores, sócios, agentes, funcionários, consultores e afiliados contra toda e qualquer reclamação, perda, dano, responsabilização, julgamento, avaliação, multa, custo e/ou outras despesas, incluindo honorários advocatícios, resultantes ou relacionados ao uso que o Usuário faça do Site, do Conteúdo ou dos Serviços, inclusive por qualquer violação dos Termos de Uso, suas Informações, violação de qualquer lei, estatuto, ordem ou regulamento, ou direitos de terceiros, e concorda em reembolsar a Imóvel Vazio por quaisquer perdas, custos e despesas que ela vier a incorrer.
                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        Contato
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        A Imóvel Vazio poderá contatar o Usuário por meio do seu número de telefone, whatsapp e/ou e-mail, sempre que necessário, para tratar de assuntos relacionados ao Site, seu Conteúdo ou aos Serviços, para fazer comunicações de caráter comercial, como, por exemplo, para oferecer imóveis, recomendar produtos, ou ainda realizar pesquisas em relação a novas funcionalidades e à experiência do Usuário ao utilizar a Imóvel Vazio.


                        Caso deseje não ser contatado para receber esse tipo de conteúdo, o Usuário poderá solicitar, a qualquer momento, o descadastramento de seu e-mail e/ou seu número de telefone, clicando no link indicado no e-mail recebido. O descadastramento do e-mail do Usuário e/ou do seu número de telefone da base de dados não afetará o uso do Site pelo Usuário.
                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        Conteúdo de terceiros
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        A Imóvel Vazio, diretamente ou por meio de terceiros, poderá publicar no Site links para outros websites. Por não ter controle sobre tais websites, o Usuário entende e concorda que a Imóvel Vazio não é e nem será no futuro responsável pela disponibilidade de tais websites, e não se responsabiliza nem é passível de ser responsabilizada por qualquer conteúdo, publicidade, produto ou outro material disponível neste ou através deste website.


                        O Usuário também entende e concorda que a Imóvel Vazio não será responsável nem poderá ser responsabilizada, direta ou indiretamente, por quaisquer perdas e danos causadas ou alegadas de terem sido causadas ou relacionadas ao uso de qualquer conteúdo, bem ou serviço disponível no Site ou através do uso de qualquer um desses websites, recursos e/ou serviços.

                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        Disposições Gerais
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        A Imóvel Vazio reserva-se no direito de suspender ou cancelar o cadastro de qualquer de Usuário que, a seu exclusivo critério, não cumpra as condições previstas nestes Termos de Uso ou quaisquer normas ou políticas vigentes da Imóvel Vazio, independentemente de qualquer notificação ou aviso prévio nesse sentido, e sem que ao Usuário seja devido o pagamento de quaisquer indenizações ou ressarcimentos.


                        A Imóvel Vazio se reserva o direito de alterar ou modificar estes Termos de Uso ao seu exclusivo critério, a qualquer momento. O Usuário é responsável por analisar periodicamente a mais atualizada versão destes Termos de Uso. Qualquer alteração ou modificação a estes Termos de Uso entrará em vigor imediatamente após a publicação em nosso Site.


                        O acesso e o uso do Site, seu Conteúdo e dos Serviços constitui a aceitação pelo Usuário de quaisquer alterações ou modificações feitas nos Termos de Uso.


                        Estes Termos de Uso, juntamente com a Política de Privacidade publicada no nosso Site, constituem o acordo integral entre as Partes, e substituem todos os acordos anteriores, escritos ou verbais.


                        As Partes são contratantes independentes, não resultando este instrumento na criação de qualquer sociedade, franquia, representação de vendas ou relações que não as expressamente previstas nestes Termos de Uso.


                        Caso qualquer uma das cláusulas e condições destes Termos de Uso venha a ser declarada nula, no todo ou em parte, por qualquer motivo legal ou contratual, as demais cláusulas continuarão em pleno vigor e efeito.


                        A tolerância ou o não exercício, por qualquer das Partes, de quaisquer direitos a ela assegurados nestes Termos de Uso ou na lei em geral não importará em novação ou em renúncia a qualquer desses direitos, podendo a referida Parte exercê-los durante a vigência destes Termos de Uso.

                        Fica eleito o foro da Comarca do Rio de Janeiro, com exclusão de qualquer outro, por mais privilegiado que seja, para dirimir quaisquer litígios ou controvérsias oriundos dos presentes Termos de Uso.
                    </p>
                </div>
            </Body>
            <Footer />
        </div>
    )
}
