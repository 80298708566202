import React, { useState } from "react";
import "../../global.css";
import * as S from "./styles";
import CloseCircle from "../../assets/close-circle.svg";
import Select from "react-select";
import api from "../../api";
import Slider from "@material-ui/core/Slider";
import ListCheckbox from "../../pages/PropertiesList/ListCheckbox";
import { useEffect } from "react";
import { getCitiesAsync } from "../../services/city.service";

export default function SideFilter({
  children,
  handleClearFilter,
  handleFilter,
  closeModalFilter,
}) {
  return (
    <>
      <S.ContainerFilter>
        <S.ContentFilter>
          <S.ContentFilterTitle>
            <span className="title-filter">Filtros por Imóveis</span>
            <img
              src={CloseCircle}
              className="icon-close"
              onClick={closeModalFilter}
            />
          </S.ContentFilterTitle>
          <S.ContentFilterBody>{children}</S.ContentFilterBody>
          <S.Footer>
            <S.CleanFiltersButton
              onClick={handleClearFilter}
              id="btn-personal-reverse"
            >
              <span className="clean-filters-button--title">
                Remover Filtros
              </span>
            </S.CleanFiltersButton>
            <S.FilterButton onClick={handleFilter} id="btn-personal">
              <span className="filters-button--title">Filtrar Imóveis</span>
            </S.FilterButton>
          </S.Footer>
        </S.ContentFilter>
      </S.ContainerFilter>
    </>
  );
}
