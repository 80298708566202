import React from "react";
import Header from '../../components/header'
import { BgImg, FilterContainer } from './styles'

const SucessPage = () => {
    return (
        <>
            {/* <BgImg hgt="100px" bs="3px 3px 10px rgb(0,0,0,0.3)">
            </BgImg> */}

            <Header />

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", marginTop: "10%" }}>
                <div className="login-wrapper">
                    <div className="info-box"></div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 50, flexDirection: "column" }}>
                        <img src={require('./icons/success.svg')} style={{ width: 200, height: 200 }} />
                        <h2 style={{ display: "flex", alignItems: "center", textAlign: "center", margin: 10 }}>
                            A equipe Imóvel Vazio agradece sua oferta. <br />
                            Iremos avaliar e enviar a nossa proposta de compra. <br />
                            Aguarde nosso retorno em até 48 horas.
                        </h2>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SucessPage;