import React from 'react'
import { ContentPartnerProperties } from '../../../pages/AdminOfferProperty/styles'

import { Switch } from 'antd'

export default function SwitchPartnerProperties({ onChangePartnerProperty, checkedPartnerProperty }) {

return(
  <ContentPartnerProperties>
    <label class="title-partner-properties" for="partnerProperties">Pertence a um Parceiro Imóvel Vazio?</label>
    <Switch checked={checkedPartnerProperty} onChange={onChangePartnerProperty} />
  </ContentPartnerProperties>
)
}