// Core
import React from "react";

// Components
import { Spin } from "antd";
import { Container } from "./styles";
import ivLogo from "../../assets/logo2.png";

const Loading = React.memo(function Loading() {
  return (
    <Container>
      <img src={ivLogo} alt="Logotipo" />
      <label className="text">Carregando informações...</label>
      <Spin size="large" />
    </Container>
  );
});

export default Loading;
