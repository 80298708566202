import styled from 'styled-components'

export const VideoContainer = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 99999;
   background-color: rgba(0, 0, 0, 0.7);
   display: flex;
   justify-content: center;
   align-items: center;
   opacity: 1;
   pointer-events: all;
   transition: all 0.3s;

   .close {
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
   }

   video {
    width: 90%;
    max-width: 800px;
    transform: scale(1);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
    outline: none;
    transition: all 0.3s;
   }
`