import React, { useEffect, useMemo, useState } from "react";
import { InputNumber } from "antd";
import { Link } from "react-router-dom";
import api from "../../api";
import Carousel from "../../components/carousel/index";
import Footer from "../../components/Footer";
import Header from "../../components/header";
import "../../global.css";
import {
  Announcement, Bank, BgImg, BtnContainer, FilterContainer, MarketingContainer,
  PropertyOffer, SellingProperties
} from "./styles";
import { getCitiesAsync } from "../../services/city.service";
import { getUserId } from "../../utils/sessionHelper";


export default function Home() {
  const userId = getUserId();

  const [cities, setCities] = useState([]);
  const [neighborhoodList, setNeighborhoodList] = useState([]);
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [city, setCity] = useState(0);
  const [neighborhood, setNeighborhood] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [priceMin, setPriceMin] = useState(0)
  const [priceMax, setPriceMax] = useState()

  useMemo(() => {
    handleLoad();
  }, []);

  async function handleLoad() {

    if (userId) {
      api.get(`/user/access/${userId}`);
    }

    getCitiesAsync({ orderBy: 'city', direction: 'ASC', showCatalog: true, arquive: false, sold: false }).then((res) => {
      let options = new Array();
      res.data.map((item) => {
        options.push({ value: item.value, name: `${item.city}, ${item.uf}` });
      });
      setCities(options);
    });

    api.get("/propertyType/").then((res) => {
      let tipos = new Array();
      res.data.propertyTypes.map((item) => {
        tipos.push({ value: item._id, name: item.name });
      });
      setPropertyTypeList(tipos);
    });
  }

  useEffect(() => {
    if (city > 0)
      api.get(`/neighborhood?city=${city}`).then((res) => {
        let bairros = new Array();
        res.data.neighborhoods.map((item) => {
          bairros.push({ value: item._id, name: item.name });
        });
        setNeighborhoodList(bairros);
      });
  }, [city]);

  useEffect(() => {
    window.scrollTo({
      top: 0
    })
  }, [])

  async function handleSend() {
    setCity(0);
    setNeighborhood('');
    const queryBuilder = [];
    if (city) queryBuilder.push(`city_id=${city}`);
    if (neighborhood) queryBuilder.push(`neighborhood_id=${neighborhood}`);
    if (propertyType) queryBuilder.push(`generalTypes=${propertyType}`);
    if (priceMin) queryBuilder.push(`sellingPriceMin=${priceMin}`);
    if (priceMax) queryBuilder.push(`sellingPrice=${priceMax}`);
    if (priceMin || priceMax) queryBuilder.push(`no_selling=true`);
    const queryString = queryBuilder.length ? `/imoveis?${queryBuilder.join('&')}` : '/imoveis';
    window.location.href = queryString;
  }

  return (
    <>
      <BgImg>
        <Header />
        <FilterContainer>
          <div className="text-wrapper">
            <h1>
              O imóvel ideal está a {window.innerWidth <= 600 ? "" : <br />}
              poucos cliques de você
            </h1>
          </div>
          <div className="filter-wrapper">
            <div className="input-wrapper">
              <small>Cidade</small>
              <select
                onChange={(value) => setCity(value.target.value)}
              >
                <option defaultValue={0}>Selecione</option>
                {cities.map((item) => (
                  <option key={item.value} value={item.value}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="input-wrapper">
              <small>Bairro</small>
              <select
                onChange={(value) => setNeighborhood(value.target.value)}
              >
                {neighborhoodList.length && <option>Selecione</option>}
                {neighborhoodList.map((item) => (
                  <option key={item.value} value={item.value}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="input-wrapper">
              <small>Tipo</small>
              <select onChange={(value) => setPropertyType(value.target.value)}>
                <option>Selecione</option>
                {propertyTypeList.map((item) => (
                  <option key={item.value} value={item.value}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="input-wrapper">
              <label>Preço de</label><br />
              <InputNumber
                style={{ width: '100%', }}
                bordered={false}
                decimalSeparator={','}
                size="large"
                min={0}
                value={priceMin}
                precision={0}
                formatter={(value) => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                parser={(value) => value.replace(/\D/g, '')}
                onChange={(value) => setPriceMin(value)}
              />
            </div>
            <div className="input-wrapper">
              <label>Até</label>
              <InputNumber
                style={{ width: '100%' }}
                bordered={false}
                decimalSeparator={','}
                size="large"
                value={priceMax}
                min={priceMin}
                precision={0}
                formatter={(value) => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                parser={(value) => value.replace(/\D/g, '')}
                onChange={(value) => setPriceMax(value)}
              />
            </div>
            <Link
              to={window.location.pathname}
              className="searchBtn"
              onClick={handleSend}
            >
              <img src={require("../../assets/search.svg")} alt="" />
              <p>Buscar</p>
            </Link>
          </div>
        </FilterContainer>
      </BgImg>

      <Announcement className="announcement">
        <div>
          <h2>Quer vender um imóvel?</h2>
          <h2
            style={{ cursor: "pointer" }}
            onClick={(e) =>
            (window.location.href = `/ofertar-imovel${new URLSearchParams(window.location.search).has("app")
              ? "?app"
              : ""
              }`)
            }
          >
            A Imóvel Vazio pode comprar o seu imóvel
          </h2>
        </div>
      </Announcement>

      <SellingProperties>
        <h1 className="title">Imóveis à venda</h1>
        <p>Amplie o seu portfólio de imóveis e acelere as suas vendas</p>
        <Carousel className="carousel" />

        <BtnContainer style={{ marginTop: -5 }}>
          <Link
            className="verTodos"
            to={`/imoveis${new URLSearchParams(window.location.search).has("app")
              ? "?app"
              : ""
              }`}
          >
            Ver todos os imóveis que Corretores podem vender
          </Link>
        </BtnContainer>
      </SellingProperties>

      <MarketingContainer>
        <h1 style={{ fontSize: 36 }}>
          Por que a Imóvel Vazio é a melhor opção para corretores?
        </h1>
        <p>Saiba porque somos pioneiros no mercado</p>

        <section className="images">
          <img
            style={{ width: window.innerWidth <= 600 ? "inherit" : "" }}
            src={require("../../assets/home/aboutUs.svg")}
            alt="A Imóvel Vazio é um grupo de investimento imobiliário que atua há mais de 10 anos no mercado. Possuímos imóveis em diferentes bairros do Estado do Rio de Janeiro, disponíveis para venda."
          />
          <img
            style={{ width: window.innerWidth <= 600 ? "inherit" : "" }}
            src={require("../../assets/home/ourProperties.svg")}
            alt="Todos os  nossos imóveis são legalizados, com possibilidade de financiamento pela rede bancária. Em sua grande maioria, eles já estão reformados e prontos para morar. E todos eles contam com serviços totalmente gratuitos de despachante e registro, para facilitar o trabalho do corretor.   "
          />
          <img
            style={{ width: window.innerWidth <= 600 ? "inherit" : "" }}
            src={require("../../assets/home/ourPrice.svg")}
            alt="Buscamos sempre ter o melhor preço de mercado e qualidade do imóvel disponibilizado, de forma a ter alta liquidez, e trazendo retorno mais rápido para o corretor que está trabalhando nossos imóveis. Além disso, oferecemos a melhor taxa de corretagem do mercado! "
          />
        </section>
      </MarketingContainer>

      <PropertyOffer>
        <h1>Compramos seu Imóvel</h1>
        <p>
          <strong>Quer vender um imóvel?</strong> A Imóvel Vazio pode comprar o
          seu imóvel
        </p>
        <Link
          onClick={(e) => window.scrollTo(0, 0)}
          to={
            userId
              ? `/ofertar-imovel${new URLSearchParams(window.location.search).has("app")
                ? "?app"
                : ""
              }`
              : `/login${new URLSearchParams(window.location.search).has("app")
                ? "?app"
                : ""
              }`
          }
        >
          Cadastre a sua oferta
        </Link>
      </PropertyOffer>

      <Bank>
        <h1>Correspondente Bancário</h1>
        <p>
          Você precisa de aprovação bancária para o financiamento do seu
          cliente? <br /> Conte com os nossos especialistas e simplifique as
          suas vendas!{" "}
        </p>
        <Link
          onClick={(e) => window.scrollTo(0, 0)}
          to={`correspondente-bancario${new URLSearchParams(window.location.search).has("app") ? "?app" : ""
            }`}
        >
          Clique Aqui
        </Link>
      </Bank>

      <Footer />
    </>
  );
}
