import React, { useState, useEffect } from "react";
import * as S from "../header/styles";
import logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import "../../global.css";
import { getUserSession } from "../../utils/sessionHelper";
import { Dropdown, Avatar } from "antd";
import { DownOutlined, DownCircleTwoTone } from '@ant-design/icons';
import "../../global.css";
import { getUserName } from "../../utils/getUserName";
const LP_URL = process.env.REACT_APP_LP_URL;

export default function HeaderMobile({ propertiesForSaleMenu, getInitials, open, setOpen, itemsMenu,
  handleDropdownItemClick }) {
  const [showOptions, setShowOptions] = useState(false);
  const userSessionProfile = getUserSession();

  return (
    <>
      <S.HeadMobile>
        <div className="container">
          <Link
            onClick={(click) =>
              (document.querySelector("body").style.overflow = "scroll")
            }
            to="/"
          >
            <S.Logo src={logo} />
          </Link>
          <div className="user-hamburger">
            {userSessionProfile !== undefined ? (
              <S.UserLogin>
                {window.innerWidth >= 800 ? (
                  <S.UserAvatar>
                    <Avatar size="large" className="user-avatar">
                      {getInitials(getUserName(userSessionProfile.name))}
                    </Avatar>
                  </S.UserAvatar>
                ) : (
                  <div>
                    <S.UserAvatar>
                      <Avatar size="large" className="user-avatar">
                        {getInitials(getUserName(userSessionProfile.name))}
                      </Avatar>
                      <DownCircleTwoTone
                        className="icon-outlined-down"
                        onClick={() => setShowOptions((showModal) => !showModal)}
                      />
                    </S.UserAvatar>
                  </div>)
                }
                {window.innerWidth >= 800 &&
                  <S.UserLabel>
                    <a onClick={(e) => handleDropdownItemClick(e)}>
                      <span className="user-name"> {getUserName(userSessionProfile.name)} </span>
                    </a>
                  </S.UserLabel>
                }
                <div
                  className="dropdown"
                  onClick={() => setShowOptions((showModal) => !showModal)}
                >
                  {window.innerWidth >= 800 &&
                    <DownOutlined className="icon-down dropbtn" />
                  }
                  {showOptions &&
                    <div className="dropdown-content">
                      <div className="container-user-name">
                        <span className="user-name"> {getUserName(userSessionProfile.name)} </span>
                      </div>
                      <S.Divider />
                      <a className="dropdown-item"
                        onClick={() => handleDropdownItemClick(0)} >Minha Conta</a>
                      {userSessionProfile.type === "agent" &&
                        <a className="dropdown-item"
                          onClick={() => handleDropdownItemClick(1)} >Editar Perfil</a>
                      }
                      <a className="dropdown-item"
                        onClick={() => handleDropdownItemClick(2)} >Sair</a>
                    </div>
                  }
                </div>
              </S.UserLogin>
            ) : (
              <S.EnterMyProfile>
                <Link
                  onClick={(click) =>
                    (document.querySelector("body").style.overflow = "scroll")
                  }
                  to="/login"
                >
                  <span>Entrar</span>
                </Link>
              </S.EnterMyProfile>
            )
            }
            <div className="container-hamburger">
              <S.Hamburger onClick={() => setOpen((options) => !options)}>
                <div />
                <div />
                <div />
              </S.Hamburger>
            </div>
          </div>
        </div>
        {open &&
          <S.Links>
            <ul>
              <S.LinkWrapper>
                <S.NewLabel>novo</S.NewLabel>
                <a
                  onClick={(click) =>
                    (document.querySelector("body").style.overflow = "scroll")
                  }
                  href={LP_URL}
                  target="_blank"
                >
                  <span>Residencial Vilares</span>
                </a>
              </S.LinkWrapper>
              <li>
                <Dropdown
                  menu={{
                    items: propertiesForSaleMenu,
                  }}
                >
                  <span>Imóveis à venda</span>
                </Dropdown>
              </li>
              <li>
                <Link
                  onClick={(click) =>
                    (document.querySelector("body").style.overflow = "scroll")
                  }
                  to="/correspondente-bancario"
                >
                  <span>Correspondente</span>
                </Link>
              </li>
              <li>
                <Link
                  onClick={(click) => {
                    document.querySelector("body").style.overflow = "scroll";
                  }}
                  to="/sobre-nos"
                >
                  <span>Sobre nós</span>
                </Link>
              </li>
              <li>
                <div
                  onClick={(click) => {
                    document.querySelector("body").style.overflow = "scroll";
                    window.location.href = "/ofertar-imovel";
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <span> Compramos seu Imóvel </span>
                </div>
              </li>
              <li>
                <div
                  onClick={(click) => {
                    document.querySelector("body").style.overflow = "scroll";
                    window.location.href = "/perguntas-frequentes";
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <span>Ajuda</span>
                </div>
              </li>
            </ul>
          </S.Links>
        }
      </S.HeadMobile>
    </>
  );
}
