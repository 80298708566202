export const Posicao = {
    placeholder: "Selecione",
    list: [{
        name: "Frente",
        value: "Frente"
    }, {
        name: "Lateral",
        value: "Lateral"
    }, {
        name: "Fundos",
        value: "Fundos"
    }],
}

export const Andar = {
    placeholder: "Selecione",
    list: [{
        name: "Andar alto",
        value: "Andar alto"
    }, {
        name: "Andar medio",
        value: "Andar medio"
    }, {
        name: "Andar baixo",
        value: "Andar baixo"
    }],
}