import React, { useEffect, useState } from "react";
import "../../global.css";
import api from "../../api";
import { CommonLoading } from "react-loadingg";
import { getUserId } from "../../utils/sessionHelper";

export default function StepOne({
  setStep,
  step,
  valorProposta,
  valorMercado,
  handleRegister: handleRegisterAsync,
  setValorProposta,
  setValorMercado,
}) {
  const [valorReformulado, setValorReformulado] = useState("");

  const init = async () => {
    if (getUserId()) {
      await handleRegisterAsync();
      setStep(6);
    } else if (step !== 6) {
      setStep(8);
    }
  };
  useEffect(() => {
    init();
  }, []);

  /**
   * Obsolete
   */
  async function handleLoadArquiveXlsx() {
    const { data } = await api.get("/excelValores");
    let response = await api.get("/excel/tolerancia");

    let tolerancia = response.data[0].tolerancia / 100;
    let array = data.arquive;
    let PLimite;
    // Plimite = ((P2 - P1) x (Ax - A1) / (A2 - A1) + P1) x (1 + T%)

    let valorMercadoR = valorMercado
      .replaceAll("R", "")
      .replaceAll("$", "")
      .replaceAll(" ", "")
      .replaceAll(".", "")
      .replaceAll(",", "");

    valorMercadoR =
      valorMercadoR.substr(0, valorMercadoR.length - 2) +
      "." +
      valorMercadoR.substr(valorMercadoR.length - 2, 2);

    for (let i = 0; i < array.length; i++) {
      if (
        Number(array[i][1].replace(",", ".")) <= valorMercadoR &&
        Number(array[i + 1][1].replace(",", ".")) > valorMercadoR
      ) {
        let A1 = Number(array[i][1].replace(",", "."));
        let A2 = Number(array[i + 1][1].replace(",", "."));
        let P1 = Number(array[i][2].replace(",", "."));
        let P2 = Number(array[i + 1][2].replace(",", "."));
        PLimite =
          aproxima(
            (
              (((P2 - P1) * (valorMercadoR - A1)) / (A2 - A1) + P1) *
              (1 + tolerancia)
            ).toFixed(2)
          ) + ".00";
      }
    }

    let valorPropostaR = valorProposta
      .replaceAll("R", "")
      .replaceAll("$", "")
      .replaceAll(" ", "")
      .replaceAll(".", "")
      .replaceAll(",", "");

    valorPropostaR =
      valorPropostaR.substr(0, valorPropostaR.length - 2) +
      "." +
      valorPropostaR.substr(valorPropostaR.length - 2, 2);

    if (Number(valorPropostaR) <= Number(PLimite)) {
      await handleRegisterAsync();
      setStep(6);
    } else {
      setValorMercado(valorMercadoR);
      setValorReformulado(PLimite);
      setStep(5);
    }
  }

  function aproxima(valor) {
    if (valor % 100 == 0) return valor;
    var round = Math.ceil(valor);
    while (round % 100 != 0) round--;
    return round;
  }

  function handleNext() {
    handleRegisterAsync(valorReformulado, "Alterada")
      .then(() => setStep(6))
      .catch((err) => console.error(err));
  }

  function styleBorderError(object) {
    setTimeout(() => {
      object.style.border = "none";
    }, 3000);
  }

  function handleReject() {
    let valorPropostaR = valorProposta
      .replaceAll("R", "")
      .replaceAll("$", "")
      .replaceAll(" ", "")
      .replaceAll(".", "")
      .replaceAll(",", "");

    valorPropostaR =
      valorPropostaR.substr(0, valorPropostaR.length - 2) +
      "." +
      valorPropostaR.substr(valorPropostaR.length - 2, 2);
    handleRegisterAsync(valorPropostaR, "Negada").catch((err) =>
      console.error(err)
    );
  }

  return (
    <>
      <div className="step">
        {step == 4 ? (
          <div className="step-loading">
            <div className="circle-loading">
              <CommonLoading />
            </div>
            <h3>Estamos analisando, aguarde um instante, por favor!</h3>
          </div>
        ) : (
          ""
        )}

        {step == 5 ? (
          <div className="step-loading dis-flex">
            <h3>
              Obrigado pela proposta enviada.
              <br />
              Infelizmente o valor sugerido não atinge nosso interesse de
              compra. <br />
              <h3
                style={{
                  border: "1.5px solid black",
                  fontSize: "15px",
                  borderRadius: 5,
                  padding: 10,
                  paddingTop: 15,
                  width: window.innerWidth ? "100%" : "80%",
                  marginLeft: window.innerWidth ? "0%" : "10%",
                }}
              >
                {/* Atenção: Nossa plataforma faz um primeiro filtro na sua oferta baseado em inúmeras informações, como: quantidade de imóveis ofertados na localidade levando em consideração área, valor do condomínio, disposição interna etc. Para que posamos fazer uma avaliação mais detalhada, precisamos que você aceite num primeiro momento o valor oferecido como base pela nossa plataforma. Esse valor é apenas um parâmetro para que possamos prosseguir e não deve ser levado em consideração quando enviarmos uma proposta definitiva." */}
                Nossa plataforma faz um primeiro filtro na sua oferta baseado em
                inúmeras informações, como: quantidade de imóveis ofertados na
                localidade levando em consideração área, valor do condomínio,
                disposição interna etc.
                <br></br>
                ATENÇÃO: Este valor oferecido pela nossa plataforma NÃO é
                definitivo. Iremos fazer uma avaliação mais detalhada, mas para
                isso é preciso que aceite esse valor, sendo apenas um parâmetro
                inicial para nós. E então, enviaremos a proposta definitiva por
                e-mail.
              </h3>{" "}
              <br />
              Para este imóvel a oferta poderia ser de no máximo{" "}
              <strong>
                R$ {Number(valorReformulado).toLocaleString("pt-BR")}
              </strong>
              .<br />
              Gostaria de alterar a proposta de venda para este valor?
            </h3>

            <h3 className="h3" style={{ color: "#EA5C1E" }}>
              Atenção: Optando por SIM, você não precisa refazer a proposta com
              o novo valor!
            </h3>

            <div className="container-next mins">
              <button className="btBack" onClick={handleReject}>
                Não
              </button>
              <button onClick={handleNext}>Sim</button>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* {
                    step == 6 ?
                        <div className="step-loading dis-flex margin-texts">
                            <img src={require('./icons/success.svg')} />
                            <h3>
                                A equipe Imóvel Vazio agradece sua oferta. <br />
                                Iremos avaliar e enviar a nossa proposta de compra. <br />
                                Aguarde nosso retorno em até 48 horas.
                            </h3>
                            {window.location.href = "/ofertar-imovel/sucesso"}
                        </div>
                        :
                        ''
                } */}

        {step == 7 ? (
          <div className="step-loading dis-flex margin-texts">
            <img src={require("./icons/error.svg")} />
            <h3>
              A equipe Imóvel Vazio agradece sua oferta e aguarda as próximas
              oportunidades.
            </h3>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
