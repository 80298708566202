import React, {useEffect, useState} from 'react'
import '../../global.css';
import { Link } from 'react-router-dom'
import api from '../../api'
import InputMask from "react-input-mask";
import { notification } from "antd";

export default function StepOne({setStep, setNome, nome, setCpf, cpf, setSenha, senha, email, setEmail, celular, setCelular, setSenhaConfirm, senhaConfirm}) {

    function handleNext(){
        if(nome.length >= 3 && cpf.length == 14 && validaCpfCnpj(cpf) && email.length >= 5 && senha.length >= 6 && senha == senhaConfirm && !celular.includes('_')){
            setStep(2)
        }else{
            let errors = document.querySelectorAll('.input-box')
            if(nome.length < 3){
                errors[0].style.border = "2px solid red"
                styleBorderError(errors[0])
            }
            if(email.length <= 5){
                errors[1].style.border = "2px solid red"
                styleBorderError(errors[1])
            }
            if(cpf.length != 14 || !validaCpfCnpj(cpf)){
                errors[2].style.border = "2px solid red"
                styleBorderError(errors[2])
            }
            if(celular.includes('_')){
                errors[3].style.border = "2px solid red"
                styleBorderError(errors[3])
            }
            if(senha.length <= 6){
                errors[4].style.border = "2px solid red"
                styleBorderError(errors[4])
            }
            
        }
    }
    function styleBorderError(object){
        setTimeout(() => {
            object.style.border = "none"
        }, 3000);
    }
    function validaCpfCnpj(val) {
        if (val.length == 14) {
            var cpf = val.trim();
         
            cpf = cpf.replace(/\./g, '');
            cpf = cpf.replace('-', '');
            cpf = cpf.split('');
            
            var v1 = 0;
            var v2 = 0;
            var aux = false;
            
            for (var i = 1; cpf.length > i; i++) {
                if (cpf[i - 1] != cpf[i]) {
                    aux = true;   
                }
            } 
            
            if (aux == false) {
                return false; 
            } 
            
            for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
                v1 += cpf[i] * p; 
            } 
            
            v1 = ((v1 * 10) % 11);
            
            if (v1 == 10) {
                v1 = 0; 
            }
            
            if (v1 != cpf[9]) {
                return false; 
            } 
            
            for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
                v2 += cpf[i] * p; 
            } 
            
            v2 = ((v2 * 10) % 11);
            
            if (v2 == 10) {
                v2 = 0; 
            }
            
            if (v2 != cpf[10]) {
                return false; 
            } else {   
                return true; 
            }
        } else if (val.length == 18) {
            var cnpj = val.trim();
            
            cnpj = cnpj.replace(/\./g, '');
            cnpj = cnpj.replace('-', '');
            cnpj = cnpj.replace('/', ''); 
            cnpj = cnpj.split(''); 
            
            var v1 = 0;
            var v2 = 0;
            var aux = false;
            
            for (var i = 1; cnpj.length > i; i++) { 
                if (cnpj[i - 1] != cnpj[i]) {  
                    aux = true;   
                } 
            } 
            
            if (aux == false) {  
                notification.error({
                    message: 'CNPJ invalido.',
                  });
                return false; 
            }
            
            for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
                if (p1 >= 2) {  
                    v1 += cnpj[i] * p1;  
                } else {  
                    v1 += cnpj[i] * p2;  
                } 
            } 
            
            v1 = (v1 % 11);
            
            if (v1 < 2) { 
                v1 = 0; 
            } else { 
                v1 = (11 - v1); 
            } 
            
            if (v1 != cnpj[12]) {  
                notification.error({
                    message: 'CNPJ invalido.',
                  });
                return false; 
            } 
            
            for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) { 
                if (p1 >= 2) {  
                    v2 += cnpj[i] * p1;  
                } else {   
                    v2 += cnpj[i] * p2; 
                } 
            }
            
            v2 = (v2 % 11); 
            
            if (v2 < 2) {  
                v2 = 0;
            } else { 
                v2 = (11 - v2); 
            } 
            
            if (v2 != cnpj[13]) {   
                notification.error({
                    message: 'CNPJ invalido.',
                  });
                return false; 
            } else {  
                return true; 
            }
        } else {
            return false;
        }
     }

    async function validateEmail(){
        const {data} = await api.post('/user/validate', {
            email
        })

        if(data.success){
            notification.success({
                message: 'Email ja cadastrado',
              });
            setStep(1)
        }else{
            handleNext()    
        }
    }
    
    return (
        <>  
            <div className="step">
                <div className="input-box">
                    <small>Nome</small>
                    <input value={nome}  onChange={text => setNome(text.target.value)} placeholder="Ex.: João Silva" autoCapitalize/>
                </div>
                <div className="input-box">
                    <small>E-mail</small>
                    <input value={email}  onChange={text => setEmail(text.target.value)} placeholder="Ex.: joao.silva@email.com" type="email"/>
                </div>

                <div className="container-input-box">
                    <div className="input-box">
                        <small>CPF</small>
                        <InputMask mask="999.999.999-99" onChange={text => setCpf(text.target.value)} value={cpf} placeholder="Ex.: 000.000.000-00"/>
                    </div>
                    <div className="input-box">
                        <small>Celular</small>
                        <InputMask mask="(99) 99999-9999" value={celular}  onChange={text => setCelular(text.target.value)} placeholder="Ex.: (00) 00000-0000" pattern="\(\d{2}\)\d{4}-\d{4}"/>
                    </div>
                </div>
                <div className="container-input-box">
                    <div className="input-box">
                        <small>Senha</small>
                        <input value={senha}  onChange={text => setSenha(text.target.value)} type="password" placeholder="No mínimo 6 digitos" />
                    </div>
                    <div className="input-box">
                        <small>Confirmação de senha</small>
                        <input value={senhaConfirm}  onChange={text => setSenhaConfirm(text.target.value)} type="password" placeholder="" />
                    </div>
                </div>

                <div className="container-next">
                    <button onClick={validateEmail}>Avançar</button>
                </div>
            </div>
        </>
    )
}
