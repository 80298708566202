import React, { useEffect, useState } from 'react'
import '../../global.css';
import { requesterCodeVerificationAsync, requesterValidateSmsAsync } from '../../services/user.service';
import { NOTIFICATION_TYPE, openNotification } from '../../utils/notification';

export default function StepFour({ setStep, codigo, setCodigo, handleRegister, celular }) {
    const [sms, setSMS] = useState('')
    useEffect(() => {
        handleSMS();
    }, [])

    function handleNext() {
        if (codigo.length > 0) {
            requesterCodeVerificationAsync({
                telephone: celular,
                verificationCode: codigo,
            })
                .then(() => {
                    handleRegister();
                })
                .catch((err) => {
                    console.error(err);
                    const content = err?.response?.data?.userMessage || 'Falha na validação!';
                    openNotification(content, NOTIFICATION_TYPE.error);
                });
        } else {
            let errors = document.querySelectorAll('.input-box')
            errors[0].style.border = "2px solid red"
            setTimeout(() => {
                errors[0].style.border = "none"
            }, 3000);
        }

    }

    async function handleSMS() {
        requesterValidateSmsAsync(celular.replace(/\D/g, ''))
            .catch((err) => {
                console.error(err);
                const content = err?.response?.data?.userMessage || 'Falha ao enviar código!';
                openNotification(content, NOTIFICATION_TYPE.error);
            });
    }

    return (
        <>
            <div className="stepi">
                <p className="p">Enviamos um SMS para o celular <strong>{celular}</strong>.</p>

                <p className="reload">
                    Aguarde até 1 minuto para receber o código.
                </p>
                <p className="reload">
                    Caso não receba, <a onClick={handleSMS}>clique aqui para enviarmos outro.</a>
                </p>


                <div className="input-box input-center" style={{ width: window.innerWidth <= 600 ? '80%' : '' }}>
                    <small>Digite o codigo</small>
                    <input value={codigo} onChange={change => setCodigo(change.target.value)} />
                </div>
                <br />


                <div className="container-next container-center" style={{ width: window.innerWidth <= 600 ? '95%' : '' }}>
                    <button onClick={handleNext}>Confirmar</button>
                </div>
                <br />

                <a onClick={click => setStep(2)} className="edit-number">Editar o número do celular</a>

            </div>
        </>
    )
}
