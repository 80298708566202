import React from "react";
import * as S from "./styles";
import "../../../global.css";
import ImgVerifyPrepared from '../../../assets/ImgVerifyPrepared.svg'
import { InfoOutlined } from '@ant-design/icons';

export default function StartBindingZap({ setNextViewModalBinding }) {
  function redirectModal(next) {
    setNextViewModalBinding(next);
  }

  return (
    <>
      <S.Container>
        <S.Content>
          <S.ContentTitle>
           <span className="title">Comece por aqui:</span>
           <span className="subTitle">Você já possui uma conta Canal Pro?</span>
           <S.ContentBtns>
             <S.NextButton onClick={() => redirectModal('alertZap')}>Já tenho uma conta</S.NextButton>
             <S.BtnOutlined onClick={() => redirectModal('createZap')}>Não tenho uma conta</S.BtnOutlined>
           </S.ContentBtns>
          </S.ContentTitle>
          <S.ContentInfo>
            <div className="info-circle">
                <InfoOutlined className="info" />
            </div>
            <span className="title-info">Certifique-se de possuir uma assinatura e uma conta ativa.</span>
          </S.ContentInfo>
        </S.Content>
        <S.ContentImgIllustrated>
            <img src={ImgVerifyPrepared} alt="Image Illustrated" />
        </S.ContentImgIllustrated>
      </S.Container>
    </>
  );
}
