import React from "react";
import * as S from "./styles";
import "../../../../global.css";
import { Divider } from 'antd';

export default function AdValuesView({ data }) {

  function money(value) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  }

  return (
    <>
      <S.Container>
        <S.Title>Valores</S.Title>
        <S.ContentCharacteristics>
          <S.TitleCharacteristics>Imóvel</S.TitleCharacteristics>
          <S.ContentCharacteristicsValue>
            <S.Question>Valor da venda do imóvel</S.Question>
            <S.ValueQuestion>{money(data.sellingPrice)}</S.ValueQuestion>
          </S.ContentCharacteristicsValue>

          <S.ContentCharacteristicsValue>
            <S.Question>Valor do Condomínio</S.Question>
            <S.ValueQuestion>{money(data.condominiumPrice)}</S.ValueQuestion>
          </S.ContentCharacteristicsValue>
        </S.ContentCharacteristics>
        <Divider />

        <S.ContentCharacteristics>
          <S.TitleCharacteristics>IPTU</S.TitleCharacteristics>
          <S.ContentCharacteristicsValue>
            <S.Question>Valor (mensal)</S.Question>
            <S.ValueQuestion>{money(data.IPTU)}</S.ValueQuestion>
          </S.ContentCharacteristicsValue>

          <S.ContentCharacteristicsValue>
            <S.Question>Número</S.Question>
            <S.ValueQuestion>{data.IPTUNumber}</S.ValueQuestion>
          </S.ContentCharacteristicsValue>
        </S.ContentCharacteristics>

      </S.Container>
    </>
  );
}
