import React from "react";
import { Input } from "antd";
import { Field, SectionContainer } from "../styles";
import Topic from "./topic";
import { SECTION_TYPE, checkErrorStyle } from "../constants";
import { Controller, useFormContext } from "react-hook-form";
import TextArea from "antd/es/input/TextArea";

const Section = ({ prefix = '', title, topics = 0, types = [] }) => {

  const {
    control,
    formState: { isSubmitted, },
  } = useFormContext();

  const TOPICS = Array.from({ length: topics });

  return <>
    {types.includes(SECTION_TYPE.title) &&
      <Controller
        control={control}
        name={`${prefix}.title`}
        rules={{
          required: 'Informe o título',
          maxLength: {
            value: 20,
            message: 'Máximo de 20 caracteres',
          },
        }}
        render={({ field, fieldState: {
          invalid, isDirty, isTouched, error,
        }, }) => (
          <Field {...checkErrorStyle({
            invalid, isDirty, isTouched, isSubmitted, error,
          })} className="input-box">
            <small>Título</small>
            <Input {...field} maxLength={20} />
          </Field>
        )}
      />
    }
    <Controller
      control={control}
      name={`${prefix}.description`}
      rules={{
        required: `Insira uma descrição curta`,
        maxLength: {
          value: 180,
          message: 'Máximo de 180 caracteres',
        },
      }}
      render={({ field, fieldState: {
        invalid, isDirty, isTouched, error,
      }, }) => (
        <Field {...checkErrorStyle({
          invalid, isDirty, isTouched, isSubmitted, error,
        })} className="input-box">
          <small>Texto de destaque</small>
          <TextArea {...field} rows={2} maxLength={180} />
        </Field>
      )}
    />

    {topics > 0 && <div key={`${prefix}.topics`} style={{ paddingLeft: '2rem' }}>
      {TOPICS
        .map((_, index) => <Topic key={`${prefix}.topics.${index}`} {...{ types, index, prefix: `${prefix}.topics.${index}` }} />)}
    </div>
    }
  </>
};

export default Section;

