import "../../global.css";
import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { BgImg, FilterContainer } from "./styles";
import Footer from "../../components/Footer";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link, useLocation } from "react-router-dom";
import { authApi, exitProfileToken, revokeToken, SESSION_NAMES, userLocationApi } from "../../api";
import Loading from "../../components/Loading";

import PasswordRecovery from "./PasswordRecovery";
import { Input } from "antd";
import FormField from "../../components/FormField";
import { Controller, useForm } from "react-hook-form";
import { NOTIFICATION_TYPE, openNotification } from "../../utils/notification";
import jwtDecode from "jwt-decode";
import { PanoramaWideAngle } from "styled-icons/material";
import { getCityOfUserActing, setLocationSession, setUserSession } from "../../utils/sessionHelper";
import { getCitiesAsync } from "../../services/city.service";
export default function UserLogin() {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [corretor, setCorretor] = useState(true);
  const [proprietario, setProprietario] = useState(false);
  const [flex, setFlex] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation()

  function handleChange(type) {
    if (type != "corretor") {
      setProprietario(true);
      setCorretor(false);
    } else if (type != "proprietario") {
      setProprietario(false);
      setCorretor(true);
    }
  }
  useEffect(() => {
    exitProfileToken()
    window.scrollTo({
      top: 0
    })
    let redirect = window.location.href;
    if (redirect.includes("modal=true")) {
      setFlex(true);
      //window.location.href='/banking2'
    }
  }, []);

  async function handleLogin() {
    setLoading(true);
    var redirect = window.location.href;

    if (email.length == 0) {
      let emailBox = document.querySelector(".email-box");
      emailBox.style.border = "2px solid red";
      setTimeout(() => {
        emailBox.style.border = "none";
      }, 3000);
      setLoading(false);
      return;
    }
    if (senha.length == 0) {
      let passBox = document.querySelector(".password-box");

      passBox.style.border = "2px solid red";
      setTimeout(() => {
        passBox.style.border = "none";
      }, 3000);
       
      setLoading(false);
      return;
    }

    const { data: authData } = await authApi.post("", {
      email: email,
      password: senha,
    });

    if (authData.error) {
      openNotification("Email ou senha incorreto.", NOTIFICATION_TYPE.warning);
      setLoading(false);
      return;
    }

    const { token, clientId, } = setLocationSession(authData);
    const { data: userLocation } = await userLocationApi();
    const { data: sessionData, status: sessionStatus } = await authApi.patch(`/token/${token}/${clientId}`, userLocation);

    if (sessionStatus >= 400 || !sessionData) {
      openNotification('Tente novamente!', NOTIFICATION_TYPE.error);
      setLoading(false);
      return;
    }

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(`corretor:${sessionData._id}`);
    }
    setUserSession(sessionData);
    if (redirect.includes("goBack=/imoveis/")) {
      window.location.href = redirect.split("goBack=")[1];
    } else {
      const cityId = getCityOfUserActing();

      getCitiesAsync({ orderBy: 'city', direction: 'ASC' })
        .then(({ data: cities }) => {
          if (cities?.some(c => c.value === cityId)) {
            window.location.href = `/imoveis?city_id=${cityId}`;
          }
          else {
            window.location.href ='/imoveis';
          }
        })
        .catch(() => window.location.href ='/imoveis');
    }
   setLoading(false);
  }

  const { control, register, formState: { errors }, handleSubmit, reset } = useForm({});

  return (
    <>
      <BgImg>
      {loading && <Loading />}
        <Header />
        {/* {flex ? <Modal setFlex={setFlex} /> : ""} */}
        {flex && <PasswordRecovery HandlerModal={setFlex} showModal={flex}></PasswordRecovery>}

        <FilterContainer>
          <div className="login-wrapper">
            <div className="login-box">
              <h1>Login</h1>
              <span id="appview"></span>
              <div className="body-box">
                <div style={{ width: '100%' }}>
                  <FormField label='E-mail' error={errors.email} >
                    <input {...register('email', { required: 'Informe o usuário', minLength: { value: 2, message: 'Informe um usuário válido' } })}
                      value={email}
                      onChange={(text) => {
                        setEmail(text.target.value.replace(" ", ""));
                      }}
                    />
                  </FormField>
                </div>
                <Controller
                  name="senha"
                  control={control}
                  rules={{
                    required: senha ? false : 'Informe a senha',
                  }}
                  render={({ field }) =>
                    <div style={{ width: '100%' }}>
                      <FormField label='Senha' error={errors.senha}>
                        <Input.Password
                          value={senha}
                          onChange={(text) => setSenha(text.target.value)}
                        />
                      </FormField>
                    </div>
                  }
                />
              </div>

              <a onClick={handleSubmit(handleLogin)} style={{ cursor: 'pointer' }}>
                <div className="bt-login">Entrar</div>
              </a>
              <small
                onClick={(e) => {
                  window.scrollTo(0, 0);
                  if (window.innerWidth <= 600) {
                    window.scrollTo(0, 300);
                  }
                  document.querySelector("body").style.overflow = "hidden";
                  setFlex(true);
                }}
              >
                Esqueci a minha senha
              </small>
            </div>
            <div className="register-box">
              <h1>Cadastre-se</h1>

              {/* inicio  */}

              <div className="body-box">
                <div className="group-box">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={corretor}
                        onChange={(change) => handleChange("corretor")}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Corretor de imóvel"
                  />

                  {/* <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={proprietario}
                                                onChange={change=>handleChange('proprietario')}
                                                name="checkedB"
                                                color="primary"
                                                
                                            />
                                            }
                                            label="Proprietário de imóvel"
                                        /> */}
                </div>
              </div>

              <Link to={corretor ? location.search.includes('chegamos_em_bh') ? 
              "/register?origin=chegamos_em_bh" : "cadastro-corretor" : "RegisterOwner"}>
                <div className="bt-register">Cadastrar</div>
              </Link>

              {/* fim  */}
            </div>
          </div>
        </FilterContainer>
      </BgImg>

      <Footer />
    </>
  );
}
