import React, {useEffect, useState} from 'react'
import '../../global.css';
import { Link } from 'react-router-dom'
import api from '../../api'

export default function StepTwo({setStep, codigo, setCodigo, handleRegister, celular}) {
    const [sms, setSMS] = useState('')
    useEffect(() => {
        handleSMS()
    }, [])

    function handleNext(){
        if(codigo.length > 0){
            
            if(sms == codigo){
                if(handleRegister()){
                    //setStep(5)
                }
            }else{
                let errors = document.querySelectorAll('.input-box')
                errors[0].style.border="2px solid red"
                setTimeout(() => {
                    errors[0].style.border = "none"
                }, 3000);
            }
        }
    }
    
    async function handleSMS(){
        let response = await api.post(`/sms?telephone=${celular.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')}`)
        
        setSMS(response.data.code)
    }

    return (
        <>
            <div className="stepi">
                <p>Enviamos um SMS para o celular <strong>{celular}</strong>.</p>

                <p className="reload">
                    Aguarde até 1 minuto para receber o código.
                </p>
                <p className="reload">
                    Caso não receba, <a onClick={handleSMS}>clique aqui para enviarmos outro.</a>
                </p>


                <div className="input-box input-center">
                    <small>Digite o codigo</small>
                    <input value={codigo} onChange={change=>setCodigo(change.target.value)}/>
                </div>
                <br/>


                <div className="container-next container-center">
                    <button onClick={handleNext}>Confirmar</button>
                </div>
                <br/>

                <a onClick={click => setStep(1)} className="edit-number">Editar o número do celular</a>
                        
            </div>
        </>
    )
}
