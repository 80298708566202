import React, {useState} from "react";
import * as S from "./styles";
import "../../../global.css";
import ImageLinkZap from '../../../assets/ImageLinkZap.svg'
import ArrowLeft from '../../../assets/ArrowLeft.svg';
import { InfoOutlined } from '@ant-design/icons';
import CopyIcon from '../../../assets/CopyIcon.svg'
import { getUserSession } from "../../../utils/sessionHelper";
import { Popover } from 'antd';

export default function LinkZap({ setNextViewModalBinding }) {
  const [openPopupCopy, setOpenPopupCopy] = useState(false);

  let userSession = getUserSession();
  const handleCopyClick = () => {
    const textToCopy = `https://integracao.visualizarimovel.com.br/${+userSession.sub}.xml`;
    // Cria um elemento de input dinamicamente
    const inputElement = document.createElement('input');
    // Atribui o texto que será copiado ao valor do input
    inputElement.value = textToCopy;
    // Adiciona o input ao DOM (é necessário estar no DOM para copiar)
    document.body.appendChild(inputElement);
    // Seleciona todo o texto dentro do input
    inputElement.select();
    // Executa o comando de cópia
    document.execCommand('copy');
    // Remove o input do DOM
    document.body.removeChild(inputElement);

    // Atualiza o estado para indicar que o texto foi copiado
    setOpenPopupCopy(true);
    // Define um tempo limite para resetar o estado após um curto período de tempo
    setTimeout(() => {
      setOpenPopupCopy(false)
    }, 1200);
  };
  
  return (
    <>
      <S.Container>
        <S.Content>
            <S.ContentButtonBack>
                <div className="content-back" onClick={() => setNextViewModalBinding('integrateNameZap')}>
                    <img src={ArrowLeft} alt="Arrow Left" />
                    <span className="title-back">Voltar</span>
                </div>
            </S.ContentButtonBack>
          <S.ContentTitle>
            <span className="title">Vincule seu Link</span>
            <span className="subTitle">
                Copie o <strong>link</strong> abaixo e cole em qualquer plataforma que utilize o tipo XML 
                (o mesmo utilizado pelo grupo ZAP) em seu integrador de anúncio.
            </span>
            <S.ContentInputLink>
                <S.InputLink value={`https://integracao.visualizarimovel.com.br/${+userSession.sub}.xml`} disabled />
                <Popover
                    content={
                      <S.ContentTitlePopupCopy>
                        <S.TitlePopupCopy>Copiado com Sucesso!</S.TitlePopupCopy>
                      </S.ContentTitlePopupCopy>
                    }
                    trigger="click"
                    open={openPopupCopy}
                >
                 <img src={CopyIcon} alt="Copy Icon" className="copy-icon" onClick={handleCopyClick} />
                </Popover>
            </S.ContentInputLink>
          </S.ContentTitle>
          <S.ContentInfo>
            <div className="info-circle">
                <InfoOutlined className="info" />
            </div>
            <span className="title-info">Certifique-se de possuir uma assinatura e uma conta ativa.</span>
          </S.ContentInfo>
        </S.Content>
        <S.ContentImgIllustrated>
          <img src={ImageLinkZap} alt="Image Config Zap" />
        </S.ContentImgIllustrated>
      </S.Container>
    </>
  );
}
