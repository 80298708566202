import React, { useEffect, useState, useRef } from "react";
import "../../global.css";

export default function ContainerQuestion({ type }) {
  const [data, setData] = useState(
    type == "corretor"
      ? corretorQuestions
      : type == "comprador"
      ? compradorQuestions
      : vendedorQuestions
  );

  useEffect(() => {
    let containers = document.querySelectorAll(".container-questions");
    let results = document.querySelectorAll(".resposta");
    let imgs = document.querySelectorAll(".arrow");

    if (containers.length > 0 && results.length > 0 && imgs.length > 0) {
      containers[0].style.marginTop = "30px";
      for (let i = 0; i < containers.length; i++) {
        containers[i].addEventListener("click", (e) => {
          if (
            results[i].style.display == "" ||
            results[i].style.display == "none"
          ) {
            results[i].style.display = "flex";
            imgs[i].style.transform = "rotate(180deg)";
          } else {
            results[i].style.display = "none";
            imgs[i].style.transform = "rotate(0deg)";
          }
        });
      }
    }
  }, []);

  return (
    <>
      {data.map((value) => (
        <div className="container-questions">
          <div className="pergunta">
            <h4>{value.question}</h4>
            <img className="arrow" src={require("./arrow.svg")} />
          </div>
          <div className="resposta">
            {String(value.result).split("<a>").length > 1 ? (
              <a>
                {String(value.result).split("<a>")[0]}
                {String(value.result).split("<a>").length > 1 ? (
                  <a
                    style={{ marginLeft: 3, textDecoration: "underline" }}
                    onClick={(e) =>
                      (window.location.href =
                        String(value.result).split("|")[1] +
                        `${
                          new URLSearchParams(window.location.search).has("app")
                            ? "?app"
                            : ""
                        }`)
                    }
                  >
                    {" " + String(value.result).split("<a>")[1]}
                  </a>
                ) : (
                  ""
                )}
              </a>
            ) : (
              value.result
            )}
          </div>
        </div>
      ))}
    </>
  );
}

var corretorQuestions = [
  {
    question: "A Imóvel Vazio faz vendas diretas?",
    result:
      "Não. Somos um grupo de investidores do mercado imobiliário que compra imóveis e os comercializa exclusivamente por meio de corretores de imóveis. ",
  },
  {
    question:
      "Posso divulgar os imóveis por um valor diferente do anunciado no site?",
    result:
      "Não. É proibido anunciar qualquer imóvel por valor inferior ou superior ao apresentado neste site.",
  },
  {
    question: "A comissão de corretagem já está inclusa no valor do imóvel?",
    result:
      "Sim. Os valores anunciados neste site já consideram a comissão de corretagem.",
  },
  {
    question: "Como faço para me tornar um corretor parceiro?",
    result:
      "É muito fácil e rápido se tornar um corretor parceiro da Imóvel Vazio. <a> Basta se cadastrar neste formulário e aguardar a aprovação.<a>|/cadastro-corretor|",
  },
  {
    question: "Ainda não tenho CRECI, posso me cadastrar?",
    result:
      "Não. A parceria é exclusiva para corretores de imóveis profissionais.",
  },
  {
    question: "Qual é o percentual de corretagem?",
    result:
      "Pagamos 6% de comissão para o preço de lista, já incluídos nos valores apresentados.",
  },
  {
    question: "Como funciona a área do corretor?",
    result:
      "EM BREVE: A área do corretor é um ambiente que você acessa por meio de login e senha exclusivos. Nela você pode visualizar os imóveis da sua carteira, receber notificações sobre os status dos imóveis, realizar parcerias e realizar outras ações importantes para facilitar a gestão do seu negócio. ",
  },
  {
    question: "Quem providencia o correspondente bancário?",
    result:
      "A Imóvel Vazio oferece o serviço de correspondente bancário e o corretor ainda pode lucrar com isso. <a>Saiba mais aqui.<a>|/correspondente-bancario|",
  },
  {
    question: "Quem paga o custo do despachante?",
    result:
      "Na Imóvel Vazio o corretor não tem nenhuma despesa extra. Nós oferecemos esse serviço gratuitamente.",
  },
  {
    question:
      "E se eu precisar de ajuda para obter documentação e realizar o registro da escritura?",
    result:
      "Na Imóvel Vazio você conta com serviço gratuito de despachante para realizar todas as etapas de compra e venda dos imóveis.",
  },
  {
    question:
      "É permitido sugerir permutas por carros ou outros bens na negociação dos imóveis?",
    result:
      "Sim. Estudamos permutas por carros ou outros imóveis, mediante prévia negociação.",
  },
  {
    question: "Como são os preços dos imóveis?",
    result:
      "A Imóvel Vazio pratica preços abaixo do mercado. Esse é um dos nossos grandes diferenciais.",
  },
  {
    question: "É fácil visitar um imóvel?",
    result:
      "É muito fácil e prazeroso acompanhar o seu cliente em uma visita ao imóvel desejado. O acesso descomplicado do corretor aos imóveis é um dos grandes diferenciais da Imóvel Vazio.",
  },
  {
    question: "Como posso me manter informado sobre novos imóveis? ",
    result:
      "Atualizamos continuamente nosso site para manter os corretores sempre atualizados de novos imóveis a venda e dos que já foram vendidos. Mandamos e-mail semanalmente para todos os corretores cadastrados.",
  },
  {
    question: "Posso vender os imóveis em parceria com outros corretores?",
    result:
      "EM BREVE: Sim. Ao se cadastrar na Imóvel Vazio, você terá acesso a uma área exclusiva onde poderá gerenciar suas parcerias com outros corretores e negociar livremente as comissões sobre a venda dos imóveis.",
  },
  {
    question: "É seguro negociar com a Imóvel Vazio?",
    result:
      "Sim, é totalmente seguro negociar com a Imóvel Vazio e muito bom para sua carreira. Somos um grupo de investidores que atua no mercado imobiliário há mais de dez anos. Temos total credibilidade no mercado e atuamos em parceria com mais de 1000 corretores profissionais. <a>Saiba mais aqui.<a>|/sobre-nos|",
  },
  {
    question: "O que acontece se meu cliente procurar a imóvel Vazio?",
    result:
      "Temos total compromisso com os nossos corretores parceiros. Caso um comprador nos procure, você será informado para dar continuidade ao atendimento.",
  },
];

var vendedorQuestions = [
  {
    question: "Qual é o diferencial da Imóvel Vazio?",
    result:
      "A Imóvel Vazio é um portal no qual você pode oferecer seus imóveis com segurança e total confiabilidade. Lembramos que os imóveis ofertados precisam ser considerados oportunidades de mercado.",
  },
  {
    question: "Como faço para colocar um imóvel à venda?",
    result:
      "É muito fácil e rápido cadastrar o seu imóvel para venda na Imóvel Vazio.<a>Basta preencher este formulário e aguardar o retorno.<a>|/ofertar-imovel|",
  },
  {
    question: "Posso ter acesso aos meus imóveis cadastrados?",
    result:
      "Sim. Ao se cadastrar, você receberá um login e senha para acessar sua área de vendedor de imóvel.",
  },
  {
    question: "Como funciona a avaliação do meu imóvel?",
    result:
      "No momento do cadastro, você informará, dentre outros dados, o valor de avaliação do imóvel e o valor desejado pela venda. A Imóvel Vazio fará uma análise e responderá sua solicitação informando o valor oferecido pelo imóvel, caso haja interesse na compra.",
  },
  {
    question: "É seguro vender um imóvel para a Imóvel Vazio?",
    result:
      "Sim, é totalmente seguro. Somos um grupo de investidores que atua no mercado imobiliário há mais de 10 anos. Temos total credibilidade no mercado e atuamos em parceria com mais de 1000 corretores profissionais.<a>Saiba mais aqui.<a>|/sobre-nos|",
  },
];

var compradorQuestions = [
  {
    question: "Como faço para escolher um imóvel?",
    result:
      "Proporcionamos no site uma experiência agradável e intuitiva, tanto pelo computador quanto pelo seu tablet ou smartphone. Basta navegar pela área de imóveis, usando os filtros de bairro, valor ou outro critério para facilitar a segmentação. Feito isso, clique nos imóveis desejados e tenha acesso às fotos e principais informações, como número de quartos, dependências, vaga e outras. Gostou do imóvel? Então basta clicar no botão: Quero visitar este imóvel - e aguardar o retorno do corretor que o acompanhará na visita aos imóveis escolhidos.",
  },
  {
    question: "É possível comprar um imóvel com financiamento bancário?",
    result:
      "Sim. Não se preocupe que o seu corretor será o encarregado de lhe informar sobre o passo-a-passo para solicitar o financiamento bancário.",
  },
  {
    question: "Os imóveis estão prontos para morar?",
    result:
      "Sim. Este é um dos grandes diferenciais da Imóvel Vazio. Nossos imóveis são acessíveis e prontos para morar. Todos eles são legalizados, já estão reformados e com possibilidade de financiamento pela rede bancária.",
  },
  {
    question: "Posso sugerir análise de permuta por outros bens?",
    result:
      "Sim. Estudamos permutas por carros ou outros imóveis, mediante prévia negociação. Solicite ao seu corretor.",
  },
  {
    question: "Posso comprar meu imóvel diretamente com a Imóvel Vazio?",
    result:
      "Não. Somos um grupo de investidores do mercado imobiliário que compra imóveis e os comercializa exclusivamente por meio de corretores de imóveis parceiros. Sua compra será sempre intermediada pelo corretor, que é o profissional especializado neste atendimento.",
  },
];
