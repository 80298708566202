import styled from "styled-components";

export const BarStory = styled.div`
   background-color: #FFFAF3;
   position: relative;
`

export const MainStory = styled.div`
   display: flex;
   justify-content: space-around;
   align-items: center;
   padding-top: 130px;
   padding-bottom: 130px;

   @media (max-width: 768px) { 
        display: flex;
        flex-direction: column;
        text-align: center;
     }
`

export const StoryTextLP = styled.div`
   width: 40%;

   span {
    font-family: 'Roboto';
    font-size: 51px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color: #3B414A;

    .title-color {
        color: #0098AE !important;
    }
   }

   p {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
   }

   @media (max-width: 768px) { 
    width: 100%;

    span {
       font-size: 42px;
       text-align: center;
    }

    p {
       font-size: 16px;
       text-align: center;
       width: 80%;
    }
   }

   @media (max-width: 480px) { 
    width: 100%;

    span {
       font-size: 42px;
       text-align: center;
    }

    p {
       font-size: 16px;
       text-align: center;
    }
   }
`

export const StoryTextTitle = styled.div`
    margin-bottom: 70px;
` 

export const StoryFirstParagraph = styled.div`
    margin-bottom: 20px;

    @media (max-width: 768px) { 
      display: flex;
      justify-content: center;
    }
`

export const StoryLastParagraph = styled.div`
    margin-bottom: 100px;

    @media (max-width: 768px) { 
      display: flex;
      justify-content: center;
    }
`

export const ImageBanner = styled.div`
    @media (max-width: 768px) { 
       img {
         width: 75%;
       }
    }
`

export const ImageBuildings = styled.img`
    width: 100%;
    opacity: 1;
    position: absolute;
    bottom: 0;
`