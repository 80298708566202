import styled from 'styled-components';
import { Button } from 'antd';

const SaveButton = styled(Button)`
    width: 199px;
    height: 45px;
    background: linear-gradient(90deg,#ea5c1e 0%,#c13818 100%);
    border-radius: 4px;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    -webkit-letter-spacing: 0.05em;
    -moz-letter-spacing: 0.05em;
    -ms-letter-spacing: 0.05em;
    letter-spacing: 0.05em;
    border: none;
    color: #ffffff;
    :not(:disabled):not(.ant-btn-disabled):hover {
        color: #ffffff;
        border: none;
    }
`;

export default SaveButton;
