import styled from "styled-components";

export const ValidationStyle = styled.div`
  border: ${({ isValid, pristine }) => !isValid && !pristine ? '2px solid red' : 'none'}
`;

ValidationStyle.defaultProps = {
  isValid: true,
  pristine: true,
};

export const ValidationMessage = styled.small`
  color: red;
`;