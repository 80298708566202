import { NonceProvider } from "react-select/dist/react-select.cjs.dev";
import styled from "styled-components";

export const Bar = styled.div`
   background-color: #fff;
`

export const TitleServices = styled.div`
    font-family: 'Roboto';
    font-size: 36px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 110px;

      span {
        color: #0088AC;
      }

      @media (max-width: 768px) { 
        font-size: 34px;
        width: 90%;
        margin: 0 auto;
        margin-top: 90px;
     }
`

export const CardServices = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`

export const CardGroupService = styled.div`
    width: 300px;
    text-align: center;

    .first-service-text {
      padding-top: 40px;
      font-family: 'Roboto';
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
    }

    .last-service-image {
        margin-top: 20px;
        font-family: 'Roboto';
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;                                                                
    }

    .last-service-text {
        margin-top: 50px;
        font-family: 'Roboto';
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
    }
`

export const Topic = styled.img`
    padding-left: 15px;
    padding-right: 15px;
`

export const TopicLine = styled.div`
   @media(max-width: 768px) {
     transform: rotate(90deg);
     padding-top: 60px;
     padding-bottom: 60px;
   }
`

export const Btn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
    margin-bottom: 130px;
`


export const BtnPartnerBroker = styled.div`
    display: inline-block;
    padding-top: 40px;
    a{
        text-decoration:none;
        color: #fff;
        background-color: #0088AC;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 15px;

        span {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        }

        .icon-arrow {
            position: relative;
            top: 2px;
            left: 8px;
        }

        &:hover, .linkButton {
            background-color: #006690;
            color: #fff;
        }
    }
`

export const BtnSeeProperties = styled.div`
    display: inline-block;
    padding-top: 40px;

    a{
        text-decoration:none;
        color: #fff;
        background-color: #0088AC;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 15px;
        padding-left: 35px;
        padding-right: 35px;

        span {
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        }

        .icon-arrow {
            position: relative;
            top: 2px;
            left: 8px;
        }

        &:hover, .linkButton {
            background-color: #006690;
            color: #fff;
        }

    }

    @media (max-width: 768px) {
       display: flex;
       justify-content: center;

       a {
        padding-left: 60px;
        padding-right: 60px
       }
    }
`