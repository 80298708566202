import React, { useContext, useState } from "react";
import * as S from "./styles";
import "../../../../global.css";
import TextEditor from "../../../TextEditor";
import { Image, Select, Space, } from 'antd';
import { Controller } from "react-hook-form"
import FormContext from '../../../ModalZap/_context';

export default function AdDescriptionFull({ data, isAdEdit, features, }) {

  const propertyData = isAdEdit ? data?.property : data;

  const options = features.map((item) => ({
    label: item.displayName,
    value: item.displayName
  }))

  const arrayImages = [];
  propertyData.images.map((item) => {
    const urlImage = `${process.env.REACT_APP_FILES_URL}/${propertyData._id}/fotos/${encodeURIComponent(item.image)}`
    arrayImages.push(urlImage);
  })

  const ListImages = ({ arrayImages, id, images, indexImage }) => {
    const [current, setCurrent] = useState(indexImage);

    function handleVisibleImage(visible) {
      if (!visible) {
        setCurrent(indexImage);
      }
    }

    return (
      <>
        {indexImage < 4 &&
          <Image.PreviewGroup
            items={arrayImages}
            preview={{
              current: current,
              onChange: (currentIndex) => setCurrent(currentIndex),
              onVisibleChange: (visible) => handleVisibleImage(visible)
            }}
          >
            <div className="content-image">
              <Image
                width={150}
                height={110}
                className={indexImage === 3 ? 'image-count' : ''}
                src={`${process.env.REACT_APP_FILES_URL}/${id}/fotos/${encodeURIComponent(images[indexImage].image)}`}
              />
              {images.length > 4 && indexImage === 3 && (
                <S.ContentCountImage>
                  <span className="title">+{images.length - 4}</span>
                  <span className="subtitle">Conteúdos</span>
                </S.ContentCountImage>
              )}
            </div>
          </Image.PreviewGroup>
        }
      </>
    );

  };

  const { form: { watch, setValue, control, register, formState: { errors, }, } } = useContext(FormContext);

  const validation = {
    title: {
      required: 'Informe o título do anúncio!',
      minLength: { message: 'O título deve ter no mínimo 10 caracteres', value: 10 },
      maxLength: { message: 'O título deve ter no máximo 100 caracteres', value: 100 }
    },
    description: {
      required: 'Preencha a descrição!',
      minLength: { message: `Adicione mais descrição ao imóvel. Mínimo de ${50} caracteres`, value: 50 },
      maxLength: { message: `A descrição deve ter no máximo ${3000} caracteres`, value: 3000 }
    }
  }


  return (
    <>
      <S.Container>
        <S.Title>Descrição do anúncio</S.Title>

        <S.FormItem>
          <S.LabelEditor>Título do anúncio</S.LabelEditor>
          <Space direction="vertical">

            <S.Field
              {...register('title', validation.title)}
              type='text'
              placeholder="Ex.: Lindo apartamento com vista para o mar!"
            />
            <S.FieldFooter>
              {errors?.title &&
                <span className="txt-error">{errors.title.message}</span>
              }
              {watch('title')?.length !== undefined && <span className={validation.title.maxLength.value - watch('title').length < 0 ? 'count-caractere txt-error' : 'count-caractere'}>
                {validation.title.maxLength.value - watch('title')?.length}</span>}
            </S.FieldFooter>

          </Space>
        </S.FormItem>

        {/* bairro comercial ini */}
        <S.FormItem>
          <S.LabelEditor>Bairro Comercial (opcional)</S.LabelEditor>
          <Space direction="vertical">
            <S.Field
              {...register('commercialDistrict')}
              type='text'
              placeholder="Ex.: Barra Olímpica" />
          </Space>
        </S.FormItem>
        {/* bairro comercial fim */}

        {/* features ini */}
        <S.FormItem>
          <S.LabelEditor>Características do Imóvel</S.LabelEditor>
          <Space direction="vertical" >
            <div className={'content-select'}>
              <Controller
                name="propertyFeatures"
                control={control}
                rules={{
                  required: 'Selecione pelo menos uma característica!',
                }}
                render={({ field }) =>
                  <Select {...field}
                    value={field.value}
                    onChange={field.onChange}
                    options={options}
                    placeholder="Ex.: Varanda gourmet"
                    mode='multiple'
                    style={{
                      width: '100%',
                    }} />
                }
              />
            </div>
            <S.FieldFooter>
              {errors?.propertyFeatures &&
                <span className="txt-error">{errors.propertyFeatures.message}</span>
              }
            </S.FieldFooter>
          </Space>
        </S.FormItem>
        {/* features fim */}
        {/* description ini */}
        <S.FormItem>
          <Space direction="horizontal" style={{ justifyContent: 'space-between' }}>
            <S.LabelEditor>Descrição</S.LabelEditor>
            {propertyData.descriptionSuggestion && <S.TagValue activeStatus={watch('description') !== propertyData.descriptionSuggestion}
              onClick={() => setValue('description', propertyData.descriptionSuggestion)}
            >Seguir sugestão</S.TagValue>}
          </Space>
          <Controller
            name="description"
            control={control}
            rules={validation.description}
            render={({ field }) =>
              <TextEditor
                text={field.value}
                handlerText={field.onChange}
                height="200px"
                padding="0"
                margin="0.02rem 0px 2.5rem"
              />
            }
          />
          <S.FieldFooter>
            {errors?.description &&
              <span className="txt-error">{errors.description.message}</span>
            }
          </S.FieldFooter>
        </S.FormItem>
        {/* description fim */}
        {propertyData.images.length > 0 && <S.ContainerTitleImage>
          <S.TitlePhoto>Visualizar fotos ou vídeos do imóvel</S.TitlePhoto>
          <S.ContainerImage>
            {propertyData.images.map((item, index) =>
              <ListImages key={item.image} arrayImages={arrayImages} id={propertyData._id} images={propertyData.images} indexImage={index} />
            )}
          </S.ContainerImage>
        </S.ContainerTitleImage>}
      </S.Container>
    </>
  );
}
