import { notification } from "antd";


export const NOTIFICATION_TYPE = {
  error: {
    type: "error",
    message: "🤔 Ops!"
  },
  warning: {
    type: "warning",
    message: "Atenção!",
  },
  success: {
    type: "success",
    message: "Sucesso!",
  },
};

export const openNotification = (content='', notificationType='') => {
  notification[notificationType.type]({
    message: notificationType.message,
    description: content,
  });
};
