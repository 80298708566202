import React, { useEffect, useState } from 'react'
import Header from '../../components/adminHeader'
import Aside from '../../components/adminSideBar'
import { Container } from './styles'
import api from '../../api'
import { Link, useLocation } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { getUserSession } from '../../utils/sessionHelper'
import { Popconfirm } from "antd";
import Loading from '../../components/Loading'
import { getCsv, getUsers } from '../../services/user.service'
import { NOTIFICATION_TYPE, openNotification } from '../../utils/notification'

export default function AdminMarketingList() {
    const token = getUserSession();
    const location = useLocation();

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [isUser, setIsUser] = useState(false);
    const [algo, setAlgo] = useState('')
    const [acessos, setAcessos] = useState('')
    const [dias, setDias] = useState('')
    const [reEmail, setReEmail] = useState('')
    const [ordena, setOrdena] = useState('')
    const [lista, setListar] = useState('100')
    const [total, setTotal] = useState(0)

    const [page, setPage] = useState(0)
    const [lastPage, setLastPage] = useState(1)

    const [download, setDownload] = useState('')

    const CONFIRM_CONTEXT = {
        default: '',
        home: 'Home',
        catalog: 'Catalog',
        delete: 'Excluir',
        sold: 'Vendido',
        active: 'Ativo'
    };

    const [confirmLoading, setConfirmLoading] = useState(false);
    const defaultOpenConfirm = { state: false, id: 0, context: CONFIRM_CONTEXT.default };
    const [openConfirm, setOpenConfirm] = useState(defaultOpenConfirm);
    const isOpen = (id, context) => {
        return openConfirm.state && openConfirm.context === context && openConfirm.id === id;
    }
    const handlerOpenConfirm = (state = defaultOpenConfirm.state, id = defaultOpenConfirm.id, context = defaultOpenConfirm.context) => {
        setOpenConfirm({
            state,
            id,
            context,
        })
    }

    const onConfirm = async (callback) => {
        try {
            setConfirmLoading(true);
            await callback();
        } finally {
            setConfirmLoading(false);
            handlerOpenConfirm(false);
        }
    };

    useEffect(() => {
        if (localStorage.getItem('type_user') != 'admin') {
            window.location.href = '/login'
        }
        handleLoad()
        showIsProfile()
    }, [])

    function cleanStates() {
        setAlgo('')
        setAcessos('')
        setDias('')
        setListar('100')
        setReEmail('')
        setOrdena('')
        setTotal('')
    }

    async function handleLoad() {
        setLoading(true)
        cleanStates()
        const response = await api.get(`/user?limit=${lista}`)

        setData(response.data.users)
        setLastPage(response.data.pages)
        setPage(0)
        setTotal(response.data.total)

        setLoading(false)
    }

    function baseRequest() {


        // let querys = `?`

        // if(algo){
        //     querys+=`buscaLivre=${algo}&`
        // }

        // if(acessos && dias){
        //     querys+=`betweenDate=${dias}&acesso=${acessos}&`
        // }

        // if(reEmail){
        //     querys+=`receiveEmail=${reEmail}&`
        // }

        // if(ordena){
        //     querys+=`order=${ordena}&`
        // }

        const request = {};

        if (algo) {
            request.buscaLivre = algo;
        }

        if (acessos && dias) {
            request.betweenDate = dias;
            request.acesso = acessos;
        }

        if (reEmail) {
            request.receiveEmail = reEmail;
        }

        if (ordena) {
            request.order = ordena;
        }

        return request;
    }

    function handleApply() {
        setLoading(true)
        var request = baseRequest();

        if (lista) {
            switch (lista) {
                case '5':
                    request.limit = 5;
                    break
                case '10':
                    request.limit = 10;
                    break
                case '15':
                    request.limit = 15;
                    break
                case '20':
                    request.limit = 20;
                    break
                case '50':
                    request.limit = 50;
                    break
                case '100':
                    request.limit = 100;
                    break
                case '500':
                    request.limit = 500;
                    break
                case '99999':
                    request.limit = 99999;
                    break
            }
        }

        getUsers(request)
            .then(({ users, pages, total }) => {
                setData(users);
                setLastPage(pages);
                setTotal(total);
                setLoading(false)
            })
            .catch(({ userMessage, message }) => {
                console.error(message);
                openNotification(userMessage || 'Falha ao tentar aplicar filtros!', NOTIFICATION_TYPE.error);
                setLoading(false);
            });


    }


    function handleListAll(pages, e) {
        setLoading(true)
        var request = baseRequest()
        setListar(e.target.value)
        getUsers({ ...request, limit: e.target.value, page: 1 })
            .then(({ users, pages, total }) => {
                setTotal(total);
                if (users) {
                    setData(users);
                    setLastPage(pages);
                    setPage(0)
                }
                setLoading(false)
            })
            .catch(({ userMessage, message }) => {
                console.error(message);
                openNotification(userMessage || 'Falha ao tentar aplicar filtros!', NOTIFICATION_TYPE.error);
                setLoading(false);
            });
    }

    function handlePage(pages) {
        setLoading(true)
        var request = baseRequest()
        getUsers({ ...request, limit: lista, page: pages.selected + 1 })
            .then(({ users, pages: page, total }) => {
                setTotal(total);
                if (users) {
                    setData(users);
                    setLastPage(pages);
                    setPage(pages.selected);
                }
                setLoading(false)
            })
            .catch(({ userMessage, message }) => {
                console.error(message);
                openNotification(userMessage || 'Falha ao tentar aplicar filtros!', NOTIFICATION_TYPE.error);
                setLoading(false);
            });
    }

    async function handleDelete(id) {
        setLoading(true)
        let response = await api.delete(`/user/${id}`)

        if (response.data.deletedId) {
            let vector = []
            data.map(e => {
                if (e._id != id) {
                    vector.push(e)
                }
            })
            setData(vector)
        }
        setLoading(false)
    }

    function toDate(dateStr) {
        var parts = dateStr.split("T")[0].split('-');

        return `${parts[2]}/${parts[1]}/${parts[0]}`;
    }

    function showIsProfile() {
        const id = location.search.split("=");

        setIsUser(token.sub === id[1]);
    }


    async function downloadCsv() {
        setLoading(true);
        try {
            const data = await getCsv({ ...baseRequest(), limit: 99999 });
            if (data) {
                const url = URL.createObjectURL(new Blob([data], {type: 'text/csv'}));
                var a = document.createElement("a");
                a.href = url || 'data:text/csv,' + data;
                a.target = '_blank';
                a.download = 'corretores.csv';
                a.click();
                document.body.appendChild(a);
            }
            setLoading(false);

        } catch (error) {
            const { userMessage, message } = error;
            console.error(message);
            openNotification(userMessage || 'Falha ao tentar fazer download!', NOTIFICATION_TYPE.error);
            setLoading(false);
        }

    }

    function innerRow(row) {
        return (
            <>
                <div className="body-active">
                    <div>
                        <p style={{ fontWeight: 600 }}>{row.name}</p>
                        <p>Email: {row.email}</p>
                        <p>CPF: {row.cpf} - Creci: {row.creci}</p>
                        <p>Data Cadastro: {toDate(row.createdAt)}</p>
                        {row.lastAccess ? (
                            <p>Último acesso: {toDate(row.lastAccess)}</p>
                        ) : ''}
                    </div>
                </div>
                <div className="body-imovel">
                    <div className="opt-left">
                        <p>{!row.receiveEmail ? row.receiveEmail ? 'Sim' : 'Não' : 'Sim'}</p>
                    </div>
                </div>
                <div className="body-actions">
                    <div>
                        <a style={{ fontSize: window.innerWidth <= 600 ? '2.5vh' : '1.3vw' }}
                            onClick={e => window.location.href = `/admin/marketing/editar?id=${row._id}`}
                        >
                            {isUser ? 'Editar' : 'Visualizar'}
                        </a>
                    </div>
                    <Popconfirm
                        placement="top"
                        title={`Imóvel ${row._id}`}
                        description='Deseja excluir esse corretor?'
                        onConfirm={() => onConfirm(async () => await handleDelete(row._id))}
                        open={isOpen(row._id, CONFIRM_CONTEXT.delete)}
                        okButtonProps={{
                            loading: confirmLoading,
                        }}
                        onCancel={() => handlerOpenConfirm()}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <div>
                            <a onClick={() => handlerOpenConfirm(true, row._id, CONFIRM_CONTEXT.delete)} style={{ fontSize: window.innerWidth <= 600 ? '2.5vh' : '1.3vw' }} >Excluir</a>
                        </div>
                    </Popconfirm>
                </div>
            </>
        )
    }

    return (
        <>
            {loading && <Loading />}
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">
                        MARKETING >
                    </h1>
                    <Link to={window.location.pathname} className="where">
                        Lista de Corretores
                    </Link>
                </div>
                <div className="filter">
                    <div className="input-group">
                        <label htmlFor="">Digite algo</label>
                        <input value={algo} onChange={e => setAlgo(e.target.value)} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Acessos</label>
                        <select value={acessos} onChange={e => setAcessos(e.target.value)}>
                            <option></option>
                            <option value={1}>Acessou o site nos últimos </option>
                            <option value={2}>Não acessa o site por mais de </option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Dias</label>
                        <input type='number' min='0' value={dias} onChange={e => setDias(e.target.value)} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Recebe e-mail</label>
                        <select value={reEmail} onChange={e => setReEmail(e.target.value)}>
                            <option></option>
                            <option value={true}>Sim</option>
                            <option value={false}>Não</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Ordenar por</label>
                        <select value={ordena} onChange={select => setOrdena(select.target.value)}>
                            <option value={''}>Ordenar por</option>
                            <option value={'a-z'}>Ordem Alfabética (A-Z)</option>
                            <option value={'z-a'}>Ordem Alfabética (Z-A)</option>
                            <option value={'recentes'}>Cadastros Mais Recentes</option>
                            <option value={'antigos'}>Cadastros Mais Antigos</option>
                            <option value={'acesso-recente'}>Data do último acesso (mais recente p/ mais antigo)</option>
                            <option value={'acesso-antigo'}>Data do último acesso (mais antigo p/ mais recente)</option>
                            <option value={'visualizou-mais'}>Quem viu mais imóveis</option>
                            <option value={'visualizou-menos'}>Quem viu menos imóveis</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Listar todos</label>
                        <select value={lista} onChange={select => handleListAll(page, select)}>
                            <option value='100'>Listar todos</option>
                            <option>5</option>
                            <option>10</option>
                            <option>15</option>
                            <option>20</option>
                            <option>50</option>
                            <option>100</option>
                            <option>500</option>
                            <option value='99999'>1000+</option>
                        </select>
                    </div>
                    <div className="button-group">
                        <button onClick={handleLoad} className="outline">Remover filtros</button>
                        <button onClick={handleApply} className="full">Aplicar filtros</button>
                    </div>
                    <p style={{ width: '100%', textAlign: 'end' }}>A busca livre é feita no nome e e-mail do corretor. O número de dias inclui a data de hoje.</p>
                </div>

                <div style={{ display: 'flex', flexDirection: window.innerWidth <= 600 ? 'column' : 'row', justifyContent: 'space-between', marginTop: 20, textAlign: window.innerWidth <= 600 ? 'center' : 'unset' }}>
                    <p style={{ textAlign: window.innerWidth <= 600 ? 'center' : 'unset' }}>Foram encontrados {total} corretores.</p>
                    <a onClick={async ()=> await downloadCsv()} style={{ color: '#00AEC8', textDecoration: 'none', cursor: 'pointer' }}>Fazer download da lista de corretores</a>
                </div>


                <div className="header" style={{ marginTop: 30 }}>
                    <div className="cod">
                        <h2>CÓD.</h2>
                    </div>
                    {
                        window.innerWidth <= 600 ?
                            <div style={{ overflow: 'auto', display: '-webkit-inline-box', width: '82%' }}>
                                <div className="active">
                                    <h2>CORRETOR</h2>
                                </div>
                                <div className="imovel">
                                    <h2>RECEBE E-MAIL</h2>
                                </div>
                                <div className="engajamento">
                                    <h2>AÇÕES</h2>
                                </div>
                            </div>
                            :
                            <>
                                <div className="active">
                                    <h2>CORRETOR</h2>
                                </div>
                                <div className="imovel">
                                    <h2>RECEBE E-MAIL</h2>
                                </div>
                                <div className="engajamento">
                                    <h2>AÇÕES</h2>
                                </div>
                            </>
                    }
                </div>

                {
                    data.map(row => (
                        <div key={row._id} className="body">
                            <div className="body-cod">{row._id}</div>
                            {
                                window.innerWidth <= 600 ?
                                    <div style={{ overflow: 'auto', display: '-webkit-inline-box', width: '82%' }}>
                                        {innerRow(row)}
                                    </div>
                                    :
                                    <>
                                        {innerRow(row)}
                                    </>
                            }
                        </div>
                    ))
                }


                <div className="paginate">
                    <ReactPaginate
                        pageCount={lastPage}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={e => handlePage(e)}
                        forcePage={page}
                        previousLabel={"Anterior"}
                        nextLabel={"Proximo"}
                        breakLabel={<a href="">...</a>}
                        breakClassName={"break-me"}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </div>
            </Container>
        </>
    )
}