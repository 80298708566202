import React, { useState, useEffect } from "react";
import * as S from "./styles";
import "../../../../global.css";
import { Divider, Select, Space, Form } from 'antd';
import * as Yup from 'yup';

export default function AdAboutView({ data, }) {

  return (
    <>
      <S.Container>
        <S.Title>Sobre</S.Title>

        <S.ContentCharacteristics>
          <S.TitleCharacteristics>Metragem</S.TitleCharacteristics>
          <S.ContentCharacteristicsValue>
            <S.Question>Quantos m²?</S.Question>
            <S.ValueQuestion>{data.fullLandSize} m²`</S.ValueQuestion>
          </S.ContentCharacteristicsValue>
        </S.ContentCharacteristics>
        <Divider />


        <S.ContentCharacteristics>
          <S.TitleCharacteristics>Quartos</S.TitleCharacteristics>
          <S.ContentCharacteristicsValue>
            <S.Question>Quantos quartos totais o imóvel possui?</S.Question>
            <S.ValueQuestion>{data.bedrooms}</S.ValueQuestion>
          </S.ContentCharacteristicsValue>
          <S.ContentCharacteristicsValue>
            <S.Question>Quantos deles são suítes?</S.Question>
            <S.ValueQuestion>{data.suites}</S.ValueQuestion>
          </S.ContentCharacteristicsValue>
        </S.ContentCharacteristics>
        <Divider />
        <S.ContentCharacteristics>
          <S.TitleCharacteristics>Banheiros</S.TitleCharacteristics>
          <S.ContentCharacteristicsValue>
            <S.Question>Incluindo suítes e sem incluir lavabos e banheiros de serviço</S.Question>
            <S.ValueQuestion>{data.bathrooms}</S.ValueQuestion>
          </S.ContentCharacteristicsValue>
        </S.ContentCharacteristics>
        <Divider />
        <S.ContentCharacteristics>
          <S.TitleCharacteristics>Vagas na Garagem</S.TitleCharacteristics>
          <S.ContentCharacteristicsValue>
            <S.Question>Considerando apenas vaga de carro</S.Question>
            <S.ValueQuestion>{data.parkingSpots}</S.ValueQuestion>
          </S.ContentCharacteristicsValue>
        </S.ContentCharacteristics>
      </S.Container>
    </>
  );
}
