import React, { useState, useEffect } from 'react'
import { Container } from './style'

function Picker({setValue, value}) {

    const [data, setData] = useState([
        {
            name: 'Asfaltado'
        },
        {
            name: 'Acesso para Deficientes'
        },
        {
            name: 'Automação Predial'
        },
        {
            name: 'Bar'
        },
        {
            name: 'Brinquedoteca'
        },
        {
            name: 'Câmeras de Segurança'
        },
        {
            name: 'Churrasqueira'
        },
        {
            name: 'Elevador'
        },
        {
            name: 'Espaço Gourmet'
        },
        {
            name: 'Estacionamento'
        },
        {
            name: 'Estacionamento para Visitante'
        },
        {
            name: 'Fitness/Sala de Ginástica'
        },
        {
            name: 'Jardim/Área Verde'
        },
        {
            name: 'Parque'
        },
        {
            name: 'Piscina'
        },
        {
            name: 'Piscina Infantil'
        },
        {
            name: 'Playground'
        },
        {
            name: 'Portão Eletrônico'
        },
        {
            name: 'Portaria 24 horas'
        },
        {
            name: 'Próximo ao Metrô'
        },
        {
            name: 'Quadra Poliesportiva'
        },
        {
            name: 'Restaurante'
        },
        {
            name: 'Salão de Beleza'
        },
        {
            name: 'Salão de Festas'
        },
        {
            name: 'Salão de Jogos'
        },
        {
            name: 'Sauna'
        },
        {
            name: 'Sistema de Alarme'
        },
        {
            name: 'Varanda'
        },
    ])

    useEffect(() => {
        setFiltered(data)
    }, [data])

    const [filtered, setFiltered] = useState([])

    const [tags, setTags] = useState([])

    const filter = (text) => {
        setFiltered(data.filter(value => value.name.toLowerCase().trim().includes(text.toLowerCase().trim())))
    }

    function addTag(tagname) {
        setTags([...tags, { name: tagname }])
        setValue([...tags, { name: tagname }])

        let tagRepeat = tags.filter(tag => {
            return tag.name == tagname
        }) 

        if(tagRepeat.length > 0){
            let ar = []
            tags.map(tag => {
                tagRepeat.map(repeat => {
                    if(tag.name != repeat.name){
                        ar.push(tag)
                    }else{
                        ar.push(tag)
                    }
                })
            })
            setTags(ar)
            setValue(ar)
        }
    }

    function removeTag(tagname) {
        setTags(tags.filter(value => value.name !== tagname))
        setValue(tags.filter(value => value.name !== tagname))
    }

    return (
        <Container>
            <div className="header" style={{padding: '3px'}}>
                {
                    value ? value.map((value) => (
                        <div className="item" key={value.name}>
                            <p style={{inlineSize: 'max-content'}}>{value.name}</p>
                            <button onClick={() => removeTag(value.name)}>x</button>
                        </div>
                    ))
                    : tags.map((value) => (
                        <div className="item" key={value.name}>
                            <p style={{inlineSize: 'max-content'}}>{value.name}</p>
                            <button onClick={() => removeTag(value.name)}>x</button>
                        </div>
                    ))
                }
            </div>
            <div className="input-wrapper">
                <input type="text" placeholder="Buscar..." onChange={(e) => filter(e.target.value)} />
                <img src={require('../../assets/picker/searchIcon.svg')} alt="" />
            </div>
            <div className="tags">
                {
                    filtered.map((value) => (
                        <button onClick={()=> addTag(value.name)} key={value.name}>
                            {value.name}
                        </button>
                    ))
                }
            </div>
        </Container>
    )
}

export default Picker
