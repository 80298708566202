import styled from "styled-components";

export const Container = styled.div`
  .column {
      display: flex;
      flex-direction: column;
      width: 50%;

      textarea {
        height: 100%;
        border: 1px solid #8b9495;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 1%;
        margin-bottom: 3%;
        resize: none;
        padding: 10px;
      }

      .input-compose {
        width: 100%;
        background: #dadada;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 20px;

        p {
          margin-left: 0px;
          width: 100%;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.05em;
          color: #1e1d1d;
        }

        input {
          width: 100%;
          border: none;
          padding-top: 5px;
          border-bottom: 1px solid #c4c4c4;
          background: transparent;
        }
      }
    }

   .radio-group {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 3%;

      @media only screen and (max-width: 600px) {
        width: 100%;
        margin-top: 10px;
      }
      span {
        margin-left: 5px;
      }
      h1 {
        margin-left: 5px;
      }
    }

  .save-button {
    margin-top: 5px;
    width: 199px;
      height: 45px;
      background: linear-gradient(90deg, #ea5c1e 0%, #c13818 100%);
      border-radius: 4px;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.05em;
      border: none;
      color: #ffffff;
  }

  margin-left: 20vw;
  width: 80vw;
  height: 100%;
  padding: 30px;
  padding-right: 20vw;

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-left: 0;
    padding-right: 0;
    padding: 30px;
  }

  .bread-crumb {
    display: flex;
    align-items: baseline;
    margin-bottom: 1rem;
    margin-top: 10vh;

    @media only screen and (max-width: 600px) {
      margin-top: 1vh;
      flex-direction: column;
    }

    .category {
      color: #0a677c;
      margin-right: 3px;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.05em;
    }

    .where {
      color: #eb5c1c;
      margin-bottom: 10px;
      text-decoration: none;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.05em;
    }
  }

  .input-group {
    display: flex;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    .select-group {
      display: flex;
      align-items: center;
      width: 50%;

      @media only screen and (max-width: 600px) {
        width: 100%;
        margin-top: 10px;
      }
      span {
        margin-left: 5px;
      }
    }

    .input-compose {
      width: 100%;
      background: #dadada;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 20px;
      margin-right: 5px;

      input,
      select,
      textarea {
        width: 100%;
        border: none;
        padding-top: 5px;
        border-bottom: 1px solid #c4c4c4;
        background: transparent;
        resize: none;
      }

      label {
        margin-left: 0px;
        width: 100%;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.05em;
        color: #1e1d1d;
      }

    .key-squeme {
      color: red !important;
    }

    .input-compose {
      width: 100%;
      background: #dadada;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 20px;
    }

    .input-compose + .input-compose {
      margin-left: 10px;

      @media only screen and (max-width: 600px) {
        margin-left: 0;
      }
    }
  }

  .share {
    display: flex;
    width: 50%;
    text-align: justify;
    border-top: 5px solid #c3c8c9;
    border-bottom: 5px solid #c3c8c9;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    margin-bottom: 15px;

    .row {
      display: flex;

      input {
        margin-right: 5px;
        margin-top: 8px;
      }
    }

    .text {
      width: 50%;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */
      margin-left: 20px;
      letter-spacing: 0.05em;

      color: #1e1d1d;
    }

    .column + .column {
      margin-left: 10px;

      @media only screen and (max-width: 600px) {
        margin-left: 0;
      }
    }
  }

  .save {
    width: 100%;
    text-align: right;
    margin-top: -70px;

    @media only screen and (max-width: 600px) {
      margin-top: 0;
      button {
        width: 100%;
      }
    }
  }
`;

export const ContentPartnerProperties = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  .title-partner-properties {
    margin-left: 0px;
    margin-right: 10px;
    width: 100%;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #1e1d1d;
  }
`

export const SwitchPartnerProperties = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;

  .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImageItem = styled.label`
  min-width: 178px;
  min-height: 128px;
  border-radius: 4px;
  background: url(${(props) =>
    props.image
      ? props.image
      : require("../../assets/adminOfferProperty/imgplaceholder.svg")});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;

  input {
    display: none;
  }

  .close {
    display: ${(props) => (props.children[1].props.show ? "flex" : "none ")};
    position: absolute;
    width: 16px;
    height: 16px;
    background: #ffffff;
    right: 10px;
    top: 10px;
    border: none;
    font-size: 16px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
  }
`;
export const BtnWrapper = styled.div`
  width: 100%;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlusBtn = styled.button`
  margin-left: 20px;
  background-color: transparent;
  border: none;
`;

export const Carousel = styled.div`
  width: 100%;
  height: auto;
  overflow-x: auto;
  display: flex;
`;

export const DocItem = styled.label`
  min-width: 178px;
  min-height: 128px;
  border-radius: 4px;
  background: url(${(props) =>
    props.image
      ? props.image
      : require("../../assets/adminOfferProperty/docplaceholder.svg")});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;

  input {
    display: none;
  }

  .close {
    display: ${(props) => (props.children[1].props.show ? "flex" : "none ")};
    position: absolute;
    width: 16px;
    height: 16px;
    background: #ffffff;
    right: 10px;
    top: 10px;
    border: none;
    font-size: 16px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
  }
`;

export const BookItem = styled.label`
    min-width: 178px;
    min-height: 128px;
    border-radius: 4px;
    border: dashed 1px #A9A9A9;
    background:url(${(props) =>
    props.image
      ? props.image
      : require("../../assets/adminOfferProperty/docok.svg")});
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    position:relative;
    cursor:pointer;

    div {
        text-align: center;
        margin-top: 32%;
        color: black;
    }

    input{
        display:none;
    }

    .close{
        display : ${(props) =>
    props.children[1].props.show ? "flex" : "none "};
        position:absolute;
        width: 16px;
        height: 16px;
        background: #FFFFFF;
        right : 10px;
        top : 10px;
        border:none;
        font-size:16px;
        border-radius:8px;
        align-items:center;
        justify-content:center;
`;

export const DownloadLink = styled.a`
  font-weight: normal;
  font-size: 14px;
  margin: 10px;
  color: rgb(235, 92, 28);
  text-decoration: none;
`;

