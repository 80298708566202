import styled from "styled-components";

export const Container = styled.div`
  margin: 0 10%;
  text-align: center;
  .title {
    font-size: 45px;
    font-weight: 500;
  }
  .subtitle {
    font-size: 42px;
    font-weight: 500;
  }
  .content {
    font-size: 18px;
  }
`;
