import React, { useEffect } from 'react';
import Section from './section';
import { Anchor, Badge, Card, Col, Row, Tabs, Typography } from 'antd';
import { SECTION_TYPE } from '../constants';
import { SectionContainer, SectionsMenu } from '../styles';
import { useFormContext } from 'react-hook-form';
const props = {
  id: undefined,
  addField: (val) => console.warn('addField not implemented', val),
};
const Sections = ({ addField } = props) => {

  const identifier = 'sections';
  const sections = [
    {
      key: 'cover',
      label: 'Apartamentos com quartos e salas mobiliados',
      props: {
        prefix: `${identifier}.cover`,
        title: 'Capa',
        types: [
          SECTION_TYPE.image,
        ]
      },
    },
    {
      key: 'aboutVenture',
      label: 'Sobre o residencial',
      props: {
        prefix: `${identifier}.aboutVenture`,
        title: 'Sobre o residencial',
        types: [
          SECTION_TYPE.image,
        ]
      },
    },
    {
      key: 'aboutProperty',
      label: 'Sobre os apartamentos',
      props: {
        prefix: `${identifier}.aboutProperty`,
        title: 'Sobre os apartamentos'
        ,
        types: [
        ]
      },
    },
    {
      key: 'highlights',
      label: 'Diferenciais',
      props: {
        prefix: `${identifier}.highlights`,
        title: 'Diferenciais',
        topics: 6,
        types: [
          SECTION_TYPE.topicTitle
        ]
      },
    },
    {
      key: 'discoverAvailableOptions',
      label: 'Conheça os apartamentos disponíveis',
      props: {
        prefix: `${identifier}.discoverAvailableOptions`,
        title: 'Conheça os apartamentos disponíveis'
        ,
        types: [
        ]
      },
    },
    {
      key: 'location',
      label: 'Localização Privilegiada',
      props: {
        prefix: `${identifier}.location`,
        title: 'Localização Privilegiada'
        ,
        types: [
        ]
      },
    },
    {
      key: 'interested',
      label: 'Para compradores ou corretores',
      props: {
        prefix: `${identifier}.interested`,
        title: 'Para compradores ou corretores'
        ,
        types: [
        ]
      },
    },
    {
      key: 'aboutCompany',
      label: 'Mas quem é a Imóvel Vazio?',
      props: {
        prefix: `${identifier}.aboutCompany`,
        title: 'Mas quem é a Imóvel Vazio?',
        topics: 4,
        types: [
          SECTION_TYPE.image,
          SECTION_TYPE.topicDescription,
          SECTION_TYPE.topicTitle
        ]
      },
    },
  ];


  useEffect(() => {
    addField(sections.map(({ key }) => key));
  }, []);

  const {
    formState: { errors, },
  } = useFormContext();

  const countErrors = (sectionKey = '') => {
    if (!(errors?.sections && errors?.sections[sectionKey])) return 0;
    const entries =
      Object
        .entries(errors.sections[sectionKey])
        .reduce((acc, [key, val]) =>
          ((Array.isArray(val)) ? val.reduce((x, y) => x + (y ? 1 : 0), 0) : 1) + acc
          , 0);
    return entries;
  };

  return (
    <>
      <Row>
        <Col span={16}>
          <SectionContainer>
            {sections.map(
              ({ key, label, props }) =>
                <Card style={{ margin: '0 0 2rem' }}
                  bordered
                  key={props.prefix}
                  id={key}
                  title={label} >
                  <Section key={`${key}.section`} {...props} />
                </Card>
            )}
          </SectionContainer>
        </Col>
        <Col span={8}>
          <Anchor
            affix
            offsetTop={100}
            direction='vertical'
            items={
              sections.map(
                ({ key, label }) => ({
                  href: `#${key}`,
                  title: <SectionsMenu><Badge count={countErrors(key)} />  {label}</SectionsMenu>,
                  key,
                  replace: true,
                })
              )
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default Sections;
