import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
`;

export const Filename = styled.span`
  margin-left: 1rem;
`;

export const ErrorMessage = styled.span`
  color: red;
`;