import React, { useEffect, useState } from "react";
import * as S from './style'
import logo from "../../../../assets/logo.svg";
import logoWhite from '../../../../assets/logoWhite.svg'
import Button from "../../Button";
import { PlayCircleOutlined, ArrowRightOutlined, MenuOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

export default function HeaderLP({setShowModal, visible, setVisible, user, city}) {
  const [navbar, setNavBar] = useState(false);
  const [open, setOpen] = useState(false)
  
  const handleToggle = () => {
    setVisible((current) => !current);
  };

  const resetPageLP = () => {
    window.scrollTo({
      top: 0
    })
    setVisible(false)
    setOpen(false)
  }

  const scrollAndCloseMenu = (option, item) => {
    let optionMenu = document.getElementById(option);
    item.preventDefault();  
    optionMenu && optionMenu.scrollIntoView();
    setVisible(false)
    setOpen(false)
  }

      const changeNavBar = () => {
        if(window.scrollY >= 10) {
            setNavBar(true);
        } else {
            setNavBar(false)
        }
    }

    window.addEventListener('scroll', changeNavBar);

    return (
    <>
    <S.Bar navbar={navbar}>
      {window.innerWidth >= 1100 ? 
      (
       <>
      <S.Main>
      <Link to="/">
       <S.Logo src={navbar ? logo : logoWhite}  />
      </Link>
          <S.MainNav navbar={navbar} buttonResponsive={visible}>
            <S.NavLi>
              <S.NavLink navbar={navbar} href="/"
                onClick={e => {
                let services = document.getElementById("services");
                e.preventDefault();  
                services && services.scrollIntoView();
                }}>O que fazemos</S.NavLink>
            </S.NavLi>
            <S.NavLi>
              <S.NavLink navbar={navbar} onClick={e => {
                let story = document.getElementById("story");
                e.preventDefault();  
                story && story.scrollIntoView();
                }}>Nossa História</S.NavLink>
            </S.NavLi>
            <S.NavLi>
              <S.NavLink navbar={navbar} 
              onClick={e => {
                let platform = document.getElementById("platform");
                e.preventDefault();  
                platform && platform.scrollIntoView();
                }}
              >Nossa Plataforma</S.NavLink>
            </S.NavLi>
            <S.NavLi>
              <S.NavLink navbar={navbar} 
                onClick={e => {
                  let go_together = document.getElementById("go_together");
                e.preventDefault();  
                go_together && go_together.scrollIntoView();}}
              >Vamos Juntos</S.NavLink>
            </S.NavLi>
          </S.MainNav>
          </S.Main>
          <S.Btns buttonResponsive={visible}>
          {navbar &&
            <S.BtnPlayVideo navbar={navbar} onClick={() => setShowModal(true)}>
              <a>
                <span>Assistir Vídeo</span>
                <PlayCircleOutlined className="icon-play" />
              </a>
            </S.BtnPlayVideo>}
            {user !== undefined ?
              <S.BtnSeeProperties navbar={navbar}>
              <Link to={`/imoveis?city_id=${city}`}>
                <span className="title-button">
                  Conferir Imóveis
                </span>
                <ArrowRightOutlined className="icon-arrow" />
              </Link>
              </S.BtnSeeProperties> 
               :
              <Button navbar={navbar} />
            }
        </S.Btns>
          </>)
           :
          (
          <>
          <S.NavMenu>
          <Link to="/">
          <S.Logo src={navbar ? logo : logoWhite} />
          </Link>
          <S.NavBarToggle onClick={() => handleToggle()}>
            <S.StyledBurger navbar={navbar} open={open} onClick={() => setOpen(!open)}>
              <div />
              <div />
              <div />
            </S.StyledBurger>
          </S.NavBarToggle>
          </S.NavMenu>
          <S.NavMenuItems navbar={navbar} buttonResponsive={visible}>
          <S.MainNav navbar={navbar} buttonResponsive={visible}>
            <S.NavLi>
              <S.NavLink navbar={navbar} 
              onClick={e => scrollAndCloseMenu("services", e) }
              >
                O que fazemos
              </S.NavLink>
            </S.NavLi>
            <S.NavLi>
              <S.NavLink navbar={navbar} 
              onClick={e => scrollAndCloseMenu("story", e)}>
                Nossa História</S.NavLink>
            </S.NavLi>
            <S.NavLi>
              <S.NavLink navbar={navbar}  
              onClick={e =>  scrollAndCloseMenu("platform", e)}>
                Nossa Plataforma</S.NavLink>
            </S.NavLi>
            <S.NavLi>
              <S.NavLink navbar={navbar} 
              onClick={e => scrollAndCloseMenu("go_together", e)}>
                Vamos Juntos</S.NavLink>
            </S.NavLi>
          </S.MainNav> 
         {visible && <S.Divider />}
          <S.Btns buttonResponsive={visible}>
              <S.BtnPlayVideo navbar={navbar} onClick={() => setShowModal(true)}>
                <a className="linkButtonPlay">
                  <span>Assistir Vídeo</span>
                  <PlayCircleOutlined className="icon-play" />
                </a>
              </S.BtnPlayVideo>
            {user !== undefined ?
              <S.BtnSeeProperties navbar={navbar}>
                <Link to={`/imoveis?city_id=${city}`}>
                  <span className="title-button">
                    Conferir Imóveis
                  </span>
                  <ArrowRightOutlined className="icon-arrow" />
                </Link>
              </S.BtnSeeProperties> 
               :
              <Button navbar={navbar} />
            }
          </S.Btns>
          </S.NavMenuItems>
          </>)}
    </S.Bar>
    </>
  )}