import styled from 'styled-components';
import { Card } from 'antd';
export const Container = styled.div`
  display: inline-flex;
`;

export const DragContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ImageContainer = styled(Card)`
  width: 260px; 
  min-height: 235px;
  .ant-card-body {
    padding: 0.5rem;
  }

  .ant-card-head {
    min-height: 2rem;
  }
  .ant-card-meta-description {
    text-align: center;
  }
`;

export const Frame = styled.div`
  border: 10px solid #737373;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  /* margin: 1rem 0; */
  text-align: center;
  max-width: fit-content;
  align-self: center;

  img {
    max-width: 100%;
    max-height: 205px;
    object-fit: cover;
  }
`;