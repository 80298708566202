export const getUserName = (name) => {
    if(name === '') return;
    let parts = name.split(' ');

    if(parts[0].includes('.') && parts.length > 3){
        return `${parts[1]} ${parts[2]}...`
    } 

    if(parts[0].includes('.') && parts.length === 3) {
        return `${parts[1]} ${parts[2]}`
    }

    if(parts.length >= 3) {
        return `${parts[0]} ${parts[1]}...`
    }

    return name;
}