import api from "../api";
import queryString from "../utils/queryString";

export const getPropertiesAsync = ({ limit = 20, arquive = false, orderby = { _id: -1 }, ...rest }, isAuth = false, cancelToken) => {
  const request = { ...rest, limit, arquive };
  const url = isAuth ? 'property/auth' : 'property';
  return api.get(
    `/${url}${queryString(request)}`,
    {
      headers: {
        orderby: JSON.stringify(orderby),
        cancelToken
      },

    }
  ).catch(({ response: error }) => {
    const { status, data } = error;
    localStorage.clear();
    console.error(data);
    if (status === 401) window.location.href = "/login";
  });

};
