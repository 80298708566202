import styled from 'styled-components';

export const Container = styled.div`
  
    height:90vh;
    width:100vw;
    display: flex;
    justify-content: center;
    align-items: center;


 form{
    width:100vw;
    max-width:350px;
    height: 90vh;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
    padding:10px;
}

 form h1{
    text-align: center;
    color: #0A677C;
}

.inputs{
    width: 100%;
    margin-bottom: 20px;;
}


 form .inputs .input-group{
    width: 100%;
    padding-top: 20px;
}

 form .inputs .input-group input{
    width:100%;
    height: 35px;
    border-radius: 8px;
    border:none;
    border-bottom: 1px solid #ccc;
    padding-left:20px
}

 form .inputs .input-group label{
    margin-left: 0px;
    text-align: left;
    font-weight: bold;
}

 form button{
    height:35px;
    color: #0A677C;
    background: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
}

`;
