import React from "react";
import Header from "../../components/header";
import Footer from "../../components/Footer";
import { Body, ImageWrapper, TextWrapper } from "./styles";
import { Descriptions, Table } from "antd";

export default function Privacy() {
  const finalities = [
    {
      finalidades: "Cadastro",
      dadosPessoais: ["Dados de identificação de contato"],
      basesLegais: "Consentimento",
    },
    {
      finalidades: "Acesso",
      dadosPessoais: ["E-mail e Senha"],
      basesLegais: "Execução de contrato",
    },
    {
      finalidades: "Pagamento de comissão",
      dadosPessoais: ["DAdos de identificação e contato", "Dados de pagamento"],
      basesLegais: "Execução de contrato",
    },
    {
      finalidades: "Avaliação de financiamento",
      dadosPessoais: [
        "CPF",
        "Data de nascimento",
        "Renda bruta",
        "Valor a ser financiado",
        "Valor total do imóvel",
        "Utilização de FGTS",
        "Regima de contratação",
      ],
      basesLegais: "Consentimento",
    },
    {
      finalidades: "E-mail Marketing",
      dadosPessoais: ["Nome", "E-mail"],
      basesLegais: "Legítimo Interesse",
    },
    {
      finalidade: "Verificação de Ônus em Imóveis",
      dadosPessoais: ["Dados constantes em certidção de Ônus Reais"],
      basesLegais: "Legítimo interesse",
    },
  ];

  const legalObligation = [
    {
      motivacao: "Geral",
      tipo: "Ação Judicial (Cível)",
      norma: "Art. 205 do Código Civil",
      prazo: "10 anos, quando a lei não fixar prazo menor",
    },
    {
      motivacao: "Reparação Civil",
      tipo: "Ação Judicial (Cível)",
      norma: "Art. 206, § 3°, V do Código Civil",
      prazo: "3 anos",
    },
    {
      motivacao: "Registro de acesso a aplicação de internet",
      tipo: "Dever de guarda previsto em lei",
      norma: "Art 15 da Lei n° 12.965/14",
      prazo: "6 meses",
    },
    {
      motivacao: "Crédito/Indenização Trabalhista",
      tipo: "Ação Judicial (Trabalhista)",
      norma: "Art. 7°, XXIX, da Constituição Federal",
      prazo:
        "5 anos ou em até 2 anos após extinção do Contrato de Trabalho (para os casos de dados dos colaboradores)",
    },
    {
      motivacao: "Crédito Tributário",
      tipo: "Execução Fiscal",
      norma: "Art. 174 do CTN",
      prazo: "5 anos",
    },
    {
      motivacao: "Cadastro Positivo",
      tipo: "Faculdade legal para banco de dados",
      norma: "Art. 14 da lei n° 12.414/11",
      prazos:
        "Dados não podem constar no banco de dados por prazo superior a 15 anos (prazo máximo)",
    },
    {
      motivacao: "Cadastro de consumidores",
      tipo: "Faculdade legal para banco de dados",
      norma: "Art. 43, §1°, do CDC",
      prazo: "5 anos (máximo, para informações negativas)",
    },
  ];

  return (
    <div>
      <Header />
      <Body>
        <ImageWrapper>
          <TextWrapper>
            <h1>Política de Privacidade</h1>
          </TextWrapper>
        </ImageWrapper>
        <div className="text">
          <p style={{ textAlign: "start" }}>
            Nossa Política de Privacidade busca reafirmar o compromisso da
            Imóvel Vazio com a adoção das melhores práticas de segurança,
            transparência e privacidade no acesso e tratamento das suas
            informações. Ela busca esclarecer como coletamos, usamos,
            armazenamos e tratamos os seus dados.
          </p>
          <h3 style={{ textAlign: "start" }}>I. Introdução</h3>
          <p style={{ textAlign: "start" }}>
            A presente Política de Privacidade tem por objetivo esclarecer e
            informar, de forma transparente, como a IMÓVEL VAZIO, plataforma
            digital de responsabilidade da IMOVEL VAZIO OPERACOES IMOBILIARIAS
            LTDA (“Nós” ou “IMÓVEL VAZIO”), trata os dados pessoais das pessoas
            com quem interage, na qualidade de Usuários do seu website, (a
            partir de agora denominados “Vocês”) são coletados, utilizados,
            armazenados, tratados e protegidos no contexto da utilização dos
            serviços oferecidos.
            <br />
            <br />
            Para os fins desta Política de Privacidade, a IMÓVEL VAZIO será
            considerada a Controladora de Dados. Os detalhes de contato são
            fornecidos na Seção XV abaixo.
            <br />
            Esta Política de Privacidade poderá ser alterada ou atualizada de
            tempos em tempos para refletir mudanças em nossas práticas com
            relação ao Tratamento de Dados Pessoais ou mudanças nas leis
            aplicáveis.
            <br />
            <br />
            <strong>
              <u>
                Ao se cadastrar ou usar, de qualquer forma, o website, bem como
                ao clicar “EU LI E ACEITO” ou similar, você concorda com esta
                Política de Privacidade, com todos os seus termos e condições.
                Nós recomendamos e encorajamos Você a ler esta Política de
                Privacidade com atenção, bem como a verificar regularmente esta
                página para revisá-la e ficar a par de quaisquer alterações que
                possamos realizar nos termos desta Política
              </u>
            </strong>
          </p>
          <h3 style={{ textAlign: "start" }}>
            II. Finalidade dessa Política de Privacidade
          </h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;2.1. Esperamos que o exposto ajude a compreender os
            nossos compromissos de privacidade para com os seus dados pessoais.
            De todo modo, para maiores esclarecimentos sobre os termos
            utilizados na presente Política, entre em contato conosco por
            qualquer um dos meios listados ao final dessa Política.
            <br />
            <br />
            &nbsp;&nbsp;2.2. Lembramos que, caso não concorde com o conteúdo da
            presente Política, você poderá optar por não utilizar os nossos
            serviços.
            <br />
            <br />
            &nbsp;&nbsp;2.3. O objetivo da presente Política é:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;(i) Garantir que Você compreende as razões
            pelas quais coletamos e utilizamos os seus dados pessoais e de que
            forma são compartilhados;
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;(ii) Explicar e dar transparência a quais
            dados são tratados por nós, a sua finalidades, e as operações de
            tratamento a que esses dados estão sujeitos, com fim de proporcionar
            a você a melhor experiência em relação aos nossos serviços; e
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;(iii) Explicar os seus direitos e opções de
            escolha referentes aos seus dados pessoais que coletamos e
            processamos e de que forma preservamos sua privacidade e cuidamos
            dos seus dados pessoais.
            <br />
            <br />
            &nbsp;&nbsp;2.4. Para atendermos aos objetivos descritos acima,
            devemos realizar diversas operações específicas de tratamentos de
            dados, dentre as quais se encontram aquelas referentes ao
            processamento de dados pessoais de nossos Usuários, conforme
            indicado na tabela da Seção V abaixo.
          </p>
          <h3 style={{ textAlign: "start" }}>III. Dados Pessoais</h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;3.1. Os dados pessoais que tratamos dos nossos Usuários
            estão listado abaixo e divididos conforme a sua categoria:
            <Descriptions
              className="description-table"
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              bordered
            >
              <Descriptions.Item label="Dados de identificação e contato">
                Nome
                <br />
                CPF
                <br />
                E-mail
                <br />
                Telefone
                <br />
                Inscrição CRECI
              </Descriptions.Item>
              <Descriptions.Item label="Dados de Pagamento">
                Informações de conta bancária/PIX
              </Descriptions.Item>
              <Descriptions.Item label="Dados de imóveis">
                Informações gerais sobre imóveis anunciados na plataforma,
                <br />
                incluindo endereço, área, cômodos e fotografias
              </Descriptions.Item>
            </Descriptions>
            <br />
            <br />
            &nbsp;&nbsp;3.2. Os dados dos imóveis apresentados, por si só, não
            configurarão dados pessoais senão quando associados a uma pessoa
            natural, de modo que, quando não estiverem associados a uma pessoa,
            não estarão sujeitos a legislação de privacidade e proteção de dados
            vigente ou a presente Política de Privacidade.
          </p>
          <h3 style={{ textAlign: "start" }}>IV. Coleta dos dados pessoais</h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;4.1. Os dados obtidos por Nós são fornecidos pelo
            próprio Usuário, por meio do nosso website ou via e-mail, conforme o
            caso.
          </p>
          <h3 style={{ textAlign: "start" }}>
            V. Processos de Tratamento de Dados
          </h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;5.1. Como mencionamos, para que nossos serviços sejam
            prestados, é necessário efetuarmos diversas operações com os dados
            pessoais mencionados acima, conforme a finalidade do Usuário.
            <br />
            <br />
            &nbsp;&nbsp;5.2. A seguir estão listados os processos em que
            utilizamos os dados pessoais dos nossos Usuários, divididos conforme
            o produto utilizado e a base legal utilizada para essa finalidade:
            <Table
              className="fin-table"
              dataSource={finalities}
              bordered={true}
              pagination={false}
              columns={[
                {
                  title: "Finalidades",
                  dataIndex: "finalidades",
                },
                {
                  title: "Dados pessoais",
                  dataIndex: "dadosPessoais",
                  render: (text) => (
                    <ul>
                      {text &&
                        text.map((item) => <li className="li-item">{item}</li>)}
                    </ul>
                  ),
                },
                {
                  title: "Bases Legais",
                  dataIndex: "basesLegais",
                },
              ]}
            />
            <br />
            &nbsp;&nbsp;5.3. Sem prejuízo do mencionado na tabela acima, as
            finalidades para as quais podemos processar dados pessoais, sujeito
            à lei aplicável, incluem:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;i)&nbsp;Prestação de serviços a você,
            disponibilizando nosso website a você; e promovendo o atendimento e
            o contato com você em relação a esses serviços;
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;ii)&nbsp;Boletins informativos e outras
            comunicações de marketing, comunicando-se com você por qualquer meio
            (incluindo e-mail, telefone, mensagem de texto, correio ou
            pessoalmente), e fornecendo informações relevantes sobre nossos
            serviços;
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;iii)&nbsp;Aprimorar funcionalidades de nosso
            website;
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;iv)&nbsp; Evitar e verificar casos de
            suspeita de fraudes, violação de direitos ou outros ilícitos
            praticados pelo usuário;
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;v)&nbsp; Confirmação de dados/informação de
            identificação solicitadas; ou
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;vi)&nbsp;Atender a ordens judiciais ou de
            órgãos competentes.
          </p>
          <h3 style={{ textAlign: "start" }}>
            Financiamento E Correspondente Bancário
          </h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;5.4. Nós, a fim de melhorar a experiência dos nossos
            usuários e seus clientes, oferecemos em nossa plataforma a
            possibilidade de verificação de condições de financiamento para
            aquisição de imóveis junto a parceiro correspondente bancário.
            <br />
            <br />
            &nbsp;&nbsp;5.5. Para tanto, mediante requerimento específico do
            Usuário, os dados para “Avaliação de Financiamento” serão fornecidos
            pelo Usuário mediante consentimento expresso de seu titular e
            compartilhados com o nosso parceiro: “
            <strong>ARG INVESTIMENTOS IMOBILIARIOS LTDA”.</strong>
            <br />
            <br />
            &nbsp;&nbsp;5.6. Importante destacar que, em primeira etapa de
            verificação, os dados fornecidos não possibilitam a identificação do
            seu titular, de modo que não estarão sujeitos a legislação de
            privacidade e proteção de dados vigente ou a presente Política de
            Privacidade e dispensarão a outorga de consentimento ou sujeição a
            qualquer outra base legal para tratamento dos dados.
            <br />
            <br />
            &nbsp;&nbsp;5.7. Todos os dados tratados pela IMÓVEL VAZIO seguirão
            o disposto na presente política de privacidade, contudo, eventuais
            serviços e dados fornecidos fora do ambiente de nossa
            plataforma/website não estão sob o controle da Imóvel Vazio.
            <br />
            <br />
            &nbsp;&nbsp;5.8. O usuário poderá ter acesso a links para outros
            sites e outros sites. Esses outros domínios e sites não são
            controlados por nós e a IMÓVEL VAZIO não endossa nem faz
            representações sobre sites de terceiros ou plataformas de mídia
            social. Incentivamos nossos usuários a ler as políticas de
            privacidade de todos os sites e aplicativos com os quais interagem.
          </p>
          <h3 style={{ textAlign: "start" }}>
            VI. Compartilhamento de Dados com Terceiros
          </h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;6.1. Também com o único fim de prestarmos os nossos
            serviços, atendendo ao Usuário e cumprindo com nossas obrigações
            legais, podemos compartilhar com terceiros alguns dos dados
            recebidos. Esses terceiros nos fornecem serviços como, hospedagem de
            sites, processamento de pagamentos, realização de pedidos,
            distribuição de produtos, fornecimento de infraestrutura, serviços
            de TI, atendimento ao cliente, serviços de entrega de e-mail, ,
            serviços de auditoria, verificações de segurança e outros serviços
            semelhantes.
            <br />
            <br />
            &nbsp;&nbsp;6.2. Nós da IMÓVEL VAZIO nos comprometemos a exigir de
            qualquer empresa contratada por nós o mesmo grau de segurança e
            atendimento as normas legais aplicáveis assumidos por nós em toda a
            nossa prestação de serviços.
            <br />
            <br />
            &nbsp;&nbsp;6.3. Nós utilizamos serviços de servidor e banco de
            dados remoto geridos pela Amazon Web Services (AWS), estando o
            servidor utilizado por Nós localizado no Brasil.
            <br />
            <br />
            &nbsp;&nbsp;6.4. Além disso, podemos divulgar seus dados pessoais
            para:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;i)&nbsp;Autoridades legais e regulatórias,
            mediante determinação ou para fins de relatar qualquer violação real
            ou suspeita da lei ou regulamento aplicável;
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;ii)&nbsp;Qualquer parte relevante, agência
            de aplicação da lei ou tribunal, na medida necessária para o
            estabelecimento, exercício ou eficácia dos direitos legais;
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;iii)&nbsp;Qualquer parte relevante para
            efeitos de prevenção, investigação, detecção ou repressão de
            infrações penais ou execução de sanções penais, incluindo a
            salvaguarda e a prevenção de ameaças à segurança pública;
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;iv)&nbsp;Qualquer adquirente(s) terceiro(s)
            relevante(s), no caso de reorganização, fusão, venda, joint venture,
            cessão, transferência ou outra disposição de toda ou qualquer parte
            dos negócios, ativos ou ações, incluindo falência ou processos
            semelhantes;
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;v)&nbsp;Funcionários e empresas do grupo,
            investidores, parceiros comerciais, Instituição Parceira, agentes
            e/ou prestadores de serviços da IMÓVEL VAZIO, no Brasil ou no
            exterior; e
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;vi)&nbsp;Quaisquer outros terceiros para
            quem a IMÓVEL VAZIO seja obrigada a compartilhar as informações
            devido a obrigações legais, contratuais e regulatórias e/ou cuja
            divulgação das informações seja necessária ou recomendável para a
            devida, efetiva e eficiente operação do website e de suas
            funcionalidades.
          </p>
          <h3 style={{ textAlign: "start" }}>
            VII. Transferência internacional de dados pessoais
          </h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;7.1. Devido à parcela internacional da natureza dos
            nossos serviços, podemos precisar transferir seus Dados Pessoais
            para terceiros, conforme observado na Seção III acima, em conexão
            com os objetivos definidos nesta Política.
            <br />
            <br />
            &nbsp;&nbsp;7.2. Por esse motivo, podemos transferir seus Dados
            pessoais para outros países que podem ter leis e requisitos de
            conformidade de proteção de dados diferentes daqueles que se aplicam
            no país em que você está localizado. Em particular, seus Dados
            Pessoais podem ser divulgados a empresas do nosso grupo empresarial
            localizadas no exterior, na medida do apropriado.
            <br />
            <br />
            &nbsp;&nbsp;7.3. Quando transferimos seus dados pessoais para outros
            países, o fazemos sempre de acordo com a Lei Geral de Proteção de
            Dados (Lei 13.709/18).
          </p>
          <h3 style={{ textAlign: "start" }}>VIII. Segurança de Dados</h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;8.1. Programamos medidas de segurança técnicas e
            organizacionais adequadas destinadas a proteger seus Dados Pessoais
            contra destruição a acidental ou ilegal perda, alteração, divulgação
            e acesso não autorizados e outras formas ilegais ou não autorizadas
            de Tratamento, de acordo com a legislação aplicável e conforme as
            boas práticas de mercado.
            <br />
            <br />
            &nbsp;&nbsp;8.2. As informações são protegidas com tecnologia SSL
            (Secure Sockets Layer) para que os dados do Usuário permaneçam em
            sigilo. Essa tecnologia também visa impedir que as informações sejam
            transmitidas ou acessadas por terceiros não autorizados.
            <br />
            <br />
            &nbsp;&nbsp;8.3. Sem prejuízo, não podemos nos responsabilizar pela
            forma como Você opta por disponibilizar seus Dados Pessoais a nós,
            ficando Você responsável por garantir que quaisquer Dados Pessoais
            que você nos envie sejam enviados com a devida segurança.
          </p>
          <h3 style={{ textAlign: "start" }}>
            IX. Qualidade dos Dados Pessoais
          </h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;9.1. Seus dados pessoais que processamos serão mantidos
            exatos e, quando necessário, atualizados. Tomamos todas as medidas
            razoáveis para garantir que:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;i.&nbsp;&nbsp;Seus dados pessoais que
            processamos são precisos e, quando necessário, mantidos atualizados;
            e
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;ii.&nbsp;&nbsp;Quaisquer dos seus Dados
            pessoais que tratemos e que sejam imprecisos (considerando os fins
            para os quais são processados) sejam apagados ou retificados tão
            logo identificada a incorreção, razão pela qual poderemos, de tempos
            em tempos, pedir que você confirme a exatidão de seus Dados
            Pessoais.
          </p>
          <h3 style={{ textAlign: "start" }}>
            X. Necessidade e Adequação (Minimização) de Dados Pessoais
          </h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;10.1. Tomamos todas as medidas razoáveis para garantir
            que seus Dados Pessoais Tratados sejam limitados aos Dados Pessoais
            necessários para atingimento dos fins mencionados nesta Política.
          </p>
          <h3 style={{ textAlign: "start" }}>XI. Retenção de Dados</h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;11.1. Manteremos cópias de seus Dados Pessoais em uma
            forma que permita a identificação apenas pelo tempo necessário em
            relação aos propósitos definidos nesta Política.
            <br />
            <br />
            &nbsp;&nbsp;11.2. Todos os dados pessoais mantidos em nossa
            plataforma para as finalidades a que se destinam, conforme aqui
            mencionadas, pelo período que a finalidade justificar a guarda e
            utilização dos dados.
            <br />
            <br />
            &nbsp;&nbsp;11.3. Deste modo, os critérios para determinar a duração
            pela qual manteremos seus dados pessoais para a finalidade geral de
            uso dos nossos serviços pelo período em que Você autorizar ou
            enquanto Você poderá nos solicitar a interrupção do tratamento e
            eliminação dos Dados Pessoais, quando essa solicitação não implicar
            em desobediência de obrigação legal imposta a Nós.
            <br />
            <br />
            &nbsp;&nbsp;11.4. Por outro lado, esclarecemos que dados poderão ser
            mantidos por prazo mínimo determinado de 6 (seis) meses, em razão de
            obrigações legais, para essa única e exclusiva finalidade, conforme
            autorizado e determinado legalmente, nos termos do Marco Civil da
            Internet.
            <br />
            <br />
            &nbsp;&nbsp;11.5. Para fins de auditoria e preservação de direitos,
            permanecer com o histórico de registro dos dados dos Usuários por
            prazo maior, nas hipóteses que a lei ou norma regulatória assim
            estabelecer ou para preservação de direitos. Os dados preservados na
            condição indicada terão seu uso limitado única e exclusivamente para
            a preservação dos direitos mencionados. Nesse sentido, temos a
            seguir uma tabela&nbsp;
            <strong>exemplificativa e não vinculativa</strong>&nbsp; de prazo
            que poderão ser considerados para manutenção de dados em razão de
            obrigação legal:
            <Table
              pagination={false}
              bordered={true}
              dataSource={legalObligation}
              columns={[
                {
                  title: "Motivação",
                  dataIndex: "motivacao",
                },
                {
                  title: "Tipo",
                  dataIndex: "tipo",
                },
                {
                  title: "Norma",
                  dataIndex: "norma",
                },
                {
                  title: "Prazo",
                  dataIndex: "prazo",
                },
              ]}
            />
            <br />
            <br />
            &nbsp;&nbsp;11.6. Caso deseje retirar qualquer autorização
            concedida, você pode fazê-lo usando as informações de contato
            disponibilizadas na Seção XV abaixo.
          </p>
          <h3 style={{ textAlign: "start" }}>XII. Cookies</h3>
          <p>
            &nbsp;&nbsp;12.1. Utilizamos cookies e tecnologias similares, como
            pixels e tags, para nos certificarmos de que os serviços prestados
            estão de acordo com o melhor padrão de desempenho esperado pelo
            Usuário.
            <br />
            <br />
            &nbsp;&nbsp;12.2. Os cookies coletados pela Imóvel Vazio abrangem
            somente estatísticas e não serão utilizados para propósitos diversos
            dos expressamente previstos nesta Política de Privacidade.
            <br />
            <br />
            &nbsp;&nbsp;12.3. Para mais informações sobre Cookies, veja
            informações a seguir:
            <br />
            <br />
            <Descriptions
              className="description-table"
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              bordered
            >
              <Descriptions.Item label="O que é um cookie?">
                Cookie é um pequeno arquivo adicionado ao seu dispositivo ou
                computador para fornecer uma experiência personalizada de acesso
                à Imóvel Vazio.
              </Descriptions.Item>
              <Descriptions.Item label="Como a Imóvel Vazio faz a coleta de cookies?">
                A Imóvel Vazio utiliza empresas especializadas em veiculação de
                propagandas, dentro e fora da Imóvel Vazio como, por exemplo, o
                Google e Facebook.
              </Descriptions.Item>
              <Descriptions.Item label="Que tipos de cookies a Imóvel Vazio utiliza?">
                A Imóvel Vazio permite a coleta de dois tipos de cookies:
                <br />
                <li> Salvo e temporário </li>
                &nbsp;&nbsp;(i) O cookie salvo serve para armazenar informações,
                como nome e senha, de maneira que o usuário não tenha que se
                conectar sempre que acessar as plataformas da Imóvel Vazio
                <br />
                &nbsp;&nbsp;(ii) Um cookie temporário é aquele que é usado para
                identificar uma visita específica ao site da Imóvel Vazio.
                <br />
                <br />
                Estes cookies são removidos do terminal de acesso (ex.:
                computador, tablet, etc.) do Usuário assim que este finaliza a
                utilização do navegador e são utilizados para armazenar
                informações temporárias.
              </Descriptions.Item>
              <Descriptions.Item label="Para que os cookies são utilizados?">
                O Imóvel Vazio utiliza cookies para vários fins, incluindo:
                <br />
                &nbsp;&nbsp;(i) ações de marketing para remarketing. Este
                recurso nos permite atingir os visitantes do Site para
                lembrá-los de efetuarem o cadastro na Imóvel Vazio ou para que
                saibam das atualizações em nosso portfólio, locais de atuação da
                Imóvel Vazio;
                <br />
                &nbsp;&nbsp;(ii) entender o comportamento de uso do Site e do
                Blog para melhor desenvolvimento do produto.
              </Descriptions.Item>
              <Descriptions.Item label="É possível limitar a coleta de cookies?">
                Os navegadores em geral permitem que seja desabilitada a coleta
                de cookies. Dessa forma, caso você não altere os padrões de
                coleta de cookie do seu navegador, nós iremos considerar que
                você concorda com a coleta de cookies. Porém, as plataformas da
                Imóvel Vazio poderão não funcionar da forma desejada caso seja
                desabilitada a coleta de cookies.
              </Descriptions.Item>
            </Descriptions>
          </p>
          <h3 style={{ textAlign: "start" }}>XIII. Seus direitos legais</h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;13.1. Além dos demais direitos garantidos nesta
            Política, e sujeito à legislação aplicável, nós garantimos a você:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;i)&nbsp;O direito de solicitar acesso ou
            cópias de seus dados pessoais que processamos ou controlamos;
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;ii)&nbsp;O direito de solicitar a
            retificação de quaisquer imprecisões em seus Dados Pessoais;
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;iii)&nbsp;O direito de solicitar, conforme o
            caso, a eliminação dos Dados Pessoais que tratamos ou a restrição de
            Tratamento de seus dados pessoais que processamos ou controlamos;
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;iv)&nbsp;O direito de se opor ao Tratamento
            de seus dados pessoais;
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;v)&nbsp;O direito de ter seus Dados Pessoais
            transferidos para outro Controlador, na medida do aplicável
            (portabilidade);
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;v)&nbsp;Onde processamos seus dados pessoais
            com base em seu consentimento, o direito de retirar esse
            consentimento; e
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;v)&nbsp;O direito de apresentar reclamações
            sobre o Tratamento de seus Dados Pessoais a Autoridade Nacional de
            Proteção de Dados.
            <br />
            <br />
            &nbsp;&nbsp;13.2. Para exercer um ou mais desses direitos, para
            fazer qualquer pergunta sobre esses direitos ou qualquer outra
            disposição desta Política ou sobre o Tratamento de seus Dados
            Pessoais realizado por Nós, entre em contato conosco por meio das
            informações disponibilizadas na Seção XV abaixo.
          </p>
          <h3 style={{ textAlign: "start" }}>XIV. Suas obrigações</h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;14.1. Contamos com Você para nos fornecer Dados Pessoais
            completos e precisos sobre você, para que possamos fornecer os
            serviços adequados a você e aos nossos Clientes.
            <br />
            <br />
            &nbsp;&nbsp;14.2. Contamos com você também para garantir que seja
            legalmente capaz de divulgar Dados Pessoais para nós, conforme
            estabelecido nesta Política.
          </p>
          <h3 style={{ textAlign: "start" }}>XV. Detalhes de contato</h3>
          <p style={{ textAlign: "start" }}>
            &nbsp;&nbsp;15.1. Se você tiver quaisquer comentários, perguntas ou
            preocupações sobre qualquer uma das informações nesta Política, ou
            quaisquer outras questões relacionadas ao Tratamento de Dados
            Pessoais pela IMÓVEL VAZIO, entre em contato com o Departamento
            Responsável através de:
            <br />
            <br />
            <li>
              <a href="mailto=contato@imovelvazio.com.br">
                contato@imovelvazio.com.br
              </a>
            </li>
            <br />
            <br />
            <li>
              <strong>+55 21 99364-3054 (Whatsapp)</strong>
            </li>
          </p>
          <p style={{ textAlign: "end" }}>
            <strong>Última atualização: 21 de julho de 2022</strong>
          </p>
        </div>
      </Body>
      <Footer />
    </div>
  );
}
