import styled from 'styled-components'
import BgImage from './register-background.png'

export const BgImg = styled.div`
    height:1000px;
    width:100vw;
    background : url(${BgImage});
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;

    @media only screen and (max-width: 600px) {
        height: 1700px;
    }
`

export const FilterContainer = styled.div`
    width:100vw;
    // height:93%;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:center;
    padding: 50px 0 150px;

    .login-wrapper{
        border-radius: 8px;
        width: 75%;
        max-width:1250px;
        display:flex;
        flex-wrap:wrap;
        padding:20px;
        padding-right:none;
        justify-content: space-evenly;
        align-items: center;
        height: 80%;

        @media only screen and (max-width: 600px) {
            height: 100%;
        }

        .info-box {
            height: 100%;
            width: 50%;
            padding-left: 35px;
            padding-right: 45px;
            padding-top: 27px;
            display: flex;
            flex-direction: column;
            padding-bottom: 15px;
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(30px);
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;

            @media only screen and (max-width: 600px) {
                padding-left: 25px;
                padding-right: 25px;
            }

            h1 {
                font-size: 22px;
                font-weight: 1000;
            }

            .card-retangle{
                height: 50px;
                width: 100%;
                margin-top: 25px;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 18px;
                font-weight: 500;

                @media only screen and (max-width: 600px) {
                    font-size: 16px;
                }

                img{
                    height: 80%;
                    width: 10%;
                    margin-right: 20px;
                }
            }

        }

        .register-box{
            // height: 110%;
            width: 50%;
            padding-left: 30px;
            border-radius: 10px;
            padding-right: 45px;
            position: relative;
            display: flex;
            flex-direction: column;
            padding-bottom: 15px;
            background: white;
            padding-top: 20px;
            align-items: center;
            
            h4{
                font-weight: 400;
                margin-top: 8px;
                color: #444;
                font-size: 16px;
            }

            .body-box{
                margin-top: 25px;
                margin-bottom: 10px;
                height: 100%;
                width: 90%;

                .steps-box{
                    height: 50px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;

                    .step-horizontal{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-weight: 400;
                        margin-left: 10px;
                        font-size: 14px;

                        .step-circle{
                            border: 3px solid #EA5C1E;
                            height: 35px;
                            width: 35px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: black;
                            font-weight: 800;
                            border-radius: 50%;
                            font-size: 20px;
                            margin-right: 8px;
                            
                        }

                        .step-disable{
                            border: 3px solid #ddd;
                            height: 35px;
                            width: 35px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #aaa;
                            font-weight: 800;
                            border-radius: 50%;
                            font-size: 20px;
                            margin-right: 8px;
                        }

                        .step-complete{
                            border: 3px solid #05677C;
                            height: 35px;
                            width: 35px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: black;
                            font-weight: 800;
                            border-radius: 50%;
                            font-size: 20px;
                            margin-right: 8px;
                        }
                    }

                }

                .input-box{
                    background-color: #eee;
                    border-radius: 5px;
                    height: 60px;
                    width: 100%;
                    margin-top: 20px;
                    padding-left: 10px;
                    padding-right: 15px;
                    padding-top: 3px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-evenly;

                    small{
                        font-weight: 500;
                                                
                    }

                    input{
                        background-color: transparent;
                        border: transparent;
                        border-bottom: 2px solid #ccc;
                        width: 100%;
                    }
                }

                .container-input-box{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    .input-box{
                        width: 48%;
                    }
                }

                .container-next{
                    height: 50px;
                    width: 100%;
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;
                    align-items: space-evenly;
                    justify-content: flex-end;

                    
                    button{
                        border: transparent;
                        background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
                        width: 48%;
                        color: white;
                        height: 100%;
                        border-radius: 4px;
                    }

                    button:hover{
                        color: #EA5C1E;
                        background: white;
                        text-decoration: none;
                        border: 2px solid #EA5C1E;
                        filter: none;
                        background-color: white;
                        background-image: none;
                    }

                    .btBack{
                        margin-right: 4%;
                        border: 2px solid #EA5C1E;
                        background-color: white;
                        background: white;
                        color: #EA5C1E;
                    }

                    .btBack:hover{
                        background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
                        color: white;
                        border: none;
                    }
                }


                .body-terms{
                    background-color: #eee;
                    margin-top: 20px;
                    padding-top: 20px;
                    padding-left: 20px;
                    overflow-y: scroll;
                    height: 160px;
                    border-radius: 8px;
                    font-size: 13px;

                    h2{
                        margin-bottom: 8px;
                    }

                    p{
                        color: #888
                    }
                }

                .stepi{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;    
                    font-size: 17px;
                    font-weight: 300;
                    padding-top: 16px;

                    p{
                        margin-bottom: 10px;
                    }

                    .edit-number{
                        font-size: 15px;
                        color: #00AEC8;
                    }

                    .input-center{
                        width: 60%;
                    }

                    .container-center{
                        width: 60%;
                        margin-top: 45px;
                        a{width: 100%;}
                        button{
                            width: 100%;
                        }
                    }

                    .reload{
                        font-size: 14px;
                        margin-bottom: 0px;
                    }

                    a{
                        color: black;
                    }
                }

            }
        }

    }
    
@media only screen and (max-width: 1400px) {
    .login-wrapper{
        width: 90%;

        .register-box{
            h1{
                font-size: 26px;
            }
            h4{
                font-size: 13px;
            }
        }
    }
  }
  @media only screen and (max-width: 1100px) {
      .login-wrapper{
          width: 100%;

          .register-box{
              h1{
                  font-size: 21px;
              }
              h4{
                  font-size: 12px;
              }

              .body-box{
                  .step{
                      .MuiFormControlLabel-root{
                          .MuiTypography-root{
                              font-size: 13px
                          }
                      }
                  }

                  .stepi{
                        .reload{
                            font-size: 10px;
                        }

                      .p{
                          font-size: 13px;
                      }
                  }
              }
          }
      }
    }

    @media only screen and (max-width: 1000px) {
        .login-wrapper{
  
            .register-box{
  
                .body-box{
                    .steps-box{
                        .step-horizontal{
                            font-size: 11px;
                            font-weight: 900;

                            .step-complete{
                                height: 30px;
                                width: 30px;
                                font-size: 17px;
                            }

                            .step-circle{
                                height: 30px;
                                width: 30px;
                                font-size: 17px;
                            }

                            .step-disable{
                                height: 30px;
                                width: 30px;
                                font-size: 17px;
                            }
                        }
                    }
                }
            }
        }
      }

    @media only screen and (max-width: 600px) {

        .login-wrapper{
            flex-flow: wrap-reverse;
            flex-direction: column-reverse;
            height: 95%;
            
            .register-box{
                width: 100%;
                // height: 45%;

                h1{
                    font-size: 19px;
                }

                .body-box{
                    
                    .step{
                        .container-input-box{
                            display: block;

                            .input-box{
                                width: 100%;
                            }
                        }
                        .container-next{
                            button{
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .info-box {
                width: 100%;
                height: 45%;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
`

export const ButtonAccount = styled.div`
    // position: absolute;
    bottom: 1%;
    border: transparent;
    background-color: transparent;
    width: 100%;
    margin-left: 3.5%;
    padding: 12px;
    border-radius: 4px;

    button{
        width: 100%;
        color: #EA5C1E;
        background-color: transparent;
        border: none;
        padding: 12px;
        border-radius: 4px;

         span {
            font-size: 16px;
         }
     }                      
`

export const Announcement = styled.div`
width: 100vw;
height: 69px;
background: #ECECEC;
text-align:center;
display:flex;
justify-content:center;
align-items:center;

    h2{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: right;
        letter-spacing: 0.05em;
        text-decoration-line: underline;
        color: #0EA5BB;
    }

    h2:first-child{
        text-decoration-line: none;
        margin-right:20px;
        color: #676666;
    }

`

export const SellingProperties = styled.section`
    width:100vw;
    display:flex;
    flex-direction:column;

    .title{
        margin-top:90px;
        font-size: 36px;
        line-height: 41px;
        text-align: center;
        color: #1E1D1D;
    }

    p{
        text-align: center;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.05em;

        color: #1E1D1D;
    }

    .carousel{
        margin-top:50px;
        padding-left:100px;
        padding-right:100px;
    }

`

export const BtnContainer = styled.div`
    width:100vw;
    height:10vh;
    display:flex;
    justify-content:center;
    align-items:center;

    a{
        text-decoration:none;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #EA5C1E;
        border: 1px solid #EA5C1E;
        box-sizing: border-box;
        border-radius: 4px;
        padding:14px;
        padding-left:40px;
        padding-right:40px;
    }
`

export const MarketingContainer = styled.section`
    margin-top:10vh;
    width:100vw;
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: #1E1D1D;

    p{
        margin-top:10px;
        font-weight: 300;
        font-size: 20px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.05em;

        color: #1E1D1D;
    }

    .images{
        display:flex;
        width:100%;
        justify-content:space-around;
        align-items:center;
        flex-wrap:wrap;
    }
`
export const PropertyOffer = styled.div`
    margin-top:10vh;
    width:100vw;
    height: 272px;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    background:url(${require('../../assets/home/offerbg.png')});
    background-size:cover;

    h1{
        font-weight: bold;
        font-size: 36px;
        line-height: 41px;
        text-align: center;
        margin-bottom:20px;
        color: #FFFFFF;
    }

    p{
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.05em;
        margin-bottom:20px;
        color: #FFFFFF;
    }

    a{
        text-decoration:none;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;
        padding:12px;
        color: #FFFFFF;
        background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
        border-radius: 4px;
    }
`

export const Bank = styled.div`
    width:100vw;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    margin-top:10vh;
    margin-bottom:10vh;

    h1{
        font-size: 36px;
        line-height: 41px;
        text-align: center;
        margin-bottom:20px;
        /* #1E1D1D */

        color: #1E1D1D;
    }

    p{
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
        /* or 139% */

        text-align: center;
        letter-spacing: 0.05em;
        margin-bottom:20px;
        color: #1E1D1D;
    }

    a{
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;

        color: #EA5C1E;
        padding:10px;
        padding-left:30px;
        padding-right:30px;
        text-decoration:none;
        border: 1px solid #EA5C1E;
        box-sizing: border-box;
        border-radius: 4px;
    }
`

