import React from "react";
import "../../global.css";
import * as S from "./styles";
import CloseCircleBlue from "../../assets/close-circle-blue.svg";

export default function BadgeFilter({ children, handleCleanFilterBadge }) {
  return (
    <>
      <S.ContainerBadge>
        {children}{" "}
        <S.ContentCloseBadge>
          {" "}
          <img
            src={CloseCircleBlue}
            className="icon-close"
            onClick={handleCleanFilterBadge}
          />
        </S.ContentCloseBadge>
      </S.ContainerBadge>
    </>
  );
}
