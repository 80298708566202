import React from "react";
import * as S from "./styles";
import "../../../global.css";
import ImageLoginZap from '../../../assets/ImageLoginZap.svg'
import ArrowLeft from '../../../assets/ArrowLeft.svg';
import { InfoOutlined } from '@ant-design/icons';

export default function LoginZap({ setNextViewModalBinding }) {
  
  return (
    <>
      <S.Container>
        <S.Content>
            <S.ContentButtonBack>
                <div className="content-back" onClick={() => setNextViewModalBinding('alertZap')}>
                    <img src={ArrowLeft} alt="Arrow Left" />
                    <span className="title-back">Voltar</span>
                </div>
            </S.ContentButtonBack>
          <S.ContentTitle>
           <span className="title">Login</span>
           <span className="subTitle">
               <span className="link" onClick={() => window.open('https://canalpro.grupozap.com/login')}>canalpro.grupozap.com/login</span>
               e entre em sua conta.
           </span>
          </S.ContentTitle>
          <S.ContentInfo>
            <div className="info-circle">
                <InfoOutlined className="info" />
            </div>
            <span className="title-info">Certifique-se de possuir uma assinatura e uma conta ativa.</span>
          </S.ContentInfo>
        </S.Content>
        <img src={ImageLoginZap} alt="Image Create Account Zap" />
      </S.Container>
    </>
  );
}
