import React, { useState, useEffect, useMemo } from 'react';
import { Link, } from "react-router-dom";
import { Container, SaveContainer } from './styles';
import Header from '../../components/adminHeader';
import Aside from '../../components/adminSideBar';

import { Badge, Tabs } from "antd";
import UploadGallery from '../../components/UploadGallery';
import Documentation from './documentation';
import SaveButton from '../../components/Atoms/SaveButton';
import { useForm, FormProvider } from "react-hook-form";
import Sections from './Sections';
import { NOTIFICATION_TYPE, openNotification } from '../../utils/notification';
import { getRealEstateDevelopmentAsync, saveRealEstatedDevelopmentAsync, updateRealEstatedDevelopmentAsync } from '../../services/realEstateDevelopment.service';

const downloadUrl = process.env.REACT_APP_FILES_URL;

// const requestLockControl = {
//     dataLocked: false,
// }
export default function () {

    const [activeTab, setActiveTab] = useState('gallery');

    const form = useForm({
        defaultValues: {
            name: 'Residencial Vilares'
        },
    });

    const getAsync = (state) => {
        // if (requestLockControl.dataLocked) return;
        // requestLockControl.dataLocked = true;
        return getRealEstateDevelopmentAsync(state)
            .then(({ data }) => {
                form.reset(data);
            })
            .catch(({ response: error }) => {
                const errorMessage = error.data?.userMessage || 'Erro ao tentar salvar';
                console.error(errorMessage, error);
                if (error.status !== 404)
                    openNotification(errorMessage, NOTIFICATION_TYPE.error);
            })
        // .finally(() => requestLockControl.dataLocked = false);
    }

    useMemo(() => getAsync(), []);

    const [tabFields, setTabFields] = useState({ docs: [], gallery: [], sections: [] });

    const onSubmit = (formData, e) => {

        if (formData._id) {
            updateRealEstatedDevelopmentAsync(formData)
                .then(() => {
                    openNotification('Empreendimento atualizado com sucesso', NOTIFICATION_TYPE.success);
                    form.reset(formData);
                })
                .catch(({ response: error }) => {
                    const errorMessage = error.data?.userMessage || 'Erro ao tentar salvar';
                    console.error(errorMessage, error);
                    openNotification(errorMessage, NOTIFICATION_TYPE.error);
                });
        } else {
            saveRealEstatedDevelopmentAsync(formData)
                .then((res) => {
                    const updatedData = { ...formData, _id: res.data._id };
                    openNotification('Empreendimento salvo com sucesso', NOTIFICATION_TYPE.success);
                    form.reset(updatedData);
                })
                .catch(({ response: error }) => {
                    const errorMessage = error.data?.userMessage || 'Erro ao tentar salvar';
                    console.error(errorMessage, error);
                    openNotification(errorMessage, NOTIFICATION_TYPE.error);
                });
        }
    };
    const onError = (errors, e) => {

        console.error(errors, e);

        const getFlatMap = (values) => Object.values(values);

        if (errors && form.formState.submitCount > 1) {
            getFlatMap(errors)
                .filter(({ message, value, }) => (message || value))
                .map(({ message, value, }) =>
                    openNotification(message || value, NOTIFICATION_TYPE.warning));
            Object.entries(errors)
                .filter(([_, val]) => (!val.message && !val.value))
                .map(([parentName, val]) => {
                    const label = menuTabs.find(x => x.key === parentName)?.label;
                    if (label) {
                        const errorsLength = getFlatMap(val).length;
                        const oneOrMany = errorsLength > 1 ? 'erros' : 'erro';
                        const message = `${errorsLength} ${oneOrMany} em ${label}`;
                        openNotification(message, NOTIFICATION_TYPE.warning);
                    }
                });

        }
    };

    const sectionsParentName = 'sections';
    useEffect(() => {
        if (!form.getValues(sectionsParentName)) {
            form.setError(sectionsParentName, {
                type: 'required',
                value: 'Preencha as seções'
            });
        } else {
            form.clearErrors(sectionsParentName);
        }
    }, [form.getValues(sectionsParentName)]);

    const addField = (tabName, values) => {
        if (tabFields[tabName])
            setTabFields({ ...tabFields, [tabName]: [...tabFields[tabName], ...values] });
        else
            setTabFields({ ...tabFields, [tabName]: values });
    }

    const countErrors = (filter, parentName) => {
        if (parentName && !form.getValues(parentName) && form.formState.isSubmitted) {
            return form.formState.errors[parentName] ? 1 : 0;
        }
        const keys = (parentName && form.formState.errors[parentName])
            ? Object
                .keys(form.formState.errors[parentName])
            : Object
                .keys(form.formState.errors);
        return keys
            .filter(filter).length;
    }

    const menuTabs = [
        {
            icon: <Badge count={countErrors(x => tabFields.docs?.includes(x))} />,
            key: 'docs',
            label: 'Documentação',
            children: <>
                <Documentation {...{ addField: (val) => addField('docs', val) }} />

            </>,
        },
        {
            icon: <Badge count={countErrors(x => tabFields.sections?.includes(x), 'sections')} />,
            key: 'sections',
            label: 'Seções',
            children: (<Sections {...{
                addField: (val) => addField('sections', val)
            }} />),
        },
        {
            key: 'gallery',
            label: 'Galeria',
            children: <UploadGallery
                method='patch'
                downloadUrl={downloadUrl}
                uploadUrl={`real-estate-development/${form.getValues('_id')}/gallery/property`}
                files={form.getValues('gallery')}
                handler={(val = []) => form.setValue('gallery', val)}
                multiple={true} />,
            disabled: !form.getValues('_id'),
        },
    ];

    return (
        <>
            <Header />
            <Aside />
            <Container>
                <div className="bread-crumb">
                    <h1 className="category">Empreendimentos&nbsp;{">"}&nbsp;</h1>
                    <Link className="where" to='#'>
                        Residencial Vilares
                    </Link>

                </div>
                <FormProvider {...form}>
                    <Tabs animated defaultActiveKey={menuTabs[0].key} onChange={(val) => setActiveTab(val)} items={menuTabs} style={{ paddingRight: '2rem' }} />
                    {activeTab !== 'gallery' && <SaveContainer>
                        <SaveButton onClick={form.handleSubmit(onSubmit, onError)}>Salvar</SaveButton>
                    </SaveContainer>}
                </FormProvider>

            </Container>

        </>
    );

}
