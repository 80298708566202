import React from "react";
import * as S from "./styles";
import "../../../global.css";
import ArrowLeft from '../../../assets/ArrowLeft.svg';
import ImageIntegratorName from '../../../assets/ImageIntegratorName.svg';
import { InfoOutlined } from '@ant-design/icons';

export default function IntegratorNameZap({ setNextViewModalBinding }) {
  
  return (
    <>
      <S.Container>
        <S.Content>
            <S.ContentButtonBack>
                <div className="content-back" onClick={() => setNextViewModalBinding('configZapIntegrate')}>
                    <img src={ArrowLeft} alt="Arrow Left" />
                    <span className="title-back">Voltar</span>
                </div>
            </S.ContentButtonBack>
          <S.ContentTitle>
           <span className="title">Nome do Integrador</span>
           <span className="subTitle">
               No campo <strong>Selecione o Software</strong>, preencha "Desenvolvedor Próprio".
           </span>
          </S.ContentTitle>
          <S.ContentInfo>
            <div className="info-circle">
                <InfoOutlined className="info" />
            </div>
            <span className="title-info">Certifique-se de possuir uma assinatura e uma conta ativa.</span>
          </S.ContentInfo>
        </S.Content>
        <S.ContentImgIllustrated>
         <img src={ImageIntegratorName} alt="Image Config Zap" />
        </S.ContentImgIllustrated>
      </S.Container>
    </>
  );
}
