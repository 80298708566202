/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback, useContext } from "react";

import { useHistory } from 'react-router-dom';
import Header from "../../components/header";
import { notification, Tabs, Badge, Tooltip } from "antd";
import { TagPartnerProperties } from "../PropertiesList/styles";
import {
  ImagesContainer,
  ImageFull,
  Image1,
  Image2,
  Image3,
  Image4,
  Container,
  Aside,
  Body,
  Tag,
  ImagesContainerMobile,
  ImageFullMobile,
  ArrowSlide,
  SpanArrow,
  DownloadImage,
  CountImage,
  DownloadIV,
  ContainerVerifyPrepareAds,
  ContentImgVerifyPrepareAds,
  ContentInformationVerifyPrepareAds,
  ContainerTabsProperty,
  TitleVerifyPrepareAds,
  SubTitleVerifyPrepareAds
} from "./style";



import Loading from "../../components/Loading";

import Carousel from "../../components/SimilarImovelCarousel";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import moment from "moment";
import PhotosModal from "../../components/PhotosModal";
import Banner from "./modal";
import ModalZap from "../../components/ModalZap";

import ArrowLeft from './icons/ArrowLeft.svg'

import PrepareAds from "../../components/ViewsModal/AddAdsList/PrepareAd";
import SuccessPropertyPrepared from '../../components/ViewsModal/AddAdsList/SuccessPropertyPrepared'

import api from "../../api";
import { refreshTokenCallback } from "../../api";

// Icons
import { ShareFill, Facebook, Telegram, Whatsapp, House, CloudDownloadFill } from "@styled-icons/bootstrap";
import { CardChecklist } from "@styled-icons/bootstrap/CardChecklist";
import { Dropdown, Collapse, Modal } from "antd";
import VolumeLowIcon from '../../assets/icons/volumeLow.svg'
import ImageButton from "./components/ImageButton";
import InstallApp from "./components/InstallApp";
import DigitalLockLinkButton from "./components/DigitalLockLinkButton";
import { getUserId } from "../../utils/sessionHelper";
import ModalApp from "../../components/ModalApp";

import { getUserSession, getRefreshToken } from "../../utils/sessionHelper";

import ImageVerifyPrepared from '../../assets/ImgVerifyPrepared.svg'

import InformationView from "../../components/ViewsModal/TabsViewsVerify/InformationView";
import AdAboutTabView from "../../components/ViewsModal/TabsViewsVerify/AdAboutView";
import AdValuesView from "../../components/ViewsModal/TabsViewsVerify/AdValuesView";
import AdDescriptionFull from "../../components/ViewsModal/TabsViewsVerify/AdDescriptionFull";

import { validateContent } from "../../utils/validateNoContent";
import FormContext from '../../components/ModalZap/_context';
import InstructionsPadlock from "./components/InstructionsPadlock";
import InstructionDetails from "./components/InstructionDetails";

function PropertyDetail() {
  const [data, setData] = useState();
  const [modalCaract, setCaract] = useState(false);
  const [autoTag, setTag] = useState("");
  const [modal, setModal] = useState(false);
  const [showModalFeedback, setShowModalFeedback] = useState(false)
  const [photoModal, setPhotoModal] = useState(false);
  const [modalZap, setModalZap] = useState(false);
  const [nextViewModalPrepare, setNextViewModalPrepare] = useState("initial");
  const [titleButtonModal, setTitleButtonModal] = useState('Próximo');

  /* Modal Propostas */
  const [modalProposta, setModalProposta] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [auxIndex, setAuxIndex] = useState(0);
  const [slider, setSlider] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [useApp, setUseApp] = useState();
  const [lockId, setLockId] = useState(null);

  const [isPartnerProperty, setIsPartnerProperty] = useState(false)

  const [features, setFeatures] = useState([])

  const [commercialDistrict, setCommercialDistrict] = useState('');
  const [propertyFeatures, setPropertyFeatures] = useState([]);
  const [propertyDescription, setPropertyDescription] = useState('');
  const [valueTitleAds, setValueTitleAds] = useState('');
  const [checkboxMarkedModal, setCheckboxMarkedModal] = useState(false);

  const idFurnished = 8147151116452981;
  const idUnderConstruction = 1930369337545538;
  const idRenovated = 7102595495777344;

  const history = useHistory();

  const { form: { formState: { errors, }, } } = useContext(FormContext);
  const [countZapAdsErrors, setCountZapAdsErrors] = useState(Object.entries(errors)?.length);

  useEffect(() => setCountZapAdsErrors(Object.entries(errors)?.length), [errors]);


  const zapAdsTabs = [
    {
      key: 'InformationView',
      label: 'Informações',
      children: <InformationView data={data} />,
    },
    {
      key: 'AdAboutTabView',
      label: 'Sobre',
      children: <AdAboutTabView data={data} />,
    },
    {
      key: 'AdValuesTab',
      label: 'Valores',
      children: <AdValuesView data={data} />,
    },
    {
      key: 'AdDescriptionFull',
      label: 'Descrição do anúncio',
      icon: <Badge count={countZapAdsErrors} />,
      children: <AdDescriptionFull
        data={data}
        isAdEdit={false}
        features={features}
      />,
    },
  ];

  const userId = getUserId();
  let userSession = getUserSession();

  const screen = {
    pageStartX: 0,
  };

  const getMobileOperatingSysyem = useCallback(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Window Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }, []);

  function closeModalZap() {
    setModalZap(false);
  }

  const handleClick = useCallback(() => {
    const OShandler = getMobileOperatingSysyem();
    setTimeout(function () {
      if (OShandler === "Android") {
        window.location =
          "https://play.google.com/store/apps/details?id=br.com.iv.imovelvazio&hl=pt_BR&gl=US";
      } else if (OShandler === "iOS") {
        window.location = "https://apps.apple.com/br/app/housi/id1573642473";
      } else {
        window.location = "https://imovelvazio.com.br/";
      }
    }, 50);

    window.location = `fechaduradigitalapp://lock/${lockId}/user/${userId}`;
  }, [lockId]);

  useEffect(() => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      setIsMobile(true);
    }

    if (data && data.images) {
      let ImagesTrue = [];
      for (let i = 0; i < data.images.length; i++) {
        if (data.images[i].show != "false") {
          ImagesTrue.push(data.images[i]);
        }
      }

      setImages(ImagesTrue);
    }
  }, [data]);

  useEffect(() => {
    if (images.length > 0) {
      const sliderElement = document.querySelector(".slider");

      if (sliderElement != slider) {
        setSlider(sliderElement);
      }

      if (slider) {
        const touchstart = (e) => {
          return (screen.pageStartX = e.changedTouches[0].pageX);
        };

        const touchend = (e) => {
          if (e.changedTouches[0].pageX < screen.pageStartX) {
            if (index != images.length - 1) setAuxIndex(auxIndex + 1);

            return;
          }

          if (e.changedTouches[0].pageX > screen.pageStartX) {
            if (index != 0) setAuxIndex(auxIndex - 1);

            return;
          }
        };

        slider.removeEventListener("touchstart", touchstart);
        slider.removeEventListener("touchend", touchend);

        slider.addEventListener("touchstart", touchstart, { once: true });

        slider.addEventListener("touchend", touchend, { once: true });
      }
    }
  }, [images, slider, auxIndex, refresh]);

  useEffect(() => {
    if (auxIndex != index) {
      if (auxIndex > images.length - 1) {
        setIndex(images.length - 1);
        setAuxIndex(images.length - 1);
      } else {
        if (auxIndex < 0) {
          setAuxIndex(0);
        } else {
          setIndex(auxIndex);
        }
      }
    }
  }, [auxIndex]);

  const tag = useRef();
  const sellingNotes = useRef();

  const params = useParams();

  async function fetchData() {
    const response = await api
      .get(`/property/${params.id}`)
      .catch((err) => console.error(err));

    setUseApp(response.data.useApp);
    setLockId(response.data.lockId);

    handleCompact(params.id);
    handleVisualization(params.id);
    const obj = response.data;

    obj.images.sort((a, b) => {
      if (a["order"] > b["order"]) {
        return 1;
      } else {
        return -1;
      }
    });
    if (obj.videoLink) {
      obj.isVideoLink = obj.videoLink?.toString()?.startsWith('http');
    }

    setData(obj);


    // setCommercialDistrict(obj.neighborhoodName);
    // setPropertyDescription(obj.description);
    // setIsPartnerProperty(obj.isPartnerProperty)

    // let validateIsConstructionInProgress = obj.tag ? obj.tag?._id === idUnderConstruction : false;
    // let validateIsPropertyFurnished = obj.tag ? obj.tag?._id === idFurnished : false;
    // let validateIsPropertyRenovated = obj.tag ? obj.tag?._id === idRenovated : false;


    // setConstructionInProgress(validateIsConstructionInProgress);
    // setPropertyFurnished(validateIsPropertyFurnished);
    // setPropertyRenovated(validateIsPropertyRenovated);
  }

  async function getFeatures() {
    const response = await api
      .get(`/feature`)
      .catch((err) => console.error(err));

    setFeatures(response?.data);
  }

  async function advertisementTutorial() {
    await api
      .patch(`/user/advertisement-tutorial`, { checkboxMarkedModal })
      .catch((err) => console.error(err));

    await refreshTokenCallback(true);
    const dados = getUserSession();
  }

  useEffect(() => {
    fetchData();
    getFeatures();

  }, []);

  useEffect(() => {
    filterTag();
  }, [data]);

  function copyTo() {
    let text = `http://visualizarimovel.com.br/${data._id}`;
    var textArea = document.createElement("textarea");

    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = "2em";
    textArea.style.height = "2em";
    textArea.style.padding = 0;
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";
    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      window.open(text);
    } catch (err) {
      window.prompt(
        "Copie para área de transferência: Ctrl+C e tecle Enter",
        text
      );
    }

    document.body.removeChild(textArea);
  }

  function filterTag() {
    if (data != undefined) {
      if (Date.parse(data.createdAt)) {
        const createdAt = moment(new Date(data.createdAt)).diff(
          moment(Date.now())
        );

        const age = moment.duration(createdAt);

        let datase =
          data.createdAt.split(" ")[0].split("/")[2] +
          "-" +
          data.createdAt.split(" ")[0].split("/")[1] +
          "-" +
          data.createdAt.split(" ")[0].split("/")[0];

        var dataBanco = new Date(datase);

        if (isNaN(dataBanco.getTime())) {
          dataBanco = new Date("2020", "01", "01");
        }
        var date2 = new Date();

        var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (data.sold) {
          tag.current.style.backgroundColor = "#1E1D1D";
          return setTag("VENDIDO");
        }

        if (data.showTag && data.tag) {
          tag.current.style.backgroundColor = "#00AEC8";
          return setTag(data.tag.name);
        }

        if (diffDays <= 2) {
          tag.current.style.backgroundColor = "#1C9C40";
          return setTag("NOVIDADE");
        }

        if (
          data.descountPrice &&
          data.descountPrice != data.sellingPrice &&
          data.descountPrice < data.sellingPrice
        ) {
          tag.current.style.backgroundColor = "#EA1E1E";
          return setTag(
            "DESCONTO DE " +
            Math.abs(
              Math.floor((data.descountPrice / data.sellingPrice) * 100 - 100)
            ).toString() +
            "%"
          );
        }

        if (data.tag) {
          tag.current.style.backgroundColor = "#00AEC8";
          return setTag(data.tag.name);
        } else {
          tag.current.style.opacity = "0";
        }
      } else {
        const createdAt = moment(data.createdAt, "DD/MM/YYYY HH:mm").diff(
          moment(Date.now())
        );

        const age = moment.duration(createdAt);

        let datase =
          data.createdAt.split(" ")[0].split("/")[2] +
          "-" +
          data.createdAt.split(" ")[0].split("/")[1] +
          "-" +
          data.createdAt.split(" ")[0].split("/")[0];

        var dataBanco = new Date(datase);

        if (isNaN(dataBanco.getTime())) {
          dataBanco = new Date("2020", "01", "01");
        }
        var date2 = new Date();

        var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (data.sold) {
          tag.current.style.backgroundColor = "#1E1D1D";
          return setTag("VENDIDO");
        }

        if (data.showTag && data.tag) {
          tag.current.style.backgroundColor = "#00AEC8";
          return setTag(data.tag.name);
        }

        if (diffDays <= 2) {
          tag.current.style.backgroundColor = "#1C9C40";
          return setTag("NOVIDADE");
        }

        if (
          data.descountPrice &&
          data.descountPrice != data.sellingPrice &&
          data.descountPrice < data.sellingPrice
        ) {
          tag.current.style.backgroundColor = "#EA1E1E";
          return setTag(
            "DESCONTO DE " +
            Math.abs(
              Math.floor((data.descountPrice / data.sellingPrice) * 100 - 100)
            ).toString() +
            "%"
          );
        }
        if (data.tag) {
          tag.current.style.backgroundColor = "#00AEC8";
          return setTag(data.tag.name);
        } else {
          tag.current.style.opacity = "0";
        }
      }
    }
  }

  async function handleDownload() {
    try {
      window.open(
        `${window.location.origin}/d?url=${process.env.REACT_APP_API_URL}/download/${data._id}/${images[index].image}&image_name=${images[index].image}`,
        "_blank"
      );
    } catch (e) {
      notification.error({
        message: 'Erro ao tentar baixar a imagem',
      });
    }
  }

  function money(value) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  }

  async function handleCompact(id) {
    let response = await api.post("/download", { id: id });
  }
  async function handleVisualization(id) {
    let response = await api.put(`/visualization/${id}`);
  }

  function resultFieldText(arrayFieldError) {
    let resultError = '';

    if (arrayFieldError.length === 2) {
      resultError += `${arrayFieldError[0]} e ${arrayFieldError[1]}`
    }

    if (arrayFieldError.length === 1) {
      resultError += arrayFieldError[0];
    }

    if (arrayFieldError.length > 2) {
      resultError += `${arrayFieldError[0]}, ${arrayFieldError[1]} e ${arrayFieldError[2]}`
    }

    return resultError;
  }

  function validateModalPrepareAds() {
    let arrayFieldError = [];

    if (valueTitleAds === '' || valueTitleAds.length <= 10 || valueTitleAds.length >= 100) {
      arrayFieldError.push('titulo do anúncio');
    }

    if (propertyFeatures.length === 0) {
      arrayFieldError.push('característica do imóvel');
    }

    if (validateContent(propertyDescription ? propertyDescription : '')) {
      arrayFieldError.push('descrição');
    }

    if (arrayFieldError.length !== 0) {
      const messageError = `Erro: Os seguintes campos são obrigatórios - ${resultFieldText(arrayFieldError)}. Por favor, forneça as informações necessárias.`

      notification.error({ message: messageError });
    } else {
      addPropertyToZap();
    }

  }

  async function addPropertyToZap(val) {

    const requestData = {
      idProperty: +data._id,
      ...val
    }

    setShowLoading(true);

    try {
      await api.post('/property-zap-advertisement', requestData);

      advertisementTutorial();
      setShowLoading(false);
      setNextViewModalPrepare('success');
      setTitleButtonModal('Ir Para o Gerenciamento');
      changeViewModalPrepare()
    } catch (e) {
      setShowLoading(false);
      openNotificationWithIcon();
    }
  }

  const openNotificationWithIcon = () => {
    notification.error({ message: `Erro: Imóvel já foi inserido a Zap Móveis. Por favor, insira outro imóvel!` });
  };

  function closeModalProposal() {
    setShowModalFeedback(false);
    window.location.href = `/imoveis/${data._id}`
  }

  useEffect(() => {
    setTitleButtonModal('Próximo')
    setNextViewModalPrepare('initial');
  }, [modalZap])

  const changeViewModalPrepare = () => {
    if (nextViewModalPrepare === 'initial') {
      setTitleButtonModal('Salvar');
      setNextViewModalPrepare('verify');
    }


    if (nextViewModalPrepare === 'verify') {
      setTitleButtonModal('Ir para o Gerenciamento');
      setNextViewModalPrepare('success');
    }
  }

  function Imagens() {
    let ImagesTrue = [];
    for (let i = 0; i < data.images.length; i++) {
      if (data.images[i].show != "false") {
        ImagesTrue.push(data.images[i]);
      }
    }

    return (
      <>
        <ImageFull
          style={{ cursor: "pointer" }}
          onClick={() => setPhotoModal(true)}
          image={
            ImagesTrue[0]
              ? `'${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${ImagesTrue[0].image}'`
              : ""
          }
        >
          {isPartnerProperty === true &&
            <TagPartnerProperties>
              <h6>Parceiro Imóvel Vazio</h6>
            </TagPartnerProperties>
          }
          <Tag ref={tag}>{autoTag}</Tag>
        </ImageFull>
        <Image1
          style={{ cursor: "pointer" }}
          onClick={() => setPhotoModal(true)}
          image={
            ImagesTrue[1]
              ? `'${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${ImagesTrue[1].image}'`
              : ""
          }
        />
        <Image2
          style={{ cursor: "pointer" }}
          onClick={() => setPhotoModal(true)}
          image={
            ImagesTrue[2]
              ? `'${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${ImagesTrue[2].image}'`
              : ""
          }
        />
        <Image3
          style={{ cursor: "pointer" }}
          onClick={() => setPhotoModal(true)}
          image={
            ImagesTrue[3]
              ? `'${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${ImagesTrue[3].image}'`
              : ""
          }
        />
        <Image4
          style={{ cursor: "pointer" }}
          onClick={() => setPhotoModal(true)}
          image={
            ImagesTrue[4]
              ? `'${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${ImagesTrue[4].image}'`
              : ""
          }
        >
          {ImagesTrue.length > 5 ? (
            <button onClick={() => setPhotoModal(true)}>Mais fotos</button>
          ) : null}
        </Image4>
      </>
    );
  }

  function AsideComponent() {
    return (
      <Aside
        style={{ marginTop: isMobile ? 25 : "", width: isMobile ? "100%" : "" }}
      >
        <div className="card">
          <div className="row price">
            <p
              style={{
                width: isMobile ? "auto" : "",
                marginTop: isMobile ? "0" : "",
              }}
            >
              Valor
            </p>
            <h1
              style={{
                width: isMobile ? "auto" : "",
                marginBottom: isMobile ? "0" : "",
              }}
            >
              {data.descountPrice &&
                data.descountPrice != data.sellingPrice &&
                data.descountPrice < data.sellingPrice
                ? `R$ ${data.descountPrice.toLocaleString("pt-BR")}`
                : `R$ ${data.sellingPrice.toLocaleString("pt-BR")}`}
            </h1>
          </div>
          {data.descountPrice &&
            data.descountPrice != data.sellingPrice &&
            data.descountPrice < data.sellingPrice ? (
            <div
              className="row descount"
              style={{ marginBottom: 15, justifyContent: "flex-end" }}
            >
              <p style={{ textAlign: "end" }}>
                Valor reduzido de
                {` R$ ${data.sellingPrice.toLocaleString("pt-BR")} `}
                para
                {` R$ ${data.descountPrice.toLocaleString("pt-BR")}`}
              </p>
            </div>
          ) : null}
          <div className="row margin-left">
            <p className="condo">Condomínio</p>
            <p className="price">
              R${" "}
              {Number(
                String(data.condominiumPrice).split(".")[0]
              ).toLocaleString("pt-BR")}
            </p>
          </div>
          <div className="row margin-left">
            {data.IPTU ? (
              <>
                <p className="condo">IPTU (mensal):</p>
                <div className="price">
                  {data.IPTU
                    ? `R$ ${String(data.IPTU).toLocaleString("pt-BR") == "00.00"
                      ? "0"
                      : Number(
                        String(data.IPTU).split(".")[0]
                      ).toLocaleString("pt-BR")
                    }`
                    : ""}
                </div>
              </>
            ) : (
              ""
            )
            }
          </div>
          {
            data.IPTUNumber ? (
              <>
                <div
                  style={{
                    width: "100%",
                    textAlign: "right",
                    color: "#999",
                    marginTop: -5,
                  }}
                >
                  ({data.IPTUNumber})
                </div>
              </>
            ) : (
              ""
            )
          }
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "flex-end",
            }}
          >
            {!data.sold ? (
              <>
                {userId ? (
                  <p
                    style={{
                      fontSize: 18,
                      textAlign: "center",
                      marginBottom: 15,
                    }}
                  >
                    Comissão de{" "}
                    <span
                      style={{
                        fontSize: 25,
                        color: "#EA5C1E",
                        fontWeight: 600,
                      }}
                    >
                      {data.comission ? data.comission : "6"}%{" "}
                    </span>
                    (
                    {
                      money(
                        ((data?.descountPrice
                          ? data.descountPrice
                          : data.sellingPrice) /
                          100) *
                        (data.comission ? data.comission : 6)
                      )
                    }
                    )
                  </p>
                ) : (
                  ""
                )}

                {userId ? (
                  <Link
                    to={window.location.pathname}
                    style={{
                      height: '4rem',
                      fontSize: '1.5rem',
                      marginTop: 15,
                      background:
                        "linear-gradient(90deg, #E5983D 0%, #EB5C1C 100%)",
                    }}
                    onClick={() => {
                      document.querySelector("body").style.width = "100%";
                      document.querySelector("body").style.height = "100%";
                      document.querySelector("body").style.overflow = "hidden";
                      document.querySelector("body").style.position = "fixed";
                      window.scroll(0, isMobile ? 300 : 120);
                      setModalProposta(true);
                    }}
                  >
                    Enviar Proposta
                  </Link>
                ) : (
                  <Link
                    style={{
                      height: '4rem',
                      fontSize: '1.5rem',
                    }}
                    to={window.location.pathname}
                    onClick={() => window.location.href = `/login?goBack=/imoveis/${data._id}`}>
                    Entrar/Cadastre-se
                  </Link>
                )
                }
                <p className="text-center exclusive">
                  Exclusivo para corretores de imóveis.
                </p>
              </>
            ) : (
              ""
            )}
            {data.sold ? (
              <>
                <p
                  style={{
                    fontSize: 20,
                    textAlign: "center",
                    marginBottom: 15,
                  }}
                >
                  Imóvel Vendido
                </p>
                <Link style={{ marginBottom: 25 }} to="/imoveis">
                  Ver outros imóveis
                </Link>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="contactUs">
          <Link onClick={(e) => window.scrollTo(0, 0)} to="/contato">
            Ainda com dúvidas? Fale conosco
          </Link>
        </div>
        {
          userId ? (
            <>
              <h1 style={{ fontSize: 27, marginTop: 40 }}>
                Fotos e Documentação
              </h1>

              <a
                className="download"
                target="_blank"
                style={{ width: !isMobile ? "350px" : "" }}
                href={`${process.env.REACT_APP_FILES_URL}/${data._id}/fotos.zip`}
              >
                Baixar fotos como zip
              </a>
              <a
                className="download"
                target="_blank"
                style={{ width: !isMobile ? "350px" : "" }}
                href={`${process.env.REACT_APP_FILES_URL}/${data._id}/docs.zip`}
              >
                Baixar documentos como zip
              </a>
            </>
          ) : (
            ""
          )
        }
      </Aside >
    );
  }

  const ShareLink = () => {
    const link = `${process.env.REACT_APP_VIEW_PROPERTY_URL}/${data._id}`;
    const neighborhoodText = data?.neighborhood?.name ? ` - ${data?.neighborhood?.name}` : '';
    const text = `${data.type.name} ${data.generalType.name} ${data.fullLandSize} m², ${data.address}${neighborhoodText}`;
    const getMenuItem = (icon, name = '', href = '') => ({
      key: name,
      label: (
        <a href={href} target="_blank" >
          {icon} {name}
        </a>
      )
    })

    const shareItem = (icon, name = '') => ({
      key: name,
      label: (
        <a
          target='_blank'
          rel='nofollow noopener'
          onClick={async () => {
            await navigator.share({
              url: link,
              title: 'Compartilhar imóvel',
              text: text,
            })
          }}>
          {icon} {name}
        </a>
      )
    });

    const getFacebookUrl = () => encodeURI(`https://www.facebook.com/sharer.php?u=${link}`);
    const getTelegramUrl = () => encodeURI(`https://t.me/share/url?url=${link}&text=${text}`);
    const getWhatsAppUrl = () => encodeURI(`https://wa.me/?text=${link}`);

    return <Dropdown
      menu={{
        items: [
          getMenuItem(<Facebook color='#3b5998' size={15} />, 'Facebook', getFacebookUrl()),
          getMenuItem(<Telegram color='#0088cc ' size={15} />, 'Telegram', getTelegramUrl()),
          getMenuItem(<Whatsapp color='#25D366' size={15} />, 'WhatsApp', getWhatsAppUrl()),
          getMenuItem(<House color='#ea5c1e' size={15} />, 'Visualizar Imóvel', link),

          shareItem(<ShareFill size={15} />, 'Mais'),
        ],
      }}>
      <span style={{ display: 'inline-flex', gap: '0.5rem', width: '100%', placeContent: 'center', }}><ShareFill size={15} /> Compartilhar</span>
    </Dropdown>
  }

  const validationNextModal = () => {
    if (nextViewModalPrepare === 'verify') {
      return validateModalPrepareAds();
    }

    if (nextViewModalPrepare === 'success') {
      history.push('/admin/anuncios-zap/list');
    }

    return changeViewModalPrepare();
  }

  const validateIsData = (valueData) => {
    if (!valueData) {
      return false;
    }

    return true
  }

  const validateIsPrepareAds = () => !data.hasZapAdvertisement

  const resultValidateLength = () => {
    const validates = [
      validateIsData(data.pdf),
      validateIsData(data.memorial),
      validateIsPrepareAds()
    ];

    const lengthValidate = validates.filter(Boolean).length;

    return lengthValidate > 2;
  }

  function redirectProperty() {
    setModalZap(false);
    history.goBack();
  }

  const [currentTabModalZap, SetCurrentTabModalZap] = useState(zapAdsTabs[0].key);

  const nextModal = (val) => {
    if (nextViewModalPrepare === 'initial') {
      changeViewModalPrepare();
    }
    else if (currentTabModalZap === zapAdsTabs[zapAdsTabs.length - 1].key) {
      addPropertyToZap(val);
    }
    else {
      const index = zapAdsTabs.findIndex(item => item.key === currentTabModalZap);
      SetCurrentTabModalZap(zapAdsTabs[index + 1]?.key)
    }


  };

  return (
    <>
      {data ? (
        <>
          {modalZap &&
            <>
              <ModalZap
                open={modalZap}
                setOpen={setModalZap}
                closeModal={closeModalZap}
                nextModal={nextModal}
                activeKey={currentTabModalZap}
                propertyData={data}
                advertisementTutorial={setCheckboxMarkedModal}
                viewModalPrepare={nextViewModalPrepare}
                titleButtonPrimary={titleButtonModal}
                features={features}
                advertisementRemember={userSession.advertisementInformationSeen}
              >
                {nextViewModalPrepare == 'initial' &&
                  <PrepareAds />
                }
                {nextViewModalPrepare == 'verify' &&
                  <>
                    <ContainerVerifyPrepareAds>
                      <ContentImgVerifyPrepareAds>
                        <div className="content-back" onClick={() => setNextViewModalPrepare('initial')}>
                          <img src={ArrowLeft} alt="Arrow Left" />
                          <span className="title-back">Voltar</span>
                        </div>
                        <img src={ImageVerifyPrepared} alt="Img Success" />
                      </ContentImgVerifyPrepareAds>
                      <ContentInformationVerifyPrepareAds>
                        <Tabs defaultActiveKey={zapAdsTabs[0].key} activeKey={currentTabModalZap} className="custom-tab" onChange={(activeKey) => SetCurrentTabModalZap(activeKey)} items={zapAdsTabs} />
                      </ContentInformationVerifyPrepareAds>
                    </ContainerVerifyPrepareAds>
                  </>
                }
                {nextViewModalPrepare == 'success' &&
                  <SuccessPropertyPrepared />
                }
              </ModalZap>
            </>
          }
          {<Modal
            footer={null}
            width='85%'
            open={modal}
            onCancel={() => setModal(false)}
          >
            <InstructionDetails {...{ data, sellingNotes, isMobile, lockId, useApp, }} />
          </Modal>}
          {modal && photoModal ? (
            <PhotosModal
              images={data.images}
              id={data._id}
              setModal={setPhotoModal}
            />
          ) : (
            ""
          )}
          {photoModal ? (
            <PhotosModal
              images={data.images}
              id={data._id}
              setModal={setPhotoModal}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      <Header />
      {showLoading &&
        <Loading />
      }
      {showModalFeedback &&
        <ModalApp closeModalProposal={closeModalProposal} data={data} />
      }
      {modalProposta ? (
        <Banner
          setShowModalFeedback={setShowModalFeedback}
          setModalProposta={setModalProposta}
          setShowLoading={setShowLoading}
          data={data}
          comission={money(
            ((data?.descountPrice ? data.descountPrice : data.sellingPrice) /
              100) *
            (data.comission ? data.comission : 6)
          )}
        />
      ) : (
        ""
      )}
      {data ? (
        <>
          {modalCaract ? (
            <>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#000",
                  opacity: 0.3,
                  position: "absolute",
                  zIndex: 1,
                }}
              ></div>
              <div
                style={{
                  height: isMobile ? "80%" : "70%",
                  width: isMobile ? "90%" : "60%",
                  top: isMobile ? "10%" : "22%",
                  left: isMobile ? "5%" : "20%",
                  backgroundColor: "#fff",
                  position: "absolute",
                  boxShadow: "3px 3px 20px rgba(0,0,0,0.3)",
                  zIndex: 2,
                  borderRadius: isMobile ? 5 : 10,
                  overflowY: isMobile ? "auto" : "hidden",
                }}
              >
                <div
                  style={{
                    height: "8%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#EA5C1E",
                    fontWeight: 500,
                    fontSize: 19,
                    alignItems: "center",
                    paddingRight: 20,
                  }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setCaract(false);
                      document.querySelector("body").style.overflow = "scroll";
                      document.querySelector("body").style.position = "static";
                    }}
                  >
                    X
                  </span>
                </div>
                <div style={{ width: "87%", marginLeft: "5%", height: "92%" }}>
                  <h1
                    style={{
                      fontSize: isMobile ? "200%" : 35,
                      height: "13%",
                      paddingBottom: isMobile ? "100px" : 0,
                    }}
                  >
                    Todas as Características
                  </h1>

                  <div
                    style={{
                      display: "flex",
                      height: "7%",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={require("./home.svg")}
                      height="22"
                      width="25"
                      alt=""
                    />
                    <h4 style={{ fontSize: 18, marginLeft: 9 }}>
                      {data.type.name}{" "}
                      {data.generalType
                        ? data.generalType.name
                        : data.type.generalType == 1644602035863502
                          ? "Comercial"
                          : "Residencial"}
                    </h4>
                  </div>

                  <div
                    style={{
                      display: isMobile ? "grid" : "flex",
                      height: isMobile ? "none" : "25%",
                      flexDirection: "row",
                      alignItems: "center",
                      gridTemplateColumns: isMobile
                        ? "33.3333% 33.3333% 33.3333%"
                        : "none",
                      paddingLeft: isMobile ? "13%" : 0,
                      paddingTop: isMobile ? "-5%" : 0,
                    }}
                  >
                    <div
                      style={{
                        width: isMobile ? "33.3333%" : "15%",
                        height: "100%",
                        display: "flex",
                        marginRight: "3%",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <img
                        style={{ marginBottom: 10, marginTop: "50%" }}
                        src={require("../../assets/outline_icons/grey/ruler.png")}
                        height="25"
                        width="12"
                        alt=""
                      />
                      <p style={{ marginBottom: 8, fontSize: 15 }}>Área Útil</p>
                      <p style={{ fontWeight: 600, fontSize: 19 }}>
                        {data.fullLandSize} m²
                      </p>
                    </div>
                    <div
                      style={{
                        width: isMobile ? "33.3333%" : "15%",
                        height: "100%",
                        display: "flex",
                        marginRight: "3%",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <img
                        style={{ marginBottom: 10, marginTop: "50%" }}
                        src={require("../../assets/outline_icons/grey/ruler.png")}
                        height="25"
                        width="12"
                        alt=""
                      />
                      <p style={{ marginBottom: 8, fontSize: 15 }}>
                        Área Total
                      </p>
                      <p style={{ fontWeight: 600, fontSize: 19 }}>
                        {data.fullLandSize} m²
                      </p>
                    </div>
                    <div
                      style={{
                        width: isMobile ? "33.3333%" : "15%",
                        height: "100%",
                        display: "flex",
                        marginRight: "3%",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <img
                        style={{ marginBottom: 10, marginTop: "50%" }}
                        src={require("../../assets/outline_icons/grey/bed.png")}
                        height="25"
                        width="20"
                        alt=""
                      />
                      <p style={{ marginBottom: 8, fontSize: 15 }}>
                        N° de Quartos
                      </p>
                      <p style={{ fontWeight: 600, fontSize: 19 }}>
                        {data.bedrooms}
                      </p>
                    </div>
                    <div
                      style={{
                        width: isMobile ? "33.3333%" : "15%",
                        height: "100%",
                        display: "flex",
                        marginRight: "3%",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <img
                        style={{ marginBottom: 10, marginTop: "50%" }}
                        src={require("../../assets/outline_icons/grey/bed.png")}
                        height="25"
                        width="20"
                        alt=""
                      />
                      <p style={{ marginBottom: 8, fontSize: 15 }}>
                        N° de Suítes
                      </p>
                      <p style={{ fontWeight: 600, fontSize: 19 }}>
                        {data.suites}
                      </p>
                    </div>
                    <div
                      style={{
                        width: isMobile ? "33.3333%" : "15%",
                        height: "100%",
                        display: "flex",
                        marginRight: "3%",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <img
                        style={{ marginBottom: 10, marginTop: "50%" }}
                        src={require("../../assets/outline_icons/grey/bathtub.png")}
                        height="25"
                        width="20"
                        alt=""
                      />
                      <p style={{ marginBottom: 8, fontSize: 15 }}>
                        N° de Banheiros
                      </p>
                      <p style={{ fontWeight: 600, fontSize: 19 }}>
                        {data.bathrooms}
                      </p>
                    </div>
                    <div
                      style={{
                        width: isMobile ? "33.3333%" : "15%",
                        height: "100%",
                        display: "flex",
                        marginRight: "3%",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <img
                        style={{ marginBottom: 10, marginTop: "50%" }}
                        src={require("../../assets/outline_icons/grey/car_parking.png")}
                        height="25"
                        width="20"
                        alt=""
                      />
                      <p style={{ marginBottom: 8, fontSize: 15 }}>
                        N° de Vagas
                      </p>
                      <p style={{ fontWeight: 600, fontSize: 19 }}>
                        {data.parkingSpots}
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      height: "50%",
                      display: "grid",
                      gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr",
                      gridAutoRows: "0.2fr 0.2fr 0.2fr 0.2fr",
                      borderTop: "1px solid #aaa",
                      marginTop: "5%",
                      paddingTop: 15,
                      paddingBottom: 15,
                      fontSize: isMobile ? "70%" : "none",
                    }}
                  >
                    {data.features ? (
                      data.features.map((row) => (
                        <>
                          <div
                            style={{
                              marginLeft: 10,
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{ marginRight: 8 }}
                              src={require("./check.svg")}
                              height="25"
                              width="20"
                              alt=""
                            />
                            <p>{row.name}</p>
                          </div>
                        </>
                      ))
                    ) : (
                      <h3>Nenhuma característica definida.</h3>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {isMobile ? (
            <ImagesContainerMobile
              onClick={() => setRefresh(!refresh)}
              className="slider"
              style={photoModal ? { marginTop: "0px" } : {}}
            >
              <ImageFullMobile
                crossOrigin="anonymous"
                id="imagem-download"
                src={`${process.env.REACT_APP_FILES_URL}/${data._id}/fotos/${images.length > 0 &&
                  images[
                    index >= 0
                      ? images.length - 1 <= index
                        ? images.length - 1
                        : index
                      : 0
                  ].image
                  }`}
              />
              {index != 0 && (
                <ArrowSlide
                  onClick={() => {
                    setAuxIndex(auxIndex - 1);
                  }}
                  direction="left"
                >
                  <SpanArrow src={require("./../../assets/leftArrow.png")} />
                </ArrowSlide>
              )}
              {!(images.length - 1 <= index) && (
                <ArrowSlide
                  onClick={() => {
                    setAuxIndex(index + 1);
                  }}
                  direction="right"
                >
                  <SpanArrow src={require("./../../assets/rightArrow.png")} />
                </ArrowSlide>
              )}

              <CountImage>
                {`${index + 1}/${images.length}`}
                <img src={require("./../../assets/ic-camera-white.svg")} />
              </CountImage>

              <DownloadImage
                onClick={handleDownload}
                src={require("./../../assets/download.png")}
              />
              {isPartnerProperty === true &&
                <TagPartnerProperties>
                  <h6>Parceiro Imóvel Vazio</h6>
                </TagPartnerProperties>
              }
              <Tag ref={tag}>{autoTag}</Tag>
            </ImagesContainerMobile>
          ) : (
            <ImagesContainer style={photoModal ? { marginTop: "0px" } : {}}>
              {Imagens()}
            </ImagesContainer>
          )}
          <Body>
            <Container>
              <div className={resultValidateLength ? "street-column" : "street"}>
                <h1>
                  {data.address ? data.address : ""}
                  {userId
                    ? data.showNumber
                      ? ", " + data.addressNumber
                      : ""
                    : ""}
                  {userId && <InstructionsPadlock {...{
                    propertyId: data._id,
                    setModal,
                    useApp,
                    userId
                  }} />}
                </h1>
                <div className="actions">
                  <ImageButton
                  ><ShareLink /></ImageButton>
                  {userId && <>
                    {validateIsPrepareAds() && <button className="btn-zap" onClick={() => setModalZap(true)}>
                      Preparar Anúncio
                      <img src={VolumeLowIcon} alt="Volume Low Icon" />
                    </button>}
                    {validateIsData(data.pdf) && <ImageButton
                      href={`${process.env.REACT_APP_FILES_URL}/${data._id}/${encodeURIComponent(data.pdf)}`}
                      icon={<img src={require("./cam.svg")} alt="camera" />}
                    >Book</ImageButton>}
                    {validateIsData(data.memorial) && <ImageButton
                      href={`${process.env.REACT_APP_FILES_URL}/${data._id}/${encodeURIComponent(data.memorial)}`}
                      icon={<CardChecklist size={20} />}
                    >&nbsp;Memorial Descritivo</ImageButton>}
                  </>}
                </div>
              </div>
              <p className="city">
                <strong>
                  {userId
                    ? data.showComplement
                      ? data.addressComplement
                        ? data.addressComplement + " - "
                        : ""
                      : ""
                    : ""}
                </strong>
                {data.neighborhood ? data.neighborhood.name : ""} -{" "}
                {`${data.city?.name}, ${data.neighborhood?.state}`}
              </p>

              <div className="row">
                <div
                  className="icon-group"
                  style={isMobile ? { width: "100%" } : {}}
                >
                  <img
                    src={require("../../assets/outline_icons/orange/house.png")}
                    height="15"
                    width="15"
                    alt=""
                  />
                  <p>
                    <strong>
                      {data.type.name}{" "}
                      {data.generalType
                        ? data.generalType.name
                        : data.type.generalType == 1644602035863502
                          ? "Comercial"
                          : "Residencial"}
                    </strong>
                  </p>
                </div>
                <div
                  className="icon-group"
                  style={isMobile ? { width: "50%", marginTop: 15 } : {}}
                >
                  <img
                    src={require("../../assets/outline_icons/grey/ruler.png")}
                    height="15"
                    width="7"
                    alt=""
                  />
                  <p>{data.fullLandSize} m²</p>
                </div>
                <div
                  className="icon-group"
                  style={isMobile ? { marginTop: 15 } : {}}
                >
                  <img
                    src={require("../../assets/outline_icons/grey/bed.png")}
                    height="12"
                    width="15"
                    alt=""
                  />
                  <p>
                    {data.bedrooms} {data.bedrooms > 1 ? "quartos" : "quarto"}
                  </p>
                </div>
                <div
                  className="icon-group"
                  style={isMobile ? { width: "50%", marginTop: 15 } : {}}
                >
                  <img
                    src={require("../../assets/outline_icons/grey/bed.png")}
                    height="12"
                    width="15"
                    alt=""
                  />
                  <p>
                    {data.suites} {data.suites > 1 ? "suítes" : "suíte"}
                  </p>
                </div>
                <div
                  className="icon-group"
                  style={isMobile ? { marginTop: 15 } : {}}
                >
                  <img
                    src={require("../../assets/outline_icons/grey/bathtub.png")}
                    height="12"
                    width="15"
                    alt=""
                  />
                  <p>
                    {data.bathrooms}{" "}
                    {data.bathrooms > 1 ? "banheiros" : "banheiro"}
                  </p>
                </div>
                <div
                  className="icon-group"
                  style={isMobile ? { marginTop: 15 } : {}}
                >
                  <img
                    src={require("../../assets/outline_icons/grey/car_parking.png")}
                    height="12"
                    width="15"
                    alt=""
                  />
                  <p>
                    {data.parkingSpots}{" "}
                    {data.parkingSpots > 1 ? "vagas" : "vaga"}
                  </p>
                </div>
              </div>
              <Link
                to={window.location.pathname}
                onClick={() => {
                  document.querySelector("body").style.width = "100%";
                  document.querySelector("body").style.height = "100%";
                  document.querySelector("body").style.overflow = "hidden";
                  document.querySelector("body").style.position = "fixed";
                  window.scroll(0, window.innerWidth <= 1300 ? 250 : 120);
                  setCaract(true);
                }}
              >
                Mostrar todas as características{" "}
              </Link>

              {isMobile ? AsideComponent() : ""}
              {data.videoLink &&
                <>{data.isVideoLink ?
                  <video src={data.videoLink} width="100%" controls preload >
                    <p>Seu browser não é compatível, <a href={data.videoLink}>baixe este vídeo em formato MP4</a></p>
                  </video>
                  :
                  <p
                    width="100%"
                    dangerouslySetInnerHTML={{ __html: data.videoLink }} />
                }</>
              }

              {/* <!-- instruction ini --> */}

              {userId && <div
              >
                <h1
                  style={{ marginTop: "60px" }}
                >
                  Ofereça este exclusivo imóvel aos seus clientes!
                </h1>
                <h4>
                  Comissão{" "}
                  <span style={{ color: "#EA5C1E" }}>
                    {data.comission}%
                  </span>
                </h4>

                {useApp ? (
                  <>
                    <h4>
                      Instruções
                    </h4>

                    <ol
                      style={{
                        marginLeft: '2rem',
                        marginTop: "10px",
                      }}
                    >
                      <li style={{ marginBottom: "10px" }}>
                        Para abrir a fechadura é preciso usar o app do
                        Imóvel Vazio. <strong>Instale no seu celular!</strong>.
                      </li>
                      <li style={{ marginBottom: "10px" }}>
                        Instale também o app Fechadura Digital
                      </li>
                      <li style={{ marginBottom: "10px" }}>
                        Esteja próximo da fechadura do Imóvel.
                      </li>
                      <li style={{ marginBottom: "10px" }}>
                        {isMobile ?
                          <span>Clique no botão abaixo e aguarde novas instruções.</span>
                          : <span>Acesse o app Imóvel Vazio</span>}
                      </li>
                    </ol>

                    {data?.sellingNotesApp && <Collapse size='small' ghost defaultActiveKey={['more']}>
                      <Collapse.Panel header={<b style={{
                        fontWeight: 'bolder',
                        padding: '0px',
                      }}>Mais Instruções</b>} key="more"
                        style={{
                          height: "20%",
                          fontSize: isMobile ? 13 : 16,
                          overflowY: "auto",
                          color: "#333",
                          marginTop: "10px",
                        }}
                      >
                        {data?.sellingNotesApp}
                      </Collapse.Panel>
                    </Collapse>}

                    <DigitalLockLinkButton lockId={lockId} />
                    <InstallApp />

                  </>
                ) : (
                  <div
                    style={{
                      marginTop: isMobile ? "20px" : "30px",
                      marginBottom: "30px",
                    }}
                  >
                    <h4>
                      Instruções
                    </h4>

                    <div
                      style={{
                        height: "20%",
                        fontSize: isMobile ? 13 : 16,
                        overflowY: "auto",
                        color: "#333",
                        marginTop: "10px",
                      }}
                      ref={sellingNotes}
                    >
                      {data && data.sellingNotes
                        ? data?.sellingNotes
                        : "Sem instruções no momento."}
                    </div>
                  </div>
                )}

              </div>}

              {/* <!-- instruction fim --> */}

              <h1 style={{ marginTop: "60px" }}>Descrição</h1>
              <p
                dangerouslySetInnerHTML={{ __html: data?.description }}
              />

              {data.driveLink && <a
                className="download"
                style={{ display: 'inline-flex', gap: '0.5rem', fontWeight: 'bold' }}
                target="_blank"
                href={data.driveLink}
              >
                <CloudDownloadFill size={15} />
                Fotos para download
              </a>}

              {data?.descriptionSuggestion &&
                <>
                  <h1 style={{ marginTop: "60px" }}>Sugestão de descrição para anúncios</h1>
                  <p
                    dangerouslySetInnerHTML={{ __html: data?.descriptionSuggestion }}
                  />
                </>
              }

              <h1 style={{ marginTop: "60px" }}>Localização</h1>
              <iframe
                src={`https://www.google.com/maps/embed/v1/place?key=${"AIzaSyCbyjDUXM7xpSvvwybHURKFrOh65yPo2Gg"}&q=${data.neighborhood?.name
                  }+${data.city.name}+${data.neighborhood?.state}+${userId ? data.address : ""
                  }+${userId ? data.addressNumber : ""}`}
                frameBorder="0"
              >
                {/* https://maps.google.com/maps/api/geocode/json?address=Av%20Santo%20amaro,%20artur%20nogueira,%20sao%20paulo%20&sensor=false&key=AIzaSyCfZAKxUg065qNJ33P6NMjbPCY3NtPASMY */}
              </iframe>

              <h1 style={{ marginTop: "60px" }}>Imóveis similares</h1>
              <Carousel
                className="teste carousel"
                id={data._id}
                itemsToShow={isMobile ? 1 : 2}
                showCard={false}
              />
            </Container>
            {!isMobile ? AsideComponent() : ""}
          </Body>
        </>
      ) : (
        ""
      )}
      <Footer />
    </>
  );
}

export default PropertyDetail;
