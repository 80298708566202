import React, {useEffect, useState} from 'react'
import { InputGroup } from './../styles'

const ModalHistorico = ({modal, setModal, money, moneyToNumber}) => {
    if(modal.row && modal.row.historic.length > 0 && modal.row.historic)
        modal.row.historic = modal.row.historic.sort((a, b) => a.createdAt < b.createdAt?1:-1)
        
    if(modal.historico) {
        return <>
            <div style={{height: '100vh', top: 0, width: '100vw', backgroundColor: '#000', opacity: 0.3, position: "fixed", zIndex: 99999}}></div>
            <div style={{height: window.innerWidth <= 600 ? '66%':'44vh', width: window.innerWidth <= 600 ? '96%':'60vw', top: window.innerWidth <= 600 ? '12vh':'23vh', left: window.innerWidth <= 600 ? '2%':'15vw', backgroundColor: '#fff', position: "fixed", zIndex: 9999999, borderRadius: 10}}>
                <div style={{height: '8%', width: '100%', display: 'flex', justifyContent: 'flex-end', color: '#EA5C1E', fontWeight: 500, fontSize: 19, alignItems: 'center', paddingRight: 20, marginTop: 15}}><span style={{cursor: "pointer"}} 
                onClick={() => {
                    setModal({...modal, historico: false})
                }}>X</span></div>
                <div style={{width: '90%', marginLeft: '5%', height: '95%'}}>
                    <h1 style={{fontSize: window.innerWidth <= 600 ? 20:35, height: '13%', width: '100%', textAlign: 'center'}}>
                        Histórico de Alteração de Valores
                    </h1>
    
                    <div style={{overflow: 'auto', paddingTop: 15, height: '77%', width: '100%', fontSize: 19, marginTop: window.innerWidth <= 600 ?'0':'0%', textAlign: 'center'}}>
                        {
                            modal.row && modal.row.historic.length > 0 && modal.row.historic &&
                            modal.row.historic.map(historic => 
                                <div style={{overflow: 'auto', 
                                    whiteSpace: window.innerWidth <= 600 ? 'nowrap':'none',
                                    height: window.innerWidth <= 600 ? 120:75, width: '100%', 
                                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 15, marginTop: window.innerWidth <= 600 ?18:'0', borderBottom: window.innerWidth <= 600 ? '1px solid #aaa': '0'}}>
                                    <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', width: window.innerWidth <= 600  ? 420 :'25%'}}>
                                        {historic.isCreate? 'Data da Proposta:': 'Alteração da Proposta:'}<br></br>{window.innerWidth <= 600 && <br></br>}
                                        {historic.createdAt || ''} {!historic.isCreate && historic.isCorretor && <>(Corretor)</>} {!historic.isCreate && !historic.isCorretor && <>(Admin IV)</>}
                                    </div>
                                    <div style={{marginLeft: window.innerWidth <= 600 ? 15:0, display:'flex', alignItems: 'center', justifyContent: 'center', width: window.innerWidth <= 600  ? 420 :'25%'}}>
                                        Valor à Vista<br></br>{window.innerWidth <= 600 && <br></br>}
                                        {money(!String(historic.valorVista).includes('.00') && !String(historic.valorVista).includes('.') ? historic.valorVista*100:historic.valorVista) || 'R$ 0,00'}
                                    </div>
                                    <div style={{marginLeft: window.innerWidth <= 600 ? 15:0, display:'flex', alignItems: 'center', justifyContent: 'center', width: window.innerWidth <= 600  ? 420 :'25%'}}>
                                        Valor Financiado<br></br>{window.innerWidth <= 600 && <br></br>}
                                        {/* {money(historic.valorFinanciado) || 'R$ 0,00'} */}
                                        {money(!String(historic.valorFinanciado).includes('.00') && !String(historic.valorFinanciado).includes('.') ? historic.valorFinanciado*100:historic.valorFinanciado) || 'R$ 0,00'}
                                    </div>
                                    <div style={{marginLeft: window.innerWidth <= 600 ? 15:0, display:'flex', alignItems: 'center', justifyContent: 'center', width: window.innerWidth <= 600  ? 420 :'25%'}}>
                                        Total da Proposta<br></br>{window.innerWidth <= 600 && <br></br>}
                                        {/* {money(historic.totalProposta) || 'R$ 0,00'} */}
                                        {money(!String(historic.totalProposta).includes('.00') && !String(historic.totalProposta).includes('.') ? historic.totalProposta*100:historic.totalProposta) || 'R$ 0,00'}
                                        
                                    </div>
                                </div>
                            )
                        }
                    </div>
    
                </div>
    
            </div>
        </>
    }
    
    return <></>
}

const ModalVendido = ({modal, setModal, money, moneyToNumber}) => {
    const [valorVista, setValorVista] = useState('R$ 0,00')
    const [valorFinanciado, setValorFinanciado] = useState('R$ 0,00')
    const [valorComissao, setValorComissao] = useState('R$ 0,00')
    const [totalProposta, setTotalProposta] = useState('R$ 0,00')
    const [valorInscritura, setValorInscritura] = useState('R$ 0,00')

    useEffect(() => {
        if(modal.row){
            if(modal.row.sold){
                setValorVista(money(String(modal.row.sold.valorVista*100)))
                setValorFinanciado(money(String(modal.row.sold.valorFinanciado*100)))
                setValorComissao(money(String(modal.row.sold.valorComissao*100)))
                setValorInscritura(money(String(modal.row.sold.valorInscritura*100)))
            } 
        }
    }, [modal])

    useEffect(() => {
        const vista = Number(moneyToNumber(valorVista))
        const financiado = Number(moneyToNumber(valorFinanciado))
        const comissao = Number(moneyToNumber(valorComissao))
        const soma = vista+financiado+comissao
        setTotalProposta(money(String(Math.floor(soma*100))))  

    }, [valorVista, valorFinanciado, valorComissao])

    if(modal.vendido) {
        return <>
            <div style={{height: '100vh', top: 0, width: '100vw', backgroundColor: '#000', opacity: 0.3, position: "fixed", zIndex: 99999}}></div>
            <div style={{height: window.innerWidth <= 600 ? '84vh':'44vh', width: window.innerWidth <= 600 ? '96vw':'50vw', top: window.innerWidth <= 600 ? '8vh':'28vh', left: window.innerWidth <= 600 ? '2%':'25vw', backgroundColor: '#fff', position: "fixed", zIndex: 9999999, borderRadius: 10}}>
                <div style={{height: '8%', width: '100%', display: 'flex', justifyContent: 'flex-end', color: '#EA5C1E', fontWeight: 500, fontSize: 19, alignItems: 'center', paddingRight: 20, marginTop: 15}}><span style={{cursor: "pointer"}} 
                onClick={() => {
                    setModal({...modal, vendido: false})
                }}>X</span></div>
                <div style={{width: '90%', marginLeft: '5%', height: '95%'}}>
                    <h1 style={{fontSize: window.innerWidth <= 600 ? 30:35, height: '13%', width: '100%', textAlign: 'center'}}>
                        Imóvel Vendido
                    </h1>
    
                    <h4 style={{height: window.innerWidth <= 600 ? 'auto':'20%', width: '100%', fontSize: 19, marginTop: window.innerWidth <= 600 ?'0':'5%', textAlign: 'center',display: 'flex', flexDirection: window.innerWidth <= 600 ? 'column':'row'}}>
                        
                        <InputGroup className="input-group-teste">                        
                            <label htmlFor="">Valor à Vista</label>
                            <input placeholder="Ex: R$ 1.200.000,00" value={valorVista} onChange={element => setValorVista(money(element.target.value))}/>
                        </InputGroup>

                        <InputGroup className="input-group-teste">                        
                            <label htmlFor="">Valor Financiado</label>
                            <input placeholder="Ex: R$ 500.000,00" value={valorFinanciado} onChange={element => setValorFinanciado(money(element.target.value))}/>
                        </InputGroup>
                        <InputGroup className="input-group-teste">                        
                            <label htmlFor="">Valor da Comissão</label>
                            <input placeholder="Ex: R$ 50.000,00" value={valorComissao} onChange={element => setValorComissao(money(element.target.value))}/>
                        </InputGroup>
                    </h4>
                    <h4 style={{height: window.innerWidth <= 600 ? '40%':'20%', width: '100%', fontSize: 19, marginTop: window.innerWidth <= 600 ?'0':'1%', textAlign: 'center',display: 'flex', flexDirection: window.innerWidth <= 600 ? 'column':'row'}}>
                        
                        <InputGroup className="input-group-teste">                        
                            <label htmlFor="">Valor da Inscritura</label>
                            <input placeholder="Ex: R$ 50.000,00" value={valorInscritura} onChange={element => setValorInscritura(money(element.target.value))}/></InputGroup>

                        <InputGroup style={{background: '#fff', color: '#000'}} className="input-group-teste">                        
                            <label htmlFor=""><b>Total da Proposta</b></label>
                            <input disabled={true} placeholder="R$ 0,00" value={totalProposta}/>
                        </InputGroup>
                        
                        <div style={{height: window.innerWidth <= 600 ? 59:'74%', marginRight: 10, display: 'flex', cursor: 'pointer', width: window.innerWidth <= 600 ? '100%':'33%', borderRadius: 6, justifyContent: 'center', alignItems: 'center', fontSize: window.innerWidth <= 600 ? 18:23, color: '#fff', background: 'linear-gradient(90deg, #EA5C1E 0%, #C13818 100%)'}}
                            onClick={() => {
                                modal.vendidoFunction(modal.row._id, {valorVista, valorComissao, valorFinanciado, totalProposta, valorInscritura})

                                setModal({...modal, vendido: false})
                            }}
                        >
                            Salvar
                        </div>
                    </h4>
    
                </div>
    
            </div>
        </>
    }
    
    return <></>
}


const ModalExcluir = ({modal, setModal}) => {
    if(modal.excluir) {
        return <>
            <div style={{height: '100vh', top: 0, width: '100vw', backgroundColor: '#000', opacity: 0.3, position: "fixed", zIndex: 99999}}></div>
            <div style={{height: window.innerWidth <= 600 ? '50vh':'38vh', width: window.innerWidth <= 600 ? '96vw':'50vw', top: window.innerWidth <= 900 ? '25vh':'30vh', left: window.innerWidth <= 600 ? '2vw':'25vw', backgroundColor: '#fff', position: "fixed", zIndex: 9999999, borderRadius: 10}}>
                <div style={{height: '8%', width: '100%', display: 'flex', justifyContent: 'flex-end', color: '#EA5C1E', fontWeight: 500, fontSize: 19, alignItems: 'center', paddingRight: 20, marginTop: 15}}><span style={{cursor: "pointer"}} 
                onClick={() => {
                    setModal({...modal, excluir: false})
                }}>X</span></div>
                <div style={{width: '87%', marginLeft: '5%', height: '95%'}}>
                    <h1 style={{fontSize: window.innerWidth <= 600 ? 30:35, marginTop: '2%', height: '13%', width: '100%', textAlign: 'center'}}>
                        Excluir a Proposta
                    </h1>
    
                    <h4 style={{height: '40%', width: window.innerWidth <= 600 ? '100%':'120%', marginLeft: window.innerWidth <= 600 ? '0':'-10%', fontSize: window.innerWidth <= 600 ? 17:19, marginTop: window.innerWidth <= 600 ?'12%':'5%', textAlign: 'center'}}>
                        Ao confirmar, a proposta será excluída definitivamente. <br></br>
                        Confirme se deseja excluir a proposta. 
                    <span style={{color: '#EA5C1E'}}></span></h4>
    
                    <div style={{height: '25%', width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <h4 style={{height: '75%', display: 'flex', cursor: 'pointer', width: window.innerWidth <= 600 ? '100%':'30%', borderRadius: 6, justifyContent: 'center', alignItems: 'center', fontSize: window.innerWidth <=600 ? 18:23, marginTop: window.innerWidth <= 600 ? 0: 0, color: '#fff', background: 'linear-gradient(90deg, #EA5C1E 0%, #C13818 100%)'}}
                            onClick={() => {
                                modal.excluirFunction(modal._id)

                                setModal({...modal, excluir: false})
                            }}
                        >
                            Confirmar
                        </h4>
                    </div>
                </div>
    
            </div>
        </>
    }
    
    return <></>
}


export {ModalHistorico, ModalVendido, ModalExcluir}