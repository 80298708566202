import styled from 'styled-components'
import BgImage from '../../assets/Login/login-background.jpg'

export const BgImg = styled.div`
    height:880px;
    width:100vw;
    background : url(${BgImage});
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;

    .password-box{
        background-color: #eee;
        border-radius: 5px;
        height: 12%;
        width: 75%; 
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 15px;
        padding-top: 4px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;

        small{
            font-weight: 400;
        }

        input{
            background-color: transparent;
            border: transparent;
            border-bottom: 2px solid #ccc;
            width: 100%;
        }
    }
    .email-box{
        background-color: #eee;
        border-radius: 5px;
        height: 15%;
        width: 75%; 
        margin-top: 35px;
        padding-left: 10px;
        padding-right: 15px;
        padding-top: 4px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;

        small{
            font-weight: 400;
        }

        input{
            background-color: transparent;
            border: transparent;
            border-bottom: 2px solid #ccc;
            width: 100%;
        }
    }

    .min{
        width: 50%;
    }

    #btnEmail{
        border: 2px solid #EA5C1E; 
        color: #EA5C1E; 
        background: white;
        height: 12%;
        width: 73%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        cursor: pointer;
        margin-top: 28px;
    }

    #btnEmail:hover{
        border: none;
        background: #EA5C1E;
        color: white;   
    }

    @media(max-width:600px){
        height: 1200px;

        .contentModal{
            width: 90%;
            left: 5%;
        }
    }
`

export const FilterContainer = styled.div`
    width:100vw;
    height:90%;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:center;
    padding-bottom: 80px;

    .login-wrapper{
        background: #FFFFFF;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15), 0px 4px 32px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        width: 65%;
        max-width:1250px;
        display:flex;
        flex-wrap:wrap;
        padding:20px;
        padding-right:none;
        justify-content: space-evenly;
        align-items: center;
        height: 80%;

        .login-box{
            height: 90%;
            width: 50%;
            padding-left: 30px;
            padding-right: 45px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 15px;

            h1 {
                font-size: 50px;
            }

            .body-box{
                height: inherit;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;

                .email-box{
                    background-color: #eee;
                    border-radius: 5px;
                    height: 18%;
                    width: 100%;
                    margin-top: 10px;
                    padding-left: 10px;
                    padding-right: 15px;
                    padding-top: 4px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-evenly;

                    small{
                        font-weight: 400;
                    }

                    input{
                        background-color: transparent;
                        border: transparent;
                        border-bottom: 2px solid #ccc;
                        width: 100%;
                    }
                }

                .password-box{
                    background-color: #eee;
                    border-radius: 5px;
                    height: 18%;
                    width: 100%; 
                    margin-bottom: 30px;
                    padding-left: 10px;
                    padding-right: 15px;
                    padding-top: 4px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-evenly;

                    small{
                        font-weight: 400;
                    }

                    input{
                        background-color: transparent;
                        border: transparent;
                        border-bottom: 2px solid #ccc;
                        width: 100%;
                    }
                }
            }
            
            a{
                text-decoration:none;
                width: 100%;
                height: 18%;   
                margin-bottom: 9px; 

                .bt-login{
                    background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
                    color: white;
                    height: 100%;
                    width: 100%;
                    border-radius: 8px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 400;
                    font-size: 25px
                }

                .bt-login:hover{
                    border: 3px solid #EA5C1E;
                    color: #EA5C1E;
                    background: white;
                }
            }
            small{
                font-size: 14px;
                text-align: center;
                cursor: pointer;
                font-weight: 300;
            }
        }

        .register-box{
            border-left: 1px solid #bbb;
            height: 90%;
            width: 50%;
            padding-left: 30px;
            padding-right: 45px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 15px;



            h1 {
                font-size: 50px;
            }
            .body-box{
                height: inherit;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;

                .group-box{
                    height: 18%;
                    width: 100%;
                    margin-top: -20px;
                    padding-left: 10px;
                    padding-right: 15px;
                    padding-top: 4px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-evenly;
                    font-size: 50px;

                    small{
                        font-weight: 400;
                        color: black;
                    }

                    input{
                        background-color: transparent;
                        border: transparent;
                        border-bottom: 2px solid #ccc;
                        width: 100%;
                    }
                }

            }
            
            a{
                text-decoration:none;
                width: 100%;
                height: 18%;   
                margin-bottom: 9px; 

                .bt-register{
                    border: 3px solid #EA5C1E;
                    color: #EA5C1E;
                    height: 100%;
                    width: 100%;
                    border-radius: 8px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 600;
                    font-size: 25px
                }

                .bt-register:hover{
                    background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
                    border: 0px solid #EA5C1E;
                    color: white;
                }
            }
            small{
                font-size: 14px;
                text-align: center;
                cursor: pointer;
                font-weight: 300;
                color: white;
            }

        }
    }
    
    @media(max-width:600px){
        justify-content: flex-start;
        margin-top:70px;

        .login-wrapper{
            width: 90%;
            padding-left: 0px;
            padding-right: 0px;
            

            .login-box{
                width: 100%;
                height: 60%;
                a{
                    height: 14%;
                }
            }

            .register-box{
                width: 100%;
                height: 40%;
                border-left: 0px solid #bbb;
                border-top: 1px solid #bbb;

                h1{
                    font-size: 10vw;
                    //text-align: center;
                    margin-top: 20px;
                }
            }
        }
    }
`

export const Announcement = styled.div`
width: 100vw;
height: 69px;
background: #ECECEC;
text-align:center;
display:flex;
justify-content:center;
align-items:center;

    h2{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: right;
        letter-spacing: 0.05em;
        text-decoration-line: underline;
        color: #0EA5BB;
    }

    h2:first-child{
        text-decoration-line: none;
        margin-right:20px;
        color: #676666;
    }

`

export const SellingProperties = styled.section`
    width:100vw;
    display:flex;
    flex-direction:column;

    .title{
        margin-top:90px;
        font-size: 36px;
        line-height: 41px;
        text-align: center;
        color: #1E1D1D;
    }

    p{
        text-align: center;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.05em;

        color: #1E1D1D;
    }

    .carousel{
        margin-top:50px;
        padding-left:100px;
        padding-right:100px;
    }

`

export const BtnContainer = styled.div`
    width:100vw;
    height:10vh;
    display:flex;
    justify-content:center;
    align-items:center;

    a{
        text-decoration:none;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #EA5C1E;
        border: 1px solid #EA5C1E;
        box-sizing: border-box;
        border-radius: 4px;
        padding:14px;
        padding-left:40px;
        padding-right:40px;
    }
`

export const MarketingContainer = styled.section`
    margin-top:10vh;
    width:100vw;
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: #1E1D1D;

    p{
        margin-top:10px;
        font-weight: 300;
        font-size: 20px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.05em;

        color: #1E1D1D;
    }

    .images{
        display:flex;
        width:100%;
        justify-content:space-around;
        align-items:center;
        flex-wrap:wrap;
    }
`
export const PropertyOffer = styled.div`
    margin-top:10vh;
    width:100vw;
    height: 272px;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    background:url(${require('../../assets/home/offerbg.png')});
    background-size:cover;

    h1{
        font-weight: bold;
        font-size: 36px;
        line-height: 41px;
        text-align: center;
        margin-bottom:20px;
        color: #FFFFFF;
    }

    p{
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.05em;
        margin-bottom:20px;
        color: #FFFFFF;
    }

    a{
        text-decoration:none;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;
        padding:12px;
        color: #FFFFFF;
        background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
        border-radius: 4px;
    }
`

export const Bank = styled.div`
    width:100vw;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    margin-top:10vh;
    margin-bottom:10vh;

    h1{
        font-size: 36px;
        line-height: 41px;
        text-align: center;
        margin-bottom:20px;
        /* #1E1D1D */

        color: #1E1D1D;
    }

    p{
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
        /* or 139% */

        text-align: center;
        letter-spacing: 0.05em;
        margin-bottom:20px;
        color: #1E1D1D;
    }

    a{
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;

        color: #EA5C1E;
        padding:10px;
        padding-left:30px;
        padding-right:30px;
        text-decoration:none;
        border: 1px solid #EA5C1E;
        box-sizing: border-box;
        border-radius: 4px;
    }
`

