import styled from 'styled-components';

export const Container = styled.div`
  margin-left:20vw;
  width:80vw;
  height:100%;
  padding:30px;
  padding-right:20vw;

  @media only screen and (max-width: 600px) {
      padding-top: 4vh;
      margin-left: 0;
      width: 100%;
      padding-right: 0px;
      padding: 30px;
  }

  .bread-crumb{
    display:flex;
    align-items:center; 
    margin-bottom:40px;
    margin-top:10vh;
    
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        margin-top: 3vh;
        width: 100%;
    }

    .category{
        color: #0A677C;
        margin-right:3px;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }

    .where{
        color: #EB5C1C;
        text-decoration:none;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }
  }
  


    .box-control{
        border: 1px solid #ddd;
        width: 125%;
        height: 8vh;
        border-radius: 4px;
        padding-left: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        
        @media only screen and (max-width: 600px) {
            width: 100%;
            flex-direction: column;
            height: 150px;
            padding-left: 0;
        }

        button{
            background: white;
            border: 1.8px solid #EA5C1E;
            height: 60%;
            border-radius: 4px;
            width: 30%;
            color: #EA5C1E;
            background-color: white;
            margin-right: 15px;
        }

        button:hover{
            filter: none;
            border: none;
            background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
            color: white;
        }

        label{
            background: white;
            border: 1.8px solid #EA5C1E;
            height: 60%;
            border-radius: 4px;
            width: 30%;
            color: #EA5C1E;
            background-color: white;
            margin-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            cursor: pointer;
        }

        label:hover{
            filter: none;
            border: none;
            background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
            color: white;
        }
        
        span {
            font-size: 16px;
            font-weight: 400;
        }

        .buttonSave{
            border: none;
            background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
            float: right;
            color: white;
        }

        .bt-inverse{
            border: 1px solid #EA5C1E;
            background: white;
            float: right;
            color: #EA5C1E;
        }
        
        .container-control{
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            
            @media only screen and (max-width: 600px) {
                width: 100%;
            }
        }
        

        .flex-end{
            justify-content: flex-end
        }
        
        .input-padding {
            padding-left: 20px;
            padding-right: 20px;
        }

        .input-tolerant{
            background: #eee;
            height: 75%;
            width: 40%;
            margin-right: 20px;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            align-items: space-evenly;
            justify-content: space-around;
            padding-left: 8px;

            @media only screen and (max-width: 600px) {
                margin-right: 0px;
            }

            span{
                font-size: 14px;
            }

            input{
                background: transparent;
                border: none;
                color: #aaa
            }

            select{
                background: transparent;
                border: none;
                color: #888;
            }
            
        }
    }

    .box-table{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        height: max-content;

        .box-header{
            height: 60px;    
            width: 70%;
            display: flex;
            flex-direction: row;
            border-radius: 5px;
            background: #0A677C;
            
            @media only screen and (max-width: 600px) {
                width: 100%;
                padding-left: 0;
            }

            h1 {
                height: 100%;
                width: 33.3%;
                padding-left: 10px;
                color: white;
                font-size: 20px;
                display: flex;
                align-items: center;
                font-weight: 500;
                
            }
            
            .h1-border{
                border-right: 1px solid #fff;
            }

            .h1-1{
                min-width: 5%;
            }

            .h1-2{
                min-width: 26%;
            }

            .h1-3{
                min-width: 24%;
            }

            .h1-4{
                min-width: 15%;
            }
            
            .h1-5{
                min-width: 65%;
                font-size: 16px;
            }

            .h1-6{
                min-width: 35%;
                font-size: 17px;
            }

            .container-divide{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-right: 0px solid red; 
                flex-direction: row;

                div{
                    width: 100%;
                    height: 100%;
                    border: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    white-space: nowrap;
                    overflow-x: auto;
                }

                .border-top{
                    border-top: 2px solid #ddd;
                }

                .btOptions{
                    cursor: pointer;
                    color: #00AEC8;
                    font-weight:500;
                    
                    @media only screen and (max-width: 600px) {
                        font-size: 16px;
                    }
                }

                .divNome{
                    align-items: flex-start;
                    padding-left: 10px;
                }
                
                .divEmail{
                    align-items: flex-start;
                    padding-left: 20px;
                    font-weight: 500;
                }

                .divTelephone{
                    align-items: flex-start;
                    padding-left: 10px;
                }

                // .btOptions:hover{
                //     background: #00AEC8;
                //     color:white;
                // }

                .divData{
                    font-size: 13px;
                    height: 25%;
                    align-items: flex-start;
                    padding-left: 20px;
                    padding-top: 4px;
                    overflow: hidden;
                    border-top: none;
                    white-space: break-spaces;
                    overflow-y: auto;
                }
                .divDescription{
                    font-size: 15px;
                    height: 50%;
                    align-items: flex-start;
                    padding-left: 20px;
                    overflow: hidden;
                    border-top: none;
                    white-space: break-spaces;
                    color: #0A677C;
                    font-weight: 700;
                    overflow-y: auto;
                }

                .divState{
                    font-size: 14px;
                    height: 25%;
                    align-items: flex-start;
                    padding-left: 20px;
                    overflow: hidden;
                    color: #0A677C;
                    font-weight: 400;
                    border-top: none;
                    white-space: break-spaces;
                    overflow-y: auto;
                }
            }
        }

        .box-body{
            margin-top: 20px;   
            background: white;
            height: max-content;
            display: flex;
            flex-direction: column;

            .table-row{
                display: flex;
                width: 100%;
                height: 60px;
                flex-direction: row;
                border: 2px solid #ddd;
                border-radius: 5px;


                .divID{
                    text-align: center;
                    justify-content: center;
                }
                .border-div{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    border-right: 2px solid #ddd;
                }

                .table-index{
                    color: #0A677C;
                    font-weight: 600;
                }
                .row-last{
                    border-right: 1px solid #ddd;
                }
            }
        }
    }
`;

export const Item = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`

export const ImageItem = styled.label`
    min-width: 178px;
    min-height: 128px;
    border-radius: 4px;
    background:url(${props => props.image ? props.image : require('../../assets/adminOfferProperty/imgplaceholder.svg')});
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    position:relative;
    cursor:pointer;

    input{
        display:none;
    }

    .close{
        display : ${props => props.children[1].props.show ? 'flex' : 'none '};
        position:absolute;
        width: 16px;
        height: 16px;
        background: #FFFFFF;
        right : 10px;
        top : 10px;
        border:none;
        font-size:16px;
        border-radius:8px;
        align-items:center;
        justify-content:center;
    }
`
export const BtnWrapper = styled.div`
    width: 100%;
    height: 128px;
    display:flex;
    justify-content:center;
    align-items:center;
`

export const PlusBtn = styled.button`  
    margin-left:20px;
    background-color:transparent;
    border:none;
`

export const Carousel = styled.div`
    width:100%;
    height:auto;
    overflow-x:auto;
    display:flex;

`

export const DocItem = styled.label`
    min-width: 178px;
    min-height: 128px;
    border-radius: 4px;
    background:url(${props => props.image ? props.image : require('../../assets/adminOfferProperty/docplaceholder.svg')});
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    position:relative;
    cursor:pointer;

    input{
        display:none;
    }

    .close{
        display : ${props => props.children[1].props.show ? 'flex' : 'none '};
        position:absolute;
        width: 16px;
        height: 16px;
        background: #FFFFFF;
        right : 10px;
        top : 10px;
        border:none;
        font-size:16px;
        border-radius:8px;
        align-items:center;
        justify-content:center;
    }
`