import React from "react";
import { BarStory, MainStory, StoryTextLP, StoryTextTitle, 
       StoryFirstParagraph, StoryLastParagraph, BtnPartnerBroker, BtnSeeProperties,
       ImgBanner  } from "./style";
import ImageGoTogether from '../../../assets/LP_MG/GoTogether_LP/ImageGoTogether.svg'
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from '@ant-design/icons';
import "../../../global.css";

export default function GoTogether({ user, city }) {

  return (
    <>
        <BarStory>
         <MainStory>
            <StoryTextLP>
             <StoryTextTitle>
                <span>
                 <span className="title-color">Vamos juntos <br />
                 </span> transformar o mercado</span>
             </StoryTextTitle>
             <StoryFirstParagraph>
                <p>
                  BH é nosso primeiro destino, pois parte do nosso corpo diretivo também é mineiro.
                </p>
            </StoryFirstParagraph>
            <StoryLastParagraph>
                <p>
                Essa é uma aliança de valores. Assim como você, damos valor ao bem maior 
                de cada pessoa, seu imóvel.
                </p>
            </StoryLastParagraph>
            {user !== undefined ?
              <BtnSeeProperties>
              <Link to={`/imoveis?city_id=${city}`}>
                <span className="title-button">
                  Conferir Imóveis
                </span>
                <ArrowRightOutlined className="icon-arrow" />
              </Link>
              </BtnSeeProperties> 
               :
              <BtnPartnerBroker>
                <Link to="/register?origin=chegamos_em_bh" className="linkButton">
                  <span className="title-button">Quero Ser Corretor Parceiro</span>
                  <ArrowRightOutlined className="icon-arrow" />
                </Link>
              </BtnPartnerBroker>
            }
            </StoryTextLP>
            <ImgBanner>
            <img src={ImageGoTogether}></img>
           </ImgBanner>
       </MainStory>
    </BarStory>
    </>
  );
}
