// Core
import React, { useState } from "react";
import wppIcon from "../../../assets/whats.png";

import { Affix } from "antd";

const WppButton = React.memo(function WppButton() {
  const [bottom, setBottom] = useState(10);

  return (
    <a
      target="_blank"
      href="http://wa.me/5521993643054"
      style={{ position: "fixed", bottom: 15, right: 15 }}
    >
      <img
        src={wppIcon}
        alt="whatsapp-logo"
        className="uk-invisible"
        width={60}
      />
    </a>
  );
});

export default WppButton;
