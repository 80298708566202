import React, { useState, useEffect } from 'react';
import { getMobileOperatingSystem, getUserAgentTypes } from '../../helpers/devices';
import { useParams } from "react-router-dom";

const DigitalLockLinkButton = ({ lockId}) => {
  const [currentUserAgent, setCurrentUserAgent] = useState(undefined);
  useEffect(() => {
    const current = getMobileOperatingSystem();
    setCurrentUserAgent(current);
  }, []);

  const params = useParams();
  const id = params.id;
  const userId = localStorage.getItem("id");
  const link = `fechaduradigitalapp://property/${id}/user/${userId}/lock/${lockId}`;
  
  const openLockHandleClick = () => {
    window.open(link);
  };

  return (
    <center>
      {getUserAgentTypes().android === currentUserAgent &&
        <a
          href={link}>

          <button
            to={window.location.pathname}
            style={{
              marginTop: "15px",
              marginBottom: "15px",
              color: "white",
              backgroundColor: "#EA5C1E",
              border: "none",
              padding: "10px 10%",
              borderRadius: "5px",
            }}
          >
            Fechadura Digital
          </button>
        </a>}

      {
        getUserAgentTypes().ios === currentUserAgent &&
        <button
          style={{
            marginTop: "15px",
            marginBottom: "15px",
            color: "white",
            backgroundColor: "#EA5C1E",
            border: "none",
            padding: "10px 10%",
            borderRadius: "5px",
          }}
          onClick={openLockHandleClick}
        >
          Fechadura Digital
        </button>}
    </center>);
}

export default DigitalLockLinkButton;