/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../global.css";
import api from "../../api";
import InputMask from "react-input-mask";
import FieldErrorValidation from "../../components/FieldErrorValidation";
import { NOTIFICATION_TYPE, openNotification } from "../../utils/notification";
import PlaceField from "../../components/forms/PlaceField";


export default function StepOne({
  setStep,
  step,
  setEndereco,
  endereco,
  setNumero,
  numero,
  setComplemento,
  complemento,
  setTipo,
  tipoSelected,
  setBairro,
  block,
  setBlock,
  setCurrentPlace,
  currentPlace,
}) {
  const [tipo, setTipoArray] = useState([]);
  const [tipe, setTipe] = useState("");
  const [pristine, setPristine] = useState({
    address: true,
    type: true,
  });

  function handleNext() {
    setPristine({
      address: false,
      type: false,
    });
    validatePlace(currentPlace);
    if (!checkErrorStyle('address').isValid)
      return;
    if (!checkErrorStyle('type').isValid) {
      openNotification("Informe o tipo de imóvel!", NOTIFICATION_TYPE.warning);
      return;
    }
    setStep(2);
  }

  const handleLoadTypes = async () => {
    const { data } = await api.get("/propertyType?limit=9999");
    let tips = new Array();
    data.propertyTypes.map((item) => {
      tips.push({ value: item._id, name: item.name });
      if (item._id == tipoSelected) {
        setTipe(item.name);
      }
    });
    setTipoArray(tips);
  };

  /**
   * 
   * @param {'address' | 'type'} fieldName 
   */

  const checkErrorStyle = (fieldName) => {

    // if (fieldName === 'address' && !pristine.address && (!endereco || !numero))
    if (fieldName === 'address')
      return {
        pristine: pristine.address,
        isValid: !!(endereco && numero),
        errorMessage: !endereco ? "Selecione um endereço válido!" : "Informe o número corretamente e selecione!",
      };
    // if (fieldName === 'type' && !pristine.type && !tipoSelected)
    if (fieldName === 'type' && !pristine.type)
      return {
        pristine: pristine.type,
        isValid: tipoSelected > 0,
      };

    return {};

  };

  useEffect(() => {
    setPristine({
      address: !endereco,
      type: !tipoSelected
    });
  }, []);

  useEffect(() => {

    let dirtyAddress = !pristine.address;
    let dirtyType = !pristine.type;

    if (endereco)
      dirtyAddress = true;

    if (tipoSelected)
      dirtyType = true;

    setPristine({
      address: !dirtyAddress,
      type: !dirtyType,
    });

  }, [endereco, tipoSelected]);

  useEffect(() => {
    handleLoadTypes();
  }, []);

  const verify = (value) => {
    if (!(value === endereco)) {
      setBlock(true);
    }
  };

  const validatePlace = (place) => {
    if (!place.address) {
      openNotification("Endereço inválido!", NOTIFICATION_TYPE.warning);
      return false;
    }

    if (!place.addressNumber) {
      openNotification("Número não informado!", NOTIFICATION_TYPE.warning);
      return false;
    }

    return true;

  };

  return (
    <>
      <div className="step">
        <PlaceField
          {...checkErrorStyle('address')}
          className="input-box"
          stateValue={endereco}
          number={numero}
          setValue={setEndereco}
          setComplement={setComplemento}
          setNumber={setNumero}
          onChangeVerification={verify}
          setPlace={setCurrentPlace}
        />
        <div className="container-input-box">
          <div className="input-box">
            <small>Complemento (não obrigatório)</small>
            <InputMask
              value={complemento}
              onChange={(text) => setComplemento(text.target.value)}
            />
          </div>
          <FieldErrorValidation {...checkErrorStyle('type')} className="input-box">
            <small>Tipo de imóvel*</small>
            <select
              value={tipoSelected}
              name="Tipo"
              onChange={(value) => {
                const val = (value.target.value === "Selecione") ? '' : value.target.value;
                setTipo(val);
              }}
            >
              <option id="optiTeste">Selecione</option>
              {tipo.map((items) => (
                <option key={items.value.toString()} value={items.value}>{items.name}</option>
              ))}
            </select>
          </FieldErrorValidation>
        </div>
        <div className="container-next">
          <button onClick={handleNext}>Avançar</button>
        </div>
      </div>
    </>
  );
}
