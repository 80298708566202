import React, { useEffect } from "react";
import { useState } from "react";
import ReactInputMask from "react-input-mask";
import { getRealEstateAppraisalsAsync } from "../../services/realEstateAppraisal.service";
import { money } from "../../utils/converterHelper";

const DeprecatedResult = ({
  itbiPriceCurrentYear,
  itbiPriceLastYear,
  iVPriceLastYear,
  iVPriceCurrentYear,
  m2 = 1,
  createdAt = '',
}) => {

  const calcM2 = (value) => money(value / m2);
  const year = !createdAt ? new Date().getFullYear() : Number(createdAt.split('-')[0]);
  const lastYear = year - 1;

  return <>

    {!!itbiPriceCurrentYear && <div className="container-input-box">
      <div className="box-divide">
        <small>Preço m2 ITBI em {year}</small>
        <ReactInputMask disabled readOnly value={calcM2(itbiPriceCurrentYear)} />
      </div>

      <div className="box-divide">
        <small>Preço médio ITBI em {year}</small>
        <ReactInputMask disabled readOnly value={money(itbiPriceCurrentYear)} />
      </div>
    </div>}

    {!!iVPriceCurrentYear && <div className="container-input-box">
      <div className="box-divide">
        <small>Preço m² IV em {year}</small>
        <ReactInputMask disabled readOnly value={calcM2(iVPriceCurrentYear)} />
      </div>
      <div className="box-divide">
        <small>Preço médio IV em {year}</small>
        <ReactInputMask disabled readOnly value={money(iVPriceCurrentYear)} />
      </div>
    </div>}

    {!!itbiPriceLastYear && <div className="container-input-box">
      <div className="box-divide">
        <small>Preço m2 ITBI em {lastYear}</small>
        <ReactInputMask disabled readOnly value={calcM2(itbiPriceLastYear)} />
      </div>

      <div className="box-divide">
        <small>Preço médio ITBI em {lastYear}</small>
        <ReactInputMask disabled readOnly value={money(itbiPriceLastYear)} />
      </div>
    </div>}

    {!!iVPriceLastYear && <div className="container-input-box">
      <div className="box-divide">
        <small>Preço m² IV em {lastYear}</small>
        <ReactInputMask disabled readOnly value={calcM2(iVPriceLastYear)} />
      </div>

      <div className="box-divide">
        <small>Preço médio IV em {lastYear}</small>
        <ReactInputMask disabled readOnly value={money(iVPriceLastYear)} />
      </div>
    </div>}
  </>
}

const RealEstateAppraisals = ({
  offerId,
  itbiAvgValueCurrentYear,
  itbiPriceCurrentYear,
  itbiAvgValueLastYear,
  itbiPriceLastYear,
  iVAvgValueLastYear,
  iVPriceLastYear,
  iVAvgValueCurrentYear,
  iVPriceCurrentYear,
  landSizeUseful = 0,
  createdAt = '',
}) => {

  const [appraisals, setAppraisals] = useState([]);
  const [state, setState] = useState(false);
  const [count, setCount] = useState(0);
  const [m2, setM2] = useState(1);
  const [date, setDate] = useState('');

  const getCount = () => {

    const isValid = (value) => value && value !== 0;
    let countLength = appraisals.length;

    if (isValid(itbiAvgValueCurrentYear)) countLength++;
    if (isValid(itbiPriceCurrentYear)) countLength++;
    if (isValid(itbiAvgValueLastYear)) countLength++;
    if (isValid(itbiPriceLastYear)) countLength++;
    if (isValid(iVAvgValueLastYear)) countLength++;
    if (isValid(iVPriceLastYear)) countLength++;
    if (isValid(iVAvgValueCurrentYear)) countLength++;
    if (isValid(iVPriceCurrentYear)) countLength++;
    setCount(countLength);
  }

  useEffect(() => setDate(date || createdAt), [createdAt]);

  useEffect(() => setM2(landSizeUseful), [landSizeUseful]);

  useEffect(() => getCount(), [
    itbiAvgValueCurrentYear,
    itbiPriceCurrentYear,
    itbiAvgValueLastYear,
    itbiPriceLastYear,
    iVAvgValueLastYear,
    iVPriceLastYear,
    iVAvgValueCurrentYear,
    iVPriceCurrentYear,
  ]);

  const getAsync = async () => {

    try {
      const { data } = await getRealEstateAppraisalsAsync(offerId);
      if (data?.length && Array.isArray(data)) {
        const result = data.map(({ usefulAreaPrice, m2Price, ...rest }) => (
          {
            ...rest,
            usefulAreaPrice: money(usefulAreaPrice),
            m2Price: money(m2Price),
          }
        ))
        setAppraisals(result);
        setDate(result[0].createdAt);
      }
      else
        getCount();
    } catch (e) {
      const { message, stack } = e;
      console.error({ message, stack });
    }
    setState(true);
  };

  useEffect(() => { if (offerId) getAsync() }, [offerId]);

  return <>
    {state ? <>
      <div className="analise">
        Avaliação imobiliária: {(!appraisals.length && count === 0) ? <span>Não há informação</span> : new Date(date).toLocaleDateString()}
      </div>
      {
        appraisals?.map(({ _id, appraisalType, year, usefulAreaPrice, m2Price }) =>
          <div key={_id} className="container-input-box">

            <div className="box-divide">
              <small>Preço m² {appraisalType} em {year}</small>
              <ReactInputMask disabled readOnly value={m2Price} />
            </div>

            <div className="box-divide">
              <small>Preço médio {appraisalType} em {year}</small>
              <ReactInputMask disabled readOnly value={usefulAreaPrice} />
            </div>

          </div>
        )
      }
      {count > 0 && <DeprecatedResult {...{
        itbiPriceCurrentYear,
        itbiPriceLastYear,
        iVPriceLastYear,
        iVPriceCurrentYear,
        m2,
        createdAt
      }}>

      </DeprecatedResult>}
    </> : null}
  </>

}
export default RealEstateAppraisals;