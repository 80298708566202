import React from "react";
import * as S from "./styles";
import "../../../global.css";
import IconAlert from "../../../assets/IconAlert.svg";
import ArrowLeft from '../../../assets/ArrowLeft.svg'

export default function InformationAlert({ setNextViewModalBinding }) {
  return (
    <>
      <S.Container>
        <S.ContentButtonBack>
            <div className="content-back" onClick={() => setNextViewModalBinding('start')}>
                <img src={ArrowLeft} alt="Arrow Left" className="icon-left" />
                <span className="title-back">Voltar</span>
            </div>
          </S.ContentButtonBack>
        <S.Content>
          <img src={IconAlert} alt="Icon Alert"  className="alert-icon" />
          <span className="title">Atenção!</span>
          <span className="subtitle">
            Uma vez feita a vinculação, apenas seus anúncios feitos com nossa
            integração aparecerão nas plataformas escolhidas!
          </span>
        </S.Content>
      </S.Container>
    </>
  );
}
