import React, { useEffect, useState } from 'react'
import '../../global.css';
import { Link } from 'react-router-dom'
import { getCityOfUserActing, } from '../../utils/sessionHelper';
import { getCitiesAsync } from '../../services/city.service';

const url = '/imoveis';
export default function Finish({ setStep }) {
    const [hrefTo, setHrefTo] = useState(url);
    useEffect(() => {
        const cityId = getCityOfUserActing();
        getCitiesAsync({ limit: 9999, orderBy: 'city', direction: 'ASC' })
            .then(({ data: cities }) => {
                if (cities?.some(c => c.value === cityId)) {
                    setHrefTo(`${url}?city_id=${cityId}`);
                }
                else {
                    setHrefTo(url);
                }
            })
            .catch(() => setHrefTo(url));

    }, []);
    return (
        <>
            <div className="stepi">
                <br /><br /><br />
                <p style={{ fontSize: 24, textAlign: 'center' }}>Cadastro realizado com sucesso!</p>
                <div className="container-next container-center">
                    <Link to={hrefTo}><button>Ver Imóveis</button></Link>
                </div>
            </div>
        </>
    )
}
