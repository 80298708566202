import React, { useEffect, useState } from "react";
import "../../global.css";
import api from "../../api";
import InputMask from "react-input-mask";
import { NOTIFICATION_TYPE, openNotification } from "../../utils/notification";

export default function StepEight({
  setStep,
  email,
  setEmail,
  nome,
  setNome,
  handleRegister: handleRegisterAsync,
  telefone,
  setTelefone,
}) {
  const [valorMer, setValorMer] = useState("");
  const [valorIM, setValorIM] = useState("");

  const handleNext = async () => {
    if (
      email &&
      email.length >= 6 &&
      nome &&
      nome.length >= 3 &&
      telefone.length == 15 &&
      !telefone.includes("_") &&
      (email.match(/@/g) || []).length === 1
    ) {
      handleRegisterAsync()
        .then(() => setStep(6))
        .catch((err) => console.error(err));
    } else {
      openNotification("Verifique os campos informados!", NOTIFICATION_TYPE.warning);

      let errors = document.querySelectorAll(".input-box");
      if (!nome || nome.length < 3) {
        errors[0].style.border = "2px solid red";
        styleBorderError(errors[0]);
      }
      if (telefone.includes("_") || telefone == "") {
        errors[1].style.border = "2px solid red";
        styleBorderError(errors[1]);
      }
      if (!email || email.length < 6 || (email.match(/@/g) || []).length != 1) {
        errors[2].style.border = "2px solid red";
        styleBorderError(errors[2]);
      }
    }
  };

  function styleBorderError(object) {
    setTimeout(() => {
      object.style.border = "none";
    }, 3000);
  }

  function money(value) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(+value.replace(/\D+/g, "") / 100);
  }

  return (
    <>
      <div className="step">
        <div className="container-input-box">
          <div className="input-box">
            <small>Nome</small>
            <InputMask
              type="text"
              onChange={(text) => {
                setNome(text.target.value);
              }}
              value={nome}
              placeholder="Ex.: João Silva"
            />
          </div>

          <div className="input-box">
            <small>Celular</small>
            <InputMask
              mask="(99) 99999-9999"
              value={telefone}
              onChange={(text) => setTelefone(text.target.value)}
              placeholder="Ex.: (00) 00000-0000"
              pattern="\(\d{2}\)\d{4}-\d{4}"
            />
          </div>
        </div>
        <div className="input-box">
          <small>Email</small>
          <input
            onKeyDown={(e) => (e.key == " " ? e.preventDefault() : "")}
            type="email"
            value={email}
            onChange={(text) => {
              setEmail(text.target.value);
            }}
            placeholder="Ex.: joao.silva@email.com.br"
          />
        </div>

        <div className="container-next min">
          <button className="btBack" onClick={(click) => setStep(3)}>
            Voltar
          </button>
          <button onClick={handleNext}>Avançar</button>
        </div>
      </div>
    </>
  );
}
