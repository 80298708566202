import { createContext } from "react";

const FormContext = createContext({
  userData: {},
  currentStep: {},
  setData: (data) => { },
  register: (name, validation) => { },
  errors: {},
  control: {},
  formState: {},
  setStatus: (value = '') => { },
  currentStatus: '',
  setDefaultValues: (data) => { },
  openNotification: (content = '', notificationType = '') => {},
});

export default FormContext;