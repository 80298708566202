import styled from 'styled-components'

export const Bar = styled.nav`
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    padding: 30px;
    height: 84px;
    z-index: 1000;
    align-items: center;
    font-size: 18px;
    background: ${props => props.navbar ? 'rgba(250, 255, 255, 0.88)' : '#0098AE'} ;
    position: sticky;
    top: 0;
    width: 100%;
`

export const Main = styled.div`
   display: flex;
`

export const MainNav = styled.ul`
    display: flex;
    margin-right: 30px;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: ${props => props.navbar ? '40px' : '35px'};
    margin-left: 50px;
    padding-top: 0;

  @media (min-width: 1101px) and (max-width: 1200px) {
    margin-left: 5px;
    margin-right: 15px;
  }

  @media (max-width: 1100px) {
    padding-top: 40px;
    list-style-type: none;
    display: ${props => props.buttonResponsive ? 'flex' : 'none'};
    flex-direction: column;
  }
`
export const NavMenuItems = styled.div`
   background-color: ${props => props.buttonResponsive && window.innerWidth <= 1100 ?
    props.navbar ? 'rgba(250, 255, 255, 0.88)' : '#0098AE'
    : 'transparent'};
    position: ${props => props.buttonResponsive && window.innerWidth <= 1100 && 'fixed'};;
    left: ${props => props.buttonResponsive && window.innerWidth <= 1100 && '0'};
    top: ${props => props.buttonResponsive && window.innerWidth <= 1100 && '84px'};
    width: ${props => props.buttonResponsive && window.innerWidth <= 1100 && '100vw'};
    height: ${props => props.buttonResponsive && window.innerWidth <= 1100 && 'calc(100vh - 73.6px)'};
    display:  ${props => props.buttonResponsive && window.innerWidth <= 1100 && 'flex'};
    flex-direction: ${props => props.buttonResponsive && window.innerWidth <= 1100 && 'column'};
    align-items: ${props => props.buttonResponsive && window.innerWidth <= 1100 && 'center'};
    justify-content: ${props => props.buttonResponsive && window.innerWidth <= 1100 && 'space-around'};
    transition: ${props => props.buttonResponsive && window.innerWidth <= 1100 && '1s'};
    gap: ${props => props.buttonResponsive && window.innerWidth <= 1100 && '0'};
`

export const NavLi = styled.li`
  text-align: center;
  text-decoration: none;
  list-style-type: none;
  margin-left: 2vw;

  @media (min-width: 1101px) and (max-width: 1430px) {
    margin-left: 0;
  }

  @media (max-width: 1100px) {    
    padding-bottom: 20px;
  }
` 

export const NavLink = styled.a`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding-bottom: 20px;
  color: ${props => props.navbar ? '#3B414A' : '#fff' };
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  margin: 0px 10px;
  letter-spacing: 0em;
  text-align: center;

  @media (min-width: 1101px) and (max-width: 1430px) {
   font-size: ${props => props.navbar && '15px' };
  }


  @media (max-width: 1100px) {    
    font-size: 22px;
    margin: 0;
  }
`
export const Logo = styled.img`
  width: 202px;
  padding-bottom: 20px;
  cursor: pointer;

  @media (min-width: 1101px) and (max-width: 1200px) {
    width: 182px;
  }
`;

export const NavBarToggle = styled.span`
  padding-top: 35px;
  cursor: pointer; 
  color: rgba(255,255,255,0.8);
  font-size: 24px;
  padding-top: 25px;
`

export const NavMenu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Btns = styled.div `
    display: flex;

    @media (min-width: 1101px) and (max-width: 1430px) {
      width: ${props => props.navbar && '30%' };
    }

    @media (max-width: 1100px) {
      display: ${props => props.buttonResponsive ? 'flex' : 'none'};
      flex-direction: column-reverse;
      margin-left: 30px;
    }
    
`

export const BtnPlayVideo = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right: 20px;
    cursor: pointer;

    a{
        text-decoration:none;
        color: ${props => props.navbar ?  '#0088AC' : '#fff'};
        border: 1px solid #0088AC;
        box-sizing: border-box;
        border-radius: 8px;
        padding:12px;
        padding-left:25px;
        padding-right:25px;

        span {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        }

        .icon-play {
            position: relative;
            top: 2px;
            left: 6px;
        }

        &:hover, .linkButton {
          border: 1px solid #0088AC;
          color: #006690;
        }

    }

    @media (min-width: 1101px) and (max-width: 1430px) {
      padding-right: 8px;
      margin-right: 0;

      a {
        width: 100%;
        padding: 10px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    @media (max-width: 1100px) {
      padding-bottom: 20px;
      padding-left: 10px;

      a {
      border: none;

      &:hover, .linkButton {
        border: none;
        color: #006690;
      }

      .icon-play {
        top: 0;
        }
      }
    }
`

export const Divider = styled.hr`
    width: 100%;
    position: absolute;
    bottom: 30%;
`

export const StyledBurger = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background:  ${({ navbar }) => navbar ? '#3B414A' : '#fff' };
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }

  @media (max-width: 1100px) {
     display: flex;
  }
`

export const BtnSeeProperties = styled.div`
    height: 50px;
    display:flex;
    justify-content:center;
    align-items:center;

    a{
        text-decoration:none;
        color: ${props => props.navbar ? '#fff' : '#0088AC'};
        background-color: ${props => props.navbar ? '#0088AC' : '#fff'};
        box-sizing: border-box;
        border-radius: 8px;
        padding:12px;
        padding-left:35px;
        padding-right:35px;

        span {
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        }

        .icon-arrow {
            position: relative;
            top: 2px;
            left: 8px;
        }

        &:hover, .linkButton {
            background-color: #006690;
            color: #fff;
        }
    }

    @media (min-width: 1101px) and (max-width: 1430px) {

      a {
        width: ${props => props.navbar && '100%' };
        padding: ${props => props.navbar && '10px' };
        padding-left: ${props => props.navbar && '10px' };
        padding-right: ${props => props.navbar && '10px' };
      }
    }

    /* @media (max-width: 1420px) and (min-width: 1100px) {
      padding-top: 20px;
    }  */

    @media (max-width: 1100px) {
      padding-bottom: 20px;
    }
`