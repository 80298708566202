import React from "react";
import * as S from "./styles";
import "../../../global.css";
import ImageConfigZap from '../../../assets/ImageConfigZap.svg'
import ImageTabZap from '../../../assets/ImageTabZap.svg'
import ArrowLeft from '../../../assets/ArrowLeft.svg';
import { InfoOutlined } from '@ant-design/icons';

export default function ConfigZap({ titleNameConfig, showImageConfigZapAccount, setNextViewModalBinding}) {

  function backRedirect() {
    if(showImageConfigZapAccount) {
      setNextViewModalBinding('loginZap');
      return;  
    }

    setNextViewModalBinding('configZap');  
  } 
  
  return (
    <>
      <S.Container>
        <S.Content>
            <S.ContentButtonBack>
                <div className="content-back" onClick={() => backRedirect()}>
                    <img src={ArrowLeft} alt="Arrow Left" />
                    <span className="title-back">Voltar</span>
                </div>
            </S.ContentButtonBack>
          <S.ContentTitle>
           <span className="title">Configurações</span>

           <span className="subTitle">
               {showImageConfigZapAccount ? 'Uma vez logado' : 'Em seguida'}, vá para: <br />
               <strong>{titleNameConfig}</strong>
           </span>
          </S.ContentTitle>
          <S.ContentInfo>
            <div className="info-circle">
                <InfoOutlined className="info" />
            </div>
            <span className="title-info">Certifique-se de possuir uma assinatura e uma conta ativa.</span>
          </S.ContentInfo>
        </S.Content>
        
        <S.ContentImgIllustrated>
          <img src={showImageConfigZapAccount ? ImageConfigZap : ImageTabZap} alt="Image Config Zap" />
        </S.ContentImgIllustrated>
      </S.Container>
    </>
  );
}
