import styled from 'styled-components';

export const Container = styled.div`
    width:100%;
    height:290px;
    background: #DADADA;
    border-radius: 4px;

    .header{
        width:100%;
        height:15%;
        display:flex;
        padding:10px;
        border-bottom:1px solid #C4C4C4;
        overflow-x:auto;

        .item{
            background: #8B9495;
            opacity: 0.8;
            border-radius: 2px;
            display:flex;
            justify-content:center;
            align-items:center;
            
            p{
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #FFFFFF;
                padding-left:10px;
                padding-right:10px;
            }

            button{
                color:#FFFFFF;
                background:transparent;
                border:none;
                padding-right:10px;
                font-size:14px;
            }
        }

        .item + .item{
            margin-left:10px;
        }
    }

    .input-wrapper{
        width:100%;
        height:15%;
        display:flex;
        position:relative;
        padding-right:10px;
        border-bottom:1px solid #C4C4C4;

        input{
            width:100%;
            background:transparent;
            border:none;font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.05em;
            color: #8B9495;
            padding:10px;
        }

        icon{
            position:absolute;
            top:10px;
        }
    }

    .tags{
        display:flex;
        flex-direction:column;
        height:70%;
        overflow-y:auto;

        button{
            background:transparent;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.05em;
            color: #8B9495;
            border:none;
            text-align:left;
            padding:10px;
        }
    }
`;
