import api from "../api";
import queryString from "../utils/queryString";

export const getCitiesAsync = (filter, isAuth = false, cancelToken = undefined) => {
  const request = queryString(filter);
  const url = isAuth ? '/auth' : '';
  return api.get(
    `/city/properties/location${url}${request}`,
    {
      headers: {
        cancelToken
      },
    }
  ).catch(({ response: error }) => {
    const { status, data } = error;
    console.error(data);
    if (status === 410) {
      localStorage.clear();
      window.location.href = "/login";
    }
  });

};

export const getNeighborhoodAndCityAsync = ({stateName = '', cityName = '', neighborhoodName = ''}) => {
  
  if (!stateName || !cityName || !neighborhoodName) {
    throw new Error('Endereço incompleto, verifique a localização');
  }
  
  const request = queryString({ stateName, cityName, neighborhoodName });
  return api.get(`/city/keys/neighborhood-and-city/${request}`
  ).catch(({ response: error }) => {
    const { status, data } = error;
    console.error(data);
    if (status === 410) {
      localStorage.clear();
      window.location.href = "/login";
    }
  });

};

export const getNeighborhoodsOfCityAsync = ({stateName = '', cityName = ''}) => {
  
  if (!stateName || !cityName) {
    throw new Error('Endereço incompleto, verifique a localização');
  }  
  const request = queryString({ stateName, cityName });
  return api.get(`/city/list/neighborhoods${request}`
  );

};
