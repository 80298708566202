import styled from "styled-components";

export const Container = styled.div`
  margin-left: 20vw;
  width: 80vw;
  height: 100%;
  padding: 30px;
  padding-right: 20vw;

  @media only screen and (max-width: 600px) {
    margin-left: 0;
    //border: 1px solid black;
    width: 100%;
    padding-top: 30px;
    padding-right: 0px;
    padding: 30px;
  }

  .bread-crumb {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 10vh;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      margin-top: 0px;
    }

    .category {
      color: #0a677c;
      margin-right: 3px;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.05em;

      @media only screen and (max-width: 600px) {
        inline-size: max-content;
      }
    }

    .where {
      color: #eb5c1c;
      text-decoration: none;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.05em;

      @media only screen and (max-width: 600px) {
        inline-size: max-content;
      }
    }
  }

  .box-control {
    width: 90%;
    border-radius: 4px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media only screen and (max-width: 600px) {
      padding-left: 0px;
    }

    .roll-page {
      @media only screen and (max-width: 600px) {
        margin-top: 20px;
        width: 100%;
      }
    }

    .roll-page button {
      inline-size: max-content;
      height: 40px;
      padding-left: 5px;
      padding-right: 5px;
      background: transparent;
      color: #eb5c1c;
      border-radius: 4px;
      border: 2px solid #eb5c1c;

      @media only screen and (max-width: 600px) {
        inline-size: inherit;
      }
    }

    .box-divide {
      background-color: #eee;
      border-radius: 5px;
      height: 60px;
      width: 100%;
      margin-top: 20px;
      padding-left: 10px;
      padding-right: 15px;
      padding-top: 3px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;

      small {
        font-weight: 500;
      }

      input {
        background-color: transparent;
        border: transparent;
        border-bottom: 2px solid #ccc;
        width: 100%;
      }
      textarea {
        background-color: transparent;
        border: transparent;
        border-bottom: 2px solid #ccc;
        width: 100%;
      }

      select {
        background: transparent;
        border: none;
        color: #888;
        border-bottom: 2px solid #ccc;
        width: 100%;
      }
    }

    .container-input-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      .box-divide {
        width: 48%;
      }

      .max-camp {
        width: 48%;
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }

    .flex-column {
      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    .infos {
      width: 100%;
      margin-top: 20px;
    }

    .analise {
      color: #eb5c1c;
      margin-top: 20px;
      font-weight: 600;
      font-size: 18px;
      width: 100%;
    }

    .max {
      height: 180px;
      textarea {
        height: 80%;
        resize: none;
      }
    }

    .divSend {
      display: flex;
      flex-direction: row;
      width: 96%;
      border-bottom: 2px solid #ccc;
      height: 60%;
      margin-left: 2%;
    }

    .btSend {
      width: 35px;
      height: 35px;
      border-radius: 20px;
      background: rgb(235, 92, 28);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 1000;
      cursor: pointer;
      font-size: 21px;
    }

    .container-next {
      height: 50px;
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: space-evenly;
      justify-content: flex-end;

      button {
        border: transparent;
        background: linear-gradient(90deg, #ea5c1e 0%, #c13818 100%);
        width: 28%;
        color: white;
        height: 100%;
        border-radius: 4px;
      }

      button:hover {
        color: #ea5c1e;
        background: white;
        text-decoration: none;
        border: 2px solid #ea5c1e;
        filter: none;
        background-color: white;
        background-image: none;
      }
    }
  }

  @media only screen and (max-width: 1500px) {
    .box-control {
      width: 110%;
    }
  }

  @media only screen and (max-width: 600px) {
    .box-control {
      width: 100%;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImageItem = styled.label`
  min-width: 178px;
  min-height: 128px;
  border-radius: 4px;
  background: url(${(props) =>
    props.image
      ? props.image
      : require("../../assets/adminOfferProperty/imgplaceholder.svg")});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;

  input {
    display: none;
  }

  .close {
    display: ${(props) => (props.children[1].props.show ? "flex" : "none ")};
    position: absolute;
    width: 16px;
    height: 16px;
    background: #ffffff;
    right: 10px;
    top: 10px;
    border: none;
    font-size: 16px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
  }
`;
export const BtnWrapper = styled.div`
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlusBtn = styled.button`
  margin-left: 20px;
  background-color: transparent;
  border: none;
`;

export const Carousel = styled.div`
  width: 100%;
  height: auto;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
`;

export const DocItem = styled.label`
  min-width: 178px;
  min-height: 128px;
  border-radius: 4px;
  background: url(${(props) =>
    props.image
      ? props.image
      : require("../../assets/adminOfferProperty/docplaceholder.svg")});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;

  input {
    display: none;
  }

  .close {
    display: ${(props) => (props.children[1].props.show ? "flex" : "none ")};
    position: absolute;
    width: 16px;
    height: 16px;
    background: #ffffff;
    right: 10px;
    top: 10px;
    border: none;
    font-size: 16px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
  }
`;

export const DownloadLink = styled.a`
  font-weight: normal;
  font-size: 14px;
  margin: 10px;
  color: rgb(235, 92, 28);
  text-decoration: none;
`;
