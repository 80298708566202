import api from "../api";

export const saveRealEstatedDevelopmentAsync = (data) => {

  return api.post(`/real-estate-development/`, data);

};

export const updateRealEstatedDevelopmentAsync = (data) => {

  return api.put(`/real-estate-development/${data._id}`, data);

};
export const getRealEstateDevelopmentAsync = () => {
  const id = process.env.REACT_APP_FILES_RED || '*';
  return api.get(`/real-estate-development/${id}`);

};
