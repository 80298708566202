import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/Footer'
import { Body, ImageWrapper, TextWrapper } from './styles'
export default function Realtor() {
    return (
        <div>
            <Header />
            <Body>
                <ImageWrapper>
                    <TextWrapper>
                        <h1>Política de Uso do Corretor de Imóveis</h1>
                    </TextWrapper>
                </ImageWrapper>
                <div className="text">
                    <p style={{textAlign: 'start'}}>
                        Nós, da Imóvel Vazio, estamos comprometidos em resguardar a sua privacidade. O intuito deste documento é esclarecer quais informações são coletadas dos corretores de imóveis usuários deste  site, e de que forma esses dados são manipulados e utilizados.


                        Alertamos que se você não concorda com o conteúdo desta política, não é recomendável baixar nossos materiais nem utilizar quaisquer de nossos serviços.

                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        - Sobre o acesso às suas informações pessoais
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        Poderão ver suas informações pessoais apenas os funcionários da Imovel Vazio. Eventualmente, caso a inserção de suas informações se dê em ações criadas em parcerias, os parceiros explicitamente identificados também terão acesso à informação. Nenhuma informação pessoal poderá ser divulgada publicamente.


                        A Imóvel Vazio também se compromete a não vender, alugar ou repassar suas informações para terceiros. A única exceção está em casos em que essas informações forem exigidas judicialmente.


                        Além disso, embora trabalhemos com boas práticas de proteção e segurança, nenhum serviço web possui 100% de garantia contra invasões e não podemos nos responsabilizar caso isso ocorra.

                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        - Sobre o cancelamento da assinatura e alteração/exclusão de informações pessoais
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        Você pode optar por não receber mais qualquer tipo de email da Imovel Vazio. Em todos os emails que enviamos há sempre um link para cancelar a assinatura, disponível nas últimas linhas. Ao clicar nesse link você será automaticamente descadastrado da lista.

                        É importante mencionar que ao preencher qualquer formulário novamente ficará caracterizada a reinserção do seu email à lista. Portanto, a requisição de cancelamento deverá ser feita novamente, caso seja de seu interesse.

                        Para alterar suas informações pessoais ou mesmo excluí-las do nosso banco de dados, basta enviar um email para contato@imovelvazio.com.br.

                    </p>
                    <h3 style={{textAlign: 'start'}}>
                        - Sobre a conduta aceitável
                    </h3>
                    <p style={{textAlign: 'start'}}>
                        Você não pode transmitir qualquer material no site ou através dele que:
                        (a) Restrinja o usufruto de qualquer usuário do site;
                        (b)Seja ilegal, ameaçador, abusivo, calunioso, difamatório, pornográfico, profano ou de qualquer forma ofensivo;
                        (c) Constitua ou incentive comportamento criminal, dê origem a responsabilidade civil ou que de outra forma viole qualquer lei;
                        (d) Viole ou infrinja os direitos de terceiros incluindo, sem limite de, patente, direitos autorais, marca comercial, privacidade ou qualquer outro direito de propriedade;
                        (e) Contenha um vírus ou qualquer outro componente nocivo;
                        (f) Contenha indicações falsas ou enganosas quanto à origem ou declarações do fato.

                        A Imóvel Vazio se reserva o direito de divulgar informações, sempre que necessárias, para atender qualquer lei, regulamento, solicitação governamental, ordem jurídica, intimação ou outros processos legais, ou para editar ou remover do Site toda informação, total ou em parte, a critério exclusivo da Imovel Vazio, que seja inaceitável e inoportuna ou viole estes Termos e Condições.


                        A Imóvel Vazio se reserva ao direito de suspender imediatamente seu acesso ao Site em caso de qualquer violação desta cláusula.

                    </p>
                </div>
            </Body>
            <Footer />
        </div>
    )
}
