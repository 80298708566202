import React, { useEffect, useState } from "react";
import Header from "../../components/adminHeader";
import Aside from "../../components/adminSideBar";
import { Container } from "./styles";
import Autocomplete from "../../components/autosuggest";
import api from "../../api";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Popconfirm, notification } from "antd";


export default function LocksPage() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);

  const [lockName, setLockName] = useState("");
  const [lockToken, setLockToken] = useState("");
  const [status, setStatus] = useState(true);
  const [property, setProperty] = useState(0);

  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [tempId, setTempId] = useState(null);
  const [editFlex, setEditFlex] = useState(false);

  const CONFIRM_CONTEXT = {
    default: '',
    home: 'Home',
    catalog: 'Catalog',
    delete: 'Excluir'
  };

  const [confirmLoading, setConfirmLoading] = useState(false);
  const defaultOpenConfirm = {state: false, id: 0, context: CONFIRM_CONTEXT.default};
  const [openConfirm, setOpenConfirm] = useState(defaultOpenConfirm);
  const isOpen = (id, context) => {
    return openConfirm.state && openConfirm.context === context && openConfirm.id === id;
  }
  const handlerOpenConfirm = (state= defaultOpenConfirm.state, id=defaultOpenConfirm.id, context =defaultOpenConfirm.context) => {
    setOpenConfirm({
      state,
      id,
      context,
    })
  }

  const onConfirm = async (callback)=> {
    try {
      setConfirmLoading(true);
      await callback();
    } finally{
      setConfirmLoading(false);
      handlerOpenConfirm(false);
    }
  };

  useEffect(() => {
    // if (localStorage.getItem('type_user') != 'admin') {
    //     window.location.href = '/admin/login'
    // }
    handleLoad();
  }, []);

  const cleanStates = () => {
    setLockName("");
    setLockToken("");
    setTempId(null);
  };

  const handleRegister = async () => {
    if (lockName && lockToken) {
      try {
        let obj = {
          name: lockName,
          password: lockToken,
        };
        await api
          .post("/lock", obj)
          .then((res) => {
            notification.success({
              message: `${res.data.response}`
              });
          })
          .catch((err) => {
            notification.error({
              message: `${err.data.error}`
              });
          });
        handleLoad();
      } catch (error) {
        notification.error({
          message: 'Error: ' + error,
          });
      }
    } else {
      let inputs = document.querySelectorAll("[required]");
      Array.from(inputs).map((input) => {
        if (!input.querySelector("input").value) {
          styleBorderError(input);
        }
      });
    }
  };

  function styleBorderError(object) {
    object.style.border = "2px solid red";
    setTimeout(() => {
      object.style.border = "none";
    }, 1000);
  }

  const handleLoad = async () => {
    cleanStates();
    const response = await api.get(`/lock`);

    setLastPage(response.data.pages);
    setData(response.data);
    setTotal(response.data.length);
  };

  const handleEdit = (row) => {
    setLockName(row.name);
    setLockToken(row.password);
    setTempId(row._id);
    window.scrollTo(0, 0);
  };

  const handleUpdate = async () => {
    try {
      await api.put(`/lock/${tempId}`, {
        name: lockName,
        password: lockToken,
      });
      notification.success({
        message: 'Alterado com Sucesso!'
        });
      cleanStates();
      handleLoad();
      setEditFlex(false);
    } catch (error) {
      notification.error({
        message: 'Não foi possivel alterar!',
        description: `Error: ${error}`
      });
    }
  };

  const handleDelete = async (id) => {
      const { data } = await api.delete(`/lock/${id}`);

      handleLoad();
  };

  async function handlePage(pages) {
    const response = await api.get(`/lock?limit=20&page=${pages.selected + 1}`);
    setTotal(response.data.total);

    if (response.data.propertys) {
      setPage(pages.selected);
    }
  }

  const innerRow = (row, index) => {
    return (
      <>
        <div className="body-actions">
          <div>
            <a
              onClick={() => {
                setEditFlex(true);
                handleEdit(row);
              }}
            >
              Editar
            </a>
          </div>
          <Popconfirm
            placement="top"
            className="size-modal"
            title={`Imóvel ${row._id}`}
            description='Deseja excluir essa fechadura?'
            onConfirm={()=> onConfirm(async() => await handleDelete(row._id))}
            open={isOpen(row._id, CONFIRM_CONTEXT.delete)}
            okButtonProps={{
              loading: confirmLoading,
            }}
            onCancel={()=>handlerOpenConfirm()}
            okText="Sim"
            cancelText="Não"
          >
          <div>
            <a onClick={()=>handlerOpenConfirm(true, row._id, CONFIRM_CONTEXT.delete)}>Excluir</a>
          </div>
          </Popconfirm>
        </div>
      </>
    );
  };

  return (
    <>
      <Header />
      <Aside />
      <Container>
        <div className="bread-crumb">
          <h1 className="category">{"IMÓVEIS À VENDA >"}</h1>
          <Link to={window.location.pathname} className="where">
            Fechaduras
          </Link>
        </div>
        <div className="filter">
          <div className="input-group" required>
            <label htmlFor="">Nome da Fechadura</label>
            <input
              type="text"
              placeholder="Digite aqui o nome..."
              selected
              value={lockName}
              onChange={(input) => setLockName(input.target.value)}
            />
          </div>
          <div className="input-group" required>
            <label htmlFor="">Token</label>
            <input
              type="text"
              placeholder="Digite aqui o Token..."
              value={lockToken}
              onChange={(input) => setLockToken(input.target.value)}
            />
          </div>
          <div hidden={!editFlex} className="button-group">
            <button onClick={() => handleUpdate()} className="full">
              Aplicar Edição
            </button>
          </div>
          <div hidden={editFlex} className="button-group">
            <button onClick={() => handleRegister()} className="full">
              Adicionar Fechadura
            </button>
          </div>
        </div>
        <div className="row-ordenate">
          <p>Existem atualmente {total} fechaduras registradas!</p>
        </div>

        <div className="header">
          <div className="cod" style={{ width: "80%" }}>
            <h2>FECHADURA</h2>
          </div>
          <div className="actions" style={{ width: "20%" }}>
            <h2>AÇÕES</h2>
          </div>
        </div>

        {(data || []).map((row, index) => {
          return (
            <>
              <div key={row._id.toString()} className="body">
                <div className="body-cod" style={{ width: "20%" }}>
                  {row.name}
                </div>
                <div className="body-imovel" style={{ width: "60%" }}>
                  {" "}
                  {/* criar classe para estilizar texto */}
                  <h2>{row.password}</h2>
                </div>
                {window.innerWidth <= 600 ? (
                  <div
                    className="body-actions"
                    style={{
                      overflow: "auto",
                      display: "-webkit-inline-box",
                      width: "20%",
                    }}
                  >
                    {innerRow(row, index)}
                  </div>
                ) : (
                  <>{innerRow(row, index)}</>
                )}
              </div>
            </>
          );
        })}

        {/* <div className="paginate">
                    <ReactPaginate
                        pageCount={lastPage}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={e => handlePage(e)}
                        forcePage={page}
                        previousLabel={"Anterior"}
                        nextLabel={"Proximo"}
                        breakLabel={<a href="">...</a>}
                        breakClassName={"break-me"}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </div> */}
      </Container>
    </>
  );
}
