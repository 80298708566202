import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalContainer = styled(Modal)`

  .ant-modal-content {
    padding: 24px 20px !important; 
  }
`;

export const Content = styled.div`
  text-align: center;
  margin: 2rem 1rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

export const Header = styled.h3`
  text-align: center;
  margin: 1.5rem;
`;