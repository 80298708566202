import styled from "styled-components";

export const ContainerBadge = styled.div`
  margin-left: 13px;
  border-radius: 50px;
  border: 1px solid #0088ac;
  background: #faffff;
  display: flex;
  height: 36px;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  color: #0088ac;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
`;

export const ContentCloseBadge = styled.div`
  position: sticky;
  right: 20px;

  .icon-close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;
