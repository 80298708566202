import styled from 'styled-components'
import { Slider, Select } from "antd"
import favoriteIcon from '../../assets/propertiesList/favorite.svg'
const placeholder = 'https://www.ufukkimya.com/media/resim-yok.png'

export const Body = styled.div`
    width:100%;
    
    padding: 0 2rem 0.5rem;

    

    @media(max-width:600px){
        margin-top:0px;
        h1{
            margin-left:0px;
            width: 100%;
            text-align: center;
        }
    }

    .title-empty-result {
        font-family: 'Roboto';
        font-size: 29px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;

        @media(max-width:600px){
            font-size: 24px;
        }

    }

    /* width */


    
`
export const SeeMore = styled.div`
    width:100%;
    padding: 1rem;
    display:flex;
    justify-content:center;
    align-items:center;

    button{
        width: 236px;
        height: 38px;
        border: 1px solid #EA5C1E;
        box-sizing: border-box;
        background:transparent;
        
        border-radius: 4px;
        outline:none;

        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #EA5C1E;
    }
`

export const Menu = styled.div`
    margin-top:12px;

    @media(max-width:600px){
        margin-left:0px;
        
        .input-group{
            select{
                text-align-last: center;
            }
        }
    }

    a{
        text-decoration:none;
        font-size:18px;
        color: #00AEC8;
        font-weight:bold;
        background: #F2F2F2;
        display:flex;
        justify-content:center;
        align-items:center;
        padding-left:40px;
        padding-right:40px;
        border-radius: 8px;
    }

    .input-group{
        display:flex;
        flex-direction: column;

        @media(max-width:600px){
            width:100%;
        }

    .content-select-order {
        display: flex;
        align-items: center;

          .title-select-order {
            color: #0A0D14;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

        select{
            border-radius: 8px;
            width: auto;
            height: 56px;
            color: #474C57;
            font-family: "Roboto";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left:10px;
            border:none;
            display: block;
            cursor: pointer;

            @media(max-width:600px){
                margin-left:0px;
                width:100%;
                margin-top:10px;
            }
        }   
    }

    .content-filter{
        display: flex;
        align-items: center;
        margin-top: 12px;
        .filters{
                margin-right: 0px;
                border-radius: 50px;
                border: 1px solid #0088AC;
                background: #FAFFFF;
                color: #0088AC;
                display: flex;
                width: 93px;
                height: 36px;
                padding: 7px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                text-align: center;
                font-family: "Roboto";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                cursor: pointer;

            }

            .filtered {
                margin-right: 0px;
                border-radius: 50px;
                background: #0088AC;
                color: #FAFFFF;
                display: flex;
                width: 93px;
                height: 36px;
                padding: 7px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                text-align: center;
                font-family: "Roboto";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                cursor: pointer;
            }
    }
        /* div{
            background: #F2F2F2;
            border-radius: 8px;
            width: 240px;
            height: 56px;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.05em;
            color: #1E1D1D;
            padding-left:10px;
            border:none;
            cursor: pointer;
            margin-right:20px;

            @media(max-width:600px){
                margin-left:0px;
                width:100%;
                margin-top:10px;
            }
        }   */

    }

`

export const StyledSlider = styled(Slider)`
  .ant-slider-track {
    background: #F28E53 !important;
    height: 3px;
  }

  .ant-slider-handle::after {
    background: #EA5C1D;
    border: none !important;
    box-shadow: none !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
    margin-top: -6px;
  }
  .ant-slider-handle:hover {
    margin-top: 0 !important;
  }
  .ant-slider-horizontal .ant-slider-handle {
    inset-block-start: 0 !important;
  }
  .ant-slider-dot {
    border: 1.5px solid #29292c;
    border-radius: 1px;
    background: #29292c;
    width: 2px;
    height: 10px;
  }
  .ant-slider-dot-active {
    border: 1.5px solid #929293;
    border-radius: 1px;
    background: #929293;
    width: 2px;
    height: 10px;
  }
  .ant-slider-with-marks {
      margin-bottom: 50px;
  }
`

export const ItemsContainer = styled.div`
    width:100%;
    height: auto;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:center;
    margin-top:30px;

    @media(max-width:1380px){
        width:120%;
        margin-left: -10%;
    }

    @media(max-width:340px){
        width:150%;
        margin-left: -25%;
    }

    a{
        text-decoration:none;
        margin-left:20px;
        margin-bottom:20px;
        max-width: 412px; //340
		width: 412px;
        background: #FFFFFF;
        border-radius:8px;
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
        color: #1E1D1D;

        /* @media(max-width:340px){
            height: 430px;
        } */

        
        @media(max-width:600px){
            width:100%;
        }
        @media(max-width:1380px){
            max-width: 380px;
        }
    }

    .container-empty-search {
        height: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
`

export const ItemHeader = styled.div`
    background: url(${props => props.image ? props.image : 'https://www.ufukkimya.com/20/resim-yok.png'});
    background-size:cover;
    background-position:center;
    width:100%;
    height:256px;
    position:relative;        
    border-radius: 5px;
`

export const TagPartnerProperties = styled.div`

    position:absolute;
    top: 20px;
    left:20px;
    color: #FFFFFF;
    height:22px;
    display:flex;
    padding:5px;
    justify-content:center;
    align-items:center;
    border-radius: 2px;
    background-color: #ea5c1e;
    opacity:0.8;

    h6{
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }
        
`

export const Tag = styled.div`

    position:absolute;
    bottom: 20px;
    left:20px;
    color: #FFFFFF;
    height:22px;
    display:flex;
    padding:5px;
    justify-content:center;
    align-items:center;
    border-radius: 2px;
    background-color:${props => props.background ? props.background : '#1E1D1D  '};
    opacity:0.8;

    h6{
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }
        
`

export const Favorite = styled.button`
    outline:0;
    border:none;
    position:absolute;
    right:20px;
    top:20px;
    width:18px;
    height:15px;
    background:url(${favoriteIcon});
    background-size:cover;  
`

export const OurProperty = styled.div`
    position: absolute;
    left: 0%;
    right: 0%;
    width:100%;
    height:28px;
    bottom: 0%;
    background: #EA5C1E;
    opacity: 0.9;
    display:flex;
    justify-content:flex-start;
    padding-left:20px;
    align-items:center;
    
    h6{
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
    }
`
export const Description = styled.div`
    min-height: 145px;
    width:100%;
    padding-left:20px;
    padding-right:20px;
    padding-top:20px;
    display:flex;
    flex-direction:column;
    padding-bottom:15px;

    .row{
        width:100%;
        display:flex;
        justify-content:space-between; 
        align-items:flex-start;

        .locality-title{
					display: grid;

            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.05em;
            color: #1E1D1D;

						.locality-subtitle{
								font-weight: 500;
								font-size: 15px;
						}
        }
        .price{ 
            text-wrap: nowrap;
            margin-left: 5px;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            text-align: right;
            color: #00AEC8;
        }

        .street{
            font-weight: 300;
            font-size: 14px;
			width: 76%;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #1E1D1D;
            display:flex;
			height: 3rem;
			align-items: baseline;
        }

        .commission{
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            height: 100%;
            min-inline-size: fit-content;
            color: #1E1D1D;

            span{
                font-weight: bold;
                font-size: 18px;
                line-height: 21px;
                text-align: right;
                color: #EA5C1E;
            }

        }

        .icon-group{
            display:flex;
            align-items:center;
            justify-content:center;
            width:25%;
            //margin-right:15px;

            @media only screen and (max-width: 600px) {
                flex-direction: column;
            }

            img{              
                margin-right:5px;
            }
            
            .ruler{
                width:7px;
                height:15px;
            }

            .parking{
                width:15px;
                height:15px;
            }

            .bed{
                width:15px;
                height:15px;
            }

        }
    }

    .content-property-information{
        margin-top:auto;

        
        @media only screen and (max-width: 600px) {
            width: 120%;
            margin-left: -10%;
            margin-top: 15px;
        }
    }

    .content-property-sell{
        margin-top:auto;

        
        @media only screen and (max-width: 600px) {
            width: 100%;
            margin-top: 15px;
        }
    }
`

export const ContentFilter = styled.div`
    height: 100%;
    width: 100%;
    padding-top: 20px;
`

export const ContentFilterSalePrice = styled.div`
   width: 100%;
   flex-direction: column;
   display: flex;
   border-radius: 5px;
   padding: 20px;
   border-bottom: 1px solid #ddd;

   .title-price {
    font-family: "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #3B414A;
    padding-bottom: 16px;
   }

   .content-slider {
    padding-top: 22px;
    padding-bottom: 22px;
   }

   .content-inputs-price {
    display: flex;
    gap: 10px;
    padding-top: 16px;;
    padding-bottom: 16px;

    label {
    margin-left: 0 !important;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color:  #474C57;
    }

    input {
     border-radius: 6px;
     border: 1px solid #B3B4BA;
     background: #FFF;
     padding: 13px 6px;
    }
   }
`

export const ContentSelectedIPTU = styled.div`
     display: flex;
     align-items: center;
     padding-top: 16px;
     padding-bottom: 16px;

     #checkValueIPTU {
     width: 18px;
     height: 18px;
     accent-color: #EA5C1D;
     margin-right: 6px;
     }

     .title-check-iptu {
     color: #7A7B7B;
     font-family: "Roboto";
     font-size: 14px;
     font-style: normal;
     font-weight: 400;
     line-height: normal;
     }
`

export const ContentSelectNeighborhood = styled.div`
   width: 100%;
   flex-direction: column;
   display: flex;
   border-radius: 5px;
   padding: 20px;
   border-bottom: 1px solid #ddd;

   .title-neighborhood {
    font-family: "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #3B414A;
    padding-bottom: 16px;
   }

   .content-select-neighborhood {
     width: 100%;
     margin-top: 10px;
     flex-direction: column;
     justify-content: space-between;
     display: flex;
     border-radius: 5px;
     margin-bottom: 10px;
   }
`

export const ContentSelectModel = styled.div`
   width: 100%;
   flex-direction: column;
   display: flex;
   border-radius: 5px;
   padding: 20px;
   border-bottom: 1px solid #ddd;

   .title-model {
    font-family: "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #3B414A;
    padding-bottom: 16px;
   }

   .content-select-model {
     width: 100%;
     margin-top: 10px;
     flex-direction: column;
     justify-content: space-between;
     display: flex;
     border-radius: 5px;
     margin-bottom: 10px;
   }
`

export const ContentSelectConfig = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-radius: 5px;
   padding: 20px;

   .title {
    width: 60%;
    font-family: "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #3B414A;
    padding-bottom: 16px;
   }

   .content-select {
     width: 40%;
     flex-direction: column;
     justify-content: flex-end;
     display: flex;
     border-radius: 5px;


     .select {
        margin-top: 10px;
     }
   }
`

export const Divider = styled.hr`
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: #E1E6EF;
`