import React, { useEffect, useState } from "react";
import Header from "../../components/adminHeader";
import Aside from "../../components/adminSideBar";
import { Container } from "./styles";
import api from "../../api";
import { Link } from "react-router-dom";
import { notification } from "antd";

import { ModalHistorico, ModalVendido, ModalExcluir } from "./modal/models";
import { getPropertiesProposalsAsync } from "../../services/proposalImovel.service";
import { Popconfirm } from "antd";

export default function PropostaAdminEditar() {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("false");
  const [valorComissao, setValorComissao] = useState("");
  const [reload, setReload] = useState(false);
  const [valorMenosComissao, setValorMenosComissao] = useState("");
  const [modal, setModal] = useState({
    _id: 0,
    excluir: false,
    historico: false,
    vendido: false,
    excluirFunction: () => { },
    vendidoFunction: () => { },
    historicoFunction: () => { },
  });
  const [valorVista, setValorVista] = useState([]);
  const [valorFinanciado, setValorFinanciado] = useState([]);
  const [valorProposta, setValorProposta] = useState([]);
  const [ID, setID] = useState(0);

  const [indexValores, setIndex] = useState(0);

  const CONFIRM_CONTEXT = {
    default: '',
    home: 'Home',
    catalog: 'Catalog',
    delete: 'Excluir',
    sold: 'Vendido',
    active: 'Ativo',
    save: 'Salvar',
    action: 'Ação'
  };

  const [confirmLoading, setConfirmLoading] = useState(false);
  const defaultOpenConfirm = {state: false, id: 0, context: CONFIRM_CONTEXT.default};
  const [openConfirm, setOpenConfirm] = useState(defaultOpenConfirm);
  const isOpen = (id, context) => {
    return openConfirm.state && openConfirm.context === context && openConfirm.id === id;
  }
  const handlerOpenConfirm = (state= defaultOpenConfirm.state, id=defaultOpenConfirm.id, context =defaultOpenConfirm.context) => {
    setOpenConfirm({
      state,
      id,
      context,
    })
  }

  const onConfirm = async (callback)=> {
    try {
      setConfirmLoading(true);
      await callback();
    } finally{
      setConfirmLoading(false);
      handlerOpenConfirm(false);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("type_user")) {
      return (window.location.href = "/login");
    }

    if (localStorage.getItem("type_user") !== "admin") {
      return (window.location.href = "/admin/propostas-corretor");
    }

    handleLoad();
  }, [reload]);

  function cleanStates() {
    setValorFinanciado("");
    setValorProposta("");
    setValorVista("");
    setValorComissao("");
    setStatus("");
    setValorMenosComissao("");
  }

  async function handleLoad() {
    cleanStates();
    let id = new URL(window.location).searchParams.get("id");
    setID(id);

    const response = await getPropertiesProposalsAsync({ Property: id });
    let array = [];
    if (response.data.ProposalImovel.length === 0) return window.history.go(-1);

    const valorVistaArray = [];
    const valorFinanciadoArray = [];
    const valorPropostaArray = [];
    const valorComissaoArray = [];
    const valorTotalMenosComissaoArray = [];

    response.data.ProposalImovel.map((e, i) => {
      if (!String(e.valorVista).includes(".")) {
        valorVistaArray.push(money(String(e.valorVista) + "00"));
      } else {
        valorVistaArray.push(money(String(e.valorVista)));
      }

      if (!String(e.valorComissao).includes(".")) {
        valorComissaoArray.push(money(String(e.valorComissao) + "00"));
      } else {
        valorComissaoArray.push(money(String(e.valorComissao)));
      }

      if (!String(e.valorTotalMenosComissao).includes(".")) {
        valorTotalMenosComissaoArray.push(
          money(String(e.valorTotalMenosComissao) + "00")
        );
      } else {
        valorTotalMenosComissaoArray.push(
          money(String(e.valorTotalMenosComissao))
        );
      }

      if (!String(e.valorProposta).includes(".")) {
        valorPropostaArray.push(money(String(e.valorProposta) + "00"));
      } else {
        valorPropostaArray.push(money(String(e.valorProposta)));
      }

      if (!String(e.valorFinanciado).includes(".")) {
        valorFinanciadoArray.push(money(String(e.valorFinanciado) + "00"));
      } else {
        valorFinanciadoArray.push(money(String(e.valorFinanciado)));
      }

      array.push(e);
    });

    setValorVista(valorVistaArray);
    setValorProposta(valorPropostaArray);
    setValorFinanciado(valorFinanciadoArray);
    setValorComissao(valorComissaoArray);
    setValorMenosComissao(valorTotalMenosComissaoArray);

    if (array.length === 0)
      return (window.location.href = "/admin/propostas-admin");

    array.sort((a, b) => {
      if (a.status == "Novo") {
        return -1;
      }
    });

    setData(array);
  }

  function moneyToNumber(value) {
    value = String(value);
    return value
      .replace("R", "")
      .replace("$", "")
      .replaceAll(" ", "")
      .replaceAll(".", "*")
      .replace(",", ".")
      .replaceAll("*", "")
      .substr(1, value.length - 1);
  }

  function money(value) {
    value = String(value);
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(+value.replace(/\D+/g, "") / 100);
  }

  const handleEditar = async (id, index, historic) => {

    const response = await api.put(`/proposalImovel/${id}`, {
      valorFinanciado: moneyToNumber(valorFinanciado[index]),
      valorVista: moneyToNumber(valorVista[index]),
      valorProposta: moneyToNumber(valorProposta[index]),
      valorComissao: moneyToNumber(valorComissao[index]),
      valorTotalMenosComissao: moneyToNumber(valorMenosComissao[index]),
      createdAt:
        new Date().toLocaleDateString("pt-BR", { day: "2-digit" }) +
        "/" +
        new Date().toLocaleDateString("pt-BR", { month: "2-digit" }) +
        "/" +
        new Date().toLocaleDateString("pt-BR", { year: "numeric" }),
    });

    if (historic.length > 0) {
      await api.put(`/proposalImovel/Historic/${id}`, {
        isCorretor: false,
        valorFinanciado: moneyToNumber(valorFinanciado[index]),
        valorVista: moneyToNumber(valorVista[index]),
        valorProposta: moneyToNumber(valorProposta[index]),
        createdAt:
          new Date().toLocaleDateString("pt-BR", { day: "2-digit" }) +
          "/" +
          new Date().toLocaleDateString("pt-BR", { month: "2-digit" }) +
          "/" +
          new Date().toLocaleDateString("pt-BR", { year: "numeric" }),
      });
    }

    handleLoad();

    if (response.data.success){
       notification.success({
        message: 'Proposta salva!',
      });
    }
  };

  const handleAction = async(status, id) => {
    try {
      if (status.includes("Cancelada")) {

        await api.put(`/proposalImovel/${id}`, {
          status: "Proposta Ativa",
        });

        let filter = data.filter((data) => data._id !== null);
        filter.map((filt) => {
          if (filt._id == id) {
            filt.status = "Proposta Ativa";
          }
          return filt;
        });

        setData(filter);
        return;
      }

      await api.put(`/proposalImovel/${id}`, {
        status: "Cancelada (Admin IV)",
      });

      let filter = data.filter((data) => data._id !== null);
      filter.map((filt) => {
        if (filt._id == id) {
          filt.status = "Cancelada (Admin IV)";
        }
        return filt;
      });

      setData(filter);
    } catch (err) { }
  }

  const handleRemove = async (id) => {
    try {
      const response = await api.delete("/proposalImovel/" + id);

      if (response.data.deletedId)
        setData(data.filter((filter) => filter._id != id));
    } catch (err) {
      return notification.error({
        message: 'Não foi possível remover a oferta, tente novamente mais tarde...',
      });
      setReload(!reload);
    } finally {
      setReload(!reload);
    }
  };

  const handleSold = async (
    id,
    {
      valorFinanciado,
      valorInscritura,
      valorVista,
      totalProposta,
      valorComissao,
    }
  ) => {
    await api.put(`/proposalImovel/${id}`, {
      statusImovel: "Vendido",
      status: "Proposta Ativa",
      sold: {
        valorVista: moneyToNumber(valorVista),
        valorFinanciado: moneyToNumber(valorFinanciado),
        valorInscritura: moneyToNumber(valorInscritura),
        valorComissao: moneyToNumber(valorComissao),
        valorTotalProposta: moneyToNumber(totalProposta),
      },
    });

    handleLoad();
  };

  useEffect(() => {
    if (valorProposta.length > 0) {
      let arrayValores = [];
      let arrayTotalMenosComissao = [];

      valorProposta.map((valor, index) => {
        if (index == indexValores) {
          const vista = Number(moneyToNumber(valorVista[indexValores]));
          const financiado = Number(
            moneyToNumber(valorFinanciado[indexValores])
          );

          const comissao = Number(moneyToNumber(valorComissao[indexValores]));

          const soma = vista + financiado;
          const newValorTotal = soma - comissao;

          arrayValores.push(money(String(Math.floor(soma * 100))));
          arrayTotalMenosComissao.push(
            money(String(Math.floor(newValorTotal * 100)))
          );
        } else {
          arrayValores.push(valor);
          arrayTotalMenosComissao.push(valor);
        }
      });
      setValorProposta(arrayValores);
      setValorMenosComissao(arrayTotalMenosComissao);
    }
  }, [valorFinanciado, valorVista]);

  function innerRow(row, i) {
    return (
      <>
        <div
          className="body-imovel"
          style={{
            width: window.innerWidth <= 600 ? "123%" : "73%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "50%",
              paddingLeft: 20,
              display: "flex",
              flexDirection: window.innerWidth <= 600 ? "column" : "row",
            }}
          >
            <div style={{ flexDirection: "column", width: "230px" }}>
              <p>Data da Proposta: {row.createdAt}</p>
              <br></br>
              <p>
                Status: <b style={{ color: "#EA5C1E" }}>{row.status}</b>
              </p>
            </div>
            <div
              style={{
                flexDirection: "column",
                paddingLeft: window.innerWidth <= 600 ? 0 : 15,
              }}
            >
              <b>{row.User.name}</b>
              <br></br>
              <p>Email: {row.User.email}</p>
              <p>
                {row.User.telephone && row.User.telephone.length > 0 ? (
                  row.User.telephone.length == 1 ? (
                    <a
                      target="_blank"
                      href={`https://web.whatsapp.com/send?phone=55${row.User.telephone[0]}`}
                    >
                      {row.User.telephone[0]}
                    </a>
                  ) : (
                    <>
                      <a
                        target="_blank"
                        href={`https://web.whatsapp.com/send?phone=55${row.User.telephone[0]}`}
                      >
                        {row.User.telephone[0]}
                      </a>
                      {" - "}
                      <a
                        target="_blank"
                        href={`https://web.whatsapp.com/send?phone=55${row.User.telephone[1]}`}
                      >
                        {row.User.telephone[1]}
                      </a>
                    </>
                  )
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: window.innerWidth <= 600 ? 15 : 0,
              height: window.innerWidth <= 600 ? "70%" : "50%",
              paddingLeft: 20,
              paddingRight: 20,
              display: "flex",
              flexDirection: window.innerWidth <= 600 ? "column" : "row",
            }}
          >
            <div className="input-group-teste">
              <label htmlFor="">Valor à Vista</label>
              <input
                placeholder="Ex: R$ 1.200.000,00"
                value={valorVista[i]}
                onChange={(element) => {
                  const arrayVista = [];

                  valorVista.map((valor, index) => {
                    if (index == i) {
                      arrayVista.push(money(element.target.value));
                    } else {
                      arrayVista.push(valor);
                    }
                  });

                  setIndex(i);
                  setValorVista(arrayVista);
                }}
              />
            </div>
            <div className="input-group-teste">
              <label htmlFor="">Valor Financiado</label>
              <input
                placeholder="Ex: R$ 500.000,00"
                value={valorFinanciado[i]}
                onChange={(element) => {
                  const arrayFinanciado = [];

                  valorFinanciado.map((valor, index) => {
                    if (index == i) {
                      arrayFinanciado.push(money(element.target.value));
                    } else {
                      arrayFinanciado.push(valor);
                    }
                  });

                  setIndex(i);
                  setValorFinanciado(arrayFinanciado);
                }}
              />
            </div>
            <div className="input-group-teste">
              <label htmlFor="">Total da Proposta</label>
              <input
                disabled={true}
                placeholder="Ex: R$ 1.700.000,00"
                value={valorProposta[i]}
              />
            </div>
          </div>
          <div style={{ justifyContent: "flexStart", width: "95%" }}>
            <div className="input-group-teste-2">
              <label htmlFor="">Comissão</label>
              <input
                placeholder="Ex: R$ 1.200.000,00"
                value={valorComissao[i]}
                onChange={(e) => {
                  const arrayComissao = [];
                  const arrMenosComissao = [];
                  const comissao = money(e.target.value);

                  valorComissao.map((valor, index) => {
                    if (index == i) {
                      const parsedComissao = parseInt(
                        moneyToNumber(comissao)
                      ).toFixed(2);

                      const parsedValorProposta = parseInt(
                        moneyToNumber(valorProposta[index])
                      ).toFixed(2);

                      const newTotal = (
                        parsedValorProposta - parsedComissao
                      ).toFixed(2);

                      arrMenosComissao.push(
                        newTotal < 0 ? money("0") : money(newTotal)
                      );
                      arrayComissao.push(money(e.target.value));
                    } else {
                      arrayComissao.push(valor);
                      arrMenosComissao.push(valorMenosComissao[index]);
                    }
                  });

                  setIndex(i);
                  setValorComissao(arrayComissao);
                  setValorMenosComissao(arrMenosComissao);
                }}
              />
            </div>
            <div className="input-group-teste-2">
              <label htmlFor="">Valor Total menos Comissão</label>
              <input
                placeholder="Ex: R$ 1.200.000,00"
                value={valorMenosComissao[i]}
                disabled={true}
              // onChange={(e) => {}}
              />
            </div>
          </div>
        </div>
        <div
          className="body-stats"
          style={{
            flexDirection: "column",
            justifyContent: "space-around",
            width: window.innerWidth <= 600 ? "45%" : "10%",
          }}
        >
          <Popconfirm
            placement="top"
            title={`Imóvel ${row._id}`}
            description='Deseja salvar essa proposta?'
            onConfirm={()=> onConfirm(async() => await handleEditar(row._id, i, row.historic))}
            open={isOpen(row._id, i, row.historic, CONFIRM_CONTEXT.save)}
            okButtonProps={{
             loading: confirmLoading,
              }}
             onCancel={()=>handlerOpenConfirm()}
             okText="Sim"
             cancelText="Não"
            >
          <div style={{ color: "#00AEC8", fontSize: "1.3vw" }}>
            <a
              style={{
                cursor: "pointer",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={(e) => handlerOpenConfirm(true, row._id, i, row.historic, CONFIRM_CONTEXT.save)}
            >
              Salvar
            </a>
          </div>
          </Popconfirm>
          <div
            style={{
              color: "#00AEC8",
              fontSize: "1.3vw",
              borderTop: "1px solid #aaa",
            }}
          >
            <a
              style={{
                cursor: "pointer",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={async (e) =>
                setModal({
                  ...modal,
                  historico: true,
                  historicoFunction: handleRemove,
                  row: row,
                })
              }
            >
              Histórico
            </a>
          </div>
        </div>

        <div
          className="body-stats"
          style={{
            flexDirection: "column",
            justifyContent: "space-around",
            width: window.innerWidth <= 600 ? "45%" : "10%",
          }}
        >
          <Popconfirm
            placement="top"
            title={`Imóvel ${row._id}`}
            description={`Deseja ${row.status.includes("Cancelada") ? "ativar" : "cancelar"} essa proposta?`}
            onConfirm={()=> onConfirm(async() => handleAction(row.status, row._id) )}
            open={isOpen(row.status, row._id, CONFIRM_CONTEXT.action)}
            okButtonProps={{
              loading: confirmLoading,
            }}
            onCancel={()=>handlerOpenConfirm()}
            okText="Sim"
            cancelText="Não"
          >
          <div style={{ color: "#00AEC8", fontSize: "1.3vw" }}>
            <a
              style={{
                cursor: "pointer",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={async () => {
                await handlerOpenConfirm(true, row.status, row._id, CONFIRM_CONTEXT.action)
              }}
            >
              {row.status.includes("Cancelada") ? "Ativar" : "Cancelar"}
            </a>
          </div>
          </Popconfirm>
          <div
            style={{
              color: "#00AEC8",
              fontSize: "1.3vw",
              borderTop: "1px solid #aaa",
            }}
          >
            <a
              style={{
                cursor: "pointer",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={async (e) =>
                setModal({
                  ...modal,
                  vendido: true,
                  vendidoFunction: handleSold,
                  i,
                  row: row,
                })
              }
            >
              Vendido
            </a>
          </div>
          <Popconfirm
            placement="top"
            title={`Imóvel ${row._id}`}
            description='Deseja excluir essa proposta?'
            onConfirm={()=> onConfirm(async() => await handleRemove(row._id))}
            open={isOpen(row._id, CONFIRM_CONTEXT.delete)}
            okButtonProps={{
             loading: confirmLoading,
              }}
             onCancel={()=>handlerOpenConfirm()}
             okText="Sim"
             cancelText="Não"
            >
          <div
            style={{
              color: "#00AEC8",
              fontSize: "1.3vw",
              borderTop: "1px solid #aaa",
            }}
          >
            <a
              style={{
                cursor: "pointer",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handlerOpenConfirm(true, row._id, CONFIRM_CONTEXT.delete)}
            >
              Excluir
            </a>
          </div>
          </Popconfirm>
        </div>
      </>
    );
  }

  return (
    <>
      <ModalExcluir
        modal={modal}
        setModal={setModal}
        money={money}
        moneyToNumber={moneyToNumber}
      />
      <ModalVendido
        modal={modal}
        setModal={setModal}
        money={money}
        moneyToNumber={moneyToNumber}
      />
      <ModalHistorico modal={modal} setModal={setModal} money={money} />

      <Header />
      <Aside />
      <Container>
        <div className="bread-crumb">
          <h1 className="category">IMÓVEIS À VENDA ></h1>
          <Link to={window.location.pathname} className="where">
            Propostas de Compra Enviadas
          </Link>
        </div>

        <div className="header" style={{ marginTop: 30 }}>
          <div className="cod">
            <h2>CÓD.</h2>
          </div>

          <div className="active">
            <h2>IMÓVEL</h2>
          </div>
        </div>

        {data[0] && data[0].Property && (
          <div
            className="body"
            style={{ height: window.innerWidth <= 600 ? 250 : 150 }}
          >
            <div className="body-cod">{data[0].Property._id}</div>
            <div
              className="body-imovel"
              style={{
                width: "93%",
                alignItems: "start",
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <small>
                Cadastrado em:{" "}
                {String(data[0].Property.createdAt.split(" ")[0])}
              </small>
              <h2 style={{ fontSize: 18, marginTop: 20 }}>
                <a style={{ color: "inherit" }}>
                  {data[0].Property.address ? data[0].Property.address : ""}
                  {data[0].Property.addressNumber
                    ? ", " + data[0].Property.addressNumber
                    : ""}{" "}
                  {data[0].Property.addressComplement
                    ? ` / ${data[0].Property.addressComplement}`
                    : ""}
                </a>
              </h2>
              <h3>
                {data[0].Property.neighborhood
                  ? data[0].Property.neighborhood?.name
                  : ""}
                {data[0].Property.city
                  ? " - " + data[0].Property?.city?.name
                  : ""}
                {data[0].Property?.neighborhood
                  ? ", " + data[0].Property.neighborhood?.state
                  : ""}
              </h3>

              <h5>
                {data[0].Property.type ? data[0].Property.type.name : ""}{" "}
                {data[0].Property.generalType
                  ? data[0].Property.generalType.name
                  : data[0].Property.type
                    ? data[0].Property.type.generalType == "2372820374682508"
                      ? "Residencial"
                      : "Comercial"
                    : ""}
              </h5>
            </div>
          </div>
        )}
        {data.map(
          (row, index) =>
            row.Property &&
            row.User && (
              <div
                className="body"
                style={{ height: window.innerWidth <= 600 ? 350 : 250 }}
              >
                <div className="body-cod">{row._id}</div>
                {window.innerWidth <= 600 ? (
                  <div
                    style={{
                      overflow: "auto",
                      display: "-webkit-inline-box",
                      width: "82%",
                    }}
                  >
                    {innerRow(row, index)}
                  </div>
                ) : (
                  innerRow(row, index)
                )}
              </div>
            )
        )}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: 40,
          }}
        >
          <button
            onClick={() => (window.location.href = "/admin/propostas-admin")}
            style={{
              color: "white",
              background: "linear-gradient(90deg, #EA5C1E 0%, #C13818 100%)",
              border: "none",
              borderRadius: 5,
              width: window.innerWidth <= 600 ? "100%" : 250,
              height: 45,
            }}
          >
            Voltar
          </button>
        </div>
      </Container>
    </>
  );
}
