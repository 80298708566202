import React from "react";
import { Container, Button } from "./style";
import { Link } from "react-router-dom";
import RestrictedView from "../RestrictedView";
import { getUserProfile } from "../../utils/sessionHelper";
import { PERMISSION, USER_TYPE } from "../../utils/userProfileHelper";

const sidebar = { position: "fixed" };

export default function AdminSideBar() {
  const userProfile = getUserProfile();
  if (!userProfile) {
    window.location.href = '/login';
  }

  const AgentMenu = () => {
    return (
      <Container id="sidebar-4" style={sidebar}>
        {window.innerWidth <= 600 ? (
          <img
            onClick={() => {
              let e = document.getElementById("sidebar-4");
              e.style.display = "none";
            }}
            src={require("./menu.svg")}
            alt=""
            style={{
              position: "absolute",
              left: 0,
              marginLeft: 15,
              marginTop: -8,
              cursor: "pointer",
            }}
          />
        ) : (
          ""
        )}

        <h1 style={{ marginTop: window.innerWidth <= 600 ? 20 : 0 }}>
          IMÓVEIS À VENDA
        </h1>
        <div className="link-group">
          <Link to="/admin/propostas-corretor">
            Propostas de Compra Enviadas
          </Link>
          <Link to="/admin/portfolio-corretor">
            Meu Portfólio (imóveis interessados)
          </Link>
        </div>
        <h1>COMPRAMOS SEU IMÓVEL</h1>
        <div className="link-group">
          <Link to="/admin/venda-corretor">
            Ofertas de Venda para Imóvel Vazio
          </Link>
        </div>

        <h1>CORRESPONDENTE BANCÁRIO</h1>
        <div className="link-group">
          <Link to="/admin/solicitacoes-corretor">Minhas Solicitações</Link>
        </div>

        <h1>MINHA CONTA</h1>
        <div className="link-group">
          <Link to="/admin/meu-perfil">Editar Meus Dados</Link>
        </div>

        {(process.env.REACT_APP_ENABLE_ZAP_ADS?.toLowerCase() === 'true') && <>
          <h1>ZAP IMÓVEIS</h1>
          <div className="link-group">
            <Link to="/admin/anuncios-zap/list">Gerenciamento de Anúncios</Link>
          </div>
        </>}

        <h1>AJUDA</h1>
        <div className="link-group">
          <Link to="/perguntas-frequentes">Ajuda</Link>
        </div>
        <Button
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Acessar o site
        </Button>
      </Container>
    );
  }

  const MarketingMenu = () => {
    const urls = [
      "admin/property/edit",
      "admin/property/list",
      "admin/marketing/listar",
      "admin/metricas-gerais",
      "admin/criacao-tag",
      "admin/marketing/editar",
      "/admin/real-estate-development",
    ];
    if (urls.filter((e) => window.location.href.includes(e)).length == 0) {
      return (window.location.href = "/admin/marketing/listar");
    }

    return (
      <Container style={sidebar}>
        {window.innerWidth <= 600 ? (
          <img
            onClick={() => {
              let e = document.getElementById("sidebar-4");
              e.style.display = "none";
            }}
            src={require("./menu.svg")}
            alt=""
            style={{
              position: "absolute",
              left: 0,
              marginLeft: 15,
              marginTop: -8,
              cursor: "pointer",
            }}
          />
        ) : (
          ""
        )}
        <h1>IMÓVEIS À VENDA</h1>
        <div className="link-group">
          <Link to="/admin/property/list">Anúncios Imóvel Vazio</Link>
        </div>
        {/* <h1>COMPRAMOS SEU IMÓVEL</h1>
        <div className="link-group">
          <Link to="/admin/solicitacao-ofertas">
            Ofertas de Venda para Imóvel Vazio
          </Link>
        </div> */}
        {/* <Link to="/admin/tabela-avaliacao">Cadastro de Tabela de Avaliação</Link> BACKUP */}

        <h1>MARKETING</h1>
        <div className="link-group">
          <Link to="/admin/marketing/listar">Lista de Corretores </Link>
          <Link to="/admin/metricas-gerais">
            Métricas Gerais de Desempenho
          </Link>
          <Link to="/admin/criacao-tag">Criação de Tags</Link>
          <Link to="/admin/real-estate-development">Empreendimentos</Link>
        </div>

        {/* {(!process.env.REACT_APP_ENABLE_ZAP_ADS?.toLowerCase() === 'true') && <>
          <h1>ZAP IMÓVEIS</h1>
          <div className="link-group">
            <Link to="/admin/marketing/listar">Gerenciamento de Anúncios</Link>
          </div>
        </>
        } */}

        <h1>AJUDA</h1>
        <div className="link-group">
          <Link to="/perguntas-frequentes">Ajuda</Link>
        </div>

        <Button
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Acessar o site
        </Button>
      </Container>
    );
  }

  const BankingMenu = () => {
    if (
      !window.location.href.includes("admin/correspondente/listar") &&
      !window.location.href.includes("admin/correspondente/negotiate")
    ) {
      return (window.location.href = "/admin/correspondente/listar");
    }

    return (
      <Container style={sidebar}>
        {window.innerWidth <= 600 ? (
          <img
            onClick={() => {
              let e = document.getElementById("sidebar-4");
              e.style.display = "none";
            }}
            src={require("./menu.svg")}
            alt=""
            style={{
              position: "absolute",
              left: 0,
              marginLeft: 15,
              marginTop: -8,
              cursor: "pointer",
            }}
          />
        ) : (
          ""
        )}
        <h1>CORRESPONDENTE BANCÁRIO</h1>
        <div className="link-group">
          <Link to="/admin/correspondente/listar">Lista de Solicitações</Link>
        </div>

        <h1>AJUDA</h1>
        <div className="link-group">
          <Link to="/perguntas-frequentes">Ajuda</Link>
        </div>
        <Button
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Acessar o site
        </Button>
      </Container>
    );
  }

  const DefaultMenu = () =>
  (
    <Container id="sidebar-4" style={sidebar}>
      {window.innerWidth <= 600 ? (
        <img
          onClick={() => {
            let e = document.getElementById("sidebar-4");
            e.style.display = "none";
          }}
          src={require("./menu.svg")}
          alt=""
          style={{
            position: "absolute",
            left: 0,
            marginLeft: 15,
            marginTop: -8,
            cursor: "pointer",
          }}
        />
      ) : (
        ""
      )}
      <h1>IMÓVEIS À VENDA</h1>
      <div className="link-group">
        <Link to="/admin/property/list">Anúncios Imóvel Vazio</Link>
        {/* <Link>Anúncios Corretores</Link> */}
        <RestrictedView userType={["admin"]} permission={["*"]}>
          <Link to="/admin/ordenacao">Ordenação de Imóveis</Link>
        </RestrictedView>
        <Link to="/admin/propostas-admin">Propostas Recebidas</Link>
        <Link to="/admin/fechaduras">Fechaduras</Link>
      </div>
      <h1>COMPRAMOS SEU IMÓVEL</h1>
      <div className="link-group">
        <Link to="/admin/solicitacao-ofertas">
          Ofertas de Venda para Imóvel Vazio
        </Link>
        {/* <Link to="/admin/tabela-avaliacao">Cadastro de Tabela de Avaliação</Link> BACKUP */}
      </div>

      <RestrictedView userType={["admin"]} permission={["*"]}>
        <h1>CORRESPONDENTE BANCÁRIO</h1>
        <div className="link-group">
          <Link to="/admin/correspondente/listar">Lista de Solicitações</Link>
        </div>
      </RestrictedView>

      <RestrictedView userType={["admin"]} permission={["*"]}>
        <h1>MARKETING</h1>
        <div className="link-group">
          <Link to="/admin/marketing/listar">Lista de Corretores </Link>
          <Link to="/admin/metricas-gerais">Métricas Gerais de Desempenho</Link>
          <Link to="/admin/criacao-tag">Criação de Tags</Link>
          <Link to="/admin/real-estate-development">Empreendimentos</Link>
        </div>
      </RestrictedView>

      {(process.env.REACT_APP_ENABLE_ZAP_ADS?.toLowerCase() === 'true') && <>
        <h1>ZAP IMÓVEIS</h1>
        <div className="link-group">
          <Link to="/admin/anuncios-zap/list">Gerenciamento de Anúncios</Link>
        </div>
      </>}

      <RestrictedView userType={["admin"]} permission={["*"]}>
        <h1>MONITORAMENTO</h1>
        <div className="link-group">
          <Link to="/admin/logs">Visualização de Logs</Link>
        </div>
      </RestrictedView >
      <h1>AJUDA</h1>
      <div className="link-group">
        <Link to="/perguntas-frequentes">Ajuda</Link>
      </div>
      <Button
        onClick={() => {
          window.location.href = "/";
        }}
      >
        Acessar o site
      </Button>
    </Container>
  );

  return (
    userProfile.type === USER_TYPE.admin ? (
      (userProfile.permission === PERMISSION.banking && <BankingMenu />)
      || (userProfile.permission === PERMISSION.marketing && <MarketingMenu />)
      || ([PERMISSION.all, PERMISSION.regional].includes(userProfile.permission) && <DefaultMenu />))
      : <AgentMenu />
  )
}
