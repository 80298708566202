import styled from "styled-components";

export const BarStory = styled.div`
   background-color: #FAFFFF;
`

export const MainStory = styled.div`
   display: flex;
   justify-content: space-around;
   align-items: center;
   padding-top: 130px;
   padding-bottom: 130px;
   margin-bottom: 100px;

   @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
   }
`

export const StoryTextLP = styled.div`
   width: 45%;

   p {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
   }

   @media (min-width: 768px) {
    margin-left: 20px;
   }

   @media (max-width: 768px) {
    margin-bottom: 150px;
    width: 65%;

        p {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
       }
   }
`

export const StoryTextTitle = styled.div`
    margin-bottom: 50px;

    span {
    font-family: 'Roboto';
    font-size: 51px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color: #3B414A;

    .title-color {
        color: #0098AE !important;
    }
   }

   @media (max-width: 768px) {
        text-align: center;

      span {
        font-size: 42px;
      }
   }
` 

export const StoryFirstParagraph = styled.div`
    margin-bottom: 20px;

    p {
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
    }

    @media (min-width: 769px) {
        width: 60%;
    }

    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            width: 80%;
            font-size: 16px;
            text-align: center;
        }
    }
`

export const StoryLastParagraph = styled.div`
    margin-bottom: 50px;

    p {
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
    }

    @media (min-width: 769px) {
        width: 60%;
    }

    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            width: 80%;
            font-size: 16px;
            text-align: center;
        }
    }
`

export const BtnPartnerBroker = styled.div`
    display: inline-block;

    a{
        text-decoration:none;
        color: #FFF !important;
        background-color: #0088AC;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 15px;

        span {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        }

        .icon-arrow {
            position: relative;
            top: 2px;
            left: 8px;
        }

        &:hover, .linkButton {
            background-color: #006690;
            color: #fff;
        }
    }

    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export const BtnSeeProperties = styled.div`
    display: inline-block;

    a{
        text-decoration:none;
        color:#fff;
        background-color: #0088AC;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 15px;
        padding-left: 35px;
        padding-right: 35px;

        span {
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        }

        .icon-arrow {
            position: relative;
            top: 2px;
            left: 8px;
        }

        &:hover, .linkButton {
            background-color: #006690;
            color: #fff;
        }

    }

    @media (max-width: 768px) {
       display: flex;
       justify-content: center;
       align-items: center;

    }
`



export const ImgBanner = styled.div`
    img {
        width: 90%;
    }

    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 80%;
        }
    }
`

export const ImageBuildings = styled.img`
    width: 100%;
    opacity: 1;
    position: absolute;
    bottom: 0;
`