import styled from "styled-components";

export const LinkWrapper = styled.li`
  position: relative;
`;

export const NewLabel = styled.label`
  position: absolute;
  top: -5px;
  left: -50px;
  z-index: 4;
  box-sizing: border-box;
  padding: 0.5px;
  background: linear-gradient(90deg, #ea5c1e 0%, #c13818 100%);
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  border-radius: 4px;
`;

export const Head = styled.header`
  height: 7 0px;
  width: 100%;
  position: sticky;
  padding: 20px;
  background-color: #ffffff;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1020px) and (min-width: 801px) {
    padding-left: 10px;
    padding-right: 30px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    position: relative;
    max-height: 500px;
  }
`;
export const HeadMobile = styled.header`
  max-height: 60px;
  width: 100%;
  position: absolute;
  padding-left: 15px;
  padding-right: 15px;
  top: 32px;
  background-color: #ffffff;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 500px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .user-hamburger {
      display: flex;
    }

  .container-hamburger {
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin-top: 14px;
  }

  @media (min-width: 400px) {
    padding-left: 5px;
    padding-right: 35px;
  }

  @media (max-width: 300px) {
    padding-left: 2px;
    padding-right: 2px;

    .container-hamburger {
    padding-left: 5px;
  }
  }
`;
export const Logo = styled.img`
  height: 70px;
  width: 180px;

  @media(max-width: 800px) {
    width: 130px;
  }
`;
export const Links = styled.div`
  ul {
    display: flex;
    margin-top: 18px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
    }

    li {
      text-decoration: none;
      list-style-type: none;
      margin-left: 2vw;
      

      @media (max-width: 800px) {
        margin-left: 0px;
        margin-top: 10px;
        padding-top: 10px;
      }

      span {
        //styleName: Label/Label Large;
        font-size: 16px;
        font-weight: 700;
        line-height: 14px;
        text-align: center;

      }

      a {
        text-decoration: none;
        color: #1e1d1d;
      }

      #offer-button {
        color: #ffffff;
        background: linear-gradient(90deg, #ea5c1e 0%, #c13818 100%);
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        padding: 5px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
      }
    }
  }
`;

export const UserLogin = styled.div`
   padding-top: ${props => props.admin ? '2px' : '15px'};
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-left: ${props => props.admin && '12px'};

    .user-avatar {
      background-color: #ea5c1e;
      color: #fff;

      span {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      }
    }

    .container-user-name {
      display: flex;
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .user-name {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
      cursor: pointer;
      padding-right: 8px;
    }

    .icon-down {
      padding-top: 6px;
      font-size: 12px;
      cursor: pointer;
    }

    .icon-outlined-down {
      color: #0EA5BB;
      font-size: 16px;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 7px;
    }

    .dropdown {
      position: relative;
      display: inline-block;
    }

    .dropdown-icon {
      position: absolute;
      right: 13%;
      bottom: 24%;
    }

    .dropdown-content {
      display: block;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 120px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      border-radius: 5px;
      left: 50%;
      transform: translate(-90%, 8%);

      @media (max-width: 800px) {
        transform: translate(-90%, 21%);
      }
    }

    .dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      cursor: pointer;
    }

    .dropdown-content a:hover {
      background-color: #ddd;
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }

    /* @media (max-width: 800px) {
      position: absolute;
      right: 20px;
      top: 12px;
    } */
`

export const UserAvatar = styled.div`
    padding-right: 8px;
    position: relative;
`

export const UserLabel = styled.div`
    padding-right: 5px
`
export const Hamburger = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 1.7rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  
  &:focus {
    outline: none;
  }
  
  div {
    width: 1.9rem;
    height: 0.18rem;
    background: #ea5c1e;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`
export const Divider = styled.hr`
    background: #cfc7c7;
`
export const EnterMyProfile = styled.div`
   border: 1px solid #ea5c1e;
    padding: 10px;
    margin-right: 45px;
    margin-top: 20px;
    padding-right: 10px;
    border-radius: 5px;

    a {
      padding-left: 10px;
      padding-right: 10px;
      text-decoration: none;
    }

    span {
      color: #ea5c1e;
      font-weight: 500;
      font-size: 16px;
    }

    @media (max-width: 800px) {
      padding: 10px;
      margin-right: 2px;
      margin-top: 10px;
      padding-right: 0;

      a {
      padding-left: 0;
    }
    }
`
