import React, { useEffect, useState } from "react";
import { Head, Logo, Links, NewLabel, LinkWrapper, UserLogin, UserAvatar, UserLabel, EnterMyProfile } from "./styles";
import logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import "../../global.css";
import api from "../../api";
import { getUserSession } from "../../utils/sessionHelper";
import { Dropdown, Avatar, Space } from "antd";
import { DownOutlined, DownCircleTwoTone } from '@ant-design/icons';
import { useLayoutEffect } from "react";
import { getCitiesAsync } from "../../services/city.service";
import { getUserId } from "../../utils/sessionHelper";
import { revokeToken } from "../../api.js";
import HeaderMobile from "../headerMobile";
import { getInitialsName } from "../../utils/getInitialsName";
import { getUserName } from "../../utils/getUserName";

const LP_URL = process.env.REACT_APP_LP_URL;

export default function Header() {
  const [menuActive, setMenuActive] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [open, setOpen] = useState(false)
  const [propertiesForSaleMenu, setPropertiesForSaleMenu] = useState([]);
  const userId = getUserId();
  const userSessionProfile = getUserSession();

  const itemsMenu = [
    {
      label: 'Minha Conta',
      key: 0
    },
    { label: "Editar Perfil", key: 1 },
    { label: "Sair", key: 2 }
  ]

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("app")) {
      setMenuActive(false);
    }
  }, []);

  useLayoutEffect(() => {
    const options = [];
    getCitiesAsync({ orderBy: 'total', direction: 'DESC', showCatalog: true, arquive: false, sold: false }).then((res) => {
      res.data.map((item) => {
        options.push({
          key: item.value.toString(),
          label: (
            <a href={`/imoveis?city_id=${item.value}&sold=false`}>
              <h5>{`${item.city}, ${item.uf}`} <span style={{ color: '#444' }}>({item.total})</span></h5>
            </a>
          ),
        });
      });
      setPropertiesForSaleMenu(options);
    });
  }, []);

  async function handleDropdownItemClick(e) {
    switch (e) {
      case 0:
        openMyProfile();
        break
      case 1:
        window.location.href = "/admin/meu-perfil";
        break
      case 2:
        revokeToken();
        break
      default:
        console.error(`Error não tem opção`);
    }
  };

  async function openMyProfile() {
    // setLoading(true)
    if (userId) {
      localStorage.setItem("id_painel", userId);
      let { data } = await api.get(
        "/user/" + userId
      );
      localStorage.setItem("type_user", data[0].type);
      localStorage.setItem("permission", data[0].permission);
      window.location.href = "/admin/home-corretor";
    } else {
      window.location.href = "/login";
    }

    // setLoading(false);
  }

  function getInitials(name) {
    if (name === '') return;
    let parts = name.split(' ')
    let initials = ''
    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0]
      }
    }
    return initials
  }

  return menuActive ? (
    <>
      {window.innerWidth >= 800 ? (
        <div>
          <Head className="header">
            <div>
              <Link
                onClick={(click) =>
                  (document.querySelector("body").style.overflow = "scroll")
                }
                to="/"
              >
                <Logo src={logo} />
              </Link>
            </div>
            <Links>
              <ul>
                <LinkWrapper>
                  <NewLabel>novo</NewLabel>
                  <a
                    onClick={(click) =>
                      (document.querySelector("body").style.overflow = "scroll")
                    }
                    href={LP_URL}
                    target="_blank"
                  >
                    <span>Residencial Vilares</span>
                  </a>
                </LinkWrapper>
                <li>
                  <Dropdown
                    menu={{
                      items: propertiesForSaleMenu,
                    }}
                  >
                    <span>Imóveis à venda</span>
                  </Dropdown>
                </li>
                <li>
                  <Link
                    onClick={(click) =>
                      (document.querySelector("body").style.overflow = "scroll")
                    }
                    to="/correspondente-bancario"
                  >
                    <span>Correspondente</span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={(click) => {
                      document.querySelector("body").style.overflow = "scroll";
                    }}
                    to="/sobre-nos"
                  >
                    <span>Sobre nós</span>
                  </Link>
                </li>
                <li>
                  <div
                    onClick={(click) => {
                      document.querySelector("body").style.overflow = "scroll";
                      window.location.href = "/ofertar-imovel";
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span> Compramos seu Imóvel </span>
                  </div>
                </li>
                <li>
                  <div
                    onClick={(click) => {
                      document.querySelector("body").style.overflow = "scroll";
                      window.location.href = "/perguntas-frequentes";
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span>Ajuda</span>
                  </div>
                </li>
              </ul>
            </Links>
            {userSessionProfile !== undefined ? (
              <UserLogin>
                <UserAvatar>
                  <Avatar className="user-avatar">
                    {getInitialsName(getUserName(userSessionProfile.name))}
                  </Avatar>
                </UserAvatar>
                <UserLabel>
                  <a onClick={(e) => handleDropdownItemClick(e.key)}>
                    <span className="user-name"> {getUserName(userSessionProfile.name)} </span>
                  </a>
                </UserLabel>
                <div className="dropdown" onClick={() => setShowOptions((showModal) => !showModal)}>
                  <DownOutlined className="icon-down dropbtn" />
                  {showOptions &&
                    <div className="dropdown-content">
                      <a className="dropdown-item"
                        onClick={() => handleDropdownItemClick(0)} >Minha Conta</a>
                      {userSessionProfile.type === "agent" &&
                        <a className="dropdown-item"
                          onClick={() => handleDropdownItemClick(1)} >Editar Perfil</a>
                      }
                      <a className="dropdown-item"
                        onClick={() => handleDropdownItemClick(2)} >Sair</a>
                    </div>
                  }
                </div>
              </UserLogin>
            ) : (
              <EnterMyProfile>
                <Link
                  onClick={(click) =>
                    (document.querySelector("body").style.overflow = "scroll")
                  }
                  to="/login"
                >
                  <span>Entrar</span>
                </Link>
              </EnterMyProfile>
            )
            }
          </Head>
        </div>
      ) : (
        <HeaderMobile
          propertiesForSaleMenu={propertiesForSaleMenu}
          open={open}
          setOpen={setOpen}
          getInitials={getInitials}
          itemsMenu={itemsMenu}
          handleDropdownItemClick={handleDropdownItemClick} />
      )}
    </>
  ) : (
    <></>
  );
}
