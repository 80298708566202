import React from 'react';
import { FormFieldStyled } from "./styles";


export default function FormField({label = '', error= {},  children}){
  return <FormFieldStyled error={error}>
     <small>{label}</small>
    {children}
    {error.type && <span>{error.message || `${label} é obrigatório!`}</span>}
  </FormFieldStyled>;
}