import styled from "styled-components";

export const Container = styled.div`
  height: 479px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  form {
    padding-right: 30px;
  }
`

export const Title = styled.span`
  color: #3B414A;
  font-family: 'Roboto';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
`

export const ContentCharacteristics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
`

export const TitleCharacteristics = styled.span`
  color: #1B1F27;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const ContentCharacteristicsValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
`

export const ContentCharacteristicsSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 30px;

  .content-select {
    border: 1px solid transparent;
    border-radius: 6px;
    transition: border-color 0.3s ease-in-out;
  }

  .content-select.error {
    border-color: red;
  }

  .txt-error {
    color: red;
  }
`
export const Question = styled.span`
  max-width: 220px;
  color: #000;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const ValueQuestion = styled.span`
  color: #8C8C8C;
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const SubTitle = styled.span`
  color: #3B414A;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 30px;
`

export const ContentField = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;

  .content-middle {
    width: 50%;
  }

  .content-larger {
    width: 100%;
  }
`

export const Label = styled.label`
  color: #3B414A;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 0 !important;
`

export const Field = styled.input`
  display: flex;
  padding: 10px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  width: 100%;
`