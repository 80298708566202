import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Avatar, Popover, Popconfirm } from 'antd';
import Highlighter from 'react-highlight-words';
import * as S from './styles'

import money from '../../utils/money';

import { formatDate } from '../../utils/formatDate';

import EditIcon from './icons/editIcon';
import TickCircleIcon from './icons/tickCircleIcon'
import TrashIcon from './icons/trashIcon'
import MoreIcon from './icons/more.svg'
import CameraWhite from '../../assets/ic-camera-white.svg'

const TableWithSearch = ({ children, dataPropertyZap, actionProperty, deleteProperty, openModalZapEdit }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [idPropertySelected, setIdPropertySelected] = useState(null);

  const imageDefault = 'https://www.ufukkimya.com/media/resim-yok.png';

  useEffect(() => {
   setIdPropertySelected(null);
  }, [])

  const hide = () => {
    setIdPropertySelected(null);
  };

  const handleOpenChange = (idSelected) => {
    setIdPropertySelected(idSelected);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');

    handleSearch(selectedKeys, confirm, dataIndex)
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 100,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, selectedKeys, confirm, dataIndex)}
            size="small"
            style={{
              width: 100,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if(dataIndex === 'updatedAt' || dataIndex === 'status') {
        return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
      } else {
        return record.property[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.value, 100);
      }
    },
    render: (text) =>
    
    console.log('text', dataIndex)
      // searchedColumn === dataIndex ? (
      //   <Highlighter
      //     highlightStyle={{
      //       backgroundColor: '#ffc069',
      //       padding: 0,
      //     }}
      //     searchWords={[searchText]}
      //     autoEscape
      //     textToHighlight={text ? text.toString() : ''}
      //   />
      // ) : (
      //   text
      // ),
  });

  const getImageProperty = (id, urlImage) => {
   if(urlImage) {
    return `${process.env.REACT_APP_FILES_URL}/${id}/fotos/${encodeURIComponent(urlImage)}`
   }

   return imageDefault;
  }

  const columns = [
    {
      title: 'Imóvel',
      dataIndex: 'property',
      key: 'property',
      width: '30%',
      ...getColumnSearchProps('address'),
      // sorter: (a, b) => a.address.length - b.address.length,
      // sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
        <div className='property-column'>
          <Avatar src={getImageProperty(record.property._id, record.property.images[0]?.image)} size={50} />
          <div>
            <span className='title-property'>{`${record.property.address}, ${record.property.addressNumber}`}</span>
            <p className='title-state'>{record.property.stateName}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      width: '20%',
      ...getColumnSearchProps('sellingPrice'),
      // sorter: (a, b) => a.address.length - b.address.length,
      // sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span className='item-property'>{money(record.property.sellingPrice)}</span>
      ),
    },
    {
      title: 'Bairro',
      dataIndex: 'neighborhood',
      key: 'neighborhood',
      ...getColumnSearchProps('neighborhoodRealName'),
      // sorter: (a, b) => a.address.length - b.address.length,
      // sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span className='item-property'>{record.property.neighborhoodRealName}</span>
      ),
    },
    {
      title: 'Última Edição',
      dataIndex: 'lastEdition',
      key: 'lastEdition',
      ...getColumnSearchProps('updatedAt'),
      // sorter: (a, b) => a.address.length - b.address.length,
      // sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span className='item-property'>{formatDate(record.updatedAt)}</span>
      ),
    },
    {
      title: 'Status de vinculação',
      dataIndex: 'status',
      key: 'status',
      ...getColumnSearchProps('status'),
      // sorter: (a, b) => a.address.length - b.address.length,
      // sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <S.TagStatusList isActive={record.status.trim() === 'Ativo'}>
          <div className="indicate"></div>
          {record.status}
        </S.TagStatusList>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '5%',
      render: (text, record) => (
        <Popover
          content={
            <S.ContainerPopover>
              <Popconfirm
                title="Status Anúncio"
                description={`Tem certeza que deseja ${record.status.trim() === 'Ativo' ? 'inativar' : 'ativar'} esse anúncio?`}
                onConfirm={() => {
                  actionProperty(record._id, record.status.trim() === 'Ativo' ? 'Inativado' : 'Ativo')
                  hide()
                }}
                okText={record.status.trim() === 'Ativo' ? 'Inativar' : 'Ativar'}
                cancelText="Cancelar"
              >
                <S.ContentPopover>
                  <span>{record.status.trim() === 'Ativo' ? 'Inativar Imóvel' : 'Ativar Imóvel'}</span>
                  <TickCircleIcon />
                </S.ContentPopover>
              </Popconfirm>

              <S.ContentPopover onClick={() => {
                  openModalZapEdit(record)
                  hide()
                  }}>
                <span>Editar informações do imóvel</span>
                <EditIcon />
              </S.ContentPopover>
              
              <Popconfirm
                title="Deletar Anúncio"
                description={`Tem certeza que deseja deletar esse anúncio?`}
                onConfirm={() => {
                  deleteProperty(record._id)
                  hide()
                }}
                okText="Deletar"
                cancelText="Cancelar"
              >
                <S.ContentPopover>
                  <span>Excluir</span>
                  <TrashIcon />
                </S.ContentPopover>
              </Popconfirm>
            </S.ContainerPopover>
          }
          trigger="click" 
          placement="left"
          open={record._id === idPropertySelected}
          onOpenChange={hide}
        >
         <img src={MoreIcon} alt="More Icon" className='icon-more' onClick={() => handleOpenChange(record._id)} />
        </Popover>
      )
    },
  ];

  const setScroll = {
      y: 600
   }

  return <S.TableZap title={() => children} pagination={false} scroll={setScroll} columns={columns} dataSource={dataPropertyZap} />;
};
export default TableWithSearch;