/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import exportFromJSON from "export-from-json";

import {
  ComboBox,
  Container
} from "./styles";
import Header from "../../components/adminHeader";
import Aside from "../../components/adminSideBar";
import Loading from "../../components/Loading";

import { ConverterEstados } from "../../utils/converterUF";
import moment from "moment";
import api from "../../api";
import Axios from "axios";
import { Popconfirm, Select } from "antd";

import socketClient from "socket.io-client";
import { getOffersAsync } from "../../services/offer.service";

export default function () {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [socket, setSocket] = useState(null);
  const [data, setData] = useState([]);
  const [preAnalise, setPreAnalise] = useState("");
  const [status, setStatus] = useState("");
  const [bairros, setBairros] = useState([]);
  const [algo, setAlgo] = useState("");
  const [contado, setContato] = useState("");


  const [parametro, setParametro] = useState("");
  const [dataInicio, setDataInicio] = useState("");
  const [dataFinal, setDataFinal] = useState("");

  const [newMessage, setNewMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const [cancel, setCancel] = useState(Axios.CancelToken.source());

  const CONFIRM_CONTEXT = {
    default: '',
    home: 'Home',
    catalog: 'Catalog',
    delete: 'Excluir'
  };

  const [confirmLoading, setConfirmLoading] = useState(false);
  const defaultOpenConfirm = { state: false, id: 0, context: CONFIRM_CONTEXT.default };
  const [openConfirm, setOpenConfirm] = useState(defaultOpenConfirm);
  const isOpen = (id, context) => {
    return openConfirm.state && openConfirm.context === context && openConfirm.id === id;
  }
  const handlerOpenConfirm = (state = defaultOpenConfirm.state, id = defaultOpenConfirm.id, context = defaultOpenConfirm.context) => {
    setOpenConfirm({
      state,
      id,
      context,
    })
  }

  const onConfirm = async (callback) => {
    try {
      setConfirmLoading(true);
      await callback();
    } finally {
      setConfirmLoading(false);
      handlerOpenConfirm(false);
    }
  };

  useEffect(() => {
    reloadMessages();

    setSocket(
      socketClient(process.env.REACT_APP_API_URL, {
        query: {
          user: localStorage.getItem("id_painel"),
          type: localStorage.getItem("type_user"),
        },
      })
    );

    if (localStorage.getItem("type_user") != "admin") {
      window.location.href = "/login";
    }

    if (localStorage.getItem("fill"))
      window.location.href = "/admin/solicitacao-ofertas#filter";

    if (
      window.location.hash.substring(1) == "filter" &&
      localStorage.getItem("params")
    ) {
      handleReload();
    } else {
      handleLoad();
    }
    loadPropertyTypes();
  }, []);

  useEffect(() => {
    if (socket != null) {
      socket.on("listMessages", (data) => {
        reloadMessages();
      });
    }
  }, [socket]);


  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [propertyType, setPropertyType] = useState([]);

  const loadPropertyTypes = async () => {
    const { data } = await api.get("/propertyType?limit=9999");
    let tips = new Array();
    data.propertyTypes.map((item) => {
      tips.push({ value: item._id, label: item.name });
    });
    setPropertyTypeList(tips);
  };

  async function handleReload() {
    try {
      setLoading(true);
      await codeNewMessage();
      let storageParams = localStorage.getItem("params");
      if (storageParams) storageParams = JSON.parse(storageParams);

      if (storageParams?.analise)
        setPreAnalise(storageParams?.analise);

      if (storageParams?.status)
        setStatus(storageParams?.status);

      const { data: response } = await getOffersAsync(storageParams, isAuthenticated, cancel.token);
      if (response.Offer) {
        setData(response.Offer);
      } else if (response) {
        setData(response);
      }

      window.scrollTo(0, Number(localStorage.getItem("telaOffer")));
      localStorage.removeItem("fill");
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  }

  async function codeNewMessage() {
    var response = await getOffersAsync({ message: 'only-new', type: 'agent', cod: 1 }, isAuthenticated);
    if (response.data.length > 0) {
      setNewMessage(true);
    } else {
      setNewMessage(false);
    }
  }

  async function handleNewMessages() {
    document.body.style.cursor = "wait";
    const response = await getOffersAsync({ message: 'only-new', type: 'agent', cod: 2 }, isAuthenticated);

    if (response.data.length == 0) {
      setNewMessage(false);
    } else {
      setData(response.data);
    }
    cancel.cancel();
    clean();
    document.body.style.cursor = "default";
  }

  function clean() {
    setCancel(Axios.CancelToken.source());
  }

  async function handleLoad() {
    try {
      setLoading(true);
      localStorage.removeItem("fill");
      setPreAnalise("");
      setStatus("");
      setDataInicio("");
      setDataFinal("");
      setPropertyType([]);
      setContato("");
      setAlgo("");

      await codeNewMessage();
      const request = { limit: 250 };
      localStorage.setItem("params", JSON.stringify(request));
      const { data } = await getOffersAsync(request, isAuthenticated, cancel.token);

      if (data) {
        setData(data.Offer);
        localStorage.setItem("auxOfertas", JSON.stringify(data.Offer));
      }
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  }

  function money(value) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(+value.replace(/\D+/g, "") / 100);
  }

  async function handleDelete(id, archived = false) {
    if (archived) {
      const response = await api.delete(`/offer/${id}?archived=false`);
      if (response.data.deletedId) {
        let objs = data.filter((r) => r._id != response.data.deletedId);
        setData(objs);
      }
      return;
    }

    const response = await api.delete(`/offer/${id}`);
    if (response.data.deletedId) {
      // handleLoad()
      let objs = data.filter((r) => r._id != response.data.deletedId);
      setData(objs);
    }
  }

  async function filter() {

    try {
      setLoading(true);
      document.body.style.cursor = "wait";

      let dataInicial =
        !dataInicio.length ? undefined :
          dataInicio.split("-")[2] +
          "/" +
          dataInicio.split("-")[1] +
          "/" +
          dataInicio.split("-")[0].slice(0, 4);

      let dataFinalizada =
        !dataFinal.length ? undefined :
          dataFinal.split("-")[2] +
          "/" +
          dataFinal.split("-")[1] +
          "/" +
          dataFinal.split("-")[0].slice(0, 4);

      let query = {
        limit: 250,
        dataInicio: dataInicial,
        dataFinal: dataFinalizada
      };

      if (algo) query.endereco = algo;
      if (contado) query.contato = contado;

      if (preAnalise || status) {
        if (preAnalise) query.analise = preAnalise;
        if (status) {
          if (status === "Excluido") {
            query.archived = true;
          } else {
            query.status = status;
          }
        }
      }

      if (propertyType) query.propertyType = propertyType;

      localStorage.setItem("params", JSON.stringify(query));
      const { data } = await getOffersAsync(query, isAuthenticated);
      setData(data?.Offer ? data.Offer : data);
      document.body.style.cursor = "default";
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  }

  function hasQuery() {
    let dataInicial =
      !dataInicio.length ? undefined :
        dataInicio.split("-")[2] +
        "/" +
        dataInicio.split("-")[1] +
        "/" +
        dataInicio.split("-")[0].slice(0, 4);
    let dataFinalizada =
      !dataFinal.length ? undefined :
        dataFinal.split("-")[2] +
        "/" +
        dataFinal.split("-")[1] +
        "/" +
        dataFinal.split("-")[0].slice(0, 4);

    let query = {
      limit: 99999,
      dataInicio: dataInicial,
      dataFinal: dataFinalizada
    };

    if (preAnalise) query.analise = preAnalise;
    if (status) query.status = status;
    if (algo) query.endereco = algo;
    if (contado) query.contado = contado;

    return query;
  }

  function toDate(dateStr) {
    var parts = dateStr.split("T")[0].split("-");

    return `${parts[2]} /${parts[1]}/${parts[0]} `;
  }

  const verifyPropertyTip = (id) => {
    if (id == 25 || id == 29 || id == 30 || id == 39) {
      return "house";
    } else if (id == 26) {
      return "roof";
    } else {
      return false;
    }
  };

  async function hasPrint() {
    setLoading(true);
    document.body.style.cursor = "progress";
    var results = [];

    const response = await getOffersAsync(hasQuery(), isAuthenticated);
    if (response.data.Offer) {
      response.data.Offer.map((e) => {
        results.push(e);
      });
    } else {
      response.data.map((e) => {
        results.push(e);
      });
    }

    document.body.style.cursor = "default";
    const newData = [];

    results.forEach((item, i) => {
      const type = verifyPropertyTip(item?.type?._id);
      let findNeighborhoodInAddress = null;
      let findCityInAddress = null;
      if (
        !!item.address &&
        item.address.includes(",") &&
        item.address.match(/\,/g).length === 2
      ) {
        const address = item.address.split(",")[0];
        const city = item.address.split(",")[1];

        // VERIFICA SE TEM O BAIRRO
        if (address.includes(" - ")) {
          findNeighborhoodInAddress = address.split(" - ")[1];
        }

        if (city.includes(" - ")) {
          findCityInAddress = city.split(" - ")[0];
        }
      }

      if (!findNeighborhoodInAddress) {
        findNeighborhoodInAddress = item.address
          ?.split(" - ")[1]
          ?.split(",")[0];
      }

      newData.push({
        ID: item?._id,
        Nome: item?.name,
        Email: item?.email,
        Telefones: String(item?.telephone)
          .replaceAll("[", "")
          .replaceAll("]", ""),
        Endereco: item?.address.includes(" - ")
          ? item.address.split(" - ")[0]
          : item?.address,
        Numero: item?.addressNumber ? item?.addressNumber : "",
        Complemento: item?.addressComplement || '',
        "Tipo de Imóvel": item?.type?.name || '',
        Bairro: findNeighborhoodInAddress
          ? findNeighborhoodInAddress
          : item?.bairro
            ? item?.bairro.includes("RJ")
              ? ""
              : ""
            : "",
        Cidade: findCityInAddress ? item?.city : item?.city,
        Estado: !!item?.state ? ConverterEstados(item.state) : "",
        Banheiros: item?.bathrooms,
        Quartos: item?.bedrooms,
        Suites: item?.suites,
        Dependencias: item?.dependencies,
        vagas: item?.parkingSpots,
        Posicao: item?.position ? item?.position : "",
        Andar: item?.level,
        Escritura: item?.scripture ? "SIM" : "",
        Area_do_terreno: item?.totalArea,
        Area_construida: item?.constructArea,
        Area_no_iptu: item?.landSizeUseful || item?.iptuArea,
        Iptu: item?.numberIPTU,
        Status: item?.status,
        Analiise: item?.analise,
        // Area_construida_consta_no_iptu: item?.construcOnIptu ? "sim" : "não", desabilitado
        valor_condominio: money(parseFloat(item?.condominiumPrice).toFixed(2)),
        Valor_desejado: item?.valueDesejado
          ? money(parseFloat(item?.valueDesejado).toFixed(2))
          : "",
        Proposta: item?.valorFinalProposta
          ? money(parseFloat(item?.valorFinalProposta).toFixed(2))
          : "",
        Valor_mercado: item?.valueMercado
          ? money(parseFloat(item?.valueMercado).toFixed(2))
          : "",
        Valor_final: item?.valueMercado
          ? money(parseFloat(item?.valorFinal).toFixed(2))
          : "",
        Data_cadastro: moment(
          item?.createdAt,
          "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
        ).format("DD/MM/YYYY HH:mm"),
      });
    });

    const fileName = "ofertas";
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data: newData, fileName, exportType });

    setLoading(false);
  }

  window.onscroll = (e) => {
    localStorage.setItem("telaOffer", window.scrollY + "");
  };

  const reloadMessages = async () => {
    setTimeout(async () => {
      await codeNewMessage();
    }, 500);
  };

  function innerRow(row, index) {
    return (
      <>
        <div className="border-div h1-2">
          <div className="container-divide">
            <div className="divData">
              Cadastrado em:{" "}
              {row.createdAt.split("-")[2].substr(0, 2) +
                "/" +
                row.createdAt.split("-")[1] +
                "/" +
                row.createdAt.split("-")[0]}

              <small>{row.type?.name}</small>
            </div>
            <div className="border-top divDescription">
              {row.address}
              {row.addressNumber ? ", " + row.addressNumber : ""}
              {!row.addressComplement ? "" : " / " + row.addressComplement}
            </div>
            <div className="divData" ></div>
            {/* <div className="border-top divState">
              {row.bairro ? row.bairro : "Rio de Janeiro, RJ"}
            </div> */}
          </div>

        </div>

        <div className="border-div h1-3">
          <div className="container-divide">
            <div
              style={{ fontSize: window.innerWidth <= 600 ? 14 : 16 }}
              className="divNome"
            >
              {row.idUser
                ? row.idUser.name
                : row.name
                  ? row.name
                  : "Nenhum nome cadastrado"}
            </div>
            <div style={{ fontSize: 13 }} className="border-top divEmail">
              {row.idUser ? row.idUser.email : row.email}
            </div>
            <div className="border-top divTelephone">
              {row.idUser
                ? row.idUser.telephone.length != 0
                  ? row.idUser.telephone[0]
                  : "Nenhum telefone cadastrado"
                : row.telephone.length != 0
                  ? row.telephone[0]
                  : "Nenhum telefone cadastrado"}
            </div>
          </div>
        </div>
        <div className="border-div h1-4">
          <div className="container-divide">
            <div style={{ fontSize: 16 }} className="divEmail">
              Pré-análise: {row.analise}
            </div>
            <div className="border-top divEmail">{row.status}</div>
          </div>
        </div>
        <div className="border-div h1-5">
          <div className="container-divide">
            <div style={{ fontSize: 15 }} className="divEmail">
              Avaliado:{" "}
              {money(
                String(row.valorFinal).includes(".")
                  ? String(row.valorFinal)
                  : String(row.valorFinal) + "00"
              ).replaceAll(",", ".")}
            </div>
            <div style={{ fontSize: 15 }} className="border-top divEmail">
              Proposta:{" "}
              {money(
                String(row.valorFinalProposta).includes(".")
                  ? String(row.valorFinalProposta)
                  : String(row.valorFinalProposta) + "00"
              ).replaceAll(",", ".")}
            </div>
          </div>
        </div>
        <div className="border-div row-last h1-6">
          <div className="container-divide">
            <Link
              className="btOptions"
              to={`/admin/ofertas/editar?id=${row._id}`}
            >
              Editar
            </Link>
            <Popconfirm
              placement="top"
              className="size-modal"
              title={`Imóvel ${row._id}`}
              description={`Deseja excluir essa oferta?`}
              onConfirm={() => onConfirm(async () => await handleDelete(row._id, row.archived))}
              open={isOpen(row._id, CONFIRM_CONTEXT.delete)}
              okButtonProps={{
                loading: confirmLoading,
              }}
              onCancel={() => handlerOpenConfirm()}
              okText="Sim"
              cancelText="Não"
            >
              <div
                className="border-top btOptions"
                onClick={() => handlerOpenConfirm(true, row._id, CONFIRM_CONTEXT.delete)}
              >
                {!row.archived ? "Excluir" : "Ativar"}
              </div>
            </Popconfirm>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <Aside />
      {loading ? <Loading /> : ""}
      <Container>
        <div className="bread-crumb">
          <h1 className="category">COMPRAMOS SEU IMÓVEL </h1>
          <Link to="/admin/solicitacao-ofertas" className="where">
            Ofertas de Venda para Imóvel Vazio
          </Link>
        </div>
        <div className="box-control">
          <div className="container-control">
            <div className="input-tolerant">
              <span>Status</span>
              <select
                value={status}
                onChange={(e) =>
                  e.target.value == "Selecione"
                    ? setStatus("")
                    : setStatus(e.target.value)
                }
                id="input-tolerancia"
                type="number"
              >
                <option>Selecione</option>
                <option value="Novo">Novo</option>
                <option value="Analisando">Analisando</option>
                <option value="Sem Interesse">Sem Interesse</option>
                <option value="Negociando">Negociando</option>
                <option value="Não Comprado">Não Comprado</option>
                <option value="Comprado">Comprado</option>
                <option value="Acima do Preço">Acima do Preço</option>
                <option value="Avaliado">Avaliado</option>
                <option value="Vendido">Vendido</option>
                <option value="Excluido">Excluído</option>
                <option value="Aguardando infos">Aguardando infos</option>
              </select>
            </div>
            <div className="input-tolerant">
              <span>Pré-análise</span>
              <select
                value={preAnalise}
                onChange={(e) =>
                  e.target.value == "Selecione"
                    ? setPreAnalise("")
                    : setPreAnalise(e.target.value)
                }
                id="input-tolerancia"
                type="number"
              >
                <option>Selecione</option>
                <option value="Aceita">Aceita</option>
                <option value="Negada">Negada</option>
                <option value="Alterada">Alterada</option>
                <option value="Nao Negada">Não Negada</option>
              </select>
            </div>
            <div className="input-tolerant">
              <span>Data de Início</span>
              <input
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
                type="date"
              />
            </div>
            <div className="input-tolerant">
              <span>Data de Fim</span>
              <input
                value={dataFinal}
                onChange={(e) => setDataFinal(e.target.value)}
                type="date"
              />
            </div>
          </div>

          <div className="container-control">
            <div style={{ width: 'calc(25% - 0.5rem)' }} className="input-tolerant">
              <span>Tipo de imóvel</span>
              <ComboBox
                value={propertyType}
                onChange={setPropertyType}
                options={propertyTypeList}
              />
            </div>

            <div style={{ width: '50%' }} className="input-tolerant">
              <span>Endereço</span>
              <input value={algo} onChange={(e) => setAlgo(e.target.value)} />
            </div>
            <div style={{ width: 'calc(25% - 0.5rem)' }} className="input-tolerant">
              <span>Contato</span>
              <input value={contado} onChange={(e) => setContato(e.target.value)} />
            </div>
          </div>
          <div className="container-control flex-end">
            {!loading && <div style={{ width: '25%', color: '#555' }}>Exibindo <b>{data.length}</b> Ofertas</div>}
            {newMessage ? (
              <button
                style={{
                  inlineSize: "max-content",
                  marginRight: window.innerWidth <= 600 ? 0 : 8,
                  marginBottom: window.innerWidth <= 600 ? 15 : 0,
                  border: "1px solid #31AA26",
                  width: '200px'
                }}
                onClick={handleNewMessages}
                className="outline btn-green"
              >
                Novas Mensagens
              </button>
            ) : (
              ""
            )}
            <button
              onClick={() => {
                handleLoad();
              }}
              className="buttonSave bt-inverse"
            >
              Remover filtros
            </button>
            <button onClick={filter} className="buttonSave">
              Aplicar Filtros
            </button>
          </div>
          <input hidden id="ArrayValues" />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: window.innerWidth <= 600 ? "100%" : "120%",
            justifyContent:
              window.innerWidth <= 600 ? "center" : "space-between",
            marginTop: 20,
          }}
        >
          <p></p>
          <a
            onClick={hasPrint}
            style={{
              color: "#00AEC8",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            {loading ? "Carregando..." : "Fazer download da lista de ofertas"}
          </a>
        </div>

        <div className="box-control box-table">
          <div className="box-header">
            <h1 className="h1-border h1-1">SEQ.</h1>
            {window.innerWidth <= 600 ? (
              <div
                style={{
                  overflow: "auto",
                  display: "-webkit-inline-box",
                  width: "82%",
                }}
              >
                <h1 className="h1-border h1-2">IMÓVEL</h1>
                <h1 className="h1-border h1-3">CONTATO</h1>
                <h1 className="h1-border h1-4">STATUS</h1>
                <h1 className="h1-border h1-5">VALORES</h1>
                <h1 className="h1-border h1-6">AÇÕES</h1>
              </div>
            ) : (
              <>
                <h1 className="h1-border h1-2">IMÓVEL</h1>
                <h1 className="h1-border h1-3">CONTATO</h1>
                <h1 className="h1-border h1-4">STATUS</h1>
                <h1 className="h1-border h1-5">VALORES</h1>
                <h1 className="h1-border h1-6">AÇÕES</h1>
              </>
            )}
          </div>

          {data.map(
            (row, index) =>
              row.address !== "" && (
                <div key={row._id} className="box-header box-body">
                  <div className="table-row">
                    <div className="table-index border-div h1-1 divID">
                      {row._id}
                    </div>

                    {window.innerWidth <= 600 ? (
                      <div
                        style={{
                          overflow: "auto",
                          display: "-webkit-inline-box",
                          width: "82%",
                        }}
                      >
                        {innerRow(row, index)}
                      </div>
                    ) : (
                      <>{innerRow(row, index)}</>
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      </Container>
    </>
  );

}
