const parseHtml = (value) => {
  value = value.replace(/<style([\s\S]*?)<\/style>/gi, "");
  value = value.replace(/<script([\s\S]*?)<\/script>/gi, "");
  value = value.replace(/<\/div>/gi, "\n");
  value = value.replace(/<\/li>/gi, "\n");
  value = value.replace(/<li>/gi, "  *  ");
  value = value.replace(/<\/ul>/gi, "\n");
  value = value.replace(/<\/p>/gi, "\n");
  value = value.replace(/<br\s*[/]?>/gi, "\n");
  value = value.replace(/<[^>]+>/gi, "");
  return value;
};

export default parseHtml;