import React, { useState } from 'react'
import { Container, Card, Header, Button } from './style'
import Slider from "react-slick"
import Axios from 'axios';
import {  notification } from "antd";

export default function PhotosModal({ id, images,setModal }) {
    const [index, setIndex] = useState('0')
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    
    async function handleDownload(){
        console.log('id', id);
        try {
            Axios({
                url: `${process.env.REACT_APP_FILES_URL}/${id}/fotos/${images[index].image}`,
                method: 'GET',
                responseType: 'blob',
             }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                
                link.setAttribute('download', images[index].image);
                document.body.appendChild(link);
                link.click();
             })
        }catch (e) {
            notification.error({
                message: 'Erro ao tentar baixar a imagem'
              });
        }
    }

    return (
        <Container >
            <Header>
                <Button onClick={handleDownload} >Baixar Foto</Button>
                <h1 onClick={()=> setModal(false)} >&times;</h1>
            </Header>
            <Card>
                <Slider afterChange={e=>setIndex(e)} {...settings} className="slider">
                    {
                        images.map((value,index) => {
                            if(value.show == 'true' || value.show == undefined){
                                return (
                                    <img crossOrigin="anonymous" id={`imagem-download-${index}`} style={{objectFit: 'contain'}} key={index} src={`${process.env.REACT_APP_FILES_URL}/${id}/fotos/${encodeURIComponent(value.image)}`} height={500}  alt=""/>
                                    )
                            }
                    })
                    }
                </Slider>
            </Card>
        </Container>
    )
}
