import React, { useState, useEffect } from "react";
import { FooterBase } from "./styles";
import "../../global.css";
import { Link } from "react-router-dom";
import api from "../../api";
import {  notification } from "antd";

// Icons
import { SocialFacebookCircular as FacebookIcon } from "@styled-icons/typicons/SocialFacebookCircular";
import { Instagram } from "@styled-icons/fa-brands/Instagram"

// Components
import WppButton from "./WppButton";

function Footer() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [context, setContext] = useState("");
  const [menuActive, setMenuActive] = useState(true);

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("app")) {
      setMenuActive(false);
    }
  }, []);

  async function handleEmail() {
    if (name.length > 2 && email.length > 4) {
      const { data } = await api.post("/sendEmail", {
        content: `
                    <h2>Nome: ${name}</h2>
                    <h2>Email: ${email}</h2>
                    <h3>${context}</h3>
                `,
      });
      if (data) {
        notification.success({
          message: 'Sua mensagem foi enviada. Responderemos o mais breve possível'
        });
      }
    }
  }

  return (
    <>
      {menuActive ? (
        <>
          <hr
            style={{
              background: "#C3C8C9",
              marginLeft: "40px",
              marginRight: "40px",
            }}
          />

          <FooterBase>
            <div className="links">
              <div className="imovel">
                <h4>Imóvel Vazio</h4>
                <Link
                  onClick={(e) => window.scrollTo(0, 0)}
                  to="/ofertar-imovel"
                >
                  Compramos seu Imóvel
                </Link>
                <Link onClick={(e) => window.scrollTo(0, 0)} to="/imoveis">
                  Imóveis à Venda
                </Link>
                {/* <Link>Para Corretores </Link> */}
                <Link
                  onClick={(e) => window.scrollTo(0, 0)}
                  to="/correspondente-bancario"
                >
                  Correspondente Bancário
                </Link>
                <Link onClick={(e) => window.scrollTo(0, 0)} to="/sobre-nos">
                  Sobre Nós
                </Link>
                <a href="https://blog.imovelvazio.com.br/">Blog</a>
                <Link onClick={(e) => window.scrollTo(0, 0)} to="/perguntas-frequentes">
                  Perguntas frequentes
                </Link>
                {/* <div className="socials" style={{ marginTop: '20px' }}>
                                <a href="" style={{ marginRight: '20px' }}>
                                    <img src={require('../../assets/home/fbicon.svg')} alt="" />
                                </a>
                                <a href="" style={{ marginRight: '20px' }}>
                                    <img src={require('../../assets/home/linkedin.svg')} alt="" />
                                </a>
                                <a href="" style={{ marginRight: '20px' }}>
                                    <img src={require('../../assets/home/insta.svg')} alt="" />
                                </a>
                            </div> */}
              </div>
              <div className="politicas">
                <h4>Políticas</h4>
                <Link
                  onClick={(e) => window.scrollTo(0, 0)}
                  to="/politica-de-privacidade"
                >
                  Política de Privacidade
                </Link>
                <Link
                  onClick={(e) => window.scrollTo(0, 0)}
                  to="/politica-de-uso"
                >
                  Política de Uso do Corretor de Imóveis
                </Link>
                <Link
                  onClick={(e) => window.scrollTo(0, 0)}
                  to="/termos-e-condicoes"
                >
                  Termos e Condições de Uso Imóvel Vazio
                </Link>
                {/* <Link style={{color: "white", cursor: 'default'}}>.</Link>
                            <Link style={{color: "white", cursor: 'default'}}>.</Link> */}
              </div>
            </div>
            <div className="contact">
              <h4> Baixe o App da Imóvel Vazio </h4>
              <div
                className="downloads"
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <a
                  style={{ marginRight: window.innerWidth <= 600 ? 4 : 20 }}
                  className="advertise"
                  href="https://apps.apple.com/br/app/im%C3%B3vel-vazio/id1573642473?l=en"
                  target="_blank"
                >
                  <img
                    style={{
                      width:
                        window.innerWidth <= 600
                          ? window.innerWidth / 2 - 22
                          : "",
                      cursor: "pointer",
                    }}
                    src={require("../../assets/home/appstore.svg")}
                    alt=""
                  />
                </a>

                <a
                  className="advertise"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=br.com.iv.imovelvazio&hl=pt_BR&gl=US"
                >
                  <img
                    style={{
                      width:
                        window.innerWidth <= 600
                          ? window.innerWidth / 2 - 22
                          : "",
                      cursor: "pointer",
                    }}
                    src={require("../../assets/home/playstore.svg")}
                    alt=""
                  />
                </a>
              </div>
              <div>
                <h4> Redes Sociais </h4>
                <div className="social-box">
                  <a target="_blank" href="https://www.instagram.com/imovelvazioinvestimentos/">
                    <Instagram size={40} className="social-icons"/>
                  </a>
                  <a target="_blank" href="https://facebook.com/imovelvazio">
                    <FacebookIcon size={50} className="social-icons" />
                  </a>
                </div>
              </div>
            </div>
          </FooterBase>
        </>
      ) : (
        <></>
      )}
      <WppButton />
    </>
  );
}

export default Footer;
