import styled from 'styled-components'
import BgImage from './background-card.svg'
import BgCard from './background-card-footer.svg'

export const BgImg = styled.div`
    height:2250px;
    width:100vw;
    background: white;

    @media(max-width:600px){
        height: auto;
        min-height:2250px;
    }
`

export const FilterContainer = styled.div`
    width: 100vw;
    height: 48%;
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items:center;
    padding-top: 6%;

    .login-wrapper{
        background: #FFFFFF;
        border-radius: 8px;
        width: 100%;
        display:flex;
        flex-wrap:wrap;
        padding:20px;
        padding-right:none;
        justify-content: space-evenly;
        align-items: center;
        min-height: 98%;
        height: 98%;
        padding-top: 50px;

        .about-us{
            width: 55%;
            height: 100%;
            padding-left: 55px;
            padding-right: 56px;
            padding-top: 80px;
            

            h3{
                font-size: 40px;
                font-weight: 300;
            }

            h1{
                padding-top: 10px;
                font-size: 60px;

            }

            h5 {
                padding-top: 40px;
                font-size: 27px;
                font-weight: 300;
            }
        }
        
        .about-image{
            width: 45%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            padding-right: 65px;

            .img{
                height: 93%;
                width: 100%;
                border-radius: 15px;
                background : url(${BgImage});
                background-size:cover;
                background-position:left;
                background-repeat:no-repeat;
            }
        }
    }

    @media(max-width:600px){
        .login-wrapper{
            justify-content: flex-end;
            flex-flow: wrap-reverse;
        
            .about-us{
                width: 100%;
                padding-top: 50px;
                padding-left: 0px;
                padding-right: 0px;

                h3, h1{
                    font-size: 35px;
                }
            }
            .about-image{
                width: 100%;
                height: 200px;
                padding-right: 0px;
            }
        }
    }
`


export const MarketingContainer = styled.section`
    width:100vw;
    min-height: 35%;
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: #1E1D1D;

    p{
        margin-top:10px;
        font-weight: 300;
        font-size: 20px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.05em;

        color: #1E1D1D;
    }

    .images{
        display:flex;
        width:100%;
        justify-content:space-around;
        align-items:center;
        flex-wrap:wrap;
        padding-top: 30px;
        
    }
    @media(max-width:600px){
        h1{
            margin-top: 20px;
            font-size: 30px;
        }
        section{
            img:nth-child(2){ 
                margin-top: -150px;
            }
        }
    }
`
export const CardBottom = styled.div`
        height: 250px;
        width: 90%;
        background : url(${BgCard});
        background-size:cover;
        background-position:left;
        background-repeat:no-repeat;
        margin-left: 5%;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        h3{
            font-size: 36px;//40
            height: 40%;
            width: 60%;
            background: rgba(255, 255, 255, 0.6);
            backdrop-filter: blur(30px);
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            
            padding: 25px;
            height: auto;
            text-align: center;
        }

        @media(max-width:600px){
            height: 225px;
            margin-bottom: 20px;
            background-position:center;

            h3{
                height: 69%;
                width: 90%;
                text-align: center;
                font-size: 30px;
            }
        }
`

export const Bank = styled.div`
    width:100vw;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    margin-top:10vh;
    margin-bottom:10vh;

    h1{
        font-size: 36px;
        line-height: 41px;
        text-align: center;
        margin-bottom:20px;
        /* #1E1D1D */

        color: #1E1D1D;
    }

    p{
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
        /* or 139% */

        text-align: center;
        letter-spacing: 0.05em;
        margin-bottom:20px;
        color: #1E1D1D;
    }

    a{
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;

        color: #EA5C1E;
        padding:10px;
        padding-left:30px;
        padding-right:30px;
        text-decoration:none;
        border: 1px solid #EA5C1E;
        box-sizing: border-box;
        border-radius: 4px;
    }
`

