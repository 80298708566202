import React, { useEffect, useState } from "react";
import * as S from "./style";
import { ArrowRightOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import "../../../global.css";

export default function BtnPartnerBroker({ navbar }) {
    const [hoverClass, setHoverClass] = useState(false);

  return (
    <>
    <S.BtnPartnerBroker navbar={navbar}>
      <Link to="/register?origin=chegamos_em_bh" className="linkButton">
        <span>Quero Ser Corretor Parceiro</span>
        <ArrowRightOutlined className="icon-arrow" />
      </Link>
    </S.BtnPartnerBroker>
    </>
  );
}
