import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../global.css";
import "./style";
import Select from "react-select";
import api from "../../api";
import Slider from "@material-ui/core/Slider";
import InputMask from "react-input-mask";
import { notification } from "antd";

import {
  ModalContent,
  FormItem,
  ModalContainer,
  ModalTitle,
  ModalLayout,
  PrimaryButton,
} from "./style";
import { message } from "antd";
import { getUserId } from "../../utils/sessionHelper";

export default function Banner({ setModalProposta, data, setShowLoading, comission, setShowModalFeedback }) {
  const userId = getUserId();
  const [valorVista, setValorVista] = useState(0);
  const [valorFinanciado, setValorFinanciado] = useState(0);
  const [valorComissao, setValorComissao] = useState("");
  const [valorPermuta, setValorPermuta] = useState(0);

  function money(value) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  }

  useEffect(() => setValorComissao(moneyToNumber(comission)), [comission]);

  function moneyToNumber(value) {
    value = value
      .replaceAll("R", "")
      .replaceAll("$", "")
      .replaceAll(" ", "")
      .replaceAll(".", "")
      .replaceAll(",", "");
    value =
      value.substr(1, value.length - 3) +
      "." +
      value.substr(value.length - 2, 2);
    return value;
  }

  const handleSoma = () => {
    const vista = Number(valorVista);
    const financiado = Number(valorFinanciado);
    const permuta = Number(valorPermuta);
    const soma = vista + financiado + permuta;
    return money(soma);
  };

  const handleSubtract = () => {
    const comissao =
      valorComissao !== ""
        ? Number(valorComissao)
        : Number(
          (moneyToNumber(handleSoma()) / 100) *
          (data.comission ? data.comission : 6)
        );
    const vista = Number(valorVista);
    const financiado = Number(valorFinanciado);
    const permuta = Number(valorPermuta);
    const valorTotal = Number(vista + financiado + permuta);
    return valorTotal - comissao < 0 ? money(0) : money(valorTotal - comissao);
  };

  const handleRegister = () => {
    let passou = false;
    if (
      valorVista == "" ||
      valorVista == "." ||
      valorVista == ".00" ||
      valorVista == null ||
      valorVista == [] ||
      valorVista == "00.00"
    ) {
      setValorVista(0);
    } else {
      passou = true;
    }

    if (
      valorFinanciado == "" ||
      valorFinanciado == "." ||
      valorFinanciado == ".00" ||
      valorFinanciado == null ||
      valorFinanciado == [] ||
      valorFinanciado == "00.00"
    ) {
      setValorFinanciado(0);
    } else {
      passou = true;
    }

    if (passou) {
      handleSend();
    } else {
      let inputs = document.querySelectorAll("[required]");
      notification.error({
        message: 'Nenhum campo preenchido!',
      });
      Array.from(inputs).map((input) => {
        if (
          !input.querySelector("input").value ||
          input.querySelector("input").value === "R$ 0,00" ||
          input.querySelector("input").value === "0" ||
          input.querySelector("input").value === ".00" ||
          input.querySelector("input").value === 0
        ) {
          styleBorderError(input);
        }
      });
    }
  };

  const styleBorderError = (object) => {
    object.style.border = "2px solid red";
    setTimeout(() => {
      object.style.border = "none";
    }, 1000);
  };

  const getMoneyValue = (value) =>
    money(value).includes("NaN") ? money("0") : money(value);

  const handleSend = async () => {
    setShowLoading(true);
    try {
      const response = await api.post(`/proposalImovel/`, {
        Property: data._id,
        User: userId,
        createdAt:
          new Date().toLocaleDateString("pt-BR", { day: "2-digit" }) +
          "/" +
          new Date().toLocaleDateString("pt-BR", { month: "2-digit" }) +
          "/" +
          new Date().toLocaleDateString("pt-BR", { year: "numeric" }),
        valorVista: Number(valorVista),
        valorFinanciado: Number(valorFinanciado),
        valorPermuta: Number(valorPermuta),
        valorProposta: Number(moneyToNumber(handleSoma())),
        valorComissao:
          valorComissao !== ""
            ? Number(valorComissao)
            : Number(
              (moneyToNumber(handleSoma()) / 100) *
              (data.comission ? data.comission : 6)
            ),
        valorTotalMenosComissao: parseInt(moneyToNumber(handleSubtract())),
      });

      if (response.data._id) {
        setShowLoading(false);
        setModalProposta(false);
        localStorage.setItem('idProposal', response.data.Property)
        setShowModalFeedback(true);
      }
    } catch (err) {
      setShowLoading(false);
      if (err.response.data.imovel) {
        window.location.href =
          "/admin/propostas-corretor/editar?id=" + err.response.data.imovel;
      }

      if (err.response.data.error) {
        message.error(err.response.data.error);
      }
    }

    setShowLoading(false);
  };

  return (
    <>
      <div
        className=""
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#000",
          opacity: 0.3,
          position: "absolute",
          zIndex: 4,
        }}
      ></div>
      <ModalLayout className="contentModal">
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "8%" /* , borderBottom: '1px solid #ddd' */,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <h2
            style={{ width: "95%", display: "flex", justifyContent: "center" }}
          ></h2>
          <h4
            onClick={(item) => {
              setModalProposta(false) /* ; setConfigs(1) */;
              document.querySelector("body").style.overflow = "scroll";
              document.querySelector("body").style.position = "static";
            }}
            style={{
              color: "#EA5C1E",
              cursor: "pointer",
              padding: "5px",
              fontSize: "120%",
            }}
          >
            X
          </h4>
        </div>
        <ModalContainer>
          <ModalTitle>Enviar proposta para este Imóvel</ModalTitle>
          <ModalContent>
            <FormItem required>
              <label>Valor à Vista</label>
              <InputMask
                inputmode="numeric"
                type="text"
                id="valorVista"
                placeholder="Ex: R$ 1.200.000,00"
                value={getMoneyValue(valorVista)}
                onChange={(e) => {
                  if (e.target.value === "" || e.target.value.includes("NaN")) {
                    setValorVista(0);
                    return;
                  }
                  setValorVista(moneyToNumber(e.target.value));
                }}
              />
            </FormItem>

            <FormItem className="input-group-proposta" required>
              <label>Valor Financiado</label>
              <InputMask
                inputmode="numeric"
                type="text"
                id="valorFinanciado"
                placeholder="Ex: R$ 500.000,00"
                value={getMoneyValue(valorFinanciado)}
                onChange={(e) => {
                  if (e.target.value === "" || e.target.value.includes("NaN")) {
                    setValorFinanciado(0);
                    return;
                  }
                  setValorFinanciado(moneyToNumber(e.target.value));
                }}
              />
            </FormItem>

            {/* <FormItem className="input-group-proposta">
              <label>Total da Proposta</label>
              <p>{handleSoma().includes("NaN") ? money("0") : handleSoma()}</p>
            </FormItem> */}

            {/* <FormItem className="input-box">
              <label>Comissão</label>
              <InputMask
                inputmode="numeric"
                type="text"
                id="valorComissao"
                placeholder="Ex: R$ 500.000,00"
                value={
                  valorComissao !== ""
                    ? getMoneyValue(valorComissao)
                    : money(
                        (Number(valorVista) / 100) *
                          (data.comission ? data.comission : 6)
                      )
                }
                onChange={(e) => {
                  if (e.target.value === "") {
                    setValorComissao(0);
                    return;
                  }
                  setValorComissao(moneyToNumber(e.target.value));
                }}
              />
            </FormItem> */}

            {/* <FormItem className="input-box">
              <label>Valor da Permuta</label>
              <InputMask
                inputmode="numeric"
                type="text"
                id="valorPermuta"
                placeholder="Ex: R$ 500.000,00"
                value={getMoneyValue(valorPermuta)}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setValorPermuta(0);
                    return;
                  }
                  setValorPermuta(moneyToNumber(e.target.value));
                }}
              />
            </FormItem> */}

            {/* <FormItem className="input-box">
              <label>Total menos comissão</label>
              <p>
                {handleSubtract().includes("NaN")
                  ? money("0")
                  : handleSubtract()}
              </p>
            </FormItem> */}

            <PrimaryButton
              onClick={handleRegister}>Enviar</PrimaryButton>
            {/* <FormItem padding="0" className="send-item">
            </FormItem> */}
          </ModalContent>
        </ModalContainer>
      </ModalLayout>
    </>
  );
}
