import styled from 'styled-components'

export const BtnPartnerBroker = styled.div`
    height: 50px;
    display:flex;
    justify-content:center;
    align-items:center;

    a{
        text-decoration:none;
        color: ${props => props.navbar ? '#fff' : '#0088AC'};
        background-color: ${props => props.navbar ? '#0088AC' : '#fff'};
        box-sizing: border-box;
        border-radius: 8px;
        padding:12px;
        padding-left:25px;
        padding-right:25px;

        span {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        }

        .icon-arrow {
            position: relative;
            top: 2px;
            left: 8px;
        }

        &:hover, .linkButton {
            background-color: #006690;
            color: #fff;
        }
    }

    @media (min-width: 1101px) and (max-width: 1430px) {

      a {
        width: ${props => props.navbar && '100%' };
        padding: ${props => props.navbar && '10px' };
        padding-left: ${props => props.navbar && '10px' };
        padding-right: ${props => props.navbar && '10px' };
      }
    }

    @media (max-width: 1100px) {
      padding-bottom: 20px;
    }
`