import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { SECTION_TYPE, checkErrorStyle } from '../constants';
import { Field } from '../styles';
import TextArea from 'antd/es/input/TextArea';
import { Input } from 'antd';
const props = {
  prefix: '',
  types: [],
  index: 0,
  key: ''
};

const Topic = ({ prefix, types, index } = props) => {
  const {
    control,
    formState: { isSubmitted, },
  } = useFormContext();

  return <>
    {types.includes(SECTION_TYPE.topicTitle) &&
      <Controller
        control={control}
        name={`${prefix}.title`}
        rules={{
          required: `Descreva em poucas palavras`,
          maxLength: {
            value: 30,
            message: 'Máximo de 30 caracteres',
          },
        }}
        render={({ field, fieldState: {
          invalid, isDirty, isTouched, error,
        }, }) => (
          <Field {...checkErrorStyle({
            invalid, isDirty, isTouched, isSubmitted, error,
          })} className="input-box">
            <small>Tópico {index + 1}</small>
            <Input {...field} maxLength={30} />
          </Field>
        )}
      />

    }
    {types.includes(SECTION_TYPE.topicDescription) &&
      <Controller
        control={control}
        name={`${prefix}.description`}
        rules={{
          required: `Insira a descrição do tópico`,
          maxLength: {
            value: 180,
            message: 'Máximo de 180 caracteres',
          },
        }}
        render={({ field, fieldState: {
          invalid, isDirty, isTouched, error,
        }, }) => (
          <Field {...checkErrorStyle({
            invalid, isDirty, isTouched, isSubmitted, error,
          })} className="input-box">
            <small>Descrição</small>
            <TextArea {...field} rows={2} maxLength={180} />
          </Field>
        )}
      />

    }
  </>
};

export default Topic;