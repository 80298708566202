import api from "../api";
import queryString from "../utils/queryString";

export const getAlreadyRegisteredAsync = ({ cpf = '', creci = '', email = '' }) => {
  const request = queryString({ cpf, creci, email });
  return api.get(`/user/validate/already-registered/${request}`);

};

export const requesterValidateSmsAsync = async (telephone) => {
  const { data } = await api.post('/user//validate/sms', { telephone });
  return data;
};

export const requesterCodeVerificationAsync = async ({ telephone = '', verificationCode = '' }) => {
  const { data } = await api.patch('/user/validate/code-verification', { telephone, verificationCode });
  return data;
};

export const registerRealtor = async ({
  password,
  email,
  telephone,
  name,
  cpf,
  creci,
  receiveEmail,
  registrationOrigin,
  verificationCode,
}) => {
  const { data } = await api.post('/user/', {
    password,
    email,
    telephone,
    name,
    cpf,
    creci,
    receiveEmail,
    verificationCode,
    registrationOrigin,
    type: 'agent',
  })
  return data;
};

export const getUsers = async ({limit = 9999, ...rest }, cancelToken) => {
  const request = queryString({...rest, limit});
  const { data } = await api.get(`/user/${request}`);
  return data;
}

export const getCsv = async ({limit = 9999, ...rest }, cancelToken) => {
  const request = queryString({...rest, limit});
  const { data } = await api.get(`/user/download/csv/${request}`).then();
  return data;
}