import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import {
  Body,
  Menu,
  ItemsContainer,
  Tag,
  TagPartnerProperties,
  Favorite,
  OurProperty,
  Description,
  ItemHeader,
  SeeMore,
  ContentFilter,
  ContentFilterSalePrice,
  ContentSelectedIPTU,
  ContentSelectNeighborhood,
  ContentSelectModel,
  ContentSelectConfig,
  StyledSlider,
  Divider,
} from "./styles";
import { getCitiesAsync } from "../../services/city.service";
import { getPropertiesType } from "../../services/getPropertiesTypes";
import moment from "moment";
import SideFilter from "../../components/Filter/SideFilter";
import OrderComponent from "./OrderComponent";
import { useLocation, useHistory } from "react-router-dom";
import api from "../../api";
import { useQuery } from "../../hooks/query";
import { getPropertiesAsync } from "../../services/property.service.js";
import ModalApp from "../../components/ModalApp";
import BadgeFilter from "../../components/BadgeFilter/Badge";

// import Select from "react-select";
import { Pagination, Select } from "antd";
import CurrencyInput from "react-currency-input-field";
import ContainerSearch from "../../assets/containerSearch.svg";
import { getUserSession } from "../../utils/sessionHelper/index.js";

const showCatalog = true;
const limit = 12;

function PropertiesList() {
  const user = getUserSession();

  const [pagination, setPagination] = useState({
    total: undefined,
    limit,
    pages: 0,
    currentPage: 0,
    prevPage: null,
    nextPage: 0,
  });
  const [selectedCityTotal, setSelectedCityTotal] = useState(0);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityUf, setSelectedCityUf] = useState("");
  const [verification, setVerification] = useState(true);

  const [dataOrder, setDataOrder] = useState([]);
  const [typeProperties, setPropertiesType] = useState([]);

  const [data, setData] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [scrollY, setScrollY] = useState();
  const [page, setPage] = useState(1);
  const [reff, setReff] = useState(false);
  const [showModalApp, setShowModalApp] = useState(true);

  const location = useLocation();
  const locationId = location.search.split("=");

  const queryParams = useQuery();
  const cityId = queryParams.get("city_id") ? queryParams.get("city_id") : "";

  const history = useHistory();

  const [cities, setCities] = useState([{}]);
  const [precoMinimo, setPrecoMinimo] = useState({
    value: 1000,
    label: "1.000",
  });
  const [precoMaximo, setPrecoMaximo] = useState({
    value: 10000000,
    label: "10.000.000",
  });
  // const [apart, setApart] = useState(false);
  // const [casa, setCasa] = useState(false);
  // const [condo, setCondom] = useState(false);
  // const [vila, setVila] = useState(false);
  // const [cobertura, setCobertura] = useState(false);
  // const [flat, setFlat] = useState(false);
  // const [loft, setLoft] = useState(false);
  const [tipo, setTipo] = useState("");
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [selectedNeighborhood, setSelectedNeighborhood] = useState("");
  const [citySelected, setCitySelected] = useState(cityId);
  const [selectPropertyType, setSelectPropertyType] = useState("");
  const [selectStatusProperty, setSelectStatusProperty] = useState("");

  const [quarto, setQuarto] = useState("");
  const [banheiros, setBanheiros] = useState("");
  const [vagas, setVagas] = useState("");

  const defaultOption = [{ value: "", label: "Qualquer" }];

  const generalTypeData = [
    ...defaultOption,
    { value: 2372820374682508, label: "Residencial" },
    { value: 1644602035863502, label: "Comercial" },
  ];

  const statusPropertyData = [
    ...defaultOption,
    { value: 1, label: "À Venda" },
    { value: 2, label: "Vendido" },
  ];

  const bedroomsData = [
    ...defaultOption,
    { value: 1, label: "1 Quarto" },
    { value: 2, label: "2 Quartos" },
    { value: 3, label: "3 Quartos" },
    { value: 4, label: "4 Quartos ou mais" },
  ];

  const bathroomsData = [
    ...defaultOption,
    { value: 1, label: "1 Banheiro" },
    { value: 2, label: "2 Banheiros" },
    { value: 3, label: "3 Banheiros" },
    { value: 4, label: "4 Banheiros ou mais" },
  ];

  const parkingSpotsData = [
    ...defaultOption,
    { value: 1, label: "1 Vaga" },
    { value: 2, label: "2 Vagas" },
    { value: 3, label: "3 Vagas" },
    { value: 4, label: "4 Vagas ou mais" },
  ];

  const defaultMinPrice = 1000;
  const defaultMaxPrice = 10000000;

  const getObjectByQueryParams = () => {
    const filter = {};
    queryParams
      .toString()
      .split("&")
      .map((value) => value.split("=").reduce((p, c) => {
        filter[p] = c;
      }));
    return filter;
  };

  const [filterParams, setFilterParams] = useState(getObjectByQueryParams());

  useEffect(() => {
    setFilterParams(getObjectByQueryParams());
  }, [history.location]);

  const handleFixedPriceFilter = (newValue) => {
    setPrecoMinimo({
      value: newValue[0],
      label: newValue[0].toLocaleString("pt-BR"),
    });
    setPrecoMaximo({
      value: newValue[1],
      label: newValue[1].toLocaleString("pt-BR"),
    });
  };

  const fillCities = () => {
    getCitiesAsync({
      orderBy: "city",
      direction: "ASC",
      showCatalog: true,
      arquive: false,
      sold: false,
    }).then((res) => {
      let options = [];
      res.data.map((item) => {
        options.push({
          key: item.value,
          value: item.value,
          label: `${item.city}, ${item.uf}`,
        });
      });
      setCities(options);
    });
  };

  const fillNeighborhoods = () => {
    if (citySelected) {
      api
        .get(
          `neighborhood/${citySelected}/with-ads?showcatalog=${true}&sold=${false}`
        )
        .then((res) => {
          let options = [];
          res.data.data.map((item) => {
            options.push({ value: item._id, label: item.label });
          });
          setNeighborhoods(options);
        });
    }
  };

  function cleanFieldsFilter() {
    setCitySelected("");
    handleFixedPriceFilter([defaultMinPrice, defaultMaxPrice]);
    setSelectedNeighborhood("");
    setTipo("");
    setSelectPropertyType("");
    setSelectStatusProperty("");
    setQuarto("");
    setVagas("");
    setBanheiros("");
    setCitySelected("");
  }

  async function handleClearFilter() {
    cleanFieldsFilter();
    const cleanUrl = `/imoveis?sellingPriceMin=${defaultMinPrice}&sellingPrice=${defaultMaxPrice}`;
    history.push(cleanUrl);
    if (page === 1) {
      await handleFilterQuery(cleanUrl);
    } else {
      setPage(1);
    }
    closeModalFilter();
  }

  function handleSetPage(page) {
    setPage(page);
    window.scrollTo({
      top: 0,
    });
  }

  async function handleFilter() {
    let precoMin = String(precoMinimo.label);
    let precoMax = String(precoMaximo.label);
    setPage(1);

    let query = `?showCatalog=true&page=${page}&limit=${limit}&sellingPriceMin=${precoMin.replace(
      /[^\d]+/g,
      ""
    )}&sellingPrice=${precoMax.replace(/[^\d]+/g, "")}&arquive=false`;

    if (Number(selectStatusProperty) === 1) {
      query += "&sold=false";
    }

    if (Number(selectStatusProperty) === 2) {
      query += "&sold=true";
    }

    if (citySelected) {
      query += `&city_id=${citySelected}`;
    }

    if (selectedNeighborhood) {
      query += `&neighborhood_id=${selectedNeighborhood}`;
    }

    if (tipo) {
      query += `&generalType=${tipo}`;
    }

    if (selectPropertyType) {
      query += `&type=${selectPropertyType}`;
    }

    if (quarto) {
      query += `&bedrooms=${quarto}`;
    }

    if (banheiros) {
      query += `&bathrooms=${banheiros}`;
    }
    if (vagas) {
      query += `&parkingSpots=${vagas}`;
    }

    history.push({ ...history.location, search: query });

    closeModalFilter();

    let { data } = await api.get(`/property${query}`);
    setData(data.propertys);
    const { total, pages, currentPage, prevPage, nextPage } = data;
    setPagination({ total, pages, limit, currentPage, prevPage, nextPage });
  }

  async function getAsyncItemsPropertiesType() {
    await getPropertiesType().then(({ data }) => {
      setPropertiesType(data.propertyTypes);
    });

    document.querySelector("body").style.overflow = "scroll";
  }

  useEffect(() => {
    if (reff == true) {
      // backLocation();
    } else {
      setReff(true);
    }
  }, [data]);

  useEffect(() => {
    fillNeighborhoods();
  }, [citySelected]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fillCities();
    getCitiesAsync({
      orderBy: "total",
      direction: "DESC",
      showCatalog: true,
      arquive: false,
      sold: false,
    }).then((res) => {
      res.data.find((item) => {
        const locationId = location.search.split("=");
        if (item.value === Number(filterParams.city_id)) {
          const { total, city, uf } = item;
          setSelectedCityTotal(total);
          setSelectedCity(city);
          setSelectedCityUf(uf);
        }
      });
    });

    getAsyncItemsPropertiesType();
  }, []);

  function changeSellingPriceMin(input = "1000") {
    let value = Number(input);
    if (value < 1000) value = 1000;
    setPrecoMinimo({
      value: value,
      label: String(value).toLocaleString("pt-BR"),
    });
  }

  function changeSellingPriceMax(input = "10000000") {
    let value = Number(input);
    if (value > 10000000) value = 10000000;
    setPrecoMaximo({
      value: value,
      label: String(value).toLocaleString("pt-BR"),
    });
  }

  function changeSelectStatusPropertyQuery(status) {
    if (!status) return "";

    if (status === "true") {
      setSelectStatusProperty("2");
    } else {
      setSelectStatusProperty("1");
    }
  }

  useEffect(() => {
    saveFilterQuery();
  }, []);

  function saveFilterQuery() {
    const filterParams = getObjectByQueryParams();
    changeSellingPriceMin(filterParams.sellingPriceMin);
    changeSellingPriceMax(filterParams.sellingPrice);
    setSelectedNeighborhood(filterParams.neighborhood_id);
    setTipo(filterParams.generalType);
    setSelectPropertyType(filterParams.type);
    changeSelectStatusPropertyQuery(filterParams.sold);
    setQuarto(filterParams.bedrooms);
    setBanheiros(filterParams.bathrooms);
    setVagas(filterParams.parkingSpots);
  }

  // function backLocation() {
  //   if (document.querySelector("#scriptBack")) {
  //     document
  //       .querySelector("body")
  //       .removeChild(document.querySelector("#scriptBack"));
  //   }
  //   let script = document.createElement("script");
  //   script.id = "scriptBack";

  //   script.innerHTML = `
  //     var posicao = localStorage.getItem('tela');
  //     if(posicao) {
  //       setTimeout(function() {
  //           window.scrollTo(0, posicao);
  //       }, 1);
  //     }
  //     if(document.querySelector('#gambi').innerText == 'Imóveis à Venda'){
  //         window.onscroll = function (e) {
  //           posicao = window.scrollY;
  //           localStorage.setItem('tela', JSON.stringify(posicao));
  //       }
  //     }else{
  //       window.onscroll = (e) => {}
  //     }
  //   `;
  //   document.querySelector("body").appendChild(script);
  // }

  function validateShowSoldQuery() {
    if (
      precoMinimo.value !== 1000 ||
      precoMaximo.value !== 10000000 ||
      selectedNeighborhood ||
      tipo ||
      selectPropertyType ||
      quarto ||
      banheiros ||
      vagas
    ) {
      return "&sold=false";
    }

    return "";
  }

  async function fetchData(filterModal) {
    if (localStorage.getItem("id")) {
      api.get(`/user/access/${localStorage.getItem("id")}`);
    }
    const response = await api.get(
      `/property?city_id=${citySelected}&showCatalog=true&page=${page}&arquive=false&limit=${limit}`
    );
    const { total, pages, currentPage, prevPage, nextPage } = response.data;
    setPagination({ total, pages, limit, currentPage, prevPage, nextPage });

    var newData = response.data.propertys;

    // if (filterModal) {
    //   var newData = response.data.propertys;
    // } else {
    //   var newData = data.concat(response.data.propertys);
    // }

    newData.sort((a, b) => {
      const aAux = a?.soldDate?.split("/").reverse().join("");
      const bAux = b?.soldDate?.split("/").reverse().join("");
      if (!bAux) return -1;
      return aAux < bAux ? 1 : aAux > bAux ? -1 : 0;
    });

    // newData.sort((a, b) => (a.sold === b.sold ? 1 : -1));
    // newData.sort((a, b) => (a.orderPageList < b.orderPageList ? 1 : -1));

    newData.map((obj) => {
      obj.images.sort((a, b) => {
        if (a["order"] > b["order"]) {
          return 1;
        } else {
          return -1;
        }
      });
      return obj;
    });

    newData.sort((a, b) => {
      if (!a.sold) {
        if (a["orderPageList"] < b["orderPageList"]) {
          return 1;
        } else if (a["orderPageList"] == undefined) {
          return 1;
        } else if (b.sold) {
          return -1;
        } else {
          return -1;
        }
      } else {
        return 1;
      }
    });

    setData(newData);
  }

  function filterTag(value) {
    if (value != undefined) {
      if (Date.parse(value.createdAt)) {
        const date = new Date(value.createdAt);

        const createdAt = moment(date).diff(moment(Date.now()));

        const age = moment.duration(createdAt);

        let datase =
          value.createdAt.split(" ")[0].split("/")[2] +
          "-" +
          value.createdAt.split(" ")[0].split("/")[1] +
          "-" +
          value.createdAt.split(" ")[0].split("/")[0];

        var dataBanco = new Date(datase);

        if (isNaN(dataBanco.getTime())) {
          dataBanco = new Date("2020", "01", "01");
        }
        var date2 = new Date();

        var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (value.sold) {
          value.tag = {
            name: "VENDIDO",
            color: "#FF0000",
          };

          return null;
        }

        if (value.showTag && value.tag) {
          value.tag = {
            ...value.tag,
            color: "#00AEC8",
          };
          return null;
        }

        if (diffDays <= 2) {
          value.tag = {
            name: "NOVIDADE",
            color: "#1C9C40",
          };

          return null;
        }

        if (
          value.descountPrice &&
          value.descountPrice != value.sellingPrice &&
          value.descountPrice < value.sellingPrice
        ) {
          value.tag = {
            name:
              "DESCONTO DE " +
              Math.abs(
                Math.floor(
                  (value.descountPrice / value.sellingPrice) * 100 - 100
                )
              ).toString() +
              "%",
            color: "#EA1E1E",
          };

          return null;
        }

        value.tag = {
          ...value.tag,
          color: "#00AEC8",
        };

        if (value.tag) {
          if (!value.tag.name) {
            value.tag.color = "transparent";
            value.tag.name = "";
            return null;
          }
        }
      } else {
        const createdAt = moment(value.createdAt, "DD/MM/YYYY HH:mm").diff(
          moment(Date.now()),
          "days"
        );

        let datase =
          value.createdAt.split(" ")[0].split("/")[2] +
          "-" +
          value.createdAt.split(" ")[0].split("/")[1] +
          "-" +
          value.createdAt.split(" ")[0].split("/")[0];

        var dataBanco = new Date(datase);

        if (isNaN(dataBanco.getTime())) {
          dataBanco = new Date("2020", "01", "01");
        }
        var date2 = new Date();

        var timeDiff = Math.abs(date2.getTime() - dataBanco.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (value.sold) {
          value.tag = {
            name: "VENDIDO",
            color: "#FF0000",
          };
          return null;
        }

        if (value.showTag && value.tag) {
          value.tag = {
            ...value.tag,
            color: "#00AEC8",
          };
          return null;
        }

        if (diffDays <= 2) {
          value.tag = {
            name: "NOVIDADE",
            color: "#1C9C40",
          };

          return null;
        }

        if (
          value.descountPrice &&
          value.descountPrice != value.sellingPrice &&
          value.descountPrice < value.sellingPrice
        ) {
          value.tag = {
            name:
              "DESCONTO DE " +
              Math.abs(
                Math.floor(
                  (value.descountPrice / value.sellingPrice) * 100 - 100
                )
              ).toString() +
              "%",
            color: "#EA1E1E",
          };

          return null;
        }

        value.tag = {
          ...value.tag,
          color: "#00AEC8",
        };

        if (value.tag) {
          if (!value.tag.name) {
            value.tag.color = "transparent";
            value.tag.name = "";
            return null;
          }
        }
      }
    }
  }

  useEffect(() => {
    const filter = getObjectByQueryParams();
    handleFilterQuery(filter);
  }, [page]);

  useEffect(() => {
    if (window.scrollY != 0 && scrollY < window.scrollY) {
      window.scrollTo(0, window.scrollY - (window.scrollY - scrollY));
    }
  }, [data]);

  async function handleFilterQuery(filter) {
    const ranking = citySelected ? { orderCity: -1 } : { orderPageList: -1 };
    console.log(ranking);
    let { data } = await getPropertiesAsync({
      showCatalog,
      limit,
      orderby: ranking,
      ...filter,
      page,
    }, !!user);
    setData(data.propertys);
    const { total, pages, currentPage, prevPage, nextPage } = data;
    setPagination({ total, pages, limit, currentPage, prevPage, nextPage });
  }

  async function handleOrder(order) {
    const filter = getObjectByQueryParams();
    setDataOrder([]);
    const ranking = citySelected ? { orderCity: -1 } : { orderPageList: -1 };
    switch (order) {
      case "":
        //
        var response = await getPropertiesAsync({
          showCatalog,
          limit,
          orderby: ranking,
          ...filter,
        }, !!user);
        setVerification(true);
        setData(response.data.propertys);
        break;
      case "recent":
        var response = await getPropertiesAsync({
          showCatalog,
          limit,
          orderby: { sold: 1, _id: -1 },
          ...filter,
        }, !!user);
        setVerification(true);
        setData(response.data.propertys);
        break;

      case "antigo":
        var response = await getPropertiesAsync({
          showCatalog,
          limit,
          orderby: { sold: 1, _id: 1 },
          ...filter,
        }, !!user);
        setVerification(true);
        setData(response.data.propertys);
        break;
      case "maior-menor":
        var response = await getPropertiesAsync({
          showCatalog,
          limit,
          orderby: { sold: 1, sellingPrice: -1 },
          ...filter,
        }, !!user);
        setVerification(true);
        setData(response.data.propertys);
        break;

      case "menor-maior":
        var response = await getPropertiesAsync({
          showCatalog,
          limit,
          orderby: { sold: 1, sellingPrice: 1 },
          ...filter,
        }, !!user);
        setVerification(true);
        setData(response.data.propertys);
        break;

      case "me-maior-menor":
        var response = await getPropertiesAsync({
          showCatalog,
          limit,
          orderby: { sold: 1, fullLandSize: -1 },
          ...filter,
        }, !!user);
        setVerification(true);
        setData(response.data.propertys);
        break;

      case "me-menor-maior":
        var response = await getPropertiesAsync({
          showCatalog,
          limit,
          orderby: { sold: 1, fullLandSize: 1 },
          ...filter,
        }, !!user);
        setVerification(true);
        setData(response.data.propertys);
        break;

      case "a-z":
        var { data: response } = await getPropertiesAsync({
          showCatalog,
          limit,
          orderby: ranking,
          ...filter,
        }, !!user);
        var sortDatas = response.propertys.sort(function (a, b) {
          if (!a.sold) {
            var textA = "";
            var textB = "";
            if (a.neighborhood && b.neighborhood) {
              textA = a.neighborhood?.name?.toUpperCase();
              textB = b.neighborhood?.name?.toUpperCase();
            }

            return textA.localeCompare(textB);
          } else {
            return 1;
          }
        });

        if (!verification) {
          setData(sortDatas);
          setVerification(true);
        } else {
          setDataOrder(sortDatas);
          setVerification(false);
        }
        break;

      case "z-a":
        var { data: response } = await getPropertiesAsync({
          showCatalog,
          limit,
          orderby: { orderCity: -1 },
          ...filter,
        }, !!user);
        var sortDatas = response.propertys
          .sort(function (a, b) {
            var textA = "";
            var textB = "";
            if (!a.sold) {
              if (a.neighborhood && b.neighborhood) {
                textA = a.neighborhood?.name?.toUpperCase();
                textB = b.neighborhood?.name?.toUpperCase();
              }

              return textA.localeCompare(textB);
            } else {
              if (a.neighborhood && b.neighborhood) {
                textA = a.neighborhood?.name?.toUpperCase();
                textB = b.neighborhood?.name?.toUpperCase();
              }
              return b.sold ? textA.localeCompare(textB) : -1;
            }
          })
          .reverse();

        if (!verification) {
          setData(sortDatas);
          setVerification(true);
        } else {
          setDataOrder(sortDatas);
          setVerification(false);
        }
        break;
    }
  }

  function HeadImage(value, index) {
    let ImagesTrue = [];
    for (let i = 0; i < value.images.length; i++) {
      if (value.images[i].show != "false") {
        ImagesTrue.push(value.images[i]);
      }
    }

    return (
      <ItemHeader
        image={
          ImagesTrue[0]
            ? `'${process.env.REACT_APP_FILES_URL}/${value._id}/fotos/${ImagesTrue[0].image}'`
            : ""
        }
        style={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" }}
      >
        {filterTag(value)}
        {/* value.tag.name == 'VENDIDO'? {width: '120%', top: '38%', left: '-10%', height: '60px', transform: 'rotate(29deg)'}:{} */}

        {value.isPartnerProperty === true && (
          <TagPartnerProperties>
            <h6>Parceiro Imóvel Vazio</h6>
          </TagPartnerProperties>
        )}
        {value.tag !== null ? (
          value.tag.name == "VENDIDO" ? (
            <Tag
              background={value.tag ? value.tag.color : ""}
              style={{
                width: "120%",
                top: "38%",
                left: "-10%",
                height: "60px",
                transform: "rotate(29deg)",
              }}
            >
              <h6 style={{ fontSize: 23 }}>
                {value.tag ? value.tag.name : ""}
              </h6>
            </Tag>
          ) : (
            <Tag background={value.tag ? value.tag.color : ""}>
              <h6>{value.tag ? value.tag.name : ""}</h6>
            </Tag>
          )
        ) : (
          ""
        )}

        {/* <Favorite /> */}

        {/* <OurProperty >
          <h6>
            {
              value.owner ? 'PROPRIEDADE IMÓVEL VAZIO' : null
            }
          </h6>
        </OurProperty> */}
      </ItemHeader>
    );
  }

  const [deleteBadge, setDeleteBadge] = useState(null);

  useEffect(() => {
    if (deleteBadge) {
      handleFilter();
    }
  }, [deleteBadge]);

  function deleteBadgeCity() {
    setCitySelected("");
    setDeleteBadge(new Date());
  }

  function deleteBadgeSellingPrice() {
    setPrecoMinimo({
      value: 1000,
      label: "1.000",
    });
    setPrecoMaximo({
      value: 10000000,
      label: "10.000.000",
    });
    setDeleteBadge(new Date());
  }

  function deleteBadgeNeighborhood() {
    setSelectedNeighborhood("");
    setDeleteBadge(new Date());
  }

  function deleteBadgeStatusProperty() {
    changeSelectStatusPropertyQuery("");
    setDeleteBadge(new Date());
  }

  function deleteBadgeGeneralType() {
    setTipo("");
    setDeleteBadge(new Date());
  }

  function deleteBadgePropertyType() {
    setSelectPropertyType("");
    setDeleteBadge(new Date());
  }

  function deleteBadgeStatusProperty() {
    setSelectStatusProperty("");
    setDeleteBadge(new Date());
  }

  function deleteBadgeBedrooms() {
    setQuarto("");
    setDeleteBadge(new Date());
  }

  function deleteBadgeBathrooms() {
    setBanheiros("");
    setDeleteBadge(new Date());
  }

  function deleteBadgeParkingSpots() {
    setVagas("");
    setDeleteBadge(new Date());
  }

  const validateHasFilter = () => {
    const params = getObjectByQueryParams();
    delete params.arquive;
    delete params.showCatalog;
    delete params.page;
    delete params.limit;
    if (params.sellingPriceMin === "1000") delete params.sellingPriceMin;
    if (params.sellingPrice === "10000000") delete params.sellingPrice;

    if (Object.keys(params).length) return true;

    return false;
  };

  const getLabelSaleStatus = () => {

    return filterParams.sold === "false" ? ` À venda` : ` Vendido`;
  };

  const validateShowBadgeSellingPrice = () => {
    const params = getObjectByQueryParams();
    if (!params.sellingPriceMin || !params.sellingPrice) {
      return false;
    }
    if (
      Number(params.sellingPriceMin) === 1000 &&
      Number(params.sellingPrice) === 10000000
    ) {
      return false;
    }

    return true;
  };

  const getLabelCityData = () => {
    const itemCity = cities.find(
      (item) => item.key === Number(filterParams.city_id)
    );

    return itemCity?.label;
  };

  const getLabelSellingPrice = () => {
    if (precoMinimo.value > 1000 && precoMaximo.value === 10000000) {
      return `+ R$ ${String(filterParams.sellingPriceMin).toLocaleString(
        "pt-BR"
      )}`;
    }

    if (precoMaximo.value < 10000000 && precoMinimo.value === 1000) {
      return `até R$ ${precoMaximo.label.toLocaleString("pt-BR")}`;
    }

    return `R$${precoMinimo.label.toLocaleString(
      "pt-BR"
    )} - R$${precoMaximo.label.toLocaleString("pt-BR")}`;
  };

  const getLabelPropertiesTypeData = () => {
    const itemPropertyType = typeProperties.find(
      (item) => item._id === Number(filterParams.type)
    );

    return itemPropertyType?.name;
  };

  const getLabelNeighborhoodData = () => {
    const itemNeighborhood = neighborhoods.find(
      (item) => item.value === Number(filterParams.neighborhood_id)
    );

    return itemNeighborhood?.label;
  };

  const getLabelGeneralTypeData = () => {
    const itemGeneralType = generalTypeData.find(
      (item) => item.value === Number(filterParams.generalType)
    );

    return itemGeneralType.label;
  };

  const getLabelBedroomsData = () => {
    const itemBedroom = bedroomsData.find(
      (item) => item.value === Number(filterParams.bedrooms)
    );

    return itemBedroom?.label;
  };

  const getLabelBathroomsData = () => {
    const itemBathroom = bathroomsData.find(
      (item) => item.value === Number(filterParams.bathrooms)
    );

    return itemBathroom.label;
  };

  const getLabelParkingSpotsData = () => {
    const itemParkingSpots = parkingSpotsData.find(
      (item) => item.value === Number(filterParams.parkingSpots)
    );

    return itemParkingSpots.label;
  };

  function closeModalFilter() {
    setShowModalFilter(false);
    document.querySelector("body").style.overflow = "scroll";
  }

  return (
    <>
      {showModalFilter && (
        <SideFilter
          handleClearFilter={handleClearFilter}
          handleFilter={handleFilter}
          closeModalFilter={closeModalFilter}
        >
          <ContentFilter>
            <ContentSelectModel>
              <div>
                <span className="title-model">Localização</span>
              </div>

              <div className="content-select-model">
                <Select
                  size="large"
                  placeholder="Cidade"
                  showSearch
                  getPopupContainer={(trigger) => trigger.parentNode}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  optionFilterProp="children"
                  value={Number(citySelected) ? Number(citySelected) : ""}
                  onChange={(item) => setCitySelected(item)}
                  options={[...defaultOption, ...cities]}
                />
              </div>
            </ContentSelectModel>
            <Divider />
            <ContentSelectNeighborhood>
              <div>
                <span
                  className="
              title-neighborhood"
                >
                  Bairros
                </span>
              </div>

              <div className="content-select-neighborhood">
                <Select
                  size="large"
                  showSearch
                  getPopupContainer={(trigger) => trigger.parentNode}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  optionFilterProp="children"
                  placeholder="Selecione o Bairro"
                  disabled={!citySelected}
                  value={
                    Number(selectedNeighborhood)
                      ? Number(selectedNeighborhood)
                      : ""
                  }
                  onChange={(item) => setSelectedNeighborhood(item)}
                  options={[...defaultOption, ...neighborhoods]}
                />
              </div>
            </ContentSelectNeighborhood>

            <Divider />

            <ContentFilterSalePrice>
              <span className="title-price">Preço de venda</span>
              <div className="content-slider">
                <StyledSlider
                  range
                  min={1000}
                  max={10000000}
                  tooltip={{ open: false }}
                  value={[precoMinimo.value, precoMaximo.value]}
                  defaultValue={[precoMinimo.value, precoMaximo.value]}
                  onChange={handleFixedPriceFilter}
                />
              </div>
              <div className="content-inputs-price">
                <div>
                  <label for="minimumPrice">Preço min.</label>
                  <CurrencyInput
                    prefix="R$"
                    id="minimumPrice"
                    name="minimumPrice"
                    value={precoMinimo.value}
                    defaultValue={precoMinimo.value}
                    onValueChange={(price) => {
                      setPrecoMinimo({
                        value: price,
                        label: price.toLocaleString("pt-BR"),
                      });
                    }}
                  />
                </div>
                <div>
                  <label for="maximumPrice">Preço max.</label>
                  <CurrencyInput
                    prefix="R$"
                    id="maximumPrice"
                    name="maximumPrice"
                    value={precoMaximo.value}
                    defaultValue={precoMaximo.value}
                    onValueChange={(price) => {
                      setPrecoMaximo({
                        value: price,
                        label: price.toLocaleString("pt-BR"),
                      });
                    }}
                  />
                </div>
              </div>
            </ContentFilterSalePrice>
            <Divider />

            <Divider />
            <ContentSelectModel>
              <div>
                <span className="title-model">Modelo</span>
              </div>

              <div className="content-select-model">
                <Select
                  size="large"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placeholder="Residencial ou Comercial"
                  value={Number(tipo) ? Number(tipo) : ""}
                  onChange={(item) => setTipo(item)}
                  options={generalTypeData}
                />
              </div>
            </ContentSelectModel>
            <Divider />

            <ContentSelectModel>
              <div>
                <span className="title-model">Tipo do Imóvel</span>
              </div>

              <div className="content-select-model">
                <Select
                  size="large"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placeholder="Selecione um tipo do imóvel"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.name ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  optionFilterProp="children"
                  value={
                    Number(selectPropertyType) ? Number(selectPropertyType) : ""
                  }
                  onChange={(item) => setSelectPropertyType(item)}
                  options={[
                    ...defaultOption,
                    ...typeProperties.map((type) => ({
                      label: type.name,
                      value: type._id,
                    })),
                  ]}
                />
              </div>
            </ContentSelectModel>
            <Divider />

            <ContentSelectModel>
              <div>
                <span className="title-model">Status do imóvel</span>
              </div>

              <div className="content-select-model">
                <Select
                  size="large"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placeholder="Selecione um status do imóvel"
                  value={
                    Number(selectStatusProperty)
                      ? Number(selectStatusProperty)
                      : ""
                  }
                  onChange={(item) => setSelectStatusProperty(item)}
                  options={statusPropertyData}
                />
              </div>
            </ContentSelectModel>
            <Divider />

            <ContentSelectConfig>
              <div>
                <span className="title">Quartos</span>
              </div>

              <div className="content-select">
                <Select
                  size="large"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placeholder="Quartos"
                  className="select"
                  value={Number(quarto) ? Number(quarto) : ""}
                  onChange={(item) => setQuarto(item)}
                  options={bedroomsData}
                />
              </div>
            </ContentSelectConfig>
            <Divider />

            <ContentSelectConfig>
              <div>
                <span className="title">Banheiro</span>
              </div>

              <div className="content-select">
                <Select
                  size="large"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  className="select"
                  placeholder="Banheiros"
                  value={Number(banheiros) ? Number(banheiros) : ""}
                  onChange={(item) => setBanheiros(item)}
                  options={bathroomsData}
                />
              </div>
            </ContentSelectConfig>
            <Divider />

            <ContentSelectConfig>
              <div>
                <span className="title">Vaga</span>
              </div>

              <div className="content-select">
                <Select
                  size="large"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placeholder="Vagas"
                  className="select"
                  value={Number(vagas) ? Number(vagas) : ""}
                  onChange={(item) => setVagas(item)}
                  options={parkingSpotsData}
                />
              </div>
            </ContentSelectConfig>
            <Divider />
          </ContentFilter>
        </SideFilter>
      )}
      {showModalApp && window.ReactNativeWebView && (
        <ModalApp setShowModal={setShowModalApp} />
      )}
      <Header />
      <Body>
        {pagination.total > 0 ? (
          <h2>
            {pagination.total} {pagination.total === 1 ? 'imóvel' : 'imóveis'} {pagination.total === 1 ? 'encontrado' : 'encontrados'}
          </h2>
        ) : (
          <div>
            {pagination.total === undefined ? <span className="title-empty-result"><br /></span> : <span className="title-empty-result">
              Sua busca <strong>não obteve resultados</strong>, tente novamente
            </span>}
          </div>
        )}

        <Menu>

          <div className="input-group">
            <div className="content-filter">
              <div
                className={validateHasFilter() ? "filtered" : "filters"}
                onClick={(item) => {
                  document.querySelector("body").style.overflow = "hidden";
                  window.scrollTo(0, 0);
                  setShowModalFilter(true);
                }}
                name=""
                id=""
              >
                Filtrar
              </div>

              {filterParams.city_id && (
                <BadgeFilter handleCleanFilterBadge={deleteBadgeCity}>
                  {getLabelCityData()}
                </BadgeFilter>
              )}

              {filterParams.sold && (
                <BadgeFilter handleCleanFilterBadge={deleteBadgeStatusProperty}>
                  {getLabelSaleStatus()}
                </BadgeFilter>
              )}

              {validateShowBadgeSellingPrice() && (
                <BadgeFilter handleCleanFilterBadge={deleteBadgeSellingPrice}>
                  {getLabelSellingPrice()}
                </BadgeFilter>
              )}
              {filterParams.neighborhood_id && (
                <BadgeFilter handleCleanFilterBadge={deleteBadgeNeighborhood}>
                  {getLabelNeighborhoodData()}
                </BadgeFilter>
              )}
              {filterParams.generalType && (
                <BadgeFilter handleCleanFilterBadge={deleteBadgeGeneralType}>
                  {getLabelGeneralTypeData()}
                </BadgeFilter>
              )}
              {filterParams.type && (
                <BadgeFilter handleCleanFilterBadge={deleteBadgePropertyType}>
                  {getLabelPropertiesTypeData()}
                </BadgeFilter>
              )}
              {filterParams.bedrooms && (
                <BadgeFilter handleCleanFilterBadge={deleteBadgeBedrooms}>
                  {getLabelBedroomsData()}
                </BadgeFilter>
              )}
              {filterParams.bathrooms && (
                <BadgeFilter handleCleanFilterBadge={deleteBadgeBathrooms}>
                  {getLabelBathroomsData()}
                </BadgeFilter>
              )}
              {filterParams.parkingSpots && (
                <BadgeFilter handleCleanFilterBadge={deleteBadgeParkingSpots}>
                  {getLabelParkingSpotsData()}
                </BadgeFilter>
              )}
            </div>
            <div className="content-select-order">
              <span className="title-select-order">Ordenar Por: </span>
              <select
                style={{ width: window.innerWidth <= 600 ? "100%" : "310px" }}
                onChange={(option) => handleOrder(option.target.value)}
              >
                <option value="">Relevante</option>
                <option value="maior-menor">Maior Preço</option>
                <option value="menor-maior">Menor Preço</option>
                {/* <option value="me-maior-menor">Metragem (Maior-Menor)</option>
                <option value="me-menor-maior">Metragem (Menor-Maior)</option> */}
                <option value="recent">Mais recente</option>
              </select>
            </div>
          </div>
        </Menu>

        <ItemsContainer>
          {pagination.total !== 0 ? (
            <>
              {!verification ? (
                <OrderComponent
                  HeadImage={HeadImage}
                  ItemHeader={ItemHeader}
                  Link={Link}
                  filterTag={filterTag}
                  data={dataOrder}
                />
              ) : (
                ""
              )}
              {verification ? (
                <>
                  {data
                    .filter((obj) => !obj.sold)
                    .map((value, index) => (
                      <Item
                        key={value._id}
                        HeadImage={HeadImage}
                        value={value}
                        index={index}
                      />
                    ))}
                  {data
                    .filter((obj) => obj.sold)
                    .map((value, index) => (
                      <Item
                        key={value._id}
                        HeadImage={HeadImage}
                        value={value}
                        index={index}
                      />
                    ))}
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <div className="container-empty-search">
              <img
                className="content-empty-search"
                src={ContainerSearch}
                alt=""
              />
            </div>
          )}
        </ItemsContainer>
        {pagination.total !== 0 && (
          <>
            {pagination.total > limit && (
              <SeeMore>
                <Pagination
                  showSizeChanger={false}
                  showSizePage={false}
                  onChange={(value) => handleSetPage(value)}
                  current={Number(pagination.currentPage)}
                  defaultPageSize={limit}
                  total={pagination.total}
                />
              </SeeMore>
            )}
          </>
        )}
      </Body>
      <Footer />
    </>
  );
}

const Item = ({ value, index, HeadImage }) => {
  return (
    value.showCatalog && (
      <Link
        onClick={(e) => {
          if (document.querySelector("#scriptBack")) {
            document
              .querySelector("body")
              .removeChild(document.querySelector("#scriptBack"));
            window.onscroll = (e) => { };
          }
          localStorage.setItem("tela", JSON.stringify(window.scrollY));
          localStorage.setItem("isZapAdvertisements", value?.zapAdvertisements.length === 0);
        }}
        to={`/imoveis/${value._id}${new URLSearchParams(window.location.search).has("app") ? "?app" : ""
          }`}
        key={value._id}
      >
        {HeadImage(value, index)}

        <Description>
          <div className="row">
            <p className="locality-title">
              {value?.localityTitle}
              <p className="locality-subtitle">{value?.localitySubtitle}</p>
            </p>
            <p className="price">
              {value.descountPrice &&
                value.descountPrice != value.sellingPrice &&
                value.descountPrice < value.sellingPrice
                ? // Intl.NumberFormat('pt-BR', {
                //   style: 'currency',
                //   currency: 'BRL',
                // }).format(+value.descountPrice)
                `R$ ${value.descountPrice.toLocaleString("pt-BR")}`
                : // Intl.NumberFormat('pt-BR', {
                //   style: 'currency',
                //   currency: 'BRL',
                // }).format(+value.sellingPrice)
                `R$ ${value.sellingPrice.toLocaleString("pt-BR")}`}
            </p>
          </div>
          <div className="row">
            <p className="street">
              {localStorage.getItem("id") ? value.address : value.address}
              {localStorage.getItem("id") &&
                String(value.showNumber).toString() == "true" &&
                value.addressNumber
                ? // <span className="number">
                ", " + value.addressNumber
                : // </span>
                ""}
              {localStorage.getItem("id") &&
                value.showComplement &&
                value.addressComplement
                ? //<span className="number">
                " / " + value.addressComplement
                : //</span>
                ""}
            </p>

            {localStorage.getItem("id") ? (
              <p className="commission">
                Comissão <span>{value.comission}%</span>
                {/*<span>6%</span></p>*/}
              </p>
            ) : (
              ""
            )}
          </div>
          <div
            className={
              value.sold
                ? "row content-property-sell"
                : "row content-property-information"
            }
          >
            {value.sold ? (
              <strong>
                {value.soldDate ? `Vendido em ${value.soldDate}` : "Vendido"}
              </strong>
            ) : (
              <>
                <div className="icon-group">
                  <img
                    className="ruler"
                    src={require("../../assets/outline_icons/grey/ruler.png")}
                    alt=""
                  />
                  <p>{value.fullLandSize} m²</p>
                </div>
                <div className="icon-group">
                  <img
                    className="parking"
                    src={require("../../assets/outline_icons/grey/car_parking.png")}
                    alt=""
                  />
                  <p>
                    {value.parkingSpots}{" "}
                    {value.parkingSpots > 1 ? "vagas" : "vaga"}
                  </p>
                </div>
                <div className="icon-group">
                  <img
                    className="bed"
                    src={require("../../assets/outline_icons/grey/bed.png")}
                    alt=""
                  />
                  <p>
                    {value.bedrooms} {value.bedrooms > 1 ? "quartos" : "quarto"}
                  </p>
                </div>
                <div className="icon-group">
                  <img
                    className="bed"
                    src={require("../../assets/outline_icons/grey/bed.png")}
                    alt=""
                  />
                  <p>
                    {value.suites} {value.suites > 1 ? "suítes" : "suíte"}
                  </p>
                </div>
              </>
            )}
          </div>
        </Description>
      </Link>
    )
  );
};

export default PropertiesList;
