export const defaultDate = (date) => {
    if(date === '') return '';

    date = date.split("-")[2] +
    "/" +
    date.split("-")[1] +
    "/" +
    date.split("-")[0].slice(0, 4);
    
    return date;
}

export const formatDate = (time) => {
    if(time === '') return '';
    time = new Date(time);

    let date = String(time.getDate()).padStart(2, '0');
    let month = String(time.getMonth() + 1).padStart(2, '0');
    let year = time.getFullYear();
    let currentDate = date + '/' + month + '/' + year;

    return currentDate;
}