import React, {useState} from 'react'
import Header from '../../components/header'
import { BgImg, FilterContainer, MarketingContainer, CardBottom} from './styles'
import Footer from '../../components/Footer'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import '../../global.css';
import { Link } from 'react-router-dom'
import api from '../../api'

export default function About() {

    return (
        <>
            <BgImg>
                <Header />
                <FilterContainer>
                    <div className="login-wrapper">
                        <div className="about-us">
                            <h3>Sobre a Imóvel Vazio</h3>
                            <h1>
                                Somos um grupo de investimento imobiliário que atua há mais de <a style={{color: '#EA5C1E', display: 'inline-flex'}}>10 anos</a> no mercado
                            </h1>

                            <h5>Durante esta trajetória, adquirimos vasta experiência no relacionamento com corretores imobiliários. Identificamos suas principais dificuldades e criamos métodos exclusivos para facilitar sua rotina, favorecendo desta forma sua interação com clientes e com outros corretores.</h5>
                        </div>

                        <div className="about-image">
                            <div className="img"></div>
                        </div>
                    </div>

                </FilterContainer>

                <MarketingContainer>
                    <h1>Nossos diferenciais</h1>

                    <section className="images">
                        <img style={{width: window.innerWidth <= 600 ? 'inherit': ''}} src={require('./frame1.svg')} alt="frame1" />
                        <img style={{width: window.innerWidth <= 600 ? 'inherit': ''}} src={require('./frame2.svg')} alt="frame2" />
                        <img style={{marginTop: -170, width: window.innerWidth <= 600 ? 'inherit': ''}} src={require('./frame3.svg')} alt="frame3" />
                    </section>
                </MarketingContainer>

                <CardBottom>
                    <h3>
                        Comprar e vender imóveis é do nosso DNA
                    </h3>
                </CardBottom>
            </BgImg>

            <Footer />

        </>
    )
}
