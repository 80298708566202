import styled from 'styled-components'

export const BgImg = styled.div`
    width:100vw;
    min-height:150vh;
    background:url(${require('../../assets/Cbanking/bg.svg')});
    background-size:cover;
    background-position:center; 
    padding-top:200px;

    @media(max-width:600px){
        padding-top:0px;

        .header{
            margin-bottom: 70px;
        }
    }
`

export const Container = styled.main`
    width:100%;
    height:100%;
    display:flex;
    flex-wrap:wrap;
    padding-bottom:500px;   
    justify-content:center;

    @media(max-width:600px){
        padding-bottom:0px;   
    }
`
export const TableContainer = styled.div`   
    width:100%;
    max-width:650px;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(30px);
    border-radius: 8px 0px 0px 8px;
    padding:40px;
    margin-top:10px;
    @media(max-width:600px){
        padding: 10px;  
    }

    p{
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.05em;
        margin-top:10px;    
        color: #1E1D1D;
    }

    table{
        border-collapse:collapse;
        width:100%;

        .initial-th{
            border-top:1px solid #858585;
            border-right:1px solid #858585;
            border-bottom:1px solid #858585;
        }

        .final-th{
            border:1px solid #858585;
            width:30%;
            text-align:left;
        }

        td:last-child{
            border:none;
        }

        th{
            padding:5px;
            text-align:left;
            padding-left:20px;
        }     

        th:last-child{
            border-bottom:1px solid #858585;
            width:40%;
        }       

        td{
            padding-left:20px;
            border-right:1px solid #858585;
            text-align:left;
            font-weight: 500;
            font-size: 14px;
            line-height: 33px;
            width:30%;
            font-family: Ubuntu;
            font-style: normal;
            letter-spacing: 0.04em;

            color: #1E1D1D;
        }
    }
`
export const FormContainer = styled.div`
    width:100%;
    max-width:644px;
    background-color:#FFFFFF;   
    padding:30px;

    .caption{
        font-size:0.8rem;
        line-height:25px;
    }
`