import React from 'react';
import styled from 'styled-components';

export const FormFieldStyled = styled.div`

  background-color: #eee;
  border-radius: 5px;
  border: ${({ error }) => error.type ? 'var(--error-color) solid 1px' : 'none'};
  margin: 0.5rem 0.5rem 1.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;

  small{
      font-weight: 400;
  }

  span {
    font-size: smaller;
    font-weight: 500;
    color: var(--error-color);
  }

  input, select, span.ant-input-affix-wrapper.ant-input-password, span.ant-input-affix-wrapper.ant-input-password:hover{
      background-color: transparent !important;
      border: transparent;
      border-radius: 0;
      border-bottom: 2px solid #ccc;
      width: 100%;
      font-size: 1rem;
  }

  span.ant-input-suffix, span.ant-input-prefix {
    font-size: large;
  }

`;