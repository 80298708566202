import styled from "styled-components";

export const Container = styled.div`
  padding-top: 65px;
  height: 479px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  form {
    padding-right: 30px;
  }
`

export const Title = styled.span`
  color: #3B414A;
  font-family: 'Roboto';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
`

export const ContentCharacteristics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
`

export const TitleCharacteristics = styled.span`
  color: #1B1F27;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const ContentCharacteristicsValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
`

export const ContentCharacteristicsSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
`
export const Question = styled.span`
  max-width: 220px;
  color: #000;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const ValueQuestion = styled.span`
  color: #8C8C8C;
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const SubTitle = styled.span`
  color: #3B414A;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 30px;
`

export const ContentField = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
  padding-right: 30px;
  gap: 10px;
  margin-top: 40px;

  .content-middle {
    width: 50%;
  }

  .content-larger {
    width: 100%;
  }
`

export const ContentQuestion = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 40px;
  padding-bottom: 30px;
`

export const TitleQuestion = styled.span`
  color: #3B414A;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const ContentTagValue = styled.div`
  display: flex;
  gap: 20px;
`

export const TagValue = styled.span`
  border-radius: 100px;
  border: 1px solid ${props => props?.activeStatus ? '#EA5C1D' : '#FFF'};
  background-color: ${props => props?.activeStatus ? '#FEEDDB' : '#B3B4BA'};
  padding: 10px;
  width: fit-content;
  cursor: pointer;

  color: ${props => props?.activeStatus ? '#EA5C1D' : '#FFF'};
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const ContentTextEditor = styled.div`
  padding-right: 30px;
`

export const LabelEditor = styled.span`
  color: #474C57;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const Label = styled.label`
  color: #B3B4BA;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 10px;
  margin-left: 0 !important;
`

export const FieldDisabled = styled.input`
  display: flex;
  padding: 10px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  width: 100%;

  color: #8C8C8C;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`