import styled from "styled-components";

export const BgImg = styled.div`
  height: 1000px;
  width: 100vw;
  background: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 600px) {
    height: 100%; //1350px;
  }
`;

export const FilterContainer = styled.div`
  width: 100vw;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 150px;

  .login-wrapper {
    border-radius: 8px;
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    padding-right: none;
    justify-content: space-evenly;
    align-items: center;
    height: 80%;

    .info-box {
      height: 100%;
      width: 30%;
      padding-left: 35px;
      padding-right: 45px;
      padding-top: 27px;
      display: flex;
      flex-direction: column;
      padding-bottom: 15px;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(30px);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      .info-box-2 {
        p {
          margin-top: 8px;
          font-size: 18px;
        }
      }

      h1 {
        font-size: 35px;
        font-weight: 1000;
      }

      .card-retangle {
        height: 50px;
        width: 100%;
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 18px;
        font-weight: 500;

        img {
          height: 80%;
          width: 10%;
          margin-right: 20px;
        }
      }
    }

    .register-box {
      height: 110%;
      width: 70%;
      padding-left: 30px;
      border-radius: 10px;
      padding-right: 45px;
      display: flex;
      flex-direction: column;
      padding-bottom: 15px;
      background: white;
      padding-top: 20px;
      align-items: center;

      .dis-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
          text-align: center;
        }
      }

      h4 {
        font-weight: 400;
        margin-top: 8px;
        color: #444;
        font-size: 16px;
      }

      .body-box {
        margin-top: 25px;
        margin-bottom: 10px;
        height: 100%;
        width: 90%;

        .step-loading {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .circle-loading {
            padding: 0;
            height: 60px;
            position: relative;
            width: 60px;

            span {
              background: #ea5c1e;
            }
          }

          h3 {
            margin-top: 30px;
            font-weight: 300;
            font-size: 20px;
          }

          .h3 {
            font-weight: 500;
            margin-top: 10px;
            font-size: 18px;
          }

          .mins {
            justify-content: center;

            button {
              width: 35%;
            }
          }
        }

        .steps-box {
          height: 50px;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;

          .step-horizontal {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: 400;
            font-size: 14px;

            .step-circle {
              border: 3px solid #ea5c1e;
              height: 35px;
              width: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: black;
              font-weight: 800;
              border-radius: 50%;
              font-size: 20px;
              margin-right: 8px;
            }

            .step-disable {
              border: 3px solid #ddd;
              height: 35px;
              width: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #aaa;
              font-weight: 800;
              border-radius: 50%;
              font-size: 20px;
              margin-right: 8px;
            }

            .step-complete {
              border: 3px solid #05677c;
              height: 35px;
              width: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: black;
              font-weight: 800;
              border-radius: 50%;
              font-size: 20px;
              margin-right: 8px;
            }
          }
        }

        .input-box {
          background-color: #eee;
          border-radius: 5px;
          height: 60px;
          width: 100%;
          margin-top: 20px;
          padding-left: 10px;
          padding-right: 15px;
          padding-top: 3px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-evenly;

          small {
            font-weight: 500;
          }

          input,
          select {
            background-color: transparent;
            border: transparent;
            border-bottom: 2px solid #ccc;
            width: 100%;
          }
          textarea {
            background-color: transparent;
            border: transparent;
            border-bottom: 2px solid #ccc;
            width: 100%;
          }
        }

        .max {
          height: 200px;
          textarea {
            height: 80%;
            resize: none;
          }
        }

        .container-input-box {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .input-box {
            width: 48%;
          }
        }

        .container-next {
          height: 50px;
          width: 100%;
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          align-items: space-evenly;
          justify-content: flex-end;

          button {
            border: transparent;
            background: linear-gradient(90deg, #ea5c1e 0%, #c13818 100%);
            width: 48%;
            color: white;
            height: 100%;
            border-radius: 4px;
          }

          button:hover {
            color: #ea5c1e;
            background: white;
            text-decoration: none;
            border: 2px solid #ea5c1e;
            filter: none;
            background-color: white;
            background-image: none;
          }

          .btBack {
            margin-right: 4%;
            border: 2px solid #ea5c1e;
            background-color: white;
            background: white;
            color: #ea5c1e;
          }

          .btBack:hover {
            background: linear-gradient(90deg, #ea5c1e 0%, #c13818 100%);
            color: white;
            border: none;
          }
        }

        .info {
          justify-content: space-between;
          .infos {
            padding-left: 0;
            margin-right: 40px;
          }

          button {
            width: 20%;
          }
        }

        .min {
          button {
            width: 25%;
          }
        }

        .body-terms {
          background-color: #eee;
          margin-top: 20px;
          padding-top: 20px;
          padding-left: 20px;
          overflow-y: scroll;
          height: 160px;
          border-radius: 8px;
          font-size: 13px;

          h2 {
            margin-bottom: 8px;
          }

          p {
            color: #888;
          }
        }

        .stepi {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 17px;
          font-weight: 300;
          padding-top: 16px;

          p {
            margin-bottom: 10px;
          }

          .edit-number {
            font-size: 15px;
            color: #00aec8;
          }

          .input-center {
            width: 60%;
          }

          .container-center {
            width: 60%;
            a {
              width: 100%;
            }
            button {
              width: 100%;
            }
          }

          .reload {
            font-size: 14px;
            margin-bottom: 0px;
          }

          a {
            color: black;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    justify-content: flex-start;

    .login-wrapper {
      padding: 0px;
      width: 90%;

      .info-box {
        width: 90%;
        height: auto;
        backdrop-filter: blur(0);
        background: transparent;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 40px;
        .info-box-2 {
          text-align: center;
        }
      }
      .register-box {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;

        .body-box {
          .step {
            .step-loading {
              margin-top: 50px;

              h3 {
                text-align: center;
              }
            }
            .dis-flex {
              margin-top: 0px;
            }

            .margin-texts {
              margin-top: 50px;
            }

            .container-input-box {
              flex-direction: column;

              .input-box {
                width: 100%;
              }
            }
            .container-next {
              flex-direction: column;
              height: auto;

              button {
                margin-top: 10px;
                width: 100%;
                height: 50px;
              }
            }
          }
        }
      }
    }
  }
`;

export const Announcement = styled.div`
  width: 100vw;
  height: 69px;
  background: #ececec;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    letter-spacing: 0.05em;
    text-decoration-line: underline;
    color: #0ea5bb;
  }

  h2:first-child {
    text-decoration-line: none;
    margin-right: 20px;
    color: #676666;
  }
`;

export const SellingProperties = styled.section`
  width: 100vw;
  display: flex;
  flex-direction: column;

  .title {
    margin-top: 90px;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    color: #1e1d1d;
  }

  p {
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.05em;

    color: #1e1d1d;
  }

  .carousel {
    margin-top: 50px;
    padding-left: 100px;
    padding-right: 100px;
  }
`;

export const BtnContainer = styled.div`
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ea5c1e;
    border: 1px solid #ea5c1e;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 14px;
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const MarketingContainer = styled.section`
  margin-top: 10vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #1e1d1d;

  p {
    margin-top: 10px;
    font-weight: 300;
    font-size: 20px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;

    color: #1e1d1d;
  }

  .images {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
`;
export const PropertyOffer = styled.div`
  margin-top: 10vh;
  width: 100vw;
  height: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url(${require("../../assets/home/offerbg.png")});
  background-size: cover;

  h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
  }

  p {
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
    color: #ffffff;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    padding: 12px;
    color: #ffffff;
    background: linear-gradient(90deg, #ea5c1e 0%, #c13818 100%);
    border-radius: 4px;
  }
`;

export const Bank = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
  margin-bottom: 10vh;

  h1 {
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    margin-bottom: 20px;
    /* #1E1D1D */

    color: #1e1d1d;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    /* or 139% */

    text-align: center;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
    color: #1e1d1d;
  }

  a {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;

    color: #ea5c1e;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    text-decoration: none;
    border: 1px solid #ea5c1e;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const ActionsBox = styled.div`
  height: 10%;
  margin-top: 10%;
  display: flex;

  .btBack {
    margin-right: 4%;
    border: 2px solid #ea5c1e;
    background-color: white;
    background: white;
    color: #ea5c1e;
  }

  button {
    margin: 2px;
    border: transparent;
    background: linear-gradient(90deg, #ea5c1e 0%, #c13818 100%);
    width: 48%;
    color: white;
    height: 100%;
    border-radius: 4px;
  }

  button:hover {
    color: #ea5c1e;
    background: white;
    text-decoration: none;
    border: 2px solid #ea5c1e;
    filter: none;
    background-color: white;
    background-image: none;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
      height: 50px;
    }
  }
`;

export const CheckBox = styled.div`
  // incrementar ainda.
`;
