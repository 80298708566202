import styled from 'styled-components';

export const Container = styled.div`

header{
    width:100vw;
    height:10vh;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:10px;
    padding-left:20px;
    padding-right:20px;
    border-bottom: 1px solid#C4C4C4;
    background-color:#ffffff;
    z-index:1;
    position:fixed;
    
    @media (max-height: 800px){
        height:12vh;
        padding:5px;
        //position: unset;
    }

    @media (max-width: 600px){
        height:10vh;
        padding:5px;
        //position: unset;
    }

    @media (max-width: 300px){
        padding-left: 2px;
        padding-right: 2px;
        //position: unset;
    }
}

header .logo img{
    width:200px;

    @media (max-width: 600px){
        width: 150px;
    }

    @media (max-width: 300px){
        margin-right: 15px;
        width: 130px;
    }
}

header .links {
    margin-top:30px;
    margin-bottom: 10px;
    display: flex;
    padding-right: 50px;

    @media (max-height: 800px){
        margin-top:10px;
    }

    @media (max-width: 600px) and (min-width: 301px){
        padding-right: 10px !important;
        margin-top: 20px;
    }

    @media (max-width: 300px){
        width: 100%;
        margin-top:20px;
        padding-right: 2px;
        justify-content: space-between;
    }
}

/* @media (max-width:600px){
    header{
        height:auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:10px;
    }

} */

header .links .link{
    display: flex;
    align-items: center;
    padding-right: 40px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #1E1D1D;
    text-decoration: none;

    @media (max-width: 600px) and (min-width: 301px){
        padding-right: 10px !important;
    }

    @media (max-width: 300px){
        padding-right: 2px;
        padding-left: 10px;
    }
}

header .links button{
    background: transparent;
    border: none;
    outline: none;
}

header .links .link img{
    padding-right:10px;
}
  
`;
// export const Logo = styled.img`
//   width:200px !important;

//    @media (max-width: 500px){
//         width: 160px !important;
//     }
// `
