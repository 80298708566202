import styled from 'styled-components'
import BgImage from '../../assets/home-background.png'

export const BgImg = styled.div`
    min-height:750px;
    width:100vw;
    background : url(${BgImage});
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
`

export const FilterContainer = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:center;
    padding-bottom:150px;

    .text-wrapper{
        height:166px;
        width:100%;
        max-width:1250px;
        text-align:left;
        padding:30px;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(30px);
        border-radius: 8px;
        margin-top: 380px;

        h1{
            widht: 100%;
            font-size: 3rem;
        }
    }
    .filter-wrapper{
        background: #FFFFFF;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15), 0px 4px 32px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        width:100%;
        max-width:1250px;
        display:flex;
        flex-wrap:wrap;
        padding:20px;
        padding-right:none;

        .input-wrapper{
            width:100%;

            small{
                font-size: 13px;
                font-weight: 300;
                padding-left: 12px;
            }
            select{
                width: 100%;
                color: #777;
                height: 80%;
                padding-left: 5px;
                border: none;
            }
        }

        div.ant-input-number-input-wrap > input {
            width: 100%;
            font: 400 18px Roboto, sans-serif;
            font-style: normal;
            color: #777;
        }

        .searchBtn{
            text-decoration:none;
            background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
            border-radius: 4px;
            align-self:center;
            margin-left:35px;
            margin-top:10px;
            width: 157px;
            height: 45px;
            display:flex;
            justify-content:center;
            align-items:center;

            img{

            }

            p{
                font-family: Ubuntu;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 21px;
                text-align: center;
                letter-spacing: 0.05em;
                color: #FFFFFF;
                margin-left:10px;
            }
        }
    }


    @media only screen and (min-width: 600px) {
        .input-wrapper{
            max-width: 200px;
        }
    }
    @media only screen and (max-width: 600px) {
        html, body{
            width: 100%;
            margin: 0;
            padding: 0;
        }
        .text-wrapper{
            width: 80%;
            margin-top: 120px;
            h1{
                font-size: 1.8rem;
            }
        }
        .filter-wrapper{
            width: 80%;

            .searchBtn{
                width: 100%;
                margin: 0;
                margin-top: 25px;
            }
        }

        .input-wrapper{
            max-width: 500px;
            margin-top: 20px;
            border-bottom: 1px solid #aaa;
        }
        
    }
    
`

export const Announcement = styled.div`
width: 100vw;
min-height: 69px;
background: #ECECEC;
text-align:center;
display:flex;
justify-content:center;
align-items:center;
    div{
        width: 100%;
        display: flex;
        justify-content:center;
        align-items:center;
    }
    h2{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: right;
        letter-spacing: 0.05em;
        text-decoration-line: underline;
        color: #0EA5BB;
    }

    h2:first-child{
        text-decoration-line: none;
        margin-right:20px;
        color: #676666;
    }


    @media only screen and (max-width: 600px) {
        div{
            flex-direction: column;
            padding: 20px;
        }
        h2:nth-child(2){
            text-align: center;
            margin-top: 7px;
        }
    }
`

export const SellingProperties = styled.section`
    width:100vw;
    display:flex;
    flex-direction:column;

    .title{
        margin-top:90px;
        font-size: 36px;
        line-height: 41px;
        text-align: center;
        color: #1E1D1D;
    }

    p{
        text-align: center;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.05em;

        color: #1E1D1D;
    }

    .carousel{
        margin-top:50px;
        padding-left:100px;
        padding-right:100px;
    }

    @media only screen and (max-width: 600px) {
        .title{
            margin-top: 40px;
        }
        .carousel{
            padding-left:0;
            padding-right:0;
        }

        .verTodos{
            width: 80%;
            margin-top: 50px;
        }
    }
`

export const BtnContainer = styled.div`
    width:100vw;
    height:10vh;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top: -50px;

    a{
        text-decoration:none;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #EA5C1E;
        border: 1px solid #EA5C1E;
        box-sizing: border-box;
        border-radius: 4px;
        padding:14px;
        padding-left:40px;
        padding-right:40px;
    }
`

export const MarketingContainer = styled.section`
    margin-top:10vh;
    width:100vw;
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: #1E1D1D;

    p{
        margin-top:10px;
        font-weight: 300;
        font-size: 20px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.05em;

        color: #1E1D1D;
    }

    .images{
        display:flex;
        width:100%;
        justify-content:space-around;
        align-items:center;
        flex-wrap:wrap;
    }
`
export const PropertyOffer = styled.div`
    margin-top:10vh;
    width:100vw;
    height: 272px;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    background:url(${require('../../assets/home/offerbg.png')});
    background-size:cover;

    h1{
        font-weight: bold;
        font-size: 36px;
        line-height: 41px;
        text-align: center;
        margin-bottom:20px;
        color: #FFFFFF;
    }

    p{
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.05em;
        margin-bottom:20px;
        color: #FFFFFF;
    }

    a{
        text-decoration:none;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;
        padding:12px;
        color: #FFFFFF;
        background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
        border-radius: 4px;
    }
`

export const Bank = styled.div`
    width:100vw;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    margin-top:10vh;
    margin-bottom:10vh;

    h1{
        font-size: 36px;
        line-height: 41px;
        text-align: center;
        margin-bottom:20px;
        /* #1E1D1D */

        color: #1E1D1D;
    }

    p{
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
        /* or 139% */

        text-align: center;
        letter-spacing: 0.05em;
        margin-bottom:20px;
        color: #1E1D1D;
    }

    a{
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;

        color: #EA5C1E;
        padding:10px;
        padding-left:30px;
        padding-right:30px;
        text-decoration:none;
        border: 1px solid #EA5C1E;
        box-sizing: border-box;
        border-radius: 4px;
    }
`

