import api from "../api";
import queryString from "../utils/queryString";

export const getOffersAsync = ({ limit = 20, id = undefined, ...rest }, isAuth = true, cancelToken) => {

  const request = id ? `/${id}` : queryString({ ...rest, limit });
  const url = isAuth ? '/auth' : '';
  return api.get(
    `/offer${url}${request}`,
    {
      headers: {
        cancelToken
      },

    }
  );

};
